import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-product-card-image',
  templateUrl: './product-card-image.component.html',
  styleUrls: ['./product-card-image.component.scss'],
})
export class ProductCardImageComponent {
  @Input() isOnline: boolean;
  @Input() imageThumbnail: string;
  @Input() productNumber: number;
  @Input() isSplitCard: boolean;
  @Input() clickable = true;
  @Input() isSmall = false;
  @Input() isMedium = false;

  @Output() productImageClickEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  handleProductImageClick() {
    this.productImageClickEmitter.emit();
  }
}
