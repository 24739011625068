<!-- For Mobile -->
<div class="message-bar-outer-mobile" *ngIf="isMobile && shouldDisplay">
  <div class="message-bar-inner-mobile">
    <p data-cy="message-bar-mobile-text">
      {{ message }}
    </p>
  </div>
</div>
<!-- For Desktop -->
<div
  class="message-bar-outer-desktop"
  *ngIf="!isMobile && shouldDisplay"
  [style.borderRadius.px]="radius"
>
  <div
    class="message-bar-inner-desktop"
    [style.paddingTop.px]="paddingY"
    [style.paddingBottom.px]="paddingY"
    [style.paddingRight.px]="paddingX"
    [style.paddingLeft.px]="paddingX"
  >
    <ng-container *ngIf="message; else wrappedContent">
      <p [style.textAlign]="textAlign" data-cy="message-bar-desktop-text">
        {{ message }}
      </p>
    </ng-container>
    <ng-template #wrappedContent>
      <ng-content></ng-content>
    </ng-template>
  </div>
</div>
