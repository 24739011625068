import { AppStateForProduct } from '@shared/models/app-state-for-product';

export const calculateMslRestriction = (
  isMslRestricted: boolean,
  isOrderableOnMsl: boolean,
  mslProductHasSecondaries: boolean,
  mslUnrestrict?: boolean,
) => {
  if (!mslUnrestrict) {
    return isMslRestricted && !isOrderableOnMsl && !mslProductHasSecondaries;
  }
  return false;
};

export const calculateMslAlert = (
  isMSLRestrictionOverrideY: boolean,
  isMslRestricted: boolean,
  isOrderableOnMsl: boolean,
  mslProductHasSecondaries: boolean,
  orderable: boolean,
  mslUnrestrict?: boolean,
) => {
  if (!mslUnrestrict) {
    return (
      !isMSLRestrictionOverrideY &&
      isMslRestricted &&
      !isOrderableOnMsl &&
      !mslProductHasSecondaries &&
      !orderable
    );
  }
  return false;
};

export const isCustomerMslRestricted = (
  appStateForProduct: AppStateForProduct,
) =>
  appStateForProduct?.isMSLRestrictionOverrideY
    ? false
    : appStateForProduct?.isMslRestricted;
