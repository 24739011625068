import * as dayjs from 'dayjs';

/**
 * @remarks
 * These methods are date helper for Date Management in PDP Module
 */

export const formatDateWithDash = (date: string) =>
  dayjs(new Date(date.replace(/-/g, '/').replace(/T.+/, ''))).format(
    'MM/DD/YY',
  );

/**
 * Returns boolean value if date is following param rules.
 * @param lapse - input number. Ex: 180
 * @param type - input string. Ex: 'days', 'hours'
 * @param date - dayjs param
 * @returns date
 */
export const dateIsLessThan = (
  lapse: number,
  type: dayjs.UnitType,
  date: dayjs.Dayjs,
) => dayjs().diff(date, type) <= lapse;

/**
 * Returns date formatted according to format param, if there is no valid date returns empty.
 * @param format - format to output date. Ex format to output date MM/DD/YY
 * @param date - dayjs param
 * @returns date with format received
 */
export const formatDate = (format: string, date: dayjs.Dayjs) =>
  date.isValid() ? date.utc().format(format) : '';
