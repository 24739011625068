import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-selectable-countable-item',
  templateUrl: './selectable-countable-item.component.html',
  styleUrls: ['./selectable-countable-item.component.scss'],
})
export class SelectableCountableItemComponent {
  @Input() item: SelectableCountableItem;
  @Input() isSelected: boolean;

  @Output()
  selectItemEmitter: EventEmitter<SelectableCountableItem> = new EventEmitter();

  constructor() {}

  onSelectItem() {
    this.selectItemEmitter.emit(this.item);
  }
}

export interface SelectableCountableItem {
  title: string;
  subtitle: string;
  count: number;
  isComplete?: boolean;
}
