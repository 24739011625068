<div
  data-cy="product-card-note-section"
  class="product-note"
  (click)="itemNoteClick()"
>
  <ion-icon
    data-cy="product-card-note-icon"
    src="assets/icon/notes-icon.svg"
  ></ion-icon>
  <p [attr.data-cy]="'product-note-' + productNumber + '-text'">
    {{ note | truncateWithEllipsis: truncateAmount }}
  </p>
</div>
