import { Order, OrderItem } from '@usf/ngrx-order';
import { SelectedCustomerState } from '@panamax/app-state';
import { ProductState } from '@usf/ngrx-product';
import {
  CustomerPill,
  ProductContract,
  ProductInventory,
} from '@usf/product-types';
import {
  SubbedItem,
  SubmittedOrderTallies,
} from './submitted-order-view-model';
import { Customer } from '@usf/customer-types';

// eslint-disable-next-line no-shadow
export enum ShipmentTypeEnum {
  routed = 'routed',
  vendor = 'vendor',
}

export class SplittedOrder {
  order: Order;
  totalDisplayPrice: number;
  hasError: boolean;
  orderWeight: number;
  cartItems: ConfirmationCartItem[];
  cartProductsItems: ConfirmationCartItem[];
}

export class MasterOrderForView {
  order: Order;
  hasError: boolean;
  orderWeight: number;
  totalDisplayPrice: number;
  cartItems: ConfirmationCartItem[];
  cartProductsItems: ConfirmationCartItem[];
}
export interface ConfirmationCartItem {
  orderItem: OrderItem;
  subbedItem?: SubbedItem;
  productState: ProductState;
  productInventory: ProductInventory;
  productContract: ProductContract;
  customerPill: CustomerPill;
}

export interface OrderConfirmationVM {
  masterOrderById: Order;
  masterOrderForView: MasterOrderForView;
  orderTallies: SubmittedOrderTallies; // Order Pricing, eaches, cases, items and shipments totals of currently submitting order
  customerProfile: SelectedCustomerState; // selected customer info
  splittedOrders: SplittedOrder[]; // all kind of submitted orders filtered by selected customer
  ready: boolean; // Indicates that my orders api call has completed (both success and fail)
  expectedShipmentCount: number;
  showTransferButton: boolean;
  disableTransfer: boolean;
  transferButtonText: string;
  partnerAutotransfer: boolean;
  navigationRestricted: boolean;
  exceptionRestricted: boolean;
  shipToCustomer?: Customer;
  shipToDepartmentName?: string;
}
