import { createSelector } from '@ngrx/store';
import { Order, orderSelectors } from '@usf/ngrx-order';
import { Product } from 'src/app/shared/models/product.model';
import { ProductReview } from '../models/product-review.model';
import { productWithAlternativesSelector } from './product.selectors';

export const selectProductsById = (
  productNumbers: Number[],
  keyword: string | undefined,
) =>
  createSelector(
    productWithAlternativesSelector,
    (productStates: Map<Number, Product>) => {
      if (productStates.size == 0) {
        return [];
      }

      const validProducts = productNumbers.filter(prodNumber => {
        const product = productStates.get(prodNumber);
        return product != undefined && !product.notFound;
      });

      const productPreviewState = validProducts.map(prodNumber => {
        const product = productStates.get(prodNumber);
        const productSummary = product.summary;
        const productImage = product.imageThumbnail;
        const review: ProductReview = {
          productNumber: productSummary.productNumber,
          productDescription: productSummary.productDescTxtl,
          productImage: productImage,
          productPackSize: productSummary.salesPackSize,
          trackingAttributes: product.trackingAttributes,
        };
        return review;
      });
      if (keyword === undefined) {
        return productPreviewState;
      } else {
        return productPreviewState.filter(value => {
          return (
            value.productNumber.toString().includes(keyword) ||
            value.productDescription
              .toLocaleLowerCase()
              .includes(keyword.toLocaleLowerCase())
          );
        });
      }
    },
  );

export const selectProductNumbersByOrderId = (orderId: string) =>
  createSelector(
    orderSelectors.selectOrderWithId(orderId),
    (orderState: Order) => {
      return orderState.orderItems.map(order => order.productNumber);
    },
  );
