import { createSelector } from '@ngrx/store';
import { orderItemSelectors } from '@usf/ngrx-order';
import { OrderItemState } from '@usf/ngrx-order';

export const selectOrderItemWithProductNumber = (productNumber: number) =>
  createSelector(
    orderItemSelectors.selectOrderItemState,
    (orderItemState: OrderItemState) => {
      const entity = orderItemState.entities[productNumber];
      return entity;
    },
  );
