import { Customer } from '@usf/customer-types';
import { OGRestrictionsEnum } from '../constants/sub-restrictions-enum';
import { SelectedCustomerState } from '@panamax/app-state';

export const customerIsMslRestricted = (
  selectedCustomer: Customer | SelectedCustomerState,
) => selectedCustomer?.restrictToOG === OGRestrictionsEnum.RESTRICT_TO_ML;

export const customerIsOgRestricted = (selectedCustomer: Customer) =>
  selectedCustomer?.restrictToOG === OGRestrictionsEnum.RESTRICT_TO_OG;

export const customerIsUnRestricted = (selectedCustomer: Customer) =>
  selectedCustomer?.restrictToOG === OGRestrictionsEnum.UNRESTRICTED;

export const customerIsGLCustomer = (customer: Customer) => {
  let isGLCustomer = false;
  if (customer?.glOwners && customer?.glOwners?.length) {
    isGLCustomer = customer.glOwners.some(
      glOwner =>
        glOwner.isGLCustomer &&
        (glOwner.departmentNumber === 0 ||
          customer.departmentNumbers.some(
            deptNum => glOwner.departmentNumber.toString() === deptNum,
          )),
    );
  }
  return isGLCustomer;
};

export const customersAreSameContext = (
  customer1: SelectedCustomerState,
  customer2: SelectedCustomerState,
) => {
  return (
    customer1 &&
    customer2 &&
    customer1?.customerNumber === customer2?.customerNumber &&
    customer1?.departmentNumber === customer2?.departmentNumber &&
    customer1?.divisionNumber === customer2?.divisionNumber
  );
};
