import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-usf-checkbox',
  templateUrl: './usf-checkbox.component.html',
  styleUrls: ['./usf-checkbox.component.scss'],
})
export class UsfCheckboxComponent {
  @Input() isSelected: boolean;
  @Input() invertColors = false;
  @Output() selectEmitter: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  selectCheckbox() {
    this.selectEmitter.emit(!this.isSelected);
  }
}
