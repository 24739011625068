<ng-content></ng-content>
<ng-container [ngSwitch]="status">
  <ng-container *ngSwitchCase="statusList.initial"></ng-container>
  <ng-container *ngSwitchCase="statusList.disabled">
    <span [class.price-text-margin]="addMargin">{{
      'i18n.lists.noPrice' | translate
    }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="statusList.unavailable">
    <span [class.price-text-margin]="addMargin">{{
      'i18n.lists.noPrice' | translate
    }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="statusList.loading">
    <ion-spinner name="circles" class="price-spinner"></ion-spinner>
  </ng-container>
  <ng-container *ngSwitchCase="statusList.available">
    <span [class.price-text-margin]="addMargin"
      >{{ value | currency }}&nbsp;{{ uom }}</span
    >
  </ng-container>
  <ng-container *ngSwitchCase="statusList.specialOrder">
    <span [class.price-text-margin]="addMargin">{{
      'i18n.lists.specialOrderProductMessage' | translate
    }}</span>
  </ng-container>
</ng-container>
