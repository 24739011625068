import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PanAppState } from '@panamax/app-state';
import {
  getDealsProductExistsFlag,
  PROMOTION_ACTIONS,
} from '@usf/ngrx-promotions';
import { PromotionDetails } from '@usf/promotion-types';
import { filter, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { FEATURES } from 'src/app/shared/constants/splitio-features';
import { selectedCustomer } from '../../ngrx-customer/store';
import { productsAreInState } from '../../shared/selectors/helpers/product-info.selectors.helper';
import { ProductService } from '../../shared/services/product/product.service';
import {
  filterProductsWithDeals,
  selectDealsViewModel,
  selectIsDealsAndProductDataLoaded,
  selectRevManWinbacks,
} from '../selectors/deals.selector';

@Injectable({
  providedIn: 'root',
})
export class DealsService {
  constructor(
    private store: Store,
    private panAppState: PanAppState,
    private productService: ProductService,
  ) {}

  getDealsFlag() {
    return this.panAppState.feature$([FEATURES.split_global_deals]);
  }

  selectDealsProductsExist$() {
    return this.store.select(getDealsProductExistsFlag);
  }

  getAllDealsViewModel$() {
    return this.store.select(selectDealsViewModel);
  }

  getAllDealsLoaded$() {
    return this.store.select(selectIsDealsAndProductDataLoaded);
  }

  loadProducts(productNumbers: number[]) {
    return this.productService.loadProducts(productNumbers);
  }

  getRevManWinbacks$() {
    return this.productService
      .loadRevenueManagement$()
      .pipe(switchMap(_ => this.store.select(selectRevManWinbacks)));
  }

  calculateDealsProductsExist(promotionDetails: PromotionDetails[]) {
    const productNumbers: number[] = [];
    // Collect product numbers from promotion details
    promotionDetails.forEach(promotionDetail => {
      productNumbers.push(promotionDetail.productNumber);
    });

    if (productNumbers.length === 0) {
      this.setDealsProductsExist(false);
      return;
    }
    // Load product data for promotional products
    this.productService.loadProductsForOfflineStorage(productNumbers);
    // Select products from state and selected customer so we can do filtering logic
    this.productService
      .getProductsWithAlternatives(productNumbers)
      .pipe(
        filter(products => productsAreInState(products)),
        take(1),
        withLatestFrom(this.store.select(selectedCustomer)),
      )
      .subscribe(([productsMap, selectedCustomer]) => {
        // Apply filtering logic and set flag to true if at least one product exists
        let dealsProducts = filterProductsWithDeals(
          selectedCustomer,
          promotionDetails,
          productsMap,
        );
        if (dealsProducts.length > 0) {
          this.setDealsProductsExist(true);
        } else {
          this.setDealsProductsExist(false);
        }
      });
  }

  private setDealsProductsExist(newValue: boolean) {
    this.store.dispatch(
      PROMOTION_ACTIONS.updateDealsProductsExists({ newValue }),
    );
  }
}
