<ion-content>
  <section>
    <img
      data-cy="error-page-usf-logo-image"
      src="assets/images/usfoods-logo-color.svg"
      class="usf-logo"
    />
    <h1 data-cy="error-page-title-label">
      {{ 'i18n.login.loginError' | translate }}
    </h1>
    <!-- <p data-cy="error-page-message-text-1">{{ errorMessage }}&nbsp;{{ userStatus$ | async }}</p> -->
    <p data-cy="error-page-message-text">
      {{ 'i18n.login.loginErrorServiceDeskInfo' | translate }}
    </p>
    <ion-button
      data-cy="error-page-try-again-button"
      class="usf-fill-green-button"
      (click)="tryAgain()"
    >
      {{ 'i18n.login.loginErrorTryAgain' | translate }}
    </ion-button>
  </section>
</ion-content>
