import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { HotKeys } from '../../constants/hot-key.enum';
import { InventoryWorksheetService } from '../../../inventory/pages/inventory-worksheet/services/inventory-worksheet.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryHotkeyService {
  private inventoryCardIds: Set<string> = new Set();
  private itemHeights: number[] = [];
  private isEditMode = false;
  private previousId: string;
  private inventoryId: string;
  private dropdownId: string;
  private dropdownIsOpen: boolean;
  interval: any;

  constructor(
    private inventoryWorksheetService: InventoryWorksheetService,
    private popoverController: PopoverController,
  ) {}

  setInventoryCardIds(inventoryCardIds: Set<string>) {
    this.inventoryCardIds = inventoryCardIds;
  }

  setItemHeights(itemHeights: number[]) {
    this.itemHeights = itemHeights;
  }

  setIsEditMode(isEditMode: boolean) {
    this.isEditMode = isEditMode;
  }

  setPreviousId(previousId: string) {
    this.previousId = previousId;
  }

  setInventoryId(inventoryId: string) {
    this.inventoryId = inventoryId;
  }

  setDropdownId(dropdownId: string) {
    this.dropdownId = dropdownId;
  }

  setDropdownIsOpen(dropdownIsOpen: boolean) {
    this.dropdownIsOpen = dropdownIsOpen;
  }

  getInventoryCardIds() {
    return this.inventoryCardIds;
  }

  getItemHeights() {
    return this.itemHeights;
  }

  getIsEditMode() {
    return this.isEditMode;
  }

  getPreviousId() {
    return this.previousId;
  }

  getDropdownId() {
    return this.dropdownId;
  }

  getDropdownIsOpen() {
    return this.dropdownIsOpen;
  }

  onDropdownClick(event: Event) {
    this.dropdownId = '';
    this.dropdownIsOpen = true;
    const id = (event.target as Element)?.id;
    const target = event.target as HTMLElement;
    const parent = target.offsetParent as HTMLElement;
    const parentId = parent?.getAttribute('id');
    const idToHighlight = id || parentId;
    this.previousId = idToHighlight;
  }

  async onKeyEvent(event: KeyboardEvent, ionicElementToFocus: HTMLElement) {
    const nextId = this.getNextId(ionicElementToFocus);
    await this.checkIfDropdownExists();
    if (this.dropdownIsOpen) {
      this.handleHotkeyWhenDropdownIsOpen(event, nextId);
    } else {
      this.handleHotkeyWhenDropdownIsClosed(event, nextId);
    }
  }

  async handleHotkeyWhenDropdownIsOpen(event: KeyboardEvent, nextId: string) {
    if (event.code === HotKeys.escape) {
      this.handleEscapeInsideOfDropdown(event, nextId);
    } else if (event.code === HotKeys.enter && this.dropdownId) {
      this.handleEnterOnDropdownButton(event, nextId);
    } else if (event.code === HotKeys.arrowDown) {
      this.handleArrowDownInsideOfDropdown(event);
    } else if (event.code === HotKeys.arrowUp) {
      this.handleArrowUpInsideOfDropdown(event);
    } else if (this.isAnyHotkeyWeCareAboutBesidesEscape(event.code)) {
      await this.closeDropdown();
      this.blurPreviousInputAndFocusOnNextOne(event, nextId);
    }
  }

  handleHotkeyWhenDropdownIsClosed(event: KeyboardEvent, nextId: string) {
    if (event.code === HotKeys.escape) {
      this.handleEscape(event, nextId);
    } else if (this.isArrowDownOnDropdownBox(event, nextId)) {
      this.handleArrowDownWhileHighlightedOnDropdownBox(event, nextId);
    } else if (this.isAnyHotkeyWeCareAboutBesidesEscape(event.code)) {
      if (!nextId) {
        return;
      }
      this.blurPreviousInputAndFocusOnNextOne(event, nextId);
    }
  }

  async blurPreviousInputAndFocusOnNextOne(
    event: KeyboardEvent,
    nextId: string,
  ) {
    event.preventDefault();
    if (nextId) {
      const idToFocus = this.findNextIdForHotKey(
        nextId,
        event.code,
        event.shiftKey,
      );
      if (idToFocus) {
        await this.blurPreviousInput(nextId);
        // Added timeout for case when blur of the line number input triggers a redraw of the components
        setTimeout(() => {
          this.focusOnId(idToFocus);
        }, 100);
      }
    }
  }

  isAnyHotkeyWeCareAboutBesidesEscape(code: string) {
    return (
      code === HotKeys.enter ||
      code === HotKeys.tab ||
      code === HotKeys.numPadEnter ||
      code === HotKeys.arrowUp ||
      code === HotKeys.arrowDown ||
      code === HotKeys.arrowLeft ||
      code === HotKeys.arrowRight
    );
  }

  isArrowDownOnDropdownBox(event: KeyboardEvent, nextId: string) {
    return (
      event.code === HotKeys.arrowDown &&
      (nextId?.includes(HotKeys.group) || nextId?.includes(HotKeys.description))
    );
  }

  getNextId(ionicElementToFocus: HTMLElement) {
    const elementToFocusId = ionicElementToFocus?.getAttribute('id');
    const nextId = this.previousId ? this.previousId : elementToFocusId;
    return nextId;
  }

  handleEnterOnDropdownButton(event: KeyboardEvent, nextId: string) {
    event.preventDefault();
    const element = document.getElementById(this.dropdownId);
    element.click();
    this.dropdownIsOpen = false;
    this.dropdownId = '';
    this.clearHighlight();
    if (nextId) {
      this.highlightDropdown(nextId);
    }
  }

  handleArrowDownWhileHighlightedOnDropdownBox(
    event: KeyboardEvent,
    nextId: string,
  ) {
    event.preventDefault();
    const element = document.getElementById(nextId);
    element.click();
  }

  handleArrowDownInsideOfDropdown(event: KeyboardEvent) {
    event.preventDefault();
    const lastIndexOfDropdownButtons = this.getLastIndexOfDropdownButtons();
    if (!this.dropdownId) {
      this.dropdownId = HotKeys.button + '-0';
      this.selectDropdownButton();
    } else {
      this.deselectDropdownButton();
      const separatedId = this.dropdownId.split('-');
      let targetIndex = Number(separatedId[separatedId.length - 1]);
      if (targetIndex >= lastIndexOfDropdownButtons) {
        targetIndex = 0;
      } else {
        targetIndex += 1;
      }
      this.dropdownId = HotKeys.button + '-' + targetIndex;
      this.selectDropdownButton();
    }
  }

  handleArrowUpInsideOfDropdown(event: KeyboardEvent) {
    event.preventDefault();
    const lastIndexOfDropdownButtons = this.getLastIndexOfDropdownButtons();
    if (!this.dropdownId) {
      this.dropdownId = HotKeys.button + '-' + lastIndexOfDropdownButtons;
      this.selectDropdownButton();
    } else {
      this.deselectDropdownButton();
      const separatedId = this.dropdownId.split('-');
      let targetIndex = Number(separatedId[separatedId.length - 1]);
      if (targetIndex <= 0) {
        targetIndex = lastIndexOfDropdownButtons;
      } else {
        targetIndex -= 1;
      }
      this.dropdownId = HotKeys.button + '-' + targetIndex;
      this.selectDropdownButton();
    }
  }

  handleEscape(event: KeyboardEvent, nextId: string) {
    const searchBarId = this.createId(HotKeys.inventorySearchBarId, 0);
    if (!nextId?.includes(HotKeys.inventorySearchBarId)) {
      event.preventDefault();
      this.focusOnId(searchBarId);
      this.highlightIonInput(searchBarId);
    }
  }

  async handleEscapeInsideOfDropdown(event: KeyboardEvent, id: string) {
    event.preventDefault();
    await this.closeDropdown();
    this.highlightDropdown(id);
  }

  getLastIndexOfDropdownButtons() {
    const buttons = document.getElementsByClassName(
      HotKeys.ellipsisPopoverButtonClass,
    );
    const numberOfButtons = Array.from(buttons).length;
    return numberOfButtons - 1;
  }

  deselectDropdownButton() {
    const element = document.getElementById(this.dropdownId);
    element.classList.remove('isSelected');
  }

  selectDropdownButton() {
    const element = document.getElementById(this.dropdownId);
    element.classList.add('isSelected');
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  async checkIfDropdownExists() {
    const popoverExists = await this.popoverController.getTop();
    if (!popoverExists) {
      this.dropdownIsOpen = false;
      this.dropdownId = '';
    } else {
      this.dropdownIsOpen = true;
    }
  }

  async closeDropdown() {
    await this.popoverController.dismiss();
    this.dropdownIsOpen = false;
    this.dropdownId = '';
  }

  async blurPreviousInput(id: string) {
    let previousColumn;
    if (id) {
      previousColumn = id.split('-')[0];
    }
    if (
      previousColumn === HotKeys.case ||
      previousColumn === HotKeys.price ||
      previousColumn === HotKeys.lineNumber
    ) {
      const previousInput = document.getElementById(id) as HTMLIonInputElement;
      const element = await previousInput?.getInputElement();
      element?.blur();
    } else {
      this.clearHighlight();
    }
  }

  clearHighlight() {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    }
  }

  findNextIdForHotKey(id: string, code: string, shiftKey: boolean): string {
    let idToFocus = '';
    if (code === HotKeys.escape) {
      idToFocus = this.createId(HotKeys.inventorySearchBarId, 0);
    } else if (
      ((code === HotKeys.enter || code === HotKeys.numPadEnter) && shiftKey) ||
      code === HotKeys.arrowUp
    ) {
      idToFocus = this.handleInventoryCardShiftEnter(id);
    } else if (
      code === HotKeys.enter ||
      code === HotKeys.numPadEnter ||
      code === HotKeys.arrowDown
    ) {
      idToFocus = this.handleInventoryCardEnter(id);
    } else if (
      (code === HotKeys.tab && shiftKey) ||
      code === HotKeys.arrowLeft
    ) {
      idToFocus = this.handleInventoryCardShiftTabKey(id);
    } else if (code === HotKeys.tab || code === HotKeys.arrowRight) {
      idToFocus = this.handleInventoryCardTabKey(id);
    }
    return idToFocus;
  }

  focusOnId(id: string) {
    const element = document.getElementById(id) as HTMLIonInputElement;
    if (element) {
      this.setFocusAndScroll(id, element);
    } else if (!element && id) {
      this.scrollAndSetFocus(id);
    }
  }

  highlightDropdown(id: string) {
    let el = document.getElementById(id);
    let range = document.createRange();
    range.selectNodeContents(el);
    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  setFocusAndScroll(id: string, element: HTMLIonInputElement) {
    try {
      element.setFocus();
      this.previousId = '';
    } catch (error) {
      this.highlightDropdown(id);
      this.setPreviousId(id);
    }

    const inventoryScrollId = HotKeys.inventoryScrollId + this.inventoryId;
    const scroll = document.getElementById(inventoryScrollId) as HTMLElement;
    const searchBarId = this.createId(HotKeys.inventorySearchBarId, 0);
    if (id === searchBarId && scroll.scrollTop > 1000) {
      // Have to scroll in interval in case we're at the bottom of a big inventory and have to go all the way to the top
      let i = 0;
      this.interval = setInterval(() => {
        scroll.scrollTop = 0;
        i++;
        if (i === 10) {
          clearInterval(this.interval);
        }
      }, 10);
    } else {
      const newScrollTop = this.findTargetScrollHeight(id) - 250;
      scroll.scrollTop = newScrollTop;
    }
  }

  scrollAndSetFocus(id: string) {
    const inventoryScrollId = HotKeys.inventoryScrollId + this.inventoryId;
    const scroll = document.getElementById(inventoryScrollId) as HTMLElement;
    scroll.scrollTop = this.findTargetScrollHeight(id);
    // Need timeout here to make sure scroll is finished before trying to get element by id
    setTimeout(() => {
      const newElement = document.getElementById(id) as HTMLIonInputElement;
      if (newElement) {
        newElement.setFocus();
      }
    }, 500);
  }

  highlightIonInput(id: string) {
    const element = document.getElementById(id) as HTMLIonInputElement;
    setTimeout(() => {
      element.getInputElement().then(input => {
        input.setSelectionRange(0, input.value.length);
      });
    }, 100);
  }

  findTargetScrollHeight(id: string) {
    const separatedId = id.split('-');
    const targetIndex = Number(separatedId[separatedId.length - 1]);
    let targetScrollHeight = 0;
    for (let i = 0; i < targetIndex; i++) {
      targetScrollHeight += this.itemHeights[i];
    }
    return targetScrollHeight;
  }

  createId(column: string, index: number) {
    return column + '-' + this.inventoryId + '-' + index;
  }

  private handleInventoryCardEnter(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentColumn = separatedId[0];
    if (currentColumn === HotKeys.search && this.isEditMode) {
      currentColumn = HotKeys.lineNumber;
    } else if (currentColumn === HotKeys.search && !this.isEditMode) {
      currentColumn = HotKeys.case;
    }
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    while (!idToFocus && currentIndex < this.itemHeights?.length) {
      currentIndex += 1;
      const newId = this.createId(currentColumn, currentIndex);
      if (this.inventoryCardIds.has(newId)) {
        return newId;
      }
    }
    return idToFocus;
  }

  private handleInventoryCardShiftEnter(id: string) {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentColumn = separatedId[0];
    if (currentColumn === HotKeys.search) {
      return idToFocus;
    }
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    while (!idToFocus && currentIndex > 0) {
      currentIndex -= 1;
      const newId = this.createId(currentColumn, currentIndex);
      if (this.inventoryCardIds.has(newId)) {
        return newId;
      }
    }
    return idToFocus;
  }

  private handleInventoryCardTabKey(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentColumn = separatedId[0];
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    currentColumn = this.tabKeyHelper(currentColumn);
    if (this.shouldIncrementTabCounter(currentColumn)) {
      currentIndex += 1;
    }
    while (!idToFocus && currentIndex < this.itemHeights?.length) {
      const newId = this.createId(currentColumn, currentIndex);
      if (this.inventoryCardIds.has(newId)) {
        return newId;
      }
      currentColumn = this.tabKeyHelper(currentColumn);
      if (this.shouldIncrementTabCounter(currentColumn)) {
        currentIndex += 1;
      }
    }
    return idToFocus;
  }

  private handleInventoryCardShiftTabKey(id: string) {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentColumn = separatedId[0];
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    currentColumn = this.shiftTabKeyHelper(currentColumn);
    if (this.shouldDecrementTabCounter(currentColumn)) {
      currentIndex -= 1;
    }
    while (!idToFocus && currentIndex > 0) {
      const newId = this.createId(currentColumn, currentIndex);
      if (this.inventoryCardIds.has(newId)) {
        return newId;
      }
      currentColumn = this.shiftTabKeyHelper(currentColumn);
      if (this.shouldDecrementTabCounter(currentColumn)) {
        currentIndex -= 1;
      }
    }
    return idToFocus;
  }

  private tabKeyHelper(currentColumn: string): string {
    let nextId = '';
    if (currentColumn === HotKeys.search && this.isEditMode) {
      nextId = HotKeys.lineNumber;
    } else if (currentColumn === HotKeys.search && !this.isEditMode) {
      nextId = HotKeys.case;
    } else if (currentColumn === HotKeys.lineNumber) {
      nextId = HotKeys.group;
    } else if (currentColumn === HotKeys.group) {
      nextId = HotKeys.description;
    } else if (currentColumn === HotKeys.description) {
      nextId = HotKeys.case;
    } else if (currentColumn === HotKeys.case && this.isEditMode) {
      nextId = HotKeys.price;
    } else if (currentColumn === HotKeys.case && !this.isEditMode) {
      nextId = HotKeys.each;
    } else if (currentColumn === HotKeys.price) {
      nextId = HotKeys.lineNumber;
    } else if (currentColumn === HotKeys.each) {
      nextId = HotKeys.case;
    } else {
      nextId = currentColumn;
    }
    return nextId;
  }

  private shouldIncrementTabCounter(currentColumn: string): boolean {
    let shouldIncrementTabCounter = false;
    if (currentColumn === HotKeys.case && !this.isEditMode) {
      shouldIncrementTabCounter = true;
    } else if (currentColumn === HotKeys.lineNumber && this.isEditMode) {
      shouldIncrementTabCounter = true;
    }
    return shouldIncrementTabCounter;
  }

  private shiftTabKeyHelper(currentColumn: string): string {
    let nextId = '';
    if (currentColumn === HotKeys.search) {
      nextId = HotKeys.lineNumber;
    } else if (currentColumn === HotKeys.lineNumber) {
      nextId = HotKeys.price;
    } else if (currentColumn === HotKeys.group) {
      nextId = HotKeys.lineNumber;
    } else if (currentColumn === HotKeys.description) {
      nextId = HotKeys.group;
    } else if (currentColumn === HotKeys.case && this.isEditMode) {
      nextId = HotKeys.description;
    } else if (currentColumn === HotKeys.case && !this.isEditMode) {
      nextId = HotKeys.each;
    } else if (currentColumn === HotKeys.price) {
      nextId = HotKeys.case;
    } else if (currentColumn === HotKeys.each) {
      nextId = HotKeys.case;
    } else {
      nextId = currentColumn;
    }
    return nextId;
  }

  private shouldDecrementTabCounter(currentColumn: string): boolean {
    let shouldDecrementTabCounter = false;
    if (currentColumn === HotKeys.price) {
      shouldDecrementTabCounter = true;
    } else if (currentColumn === HotKeys.each) {
      shouldDecrementTabCounter = true;
    }
    return shouldDecrementTabCounter;
  }
}
