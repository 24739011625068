import { ItemTypes } from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import {
  InventoryGroupLabelViewModel,
  InventoryGroupSortViewModel,
  InventoryGroupViewModel,
  InventoryItem,
  InventoryProductViewModel,
  InventoryViewModel,
} from '@inventory/models/inventory-view.model';
import { PlatformEnum } from '@panamax/app-state';
import { InventoryGroup, InventoryGroupItem } from '@usf/inventory-types';
import {
  InventoryOptions,
  InventoryGroupHeader,
  InventorySelection,
} from '@usf/ngrx-inventory';
import {
  InventorySort,
  InventoryState,
} from '@usf/ngrx-inventory/lib/models/inventory/inventory.model';
import {
  convertInventoryWorksheetSortHeaders,
  productMatchesKeyword,
  sortInventory,
} from './inventory-view-model-sort-filter-helper';
import { Product } from '@shared/models/product.model';
import {
  extractPackSizeValue,
  isConversionValueValid,
} from './inventory-create-update-helper';
import { HotKeys } from '../../../shared/constants/hot-key.enum';
import { transformNonUsfProductToProduct } from '@inventory/pages/manage-non-usf-products/transformers/non-usf-products-transformers';
import { GeneralLedger } from '@usf/product-types';
import { Dictionary } from '@ngrx/entity';
import { GlPim } from '@usf/ngrx-gl';
import { getGlStringForProduct } from '../../../shared/selectors/helpers/product-info.selectors.helper';

export const combineMapAndArrayForNonUSFProducts = (
  products: Map<number, Product>,
  nonUSFProducts: Product[],
) => {
  let fullProdMap = new Map<number, Product>();
  products.forEach((value, key) => {
    fullProdMap.set(key, value);
  });
  nonUSFProducts.forEach(val => {
    fullProdMap.set(val.productNumber, val);
  });
  return fullProdMap;
};

export interface InventoryViewModelConfig {
  isCount?: boolean;
  platform?: PlatformEnum;
  isNotWorksheet?: boolean;
}

export const convertToInventoryViewModel = (
  inventory: InventoryState,
  options: InventoryOptions,
  isInventoryWorksheet: boolean,
  products: Map<number, Product>,
  glPimDictionary: Dictionary<GlPim>,
  config?: InventoryViewModelConfig,
  ignoreSearch = false,
) => {
  let isCount = config?.isCount;
  let platform = config?.platform;
  let isNotWorksheet = !!config?.isNotWorksheet;

  let totalPrice = 0;
  let numProductsByQuantities = 0;
  let numProducts = 0;
  let lineNumberCounter = 1;

  let inventoryViewModelToReturn = getInventoryViewModelToReturn(
    inventory,
    options,
  );

  if (inventory.groups) {
    inventory.groups?.forEach((group, index) => {
      if (group) {
        let inventoryHeaderToInject = getInventoryGroupHeaderToInject(
          group,
          index,
        );
        inventoryViewModelToReturn.groupHeaders.push(inventoryHeaderToInject);
        let inventoryGroupToInject = getInventoryGroupToInject(group, index);

        if (group.groupItems) {
          numProducts += group.groupItems.length;
          let tempProductsArray: InventoryProductViewModel[] = [];

          let absoluteItemIndex = 0;

          group.groupItems.forEach(item => {
            if (products.has(item.itemNumber) || isNotWorksheet) {
              // transformNonUsfProductToProduct is used for when we don't actually need product data
              const product = isNotWorksheet
                ? transformNonUsfProductToProduct({
                    divisionNumber: inventory.divisionNumber,
                    customerNumber: inventory.customerNumber,
                    departmentNumber: inventory.departmentNumber,
                    productDescLong: '',
                    productNumber: item.itemNumber,
                    isSelected: false,
                    vendorName: '',
                  })
                : products.get(item.itemNumber);
              let productToInject: InventoryProductViewModel = getInventoryProductToInject(
                group,
                item,
                lineNumberCounter,
                absoluteItemIndex,
                product,
                glPimDictionary,
              );

              // set cards to selected if they are found in the selection array
              productToInject = getSelectionForCard(
                options.selections,
                productToInject,
                inventory.inventoryId,
              );

              // add product to items if no search key or has search key and matches keyword
              if (
                productMatchesKeyword(
                  options.searchKey,
                  productToInject?.productName,
                  productToInject?.productNumber,
                  productToInject?.productBrand,
                ) ||
                ignoreSearch
              ) {
                tempProductsArray.push(productToInject);
              }

              totalPrice += productToInject.value;
              lineNumberCounter++;

              if (
                productToInject.caseOnHand.currentValue > 0 ||
                productToInject.eachOnHand.currentValue > 0
              ) {
                numProductsByQuantities++;
                inventoryGroupToInject.totalCases +=
                  productToInject.caseOnHand.currentValue;
                inventoryGroupToInject.totalEaches +=
                  productToInject.eachOnHand.currentValue;
              }
              inventoryGroupToInject.totalPrice += productToInject.value;
            }
            absoluteItemIndex++;
          });

          if (tempProductsArray.length) {
            inventoryGroupToInject.hasSearchResults = true;
          }
          if (
            shouldAddGroupToInventoryItems(options, inventoryGroupToInject) ||
            ignoreSearch
          ) {
            inventoryViewModelToReturn.items.push(inventoryGroupToInject);

            if (
              shouldAddSortToInventoryItems(
                isInventoryWorksheet,
                inventoryGroupToInject,
              )
            ) {
              let inventoryGroupSortToInject: InventoryGroupSortViewModel;
              inventoryGroupSortToInject = getInventoryGroupSortToInject(
                platform,
                isCount
                  ? inventory.inventoryWorksheetCountSort
                  : inventory.inventoryWorksheetEditSort,
                inventoryGroupToInject,
                isCount,
              );
              tempProductsArray = sortInventory(
                isCount
                  ? inventory.inventoryWorksheetCountSort
                  : inventory.inventoryWorksheetEditSort,
                inventoryGroupToInject.groupName,
                tempProductsArray,
              );
              if (platform === PlatformEnum.desktop) {
                inventoryViewModelToReturn.items.push(
                  inventoryGroupSortToInject,
                );
              }
            }

            if (
              platform === PlatformEnum.tablet &&
              !isCount &&
              inventoryGroupToInject.hasProducts
            ) {
              inventoryViewModelToReturn.items.push(
                getGroupLabel(inventoryGroupToInject),
              );
            }
          }

          inventoryViewModelToReturn.items.push(...tempProductsArray);
        } else {
          inventoryViewModelToReturn.items.push(inventoryGroupToInject);
        }

        inventoryViewModelToReturn.totalCases +=
          inventoryGroupToInject.totalCases;
        inventoryViewModelToReturn.totalEaches +=
          inventoryGroupToInject.totalEaches;
      }
    });
  }

  inventoryViewModelToReturn.totalPrice = totalPrice;
  inventoryViewModelToReturn.numProductsByQuantities = numProductsByQuantities;
  inventoryViewModelToReturn.numProducts = numProducts;
  return inventoryViewModelToReturn;
};

export const getInventoryCardIds = (
  items: InventoryItem[],
  isEditMode: boolean,
  inventoryId: string,
): Set<string> => {
  let inventoryCardIds: Set<string> = new Set();
  let i = 0;
  for (const item of items) {
    if (item.itemType === ItemTypes.product) {
      const castedItem = item as InventoryProductViewModel;
      if (!castedItem.productCatchWeightFlag) {
        const caseId = HotKeys.case + '-' + inventoryId + '-' + i;
        inventoryCardIds.add(caseId);
      }
      if (isEditMode) {
        const groupId = HotKeys.group + '-' + inventoryId + '-' + i;
        const priceId = HotKeys.price + '-' + inventoryId + '-' + i;
        const descriptionId = HotKeys.description + '-' + inventoryId + '-' + i;
        const lineNumberId = HotKeys.lineNumber + '-' + inventoryId + '-' + i;
        inventoryCardIds.add(lineNumberId);
        inventoryCardIds.add(groupId);
        inventoryCardIds.add(priceId);
        inventoryCardIds.add(descriptionId);
      } else {
        const eachId = HotKeys.each + '-' + inventoryId + '-' + i;
        inventoryCardIds.add(eachId);
      }
    }
    i++;
  }

  return inventoryCardIds;
};

export const getInventoryViewModelToReturn = (
  inventory: InventoryState,
  options: InventoryOptions,
): InventoryViewModel => {
  return {
    inventoryId: inventory.inventoryId,
    inventoryName: inventory.inventoryName,
    inventoryDate: inventory.inventoryDate,
    divisionNumber: inventory.divisionNumber,
    customerNumber: inventory.customerNumber,
    departmentNumber: inventory.departmentNumber,
    comment: inventory.comment,
    priceOption: inventory.priceOption,
    updatedByUser: inventory.updatedByUser,
    createdByUser: inventory.createdByUser,
    ecomCreatedUserId: inventory.ecomCreatedUserId,
    ecomCreatedDtm: inventory.ecomCreatedDtm,
    ecomUpdateUserId: inventory.ecomUpdateUserId,
    ecomUpdateDtm: inventory.ecomUpdateDtm,
    completedDtm: inventory.completedDtm,
    inventoryOptions: options,
    inventoryWorksheetCountSort: inventory.inventoryWorksheetCountSort,
    inventoryWorksheetEditSort: inventory.inventoryWorksheetEditSort,
    totalPrice: 0.0,
    totalCases: 0,
    totalEaches: 0,
    numProductsByQuantities: 0,
    numProducts: 0,
    groupHeaders: [],
    items: [],
    itemHeights: [],
    status: inventory.status,
    isSelected: inventory.isSelected,
    inventoryCardIds: new Set(),
    sourceSystem: inventory.sourceSystem,
  };
};

export const getInventoryGroupHeaderToInject = (
  group: InventoryGroup,
  index: number,
) => {
  return {
    groupName: group.groupName,
    numProducts: group.groupItems?.length,
    groupAbsoluteIndex: index,
  } as InventoryGroupHeader;
};

export const getInventoryGroupToInject = (
  group: InventoryGroup,
  index: number,
): InventoryGroupViewModel => {
  return {
    groupName: group.groupName,
    groupSequenceNumber: index + 1,
    groupAbsoluteIndex: index,
    groupUpdateDtm: group.groupUpdateDtm,
    totalPrice: 0,
    totalCases: 0,
    totalEaches: 0,
    numProducts: group.groupItems?.length,
    hasProducts: group.groupItems && group.groupItems?.length > 0,
    hasSearchResults: false,
    itemType: ItemTypes.group,
  };
};

export const getInventoryGroupSortToInject = (
  platform: PlatformEnum,
  inventoryWorksheetSort: InventorySort,
  group: InventoryGroupViewModel,
  isCount: boolean,
): InventoryGroupSortViewModel => {
  let size = 12;
  let offset = 0;

  if (!isCount) {
    if (platform === PlatformEnum.desktop) {
      size = 11.6;
      offset = 0.4;
    } else if (platform === PlatformEnum.tablet) {
      size = 11.35;
      offset = 0.65;
    }
  }

  let inventoryGroupSortViewModel = {
    groupName: group?.groupName,
    selectedHeader: inventoryWorksheetSort[group.groupName]?.selectedHeader,
    headers: convertInventoryWorksheetSortHeaders(
      platform,
      inventoryWorksheetSort[group.groupName]?.headers,
      isCount,
    ),
    sortHeaderColumnSize: size,
    sortHeaderOffsetSize: offset,
    itemType: ItemTypes.sort,
  };

  return inventoryGroupSortViewModel;
};

export const getGroupLabel = (
  group: InventoryGroupViewModel,
): InventoryGroupLabelViewModel => {
  return {
    groupName: group?.groupName,
    itemType: ItemTypes.justALabel,
  };
};

export const shouldAddGroupToInventoryItems = (
  options: InventoryOptions,
  inventoryGroupToInject: InventoryGroupViewModel,
) => {
  return options.searchKey && !inventoryGroupToInject.hasSearchResults
    ? false
    : true;
};

export const shouldAddSortToInventoryItems = (
  isInventoryWorksheet: boolean,
  inventoryGroupToInject: InventoryGroupViewModel,
) => {
  return !(isInventoryWorksheet && inventoryGroupToInject.hasProducts)
    ? false
    : true;
};

export const getInventoryProductToInject = (
  group: InventoryGroup,
  item: InventoryGroupItem,
  lineNumberCounter: number,
  index: number,
  product: Product,
  glPimDictionary: Dictionary<GlPim>,
): InventoryProductViewModel => {
  let unitPrice: number;
  let casePrice: number;
  let productConversion: number;
  let finalConversion: number;
  let marketBreak = false;
  let productMissing = false;

  // break out function, return above values as object
  if (item.productCatchWeightFlag) {
    unitPrice =
      item.priceOverrideInd === 'Y'
        ? item.overrideCasePrice
        : item.productUnitPrice;
    casePrice = -1;
    productConversion = 1;
    if (unitPrice === 0 || unitPrice === undefined) {
      unitPrice = -1;
    }
  } else {
    casePrice =
      item.priceOverrideInd === 'Y'
        ? item.overrideCasePrice
        : item.productCasePrice;
    if (casePrice === 0 || casePrice === undefined) {
      casePrice = -1;
    }

    if (!item.productBreakable && !item.nonUsfFlag) {
      productConversion = extractPackSizeValue(item.productPackSizeLabel);
    } else {
      productConversion = isConversionValueValid(item.productConvFactor)
        ? item.productConvFactor
        : extractPackSizeValue(item.productPackSizeLabel);
    }

    finalConversion = isConversionValueValid(item.invConvFactor)
      ? item.invConvFactor
      : productConversion;

    if (
      item.productBreakable &&
      item.productUnitPrice !== -1 &&
      item.productUnitPrice > 0 &&
      item.priceOverrideInd === 'N' &&
      !isConversionValueValid(item.invConvFactor)
    ) {
      finalConversion = extractPackSizeValue(item.productPackSizeLabel);
      unitPrice = item.productUnitPrice;
      marketBreak = true;
    } else if (isConversionValueValid(finalConversion) && casePrice !== -1) {
      unitPrice = casePrice / finalConversion;
    } else {
      unitPrice = -1;
    }
  }

  if (item.nonUsfFlag && !product.summary.productDescTxtl) {
    productMissing = true;
  }
  if (Number.isNaN(finalConversion)) {
    finalConversion = 1;
  }

  if (unitPrice === null || Number.isNaN(unitPrice) || unitPrice === 0) {
    unitPrice = -1;
  }

  if (casePrice === null || Number.isNaN(casePrice) || unitPrice === 0) {
    casePrice = -1;
  }

  let itemToEnrich = {
    productNumber: item.itemNumber,
    productName: product.summary.productDescTxtl,
    groupName: group.groupName,
    itemAbsoluteIndex: index,
    itemSequenceNumber: lineNumberCounter,
    priceOverrideInd: item.priceOverrideInd,
    caseOnHand: {
      currentValue: item.caseOnHand ? item.caseOnHand : 0,
      previousValue: item.caseOnHand ? item.caseOnHand : 0,
      loading: false,
      error: undefined,
    },
    eachOnHand: {
      currentValue: item.eachOnHand ? item.eachOnHand : 0,
      previousValue: item.eachOnHand ? item.eachOnHand : 0,
      loading: false,
      error: undefined,
    },
    caseOrd: item.caseOrd ? item.caseOrd : 0,
    eachOrd: item.eachOrd ? item.eachOrd : 0,
    invConvFactor: item.invConvFactor,
    glString: getGlStringForProduct(product.summary, glPimDictionary),
    overrideCasePrice: item.overrideCasePrice,
    unitPrice: unitPrice,
    casePrice: casePrice,
    value: 0,
    total: '0',
    itemType: ItemTypes.product,
    uom: item.convFactorUnit ? item.convFactorUnit : item.productUnitOfMeasure,
    customUOM: item.customConvFactorUnit,
    displayConvFactor: finalConversion,
    isSelected: false,
    productConvFactor: item.productConvFactor,
    productCasePrice: item.productCasePrice,
    productUnitPrice: item.productUnitPrice,
    productBreakable: item.productBreakable,
    productPackSizeLabel: item.productPackSizeLabel,
    productImageThumbnail: product.imageThumbnail,
    productBrand: product.summary.brand,
    productCatchWeightFlag: item.productCatchWeightFlag,
    productMarketBreakFlag: marketBreak,
    productBadPricingCaseFlag: casePrice === -1 && !item.productCatchWeightFlag,
    productBadPricingUnitFlag: unitPrice === -1,
    nonUSFFlag: item.nonUsfFlag ?? false,
    productManufacturerNumber:
      product.nonUSFData?.manufacturerProductNumber ?? '',
    productMissingFlag: productMissing,
    vendorName: product.nonUSFData?.vendorName ?? '',
    overrideFlag: item.priceOverrideInd === 'Y' && item.nonUsfFlag === false,
  };

  // break out to own function
  if (itemToEnrich.productCatchWeightFlag) {
    itemToEnrich.total = itemToEnrich.eachOnHand.currentValue.toString();
    if (
      itemToEnrich.unitPrice !== -1 &&
      itemToEnrich.eachOnHand.currentValue > 0
    ) {
      itemToEnrich.value +=
        itemToEnrich.eachOnHand.currentValue * itemToEnrich.unitPrice;
    }
  } else {
    let totalVal = 0;
    totalVal += itemToEnrich.caseOnHand.currentValue;
    totalVal += itemToEnrich.eachOnHand.currentValue / finalConversion;
    itemToEnrich.total = new Number(totalVal).toFixed(2).valueOf();
    if (
      itemToEnrich.casePrice !== -1 &&
      itemToEnrich.caseOnHand.currentValue > 0
    ) {
      itemToEnrich.value +=
        itemToEnrich.casePrice * itemToEnrich.caseOnHand.currentValue;
    }

    if (
      itemToEnrich.unitPrice !== -1 &&
      itemToEnrich.eachOnHand.currentValue > 0
    ) {
      itemToEnrich.value +=
        itemToEnrich.eachOnHand.currentValue * itemToEnrich.unitPrice;
    }
  }

  return itemToEnrich;
};

export const getSelectionForCard = (
  selections: InventorySelection[],
  item: InventoryProductViewModel,
  inventoryId: string,
) => {
  // change to a key value pair later in ngrx - like how we access headers or master list product.
  const selection = selections.find(
    value =>
      value.inventoryId === inventoryId &&
      value.groupName === item.groupName &&
      value.inventoryItemId === item.productNumber,
  );
  if (selection) {
    item.isSelected = selection.selection;
  }
  return item;
};

export const getProductCombinationMapForReports = (
  invWorksheetViewModels: InventoryViewModel[],
  productsMap: Map<number, Product>,
  nonUSFProducts: Product[],
) => {
  const deletedProducts = [];
  invWorksheetViewModels.forEach(
    (invWorksheetViewModel: InventoryViewModel) => {
      invWorksheetViewModel.items
        .filter(item => {
          return item.itemType === ItemTypes.product;
        })
        .forEach(item => {
          const itemNumber = (item as InventoryProductViewModel)?.productNumber;
          if (
            !productsMap.has(itemNumber) &&
            !nonUSFProducts.some(non => non.productNumber === itemNumber)
          ) {
            deletedProducts.push(
              transformNonUsfProductToProduct({
                divisionNumber: invWorksheetViewModel.divisionNumber,
                customerNumber: invWorksheetViewModel.customerNumber,
                departmentNumber: invWorksheetViewModel.departmentNumber,
                productDescLong: '',
                productNumber: itemNumber,
                isSelected: false,
                vendorName: '',
              }),
            );
          }
        });
    },
  );

  return combineMapAndArrayForNonUSFProducts(productsMap, [
    ...nonUSFProducts,
    ...deletedProducts,
  ]);
};
