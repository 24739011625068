<ion-header class="ion-no-border ion-header-modal-mobile">
  <ion-toolbar>
    <ion-label data-cy="sort-vendor-label">
      {{ 'i18n.vendors.sortVendors' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-content-modal-mobile">
  <ion-radio-group [(ngModel)]="selectedSort">
    <ion-item-divider></ion-item-divider>
    <ion-item>
      <ion-label id="vendorId-asc" data-cy="vendor-sort-vendor-asc-label">
        {{ 'i18n.vendors.codeAsc' | translate }}
      </ion-label>
      <ion-radio
        aria-labelledby="vendorId-asc"
        mode="md"
        slot="end"
        value="vendorId-asc"
        data-cy="vendor-sort-vendor-asc-radio"
      ></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label id="vendorId-desc" data-cy="vendor-sort-vendor-desc-label">
        {{ 'i18n.vendors.codeDesc' | translate }}
      </ion-label>
      <ion-radio
        aria-labelledby="vendorId-desc"
        mode="md"
        slot="end"
        value="vendorId-desc"
        data-cy="vendor-sort-vendor-desc-radio"
      ></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label
        id="distributorName-asc"
        data-cy="vendor-sort-distributor-name-asc-label"
      >
        {{ 'i18n.vendors.vendorNameAsc' | translate }}
      </ion-label>
      <ion-radio
        aria-labelledby="distributorName-asc"
        mode="md"
        slot="end"
        value="distributorName-asc"
        data-cy="vendor-sort-distributor-name-asc-radio"
      ></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label
        id="distributorName-desc"
        data-cy="vendor-sort-distributor-name-desc-label"
      >
        {{ 'i18n.vendors.vendorNameDesc' | translate }}
      </ion-label>
      <ion-radio
        aria-labelledby="distributorName-desc"
        mode="md"
        slot="end"
        value="distributorName-desc"
        data-cy="vendor-sort-distributor-name-desc-radio"
      ></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label id="contactName-asc" data-cy="vendor-sort-name-asc-label">
        {{ 'i18n.vendors.contactNameAsc' | translate }}
      </ion-label>
      <ion-radio
        aria-labelledby="contactName-asc"
        mode="md"
        slot="end"
        value="contactName-asc"
        data-cy="vendor-sort-name-asc-radio"
      ></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label id="contactName-desc" data-cy="vendor-sort-name-desc-label">
        {{ 'i18n.vendors.contactNameDesc' | translate }}
      </ion-label>
      <ion-radio
        aria-labelledby="contactName-desc"
        mode="md"
        slot="end"
        value="contactName-desc"
        data-cy="vendor-sort-name-desc-radio"
      ></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label id="city-asc" data-cy="vendor-sort-city-asc-label">
        {{ 'i18n.vendors.cityStateAsc' | translate }}
      </ion-label>
      <ion-radio
        aria-labelledby="city-asc"
        mode="md"
        slot="end"
        value="city-asc"
        data-cy="vendor-sort-city-asc-radio"
      ></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label id="city-desc" data-cy="vendor-sort-city-desc-label">
        {{ 'i18n.vendors.cityStateDesc' | translate }}
      </ion-label>
      <ion-radio
        aria-labelledby="city-desc"
        mode="md"
        slot="end"
        value="city-desc"
        data-cy="vendor-sort-city-desc-radio"
      ></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label id="telephoneNumber-asc" data-cy="vendor-sort-phone-asc-label">
        {{ 'i18n.vendors.phoneAsc' | translate }}
      </ion-label>
      <ion-radio
        aria-labelledby="telephoneNumber-asc"
        mode="md"
        slot="end"
        value="telephoneNumber-asc"
        data-cy="vendor-sort-phone-asc-radio"
      ></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label
        id="telephoneNumber-desc"
        data-cy="vendor-sort-phone-desc-label"
      >
        {{ 'i18n.vendors.phoneDesc' | translate }}
      </ion-label>
      <ion-radio
        aria-labelledby="telephoneNumber-desc"
        mode="md"
        slot="end"
        value="telephoneNumber-desc"
        data-cy="vendor-sort-phone-desc-radio"
      ></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label id="email-asc" data-cy="vendor-sort-email-asc-label">
        {{ 'i18n.vendors.emailAsc' | translate }}
      </ion-label>
      <ion-radio
        aria-labelledby="email-asc"
        mode="md"
        slot="end"
        value="email-asc"
        data-cy="vendor-sort-email-asc-radio"
      ></ion-radio>
    </ion-item>
    <ion-item>
      <ion-label id="email-desc" data-cy="vendor-sort-email-desc-label">
        {{ 'i18n.vendors.emailDesc' | translate }}
      </ion-label>
      <ion-radio
        aria-labelledby="email-desc"
        mode="md"
        slot="end"
        value="email-desc"
        data-cy="vendor-sort-email-desc-radio"
      ></ion-radio>
    </ion-item>
  </ion-radio-group>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="cancel-modal-btn"
        data-cy="save-vendor-sort-modal-button"
        class="usf-outline-green-mobile-modal-button"
        (click)="dismiss()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        id="save-modal-btn"
        data-cy="save-vendor-sort-modal-button"
        class="usf-fill-green-mobile-modal-button"
        (click)="onSaveSort()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
