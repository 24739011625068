import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zipCodeFormat',
})
export class ZipCodeFormatPipe implements PipeTransform {
  transform(value: string | null): string {
    if (!value) return '';
    return value.length === 4 ? `0${value}` : value;
  }
}
