import { selectedCustomer } from '@app/ngrx-customer/store';
import { createSelector } from '@ngrx/store';
import {
  MerchZoneProductInfo,
  MerchZoneViewModel,
} from '@order/models/merch-zone-view-model';
import { selectOnline } from '@panamax/app-state';
import { ImageVariantEnum } from '@product-detail/models/image-variant.enum';
import { getFirstImageURL } from '@product-detail/utils/product-images.util';
import {
  MERCH_ZONE_MAX_PRODUCTS,
  ReviewOrderMerchZone,
} from '@shared/constants/merchandising-constants';
import { PageTitle } from '@shared/constants/page-titles.enum';
import { ProductFilteringEnum } from '@shared/constants/product-filtering.enum';
import { UsfProductCardModeEnum } from '@shared/constants/usf-product-card-mode.enum';
import { ProductCardModelHelper } from '@shared/helpers/product-card-model-helper.service';
import { ProductFilter } from '@shared/helpers/product-filter.helpers';
import { productWithAlternativesSelector } from '@shared/selectors/product.selectors';
import { orderItemSelectors } from '@usf/ngrx-order';
import { selectMerchandisingResponseById } from '@usf/ngrx-product';

export const selectMerchZoneViewModel = (isMerchZoneEnabled: boolean) =>
  createSelector(
    selectedCustomer,
    selectMerchandisingResponseById(ReviewOrderMerchZone.experience),
    orderItemSelectors.selectOrderItemState,
    productWithAlternativesSelector,
    selectOnline(),
    (
      selectedCustomer,
      merchandisingState,
      orderItemState,
      products,
      online,
    ): MerchZoneViewModel => {
      if (!isMerchZoneEnabled) {
        return {
          products: [],
          pageTitle: null,
          displayText: null,
          breadcrumbs: [],
          variant: null,
        };
      }

      const merchZoneProductInfos: MerchZoneProductInfo[] = [];

      if (!!merchandisingState?.products) {
        merchandisingState?.products?.forEach(merchandisingProduct => {
          const product = products?.get(merchandisingProduct?.productNumber);

          if (
            !!product &&
            ProductFilter.applyFilter(
              product,
              selectedCustomer,
              ProductFilteringEnum.REVIEW_ORDER_MERCH_ZONE,
            ) &&
            product?.summary?.proprietaryInd !== 'Y'
          ) {
            const merchZoneProductInfo: MerchZoneProductInfo = {
              product,
              productCardVM: ProductCardModelHelper.create(
                product,
                orderItemState,
                online,
                selectedCustomer,
                merchandisingState.variant ===
                  ReviewOrderMerchZone.topSellerVariant
                  ? UsfProductCardModeEnum.topSeller
                  : UsfProductCardModeEnum.trendingNow,
              ),
              recToken: merchandisingProduct?.recToken,
              imageUrl: getFirstImageURL(
                product?.summary,
                ImageVariantEnum.Small,
              ),
            };
            merchZoneProductInfos.push(merchZoneProductInfo);
          }
        });
      }
      const merchZoneViewModel: MerchZoneViewModel = {
        products: merchZoneProductInfos.slice(0, MERCH_ZONE_MAX_PRODUCTS),
        ...setTextFromVariant(merchandisingState?.variant),
      };
      return merchZoneViewModel;
    },
  );

const setTextFromVariant = (variant: string) => {
  if (variant === ReviewOrderMerchZone.topSellerVariant) {
    return {
      pageTitle: PageTitle.topSellers,
      displayText: {
        headerText: 'i18n.orderPage.topSellers',
        subHeaderText: 'i18n.orderPage.dontMissOut',
      },
      breadcrumbs: [
        {
          label: 'i18n.breadcrumbs.home',
          url: '/desktop/home',
          active: false,
        },
        {
          label: 'i18n.breadcrumbs.topSellers',
          url: '',
          active: true,
        },
      ],
      variant: ReviewOrderMerchZone.topSellerVariant,
    };
  } else if (variant === ReviewOrderMerchZone.trendingNowVariant) {
    return {
      pageTitle: PageTitle.trendingNow,
      displayText: {
        headerText: 'i18n.orderPage.trendingNow',
        subHeaderText: 'i18n.orderPage.recommendedProducts',
      },
      breadcrumbs: [
        {
          label: 'i18n.breadcrumbs.home',
          url: '/desktop/home',
          active: false,
        },
        {
          label: 'i18n.breadcrumbs.trendingNow',
          url: '',
          active: true,
        },
      ],
      variant: ReviewOrderMerchZone.trendingNowVariant,
    };
  }
};

export const selectMerchZoneProductNumbers = createSelector(
  selectMerchandisingResponseById(ReviewOrderMerchZone.experience),
  (merchandisingState): number[] => {
    return !!merchandisingState?.products
      ? merchandisingState.products
          .map(product => product?.productNumber)
          ?.slice(0, MERCH_ZONE_MAX_PRODUCTS)
      : [];
  },
);
