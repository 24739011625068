import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectedCustomerState } from '@panamax/app-state';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { RefreshToken } from '../models/refresh-token';

@Injectable({
  providedIn: 'root',
})
export class LoginServiceHandlerService {
  constructor(private http: HttpClient) {}

  refreshAuthToken<T>(
    customer: SelectedCustomerState,
    refreshToken: string,
    platform: string,
    departmentNumber?: string,
  ): Observable<T> {
    const requestOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        'consumer-id': 'ecomr4',
        'correlation-id': uuidv4(),
      }),
      withCredentials: false,
    };

    const token: RefreshToken = {
      username: null,
      password: null,
      grantType: 'refreshToken',
      scopes:
        'usf-user usf-customer usf-list usf-order usf-product usf-alert usf-payment',
      refreshToken,
      platform,
      authContext: {
        divisionNumber: customer.divisionNumber,
        customerNumber: customer.customerNumber,
        departmentNumber: !!departmentNumber
          ? parseInt(departmentNumber, 10)
          : 0,
      },
    };
    return this.http
      .post<T>(`${environment.authApiUrl}`, token, { ...requestOptions })
      .pipe(catchError(this.handleError));
  }

  /**
   * @param errror - error response received from the api request
   * @returns empty Observable
   */
  private handleError(error: Response | any) {
    return throwError(error);
  }
}
