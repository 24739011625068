<ion-header
  class="ion-no-border"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-header-modal-tablet'
      : 'ion-header-modal-mobile'
  "
>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        id="back-modal-btn"
        data-cy="create-list-back-modal-button"
        text=""
      ></ion-back-button>
    </ion-buttons>
    <ion-label data-cy="create-list-option-text">{{
      selectedCreateListOption
    }}</ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="create-list-input_content"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-content-modal-tablet'
      : 'ion-content-modal-mobile'
  "
>
  <div class="create-list-memos">
    <span
      *ngIf="selectedCreateListOption === CreateListOptions.newList"
      data-cy="blank-list-label"
    >
      {{ 'i18n.lists.startWithBlankList' | translate }}
    </span>
  </div>
  <div class="create-list-common-container">
    <ion-item
      class="create-list-name"
      [class.list-name-exists]="listNameExists"
    >
      <ion-input
        placeholder="{{ 'i18n.lists.enterName' | translate }}"
        label="{{ 'i18n.lists.newListName' | translate }}"
        label-placement="floating"
        id="usf-line-input-style"
        data-cy="list-name-input"
        maxlength="30"
        [(ngModel)]="listName"
        (ngModelChange)="getListNameExists()"
        data-cy="new-list-name-input"
      ></ion-input>
    </ion-item>
    <p
      *ngIf="listNameExists"
      class="list-name-exists-error-text"
      data-cy="list-name-exists-message-text"
    >
      {{ 'i18n.lists.listNameExists' | translate }}
    </p>
    <div
      *ngIf="listData?.userKind === UserKinds.Internal"
      class="create-list-type"
    >
      <ion-list lines="none">
        <ion-label data-cy="list-type-label">
          {{ 'i18n.lists.listType' | translate }}
        </ion-label>
        <ion-radio-group [(ngModel)]="listType">
          <ion-item>
            <ion-label id="public-list" data-cy="public-list-type-label">
              {{ 'i18n.lists.public' | translate }}
            </ion-label>
            <ion-radio
              aria-labelledby="public-list"
              mode="md"
              slot="start"
              value="{{ ListTypes.public }}"
              data-cy="public-list-type-radio"
            ></ion-radio>
          </ion-item>
          <ion-item>
            <ion-label id="internal-list" data-cy="internal-list-type-label">
              {{ 'i18n.lists.internal' | translate }}
            </ion-label>
            <ion-radio
              aria-labelledby="internal-list"
              mode="md"
              slot="start"
              value="{{ ListTypes.internal }}"
              data-cy="internal-list-type-radio"
            ></ion-radio>
          </ion-item>
          <ion-item>
            <ion-label id="private-list" data-cy="private-list-type-label">
              {{ 'i18n.lists.private' | translate }}
            </ion-label>
            <ion-radio
              aria-labelledby="private-list"
              mode="md"
              slot="start"
              value="{{ ListTypes.private }}"
              data-cy="private-list-type-radio"
            ></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="create-modal-btn"
        data-cy="create-list-modal-button"
        [ngClass]="
          platformService?.platformType ===
          platformService?.platformEnum?.tablet
            ? !trimmedListName || !listType || listNameExists
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : !trimmedListName || !listType || listNameExists
            ? 'usf-fill-disabled-mobile-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="onCreateList()"
      >
        {{ 'i18n.common.create' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
