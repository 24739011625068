<ion-header
  class="ion-no-border"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-header-modal-tablet'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="
        platformService?.platformType === platformService?.platformEnum?.desktop
      "
      slot="end"
    >
      <ion-button
        id="close-modal-btn"
        data-cy="selected-items-modal-button"
        (click)="dismissModal()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label data-cy="selected-items-label">
      {{
        (inventory?.inventoryOptions?.selections?.length > 1
          ? 'i18n.lists.selectedProducts'
          : 'i18n.lists.selectedProduct'
        ) | translate
      }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="selected-items_content"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? 'ion-content-modal-tablet'
      : 'ion-content-modal-desktop'
  "
  tabletTouchMove
>
  <ion-grid>
    <ion-row>
      <!-- SELECTED PRODUCT ACTIONS -->
      <ion-col size="5" class="selected-items-actions">
        <p>
          {{ 'i18n.common.actions' | translate }}
        </p>
        <ion-item
          *ngIf="inventory?.groupHeaders?.length > 1"
          detail
          (click)="changeSelectedItemsAction(InventoryActions.moveToGroup)"
          [class.selected]="selectedAction === InventoryActions.moveToGroup"
          data-cy="click-move-selected-item"
        >
          <ion-label class="action-button" data-cy="move-selected-label">
            {{ 'i18n.inventory.inventoryWorksheet.moveToGroup' | translate }}
          </ion-label>
        </ion-item>
        <ion-item
          *ngIf="inventory?.groupHeaders?.length > 1"
          detail
          (click)="changeSelectedItemsAction(InventoryActions.copyToGroup)"
          [class.selected]="selectedAction === InventoryActions.copyToGroup"
          data-cy="click-copy-selected-item"
        >
          <ion-label class="action-button" data-cy="copy-selected-label">
            {{ 'i18n.inventory.inventoryWorksheet.copyToGroup' | translate }}
          </ion-label>
        </ion-item>
        <ion-item
          *ngIf="!this.inventoryWorksheetService.allCatchWeight"
          detail
          (click)="changeSelectedItemsAction(InventoryActions.changeUnits)"
          [class.selected]="selectedAction === InventoryActions.changeUnits"
          data-cy="click-change-units-selected-item"
        >
          <ion-label
            class="action-button"
            data-cy="change-units-selected-label"
          >
            {{ 'i18n.inventory.inventoryWorksheet.changeUnits' | translate }}
          </ion-label>
        </ion-item>
      </ion-col>

      <!-- SELECTED PRODUCT INPUT -->
      <ion-col offset="1.5" size="5.5" class="selected-items-input">
        <!-- MOVE/COPY -->
        <div
          *ngIf="selectedAction !== InventoryActions.changeUnits"
          class="selected-items-memos"
        >
          <span data-cy="select-product-memo-copy-text">
            {{ 'i18n.inventory.inventoryWorksheet.currentGroup' | translate }}
          </span>
          <ion-item class="current-group-text">
            <ion-label>
              {{ currentGroupText }}
            </ion-label>
          </ion-item>
          <span
            *ngIf="selectedAction === InventoryActions.moveToGroup"
            data-cy="select-product-memo-move-text"
          >
            {{
              'i18n.inventory.inventoryWorksheet.moveToGroupText' | translate
            }}
            {{
              (inventory?.inventoryOptions?.selections?.length > 1
                ? 'i18n.inventory.inventoryWorksheet.theseItems'
                : 'i18n.inventory.inventoryWorksheet.thisItem'
              ) | translate
            }}
          </span>
          <span
            *ngIf="selectedAction === InventoryActions.copyToGroup"
            data-cy="select-product-memo-copy-text"
          >
            {{
              'i18n.inventory.inventoryWorksheet.copyToGroupText' | translate
            }}
            {{
              (inventory?.inventoryOptions?.selections?.length > 1
                ? 'i18n.inventory.inventoryWorksheet.theseItems'
                : 'i18n.inventory.inventoryWorksheet.thisItem'
              ) | translate
            }}
          </span>
          <div *ngIf="sortedGroups" class="groups-list">
            <ion-radio-group
              [(ngModel)]="selectedGroup"
              (ionChange)="onSelectGroup($event)"
            >
              <ion-item *ngFor="let group of sortedGroups">
                <ion-label
                  id="group-name"
                  [attr.data-cy]="'group-name' + group?.groupName + 'name'"
                >
                  {{ group?.groupName }}
                </ion-label>
                <ion-radio
                  aria-labelledby="group-name"
                  mode="md"
                  slot="end"
                  [value]="group"
                  [attr.data-cy]="'group-name' + group?.groupName + 'radio'"
                ></ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>
        </div>
        <!-- CHANGE UNITS -->
        <div *ngIf="selectedAction === InventoryActions.changeUnits">
          <ion-row class="inventory-change-unit-header">
            <ion-label class="inventory-unit">
              {{
                (inventory?.inventoryOptions?.selections?.length > 1
                  ? 'i18n.inventory.inventoryWorksheet.inventoryItemModal.useFieldsMultiple'
                  : 'i18n.inventory.inventoryWorksheet.inventoryItemModal.useFields'
                ) | translate
              }}
            </ion-label>
            <ion-label
              *ngIf="inventoryWorksheetService.someCatchWeight"
              class="catchweight-disclaimer"
            >
              <span class="bold">
                {{ 'i18n.common.noteCaps' | translate }}&nbsp;
              </span>
              {{
                'i18n.inventory.inventoryWorksheet.inventoryItemModal.catchWeightDisclaimerStart'
                  | translate
              }}
              <span class="italic"
                >{{
                  'i18n.inventory.inventoryWorksheet.inventoryItemModal.pounds'
                    | translate
                }}.</span
              >
              {{
                'i18n.inventory.inventoryWorksheet.inventoryItemModal.catchWeightDisclaimerEnd'
                  | translate
              }}
            </ion-label>
          </ion-row>
          <ion-row class="ion-justify-content-between">
            <ion-col class="inventory-change-uom-column" size="5.2">
              <ion-label>
                {{
                  'i18n.inventory.inventoryWorksheet.inventoryItemModal.inventoryUnit'
                    | translate
                }}
              </ion-label>
              <ion-item
                data-cy="inventory-action-modal-change-uom-button"
                button
                detail-icon="caret-down-outline"
                (click)="selectInventoryItemUOM($event)"
              >
                <ion-label
                  data-cy="inventory-action-modal-change-uom-button-label"
                >
                  {{ selectedUOM }}
                </ion-label>
              </ion-item>
              <ion-input
                alphanumeric
                *ngIf="selectedUOM === 'Custom'"
                data-cy="inventory-action-modal-unit-by-case-input"
                inputmode="text"
                maxlength="8"
                type="text"
                [value]="customUOM"
                placeholder="{{
                  'i18n.inventory.inventoryWorksheet.inventoryItemModal.custom'
                    | translate
                }}"
                (ionChange)="setCustomUOM($event)"
                [class.error-highlight]="
                  customUOM && existingLabels.includes(customUOM.toUpperCase())
                "
              >
              </ion-input>
              <span
                class="error"
                *ngIf="
                  customUOM && existingLabels.includes(customUOM.toUpperCase())
                "
              >
                {{
                  'i18n.inventory.inventoryWorksheet.inventoryCustomModal.uniqueError'
                    | translate
                }}
              </span>
            </ion-col>
            <ion-col class="inventory-conversion-column" size="5.2">
              <ion-label>
                {{
                  'i18n.inventory.inventoryWorksheet.inventoryItemModal.inventoryUnitFull'
                    | translate
                }}
              </ion-label>
              <ion-input
                data-cy="inventory-action-modal-unit-by-case-input"
                inputmode="numeric"
                maxlength="5"
                type="text"
                [value]="newConversionFactor"
                (ionChange)="setConversionFactor($event)"
                [maxLeftHandDigits]="3"
                [maxPrecision]="2"
                customDecimal
              >
              </ion-input>
            </ion-col>
          </ion-row>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    (platformService?.isTouchExperience$() | async) === false
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="cancel-modal-btn"
        data-cy="cancel-order-and-products-modal-button"
        class="usf-outline-green-dsktp-tblt-modal-button"
        (click)="dismissModal()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        id="submit-modal-btn"
        data-cy="submit-selected-items-modal-button"
        [ngClass]="
          selectedAction === InventoryActions.changeUnits
            ? selectedUOM === 'Custom'
              ? customUOM && !existingLabels.includes(customUOM.toUpperCase())
                ? 'usf-fill-green-dsktp-tblt-modal-button'
                : 'usf-fill-disabled-dsktp-tblt-modal-button'
              : newConversionFactor || newUOM
              ? 'usf-fill-green-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-dsktp-tblt-modal-button'
            : selectedGroup?.groupName
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-disabled-dsktp-tblt-modal-button'
        "
        (click)="dispatchAction(selectedAction)"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
