import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';

export const IMPORT_ORDER_VALIDATION_TRACING_ACTIONS = {
  cancelClick: createAction('[NGRXTrace - Order] Cancel Import Order Click'),
  cancelImportOrder: createAction(
    '[NGRXTrace - Order] Cancel Import Order',
    props<{
      tracking: Tracking;
    }>(),
  ),
  loadImportOrder: createAction(
    '[NGRXTrace - Order] Upload Import Order File',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitImportOrderStart: createAction(
    '[NGRXTrace - Order] Track Submit Import Order Start',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitImportOrderApi: createAction(
    '[NGRXTrace - Order] Track Submit Import Order API',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitImportOrderApiResponseSuccess: createAction(
    '[NGRXTrace - Order] Track Submit Import Order API Response Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceSubmitImportOrderApiResponseFail: createAction(
    '[NGRXTrace - Order] Track Submit Import Order API Response Fail',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackSubmitImportOrderWebsocket: createAction(
    '[NGRXTrace - Order] Track Submit Import Order Websocket Response',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceImportOrderValidationPageLoad: createAction(
    '[NGRXTrace - Order] Import Order Validations Page Load',
    props<{
      tracking: Tracking;
    }>(),
  ),
};
