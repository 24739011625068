<ng-container>
  <ion-header class="ion-no-border ion-header-modal-mobile-virtual-scroll">
    <ion-toolbar>
      <ion-label data-cy="zip-code-change-label">
        {{ 'i18n.common.changeZipCode' | translate }}
      </ion-label>
    </ion-toolbar>

    <ion-label
      class="enter-new-zip-code-label"
      data-cy="enter-new-zip-code-label"
    >
      {{ 'i18n.common.enterNewZipCode' | translate }}
    </ion-label>

    <ion-input
      class="zip-code-change-touch-input"
      data-cy="zip-code-change-touch-input"
      [placeholder]="'10012'"
      [(ngModel)]="zipCodeValue"
      [maxlength]="zipCodeLength"
      type="tel"
      inputmode="numeric"
      (keyup)="keyUpZipCodeValidation($event)"
      (keydown)="keyDownZipCodeValidation($event)"
      (ionInput)="ionInputZipCodeValidation($event)"
      (paste)="pasteZipCodeValidation($event)"
      (keydown.enter)="switchGuestZip($event)"
    >
    </ion-input>
  </ion-header>
  <div class="button-flex-wrapper">
    <ion-buttons>
      <ion-button
        class="usf-outline-green-dsktp-tblt-modal-button"
        data-cy="cancel-save-zip-code-touch-button"
        (click)="modalDismiss()"
      >
        <span>{{ 'i18n.common.cancel' | translate }}</span>
      </ion-button>
      <ion-button
        class="usf-fill-green-dsktp-tblt-modal-button"
        data-cy="save-zip-code-touch-button"
        [disabled]="!isValidZipCode()"
        (click)="switchGuestZip($event)"
      >
        <span>{{ 'i18n.common.save' | translate }}</span>
      </ion-button>
    </ion-buttons>
  </div>
</ng-container>
