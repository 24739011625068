<ion-header
  class="ion-no-border"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-header-modal-tablet'
      : 'ion-header-modal-mobile'
  "
>
  <ion-toolbar>
    <ion-label data-cy="other-inventory-actions-label">
      {{ 'i18n.inventory.otherInventoryActions' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-content-modal-tablet'
      : 'ion-content-modal-mobile'
  "
>
  <app-other-inventory-actions
    [ellipsisOptions]="ellipsisOptions"
    [platform]="platformService?.platformType"
  ></app-other-inventory-actions>
</ion-content>
