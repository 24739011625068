import { createAction, props } from '@ngrx/store';
import { Division } from '@usf/customer-types';

export const getDivisionList = createAction(
  '[Divisions] Get Divisions',
);
export const getDivisionSuccess = createAction(
  '[Divisions] Get Divisions Success',
  props<{ divisions: Division[]}>(),
);
export const getDivisionFail = createAction(
  '[Divisions] Get Divisions Fail',
  props<{ error: any }>(),
);

