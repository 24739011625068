<ion-header
  class="ion-no-border"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-header-modal-tablet'
      : 'ion-header-modal-mobile'
  "
>
  <ion-toolbar>
    <ion-label data-cy="lists-add-group-label">{{
      'i18n.lists.addGroup' | translate
    }}</ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="create-group_content"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-content-modal-tablet'
      : 'ion-content-modal-mobile'
  "
>
  <ion-input
    id="create-group-input"
    data-cy="create-group-input"
    [class.group-name-exists]="groupNameExists"
    inputmode="text"
    maxlength="30"
    placeholder="{{ 'i18n.lists.enterGroupName' | translate }}"
    required="true"
    [(ngModel)]="groupName"
    (ngModelChange)="getGroupNameExists()"
  >
  </ion-input>
  <p *ngIf="groupNameExists" class="group-name-exists-error-message">
    {{ 'i18n.lists.groupNameExists' | translate }}
  </p>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="submit-modal-btn"
        data-cy="submit-create-group-modal-button"
        [ngClass]="
          platformService?.platformType ===
          platformService?.platformEnum?.tablet
            ? groupNameExists || !trimmedGroupName
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : groupNameExists || !trimmedGroupName
            ? 'usf-fill-disabled-mobile-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="onCreateGroup()"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
