<ng-container *ngIf="vm$ | async; let vm">
  <ion-header
    class="ion-no-border"
    [ngClass]="
      vm.platformType === PlatformEnum.tablet
        ? 'ion-header-modal-tablet-virtual-scroll'
        : 'ion-header-modal-mobile-virtual-scroll'
    "
  >
    <ion-toolbar>
      <ion-label data-cy="customers-label">
        {{ 'i18n.common.customers' | translate }}
      </ion-label>
    </ion-toolbar>

    <div *ngIf="showSearchbar" class="searchbar-section">
      <ion-searchbar
        id="customer-selection-searchbar"
        data-cy="customer-selection-searchbar"
        class="search"
        mode="md"
        placeholder="{{ 'i18n.header.search' | translate }}"
        (ionInput)="filterCustomers()"
        [(ngModel)]="searchText"
      ></ion-searchbar>
    </div>

    <div class="border"></div>
  </ion-header>
  <ion-content
    class="customer-selection_content"
    [ngClass]="
      vm.platformType === PlatformEnum.tablet
        ? 'ion-content-modal-tablet-virtual-scroll'
        : 'ion-content-modal-mobile-virtual-scroll'
    "
    scroll-y="false"
  >
    <ng-container *ngIf="!vm.customerSelectionOptions.length">
      <div class="no-customers-found" data-cy="no-customers-found-text">
        <p>
          {{ 'i18n.header.noCustomersFound' | translate }}
        </p>
      </div>
    </ng-container>
    <cdk-virtual-scroll-viewport
      class="customer-dropdown-touch"
      *ngIf="vm.customerSelectionOptions.length"
      orientation="vertical"
      appCustomVs
      [itemHeights]="vm.rowHeights"
    >
      <ion-list class="customer-list">
        <ng-container
          *cdkVirtualFor="
            let customerSelectionOption of vm.customerSelectionOptions;
            let i = index
          "
        >
          <ion-item
            (click)="switchTo(customerSelectionOption)"
            [ngClass]="
              customerSelectionOption?.isDepartment ? 'department' : 'customer'
            "
            [attr.data-cy]="'customer-' + i + '-list-item'"
          >
            <ion-button
              shape="round"
              *ngIf="!customerSelectionOption?.isDepartment"
              [attr.data-cy]="'customer-' + i + '-button'"
            >
              <p [attr.data-cy]="'customer-' + i + '-first-letter'">
                {{ customerSelectionOption?.firstLetter }}
              </p>
            </ion-button>
            <ion-label>
              <h3
                [ngClass]="
                  customerSelectionOption?.isDepartment
                    ? 'dept-name'
                    : 'cust-name'
                "
                [attr.data-cy]="'customer-' + i + '-name'"
              >
                {{ customerSelectionOption?.title | titlecase }}
              </h3>
              <p
                *ngIf="customerSelectionOption?.subtitle"
                [attr.data-cy]="'customer-' + i + '-subtitle'"
                s
              >
                {{ customerSelectionOption?.subtitle }}
              </p>
            </ion-label>
            <ion-icon
              *ngIf="
                !!selectedDepartmentNumber
                  ? customerSelectionOption?.departmentNumber ===
                    selectedDepartmentNumber
                  : customerSelectionOption?.isSelected
              "
              name="checkmark-circle-outline"
              slot="end"
              [attr.data-cy]="'customer-' + i + '-selected-icon'"
            ></ion-icon>
          </ion-item>
        </ng-container>
      </ion-list>
    </cdk-virtual-scroll-viewport>
  </ion-content>
</ng-container>
