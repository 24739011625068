import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { ManageVendorsModel } from '../model/manage-vendors.model';
import {
  SelectedSort,
  NextVendorSort,
  ExternalVendorActions,
  ExternalVendorState,
} from '@usf/ngrx-product';
import * as select from '../selectors/external-vendors-view-model.selector';
import { ExternalVendor } from '@usf/product-types';
import { PanAppState } from '@panamax/app-state';
import { SortingDirection } from '../../../../shared/constants/lists-constants';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner/loading-spinner.service';
import { selectedCustomer } from '@app/ngrx-customer/store';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class ExternalVendorService {
  deleteVendorModalSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );
  constructor(
    private store: Store<State>,
    private loadingSpinnerService: LoadingSpinnerService,
    readonly panAppState: PanAppState,
  ) {}

  vendorsView$ = () => this.store.select(select.externalVendorsView());

  createExternalVendor(vendorDetails: ExternalVendor) {
    this.store
      .select(selectedCustomer)
      .pipe(take(1))
      .subscribe(customer => {
        const newVendor: ExternalVendor = {
          customerNumber: customer.customerNumber,
          divisionNumber: customer.divisionNumber,
          ...vendorDetails,
        };
        this.store.dispatch(
          ExternalVendorActions.create({ vendor: newVendor }),
        );
      });
  }

  editExternalVendor(originalVendorDetails: any, updatedVendorDetails: any) {
    this.store
      .select(selectedCustomer)
      .pipe(take(1))
      .subscribe(customer => {
        const originalVendor: ExternalVendor = {
          divisionNumber: customer.divisionNumber,
          customerNumber: customer.customerNumber,
          ...originalVendorDetails,
        };
        const updatedVendor: ExternalVendor = {
          divisionNumber: customer.divisionNumber,
          customerNumber: customer.customerNumber,
          ...updatedVendorDetails,
        };
        this.store.dispatch(
          ExternalVendorActions.edit({
            originalVendor: originalVendor,
            updatedVendor: updatedVendor,
          }),
        );
      });
  }

  deleteExternalVendors(selectedVendors: ExternalVendor[]) {
    const vendorIds: string[] = [];
    selectedVendors.forEach(selectedVendor => {
      vendorIds.push(selectedVendor.vendorId);
    });
    this.store.dispatch(
      ExternalVendorActions.delete({
        vendors: selectedVendors,
        vendorIds,
      }),
    );
  }

  resetSort() {
    this.store.dispatch(ExternalVendorActions.resetSort());
  }

  changeSort(column: string, vendorData: ManageVendorsModel) {
    const newSelectedSort: SelectedSort = {
      columnName: column,
      sortDirection: vendorData.nextSortStates[column],
    };
    const newNextSortState: NextVendorSort = {
      ...vendorData.nextSortStates,
      [column]:
        vendorData.nextSortStates[column] === SortingDirection.desc
          ? SortingDirection.asc
          : SortingDirection.desc,
    };
    this.store.dispatch(
      ExternalVendorActions.changeSort({
        selectedSort: newSelectedSort,
        nextSortStates: newNextSortState,
      }),
    );
  }

  changeSortMobile(column: string, direction: string) {
    const newSelectedSort: SelectedSort = {
      columnName: column,
      sortDirection: direction,
    };
    this.store.dispatch(
      ExternalVendorActions.changeSort({
        selectedSort: newSelectedSort,
        nextSortStates: null,
      }),
    );
  }

  /* Returns the vendor ids for the vendors that are currently selected on manage vendors page */
  getSelectedVendors(vendors: ExternalVendorState[]) {
    const ret = [];
    vendors?.forEach(vendor => {
      if (vendor?.isSelected) {
        ret.push(vendor);
      }
    });
    return ret;
  }

  /* Dispatches action to set specific vendor's isSelected value to true or false */
  setSelectedVendors(vendorId: string, value: boolean) {
    this.store.dispatch(
      ExternalVendorActions.setIsSelected({ vendorId, value }),
    );
  }

  /* Dispatches action to set all vendors isSelected to true or false */
  setAllSelectedVendors(value: boolean) {
    this.store.dispatch(
      ExternalVendorActions.setIsSelected({
        vendorId: null,
        value,
      }),
    );
  }

  vendorIdExists(vendors: ExternalVendor[], vendorId: string) {
    if (vendors) {
      return vendors.some(vendor => {
        return vendor.vendorId === vendorId;
      });
    }
  }

  public openOrCloseDeleteVendorModal(open: boolean) {
    this.deleteVendorModalSubject.next(open);
  }
}
