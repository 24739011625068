import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { State } from '@app/store';
import {
  ActionSheetController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PanAppState, PlatformEnum, PlatformService } from '@panamax/app-state';
import {
  ANALYTICS_ACTIONS,
  CostComparisonReportModalOutput,
  CostReportModalOutput,
  CustomerDivisionPair,
  DownloadInventoryModalOutput,
  INVENTORY_ACTIONS,
  INVENTORY_ITEM_ACTIONS,
  InventoryAnalyticsConstants,
  InventoryTracingConstants,
  displayInventoryTransformer,
  downloadCostReportInventoryTransformer,
  pageLoadedTransformer,
  priceOverrideTransformer,
  selectInventoryById,
  selectInventoryOnline,
  selectInventoryOptionPrice,
} from '@usf/ngrx-inventory';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  firstValueFrom,
  of,
} from 'rxjs';
import { PATHS } from '../../shared/constants/paths';
import {
  Button,
  EllipsisOption,
} from '../../shared/models/ellipsis-option.model';
import { ModalService } from '../../shared/services/modal/modal.service';
import { InventoryLandingOptionsModalComponent } from '../components/inventory-landing-options-modal/inventory-landing-options-modal.component';
import { OtherInventoryActionsComponent } from '../components/other-inventory-actions/other-inventory-actions.component';
import { InventoryProductsToLoad } from '../models/inventory-products-to-load.model';
import {
  CostComparisonModalOutputUI,
  InventoryCardEllipsisClick,
  InventoryPageData,
  InventoryViewModel,
} from '../models/inventory-view.model';
import {
  getInventoryProductsToLoad,
  inventoryStateIsLoaded,
  recentInventoriesView,
  selectAllInventoriesViewModels,
  selectCustomerHasLegacyData,
  selectCustomerMigration,
  selectGlCodes,
  selectInventories,
  selectInventoriesForCostComparisonReportModal,
  selectInventoriesForCostReportModal,
  selectInventoryWorksheetViewModel,
} from '../selectors/inventory-view-model.selector';

import { DatePipe } from '@angular/common';
import { CustomerStoreService } from '@app/ngrx-customer/services';
import { DownloadInventoryModalComponent } from '@inventory/components/download-inventory-modal/download-inventory-modal.component';
import {
  AddToInventoryStrings,
  InventoryActions,
  InventoryEllipsisModalModes,
} from '@inventory/constants/inventory-constants';
import { InventoryCompleteDeleteModalComponent } from '@inventory/pages/inventory-worksheet/components/inventory-complete-delete-modal/inventory-complete-delete-modal.component';
import { NonUSFProductViewModel } from '@inventory/pages/manage-non-usf-products/models/non-usf-products-view-model';
import { NonUsfProductViewModelSelectors } from '@inventory/pages/manage-non-usf-products/selectors/non-usf-selectors';
import { transformNonUsfProductToProduct } from '@inventory/pages/manage-non-usf-products/transformers/non-usf-products-transformers';
import {
  createInventoryItemWithProductInformation,
  updateInventoryItemWithProductInformation,
} from '@inventory/selectors/helpers/inventory-create-update-helper';
import { InventoryCreateSelectors } from '@inventory/selectors/inventory-create.selector';
import { AddToInventoryDesktopTabletComponent } from '@shared/components/add-to-inventory/add-to-inventory-desktop-tablet/add-to-inventory-desktop-tablet.component';
import { AddToInventoryMobileComponent } from '@shared/components/add-to-inventory/add-to-inventory-mobile/add-to-inventory-mobile.component';
import { ModalWithNavComponent } from '@shared/components/modal-with-nav/modal-with-nav.component';
import { SimpleActionModalComponent } from '@shared/components/simple-action-modal/simple-action-modal.component';
import { Product } from '@shared/models/product.model';
import { productsAreInStateWithPricing } from '@shared/selectors/helpers/product-info.selectors.helper';
import { ProductService } from '@shared/services/product/product.service';
import {
  Inventory,
  InventoryGroup,
  InventoryGroupItem,
  InventoryStatus,
  MigrationRequest,
} from '@usf/inventory-types';
import { InventoryState } from '@usf/ngrx-inventory/lib/models/inventory/inventory.model';
import {
  getLoadingStateOfProducts,
  LoadingState,
  selectCompletedProductSummaries,
} from '@usf/ngrx-product';
import { filter, finalize, first, map, take, tap } from 'rxjs/operators';
import { mslProductLoadCheck } from '../../lists/pages/master-list/selectors/master-list.selectors';
import { EllipsisOptionsPopoverComponent } from '../../shared/components/popovers/ellipsis-options/ellipsis-options-popover.component';
import { FEATURES } from '../../shared/constants/splitio-features';
import { productSelector } from '../../shared/selectors/product.selectors';
import { LoadingSpinnerService } from '../../shared/services/loading-spinner/loading-spinner.service';
import { CostComparisonReportModalComponent } from '../components/cost-comparison-report-modal/cost-comparison-report-modal.component';
import { CostReportModalComponent } from '../components/cost-report-modal/cost-report-modal.component';
import { ClaimTicketService } from '@shared/services/claim-ticket.service';
import { environment } from 'src/environments/environment';
import { AppService } from '@shared/services/app.service';
import { PopoverHelperService } from '@shared/helpers/popover.helpers';
import { ErrorPopoverComponent } from '@shared/components/header/error-popover/error-popover.component';
import { CurrencyService } from '../../shared/services/currency.service';
import { RadioBtnInventory } from '../models/radio-btn-inventory.model';
import { selectedCustomer } from '@app/ngrx-customer/store';
import { areDatesEqual, isDateGreater } from '@shared/helpers/calendar.helpers';
import { CostComparisonReportContainerComponent } from '@inventory/components/cost-comparison-report-container/cost-comparison-report-container.component';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  public exposedLastInventory: string;
  //Flag to determine if add non usf product is click of button in worksheet page hence display the selected inventory in modal
  public addNonUSFProdFromInventoryWorksheet: boolean;
  public showSkeletonFlag: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );
  public showExtendedCostReport: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  constructor(
    private router: Router,
    private store: Store<State>,
    private popoverController: PopoverController,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private modalService: ModalService,
    private translateService: TranslateService,
    private productService: ProductService,
    public platformService: PlatformService,
    readonly datePipe: DatePipe,
    private inventoryCreateSelectors: InventoryCreateSelectors,
    private nonUsfSelectors: NonUsfProductViewModelSelectors,
    private panAppState: PanAppState,
    private loadingSpinnerService: LoadingSpinnerService,
    private customerStoreService: CustomerStoreService,
    private claimTicketService: ClaimTicketService,
    private appService: AppService,
    private popoverHelperService: PopoverHelperService,
  ) {}
  // OBSERVABLE SELECTORS
  inventoryStateIsLoaded$ = (): Observable<boolean> =>
    this.store.select(inventoryStateIsLoaded);
  inventoryOnline$ = (): Observable<boolean> =>
    this.store.select(selectInventoryOnline());

  inventoryProductsToLoad$ = (
    id: string,
  ): Observable<InventoryProductsToLoad> => {
    return this.store.select(getInventoryProductsToLoad(id));
  };

  selectExtendedCostReportFlag$ = (): Observable<boolean> => {
    return this.panAppState.feature$([
      FEATURES.split_global_inventory_extended_cost_report,
    ]);
  };

  selectCostReportFlag$ = (): Observable<boolean> => {
    return this.panAppState.feature$([
      FEATURES.split_global_inventory_cost_report,
    ]);
  };

  selectCostComparisonReportFlag$ = (): Observable<boolean> => {
    return this.panAppState.feature$([
      FEATURES.split_global_inventory_cost_comparison_report,
    ]);
  };

  selectInventoryMigrationFlag$ = (): Observable<boolean> => {
    return this.panAppState.feature$([
      FEATURES.split_global_inventory_migration,
    ]);
  };

  selectShowFoodCostReportFlag$ = (): Observable<boolean> => {
    return this.panAppState.feature$([FEATURES.split_global_food_cost_report]);
  };

  requestReload = () => {
    this.store.dispatch(INVENTORY_ACTIONS.load());
  };

  selectInventoryById$(inventoryId: string) {
    return this.store.select(selectInventoryById(inventoryId));
  }

  selectInventories$() {
    return this.store.select(selectInventories());
  }

  selectInventoriesForCostReport$() {
    return this.store.select(selectInventoriesForCostReportModal());
  }

  selectRadioBtnInventories$(): Observable<RadioBtnInventory[]> {
    const selector = combineLatest([
      this.store.select(selectAllInventoriesViewModels()),
      this.store.select(selectedCustomer),
    ]);
    return selector.pipe(
      map(([inventoryViewModels, customer]) => {
        const inventoriesToReturn: RadioBtnInventory[] = [];
        for (const inventory of inventoryViewModels.filter(
          inv => inv.status === InventoryStatus.COMPLETED,
        )) {
          const formattedDate = this.datePipe.transform(
            inventory.inventoryDate,
            'M/d/yyyy',
          );
          const updatedDate = this.datePipe.transform(
            inventory.ecomUpdateDtm,
            'M/d/yyyy',
          );
          const inventoryName = inventory.inventoryName + ' - ' + formattedDate;
          const lastUpdated = 'Last Updated ' + updatedDate;
          const searchArray = [];
          searchArray.push(
            new Date(inventory?.inventoryDate)?.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }),
          );
          inventory?.inventoryName
            ?.toUpperCase()
            .split('-')
            .forEach(nameSegment =>
              searchArray.push(...nameSegment.split(' ')),
            );

          const departmentName =
            customer?.departments?.length > 0
              ? (customer?.departments?.find(dep => {
                  return (
                    Number(dep.departmentNumber).valueOf() ===
                    inventory.departmentNumber
                  );
                })?.departmentName ?? '') +
                ' (' +
                inventory?.departmentNumber +
                ')'
              : '';

          if (!!departmentName) {
            departmentName
              ?.toUpperCase()
              .split('-')
              .forEach(nameSegment =>
                searchArray.push(...nameSegment.split(' ')),
              );
          }

          inventoriesToReturn.push({
            inventoryId: inventory.inventoryId,
            inventoryName,
            inventoryDate: new Date(inventory.inventoryDate),
            lastUpdated,
            value: CurrencyService.toUSDString(inventory.totalPrice),
            isCompleted: inventory.status === InventoryStatus.COMPLETED,
            count: inventory.numProducts,
            searchArray,
            departmentName: departmentName,
            departmentNumber: inventory.departmentNumber,
          } as RadioBtnInventory);
        }
        return inventoriesToReturn;
      }),
    );
  }

  selectRadioBtnInventoriesForFoodCost$() {
    return this.selectRadioBtnInventories$().pipe(
      map(inventories => this.sortRadioBtnInventoriesForFoodCost(inventories)),
    );
  }

  selectCompletedRadioBtnInventories$() {
    return this.selectRadioBtnInventories$().pipe(
      map(inventories => {
        inventories.filter(inventory => inventory.isCompleted);
        return inventories.sort((a, b) =>
          a.inventoryName.localeCompare(b.inventoryName),
        );
      }),
    );
  }

  selectInventoriesForCostComparisonReport$() {
    return this.store.select(selectInventoriesForCostComparisonReportModal());
  }

  selectInventoryOptionPrice$() {
    return this.store.select(selectInventoryOptionPrice());
  }

  selectCustomerHasLegacyData$() {
    return this.store.select(selectCustomerHasLegacyData());
  }

  recentInventoriesView$ = (
    platform: PlatformEnum,
  ): Observable<InventoryPageData> => {
    return combineLatest([
      this.store.select(recentInventoriesView(platform)),
      this.selectCostReportFlag$(),
      this.selectExtendedCostReportFlag$(),
      this.selectCostComparisonReportFlag$(),
      this.selectInventoriesForCostReport$(),
      this.selectInventoriesForCostComparisonReport$(),
      this.selectCustomerHasLegacyData$(),
      this.selectShowGl$(),
      this.selectShowFoodCostReportFlag$(),
    ]).pipe(
      map(
        ([
          inventoryPageData,
          costReportModalFlag,
          extendedCostReportFlag,
          costComparisonReportModalFlag,
          costReportInventories,
          costComparisonReportInventories,
          customerHasLegacyData,
          glFlag,
          foodCostReportFlag,
        ]) => {
          return {
            ...inventoryPageData,
            costReportModalFlag,
            extendedCostReportFlag,
            costComparisonReportModalFlag,
            costReportInventories,
            costComparisonReportInventories,
            customerHasLegacyData,
            glFlag,
            foodCostReportFlag,
          };
        },
      ),
    );
  };

  selectCustomerMigrationStatus$() {
    return this.store.select(selectCustomerMigration());
  }

  loadCustomerMigrationData() {
    this.selectInventoryMigrationFlag$()
      .pipe(take(1))
      .subscribe(migrationFlag => {
        if (migrationFlag) {
          this.store.dispatch(INVENTORY_ACTIONS.loadCustomerMigration());
        }
      });
  }

  selectedCustomer$() {
    return this.customerStoreService.loadSelectedCustomer$();
  }

  productsForInventoryAreLoaded(productNumbers: number[]): Observable<boolean> {
    return this.store.select(getLoadingStateOfProducts(productNumbers)).pipe(
      map((loadingState: LoadingState) => {
        return (
          loadingState === LoadingState.loaded ||
          loadingState === LoadingState.error
        );
      }),
    );
  }

  selectShowGl$(): Observable<boolean> {
    return combineLatest([
      this.store.select(selectGlCodes()),
      this.panAppState.feature$([FEATURES.split_global_inventory_gl_sorting]),
    ]).pipe(
      map(
        ([generalLedgerArray, glFeatureFlag]) =>
          generalLedgerArray?.length > 0 && glFeatureFlag,
      ),
    );
  }

  // NAVIGATION FUNCTIONS
  goTo(path: string[]) {
    this.router.navigate(path);
  }

  goToWorksheet(id: string) {
    this.goTo([
      PATHS.INVENTORY.concat('/', PATHS.INVENTORY_WORKSHEET.split(':')[0] + id),
    ]);
  }

  // INVENTORY ACTIONS
  updateRecentInventoriesSort(selectedHeader: string, sortDirection: string) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateRecentInventoriesSort({
        selectedHeader,
        sortDirection,
      }),
    );
  }

  resetRecentInventoriesSort() {
    this.store.dispatch(INVENTORY_ACTIONS.resetRecentInventoriesSort());
  }

  setInventoryToCopy(inventoryId: string) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateIsSelected({ inventoryId, isSelected: true }),
    );
  }

  // MODAL/POPOVER HELPER FUNCTIONS
  private getReportsButtons(
    isTouch: boolean,
    inventoryPageData: InventoryPageData,
    costComparisonModalRef: CostComparisonReportContainerComponent,
  ) {
    let buttons: Button[] = [];
    if (
      inventoryPageData.costReportModalFlag &&
      inventoryPageData.costReportInventories.length > 0
    )
      buttons.push({
        text: this.translateService.instant(
          'i18n.inventory.inventoryActionsPopover.reports.costReport',
        ),
        handler: () => {
          this.dismissOtherInventoryActions(isTouch);
          this.handleShowCostReport(
            inventoryPageData.costReportInventories,
            inventoryPageData.glFlag,
          );
        },
      });

    if (
      !!inventoryPageData.extendedCostReportFlag &&
      inventoryPageData.costReportInventories.length > 0
    )
      buttons.push({
        text: this.translateService.instant(
          'i18n.inventory.inventoryActionsPopover.reports.extendedCostReport',
        ),
        handler: () => {
          this.dismissOtherInventoryActions(isTouch);
          this.showHideExtendedCostReport(true);
        },
      });
    if (
      inventoryPageData.costComparisonReportModalFlag &&
      inventoryPageData.costComparisonReportInventories.length > 0
    ) {
      buttons.push({
        text: this.translateService.instant(
          'i18n.inventory.inventoryActionsPopover.reports.costComparisonReport',
        ),
        handler: () => {
          this.dismissOtherInventoryActions(isTouch);
          this.handleShowCostComparisonReport(
            inventoryPageData.costComparisonReportInventories,
            costComparisonModalRef,
          );
        },
      });
    }
    // buttons.push({
    //   text: this.translateService.instant(
    //     'i18n.inventory.inventoryActionsPopover.reports.manageFoodCosts',
    //   ),
    //   handler: () => {
    //     // placeholder for Manage Food Costs link
    //   },
    //   disabled: true,
    // });

    return buttons;
  }

  public showHideExtendedCostReport(show: boolean) {
    this.showExtendedCostReport.next(show);
  }

  async handleShowCostReport(
    allCompletedInventories: InventoryState[],
    glFlag: boolean,
    currentInventory?: InventoryViewModel,
  ) {
    this.modalService.setModalOptions(
      this.platformService.platformType !==
        this.platformService.platformEnum.desktop,
      ModalWithNavComponent,
      {
        rootPage: CostReportModalComponent,
        rootPageParams: {
          platformType: this.platformService.platformType,
          allInventories: allCompletedInventories,
          currentInventory: currentInventory,
          glFlag: glFlag,
        },
      },
      'inventory-cost-report-modal-desktop',
    );
    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );
    await modal.present();
    modal.onDidDismiss().then(event => {
      const costReportModalOutput = event.data as CostReportModalOutput;
      if (costReportModalOutput) {
        this.loadingSpinnerService.createSpinnerModal().then(() => {
          this.store.dispatch(
            INVENTORY_ACTIONS.downloadCostReport({ costReportModalOutput }),
          );
          this.store.dispatch(
            ANALYTICS_ACTIONS.trackDownloadInventoryCostReport({
              tracking: {
                analytics: {
                  data: {
                    capability: InventoryAnalyticsConstants.capability,
                    event: InventoryAnalyticsConstants.downloadEvent,
                    fileType:
                      costReportModalOutput.selectedFormat === 'CSV'
                        ? InventoryAnalyticsConstants.fileTypeCsv
                        : InventoryAnalyticsConstants.fileTypePdf,
                    fileCount: costReportModalOutput.selectedInventories.length,
                  },
                  transformFunc: downloadCostReportInventoryTransformer,
                },
              },
            }),
          );
        });
      }
    });
  }

  private async handleShowCostComparisonReport(
    allCompletedInventories: InventoryState[],
    costComparisonModalRef: CostComparisonReportContainerComponent,
  ) {
    const dualPaneFlag = await this.panAppState.featureEnabled(
      FEATURES.split_global_reusable_cost_comparison_report,
    );
    if (dualPaneFlag) {
      costComparisonModalRef.glFlag = await firstValueFrom(
        this.selectShowGl$(),
      );
      costComparisonModalRef.openModal();
    } else {
      this.modalService.setModalOptions(
        this.platformService.platformType !==
          this.platformService.platformEnum.desktop,
        ModalWithNavComponent,
        {
          rootPage: CostComparisonReportModalComponent,
          rootPageParams: {
            platformType: this.platformService.platformType,
            allInventories: allCompletedInventories,
            glFlag$: this.selectShowGl$(),
          },
        },
        'modal-desktop-size-xl',
      );
      const modal = await this.modalController.create(
        this.modalService.modalOptions,
      );
      await modal.present();
      modal.onDidDismiss().then(event => {
        const costComparisonReportModalOutput =
          event.data as CostComparisonReportModalOutput;
        if (costComparisonReportModalOutput) {
          this.loadingSpinnerService.createSpinnerModal().then(() => {
            this.store.dispatch(
              INVENTORY_ACTIONS.downloadCostComparisonReport({
                costComparisonReportModalOutput,
              }),
            );
          });
        }
      });
    }
  }

  private getManagementButtons(
    isTouch: boolean,
    inventoryPageData: InventoryPageData,
  ) {
    let buttons: Button[] = [];
    buttons.push({
      text: this.translateService.instant(
        'i18n.inventory.inventoryActionsPopover.management.manageVendors',
      ),
      handler: () => {
        this.goTo([PATHS.INVENTORY, PATHS.MANAGE_VENDORS]);
        this.dismissOtherInventoryActions(isTouch);
      },
      disabled: false,
    });
    // buttons.push({
    //   text: this.translateService.instant(
    //     'i18n.inventory.inventoryActionsPopover.management.manageGeneralLedger',
    //   ),
    //   handler: () => {
    //     // placeholder for Manage General Ledger link
    //   },
    //   disabled: true,
    // });
    buttons.push({
      text: this.translateService.instant(
        'i18n.inventory.inventoryActionsPopover.management.manageNonUSFoods',
      ),
      handler: () => {
        this.goTo([PATHS.INVENTORY, PATHS.MANAGE_NON_USF_PRODUCTS]);
        this.dismissOtherInventoryActions(isTouch);
      },
      disabled: false,
    });

    if (inventoryPageData.customerHasLegacyData && !isTouch) {
      buttons.push({
        text: this.translateService.instant(
          'i18n.inventory.inventoryActionsPopover.reports.viewPastInventories',
        ),
        handler: () => {
          this.goToR2Inventory();
          this.dismissOtherInventoryActions(isTouch);
        },
      });
    }
    return buttons;
  }

  private getLearnButtons(isTouch: boolean) {
    let buttons: Button[] = [];
    buttons.push({
      text: this.translateService.instant(
        'i18n.inventory.inventoryActionsPopover.learn.help',
      ),
      handler: () => {
        this.appService.openUrlInBrowser(
          'https://www.usfoods.com/our-services/moxe-help-center/inventory-on-moxe.html',
        );
        this.dismissOtherInventoryActions(isTouch);
      },
      hideIcon: true,
    });
    return buttons;
  }

  private getFoodCalcButtons(isTouch: boolean) {
    const button = {
      text: this.translateService.instant(
        'i18n.inventory.inventoryActionsPopover.foodCost.calculator',
      ),
      handler: () => {
        this.goTo([PATHS.INVENTORY, PATHS.FOOD_COST]);
        this.dismissOtherInventoryActions(isTouch);
      },
      hideIcon: true,
    };
    return button;
  }

  public constructOtherInventoryActionOptions(
    isTouch: boolean,
    inventoryPageData: InventoryPageData,
    costComparisonModalRef: CostComparisonReportContainerComponent,
  ) {
    let ellipsisOptions: EllipsisOption[] = [];
    const learnButtons = this.getLearnButtons(isTouch);
    if (inventoryPageData.foodCostReportFlag) {
      const foodCalcButtons = this.getFoodCalcButtons(isTouch);
      learnButtons.unshift(foodCalcButtons);
    }
    ellipsisOptions.push({
      buttons: learnButtons,
    });
    if (
      inventoryPageData.costReportModalFlag ||
      inventoryPageData.extendedCostReportFlag ||
      inventoryPageData.costComparisonReportModalFlag
    ) {
      const reportButtons = this.getReportsButtons(
        isTouch,
        inventoryPageData,
        costComparisonModalRef,
      );
      ellipsisOptions.push({
        header: this.translateService.instant(
          'i18n.inventory.inventoryActionsPopover.reports.header',
        ),
        warningMessage:
          reportButtons.length === 0
            ? 'i18n.inventory.inventoryActionsPopover.reports.noCompletedInventories'
            : '',
        buttons: reportButtons,
      });
    }

    ellipsisOptions.push({
      header: this.translateService.instant(
        'i18n.inventory.inventoryActionsPopover.management.header',
      ),
      buttons: this.getManagementButtons(isTouch, inventoryPageData),
    });
    // ellipsisOptions.push({
    //   header: this.translateService.instant(
    //     'i18n.inventory.inventoryActionsPopover.learn.header',
    //   ),
    //   buttons: this.getLearnButtons(isTouch),
    // });
    return ellipsisOptions;
  }

  async openOtherInventoryActions(
    ellipsisOptions: EllipsisOption[],
    isTouch: boolean,
    event: Event,
  ) {
    if (!isTouch) {
      const popover = await this.popoverController.create({
        component: OtherInventoryActionsComponent,
        componentProps: {
          ellipsisOptions,
          platform: this.platformService.platformType,
        },
        showBackdrop: false,
        cssClass: 'ellipsis-inventory-popover-desktop',
        event,
        mode: 'ios',
      });
      await popover.present();
    } else {
      this.modalService.setModalOptions(
        true,
        InventoryLandingOptionsModalComponent,
        {
          ellipsisOptions,
        },
      );
      const modal = await this.modalController.create(
        this.modalService.modalOptions,
      );
      await modal.present();
    }
  }

  async displayDeleteConfirmationModal(inventoryId: string) {
    this.displayInventoryCardEllipsisModal(
      inventoryId,
      InventoryEllipsisModalModes.delete,
    );
  }

  async displayViewSummaryModal(inventoryId: string) {
    this.displayInventoryCardEllipsisModal(
      inventoryId,
      InventoryEllipsisModalModes.viewSummary,
    );
  }

  async displayInventoryCardEllipsisModal(
    completedInventoryId: string,
    mode: InventoryEllipsisModalModes,
  ) {
    const cssClass =
      mode === InventoryEllipsisModalModes.viewSummary
        ? 'inventory-information-modal-desktop'
        : 'modal-desktop-size-xl';

    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      InventoryCompleteDeleteModalComponent,
      {
        platform: this.platformService.platformType,
        completedInventory$: this.store.select(
          selectInventoryWorksheetViewModel(
            this.platformService.getPlatformType(),
            completedInventoryId,
          ),
        ),
        mode: mode,
      },
      cssClass,
    );
    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );
    if (
      mode === InventoryEllipsisModalModes.complete ||
      mode === InventoryEllipsisModalModes.viewSummary
    ) {
      modal.onDidDismiss().then((res: any) => {
        if (res.data?.navigate) {
          this.goTo([PATHS.INVENTORY]);
        } else if (res.data?.inventoryId) {
          this.goToWorksheet(res.data.inventoryId);
        }
      });
    }
    await modal.present();
  }

  async dismissOtherInventoryActions(isTouch: boolean) {
    if (!isTouch) {
      await this.popoverController.dismiss();
    } else {
      await this.modalController.dismiss();
    }
  }

  updateInventoryItemBasedOnProductNumber = (
    inventoryId: string,
    groupName: string,
    inventoryItem: InventoryGroupItem,
    pricingData: string,
  ) => {
    if (inventoryItem.nonUsfFlag) {
      this.store
        .select(
          this.nonUsfSelectors.getNonUSfProductsBasedOnId([
            inventoryItem.itemNumber,
          ]),
        )
        .pipe(
          first(),
          tap(products => {
            this.updateInventoryItemBasedOnProductData(
              transformNonUsfProductToProduct(
                products[0] as NonUSFProductViewModel,
              ),
              inventoryId,
              groupName,
              inventoryItem,
              pricingData,
            );
          }),
        )
        .subscribe(() => {});
    } else {
      this.productService
        .loadProducts$([inventoryItem.itemNumber])
        .pipe(take(1))
        .subscribe(() => {
          this.store
            .select(
              this.inventoryCreateSelectors.productsForInventoryById([
                inventoryItem.itemNumber,
              ]),
            )
            .pipe(
              filter(productsToFilter =>
                productsAreInStateWithPricing(productsToFilter),
              ),
              tap(productsLoaded => {
                if (productsLoaded.has(inventoryItem.itemNumber)) {
                  this.updateInventoryItemBasedOnProductData(
                    productsLoaded.get(inventoryItem.itemNumber),
                    inventoryId,
                    groupName,
                    inventoryItem,
                    pricingData,
                  );
                }
              }),
              take(1),
              finalize(() => {}),
            )
            .subscribe(() => {});
        });
    }
  };

  updateInventoryBasedOnProductNumbersAndInventory = (
    products: number[],
    inventoryData: Inventory,
    pricingData: string,
  ) => {
    let nonUSfProducts = [];
    let usfProducts = [];

    inventoryData.groups.forEach(group => {
      group.groupItems.forEach(item => {
        if (products.includes(item.itemNumber)) {
          if (item.nonUsfFlag) {
            nonUSfProducts.push(item.itemNumber);
          } else {
            usfProducts.push(item.itemNumber);
          }
        }
      });
    });

    this.productService
      .loadProducts$(usfProducts)
      .pipe(take(1))
      .subscribe(() => {
        combineLatest([
          this.store.select(
            this.inventoryCreateSelectors.productsForInventoryById(usfProducts),
          ),
          this.store.select(
            this.nonUsfSelectors.getFakeProductsFromNonUSfProductsBasedOnId(
              nonUSfProducts,
            ),
          ),
        ])
          .pipe(
            filter(([productsToFilter, nonUSF]) =>
              productsAreInStateWithPricing(productsToFilter),
            ),
            tap(([usf, nonUSF]) => {
              this.updateInventoryBasedOnProductData(
                usf,
                nonUSF,
                inventoryData,
                pricingData,
              );
            }),
            take(1),
            finalize(() => {}),
          )
          .subscribe(() => {});
      });
  };

  updateInventoryBasedOnProductData = (
    usf: Map<number, Product>,
    nonUSF: Product[],
    inventoryData: Inventory,
    pricingData: string,
  ) => {
    let inventoryToUpdate: Inventory = {
      ...inventoryData,
      groups: inventoryData.groups.map(value => {
        const items = [];
        value.groupItems.forEach(item => {
          if (usf.has(item.itemNumber)) {
            items.push(
              updateInventoryItemWithProductInformation(
                item,
                usf.get(item.itemNumber),
                pricingData,
              ),
            );
          }
          if (
            nonUSF.some(prod => {
              return prod.productNumber === item.itemNumber;
            })
          ) {
            items.push(
              updateInventoryItemWithProductInformation(
                item,
                nonUSF.find(prod => prod.productNumber === item.itemNumber),
                pricingData,
              ),
            );
          }
        });

        return {
          ...value,
          groupItems: items,
        };
      }),
    } as Inventory;

    this.store.dispatch(
      INVENTORY_ACTIONS.update({ inventory: inventoryToUpdate }),
    );
  };

  updateInventoryItemBasedOnProductData = (
    product: Product,
    inventoryId: string,
    groupName: string,
    inventoryItem: InventoryGroupItem,
    pricingData: string,
  ) => {
    let inventoryitemToUpdate = updateInventoryItemWithProductInformation(
      inventoryItem,
      product,
      pricingData,
    );
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.update({
        inventoryId,
        groupName,
        inventoryItem: inventoryitemToUpdate,
      }),
    );
  };

  addProductsToInventory = (
    inventory: Inventory,
    group: InventoryGroup,
    productNumbers: number[],
    placement: string,
  ) => {
    this.productService
      .loadProducts$(productNumbers)
      .pipe(take(1))
      .subscribe(() => {
        this.store
          .select(
            this.inventoryCreateSelectors.productsForInventoryById(
              productNumbers,
            ),
          )
          .pipe(
            filter(productsToFilter =>
              productsAreInStateWithPricing(productsToFilter),
            ),
            tap(productsLoaded => {
              let duplicateFreeNumbers = productNumbers.slice();
              if (group.groupItems) {
                group.groupItems.forEach(item => {
                  if (duplicateFreeNumbers.includes(item.itemNumber)) {
                    const num = duplicateFreeNumbers.indexOf(item.itemNumber);
                    duplicateFreeNumbers.splice(num, 1);
                  }
                });
              }
              this.createInventoryItemsAndSendToState(
                productsLoaded,
                duplicateFreeNumbers,
                group,
                placement === AddToInventoryStrings.top,
                inventory,
              );
            }),
            take(1),
            finalize(() => {}),
          )
          .subscribe(() => {});
      });
  };

  addNonUSFProductsToInventory = (
    inventory: Inventory,
    group: InventoryGroup,
    productNumbers: number[],
    placement: string,
  ) => {
    this.store
      .select(this.nonUsfSelectors.getNonUSfProductsBasedOnId(productNumbers))
      .pipe(
        first(),
        tap(products => {
          const productsToSend = products.map(product => {
            return createInventoryItemWithProductInformation(
              transformNonUsfProductToProduct(
                product as NonUSFProductViewModel,
              ),
              'LP',
              true,
            );
          });

          this.addInventoryItemsAndTrack(
            productsToSend,
            group,
            inventory,
            placement === AddToInventoryStrings.top,
            true,
          );
        }),
      )
      .subscribe(() => {});
  };

  async createInventoryItemsAndSendToState(
    products: Map<number, Product>,
    productsToCreate: number[],
    group: InventoryGroup,
    toTop: boolean,
    inventory: Inventory,
  ) {
    let productsSorted = productsToCreate.slice();
    productsSorted.sort((a, b) => {
      const productA = products.get(a);
      const productB = products.get(b);
      if (productA.summary && productB.summary) {
        if (
          productA.summary.productDescTxtl < productB.summary.productDescTxtl
        ) {
          return -1;
        }
        if (
          productA.summary.productDescTxtl > productB.summary.productDescTxtl
        ) {
          return 1;
        }
        return 0;
      } else if (productA.summary) {
        return -1;
      } else {
        return 1;
      }
    });

    let productsToSend = productsSorted.map(value => {
      return createInventoryItemWithProductInformation(
        products.get(value),
        'LP',
        false,
      );
    });

    this.addInventoryItemsAndTrack(
      productsToSend,
      group,
      inventory,
      toTop,
      false,
    );
  }

  addInventoryItemsAndTrack(
    inventoryItems: InventoryGroupItem[],
    group: InventoryGroup,
    inventory: Inventory,
    toTop: boolean,
    nonUSF: boolean,
  ) {
    let productsToTrack = inventoryItems.map(item => {
      return {
        apn: item.itemNumber,
        groupName: group.groupName,
      };
    });

    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.addInventoryItemsIntoGroup({
        inventoryItems,
        groupName: group.groupName,
        inventoryId: inventory.inventoryId,
        placeTop: toTop,
        messageForToast: nonUSF
          ? InventoryActions.createNONUSF
          : InventoryActions.addToGroup,
        tracking: {
          tracing: {
            data: {
              traceContext: InventoryTracingConstants.inventory,
              isEndOfTrace: false,
              isStartOfTrace: true,
              attributes: {
                spanName: InventoryTracingConstants.addUSFProductsSpan,
                event: InventoryTracingConstants.addUSFProductsEvent,
                inventoryName:
                  inventory.inventoryName +
                  ' - ' +
                  this.datePipe.transform(inventory?.inventoryDate, 'M/d/yyyy'),
                inventoryId: inventory.inventoryId,
                products: productsToTrack,
              },
            },
            transformFunc: priceOverrideTransformer,
          },
        },
      }),
    );
  }

  async openAddToInventoryModal(
    productNumbers: number[],
    istouch: boolean,
    platform: PlatformEnum,
    inventory: Inventory,
    nonUsfProducts: boolean,
  ) {
    this.modalService.setModalOptions(
      istouch,
      ModalWithNavComponent,
      {
        rootPage:
          platform === PlatformEnum.mobile
            ? AddToInventoryMobileComponent
            : AddToInventoryDesktopTabletComponent,
        rootPageParams: {
          productNumbers,
          inventory,
          nonUSFFlag: nonUsfProducts,
        },
      },
      'modal-desktop-size-xl',
    );

    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );
    await modal.present();
    return modal;
  }

  async openInventoryCardEllipsis(
    data: InventoryCardEllipsisClick,
    inventoryPageData: InventoryPageData,
  ) {
    const showDownloadOption = await this.panAppState.featureEnabled(
      FEATURES.split_global_inventory_worksheet_download,
    );
    const buttons: any[] = [
      // DO NOT DELETE: FUTURE ELLIPSIS OPTIONS
      {
        text: this.translateService.instant('i18n.inventory.viewSummary'),
        handler: () => {
          if (this.platformService.platformType === PlatformEnum.desktop) {
            this.popoverController.dismiss();
          }
          this.displayViewSummaryModal(data.inventory.inventoryId);
        },
      },
    ];
    if (
      inventoryPageData.costReportModalFlag &&
      inventoryPageData.costReportInventories.length > 0 &&
      data.inventory.completedDtm &&
      data.inventory.status === InventoryStatus.COMPLETED
    )
      buttons.unshift({
        text: this.translateService.instant(
          'i18n.inventory.inventoryActionsPopover.reports.costReport',
        ),
        handler: () => {
          this.handleShowCostReport(
            inventoryPageData.costReportInventories,
            inventoryPageData.glFlag,
            data.inventory,
          );
        },
      });

    if (showDownloadOption && data.inventory.numProducts > 0) {
      buttons.push({
        text: this.translateService.instant(
          'i18n.inventory.downloadInventoryModal.downloadInventoryWorksheet',
        ),
        handler: () => {
          this.openDownloadInventoryModal(data.inventory);
        },
      });
    }
    buttons.push({
      text: this.translateService.instant(
        'i18n.inventory.deleteInventoryWorksheet',
      ),
      handler: () => {
        if (this.platformService.platformType === PlatformEnum.desktop) {
          this.popoverController.dismiss();
        }
        this.displayDeleteConfirmationModal(data.inventory.inventoryId);
      },
    });

    if (this.platformService.platformType === PlatformEnum.mobile) {
      buttons.unshift({
        text: this.translateService.instant(
          'i18n.inventory.copyInventoryWorksheet',
        ),
        handler: () => {
          this.actionSheetController.dismiss();
          this.setInventoryToCopy(data.inventory.inventoryId);
          this.goTo([PATHS.INVENTORY, PATHS.CREATE_INVENTORY]);
        },
      });
    }

    this.platformService.platformType === PlatformEnum.desktop
      ? this.openInventoryCardEllipsisDesktop(data.event, buttons)
      : this.openInventoryCardEllipsisTouch(buttons);
  }

  async openInventoryCardEllipsisDesktop(event: Event, buttons: any[]) {
    const popover = await this.popoverController.create({
      component: EllipsisOptionsPopoverComponent,
      event,
      componentProps: {
        buttons,
      },
      cssClass: 'ellipsis-options-popover-desktop',
      mode: 'ios',
      showBackdrop: false,
    });
    await popover.present();
  }

  async openInventoryCardEllipsisTouch(buttons: any[]) {
    buttons.push({
      text: this.translateService.instant('i18n.common.cancel'),
      role: 'cancel',
      cssClass: 'cancel-button',
    });
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'action-sheet-touch',
      mode: 'ios',
      buttons,
    });
    await actionSheet.present();
  }

  // Inventory Trace Display
  traceInventoryDisplayStart(inventoryId: string) {
    this.showSkeletonFlag.next(false);

    combineLatest([
      this.store.select(selectInventoryById(inventoryId)),
      this.store.select(inventoryStateIsLoaded),
    ])
      .pipe(
        filter(([worksheet, loaded]) => loaded),
        first(),
      )
      .subscribe(([worksheet, loaded]) => {
        this.store.dispatch(
          INVENTORY_ACTIONS.displayInventory({
            inventoryId,
            inventoryName: worksheet?.inventoryName,
            tracking: {
              tracing: {
                data: {
                  traceContext: InventoryTracingConstants.inventory,
                  isEndOfTrace: false,
                  isStartOfTrace: true,
                  attributes: {
                    spanName: InventoryTracingConstants.displayInventorySpan,
                    event: InventoryTracingConstants.displayInventoryEvent,
                    inventoryName:
                      worksheet?.inventoryName +
                      ' - ' +
                      this.datePipe.transform(
                        worksheet?.inventoryDate,
                        'M/d/yyyy',
                      ),
                    inventoryId: inventoryId,
                  },
                },
                transformFunc: displayInventoryTransformer,
              },
            },
          }),
        );
      });
  }

  inventoryCouldNotBeFound(inventoryId: string) {
    this.traceInventoryDisplayEnd(inventoryId, 'NOT FOUND', false);
  }

  traceInventoryDisplayEnd(
    inventoryId: string,
    inventoryName: string,
    success: boolean,
  ) {
    const traceObject = {
      tracing: {
        data: {
          traceContext: InventoryTracingConstants.inventory,
          isEndOfTrace: true,
          isStartOfTrace: false,
          attributes: {
            spanName:
              InventoryTracingConstants.displayInventorySpan +
              (success
                ? InventoryTracingConstants.spanSuccess
                : InventoryTracingConstants.spanFailure),
            event:
              InventoryTracingConstants.displayInventoryEvent +
              (success
                ? InventoryTracingConstants.eventSuccess
                : InventoryTracingConstants.eventFailure),
            inventoryName: inventoryName,
            inventoryId: inventoryId,
          },
        },
        transformFunc: displayInventoryTransformer,
      },
    };

    this.showSkeletonFlag.next(true);

    if (success) {
      this.store.dispatch(
        INVENTORY_ACTIONS.displayInventorySuccess({
          inventoryId,
          inventoryName,
          tracking: traceObject,
        }),
      );
    } else {
      this.store.dispatch(
        INVENTORY_ACTIONS.displayInventoryFail({
          error: {},
          tracking: traceObject,
        }),
      );
    }
  }

  runInventoryDisplayCheck(inventoryId: string) {
    return combineLatest([
      this.store.select(selectInventoryById(inventoryId)),
      this.store.select(selectCompletedProductSummaries),
      this.store.select(inventoryStateIsLoaded),
      this.store.select(mslProductLoadCheck),
    ]).pipe(
      filter(
        ([worksheet, products, loaded, mslProductsLoaded]) =>
          loaded && mslProductsLoaded,
      ),
      filter(([worksheet, products, loaded, mslProductsLoaded]) => {
        if (worksheet === undefined) {
          return false;
        }

        let loadCheck = true;
        worksheet.groups.forEach(group => {
          if (group.groupItems) {
            group.groupItems.forEach(item => {
              if (
                !products.find(
                  product => product.productNumber === item.itemNumber,
                ) &&
                !item.nonUsfFlag
              ) {
                loadCheck = false;
              }
            });
          }
        });
        return loadCheck;
      }),
      map(([worksheet, products, loaded, mslProductsLoaded]) => {
        return worksheet;
      }),
    );
  }

  loadProductsForOnline(inventoryId: string) {
    this.inventoryProductsToLoad$(inventoryId)
      .pipe(
        filter(
          (inventoryProductsToLoad: InventoryProductsToLoad) =>
            inventoryProductsToLoad.inventoryStateIsLoaded,
        ),
        first(),
      )
      .subscribe((inventoryProductsToLoad: InventoryProductsToLoad) => {
        if (inventoryProductsToLoad) {
          this.productService.loadProducts(
            inventoryProductsToLoad.productNumbers,
          );
        }
      });
  }

  async openDownloadInventoryModal(inventoryWorksheet: InventoryViewModel) {
    this.modalService.setModalOptions(
      this.platformService.platformType !==
        this.platformService.platformEnum.desktop,
      ModalWithNavComponent,
      {
        rootPage: DownloadInventoryModalComponent,
        rootPageParams: {
          platformType: this.platformService.platformType,
          inventoryId: inventoryWorksheet.inventoryId,
          inventoryName: inventoryWorksheet.inventoryName,
          inventoryDate: inventoryWorksheet.inventoryDate,
          groups: inventoryWorksheet.groupHeaders,
        },
      },
      'download-inventory-desktop',
    );
    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );
    await modal.present();
    modal.onDidDismiss().then(event => {
      const downloadInventoryModalOutput =
        event.data as DownloadInventoryModalOutput;
      if (downloadInventoryModalOutput) {
        this.loadingSpinnerService.createSpinnerModal().then(() => {
          this.store.dispatch(
            INVENTORY_ACTIONS.downloadInventory({
              downloadInventoryModalOutput,
            }),
          );
        });
      }
    });
  }

  downloadExtendedCostReport(dateRange: string[]) {
    const timeZoneOffset = new Date().getTimezoneOffset();
    this.customerStoreService
      .loadCustomers$()
      .pipe(first())
      .subscribe(async customer => {
        this.customerStoreService
          .loadDivisions$()
          .pipe(first())
          .subscribe(async divisions => {
            const customerDivisionPair: CustomerDivisionPair[] = [];
            customer.forEach(customer => {
              const division = divisions.find(
                division => division.divisionNumber === customer.divisionNumber,
              );
              customerDivisionPair.push({
                customerNumber: customer?.customerNumber,
                divisionNumber: division?.divisionNumber,
                customerName: customer?.customerName,
                divisionName: division?.divisionName,
              });
            });

            this.store.dispatch(
              INVENTORY_ACTIONS.downloadExtendedCostReport({
                dates: dateRange,
                customerDivisionPair,
                timeZoneOffset,
              }),
            );
          });
      });
  }

  completeInventoryConflictResolution() {
    this.router.navigate([PATHS.INVENTORY]);
    this.store.dispatch(INVENTORY_ACTIONS.resetStore());
    this.store.dispatch(INVENTORY_ACTIONS.load());
  }

  recieveExtendedCostReport = (blob: Blob) => {};

  async openMigrationConfirmationModal(
    customerName: string,
    customerNumber: number,
    departments: MigrationRequest,
  ) {
    const modalTitle =
      this.translateService.instant(
        'i18n.inventory.inventoryMigration.areYouSure',
      ) +
      customerName +
      ' (' +
      customerNumber +
      ')?';
    const transfer = this.translateService.instant(
      'i18n.inventory.inventoryMigration.thisTransferWillInclude',
    );
    const bullet1 = this.translateService.instant(
      'i18n.inventory.inventoryMigration.yourLastTwoYears',
    );
    const bullet2 = this.translateService.instant(
      'i18n.inventory.inventoryMigration.allNonUSFProducts',
    );
    const bullet3 = this.translateService.instant(
      'i18n.inventory.inventoryMigration.createdVendors',
    );
    const pleaseNote = this.translateService.instant(
      'i18n.inventory.inventoryMigration.pleaseNote',
    );
    const transferMessage =
      transfer +
      '\n' +
      '  \u{2022} ' +
      bullet1 +
      '\n' +
      '  \u{2022} ' +
      bullet2 +
      '\n' +
      '  \u{2022} ' +
      bullet3 +
      '\n' +
      pleaseNote;
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      SimpleActionModalComponent,
      {
        title: modalTitle,
        content: transferMessage,
        leftButtonText: 'Cancel',
        rightButtonText: 'Continue',
        contentClass: 'line-breaks',
        iconClass: 'alert-icon-border full-size',
      },
      'migration-confirmation-modal-desktop',
    );

    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );

    await modal.present();
    modal.onDidDismiss().then(value => {
      const initiateTransfer = value?.data?.dispatchAction;
      if (initiateTransfer) {
        this.store.dispatch(
          INVENTORY_ACTIONS.initiateMigration({ departments }),
        );
      }
    });
  }

  goToR2Inventory() {
    this.navigateWithClaimTicket(environment.inventoryUrl);
  }

  navigateWithClaimTicket(url: string) {
    this.claimTicketService.getClaimTicket()?.subscribe(
      res => {
        if (res?.claimTicketValue) {
          url = url + res?.claimTicketValue;
          this.appService.openUrlInBrowser(url);
        } else {
          this.popoverHelperService.presentPopover(
            this.popoverController,
            ErrorPopoverComponent,
            `Unable to take you to Past Inventories at this time. Please try again later.`,
          );
        }
      },
      () => {
        this.popoverHelperService.presentPopover(
          this.popoverController,
          ErrorPopoverComponent,
          `Unable to take you to Past Inventories at this time. Please try again later.`,
        );
      },
    );
  }

  trackInventoryPageLoaded() {
    this.store.dispatch(
      ANALYTICS_ACTIONS.trackInventoryHomePageLoaded({
        tracking: {
          analytics: {
            data: {
              capability: InventoryAnalyticsConstants.capability,
              event: InventoryAnalyticsConstants.pageLoaded,
              pageName: InventoryAnalyticsConstants.inventoryHome,
            },
            transformFunc: pageLoadedTransformer,
          },
        },
      }),
    );
  }

  async downloadCostComparisonReport(
    costComparisonOutput: CostComparisonModalOutputUI,
  ) {
    const costComparisonReportModalOutput: CostComparisonReportModalOutput = {
      firstSelectedInventory: await firstValueFrom(
        this.selectInventoryById$(costComparisonOutput.firstSelectedInventory),
      ),
      secondSelectedInventory: await firstValueFrom(
        this.selectInventoryById$(costComparisonOutput.secondSelectedInventory),
      ),
      selectedFormat: costComparisonOutput.selectedFormat,
      selectedSort: costComparisonOutput.selectedSort,
      productsToInclude: costComparisonOutput.productsToInclude,
      includeGlCodes: costComparisonOutput.includeGlCodes,
    };
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(
        INVENTORY_ACTIONS.downloadCostComparisonReport({
          costComparisonReportModalOutput,
        }),
      );
    });
  }

  sortRadioBtnInventoriesForFoodCost(inventories: RadioBtnInventory[]) {
    return inventories.sort((a, b) => {
      const greater = isDateGreater(a.inventoryDate, b.inventoryDate);
      if (greater) {
        return -1;
      }
      const equal = areDatesEqual(a.inventoryDate, b.inventoryDate);
      if (equal) {
        const departmentGreater =
          (a.departmentName ?? '').toUpperCase() <
          (b.departmentName ?? '').toUpperCase();
        if (departmentGreater) {
          return -1;
        }
        const departmentEqual =
          (a.departmentName ?? '').toUpperCase() ===
          (b.departmentName ?? '').toUpperCase();
        if (departmentEqual) {
          const nameGreater =
            (a.inventoryName ?? '').toUpperCase() <
            (b.inventoryName ?? '').toUpperCase();
          if (nameGreater) {
            return -1;
          }
          const nameEqual =
            (a.inventoryName ?? '').toUpperCase() ===
            (b.inventoryName ?? '').toUpperCase();
          if (nameEqual) {
            return 0;
          }
          return 1;
        }
        return 1;
      }
      return 1;
    });
  }
}
