import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Customer } from '@usf/customer-types/customer';
import { CustomerSwitchStatus } from '../../constants/customer-switch-status';
import { compareCustomers } from '../../models/client/customer';
import { CustomerState } from '../../models/state/customer-state';
import * as CustomerActions from '../actions/customer.actions';
import { CUSTOMER_SWITCH_ACTIONS } from '../actions/customer.actions';

export const adapter: EntityAdapter<Customer> = createEntityAdapter<Customer>({
  selectId: (customer: Customer) => customer.customerNumber,
  sortComparer: compareCustomers,
});

export const initialCustomerState: CustomerState = adapter.getInitialState({
  loading: false,
  error: null,
  customerSwitchStatus: CustomerSwitchStatus.notStarted,
  isSuperUser: false,
});

export const reducer = createReducer(
  initialCustomerState,
  on(CustomerActions.getCustomerList, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CustomerActions.getCustomerListSuccess, (state, action) =>
    adapter.upsertMany(action.customers, {
      ...state,
      loading: false,
      error: null,
    }),
  ),
  on(CustomerActions.getCustomerListFail, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),

  on(CUSTOMER_SWITCH_ACTIONS.getCustomerSwitchStatus, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CUSTOMER_SWITCH_ACTIONS.setCustomerSwitchStatus, (state, action) => ({
    ...state,
    customerSwitchStatus: action.customerSwitchStatus,
    loading: false,
    error: null,
  })),
  on(CUSTOMER_SWITCH_ACTIONS.resetCustomerSwitchStatus, state => ({
    ...state,
    customerSwitchStatus: CustomerSwitchStatus.notStarted,
    loading: false,
    error: null,
  })),
);

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of user ids
export const selectCustomerIds = selectIds;

// select the dictionary of user entities
export const selectCustomerEntities = selectEntities;

// select the array of users
export const selectAllCustomers = selectAll;

// select the total user count
export const selectCustomerTotal = selectTotal;
