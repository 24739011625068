import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RadioBtnInventory } from '../../models/radio-btn-inventory.model';

@Component({
  selector: 'app-radio-btn-inventory',
  templateUrl: './radio-btn-inventory.component.html',
  styleUrls: ['./radio-btn-inventory.component.scss'],
})
export class RadioBtnInventoryComponent {
  @Input() inventory: RadioBtnInventory;
  @Input() index: number;

  @Output()
  selectInventoryEmitter: EventEmitter<RadioBtnInventory> = new EventEmitter();

  constructor() {}

  selectInventory() {
    this.selectInventoryEmitter.emit(this.inventory);
  }
}
