import { Component, Input } from '@angular/core';
import { PlatformEnum } from '@panamax/app-state';
import { CustomerSelectionOption } from '../../../../../customer/models/customer-selection-option.model';

@Component({
  selector: 'app-super-user-customer-option',
  templateUrl: './super-user-customer-option.component.html',
  styleUrls: ['./super-user-customer-option.component.scss'],
})
export class SuperUserCustomerOptionComponent {
  readonly platformEnum = PlatformEnum;

  @Input() platform: PlatformEnum;
  @Input() customer: CustomerSelectionOption;
  @Input() customerOptionIndex: number;

  constructor() {}
}
