import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {
  PlatformService,
  PlatformEnum,
  PageInfoService,
  LOCAL_STORAGE_KEYS,
} from '@panamax/app-state';
import { PATHS } from '../../constants/paths';

@Injectable({
  providedIn: 'root',
})
export class PlatformGuardService implements CanActivate {
  constructor(
    readonly platformService: PlatformService,
    public router: Router,
    readonly pageInfo: PageInfoService,
  ) {}

  /** Navigates to appropriate route based on user platform, always returns false */
  canActivate(
    route: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot,
  ): boolean {
    const url = route.url.map(segment => segment.path);
    if (
      this.pageInfo.getUrlPath().indexOf('b2c') < 0 &&
      this.pageInfo.getUrlPath() !== '/' &&
      this.pageInfo.getUrlPath() !== PATHS.LOGIN &&
      this.pageInfo.getUrlPath() !== PATHS.LOGOUT &&
      this.pageInfo.getUrlPath() !== PATHS.LOGIN_ERROR &&
      this.pageInfo.getUrlPath().indexOf('404') < 0
    ) {
      this.pageInfo.saveQueryParamsToStorage(
        LOCAL_STORAGE_KEYS.params,
        route.queryParams,
        state.url,
      );
    }

    const navigationState = this.router.getCurrentNavigation().extras.state;

    if (this.platformService.platformType === PlatformEnum.desktop) {
      this.router.navigate(['desktop'].concat(url), {
        queryParams: route.queryParams,
        state: navigationState,
      });
    } else {
      this.router.navigate(['touch'].concat(url), {
        queryParams: route.queryParams,
        state: navigationState,
      });
    }

    return false;
  }
}
