<form [formGroup]="form" class="full-height-form">
  <ion-header class="ion-no-border ion-header-modal-desktop">
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button
          data-cy="import-order-modal-button"
          (click)="setModalOpen(false)"
        >
          <ion-icon
            slot="icon-only"
            name="close-outline"
            data-cy="import-order-icon"
          ></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-label data-cy="import-order-text">
        {{ 'i18n.importOrderModal.importOrder' | translate }}
      </ion-label>
    </ion-toolbar>
  </ion-header>

  <ion-content class="ion-content-modal-desktop">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <div *ngIf="loading$ | async" class="progress-bar">
            <ion-progress-bar type="indeterminate"></ion-progress-bar>
            <div>{{ 'i18n.importOrderModal.checkingFile' | translate }}</div>
          </div>

          <div class="error-message" *ngIf="!!(errors$ | async)?.length">
            <div>
              <ion-icon
                name="alert-circle-outline"
                class="alert-icon"
              ></ion-icon
              ><span>{{
                'i18n.importOrderModal.correctErrors' | translate
              }}</span>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <div
            class="file-drop-area"
            (drop)="onDrop($event)"
            (dragover)="onDragOver($event)"
            data-cy="import-order-file-drop"
          >
            <img src="assets/images/add-file-icon.png" />
            <div class="drag-drop-text" data-cy="import-order-file-drop-text">
              {{ 'i18n.importOrderModal.dragDropFile' | translate }}
            </div>
            <div class="drag-drop-text" data-cy="import-order-file-or-text">
              {{ 'i18n.importOrderModal.or' | translate }}
            </div>
            <br />
            <input
              type="file"
              #fileInput
              (change)="onFileChange($event)"
              class="hidden"
            />
            <ion-button
              class="usf-fill-green-button"
              data-cy="import-order-browse-file-button"
              (click)="browseFiles()"
              [disabled]="loading$ | async"
              >{{ 'i18n.importOrderModal.browseFilesBtn' | translate }}
            </ion-button>
          </div>
          <div
            class="file-name-area"
            data-cy="import-order-no-file-selected-button"
            *ngIf="!(errors$ | async)?.length"
          >
            <div *ngIf="!form.controls['file'].value?.name && !fileReading">
              {{ 'i18n.importOrderModal.noFileSelected' | translate }}
            </div>
            <div
              *ngIf="form.controls['file'].value?.name && !fileReading"
              class="file-accepted-row"
            >
              <div>{{ form.controls['file'].value?.name }}</div>
              <div>
                <ion-icon
                  name="checkmark-outline"
                  size="large"
                  class="checkmark-icon"
                ></ion-icon>
              </div>
            </div>
            <div *ngIf="fileReading" class="file-loading-row">
              <span>{{ 'i18n.importOrderModal.fileLoading' | translate }}</span>
              <ion-spinner name="circles"></ion-spinner>
            </div>
          </div>
          <ng-container *ngIf="errors$ | async as errMessages">
            <app-validation-message
              *ngFor="let errMessage of errMessages | slice: 0:2"
              [fileName]="form.controls['file'].value?.name"
              [message]="errMessage"
              [hideFileName]="showMultipleFileError"
            ></app-validation-message>
          </ng-container>
          <a
            class="green-link"
            data-cy="import-order-csv-template"
            href="assets/documents/example/Import_Order_Template.csv"
            download="Import_Order_Template.csv"
          >
            {{ 'i18n.importOrderModal.download' | translate }}
          </a>
          <br /><br />
          <a
            href="https://www.usfoods.com/content/dam/usf/pdf/E-Commerce/moxe/us-foods-online-import-orders-users-manual.pdf"
            class="green-link"
            data-cy="import-order-help-link"
            target="_BLANK"
            >{{ 'i18n.importOrderModal.userGuide' | translate }}</a
          ><br />

          <div class="import-order-limits-text">
            <div>
              <div class="bold-font-weight">
                {{ 'i18n.importOrderModal.importOrderLimits' | translate }}
              </div>
            </div>
            <div>
              <div class="medium-font-weight">
                {{ 'i18n.importOrderModal.customers' | translate }}
              </div>
              <div>100</div>
            </div>
            <div>
              <div class="medium-font-weight">
                {{ 'i18n.importOrderModal.orders' | translate }}
              </div>
              <div>700</div>
            </div>
            <div>
              <div class="medium-font-weight">
                {{ 'i18n.importOrderModal.lineItems' | translate }}
              </div>
              <div>20,000</div>
            </div>
          </div>
        </ion-col>

        <ion-col offset=".5" size="5.5">
          <ion-label
            class="file-type-text"
            data-cy="import-order-select-file-text"
            >{{ 'i18n.importOrderModal.selectFile' | translate }}<span>*</span>
          </ion-label>
          <div class="select-text" data-cy="import-order-select-below-text">
            {{ 'i18n.importOrderModal.selectBelow' | translate }}
          </div>

          <ion-list>
            <ion-radio-group
              class="radio-group"
              data-cy="import-order-radio-group"
              formControlName="format"
            >
              <div
                class="import-order-radio-wrapper"
                *ngFor="let option of fileFormatOptions"
              >
                <div class="radio-selection">
                  <div>
                    <ion-label
                      id="file-option"
                      [attr.data-cy]="
                        'import-order-radio-label-' + option.fileType
                      "
                      [ngClass]="{
                        'disabled-radio-label': loading$ | async
                      }"
                    >
                      {{ option.description }}
                    </ion-label>
                  </div>
                  <div>
                    <ion-radio
                      aria-labelledby="file-option"
                      mode="md"
                      slot="end"
                      [value]="option.fileType"
                      [attr.data-cy]="'import-order-radio-' + option.fileType"
                      [disabled]="loading$ | async"
                    ></ion-radio>
                  </div>
                </div>
                <ion-item-divider></ion-item-divider>
              </div>
            </ion-radio-group>
            <br />
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>

  <ion-footer class="ion-footer-modal">
    <ion-toolbar>
      <ion-buttons>
        <ion-button
          class="usf-outline-orange-dsktp-tblt-modal-button"
          data-cy="import-order-cancel-button"
          (click)="setModalOpen(false)"
        >
          {{ 'i18n.common.cancel' | translate }}
        </ion-button>
        <ion-button
          [class.disabled]="!form.valid || (loading$ | async)"
          [disabled]="!form.valid || (loading$ | async)"
          class="usf-fill-green-dsktp-tblt-modal-button"
          data-cy="import-order-start-import-button"
          (click)="importOrder()"
        >
          {{ 'i18n.importOrderModal.continue' | translate }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</form>
