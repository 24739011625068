import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  ShowFeatureDirective,
  ShowSplitDirective,
  HideSplitDirective,
} from './show-feature.directive';
@NgModule({
  imports: [],
  declarations: [ShowFeatureDirective, ShowSplitDirective, HideSplitDirective],
  exports: [ShowFeatureDirective, ShowSplitDirective, HideSplitDirective],
})
export class ShowFeatureModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: ShowFeatureModule,
    };
  }
}
