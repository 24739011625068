import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { PanAppState } from '@panamax/app-state';
import { PATHS } from '@shared/constants/paths';
import { Observable, combineLatest, filter, map, of } from 'rxjs';

/**
 * Functional guard for split.io feature flags
 *
 * @param features string array of split.io features
 * @param redirectFailuresTo Redirect Path if features are toggled off
 * @param allEnabled Default: true. Only will route if all features are enabled
 * @returns route guard function that returns true or a URLTree
 */
export function splitGuard(
  features: string[],
  redirectFailuresTo: string = PATHS.HOME,
  allEnabled: boolean = true,
): CanActivateFn {
  return (): Observable<boolean | UrlTree> => {
    const panAppState = inject(PanAppState);
    const router = inject(Router);
    if (!features || features.length === 0) {
      return of(router.parseUrl(redirectFailuresTo));
    } else {
      return panAppState
        .feature$(features, allEnabled)
        .pipe(
          map(enabled =>
            !!enabled ? true : router.parseUrl(redirectFailuresTo),
          ),
        );
    }
  };
}
