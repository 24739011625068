import { RecentPurchase } from '@usf/list-types';
import { ProductSummary } from '@usf/product-types';
import { Product } from '../../../../../shared/models/product.model';
import {
  ItemTypes,
  ListRow,
} from '../../../../shared/list-detail-management/model/list-detail-management-view.model';
import {
  RecentlyPurchasedViewModel,
  RecentPurchaseProductData,
} from '../../model/recently-purchased-view.model';
import { ListConstants } from '@usf/ngrx-list';
import { ListTypes } from '../../../../../shared/constants/lists-constants';

export const comparePurchaseDates = (
  a: RecentPurchaseProductData,
  b: RecentPurchaseProductData,
): number => {
  const date1 = new Date(a.recentPurchase?.purchaseDtm1);
  const date2 = new Date(b.recentPurchase?.purchaseDtm1);
  if (date1 < date2) {
    return 1;
  }
  if (date1 > date2) {
    return -1;
  }
  if (a?.summary?.productDescLong > b?.summary?.productDescLong) {
    return 1;
  }

  if (a?.summary?.productDescLong < b?.summary?.productDescLong) {
    return -1;
  }

  return 0;
};

export const getRecentPurchase = (
  recentlyPurchasedViewModel: RecentlyPurchasedViewModel,
  productNumber: number,
): RecentPurchase => {
  let recentPurchase = null;
  recentlyPurchasedViewModel?.items?.forEach(item => {
    const castedItem = item as RecentPurchaseProductData;
    if (castedItem?.recentPurchase?.productNumber === productNumber) {
      recentPurchase = castedItem?.recentPurchase;
    }
  });
  return recentPurchase;
};

export const hasRecentPurchase = (recentPurchase: RecentPurchase): boolean => {
  if (recentPurchase) {
    for (let i = 1; i < 5; i++) {
      if (
        recentPurchase[`unitsOrdered${i}`] > 0 ||
        recentPurchase[`eachesOrdered${i}`] > 0
      ) {
        return true;
      }
    }
  }
  return false;
};

export const createRecentPurchaseRow = (
  id: number,
  products: Map<number, Product>,
): RecentPurchaseProductData => {
  const product = products.get(id);

  return {
    ...product,
    itemType: ItemTypes.product,
    listId: ListTypes.recentlyPurchased,
  } as RecentPurchaseProductData;
};

export const compareProductDesc = (
  a: RecentPurchaseProductData,
  b: RecentPurchaseProductData,
): number => {
  if (a?.summary?.productDescTxtl > b?.summary?.productDescTxtl) {
    return 1;
  }

  if (a?.summary?.productDescTxtl < b?.summary?.productDescTxtl) {
    return -1;
  }

  return 0;
};

export const addProductClass = (
  product: ProductSummary,
  productClasses: string[],
): string[] => {
  if (
    product?.classDescription &&
    !productClasses.includes(product?.classDescription)
  ) {
    if (productClasses.length === 0) {
      productClasses.push(product?.classDescription);
    } else {
      for (let i = 0; i < productClasses.length; i++) {
        const itemToCompare = productClasses[i];
        if (productClasses.length === 1) {
          if (
            product?.classDescription?.toLocaleLowerCase() <
            itemToCompare?.toLocaleLowerCase()
          ) {
            productClasses.unshift(product?.classDescription);
            break;
          } else {
            productClasses.push(product?.classDescription);
            break;
          }
        } else {
          if (
            product?.classDescription?.toLocaleLowerCase() <
            itemToCompare?.toLocaleLowerCase()
          ) {
            productClasses.splice(i, 0, product?.classDescription);
            break;
          } else if (i === productClasses.length - 1) {
            productClasses.push(product?.classDescription);
            break;
          }
        }
      }
    }
  }

  return productClasses;
};

export const sortByProductClasses = (
  items: ListRow[],
  productClasses: string[],
  groupToSort: string,
): ListRow[] => {
  let sortedItems: ListRow[] = [];
  productClasses?.forEach(productClass => {
    let setOfSortedItems: ListRow[] = [];
    if (
      productClass === groupToSort ||
      groupToSort === ListConstants.allGroups
    ) {
      let groupCount = 0;
      items?.forEach(item => {
        const tempItem = item as RecentPurchaseProductData;
        if (tempItem?.summary?.classDescription === productClass) {
          groupCount++;
          setOfSortedItems.push({ ...tempItem, groupName: productClass });
        }
      });
      if (groupCount > 0) {
        setOfSortedItems.unshift({
          groupName: productClass,
          itemCount: groupCount,
          itemType: ItemTypes.group,
        });
        sortedItems = sortedItems.concat(setOfSortedItems);
      }
    }
  });
  return sortedItems;
};
