import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PROMOTION_ACTIONS } from '@usf/ngrx-promotions';
import { filter, take, tap } from 'rxjs/operators';
import { mslProductLoadCheck } from '../../lists/pages/master-list/selectors/master-list.selectors';
import { DealsService } from '../services/deals.service';
import { RevenueManagementActions } from '@usf/ngrx-product';

@Injectable({
  providedIn: 'root',
})
export class ClientDealsEffects {
  dealsLoadedSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PROMOTION_ACTIONS.loadPromotionsSuccess),
        tap(action => {
          this.store
            .select(mslProductLoadCheck)
            .pipe(
              filter(isLoaded => isLoaded),
              take(1),
            )
            .subscribe(() =>
              this.dealsService.calculateDealsProductsExist(
                action.promotionDetails,
              ),
            );
        }),
      ),
    { dispatch: false },
  );

  loadRevManWinbacks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RevenueManagementActions.FetchRevenueManagementResultSuccess),
        tap(action => {
          const productNumbers = action?.response?.map(
            revenueManagement => revenueManagement?.productNumber,
          );

          if (productNumbers?.length > 0) {
            this.dealsService.loadProducts(productNumbers);
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private dealsService: DealsService,
    private store: Store,
  ) {}
}
