<img
  *ngIf="isOnline && !!imageThumbnail"
  [attr.data-cy]="'product-' + productNumber + '-image'"
  [class.is-split]="isSplitCard"
  [class.click-disabled]="!clickable"
  [class.is-small]="isSmall"
  [class.is-medium]="isMedium"
  src="{{ imageThumbnail }}"
  (click)="clickable ? handleProductImageClick() : undefined"
/>
<ion-button
  *ngIf="!isOnline || !imageThumbnail"
  data-cy="product-card-no-image-button"
  fill="clear"
  class="no-hover"
  [disabled]="!clickable"
  (click)="handleProductImageClick()"
  [class.is-small]="isSmall"
  [class.is-medium]="isMedium"
>
  <ion-icon
    data-cy="product-card-no-image-icon"
    name="restaurant"
    class="no-image"
  >
  </ion-icon>
</ion-button>
