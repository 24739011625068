import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { jwtDecode, PanAppState } from '@panamax/app-state';
import { filter, switchMap, take } from 'rxjs/operators';
import { ProductService } from '../../shared/services/product/product.service';
import { selectSneakPeeks } from '../selectors/scoop.selector';
import { of } from 'rxjs';
import { SneakPeekProduct } from '../models/sneak-peek-product-view.model';

@Injectable({
  providedIn: 'root',
})
export class ScoopService {
  constructor(
    private store: Store,
    private panAppState: PanAppState,
    private productService: ProductService,
  ) {}

  loadProducts(productNumbers: number[]) {
    return this.productService.loadProducts(productNumbers);
  }

  getSneakPeeks$() {
    return this.panAppState?.accessToken$.pipe(
      filter(token => jwtDecode(token)?.['usf-claims']?.userType?.length > 0),
      take(1),
      switchMap(token => {
        const isInternalUser =
          jwtDecode(token)?.['usf-claims']?.userType === 'corp-ad';

        if (isInternalUser) {
          return this.productService.loadSneakPeek$();
        } else {
          return of(false);
        }
      }),
      switchMap(loadedSneakPeeks => {
        if (loadedSneakPeeks) {
          return this.store.select(selectSneakPeeks);
        } else {
          return of([] as SneakPeekProduct[]);
        }
      }),
    );
  }
}
