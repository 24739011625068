import {
  AnalyticsTransformer,
  TracingTransformer,
  TrackingData,
} from '@panamax/app-state';

export const defTrackTransFunc: AnalyticsTransformer = (
  tracking: TrackingData,
) => {
  let trackingObj = {
    page: {
      pageName: tracking.data.pageName,
    },
    event: tracking.data.event,
    capability: tracking.data.capability,
  };

  return trackingObj;
};

export const upcLinkTransformerFunc: AnalyticsTransformer = (
  tracking: TrackingData,
) => ({
  event: tracking.data.event,
  capability: tracking.data.capability,
  link: {
    url: tracking.data.linkUrl,
  },
});

export const inviteUserModalOpen: AnalyticsTransformer = (
  tracking: TrackingData,
) => {
  let trackingObj = {
    modal: {
      name: tracking.data.modal.name,
    },
    event: tracking.data.event,
    capability: tracking.data.capability,
  };

  return trackingObj;
};

export const inviteUserModalSubmit: AnalyticsTransformer = (
  tracking: TrackingData,
) => {
  let trackingObj = {
    event: tracking.data.event,
    capability: tracking.data.capability,
    customerCount: tracking.data.customerCount,
  };

  return trackingObj;
};

export const defTraceTransFunc: TracingTransformer = (
  tracking: TrackingData,
) => ({
  event: tracking.data.attributes?.event,
  userID: tracking.data.attributes?.userID,
});

export const defSuccessTraceTransFunc: TracingTransformer = (
  tracking: TrackingData,
) => ({
  event: tracking.data.attributes?.event,
  userID: tracking.data.attributes?.userID,
});
