<div data-cy="catch-weight-container" class="catch-weight-container">
  <svg data-cy="catch-weight-svg" viewBox="0 0 4 2" class="arrow-top">
    <polygon points="2,0 0,2 4,2" class="triangle" />
  </svg>

  <div class="main-content" data-cy="catch-weight-main-content">
    <span data-cy="catch-weight-message">
      {{ 'i18n.productDetail.catchWeight' | translate }}
    </span>
  </div>
</div>
