import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { DivisionPhoneNumber } from '@usf/customer-types/division-phone-number';
import { DIVISION_PHONE_NUMBER_ACTIONS } from '../actions/division-phone-number.actions';

export interface IDivisionPhoneNumberState
  extends EntityState<DivisionPhoneNumber> {
  loading: boolean;
  error: any;
}

export const divisionPhoneNumberAdapter: EntityAdapter<DivisionPhoneNumber> = createEntityAdapter<DivisionPhoneNumber>(
  {
    selectId: (divisionPhoneNumber: DivisionPhoneNumber) =>
      divisionPhoneNumber.divisionNumber,
  },
);

export const initialDivisionPhoneNumberState: IDivisionPhoneNumberState = divisionPhoneNumberAdapter.getInitialState(
  {
    loading: false,
    error: null,
    division: null,
  },
);

export const _divisionPhoneNumberReducer = createReducer(
  initialDivisionPhoneNumberState,
  on(DIVISION_PHONE_NUMBER_ACTIONS.getAllDivisionPhoneNumbers, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    DIVISION_PHONE_NUMBER_ACTIONS.getAllDivisionPhoneNumbersSuccess,
    (state, { divisionPhoneNumbers }) => {
      return divisionPhoneNumberAdapter.upsertMany(divisionPhoneNumbers, state);
    },
  ),
  on(
    DIVISION_PHONE_NUMBER_ACTIONS.getAllDivisionPhoneNumbersFail,
    (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    }),
  ),
);

export function divisionPhoneNumberReducer(
  state: IDivisionPhoneNumberState | undefined,
  action: Action,
): IDivisionPhoneNumberState {
  return _divisionPhoneNumberReducer(state, action);
}
