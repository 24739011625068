export enum RatingStarsCSSEnum {
  ORIGINAL_PRODUCT = 'rating-stars-original-product',
  ORIGINAL_ALTERNATIVE_PRODUCT = 'rating-stars-original-alternative-product',
  ALTERNATIVE_PRODUCT = 'rating-stars-alternative-product',
}

export enum RatingAverageCSSEnum {
  ORIGINAL_PRODUCT = 'rating-average-original-product',
  ORIGINAL_ALTERNATIVE_PRODUCT = 'rating-average-original-alternative-product',
  ALTERNATIVE_PRODUCT = 'rating-average-alternative-product',
}
