import { PlatformEnum } from '@panamax/app-state';
import { NonUsfProductHeights } from '../../../../constants/non-usf-product-constants';
import { NonUSFProductViewModel } from '../../models/non-usf-products-view-model';

export const calculateVSHeights = (
  items: NonUSFProductViewModel[],
  platform: PlatformEnum,
) => {
  let itemHeights: number[] = [];
  switch (platform) {
    case PlatformEnum.desktop:
      itemHeights = items.map(() => {
        return NonUsfProductHeights.NON_USF_PRODUCT_ITEM_DESKTOP;
      });
      itemHeights.push(NonUsfProductHeights.NON_USF_PRODUCT_FOOTER_DESKTOP);
      itemHeights.unshift(NonUsfProductHeights.NON_USF_PRODUCT_HEADER_DESKTOP);
      break;
    case PlatformEnum.tablet:
      itemHeights = items.map(() => {
        return NonUsfProductHeights.NON_USF_PRODUCT_ITEM_TABLET;
      });
      itemHeights.push(NonUsfProductHeights.NON_USF_PRODUCT_FOOTER_TABLET);
      itemHeights.unshift(NonUsfProductHeights.NON_USF_PRODUCT_HEADER_TABLET);
      break;
    case PlatformEnum.mobile:
      itemHeights = items.map(() => {
        return NonUsfProductHeights.NON_USF_PRODUCT_ITEM_MOBILE;
      });
      itemHeights.unshift(NonUsfProductHeights.NON_USF_PRODUCT_HEADER_MOBILE);
      break;
  }
  return itemHeights;
};
