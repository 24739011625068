import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SearchActionsV2 } from '@usf/ngrx-product';
import { tap } from 'rxjs/operators';
import { ProductService } from '../../shared/services/product/product.service';
import { Injectable } from '@angular/core';
import { PowerReviewService } from '@shared/services/power-review.service';

@Injectable({
  providedIn: 'root',
})
export class NewSearchPageEffects {
  searchProducts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActionsV2.FetchSearchResponseSuccess),
        tap(action => {
          const productNumbers = action?.response?.productNumbers;

          if (productNumbers?.length > 0) {
            this.productService.loadProducts(productNumbers);

            const productNumbersString = productNumbers.join(',');
            if (action.response.startIndex === 0) {
              this.powerReviewService.loadPowerReviewsProduct(
                productNumbersString,
              );
            } else {
              this.powerReviewService.updatePowerReviewsProduct(
                productNumbersString,
              );
            }
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private productService: ProductService,
    private powerReviewService: PowerReviewService,
  ) {}
}
