<div class="typeahead-search">
  <ion-searchbar
    #searchCatalog
    class="search"
    animated="true"
    autocapitalize="none"
    mode="md"
    placeholder="{{ 'i18n.header.searchCatalog' | translate }}"
    [debounce]="typeaheadDebounce"
    (ionBlur)="handleInputBlur($event)"
    (ionInput)="handleInputChange($event)"
    (ionFocus)="handleInputFocus($event)"
    (keydown.enter)="handleSearch(searchCatalog)"
    (keydown)="handleArrowKey($event)"
    data-cy="typeahead-search-input"
  ></ion-searchbar>
  <div *ngIf="typeaheadVisible && !typeaheadNoResults" class="typeahead">
    <section *ngFor="let group of customTypeAheadGroup">
      <h2 [attr.data-cy]="'group-' + group.name">{{ group.name }}</h2>
      <ul>
        <li
          *ngFor="let item of group.items; let i = index"
          (click)="handleItemClick(item, group.name, i + 1)"
          [attr.data-cy]="'click-item-' + item.description"
          [class.active]="item.isActive"
          class="active"
        >
          {{ item.description }}
        </li>
      </ul>
    </section>
  </div>
</div>
