<div
  data-cy="force-app-update-popover"
  class="force-app-update-popover_content"
  [class.tablet]="platformType === PlatformEnum.tablet"
  [class.mobile]="platformType === PlatformEnum.mobile"
>
  <!-- LOGO -->
  <img
    data-cy="force-app-update-usf-logo"
    class="usf-logo"
    src="assets/images/usfoods-logo-color.svg"
  />
  <!-- FORCE UPDATE TEXT -->
  <span data-cy="force-app-update-header-text" class="bold-font-weight">
    {{ 'i18n.forceAppUpdate.updateAvailable' | translate }}
  </span>
  <span data-cy="force-app-update-content-text" class="medium-font-weight">
    {{ 'i18n.forceAppUpdate.upgradeToLatestVersion' | translate }}
  </span>
  <!-- APP STORE BUTTON -->
  <img
    data-cy="force-app-update-app-store-logo"
    class="app-store-logo"
    [src]="appStoreLogo"
    (click)="openAppStore()"
  />
</div>
