import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ViewWillEnter, ViewDidLeave } from '@ionic/angular';
import { Subject, Observable, Subscription, take } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { TranslationResolverService } from '../shared/services/translation-resolver.service';
import { LoginService } from '@app/login/services';
import { UsfTokenStorageService } from '@panamax/app-state';
import { PunchoutSession } from '@panamax/app-state/lib/models/token.model';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.page.html',
  styleUrls: ['./logout.page.scss'],
})
export class LogoutPage implements ViewWillEnter, ViewDidLeave {
  private _loadState$: Subject<boolean>;
  loadCompleteSub$: Subscription = new Subscription();
  loadScreen: HTMLIonLoadingElement;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private loadingController: LoadingController,
    private translationResolver: TranslationResolverService,
    private tokenStorageService: UsfTokenStorageService,
  ) {
    this._loadState$ = new Subject();
    this._loadState$.next(true);
  }

  get loadState$(): Observable<boolean> {
    return this._loadState$.asObservable();
  }

  // automatically logs out with loader
  ionViewWillEnter(): void {
    this.loadCompleteSub$ = this.cleanupLoad().subscribe(state => {
      if (!state) {
        this.dismissLoading();
        this.unsubscribe();
      }
    });

    if (this.router.url === '/login') {
      this.dismissLoading();
      this._loadState$.next(false);
      this.unsubscribe();
    }

    if (this._loadState$ && this.router.url === '/logout') {
      this.presentLoading().then(() => {
        // TODO: pull out of UI code
        this.logout();
        this.unsubscribe();
      });
    }
    if (this.loadScreen) {
      this.loadScreen.onDidDismiss().then(() => {
        this._loadState$.next(false);
      });
    } else {
      this._loadState$.next(false);
    }
  }

  ionViewDidLeave(): void {
    this.dismissLoading();
    this._loadState$.next(false);
    this.unsubscribe();
  }

  async presentLoading() {
    this.loadScreen = await this.loadingController.create({
      message: 'Logging out...',
      spinner: 'circles',
      duration: 3000,
    });
    try {
      await this.loadScreen.present();
    } catch (err) {
      console.error(err);
    }
  }

  async dismissLoading() {
    this.loadScreen = await this.loadingController.getTop();
    if (this.loadScreen) {
      await this.loadScreen.dismiss(null);
      this.unsubscribe();
    }
  }

  // incase ionic logout fails. Manual logout
  logoutAction(): void {
    this.logout();
  }

  logout(): void {
    this.tokenStorageService
      .getPunchoutSession()
      .pipe(take(1))
      .subscribe((punchoutSession: PunchoutSession) => {
        const orderXML = localStorage.getItem('orderXML');
        this.translationResolver.clearTranslationFileCache();

        if (orderXML && punchoutSession?.browserFormPost) {
          this.loginService.punchout();
        } else {
          this.loginService.logout();
        }
      });
  }

  async unsubscribe() {
    if (this.loadCompleteSub$) {
      this.loadCompleteSub$.unsubscribe();
    }
  }

  cleanupLoad(): Observable<boolean> {
    return this._loadState$;
  }
}
