import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RadioBtnInventory } from '@inventory/models/radio-btn-inventory.model';
import { PlatformEnum } from '@panamax/app-state';

@Component({
  selector: 'app-multi-select-inventory-button',
  templateUrl: './multi-select-inventory-button.component.html',
  styleUrls: ['./multi-select-inventory-button.component.scss'],
})
export class MultiSelectInventoryButtonComponent {
  @Input() inventory: RadioBtnInventory;
  @Input() index: number;
  @Input() showDate: boolean;
  @Input() showDepartment: boolean;
  @Input() platform: PlatformEnum;

  @Output()
  selectInventoryEmitter: EventEmitter<RadioBtnInventory> = new EventEmitter();

  readonly platformRef = PlatformEnum;

  constructor() {}

  selectInventory() {
    this.selectInventoryEmitter.emit(this.inventory);
  }
}
