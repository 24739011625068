import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  APP_STATE_KEY,
  SelectedCustomerState,
  jwtDecode,
  selectAccessToken,
} from '@panamax/app-state';
import { AppState } from '@panamax/app-state/lib/models/app-state.model';
import { Customer } from '@usf/customer-types/customer';
import { CUSTOMER_STATE_KEY } from '../../constants/constants';
import { CustomerState } from '../../models/state/customer-state';
import { selectAllCustomers } from '../reducers/customer.reducers';
import { Dictionary } from '@ngrx/entity';

const selectCustomerState = createFeatureSelector<CustomerState>(
  CUSTOMER_STATE_KEY,
);

const selectAppState = createFeatureSelector<AppState>(APP_STATE_KEY);

export const selectAppStateUser = createSelector(
  selectAppState,
  (appState: AppState) => appState?.loggedInUser,
);

export const selectAppStateSelectedCustomer = createSelector(
  selectAppState,
  (appState: AppState) => appState?.selectedCustomer,
);

export const getCustomerEntities = createSelector(
  selectCustomerState,
  state => state.entities,
);

export const getCustomers = createSelector(
  selectCustomerState,
  selectAllCustomers,
);

export const selectCustomerWithNumber = (customerNumber: number) =>
  createSelector(getCustomers, (list: Customer[]) =>
    list.find(customer => customer?.customerNumber === customerNumber),
  );

export const selectedCustomer = createSelector(
  selectAppStateSelectedCustomer,
  getCustomerEntities,
  (customerSelected: SelectedCustomerState, entities: Dictionary<Customer>) =>
    customerSelected ? entities[customerSelected.customerNumber] : undefined,
);

export const selectedDepartment = createSelector(
  selectAppStateSelectedCustomer,
  selectedCustomer,
  (customerSelectedState: SelectedCustomerState, customer: Customer) => {
    if (!customer?.departments?.length) {
      return;
    }
    return customer.departments?.find(
      dept =>
        dept.departmentNumber ===
        customerSelectedState?.departmentNumber?.toString(),
    );
  },
);

export const getCustomerError = createSelector(
  selectCustomerState,
  (customerState: CustomerState) => customerState.error,
);

export const getCustomerLoading = createSelector(
  selectCustomerState,
  (customerState: CustomerState) => customerState.loading,
);

export const selectCustomerSwitchStatus = createSelector(
  selectCustomerState,
  (customerState: CustomerState) => customerState?.customerSwitchStatus,
);
