export const FILE_FORMAT_OPTIONS = [
  {
    description: 'CSV',
    fileType: 'csv2015',
  },
  {
    description: 'STD (Field Segment)',
    fileType: 'std',
  },
  {
    description: 'ST2 (Fixed Width) ',
    fileType: 'st2',
  },
  {
    description: 'ST3 (Fixed Width) ',
    fileType: 'st3',
  },
  {
    description: 'ST4 (Comma delimited)',
    fileType: 'st4',
  },
  {
    description: 'CMP (Field Segments)',
    fileType: 'cmp',
  },
  {
    description: 'ReMacs (Comma Delimited)',
    fileType: 'remacs',
  },
  {
    description: 'Momentus (Fixed Width)',
    fileType: 'momentus',
  },
  {
    description: 'CBORD',
    fileType: 'cbord',
  },
  {
    description: 'XML',
    fileType: 'xml',
  },
];
