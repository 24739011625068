/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
@Directive({
  selector: '[alphanumericExceptAndroid]',
})
export class AlphaNumericAndroidDirective implements OnInit {
  private regex: RegExp = new RegExp(/^([a-zA-Z0-9]+ ?)*$/);
  private isAndroid = false;
  constructor(private el: ElementRef, private platform: Platform) {}

  ngOnInit(): void {
    this.isAndroid = this.platform.is('android');
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.isAndroid) {
      let current: string = this.el.nativeElement.value;
      let next: string = current.concat(event.key);
      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
      }
    }
  }
}
