import { Component, Input } from '@angular/core';
import { PlatformEnum } from '@panamax/app-state';
import { PriceViewModel } from '@shared/models/price-view.model';

@Component({
  selector: 'app-product-shipping-price',
  templateUrl: './product-shipping-price.component.html',
  styleUrls: ['./product-shipping-price.component.scss'],
})
export class ProductShippingPriceComponent {
  @Input() pricing: PriceViewModel;
  @Input() platformType: PlatformEnum;
  @Input() disabled = true;
  @Input() isSingleLineDisplay = true;

  readonly platformEnum = PlatformEnum;
}
