import { createSelector } from '@ngrx/store';
import {
  ExternalVendorState,
  IExternalVendorState,
  selectExternalVendorsState,
} from '@usf/ngrx-product';
import { ManageVendorsModel } from '../model/manage-vendors.model';
import { selectOnline } from '@panamax/app-state';
import { SortingDirection } from '../../../../shared/constants/lists-constants';

export const externalVendorsView = () =>
  createSelector(
    selectExternalVendorsState,
    selectOnline(),
    (
      vendorState: IExternalVendorState,
      online: boolean,
    ): ManageVendorsModel => {
      let numSelectedVendors = 0;
      let vendors = Object.values(vendorState.entities);

      vendors.forEach(vendor => {
        const vendorState = vendor as ExternalVendorState;
        if (vendorState?.isSelected) {
          numSelectedVendors++;
        }
      });

      vendors.sort((a, b) => {
        let first = a[vendorState.selectedSort.columnName] as string;
        let second = b[vendorState.selectedSort.columnName] as string;
        if (vendorState.selectedSort.sortDirection === SortingDirection.asc) {
          return first?.toLowerCase() > second?.toLowerCase() ? 1 : -1;
        } else {
          return first?.toLowerCase() < second?.toLowerCase() ? 1 : -1;
        }
      });

      return {
        vendors,
        selectedSort: vendorState.selectedSort,
        nextSortStates: vendorState.nextSortStates,
        numSelectedVendors,
        online,
      };
    },
  );
