import { Component, Input } from '@angular/core';
import { ProductAlertMessage } from '../../models/product-alert-message';

@Component({
  selector: 'app-product-message-banner',
  templateUrl: './product-message-banner.component.html',
  styleUrls: ['./product-message-banner.component.scss'],
})
export class ProductMessageBannerComponent {
  @Input() alertMessage: ProductAlertMessage;
}
