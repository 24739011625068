import { ProductImages, ProductSummary } from '@usf/product-types';
import { IMAGE_CODE_INDEX } from '../constants/constants';
import { ImageVariantEnum } from '../models/image-variant.enum';
import {
  imagePriorityList,
  imageRulesLookupMap,
  packagingIndicatorCodeMap,
} from './product-image-rules';
import { ImageModel } from '@shared/models/image.model';

export const getFirstImageURL = (
  productSummary: ProductSummary,
  renditionCode: ImageVariantEnum,
): string => {
  const images: ImageModel[] = getOrderImages(productSummary);
  return !!images && images.length
    ? images[0].urls[renditionCode.toString()]
    : null;
};

export const getCarouselImages = (
  productSummary: ProductSummary,
): ImageModel[] => getOrderImages(productSummary);

export const getImageModels = (
  contextKeys: string[],
  productImages: ProductImages,
  index: number,
) => {
  return contextKeys.map(c => {
    const urls = productImages[c].renditions;
    return { code: index++, urls } as ImageModel;
  });
};

export const compareImageCodes = (code1: string, code2: string): string => {
  // we only want to be comparing image codes that end with the same character
  if (
    code1.charAt(IMAGE_CODE_INDEX.packagingIndicator).toUpperCase() !==
    code2.charAt(IMAGE_CODE_INDEX.packagingIndicator).toUpperCase()
  ) {
    return code1;
  }

  for (let property = 0; property < imagePriorityList.length; property++) {
    // need to make sure they are not equal
    if (
      imagePriorityList[property][code1.charAt(property).toUpperCase()] <
      imagePriorityList[property][code2.charAt(property).toUpperCase()]
    ) {
      return code1;
    } else if (
      imagePriorityList[property][code1.charAt(property).toUpperCase()] >
      imagePriorityList[property][code2.charAt(property).toUpperCase()]
    ) {
      return code2;
    }
  }

  // if we make it here the codes are equivalent priority
  return code1;
};

export const getHighestPriorityImages = (productImages: string[]): string[] => {
  let highestPriorityImages = {};
  productImages.forEach(imageCode => {
    let packagingIndicator = imageCode
      .charAt(IMAGE_CODE_INDEX.packagingIndicator)
      .toUpperCase();

    if (packagingIndicator !== 'X') {
      if (!(packagingIndicator in highestPriorityImages)) {
        highestPriorityImages[packagingIndicator] = imageCode;
      } else {
        highestPriorityImages[packagingIndicator] = compareImageCodes(
          imageCode,
          highestPriorityImages[packagingIndicator],
        );
      }
    }
  });

  return Object.values(highestPriorityImages);
};

export const getOrderImages = (
  productSummary: ProductSummary,
): ImageModel[] => {
  let productImages: ProductImages =
    productSummary?.productAssets?.productImages || {};
  const orderCodes: number[] = getPriorityOrders(productSummary);

  const contextKeys = getOrderedContextKeys(productImages, orderCodes);

  let index = 0;
  return contextKeys?.length >= 1
    ? getImageModels(contextKeys, productImages, index)
    : getImageModels(contextKeys, productImages, index).splice(1);
};

export const getOrderedContextKeys = (
  productImages: ProductImages,
  orderCodes: number[],
): string[] => {
  const contextKeys: string[] = getHighestPriorityImages(
    Object.keys(productImages),
  );

  contextKeys.sort((a, b) => {
    if (!!orderCodes) {
      const packagingRuleCodeIndexA = getRuleCodeIndex(a, orderCodes);
      const packagingRuleCodeIndexB = getRuleCodeIndex(b, orderCodes);
      return packagingRuleCodeIndexA - packagingRuleCodeIndexB;
    }
    return a.localeCompare(b);
  });

  return contextKeys;
};

export const getRuleCodeIndex = (
  imgCode: string,
  orderCodes: number[],
): number => {
  const minimumPriority = 6;
  const packagingRule =
    packagingIndicatorCodeMap[
      imgCode.charAt(IMAGE_CODE_INDEX.packagingIndicator)
    ];
  if (packagingRule === undefined) {
    return minimumPriority;
  }

  const ruleCode = (orderCodes ?? []).indexOf(packagingRule);
  if (ruleCode === -1) {
    return minimumPriority;
  }
  return ruleCode;
};

export const getPriorityOrders = (productSummary: ProductSummary): number[] => {
  return imageRulesLookupMap[
    productSummary?.classCode +
      '-' +
      productSummary?.categoryCode +
      '-' +
      productSummary?.groupCode
  ];
};

const badProductImages = {};
export const getAlternativeThumbnail = (
  product: ProductSummary,
  thumbnailImageURL: string,
): string => {
  let badImageSet: Set<string> =
    badProductImages[`${product?.productNumber}-${product?.divisionNumber}`];
  if (!badImageSet) {
    badImageSet = new Set<string>();
    badProductImages[
      `${product?.productNumber}-${product?.divisionNumber}`
    ] = badImageSet;
  }
  if (!!badImageSet && !badImageSet.has(thumbnailImageURL)) {
    badImageSet.add(thumbnailImageURL);
  }
  const images = getCarouselImages(product);
  const alterImage = images.find(
    m =>
      m.urls.Thumbnail !== thumbnailImageURL &&
      !badImageSet.has(m.urls.Thumbnail),
  );
  return alterImage?.urls.Thumbnail;
};
