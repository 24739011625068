import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformEnum } from '@panamax/app-state';

@Component({
  selector: 'app-delete-group-modal',
  templateUrl: './delete-group-modal.component.html',
  styleUrls: ['./delete-group-modal.component.scss'],
})
export class DeleteGroupModalComponent {
  @Input() platformType: PlatformEnum;
  @Input() numberOfProducts: number;
  @Input() groupName: string;
  readonly platformEnum = PlatformEnum;
  deleteAllProducts = true;

  constructor(private modalController: ModalController) {}

  onDeleteGroup() {
    this.modalController.dismiss({ deleteAllProducts: this.deleteAllProducts });
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}
