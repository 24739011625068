import { Injectable } from '@angular/core';
import { SharedTrackingService } from '@shared/services/analytics/shared-tracking.service';
import { Order } from '@usf/ngrx-order';
import { genericTracingTransformer } from '@shared/tracking/tracing/generic-tracing-transformer';
import { CartSyncTracingMessages } from './cart-sync-tracing.constants';
import { orderPersonalizationTransformer } from '@order/tracking/analytics/order-analytics.transformers';

@Injectable({
  providedIn: 'root',
})
export class CartSyncTracingService {
  // Recursively remove properties with undefined values from an object
  static deepRemoveUndefined(obj) {
    if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
      return obj;
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = CartSyncTracingService.deepRemoveUndefined(value);
      }
      return acc;
    }, {});
  }

  static createCartSyncTrace(
    order: Order,
    productMap: any,
    isStartOfTrace: boolean,
    isEndOfTrace: boolean,
  ) {
    const tracingProducts = SharedTrackingService.tracingProductData(
      order?.orderItems,
      productMap,
    );

    const tracking = {
      tracing: {
        data: {
          isStartOfTrace,
          isEndOfTrace,
          traceContext: CartSyncTracingMessages.CAPABILITY,
          attributes: {
            event: CartSyncTracingMessages.CART_SYNC,
            order: {
              orderId: order?.orderHeader?.orderId,
              totalAmount: order?.orderHeader?.totalPrice,
              origOrderId: order?.orderHeader?.origOrderId,
              origSplitOrderId: order?.orderHeader?.origSplitOrderId,
              tandemOrderNumber: order?.orderHeader?.tandemOrderNumber,
              requestedDeliveryDate: order?.orderHeader?.requestedDeliveryDate,
              confirmedDeliveryDate: order?.orderHeader?.confirmedDeliveryDate,
            },
            products: tracingProducts,
          },
        },
        transformFunc: genericTracingTransformer,
      },
      ...(!isStartOfTrace &&
        isEndOfTrace && {
          personalization: {
            events: [],
            transformFunc: orderPersonalizationTransformer,
          },
        }),
    };
    return CartSyncTracingService.deepRemoveUndefined(tracking);
  }

  static createCartSyncFailTrace(error: any) {
    const tracking = {
      tracing: {
        data: {
          isStartOfTrace: false,
          isEndOfTrace: true,
          traceContext: CartSyncTracingMessages.CAPABILITY,
          attributes: {
            event: CartSyncTracingMessages.CART_SYNC,
            order: {
              errorDetails: error,
            },
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    return CartSyncTracingService.deepRemoveUndefined(tracking);
  }
}
