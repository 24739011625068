<ion-header
  class="ion-no-border"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="(platformService?.isTouchExperience$() | async) === false"
      slot="end"
    >
      <ion-button
        id="close-modal-btn"
        data-cy="replace-product-close-modal-button"
        (click)="dismissModal(ButtonsEventsEnum.cancel)"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label data-cy="replace-product-label">
      {{ 'i18n.lists.replaceProduct' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="replace-product_content"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <ion-list lines="none">
    <ion-radio-group class="radio-options" [(ngModel)]="replaceAcrossLists">
      <ion-item>
        <ion-label id="replace-from-list" data-cy="replace-from-list-label">
          {{ 'i18n.lists.replaceFromThisList' | translate }}
        </ion-label>
        <ion-radio
          aria-labelledby="replace-from-list"
          mode="md"
          slot="start"
          [value]="false"
          data-cy="replace-from-list-radio"
        ></ion-radio>
      </ion-item>
      <ion-item>
        <ion-label id="replace-from-all" data-cy="replace-from-all-label">
          {{
            'i18n.lists.replaceFromAll'
              | translate: { numberOfLists: numberOfLists || 1 }
          }}
        </ion-label>
        <ion-radio
          aria-labelledby="replace-from-all"
          mode="md"
          slot="start"
          [value]="true"
          data-cy="replace-from-all-radio"
        ></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <p data-cy="replace-warming-message-text">
      {{ 'i18n.common.note' | translate }}&nbsp;
      <span>
        {{ 'i18n.lists.replaceWarning' | translate }}
      </span>
    </p>
    <ion-buttons>
      <ion-button
        id="cancel-modal-btn"
        data-cy="cancel-replace-product-modal-button"
        [ngClass]="
          platformService.platformType !== platformService.platformEnum.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismissModal(ButtonsEventsEnum.cancel)"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        id="continue-modal-btn"
        data-cy="continue-replace-product-modal-button"
        [ngClass]="
          platformService.platformType !== platformService.platformEnum.mobile
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="onReplaceProduct()"
      >
        {{ 'i18n.common.continue' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
