import { Injectable } from '@angular/core';
import { BaseDualPaneModalService } from './base/base-dual-pane-modal.service';
import { PlatformEnum, PlatformService } from '@panamax/app-state';
import {
  DualPaneFlagsConfig,
  DualPaneFunctionsConfig,
  DualPaneLabelsConfig,
  DualPaneViewModel,
  RightPaneInfo,
} from '@shared/models/dual-pane-model';
import {
  DualPaneView,
  MultiSelectItems,
} from '@shared/constants/dual-pane.enum';
import { ListDetailManagementService } from '@app/lists/shared/list-detail-management/services/list-detail-management.service';
import { ListsService } from '@app/lists/services/lists.service';
import { Product } from '@shared/models/product.model';
import { ListsPageData } from '@app/lists/model/lists-page.model';
import { ListsViewModel } from '@app/lists/model/lists-view.model';
import { BehaviorSubject, firstValueFrom, of, skip, take } from 'rxjs';
import { ListConstants, ListState } from '@usf/ngrx-list';
import { AddToListStrings } from '@shared/constants/lists-constants';
import {
  countableItemSearchDefault,
  dualPaneDefaultVerifySave,
  getSearchableObservable$,
  singleSelectRadioSearchDefault,
  updateRightPaneWithValue,
} from '@shared/components/modal-with-nav-inline/dual-pane-container/Helpers/dual-pane-helper-functions';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ListGroup } from '@usf/list-types';
import { ModalController } from '@ionic/angular';
import { ModalService } from '../modal/modal.service';
import { ModalWithNavComponent } from '@shared/components/modal-with-nav/modal-with-nav.component';
import { DualPaneModalComponent } from '@shared/components/dual-pane/dual-pane-modal/dual-pane-modal.component';
import { UserService } from '@app/user/services';
import { UserPreferencesState } from '@app/user/models/user-preferences-state';

@Injectable({
  providedIn: 'root',
})
export class AddToListModalService extends BaseDualPaneModalService {
  showAlternativeToast: boolean;
  addMultipleEntryPoint: boolean;
  addMethod: string;
  merchFeatureName?: string;
  selectedProducts = [];
  listData: ListsPageData;
  userPreferences: UserPreferencesState;
  lists = [];
  groups = [];
  useController = false;
  controllerModal;
  dismissControllerSub$;

  clearSearch = new BehaviorSubject(undefined);
  addToGroupPosition = () => {
    const reference =
      this.userPreferences?.preferences?.preferences?.positionInGroup ??
      AddToListStrings.top.valueOf();

    return [
      {
        label: 'i18n.lists.topOfTheGroup',
        value: AddToListStrings.top,
        selected: reference === AddToListStrings.top.valueOf(),
        type: MultiSelectItems.basic,
      },
      {
        label: 'i18n.lists.bottomOfTheGroup',
        value: AddToListStrings.bottom,
        selected: reference === AddToListStrings.bottom.valueOf(),
        type: MultiSelectItems.basic,
      },
    ];
  };
  constructor(
    public platformService: PlatformService,
    protected listDetailManagementService: ListDetailManagementService,
    protected listsService: ListsService,
    public translateService: TranslateService,
    private userService: UserService,
    public datePipe: DatePipe,
    private modalCtrl: ModalController,
    private modalService: ModalService,
  ) {
    super(platformService);
  }

  getListPageData$ = () => {
    return this.listsService.listsView$();
  };

  getUserPreferencesState$ = () => {
    return this.userService.userPreferencesState$();
  };

  getGroupData$ = (list: ListsViewModel) => {
    return this.listDetailManagementService.listGroupsByListKey$(
      list.listKey.listTypeId,
      list.listKey.listId.toString(),
    );
  };

  getLists = (listPageData: ListsPageData) => {
    return [
      ...listPageData?.lists?.publicLists,
      ...listPageData?.lists?.privateLists,
      ...listPageData?.lists?.internalLists,
    ].sort((a, b) => {
      if (a.listName.toUpperCase() > b.listName.toUpperCase()) {
        return 1;
      } else if (a.listName.toUpperCase() < b.listName.toUpperCase()) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  convertListToSelectableCountableItem = (list: ListsViewModel) => {
    return {
      title: list.listName,
      subtitle:
        this.translateService.instant('i18n.inventory.lastUpdated') +
        ' ' +
        this.datePipe.transform(list?.updateDtmSourceSystem, 'MM/dd/yyyy'),
      count: list.listItemCount,
    };
  };

  convertGroupsToRadioItems = (groups: ListGroup[], defaultGroup: string) => {
    return groups.map(group => {
      return {
        label: group.groupName,
        value: group?.groupName,
        selected: group?.groupName.toUpperCase() === defaultGroup.toUpperCase(),
        type: MultiSelectItems.basic,
      };
    });
  };

  getGroupLookup = (listState: ListState) => {
    return listState?.selectedGroup
      ? listState?.selectedGroup.toUpperCase()
      : ListConstants.unassignedGroup.toUpperCase();
  };

  getGroupFromGroups = (groups: ListGroup[], groupLookup: string) => {
    const group = groups.find(
      group => group?.groupName?.toUpperCase() === groupLookup,
    );
    if (!!group) {
      return group;
    } else {
      return groups[0];
    }
  };

  createAddToList = async (selectedProducts: Product[]) => {
    this.listData = await firstValueFrom(this.getListPageData$());
    this.userPreferences = await firstValueFrom(
      this.getUserPreferencesState$(),
    );
    this.selectedProducts = [...selectedProducts];
    const labelConfig: DualPaneLabelsConfig = {
      dataCy: 'add-to-list',
      title: 'i18n.lists.addToList',
      subTitle: 'i18n.lists.addToListInfo',
      submitText: 'i18n.common.submit',
      backText: 'i18n.common.save',
    };
    const functionConfig: DualPaneFunctionsConfig = {
      intialDesktopTabletView: this.buildListRightPane,
      handleSubmit: this.submitModalValues,
      verifySubmit: this.verifySubmission,
    };

    const flagConfig: DualPaneFlagsConfig = {};
    let group: ListGroup;
    if (!!this.listData.lastSelectedList) {
      this.groups = await firstValueFrom(
        this.getGroupData$(this.listData.lastSelectedList),
      );
      const groupLookup = this.getGroupLookup(this.listData?.lastSelectedList);
      group = this.getGroupFromGroups(this.groups, groupLookup);
    } else {
      this.groups = [];
    }

    const contentItems = [
      {
        label: 'i18n.lists.chooseList',
        placeHolder: 'i18n.lists.chooseList',
        subLabel: this.listData?.lastSelectedList?.listName,
        view: DualPaneView.selectableCountableRadio,
        selected: this.platformService.platformType !== PlatformEnum.mobile,
        value: this.listData?.lastSelectedList?.listName,
        onSelectfunction: this.buildListRightPane,
      },
      {
        label: 'i18n.lists.chooseGroup',
        placeHolder: 'i18n.lists.chooseGroup',
        subLabel: group?.groupName,
        view: DualPaneView.searchableItemRadio,
        selected: false,
        value: group?.groupName,
        onSelectfunction: this.buildGroupRightPane,
      },
      {
        label: 'i18n.lists.choosePosition',
        placeHolder:
          this.userPreferences?.preferences?.preferences?.positionInGroup ===
          AddToListStrings.bottom.valueOf()
            ? 'i18n.lists.bottomOfTheGroup'
            : 'i18n.lists.topOfTheGroup',
        view: DualPaneView.searchableItemRadio,
        selected: false,
        value:
          this.userPreferences?.preferences?.preferences?.positionInGroup ??
          AddToListStrings.top,
        onSelectfunction: this.buildPositionRightPane,
      },
    ];

    this.buildBasePaneWithConfigs(
      labelConfig,
      functionConfig,
      flagConfig,
      contentItems,
    );
  };

  buildListRightPane = (viewModel: DualPaneViewModel, paneSelected: string) => {
    this.lists = this.getLists(this.listData).map(
      this.convertListToSelectableCountableItem,
    );
    const searchableListModal = {
      view: DualPaneView.selectableCountableRadio,
      title: AddToListStrings.chooseList,
      itemsObservable$: of(this.lists),
      searchPane: {
        searchPlaceholder: 'i18n.lists.searchList',
        onSearchFunction: countableItemSearchDefault,
        searchBehavior: new BehaviorSubject<string>(''),
        searchKey: '',
        noResultsOverride: 'i18n.lists.noListsFound',
      },
      handleItemSelection: this.listSelection,
      verifySaveButton: dualPaneDefaultVerifySave,
    } as RightPaneInfo;

    searchableListModal.searchPane.searchableObservable$ =
      getSearchableObservable$(searchableListModal);
    updateRightPaneWithValue(viewModel, paneSelected, searchableListModal);

    const check = !!viewModel?.leftView
      ? searchableListModal.value
      : this.listData?.lastSelectedList?.listName;
    if (!!check) {
      const item = this.lists.find(value => {
        return check.toUpperCase() === value.title.toUpperCase();
      });
      searchableListModal.selectedItem = item;
    }
    return searchableListModal;
  };

  listSelection = (
    vm: DualPaneViewModel,
    platform: PlatformEnum,
    pane: RightPaneInfo,
    value: any,
  ) => {
    vm.leftView.content[0] = {
      ...vm.leftView.content[0],
      subLabel: value,
      value: value,
    };
    vm.rightView.value = value;

    if (!!value) {
      const lists = this.getLists(this.listData);
      const list = lists.find(list => {
        return list?.listName?.toUpperCase() === value?.toUpperCase();
      });
      this.listDetailManagementService
        .listGroupsByListKey$(
          list?.listKey?.listTypeId,
          list?.listKey?.listId.toString(),
        )
        .pipe(take(1))
        .subscribe(data => {
          this.groups = data;
        });
      const groupLookup = this.getGroupLookup(list);
      const group = this.getGroupFromGroups(this.groups, groupLookup);
      vm.leftView.content[1] = {
        ...vm.leftView.content[1],
        subLabel: group?.groupName,
        value: group?.groupName,
      };
    } else {
      this.groups = [];
      vm.leftView.content[1] = {
        ...vm.leftView.content[1],
        subLabel: '',
        value: undefined,
      };
    }
    return vm;
  };

  buildGroupRightPane = (
    viewModel: DualPaneViewModel,
    paneSelected: string,
  ) => {
    let selectedGroup;
    if (!!viewModel?.leftView?.content[1].value) {
      selectedGroup = viewModel?.leftView?.content[1].value?.toUpperCase();
    }

    const itemsObservable$ = of(
      this.convertGroupsToRadioItems(this.groups, selectedGroup),
    );
    const listTypeModal = {
      view: DualPaneView.searchableItemRadio,
      title: AddToListStrings.chooseGroup,
      verifySaveButton: dualPaneDefaultVerifySave,
      searchPane: {
        hideSearch: false,
        onSearchFunction: singleSelectRadioSearchDefault,
        searchBehavior: new BehaviorSubject<string>(''),
        searchKey: '',
        noResultsOverride: 'i18n.lists.noGroupsFound',
        searchPlaceholder: 'i18n.lists.searchGroup',
      },
      itemsObservable$,
      handleItemSelection: this.groupSelection,
    } as RightPaneInfo;
    listTypeModal.searchPane.searchableObservable$ =
      getSearchableObservable$(listTypeModal);
    updateRightPaneWithValue(viewModel, paneSelected, listTypeModal);
    return listTypeModal;
  };

  groupSelection = (
    vm: DualPaneViewModel,
    platform: PlatformEnum,
    pane: RightPaneInfo,
    value: any,
  ) => {
    vm.leftView.content[1] = {
      ...vm.leftView.content[1],
      subLabel: value,
      value: value,
    };
    vm.rightView.value = value;

    return vm;
  };

  buildPositionRightPane = (
    viewModel: DualPaneViewModel,
    paneSelected: string,
  ) => {
    const listTypeModal = {
      view: DualPaneView.searchableItemRadio,
      title: AddToListStrings.choosePosition,
      verifySaveButton: dualPaneDefaultVerifySave,
      searchPane: {
        hideSearch: true,
        onSearchFunction: singleSelectRadioSearchDefault,
        searchBehavior: new BehaviorSubject<string>(''),
        searchKey: '',
        searchPlaceholder: '',
        searchTitle: 'i18n.inventory.selectPosition',
      },
      itemsObservable$: of(this.addToGroupPosition()),
      handleItemSelection: this.positionSelection,
    } as RightPaneInfo;
    listTypeModal.searchPane.searchableObservable$ =
      getSearchableObservable$(listTypeModal);
    updateRightPaneWithValue(viewModel, paneSelected, listTypeModal);
    return listTypeModal;
  };

  positionSelection = (
    vm: DualPaneViewModel,
    platform: PlatformEnum,
    pane: RightPaneInfo,
    value: any,
  ) => {
    vm.leftView.content[2] = {
      ...vm.leftView.content[2],
      subLabel:
        value === AddToListStrings.top.valueOf()
          ? 'i18n.lists.topOfTheGroup'
          : 'i18n.lists.bottomOfTheGroup',
      value: value,
    };

    vm.rightView.value = value;

    return vm;
  };

  verifySubmission = (vm: DualPaneViewModel) => {
    let valid = true;
    vm.leftView.content.forEach(item => {
      if (!item.value) {
        valid = false;
      }
    });
    return valid;
  };

  async openModal(
    selectedProducts: Product[],
    showAlternativeToast: boolean,
    addMultipleEntryPoint: boolean,
    addMethod?: string,
    merchFeatureName?: string,
  ) {
    this.controllerModal = undefined;
    this.useController = false;
    this.showAlternativeToast = showAlternativeToast;
    this.addMultipleEntryPoint = addMultipleEntryPoint;
    this.addMethod = addMethod;
    this.merchFeatureName = merchFeatureName;
    await this.createAddToList(selectedProducts);
    this.isModalOpen$.next(true);
  }

  openModalViaController = async (
    selectedProducts: Product[],
    showAlternativeToast: boolean,
    addMultipleEntryPoint: boolean,
    addMethod?: string,
    merchFeatureName?: string,
  ) => {
    this.showAlternativeToast = showAlternativeToast;
    this.addMultipleEntryPoint = addMultipleEntryPoint;
    this.addMethod = addMethod;
    this.merchFeatureName = merchFeatureName;
    this.useController = true;
    await this.createAddToList(selectedProducts);
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      ModalWithNavComponent,
      {
        rootPage: DualPaneModalComponent,
        rootPageParams: this.dualPaneModalData,
      },
      'modal-desktop-size-xl',
    );

    this.controllerModal = await this.modalCtrl.create(
      this.modalService.modalOptions,
    );
    await this.controllerModal.present();
    this.controllerModal.onDidDismiss().then(() => {
      this.dismissControllerSub$?.unsubscribe();
      this.controllerModal = undefined;
    });
    this.dismissControllerSub$ = this.isModalOpen$
      .pipe(skip(1))
      .subscribe(val => {
        if (!val) this.closeModal();
      });
  };

  closeModal = () => {
    if (this.useController) {
      this.controllerModal?.dismiss();
    } else {
      this.isModalOpen$.next(false);
    }
  };

  submitModalValues = async (vm: DualPaneViewModel) => {
    const submitMap = new Map<string, any | any[]>();
    submitMap.set('list', vm.leftView.content[0]?.value);
    submitMap.set('group', vm.leftView.content[1]?.value);
    submitMap.set('position', vm.leftView.content[2]?.value);
    this.submit$.next(submitMap);
    this.closeModalAndSubmit();
  };

  closeModalAndSubmit() {
    this.closeModal();
    if (this.submit$.value !== undefined) {
      const group = this.groups.find(
        group =>
          group.groupName.toUpperCase() ===
          this.submit$.value.get('group').toUpperCase(),
      );
      const list = this.getLists(this.listData).find(list => {
        return (
          list.listName?.toUpperCase() ===
          this.submit$.value.get('list')?.toUpperCase()
        );
      });
      const position = this.submit$.value.get('position');
      this.listDetailManagementService.addProductsToList(
        list,
        group,
        this.selectedProducts,
        position,
        this.addMethod,
        this.merchFeatureName,
        this.showAlternativeToast,
        this.addMultipleEntryPoint,
      );
      this.clearSearch.next(undefined);
    }
  }
}
