import { Component, Input } from '@angular/core';
import { ModalController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { ListState } from '@usf/ngrx-list';
import { Subscription } from 'rxjs';
import { PlatformService } from '@panamax/app-state';
import { ListsService } from '../../services/lists.service';
import { ReplaceItemsViewModel } from '../list-detail-management/model/replace-items-view.model';
import { ListDetailManagementService } from '../list-detail-management/services/list-detail-management.service';
import { ButtonsEventsEnum } from '@shared/constants/product-compare.enum';

@Component({
  selector: 'app-replace-product',
  templateUrl: './replace-product.component.html',
  styleUrls: ['./replace-product.component.scss'],
})
export class ReplaceProductComponent implements ViewWillEnter, ViewWillLeave {
  @Input() productNumber: number;
  @Input() alternativeProductNumber: number;
  @Input() list: ListState;

  replaceAcrossLists = false;
  numberOfLists = 0;
  listItemSub$: Subscription = new Subscription();
  replaceItemsModel: ReplaceItemsViewModel;

  constructor(
    private modalController: ModalController,
    readonly platformService: PlatformService,
    private listsService: ListsService,
    private listDetailManagementService: ListDetailManagementService,
  ) {}

  ionViewWillEnter() {
    this.listItemSub$ = this.listsService
      .replacementProducts$(this.productNumber, this.list?.listState)
      .subscribe(data => {
        this.replaceItemsModel = data;
        this.numberOfLists = this.replaceItemsModel?.listIds?.length;
      });
  }

  onReplaceProduct() {
    if (this.replaceAcrossLists) {
      this.listDetailManagementService.replaceProductAcrossLists(
        this.replaceItemsModel.listItems,
        this.replaceItemsModel.listIds,
        this.productNumber,
        this.alternativeProductNumber,
        this.list,
      );
    } else {
      this.listDetailManagementService.replaceProduct(
        this.replaceItemsModel.listItems,
        this.productNumber,
        this.alternativeProductNumber,
        this.list,
      );
    }
    this.dismissModal(ButtonsEventsEnum.continue);
  }

  async dismissModal(event: string) {
    await this.modalController.dismiss(event);
  }

  ionViewWillLeave() {
    if (this.listItemSub$) {
      this.listItemSub$.unsubscribe();
    }
  }

  public get ButtonsEventsEnum(): typeof ButtonsEventsEnum {
    return ButtonsEventsEnum;
  }
}
