import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { FeatureFlagService } from '../services/feature-flag.service';
import { PanAppState } from '@panamax/app-state';
import { Subscription } from 'rxjs';

/**
 * @deprecated use ShowSplitDirective instead
 */
export interface FeatureFlag {
  environments?: string[];
  features?: string[];
}

@Directive({ selector: '[appPanFeature]' })
export class ShowFeatureDirective {
  /**
   * @deprecated use ShowSplitDirective instead
   */
  private hasView = false;
  private show = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlag: FeatureFlagService,
  ) {}

  /**
   * @deprecated use ShowSplitDirective instead
   */
  @Input()
  set appPanFeature(flags: FeatureFlag) {
    this.show = true;
    this.show = this.featureFlag.show(flags);
    if (this.show && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!this.show && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}

@Directive({ selector: '[appShowSplit]' })
export class ShowSplitDirective implements OnInit, OnDestroy {
  private allEnabled = true;
  private hasView = false;
  private show = false;
  private features: string[] = [];
  private featureFlagSubscription: Subscription;
  @Input()
  set appShowSplit(features: string[]) {
    this.features = features;
  }

  @Input()
  set appShowSplitAllEnabled(allEnabled: boolean) {
    this.allEnabled = allEnabled;
    this.show = true;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private panAppState: PanAppState,
  ) {}

  ngOnInit(): void {
    this.featureFlagSubscription = this.panAppState
      .feature$(this.features, this.allEnabled)
      .subscribe(val => {
        this.show = val;
        if (this.show && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (!this.show && this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.featureFlagSubscription && !this.featureFlagSubscription.closed) {
      this.featureFlagSubscription.unsubscribe();
    }
  }
}

@Directive({ selector: '[appHideSplit]' })
export class HideSplitDirective implements OnInit {
  private allEnabled = true;
  private hasView = false;
  private show = false;
  private features: string[] = [];
  @Input()
  set appHideSplit(features: string[]) {
    this.features = features;
  }

  @Input()
  set appShowSplitAllEnabled(allEnabled: boolean) {
    this.allEnabled = allEnabled;
    this.show = true;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private panAppState: PanAppState,
  ) {}

  ngOnInit(): void {
    this.panAppState.feature$(this.features, this.allEnabled).subscribe(val => {
      this.show = val;
      if (!this.show && !this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (this.show && this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }
}
