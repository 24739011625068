<span
  class="box"
  [class.invert-colors]="invertColors"
  [class.is-selected]="isSelected"
  (click)="selectCheckbox()"
>
  <ion-icon
    [class.is-selected]="isSelected"
    name="checkmark-outline"
  ></ion-icon>
</span>
