<div class="header-logout-only-popover">
  <ion-list data-cy="list-actions-logout-only">
    <ion-item
      lines="none"
      data-cy="list-actions-logout-only-item-logout"
      (click)="logout()"
    >
      <ion-label translate>{{ 'i18n.login.logOut' }}</ion-label>
    </ion-item>
  </ion-list>
</div>
