<div
  data-cy="header-menu-popover"
  class="header-menu-popover"
  (mouseleave)="dismiss()"
>
  <ion-list data-cy="header-menu-popover-list">
    <ion-item
      *ngFor="let option of menuOptions"
      [disabled]="option?.disabled"
      [ngClass]="{ disabled: option?.disabled }"
      [attr.data-cy]="
        'header-menu-popover-' +
        (option?.label | translate).toLowerCase().replaceAll(' ', '-') +
        '-button'
      "
      lines="none"
      (click)="!option?.disabled && menuOptionClick(option)"
    >
      <ion-label
        [attr.data-cy]="
          'header-menu-popover-' +
          (option?.label | translate).toLowerCase().replaceAll(' ', '-') +
          +'-label'
        "
      >
        {{ option?.label | translate }}
        <div
          *ngIf="option?.external"
          class="external-icon"
          [attr.data-cy]="
            'menu-' +
            (option?.label | translate).toLowerCase().replaceAll(' ', '-') +
            +'-external-link-icon'
          "
        ></div>
      </ion-label>
    </ion-item>
  </ion-list>
</div>

<ion-modal
  class="modal-desktop-size-xl"
  [isOpen]="importOrderModalIsOpen$ | async"
  (ionModalDidDismiss)="handleImportModalDismiss($event)"
>
  <ng-template>
    <app-import-order-modal
      (isOpenEmitter)="openImportOrderModal($event)"
      (importOrderEmitter)="importOrderHandler($event)"
      (importSuccessEmitter)="handleImportSuccess($event)"
      (initializeImportOrderStateEmitter)="initializeImportOrderState($event)"
      [fileImportErrors$]="fileImportErrors$"
      [loading$]="importOrdersLoading$"
      [loaded$]="importOrdersLoaded$"
      [orderTransactions$]="importOrdersTransactions$"
      [fileImportMessage$]="fileImportMessage$"
      [apiError$]="apiError$"
      class="ion-page"
    ></app-import-order-modal>
  </ng-template>
</ion-modal>
