import { productHasStatus } from '@usf/ngrx-product';
import { ProductInventory } from '@usf/product-types';
import { ProductAlternative } from '../models/product-alternative';

// Will return true if the product ordered is the original of a stat 0
export const isOriginalStatus0 = (
  product: ProductInventory,
  alternative: ProductAlternative,
  productNumber: number,
): boolean => {
  let ret = false;
  if (
    productHasStatus('0', product) &&
    alternative?.available &&
    alternative?.required
  ) {
    // Product is stat 0 and has a sub. Need to check if the product number is from the original or alternative
    if (product.productNumber === productNumber) {
      ret = true;
    }
  }
  return ret;
};

// Will return true is the product ordered is the sub of an original
export const didOrderSubstitute = (
  alternative: ProductAlternative,
  productNumber: number,
): boolean => {
  let ret = false;
  if (alternative?.available && alternative?.required) {
    // Product is stat 0 and has a sub. Need to check if the product number is from the original or alternative
    if (alternative?.product?.summary?.productNumber === productNumber) {
      ret = true;
    }
  }
  return ret;
};
