import { Action, createReducer, on } from '@ngrx/store';
import { AppErrorState } from 'src/app/ngrx-message/models/state/app-error-state';
import * as AppErrorActions from 'src/app/ngrx-message/store/actions/app-error/app-error.action';

export const initialAppErrorState: AppErrorState = {
  appErrors: [],
};

const appErrorReducer = createReducer(
  initialAppErrorState,
  on(AppErrorActions.upsertAppError, (state, action) => ({
    ...state,
    appErrors: [...state.appErrors, action.appError],
  })),
  on(AppErrorActions.removeAppErrors, state => ({
    ...state,
    appErrors: [],
  })),
);

export const reducer = (state: AppErrorState, action: Action) =>
  appErrorReducer(state, action);
