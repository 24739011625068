<div
  class="works-well-with-container"
  *ngIf="!!productList && productList.length > 0"
>
  <div class="works-well-with-message">
    {{ 'i18n.worksWellWith.worksWellProduct' | translate }}
  </div>
  <!-- cases: 1 www product: show thumbnail + product title -->
  <!-- 2 - 4 www products: thumbnails separated by | -->
  <!-- 4+ www products: thumbnails separated by | and +x at the end, where x is how many more www products -->
  <span class="works-well-with-products">
    <span *ngIf="worksWellWithMode === worksWellWithModes.ONE_PRODUCT_DESKTOP">
      <span class="works-well-with-product-container">
        <img src="{{ firstFourWWWProducts[0].imageThumbnail }}" />
        <p class="www-product-title">
          {{
            firstFourWWWProducts[0].summary.productDescTxtl | acronymTitleCase
          }}
        </p>
      </span>
    </span>
    <span
      *ngIf="worksWellWithMode === worksWellWithModes.TWO_PRODUCT_DESKTOP"
      class="two-product-container"
    >
      <span class="works-well-with-product-container">
        <img src="{{ firstFourWWWProducts[0].imageThumbnail }}" />
        <p class="www-product-title two-products">
          {{
            firstFourWWWProducts[0].summary.productDescTxtl | acronymTitleCase
          }}
        </p>
      </span>
      <span class="www-product-separator"></span>
      <span class="works-well-with-product-container">
        <img src="{{ firstFourWWWProducts[1].imageThumbnail }}" />
        <p class="www-product-title two-products">
          {{
            firstFourWWWProducts[1].summary.productDescTxtl | acronymTitleCase
          }}
        </p>
      </span>
    </span>
    <span
      *ngIf="
        worksWellWithMode === worksWellWithModes.THREEPLUS_PRODUCT_DESKTOP ||
        worksWellWithMode === worksWellWithModes.MOBILE
      "
    >
      <span class="works-well-with-product-container">
        <span
          *ngFor="let i of firstFourWWWProducts; let ind = index"
          class="works-well-with-thumbnail-container"
        >
          <img *ngIf="!!i.imageThumbnail" src="{{ i.imageThumbnail }}" />
          <img
            *ngIf="i.imageThumbnail === null"
            src="assets/images/no-image.svg"
          />
          <span
            *ngIf="ind !== firstFourWWWProducts.length - 1"
            class="www-product-separator"
          ></span>
        </span>
        <span class="works-well-with-additional" *ngIf="productList.length > 4">
          + {{ productList.length - 4 }}
        </span>
      </span>
    </span>
    <ion-button (click)="viewButtonClick()" class="usf-fill-green-button">{{
      'i18n.common.view' | translate
    }}</ion-button>
  </span>
</div>
