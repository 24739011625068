import { Component } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { AddToInventoryBaseComponent } from '../add-to-inventory-base.component';
import { InventoryService } from '@inventory/services/inventory.service';

@Component({
  selector: 'app-add-to-inventory-desktop-tablet',
  templateUrl: './add-to-inventory-desktop-tablet.component.html',
  styleUrls: ['./add-to-inventory-desktop-tablet.component.scss'],
})
export class AddToInventoryDesktopTabletComponent extends AddToInventoryBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    inventoryService: InventoryService,
  ) {
    super(nav, modalController, platformService, inventoryService);
  }
}
