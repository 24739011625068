/* eslint-disable @typescript-eslint/dot-notation */
import { InjectionToken } from '@angular/core';
import * as fromRouter from '@ngrx/router-store';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  INIT,
  MetaReducer,
} from '@ngrx/store';
// To run schematics update angular.json    "defaultCollectionaaa": "@ngrx/schematics",
/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
// import * as fromLayout from '@example-app/core/reducers/layout.reducer';
import {
  APP_STATE_KEY,
  TrackingService,
  appStateReducer,
  tracker,
} from '@panamax/app-state';
import { notificationReducer } from '@usf/ngrx-alerts';
import {
  LIST_GROUP_KEY,
  LIST_ITEM_KEY,
  LIST_KEY,
  MASTER_LIST_GROUP_KEY,
  MASTER_LIST_ITEM_KEY,
  MASTER_LIST_KEY,
  ORDER_GUIDE_GROUP_KEY,
  ORDER_GUIDE_ITEM_KEY,
  ORDER_GUIDE_KEY,
  RECENT_PURCHASE_KEY,
  SERVICE_REQUEST_KEY,
  listGroupReducer,
  listItemReducer,
  listReducer,
  masterListGroupReducer,
  masterListItemReducer,
  masterListReducer,
  orderGuideGroupReducer,
  orderGuideItemReducer,
  orderGuideReducer,
  recentPurchaseReducer,
  serviceRequestReducer,
} from '@usf/ngrx-list';
import {
  CART_KEY,
  NEXT_DELIVERY_KEY,
  ORDERS_FILTERS_KEY,
  ORDERS_KEY,
  cartReducer,
  nextDeliveryReducer,
  orderReducer,
  ordersFiltersReducer,
} from '@usf/ngrx-order';
import {
  PRICING_KEY,
  SELLER_PRICING_KEY,
  productPricingReducer,
  sellerPricingReducer,
} from '@usf/ngrx-pricing';
import {
  EXTERNAL_VENDOR_KEY,
  MY_PRODUCTS_KEY,
  PRODUCTS_KEY,
  PRODUCT_CHANGES_KEY,
  PRODUCT_CONTRACT_KEY,
  PRODUCT_DETAIL_KEY,
  PRODUCT_INVENTORY_KEY,
  PRODUCT_OPPORTUNITY_KEY,
  PRODUCT_SCOOP_KEY,
  PRODUCT_SUMMARY_DETAIL_KEY,
  PRODUCT_SUMMARY_KEY,
  SEARCH_RESPONSE_KEY,
  SEARCH_TYPEAHEAD_KEY,
  externalVendorReducer,
  myProductReducer,
  opportunityReducer,
  productChangesReducer,
  productContractReducer,
  productDetailReducer,
  productInventoryReducer,
  productScoopReducer,
  productSummaryDetailReducer,
  productSummaryReducer,
  searchResponseReducer,
  typeaheadReducer,
} from '@usf/ngrx-product';
import { environment } from '../../environments/environment';
import { LoginActions } from '../login/store/actions/action-types';
import {
  CUSTOMER_STATE_KEY,
  DIVISION_PHONE_NUMBER_KEY,
  DIVISION_STATE_KEY,
} from '../ngrx-customer/constants/constants';
import { reducer as CustomerReducer } from '../ngrx-customer/store/reducers/customer.reducers';
import { divisionReducer as DivisionReducer } from '../ngrx-customer/store/reducers/division.reducers';
import {
  APP_ERRORS_STATE_KEY,
  MESSAGE_STATE_KEY,
} from '../ngrx-message/constants/constants';
import { reducer as AppErrorReducer } from '../ngrx-message/store/reducers/app-error/app-error.reducer';
import { reducer as MessageReducer } from '../ngrx-message/store/reducers/message/message.reducer';
import { NOTIFICATION_COUNT_KEY } from '../notifications/constants/constants';
import { USER_STATE_KEY } from '../user/constants/constants';
import * as fromUserStore from '../user/store';
import { _divisionPhoneNumberReducer as divisionPhoneNumberReducer } from '@app/ngrx-customer/store';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', {
  factory: () => ({
    [APP_STATE_KEY]: appStateReducer,
    [USER_STATE_KEY]: fromUserStore.reducer,
    [CUSTOMER_STATE_KEY]: CustomerReducer,
    [DIVISION_STATE_KEY]: DivisionReducer,
    [LIST_KEY]: listReducer,
    [LIST_GROUP_KEY]: listGroupReducer,
    [LIST_ITEM_KEY]: listItemReducer,
    [ORDER_GUIDE_KEY]: orderGuideReducer,
    [ORDER_GUIDE_GROUP_KEY]: orderGuideGroupReducer,
    [ORDER_GUIDE_ITEM_KEY]: orderGuideItemReducer,
    [RECENT_PURCHASE_KEY]: recentPurchaseReducer,
    [EXTERNAL_VENDOR_KEY]: externalVendorReducer,
    [SERVICE_REQUEST_KEY]: serviceRequestReducer,
    [MASTER_LIST_KEY]: masterListReducer,
    [MASTER_LIST_GROUP_KEY]: masterListGroupReducer,
    [MASTER_LIST_ITEM_KEY]: masterListItemReducer,
    [PRODUCT_CHANGES_KEY]: productChangesReducer,
    [PRODUCTS_KEY]: myProductReducer,
    [MY_PRODUCTS_KEY]: myProductReducer,
    [PRODUCT_SUMMARY_DETAIL_KEY]: productSummaryDetailReducer,
    [PRODUCT_DETAIL_KEY]: productDetailReducer,
    [PRODUCT_SUMMARY_KEY]: productSummaryReducer,
    [PRODUCT_INVENTORY_KEY]: productInventoryReducer,
    [PRODUCT_CONTRACT_KEY]: productContractReducer,
    [PRODUCT_SCOOP_KEY]: productScoopReducer,
    [PRODUCT_OPPORTUNITY_KEY]: opportunityReducer,
    [ORDERS_KEY]: orderReducer,
    [ORDERS_FILTERS_KEY]: ordersFiltersReducer,
    [PRICING_KEY]: productPricingReducer,
    [SELLER_PRICING_KEY]: sellerPricingReducer,
    [MESSAGE_STATE_KEY]: MessageReducer,
    [APP_ERRORS_STATE_KEY]: AppErrorReducer,
    [NEXT_DELIVERY_KEY]: nextDeliveryReducer,
    [SEARCH_RESPONSE_KEY]: searchResponseReducer,
    [SEARCH_TYPEAHEAD_KEY]: typeaheadReducer,
    [NOTIFICATION_COUNT_KEY]: notificationReducer,
    [CART_KEY]: cartReducer,
    [DIVISION_PHONE_NUMBER_KEY]: divisionPhoneNumberReducer,
    router: fromRouter.routerReducer,
  }),
});

export const logger = (reducer: ActionReducer<State>): ActionReducer<State> => (
  state,
  action,
) => {
  const result = reducer(state, action);
  console.groupCollapsed(action.type);
  console.log('prev state', state);
  console.log('action', action);
  console.log('next state', result);
  console.groupEnd();
  return result;
};

export const reset = (reducer: ActionReducer<State>): ActionReducer<State> => (
  state,
  action,
) => {
  if (action && action.type === LoginActions.resetStore.type) {
    return reducer(
      {
        'app-state': {
          online: state['app-state'].online,
          pageTitle: state['app-state'].pageTitle,
          currentUrl: state['app-state'].currentUrl,
          previousUrl: state['app-state'].previousUrl,
          features: state['app-state'].features,
          platform: state['app-state'].platform,
        },
      },
      { type: INIT },
    );
  }
  return reducer(state, action);
};

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [reset]
  : [reset];

export const getMetaReducers = (
  trackingService: TrackingService,
): MetaReducer<State>[] =>
  !environment.production
    ? [tracker(trackingService), reset]
    : [tracker(trackingService), reset];
