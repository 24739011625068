import { createAction, props } from '@ngrx/store';
import { TokenAuthContext } from '@panamax/app-state';

export const logout = createAction('[Login] Logout User');

export const punchout = createAction('[Login] Punchout Logout User');

export const resetStore = createAction('[Login] Reset Store');

//new action to swtich customer
export const refreshTokenWithContext = createAction(
  '[Login Effect] Refresh With Context',
  props<{ authContext: TokenAuthContext }>(),
);

export const switchGuestZipCode = createAction(
  '[Login] Switch Guest Experience Zip Code',
  props<{ zipCode: number }>(),
);
