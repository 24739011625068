import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ListGroupState, ListState } from '@usf/ngrx-list';
import { PlatformService } from '@panamax/app-state';
import { ListDetailManagementService } from '../list-detail-management/services/list-detail-management.service';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss'],
})
export class CreateGroupComponent {
  @Input() groups: ListGroupState[];
  @Input() list: ListState;
  groupName: string;
  trimmedGroupName: string;
  groupNameExists = false;

  constructor(
    private modalController: ModalController,
    readonly platformService: PlatformService,
    private listDetailManagementService: ListDetailManagementService,
  ) {}

  getGroupNameExists() {
    this.trimmedGroupName = this.groupName.trim();
    this.groupNameExists = this.listDetailManagementService.groupNameExists(
      this.trimmedGroupName,
      this.groups,
    );
  }

  onCreateGroup() {
    this.listDetailManagementService.createGroup(
      this.list,
      this.trimmedGroupName,
    );
    this.dismissModal();
  }

  async dismissModal() {
    await this.modalController.dismiss(true);
  }
}
