import { Component } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { AddToInventoryBaseComponent } from '../../add-to-inventory-base.component';
import { InventoryService } from '@inventory/services/inventory.service';
@Component({
  selector: 'app-add-to-inventory-input',
  templateUrl: './add-to-inventory-input.component.html',
  styleUrls: ['./add-to-inventory-input.component.scss'],
})
export class AddToInventoryInputComponent extends AddToInventoryBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    inventoryService: InventoryService,
  ) {
    super(nav, modalController, platformService, inventoryService);
  }

  returnToSelectedAddInventoryActions() {
    this.nav.getPrevious().then(data => {
      data.params.inventoryInput = this.selectedInventory;
      data.params.groupInput = this.group;
      data.params.groupPositionInput = this.groupPosition;
      data.params.inventory = this.selectedInventory;
      this.nav.pop();
    });
  }
}
