/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[trimSpace]',
})
export class TrimSpaceDirective implements OnInit {
  private noTrailingSpaces: RegExp;
  private noLeadingSpaces: RegExp;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.noLeadingSpaces = new RegExp('^[\\s]+', 'g');
    this.noTrailingSpaces = new RegExp('[\\s]{2,}$', 'g');
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    let regexValue = event.target.value.replace(this.noTrailingSpaces, '');
    regexValue = regexValue.replace(this.noLeadingSpaces, '');
    this.el.nativeElement.value = regexValue;
    event.target.value = regexValue;
  }
}
