import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DyfProduct, dyfSelectors } from '@usf/ngrx-order';
import { ProductService } from '@shared/services/product/product.service';
import { getDayText } from '@order/selectors/dyf/dyf.selectors';
import { CART_TRACING_ACTIONS } from '@order/tracking/tracing/cart/cart-tracing.actions';
import { ORDER_ACTIONS } from '@order/actions/order-actions';

@Injectable()
export class NextDeliveryEffects {
  deliveryDateChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ORDER_ACTIONS.ChangeDeliveryDate),
        tap(props => {
          const day = getDayText(props.deliveryDate);
          this.store
            .select(dyfSelectors.selectDYF)
            .pipe(
              take(1),
              map(dyfProduct => {
                const productNumbers = this.deriveProductNumbers(
                  day,
                  dyfProduct,
                );
                this.productService.loadProducts(productNumbers);
              }),
            )
            .subscribe();
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private productService: ProductService,
    private store: Store,
  ) {}

  // Helper function to derive product numbers based on the day and DYF product
  private deriveProductNumbers(day: string, dyfProduct: DyfProduct): number[] {
    const productsAndRanks = dyfProduct.productsForDay[day];
    return productsAndRanks.map(productAndRank => productAndRank.productNumber);
  }
}
