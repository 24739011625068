/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[tabletTouchMove]',
})
export class TabletTouchMoveDirective implements OnInit, OnDestroy {
  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.el.nativeElement.addEventListener('touchmove', this.onTouchMove, {
      passive: true,
    });
  }

  ngOnDestroy() {
    this.el.nativeElement.removeEventListener('touchmove', this.onTouchMove);
  }

  onTouchMove = (event: any) => {
    event.stopPropagation();
  };
}
