import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { SelectedCustomerState, Tracking } from '@panamax/app-state';
import { OrderItem } from '@usf/ngrx-order';
import { ProductPricing } from '@usf/price-types';

@Injectable({
  providedIn: 'root',
})
export class RemoveFromOrderService {
  static createAnalytics(
    action: any,
    successAction: any,
    customer: SelectedCustomerState,
    productMap: any,
    pricing: Dictionary<ProductPricing>,
  ): Tracking {
    return {
      ...action.trackingAnalytics,
      analytics: {
        ...action.trackingAnalytics.analytics,
        data: {
          ...action.trackingAnalytics.analytics.data,
          order: {
            id: successAction?.orderHeader
              ? successAction.orderHeader.orderId
              : null,
          },
          capability: 'orders',
          products: RemoveFromOrderService.productsAnalytics(
            customer,
            action.updateOrderItemSequence,
            productMap,
            action.trackingAnalytics,
            pricing,
          ),
        },
      },
    };
  }

  static productsAnalytics(
    customer: SelectedCustomerState,
    updateOrderItem: OrderItem,
    productMap: any,
    trackingData: Tracking,
    pricing: Dictionary<ProductPricing>,
  ): {
    divisionApn: string;
    quantity: number;
    quantityUnits: string;
    attributes: string;
    name: string;
    category: string;
    price: number;
    brand: string;
  }[] {
    const product = productMap.get(updateOrderItem.productNumber);

    // For category, it needs to be in a specific format using PIM Hierarchy.
    // “Class Description;  Class Description | Category Description; Class Description | Category Description | Group Description”.
    //  ";" is a list delimiter and "|" denotes a hierarchy. This is an example:
    // CHEESE; CHEESE | CHEESE, CHEDDAR; CHEESE | CHEESE, CHEDDAR | CHEESE, CHEDDAR, OTHER SHAPES
    const category =
      `${product?.summary?.classDescription}; ${product?.summary?.classDescription} | ` +
      `${product?.summary?.categoryDescription}; ${product?.summary?.classDescription} | ` +
      `${product?.summary?.categoryDescription} | ${product?.summary?.groupDescription}`;
    const price =
      RemoveFromOrderService.quantityUnitsRemoved(updateOrderItem) === 'CS'
        ? pricing[updateOrderItem.productNumber]?.unitPrice
        : pricing[updateOrderItem.productNumber]?.eachPrice;
    return [
      {
        divisionApn:
          customer.divisionNumber + '-' + updateOrderItem.productNumber,
        quantity: RemoveFromOrderService.quantityRemoved(updateOrderItem),
        quantityUnits:
          RemoveFromOrderService.quantityUnitsRemoved(updateOrderItem),
        attributes: product?.trackingAttributes,
        name: product?.summary?.productDescTxtl,
        category,
        price,
        brand: product?.summary?.brand,
      },
    ];
  }

  static quantityUnitsRemoved(updateOrderItem: OrderItem): string {
    if (
      updateOrderItem.unitsOrdered?.currentValue <
      updateOrderItem.unitsOrdered?.previousValue
    ) {
      return 'CS';
    } else {
      return 'EA';
    }
  }

  static quantityRemoved(updateOrderItem: OrderItem): number {
    if (
      updateOrderItem.unitsOrdered?.currentValue <
      updateOrderItem.unitsOrdered?.previousValue
    ) {
      return (
        updateOrderItem.unitsOrdered?.previousValue -
        updateOrderItem.unitsOrdered?.currentValue
      );
    } else {
      return (
        updateOrderItem.eachesOrdered?.previousValue -
        updateOrderItem.eachesOrdered?.currentValue
      );
    }
  }

  static isItemRemoved(updateOrderItem: OrderItem): boolean {
    return (
      updateOrderItem.unitsOrdered?.currentValue <
        updateOrderItem.unitsOrdered?.previousValue ||
      updateOrderItem.eachesOrdered?.currentValue <
        updateOrderItem.eachesOrdered?.previousValue
    );
  }
}
