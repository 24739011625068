/* eslint-disable no-shadow */
export enum ProductFilteringEnum {
  DYF,
  INSPIRED_PICKS,
  REVIEW_ORDER_MERCH_ZONE,
  FREQUENTLY_BOUGHT_TOGETHER,
  TM_NOTES,
  PROMOTIONS,
  YOU_MAY_ALSO_NEED,
  CAB,
  SUGGESTED_PRODUCT,
  TOP_SELLERS,
  DEFAULT,

  //TODO: not implemented yet
  FORCE_UPDATE_DISPLAY,
  FORCE_UPDATE_NIGHTLY_JOB,
}
