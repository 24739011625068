import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Store } from '@ngrx/store';
import {
  PanAppState,
  PLATFORM_ACTIONS,
  UsabillaService,
} from '@panamax/app-state';
import { UsabillaSdkService } from './usabilla-sdk.service';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { buildNumber } from 'src/buildNumber';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { selectBackgroundTime } from '@panamax/app-state';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  buildNumber = buildNumber;

  constructor(
    private panAppState: PanAppState,
    private store: Store,
    private usabilla: UsabillaService,
    private usabillaSDK: UsabillaSdkService,
  ) {}

  async appForeground() {
    try {
      //get background time from state via selector
      let backgroundTime = await this.store
        .select(selectBackgroundTime)
        .pipe(take(1))
        .toPromise();

      if (backgroundTime > 0) {
        const timeInBackground = Date.now() - backgroundTime;
        console.log(
          `[Lifecycle] Time spent in background: ${timeInBackground}ms`,
        );
        this.store.dispatch(
          PLATFORM_ACTIONS.appForeground({ timeInBackground }),
        );
      }
    } catch (error) {
      // do we want to do something with this error?
      console.log('Error in appForeground');
      console.log(error);
    }
  }

  appBackground() {
    const backgroundTime = Date.now();
    this.store.dispatch(PLATFORM_ACTIONS.appBackground({ backgroundTime }));
  }

  /**
   * getCustomFeedback
   *
   * @param campaignName = the name of the campaign defined in Usabilla
   * @param resetSurvey = enable Usabilla fire event every time call the method
   * @param campaignId = the campaign id defined in Usabilla. It is required when resetSurvey param is true
   * @remarks
   * This will trigger a custom usabilla campaign, assign the default custom variables to it
   * and remove the campaign id from local storage to enable call surveys multiple times,
   * if resetSurvey is true and campaignId is defined
   */
  getCustomFeedback(
    campaignName: string,
    resetSurvey?: boolean,
    campaignId?: string,
  ) {
    this.panAppState.user$
      .pipe(
        withLatestFrom(
          this.panAppState.customer$,
          this.panAppState.currentUrl$,
          this.panAppState.logrocketUrl$,
        ),
        filter(([user, customer]) => !!user && !!customer),
        take(1),
      )
      .subscribe(([user, customer, currentUrl, logrocketUrl]) => {
        if (!Capacitor.isNativePlatform()) {
          let data = this.panAppState.buildFeedbackVariables(
            user,
            customer,
            currentUrl,
            logrocketUrl,
          );

          if (!!resetSurvey && !!campaignId) {
            this.usabilla.resetCustomSurvey(
              environment.usabillaButtonId,
              campaignId,
            );
          }
          this.usabilla.customTrigger(campaignName, data);
        } else {
          this.usabillaSDK.campaignSDKTrigger(campaignName);
          this.usabillaSDK.setUsabillaSDKVariables();
        }
      });
  }

  async openUrlInBrowser(url: string) {
    return await Browser.open({ url, presentationStyle: 'fullscreen' });
  }
}
