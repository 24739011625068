import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';

export const CART_TRACING_ACTIONS = {
  traceCartProductChangeQuantity: createAction(
    '[NGRXTrace - Order] Track Add Products To Order',
    props<{
      tracking: Tracking;
    }>(),
  ),
  traceChangePO: createAction(
    '[NGRXTrace - Order] Add PO',
    props<{ tracking: Tracking }>(),
  ),
  traceChangeDeliveryDate: createAction(
    '[NGRXTrace - Order] Change Delivery Date',
    props<{ tracking: Tracking }>(),
  ),
  traceCancelIPOrder: createAction(
    '[NGRXTrace - Order] Cancel IP Cart Order',
    props<{ tracking: Tracking }>(),
  ),
  traceCancelIPOrderSuccess: createAction(
    '[NGRXTrace - Order] Cancel IP Cart Order Success',
    props<{ tracking: Tracking }>(),
  ),
  traceCancelIPOrderFail: createAction(
    '[NGRXTrace - Order] Cancel IP Cart Order Fail',
    props<{ tracking: Tracking }>(),
  ),
};
