<div class="label">
  <span>{{ label | translate }}</span>
  <span class="required-indicator">
    {{ 'i18n.common.requiredInputIndicator' | translate }}
  </span>
</div>
<ion-item
  id="click-trigger"
  class="value-button"
  data-cy="select-value-item"
  button
  detail="false"
  (click)="clickValue($event)"
>
  <span data-cy="select-value-span">
    {{ value | translate }}
  </span>
  <ion-icon name="{{ detailIcon }}" slot="end"></ion-icon>
</ion-item>
