import { Component, Input, ViewChild } from '@angular/core';
import { LoginService } from '@app/login/services/login.service';
import { IonSearchbar } from '@ionic/angular';
import { PlatformEnum, PlatformService } from '@panamax/app-state';

@Component({
  template: '',
})
export class ZipCodeChangeBaseComponent {
  @Input() showSearchbar = true;
  @ViewChild(IonSearchbar, { static: false }) searchbar: IonSearchbar;
  zipCodeLength = 5;
  zipCodeValue = '';
  searchText = '';
  PlatformEnum = PlatformEnum;

  constructor(
    readonly platformService: PlatformService,
    readonly loginService: LoginService,
  ) {}

  isValidZipCode(): boolean {
    const validationRegex = /^[0-9]{5}$/;
    return (
      this.zipCodeValue.length === 5 && validationRegex.test(this.zipCodeValue)
    );
  }

  ionInputZipCodeValidation(event: any): void {
    const input = event.target as HTMLInputElement;

    if (input.value.length > this.zipCodeLength) {
      input.value = input.value.substring(0, this.zipCodeLength);
    }
  }

  keyDownZipCodeValidation(event: KeyboardEvent): boolean {
    const notAllowedKeys = ['.', '-', 'e', ',', 'ArrowUp', 'ArrowDown'];
    const allowedSpecialKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Enter',
    ];

    if (allowedSpecialKeys.includes(event.key)) {
      return true;
    }

    if (
      notAllowedKeys.includes(event.key) ||
      this.zipCodeValue.length === this.zipCodeLength
    ) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  keyUpZipCodeValidation(event: any): void {
    event.stopPropagation();
    event.preventDefault();

    const input = event?.target?.value?.trim()?.substring(0, 5);
    const cleanedInput = input?.replace(/[^0-9]/g, '') || '';

    event.target.value = cleanedInput;

    this.zipCodeValue = cleanedInput;
  }

  pasteZipCodeValidation(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');

    if (!/^\d+$/.test(pastedText)) {
      event.preventDefault();
    }
  }
}
