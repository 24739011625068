<!-- SORTED/FILTERED GROUP CHOICES-->
<ion-header class="ion-no-border ion-header-modal-mobile">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        id="back-modal-btn"
        data-cy="sort-filter-back-modal-button"
        text=""
        (click)="goBack()"
      >
      </ion-back-button>
    </ion-buttons>
    <ion-label data-cy="select-sort-label">
      {{ selectedSort }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-content-modal-mobile">
  <ion-list>
    <ion-radio-group class="radio-options" [(ngModel)]="selectedGroup">
      <div
        *ngIf="
          selectedSort === sortFilterType.groupAlpha ||
          selectedSort === sortFilterType.groupLine
        "
        class="groups"
      >
        <ion-item-divider></ion-item-divider>
        <ion-item>
          <ion-radio
            justify="space-between"
            mode="md"
            [value]="sortFilterType.allGroups"
            data-cy="click-all-groups-radio"
            >{{ 'i18n.lists.allGroups' | translate }}</ion-radio
          >
        </ion-item>
        <ion-item *ngFor="let group of groupNames">
          <ion-radio
            justify="space-between"
            mode="md"
            [value]="group"
            data-cy="click-sort-group-line-item"
            >{{ group }}</ion-radio
          >
        </ion-item>
      </div>
      <div *ngIf="selectedSort === sortFilterType.usFoodsClass" class="groups">
        <ion-item-divider></ion-item-divider>
        <ion-item>
          <ion-radio
            justify="space-between"
            mode="md"
            [value]="sortFilterType.allGroups"
            data-cy="click-sort-all-usf-classes-radio"
            >{{ 'i18n.lists.allUsfClasses' | translate }}</ion-radio
          >
        </ion-item>
        <ion-item *ngFor="let class of productClasses">
          <ion-radio
            justify="space-between"
            mode="md"
            [value]="class"
            data-cy="click-product-class-radio"
            >{{ class.toLocaleLowerCase() }}</ion-radio
          >
        </ion-item>
      </div>
    </ion-radio-group>
  </ion-list>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="save-modal-btn"
        data-cy="save-filter-list-options-button"
        class="usf-fill-green-mobile-modal-button"
        (click)="returntoSortFilterListOptions()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
