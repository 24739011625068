<p class="item-caption top-label">
  {{ vm?.rightView?.filePane?.fileTitle ?? '' | translate }}
</p>

<app-file-picker
  [platform]="platform"
  [dataCyTag]="dataCyTag"
  [error]="vm?.rightView?.error"
  [file]="vm?.rightView?.value"
  [showSpinner]="vm?.rightView?.filePane?.showSpinner"
  (fileAddEvent)="onAddFile()"
  (fileDeleteEvent)="onDeleteFile()"
  (updateFileEvent)="updateFile($event)"
  (processFileEvent)="processFile($event)"
>
</app-file-picker>
<p
  *ngIf="vm?.rightView?.filePane?.showExtraInfo"
  (click)="requestInfo()"
  class="template item-subtitle-regular go-green"
>
  <ion-icon name="download-outline" data-cy="template-download-icon"></ion-icon>
  {{ vm?.rightView?.filePane?.infoLabel | translate }}
</p>
