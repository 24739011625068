import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';

export const IMPORT_ORDER_ANALYTICS_ACTIONS = {
  trackImportOrderModalOpen: createAction(
    '[NGRXAnalytics Order] - Import Order Modal Open',
    props<{
      tracking: Tracking;
    }>(),
  ),
};
