<ion-header
  class="ion-no-border"
  [class.ion-header-modal-desktop]="
    platformService?.platformType === platformService?.platformEnum?.desktop
  "
  [class.ion-header-modal-tablet]="
    platformService?.platformType === platformService?.platformEnum?.tablet
  "
  [class.ion-header-modal-mobile]="
    platformService?.platformType === platformService?.platformEnum?.mobile
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="
        platformService?.platformType === platformService?.platformEnum?.desktop
      "
      slot="end"
    >
      <ion-button data-cy="list-note-close-button" (click)="dismiss()">
        <ion-icon
          data-cy="list-note-close-button-icon"
          slot="icon-only"
          name="close-outline"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label data-cy="list-note-actions-label">
      {{
        (masterListNote
          ? 'i18n.lists.note.viewTitle'
          : isEdit
          ? 'i18n.lists.note.editTitle'
          : 'i18n.lists.note.createTitle'
        ) | translate
      }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  [class.ion-content-modal-desktop]="
    platformService?.platformType === platformService?.platformEnum?.desktop
  "
  [class.ion-content-modal-tablet]="
    platformService?.platformType === platformService?.platformEnum?.tablet
  "
  [class.ion-content-modal-mobile]="
    platformService?.platformType === platformService?.platformEnum?.mobile
  "
>
  <ion-item *ngIf="!masterListNote" [class.invalid]="noteContainsEmojis">
    <ion-input
      data-cy="list-note-input"
      [placeholder]="'i18n.lists.note.max50Char' | translate"
      [maxlength]="noteLength"
      [(ngModel)]="noteToEdit"
      (ionInput)="updateNote()"
    >
    </ion-input>
  </ion-item>
  <div *ngIf="masterListNote" class="note-disclaimer">
    <p>{{ masterListNote }}</p>
    <ion-label>{{ 'i18n.lists.note.mlmDisclaimer' | translate }}</ion-label>
  </div>
  <p *ngIf="noteContainsEmojis" class="invalid-character">Invalid character</p>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    platformService?.platformType === platformService?.platformEnum?.desktop
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="list-note-cancel-button"
        [class.usf-outline-green-mobile-modal-button]="
          platformService?.platformType ===
          platformService?.platformEnum?.mobile
        "
        [class.usf-outline-green-dsktp-tblt-modal-button]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
        "
        (click)="dismiss()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        *ngIf="!masterListNote"
        data-cy="list-note-modal-save-button"
        [ngClass]="
          this.noteToEdit !== null &&
          this.noteToEdit !== undefined &&
          this.noteToEdit.length > 0 &&
          !this.noteContainsEmojis &&
          this.item?.note !== this.noteToEdit
            ? platformService?.platformType ===
              platformService?.platformEnum?.mobile
              ? 'usf-fill-green-mobile-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : platformService?.platformType ===
              platformService?.platformEnum?.mobile
            ? 'usf-fill-disabled-mobile-modal-button'
            : 'usf-fill-disabled-dsktp-tblt-modal-button'
        "
        (click)="saveNote()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
