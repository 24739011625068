<ion-item
  [attr.data-cy]="'left-view-checkbox-item-' + index"
  class="left-view-checkbox-item"
  (click)="clickValue()"
>
  <ion-checkbox
    data-cy="left-view-checkbox"
    mode="ios"
    justify="space-between"
    labelPlacement="start"
    [checked]="isChecked"
  >
    {{ label | translate }}
  </ion-checkbox>
</ion-item>
