import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import {
  selectUserKind,
  selectUserPreferences,
  selectUserProfiles,
  selectUserEmailAndPhone,
  showMLM,
  showBusinessAnalytics,
  showGL,
  showInvoiceAndPayments,
  showInvoiceAndPaymentsMenu,
  showMPP,
  showRecipesOnDemand,
  showValueAddedServices,
  selectInteractBanners,
  selectCustomMessage,
  selectCustomMessages,
  selectUserCustomizations,
  selectUserStatus,
  showTv360,
  selectUserPreferencesState,
  isSuperUser,
  selectUserProfilesState,
  showMassDelete,
  selectFoodCostRangePreferences,
  hideInvoiceAndPayments,
} from '../store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
  PanAppState,
  jwtDecode,
  selectShowInventory,
} from '@panamax/app-state';
import { FEATURES } from '../../shared/constants/splitio-features';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private store: Store<State>, private panAppState: PanAppState) {}

  userKind$ = () => this.store.select(selectUserKind);

  userStatus$ = () => this.store.select(selectUserStatus);

  userProfiles$ = () => this.store.select(selectUserProfiles);

  userProfilesState$ = () => this.store.select(selectUserProfilesState);

  userCustomizations$ = () => this.store.select(selectUserCustomizations);

  userPreferences$ = () => this.store.select(selectUserPreferences);

  userPreferencesState$ = () => this.store.select(selectUserPreferencesState);

  userPreferencesFoodCostRange$ = () =>
    this.store.select(selectFoodCostRangePreferences);

  userEmailAndPhone$ = () => this.store.select(selectUserEmailAndPhone);

  showInventory$ = () => this.store.select(selectShowInventory);

  showMoxeInventory$ = () => {
    return combineLatest([
      this.showInventory$(),
      this.panAppState.feature$([FEATURES.split_global_inventory]),
      this.panAppState.accessToken$,
    ]).pipe(
      filter(([f, f1, accessToken]) => {
        if (accessToken) {
          return true;
        } else {
          return false;
        }
      }),
      map(
        ([showInventoryCCA, globalSplitFlag]) =>
          showInventoryCCA && globalSplitFlag,
      ),
    );
  };

  showMLM$ = () => this.store.select(showMLM);

  showMPP$ = () => this.store.select(showMPP);

  showGL$ = () => this.store.select(showGL);

  showBusinessAnalytics$ = () => this.store.select(showBusinessAnalytics);

  showInvoiceAndPaymentsMenu$ = () =>
    this.store.select(showInvoiceAndPaymentsMenu);

  showInvoiceAndPayments$ = () => this.store.select(showInvoiceAndPayments);

  showRecipesOnDemand$ = () => this.store.select(showRecipesOnDemand);

  showValueAddedServices$ = () => this.store.select(showValueAddedServices);

  interactBanners$ = () => this.store.select(selectInteractBanners);

  customMessage$ = () => this.store.select(selectCustomMessage);

  customMessages$ = () => this.store.select(selectCustomMessages);

  showTv360$ = () => this.store.select(showTv360);

  isSuperUser$ = (): Observable<boolean> =>
    combineLatest([
      this.store.select(isSuperUser),
      this.userProfilesState$(),
    ]).pipe(
      filter(([_superUser, userProfilesState]) => !!userProfilesState?.loaded),
      map(([superUser, _isUserProfilesLoaded]) => superUser),
    );

  showMassDelete$ = () => this.store.select(showMassDelete);

  isGuestUser$ = (): Observable<boolean> =>
    this.panAppState.session$.pipe(
      map(currentSession => currentSession.isGuest),
    );

  hideInvoiceAndPayments$ = () => this.store.select(hideInvoiceAndPayments);
}
