<ion-header
  class="ion-no-border"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-header-modal-tablet'
      : 'ion-header-modal-mobile'
  "
>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        data-cy="back-to-vendor-form-button"
        text=""
        (click)="returnToCreateNonUsfVendorForm()"
      ></ion-back-button>
    </ion-buttons>
    <ion-label data-cy="vendor-select-state-label">
      {{ 'i18n.vendors.selectState' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="create-edit-non-usf-vendor-input_content"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-content-modal-tablet'
      : 'ion-content-modal-mobile'
  "
>
  <ion-list *ngIf="states?.length">
    <ion-item-divider></ion-item-divider>
    <ion-radio-group [(ngModel)]="selectedState">
      <ion-item *ngFor="let state of states">
        <ion-label data-cy="state-selected-text">
          {{ state }}
        </ion-label>
        <ion-radio
          mode="md"
          slot="end"
          value="{{ state }}"
          data-cy="state-selected-radio"
        ></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="save-modal-btn"
        data-cy="save-non-usf-vendor-input-button"
        [ngClass]="
          platformService.platformType === platformService.platformEnum.tablet
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        type="submit"
        (click)="returnToCreateNonUsfVendorForm()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
