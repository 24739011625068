import { ProductAlternative } from '../models/product-alternative';

import { Product } from '../../shared/models/product.model';
import { getProductReplacement } from './product-replacement.util';
import {
  filterOrSortSubs,
  getProductSubstitute,
} from './product-substitute.util';
import { AppStateForProduct } from '../../shared/models/app-state-for-product';
import { ListDataForProduct } from '../../shared/models/list-data-for-product';

export const getProductAlternative = (
  baseProduct: Product,
  productsMap: Map<number, Product>,
  appStateForProduct: AppStateForProduct,
  listDataForProduct: ListDataForProduct,
): ProductAlternative => {
  if (!baseProduct?.inventory) {
    return undefined;
  }

  const replacement = getProductReplacement(baseProduct, productsMap);

  baseProduct.inventory.substituteProducts = filterOrSortSubs(
    baseProduct,
    productsMap,
    appStateForProduct,
    listDataForProduct,
  );

  const substitute = getProductSubstitute(
    baseProduct,
    productsMap,
    appStateForProduct,
  );

  if (replacement === undefined || substitute === undefined) {
    return undefined; // CANNOT BE RESOLVED YET
  }

  // No alternative is required, return either
  if (replacement.required === false && substitute.required === false) {
    return replacement;
  }
  // Replacement required and available
  if (replacement.required && replacement.available) {
    return replacement;
  }
  // Substitute required an available
  if (substitute.required && substitute.available) {
    if (!!substitute.product.summary.baseProducts) {
      substitute.product.summary.baseProducts = Array.from(
        new Set([
          ...substitute.product.summary.baseProducts,
          baseProduct.productNumber,
        ]),
      );
    } else {
      substitute.product.summary.baseProducts = [baseProduct.productNumber];
    }
    return substitute;
  }
  // Both are required but none is available
  return replacement.required ? replacement : substitute;
};
