/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
  selector: '[percentageDirective]',
})
export class PercentageDirective implements OnInit {
  private scrubAlphaRegex: RegExp;
  private isAndroid = false;

  constructor(private el: ElementRef, private platform: Platform) {}

  ngOnInit() {
    let targetNonDigits1 = '[^\\d]';
    this.scrubAlphaRegex = new RegExp(targetNonDigits1, 'g');
    this.isAndroid = this.platform.is('android');
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    if (!this.isAndroid) {
      let regexValue = event.target.value
        .replace(this.scrubAlphaRegex, '')
        .slice(0, 3);
      this.el.nativeElement.value = regexValue;
      if (regexValue !== this.el.nativeElement.value) {
        event.target.value = regexValue;
      }
    }
  }
}
