import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PlatformEnum } from '@panamax/app-state';
import { DualPaneViewModel } from '@shared/models/dual-pane-model';
import { of } from 'rxjs';

@Component({
  selector: 'app-pick-a-file',
  templateUrl: './pick-a-file.component.html',
  styleUrls: ['./pick-a-file.component.scss', '../../dual-pane-shared.scss'],
})
export class PickAFileComponent {
  @Input() dataCyTag = 'dual-pane';
  @Input() vm: DualPaneViewModel;
  @Input() platform: PlatformEnum;

  processingData: boolean;
  badData: boolean;
  @Output() fileAddEvent = new EventEmitter<void>();
  @Output() fileDeleteEvent = new EventEmitter<void>();

  showDrag = false;

  constructor() {}
  async onDeleteFile() {
    this.fileDeleteEvent.emit();
  }

  onAddFile = async () => {
    this.fileAddEvent.emit();
  };

  processFile = async (file: File) => {
    const fileIsValid = await this.vm.rightView?.filePane?.verifyContent(
      file,
      this.vm,
    );
    if (fileIsValid) {
      this.vm.rightView.value = file;
      this.vm.rightView.filePane?.fileSelected(
        this.vm,
        this.platform,
        this.vm.rightView,
        file,
      );
    } else {
      this.vm.rightView.value = undefined;
      this.vm.rightView.error = of(true);
    }
  };

  updateFile = (file: File) => {
    this.vm.rightView.value = file;
  };

  handleFiles = async (files: FileList) => {
    const showMultipleFileError = files.length > 1;
    if (showMultipleFileError) {
      this.updateFile(undefined);
      return;
    }
    const file = files[0];
    await this.processFile(file);
  };

  async requestInfo() {
    this.vm.rightView?.filePane?.requestInfo();
  }
}
