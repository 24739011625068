import { Component, Input } from '@angular/core';
import { AppError } from '@app/ngrx-message/models/client/app-error';
import { AppErrorService } from '@app/ngrx-message/services/app-error/app-error.service';
import { ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-error-dialogue',
  templateUrl: './error-dialogue.component.html',
  styleUrls: ['./error-dialogue.component.scss'],
})
export class ErrorDialogueComponent {
  @Input() modalAppErrors: Observable<AppError[]>;

  constructor(
    private modalCtrl: ModalController,
    readonly platformService: PlatformService,
    private appErrorService: AppErrorService,
  ) {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

  dismissRoute(route?: string[]): void {
    if (route?.length) {
      this.appErrorService.redirectTo(route);
    }
    this.dismiss();
  }
}
