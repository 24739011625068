import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalController } from '@ionic/angular';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { MessageTypeEnum } from '@app/ngrx-message/constants/messageTypeEnum';
import {
  removeAppErrors,
  upsertAppError,
} from '@app/ngrx-message/store/actions/app-error/app-error.action';
import { ErrorDialogueComponent } from '@shared/components/error-dialogue/error-dialogue.component';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { AppError } from '@app/ngrx-message/models/client/app-error';
import { appErrorSelectors } from '@app/ngrx-message/store/selectors/app-error.selector';
import { Tracking } from '@panamax/app-state';
import { otelGlobalErrorMes } from '@app/ngrx-message/store/actions/message/message-tracking.action';
import { Message } from '@app/ngrx-message/models/client/message';

@Injectable({
  providedIn: 'root',
})
export class AppErrorService {
  _modalCount: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(
    private modalController: ModalController,
    readonly router: Router,
    private store: Store,
  ) {}

  // create handler for AppErrors
  appModalErrorHandler(
    title: string,
    description: string,
    dismissRoute?: string[],
    error?: any,
  ): void {
    console.error(`App Error Handler Initialized: ${title}`, error);
    const appError = {
      type: MessageTypeEnum.error,
      watermark: new Date().toUTCString(),
      modal: true,
      message: error?.message,
      stack: error?.stack,
      appErrorTitle: title,
      appErrorDescription: description,
    } as AppError;

    if (dismissRoute) {
      appError.dismissRoute = dismissRoute;
    }

    this.upsertAppError(appError);
  }

  setCount(count: number): void {
    this._modalCount.next(count);
  }

  async getCount() {
    return await firstValueFrom(this._modalCount);
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: ErrorDialogueComponent,
      cssClass: 'error-modal',
      showBackdrop: true,
      backdropDismiss: true,
      componentProps: {
        modalAppErrors: this.selectModalErrors$(),
      },
    });
    if (modal) {
      modal.onDidDismiss().then(async () => {
        this.removeAppErrors();
        let count = await this.getCount();
        if (count > 1) {
          this.modalController.dismiss();
        }
      });
    }
    await modal.present();
    console.log('ERROR: present modal');
  }

  redirectTo(path: string[]) {
    const url = window.location.pathname;
    this.router.navigate([url.split('/')[1], ...path]);
  }

  upsertAppError(appError: AppError): void {
    if (appError && !appError.id) {
      appError.id = uuidv4();
    }
    const message: Message = {
      id: appError.id,
      type: appError.type,
      display: appError.message || 'Global App Error',
      stack: appError.stack,
      watermark: appError.watermark,
      read: false,
    };

    const logData: Tracking = {
      log: {
        data: {
          message,
        },
      },
    };
    this.store.dispatch(upsertAppError({ appError }));
    this.store.dispatch(otelGlobalErrorMes({ tracking: logData }));
  }

  selectAppErrors$(): Observable<AppError[]> {
    return this.store.select(appErrorSelectors.selectAppErrors);
  }

  selectModalErrors$(): Observable<AppError[]> {
    return this.store.select(appErrorSelectors.selectModals);
  }

  removeAppErrors(): void {
    this.store.dispatch(removeAppErrors());
  }
}
