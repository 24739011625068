<ion-content *ngIf="loadState$"></ion-content>

<ion-content *ngIf="!loadState$">
  <section>
    <img src="assets/images/usfoods-logo-color.svg" class="usf-logo" />
    <h1 data-cy="logout-message-text">You have successfully logged out</h1>

    <ion-button
      class="usf-fill-green-button"
      (click)="logoutAction()"
      data-cy="logout-button"
    >
      Ok
    </ion-button>
  </section>
</ion-content>
