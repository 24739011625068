import { Component, Input, OnInit } from '@angular/core';
import { BetterBuysService } from '@app/better-buys/services/better-buys.service';
import { ModalController } from '@ionic/angular';
import { PlatformEnum } from '@panamax/app-state';
import { BetterBuyDeclineCodes } from '@shared/constants/better-buys-constants';
import { BetterBuyProduct } from '@shared/models/better-buy-card.model';
import { Product } from '@shared/models/product.model';

@Component({
  selector: 'app-usf-better-buy-decline-modal',
  templateUrl: './usf-better-buy-decline-modal.component.html',
  styleUrls: ['./usf-better-buy-decline-modal.component.scss'],
})
export class UsfBetterBuyDeclineModalComponent {
  @Input() platform: PlatformEnum;
  @Input() betterBuy: BetterBuyProduct;
  @Input() oldProduct: Product;
  codes = BetterBuyDeclineCodes.slice();

  selectedCode: number;

  readonly PlatformEnum = PlatformEnum;
  constructor(
    private modalController: ModalController,
    private betterBuysService: BetterBuysService,
  ) {}

  dismiss() {
    this.modalController.dismiss();
  }

  submit() {
    const declineCodeText = this.codes.find(
      item => item.code == this.selectedCode,
    );
    this.betterBuysService.declineBetterBuy(
      this.oldProduct,
      this.betterBuy,
      this.selectedCode,
      declineCodeText.label,
    );
    this.modalController.dismiss();
  }
}
