<ion-list
  *ngFor="let ellipsisOption of ellipsisOptions"
  [attr.data-cy]="
    'other-inventory-actions-' + ellipsisOption?.header?.toLowerCase() + '-list'
  "
  [class.other-inventory-actions-modal]="
    platform === PlatformEnum.mobile || platform === PlatformEnum.tablet
  "
  [class.mobile]="platform === PlatformEnum.mobile"
>
  <p
    [attr.data-cy]="
      'other-inventory-actions-' +
      ellipsisOption?.header?.toLowerCase() +
      '-list-header'
    "
  >
    {{ ellipsisOption?.header }}
  </p>
  <p
    class="warning-message"
    [attr.data-cy]="'other-inventory-actions-warning-message'"
  >
    {{ ellipsisOption?.warningMessage | translate }}
  </p>
  <ion-item
    *ngFor="let button of ellipsisOption?.buttons"
    [attr.data-cy]="
      'other-inventory-actions-' + button?.text?.toLowerCase() + '-list-option'
    "
    [class.disabled]="button?.disabled"
    [class.border-top]="button?.hideIcon"
    lines="none"
    (click)="button?.handler()"
  >
    <ion-icon
      src="../assets/icon/customer-locations-lightgreen.svg"
      slot="start"
      *ngIf="platform === PlatformEnum.desktop && !button?.hideIcon"
    ></ion-icon>
    <ion-label
      [attr.data-cy]="
        'other-inventory-actions-' +
        button?.text?.toLowerCase() +
        '-list-option-text'
      "
    >
      {{ button?.text }}
    </ion-label>
  </ion-item>
</ion-list>
