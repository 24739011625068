import { Component, Input } from '@angular/core';
import { TagProduct } from './models/tag-product.model';
import { ProductDetailTagsService } from '../../../product-detail/services/product-detail-tags.service';
import { ProductDetailViewModel } from '../../../product-detail/models/product-detail-view-model';

@Component({
  selector: 'app-usf-product-tag-list',
  templateUrl: './usf-product-tag-list.component.html',
  styleUrls: ['./usf-product-tag-list.component.scss'],
})
export class UsfProductTagListComponent {
  @Input() tagList: TagProduct[] = [];
  @Input() infoIconEnabled: boolean;
  @Input() product: ProductDetailViewModel;
  @Input() index: number;
  @Input() maxTags: number;
  @Input() allTagsClickable: boolean;
  @Input() showProductClaims = true;

  hiddenTags: number[] = [];

  constructor(private productDetailTagsService: ProductDetailTagsService) {}

  productTagClick(tag: TagProduct) {
    if (this.allTagsClickable) {
      this.showAllAvailableStatuses();
    } else {
      tag.clickEmitter();
    }
  }

  async showAllAvailableStatuses() {
    await this.productDetailTagsService.showAllAvailableStatuses(
      this.product,
      this.showProductClaims,
    );
  }
}
