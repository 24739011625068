import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProductAnalyticsService } from '@usf/ngrx-product';
import { ProductSummary } from '@usf/product-types';
import { UsfProductCardModeEnum } from 'src/app/shared/constants/usf-product-card-mode.enum';
import { mapProductPropertiesToAnalyticsTrackingProperties } from '../../shared/selectors/helpers/product-info.selectors.helper';
import { ProductAlternative } from '@product-detail/models/product-alternative';
import { ProductRow } from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { PATHS } from '@shared/constants/paths';
import { SubstitutionTypeEnum } from '@shared/constants/substitution-type-enum';

@Injectable({
  providedIn: 'root',
})
export class ProductAlternativeSearchService {
  constructor(
    private router: Router,
    private analyticsService: ProductAnalyticsService,
  ) {}

  async searchReplacement(
    productSummary: ProductSummary,
    mode: UsfProductCardModeEnum,
    isSubstitute: boolean = false,
    productAlternative?: ProductAlternative,
    originSearchPage?: string,
  ) {
    // Take the first 3 words of productDescTxtl and remove any comma (,)
    const searchText = this.extractReplacementSearchText(productSummary);
    const excludeProductNumbers = [productSummary.productNumber];
    const productNumber = productSummary.productNumber;
    this.trackSearchForSubs(searchText, productSummary, productAlternative);
    return this.redirectToSearchWithParams(
      searchText,
      {
        productNumber,
        mode,
        excludeProductNumbers,
        originSearchPage,
      },
      isSubstitute,
    );
  }

  async newSearchReplacement(
    productSummary: ProductSummary,
    isSubstitute?: boolean,
  ) {
    // Take the first 3 words of productDescTxtl and remove any comma (,)
    const searchText = this.extractReplacementSearchText(productSummary);
    const originalProduct = productSummary.productNumber;
    const substitutionType = isSubstitute
      ? SubstitutionTypeEnum.substituteSearch
      : SubstitutionTypeEnum.replacementSearch;
    return this.redirectToSearchForSubstitutesPageWithParams(searchText, {
      originalProduct,
      substitutionType,
    });
  }

  async searchReplacementForException(
    productSummary: ProductSummary,
    exceptionId: string,
    orderId: string,
    searchText: string = '',
  ) {
    if (searchText === '') {
      // Take the first 3 words of productDescTxtl and remove any comma (,)
      searchText = this.extractReplacementSearchText(productSummary);
    }

    const excludeProductNumbers = [productSummary.productNumber];
    const productNumber = productSummary.productNumber;
    return this.redirectToSearchWithParams(
      searchText,
      {
        productNumber,
        mode: UsfProductCardModeEnum.exceptionSearch,
        excludeProductNumbers,
        exceptionId,
        orderId,
      },
      false,
    );
  }

  async newSearchReplacementForException(
    productSummary: ProductSummary,
    exceptionId: string,
    orderId: string,
    searchText: string = '',
  ) {
    if (searchText === '') {
      // Take the first 3 words of productDescTxtl and remove any comma (,)
      searchText = this.extractReplacementSearchText(productSummary);
    }
    const substitutionType = SubstitutionTypeEnum.exceptionSearch;
    const originalProduct = productSummary.productNumber;
    return this.redirectToSearchForSubstitutesPageWithParams(searchText, {
      originalProduct,
      exceptionId,
      orderId,
      substitutionType,
    });
  }

  /**
   * Redirects the user to the search page using the cart replacement mode.
   * @param productSummary product that intends to be replaced
   * @param searchText optional search value, if not provided default search replacement logic will be applied.
   */
  async searchCartReplacement(
    productSummary: ProductSummary,
    searchText: string = '',
  ) {
    if (searchText === '') {
      // Take the first 3 words of productDescTxtl and remove any comma (,)
      searchText = this.extractReplacementSearchText(productSummary);
    }

    const excludeProductNumbers = [productSummary.productNumber];
    const productNumber = productSummary.productNumber;
    return this.redirectToSearchWithParams(
      searchText,
      {
        productNumber,
        mode: UsfProductCardModeEnum.cartReplacementSearch,
        excludeProductNumbers,
      },
      false,
    );
  }

  private async redirectToSearchWithParams(
    searchText: string,
    params: any,
    isSimpleSearch: boolean = false,
  ) {
    const url = this.getCurrentPath();
    const queryParams = isSimpleSearch
      ? { searchText }
      : { searchText, ...params };

    await this.router.navigate([url.split('/')[1], 'search'], {
      queryParams: queryParams,
    });
  }

  private async redirectToSearchForSubstitutesPageWithParams(
    searchText: string,
    params: any,
  ) {
    const url = this.getCurrentPath();
    const queryParams = { searchText, ...params };
    await this.router.navigate(
      [url.split('/')[1], 'new-search', 'substitutes'],
      {
        queryParams: queryParams,
      },
    );
  }

  private getCurrentPath(): string {
    return window.location.pathname;
  }

  private extractReplacementSearchText(productSummary: ProductSummary): string {
    return productSummary?.productDescTxtl
      ?.split(' ')
      .slice(0, 3)
      .map(word => word?.replace(/,/gi, ''))
      .filter(word => !!word)
      .join(' ');
  }

  private trackSearchForSubs(
    searchText: string,
    productSummary: ProductSummary,
    productAlternative: ProductAlternative,
  ) {
    const productAttributes = mapProductPropertiesToAnalyticsTrackingProperties(
      productSummary?.properties,
      productSummary?.additionalProperties,
    );
    let products: any[] = [
      {
        divisionApn:
          productSummary?.divisionNumber + '-' + productSummary?.productNumber,
        attributes: productAttributes,
        exceptionView: '1',
        exceptionNumberofSubs: 0,
      },
    ];

    if (!!productAlternative) {
      products = [
        {
          ...products[0],
          exceptionNumberofSubs: 1,
        },
        {
          divisionApn:
            productAlternative.product?.summary?.divisionNumber +
            '-' +
            productAlternative.product?.summary?.productNumber,
          attributes: productAlternative.product?.trackingAttributes,
          substitutionView: '1',
          substitutionParentApn:
            productSummary?.divisionNumber +
            '-' +
            productSummary?.productNumber,
          ...(!!productAlternative.substituteInfo && {
            substituteType: productAlternative.substituteInfo.substituteType,
            substitutePriority:
              productAlternative.substituteInfo.substitutePriority,
          }),
        },
      ];
    }
    this.analyticsService.trackSearchForSubs(products, searchText);
  }

  trackUserProductCompareEllipsisMenu(productData: ProductRow) {
    let products: any;
    if (!!productData && !!productData.alternative) {
      const original = {
        divisionApn:
          productData.summary.divisionNumber + '-' + productData.productNumber,
        attributes: productData.trackingAttributes,
        exceptionView: '1',
        exceptionNumberOfSubs:
          productData.inventory?.substituteProducts?.length > 0 &&
          productData.hasAlternative
            ? 1
            : 0,
      };
      const alternative = {
        divisionApn:
          productData.alternative?.product.summary.divisionNumber +
          '-' +
          productData.alternative?.product.productNumber,
        attributes: productData.alternative?.product.trackingAttributes,
        substitutionView: '1',
        substitutionParentApn:
          productData.summary.divisionNumber + '-' + productData.productNumber,
        substituteType: !!productData.alternative.substituteInfo
          ? productData.alternative.substituteInfo.substituteType
          : productData.alternative?.type,
        substitutePriority: productData.alternative?.substituteInfo
          ? productData.alternative?.substituteInfo.substitutePriority
          : 1,
      };

      products = {
        original,
        alternative,
      };

      this.analyticsService.trackUserProductCompareEllipsisMenu(products);
    }
  }
}
