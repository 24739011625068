import { Component } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { ListDetailManagementService } from 'src/app/lists/shared/list-detail-management/services/list-detail-management.service';
import { PlatformService } from '@panamax/app-state';
import { AddToListBaseComponent } from '../../add-to-list-base.component';

/**
 * @deprecated
 */
@Component({
  selector: 'app-add-to-list-input',
  templateUrl: './add-to-list-input.component.html',
  styleUrls: ['./add-to-list-input.component.scss'],
})
export class AddToListInputComponent extends AddToListBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    listDetailManagementService: ListDetailManagementService,
  ) {
    super(nav, modalController, platformService, listDetailManagementService);
  }

  returnToSelectedAddListActions() {
    this.nav.getPrevious().then(data => {
      data.params.lastSelectedListInput = this.lastSelectedList;
      data.params.groupInput = this.group;
      data.params.groupPositionInput = this.groupPosition;
      this.nav.pop();
    });
  }
}
