import { InjectionToken } from '@angular/core';
import { environment } from 'src/environments/environment';

export const NG_RX_USER_EVENTS = {
  namespace: 'Panamax',
  eventType: ['UserActivityEvent'],
};

export interface WebSocketEvent {
  eventId: string;
  correlationId: string;
  version: string;
  namespace: string;
  eventType: string;
  eventAction: string;
  data: {
    messageScope: string;
    messageIds: string[];
    namespace: string;
    eventType: string;
    eventAction: string;
    domainScope: string;
    domainIds: any[];
    watermark: string;
    messageContent: any[];
    contexts: any[];
  };
}

export interface NgRxUserLibConfig {
  apiUrl: string;
  webSocketUrl?: string;
  token?: string;
  mockApi?: boolean;
}

export const NGRX_USER_LIB_CONFIG = new InjectionToken('NGRX_USER_LIB_CONFIG');
