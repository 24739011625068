import { Component, Input, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { ListGroupState, ListItemState, ListState } from '@usf/ngrx-list';
import { PlatformService } from '@panamax/app-state';
import { SelectedGroup } from '../list-detail-management/model/selected-group.model';
import { ListDetailManagementService } from '../list-detail-management/services/list-detail-management.service';
import { ListActions } from '../../../shared/constants/lists-constants';

@Component({
  template: '',
})
export class SelectedProductsBaseComponent implements OnInit {
  @Input() selectedAction: ListActions;
  @Input() selectedProducts = [];
  @Input() groups: ListGroupState[] = [];
  @Input() selectedGroup: ListGroupState;
  @Input() listItems: ListItemState[];
  @Input() list: ListState;
  @Input() fromListDetail: boolean;

  readonly ListActions = ListActions;
  selectedGroups: SelectedGroup[] = [];
  numGroupsSelected = 0;

  constructor(
    protected nav: IonNav,
    private modalController: ModalController,
    readonly platformService: PlatformService,
    protected listDetailManagementService: ListDetailManagementService,
  ) {}

  ngOnInit() {
    let counter = 0;
    this.groups.forEach(group => {
      this.selectedGroups.push({
        groupState: group,
        items: group.listItemKeys
          ? this.listItems.slice(counter, counter + group.listItemKeys?.length)
          : [],
        isSelected:
          this.selectedGroup?.groupName === group?.groupName &&
          this.selectedAction === ListActions.delete
            ? true
            : false,
      });
      if (
        this.selectedGroup?.groupName === group?.groupName &&
        this.selectedAction === ListActions.delete
      ) {
        this.numGroupsSelected++;
      }
      if (group.listItemKeys) {
        counter += group.listItemKeys.length;
      }
    });
  }

  onSelectGroup(groupName: string, isSelected: boolean) {
    if (this.selectedAction !== ListActions.copy) {
      this.resetSelectedGroups();
    }
    this.selectedGroups.forEach(entry => {
      if (entry?.groupState?.groupName === groupName) {
        entry.isSelected = !isSelected;
        if (entry.isSelected) {
          this.numGroupsSelected++;
        } else {
          if (this.numGroupsSelected !== 0) {
            this.numGroupsSelected--;
          }
        }
      }
    });
  }

  resetSelectedGroups() {
    this.selectedGroups.forEach(group => {
      group.isSelected = false;
    });
  }

  dispatchAction() {
    if (this.selectedAction === ListActions.move) {
      this.moveProductsToGroup();
    } else if (this.selectedAction === ListActions.copy) {
      this.copyProductsToGroup();
    } else if (this.selectedAction === ListActions.delete) {
      this.deleteProductsFromGroup();
    }
  }

  moveProductsToGroup() {
    const selectedItems: ListItemState[] = [];
    let targetGroup: ListGroupState;
    let sourceGroup: ListGroupState;
    this.selectedGroups.forEach(group => {
      if (group.isSelected) {
        this.groups.forEach(group2 => {
          if (group2.groupName === this.selectedGroup?.groupName) {
            sourceGroup = group2;
          }
          if (group?.groupState?.groupName === group2.groupName) {
            targetGroup = group2;
          }
        });
      }
    });
    this.selectedProducts.forEach(product => {
      selectedItems.push(product.item);
    });
    this.listDetailManagementService.moveItemsToAnotherGroup(
      this.list,
      targetGroup,
      sourceGroup,
      selectedItems,
      this.listItems,
      false,
    );
    this.dismissModal();
  }

  copyProductsToGroup() {
    this.listDetailManagementService.copyProducts(
      this.list,
      this.selectedGroup.listGroupKey,
      this.selectedGroups,
      this.selectedProducts,
    );
    this.dismissModal();
  }

  async deleteProductsFromGroup() {
    let selectedGroup: SelectedGroup;
    this.selectedGroups.forEach(group => {
      if (group.isSelected) {
        selectedGroup = group;
      }
    });
    await this.modalController.dismiss({
      selectedGroup,
      selectedAction: this.selectedAction,
    });
  }

  showGroup(group: SelectedGroup): boolean {
    if (
      (group?.groupState?.groupName !== this.selectedGroup?.groupName &&
        (this.selectedAction === ListActions.move ||
          this.selectedAction === ListActions.copy)) ||
      this.selectedAction === ListActions.delete
    ) {
      return true;
    }
    return false;
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }
}
