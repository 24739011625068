import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Message } from '../../../../ngrx-message/models/client/message';
import { PlatformService } from '@panamax/app-state';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  @Input() message: Message;

  constructor(
    private modalCtrl: ModalController,
    readonly platformService: PlatformService,
  ) {}

  dismiss() {
    const msg = { ...this.message };
    msg.read = true;
    this.modalCtrl.dismiss(msg);
  }
}
