import { Product } from '@shared/models/product.model';
import { InventoryGroupItem } from '@usf/inventory-types';
import { ProductPropertiesEnum } from '@usf/product-types';

// ========== Inventory Item creation and pricing code ========

// call when creating a new Inventory
export const createInventoryItemWithProductInformation = (
  product: Product,
  pricingSystem: string = 'LP',
  nonUSF: boolean,
) => {
  let uom = product.summary.eachUom !== 'CS' ? product.summary.eachUom : 'EA';
  let inventoryItem = {
    itemNumber: product.productNumber,
    overrideCasePrice: undefined,
    priceOverrideInd: 'N',
    itemUpdateDtm: new Date(),
    productConvFactor: product.inventory?.eachConversionFactor ?? 1,
    productUnitOfMeasure: uom,
    productCasePrice: -1,
    productUnitPrice: -1,
    productBreakable: product.summary?.breakable,
    productPackSizeLabel: product.summary?.salesPackSize,
    productCatchWeightFlag: product.summary?.catchWeightFlag,
    nonUsfFlag: nonUSF,
  } as InventoryGroupItem;
  inventoryItem = enrichProductGroupItemWithPricing(
    inventoryItem,
    product,
    pricingSystem,
  );

  return inventoryItem;
};

// call when updating inventory pricing (not override)
// call after override is removed
export const updateInventoryItemWithProductInformation = (
  inventoryItem: InventoryGroupItem,
  product: Product,
  pricingSystem: string = 'LP',
) => {
  if (
    inventoryItem.nonUsfFlag ||
    (product.summary?.productDescTxtl === undefined &&
      product.summary?.productDescLong === undefined)
  ) {
    return inventoryItem;
  }
  let inventoryItemupdate = {
    ...inventoryItem,
    productPackSizeLabel:
      product.summary?.salesPackSize ?? inventoryItem.productPackSizeLabel,
    productCasePrice: -1,
    productUnitPrice: -1,
  } as InventoryGroupItem;

  if (inventoryItemupdate.productUnitOfMeasure === undefined) {
    inventoryItemupdate.productUnitOfMeasure =
      product.summary?.eachUom !== 'CS' ? product.summary?.eachUom : 'EA';
  }
  if (inventoryItemupdate.productBreakable === undefined) {
    inventoryItemupdate.productBreakable = product.summary?.breakable;
  }
  if (inventoryItemupdate.productCatchWeightFlag === undefined) {
    inventoryItemupdate.productCatchWeightFlag =
      product.summary?.catchWeightFlag;
  }
  if (inventoryItemupdate.productConvFactor === undefined) {
    inventoryItemupdate.productConvFactor =
      product.inventory?.eachConversionFactor ?? 1;
  }

  inventoryItemupdate = enrichProductGroupItemWithPricing(
    inventoryItemupdate,
    product,
    pricingSystem,
  );

  return inventoryItemupdate;
};

// override value is set in NGRX layer and is built into product selection logic
// this pricing block is for calculating product pricing
export const enrichProductGroupItemWithPricing = (
  itemToEnrich: InventoryGroupItem,
  product: Product,
  pricingSystem: string,
): InventoryGroupItem => {
  // recent is default so if not MP attempt recent -- if no recent found default back to market
  if (itemToEnrich.nonUsfFlag) {
    enrichNonUSF(itemToEnrich, product);
  } else if (
    product?.summary?.properties?.has(ProductPropertiesEnum.specialOrder)
  ) {
    // do nothing, we want the price to be -1 for call to order
  } else if (itemToEnrich.productCatchWeightFlag) {
    enrichWithCatchWeight(itemToEnrich, product, pricingSystem);
  } else if (pricingSystem === 'MP' && product.pricing) {
    enrichWithMarket(itemToEnrich, product);
  } else if (product.recentPurchase) {
    enrichWithRecent(itemToEnrich, product);
  } else if (product.pricing) {
    enrichWithMarket(itemToEnrich, product);
  }

  // Seeing string typing or case price in db -- if found switch to number
  if (typeof itemToEnrich.productCasePrice === 'string') {
    itemToEnrich.productCasePrice = new Number(
      itemToEnrich.productCasePrice,
    ).valueOf();
  }
  if (typeof itemToEnrich.productUnitPrice === 'string') {
    itemToEnrich.productUnitPrice = new Number(
      itemToEnrich.productUnitPrice,
    ).valueOf();
  }

  // cannot accept 0 as a price
  if (itemToEnrich.productCasePrice === 0) {
    itemToEnrich.productCasePrice = -1;
  }

  // cannot accept 0 as a price
  if (itemToEnrich.productUnitPrice === 0) {
    itemToEnrich.productUnitPrice = -1;
  }

  // reset override if new product price equals override price
  if (
    !itemToEnrich.productCatchWeightFlag &&
    itemToEnrich.priceOverrideInd === 'Y' &&
    itemToEnrich.productCasePrice === itemToEnrich.overrideCasePrice
  ) {
    itemToEnrich.priceOverrideInd = 'N';
    itemToEnrich.overrideCasePrice = undefined;
  }

  if (
    itemToEnrich.productCatchWeightFlag &&
    itemToEnrich.priceOverrideInd === 'Y' &&
    itemToEnrich.productUnitPrice === itemToEnrich.overrideCasePrice
  ) {
    itemToEnrich.priceOverrideInd = 'N';
    itemToEnrich.overrideCasePrice = undefined;
  }

  return itemToEnrich;
};

export const enrichNonUSF = (
  inventoryItem: InventoryGroupItem,
  product: Product,
) => {
  inventoryItem.productBreakable = false;
  if (product.pricing && product.pricing.unitPrice) {
    inventoryItem.productCasePrice = product.pricing.unitPrice;
    if (product.pricing.eachPrice && product.pricing.eachPrice !== 0) {
      inventoryItem.productUnitPrice = product.pricing.eachPrice;
    }
  } else {
    inventoryItem.productCasePrice = -1;
  }
};

export const enrichWithRecent = (
  inventoryItem: InventoryGroupItem,
  product: Product,
) => {
  if (product.recentPurchase && product.recentPurchase.purchasePrice1) {
    inventoryItem.productCasePrice = product.recentPurchase.purchasePrice1;
  } else if (product.pricing && product.pricing.unitPrice) {
    inventoryItem.productCasePrice = product.pricing.unitPrice;
    if (
      product.summary.breakable &&
      product.pricing.eachPrice &&
      product.pricing.eachPrice !== 0
    ) {
      inventoryItem.productUnitPrice = product.pricing.eachPrice;
    }
  } else {
    inventoryItem.productCasePrice = -1;
  }
};

export const enrichWithMarket = (
  inventoryItem: InventoryGroupItem,
  product: Product,
) => {
  if (product.pricing && product.pricing.unitPrice) {
    inventoryItem.productCasePrice = product.pricing.unitPrice;
    if (
      product.summary.breakable &&
      product.pricing.eachPrice &&
      product.pricing.eachPrice !== 0
    ) {
      inventoryItem.productUnitPrice = product.pricing.eachPrice;
    }
  } else {
    inventoryItem.productCasePrice = -1;
  }
};

// Do not use each price with catchWeight when in market, only use unit price.
export const enrichWithCatchWeight = (
  inventoryItem: InventoryGroupItem,
  product: Product,
  pricingSystem: string,
) => {
  inventoryItem.productCasePrice = -1;
  inventoryItem.productConvFactor = 1;
  inventoryItem.productUnitOfMeasure = 'LB';

  if (pricingSystem === 'LP' && product?.recentPurchase?.purchasePrice1) {
    inventoryItem.productUnitPrice = product.recentPurchase.purchasePrice1;
  } else if (pricingSystem === 'MP' && product.pricing) {
    inventoryItem.productUnitPrice = product.pricing.unitPrice;
  } else if (product?.recentPurchase?.purchasePrice1) {
    inventoryItem.productUnitPrice = product.recentPurchase.purchasePrice1;
  } else {
    inventoryItem.productUnitPrice = product.pricing.unitPrice;
  }
};

export const extractPackSizeValue = (packSize: string): number => {
  let numberToReturn = 1;
  if (!packSize) {
    return numberToReturn;
  }
  // 50/20 pieces -> 50
  if (packSize.split('/').length > 1) {
    numberToReturn = new Number(packSize.split('/')[0]).valueOf();
    // 25 LB -> 25
  } else if (packSize.split(' ').length > 1) {
    numberToReturn = new Number(packSize.split(' ')[0]).valueOf();
  }

  if (isNaN(numberToReturn)) {
    numberToReturn = 1;
  }

  return numberToReturn;
};

export const isConversionValueValid = (value: any): boolean => {
  if (value !== undefined && value !== null && value !== 0) {
    return true;
  } else {
    return false;
  }
};
