import { Component } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ListActions } from '../../../../shared/constants/lists-constants';
import { ListDetailManagementService } from '../../list-detail-management/services/list-detail-management.service';
import { SelectedProductsBaseComponent } from '../selected-products-base.component';

@Component({
  selector: 'app-selected-products-desktop-tablet',
  templateUrl: './selected-products-desktop-tablet.component.html',
  styleUrls: ['./selected-products-desktop-tablet.component.scss'],
})
export class SelectedProductsDesktopTabletComponent extends SelectedProductsBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    listDetailManagementService: ListDetailManagementService,
  ) {
    super(nav, modalController, platformService, listDetailManagementService);
  }

  changeSelectedProductsAction(action: ListActions) {
    this.selectedAction = action;
    this.numGroupsSelected = 0;
    this.resetSelectedGroups();
  }
}
