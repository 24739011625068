<ion-header
  class="ion-no-border"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="(platformService?.isTouchExperience$() | async) === false"
      slot="end"
    >
      <ion-button
        id="close-modal-btn"
        data-cy="close-delete-list-modal-button"
        (click)="dismissModal()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content
  data-cy="modal-prompt-area-text"
  class="simple-action-modal_content"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <div class="content">
    <img
      class="alert-icon-border"
      src="../../../../../assets/images/alert-yellow.svg"
    />

    <div>
      <h3 data-cy="delete-list-label">
        {{ 'i18n.lists.deleteList' | translate }}
      </h3>

      <p data-cy="confirm-delete-label">
        {{ 'i18n.lists.areYouSureDelete' | translate }}&nbsp;<em>{{
          list?.listName
        }}</em
        >?
      </p>

      <p class="note" data-cy="recover-lists-text">
        {{ 'i18n.common.note' | translate }}&nbsp;
        <span>{{ 'i18n.lists.recoverDeletedLists' | translate }}</span>
      </p>
    </div>
  </div>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    (platformService?.isTouchExperience$() | async) === false
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="no-modal-btn"
        data-cy="no-delete-list-modal-button"
        [ngClass]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismissModal()"
      >
        {{ 'i18n.common.no' | translate }}
      </ion-button>
      <ion-button
        id="yes-modal-btn"
        data-cy="yes-delete-list-modal-button"
        [ngClass]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="onDeleteList()"
      >
        {{ 'i18n.common.yes' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
