<h1
  class="ato-header bold"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'tablet'
      : 'mobile'
  "
  data-cy="product-compare-add-to-order-header"
>
  {{ 'i18n.search.addToOrder' | translate }}
</h1>
<div
  class="ato-container inner-content"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'tablet'
      : 'mobile'
  "
>
  <div class="ato-left-column">
    <div
      *ngIf="!!productDetails.productCardWarningMsg.primaryText"
      class="ato-product-warning"
    >
      <ion-icon
        name="alert-circle-outline"
        data-cy="product-compare-add-to-order-warning-alert-icon"
      ></ion-icon>
      <span data-cy="product-compare-add-to-order-warning-message">
        <strong
          >{{ productDetails.productCardWarningMsg.primaryText | translate }}
        </strong>
        {{
          productDetails.productCardWarningMsg.secondaryText || '' | translate
        }}
      </span>
      <span
        *ngIf="productDetails?.productCardWarningMsg?.secondaryTextDate"
        data-cy="product-compare-add-to-order-warning-message-subtext-2"
      >
        &nbsp;{{
          productDetails?.productCardWarningMsg?.secondaryTextDate | translate
        }}
      </span>
      <span *ngIf="!!productDetails.productCardWarningMsg.boldedText">
        <b>{{ productDetails.productCardWarningMsg.boldedText | translate }}</b>
      </span>
    </div>
    <div class="ato-main-row">
      <div class="ato-img-container">
        <img
          *ngIf="
            (panAppState.online$ | async) === true &&
              !!productDetails?.imageThumbnail;
            else NoImageIcon
          "
          [attr.data-cy]="'product-' + productDetails?.productNumber + '-image'"
          src="{{ productDetails?.imageThumbnail }}"
          (error)="
            productDetails.imageThumbnail = getAternativeThumbnail(
              productDetails?.imageThumbnail
            )
          "
          data-cy="product-compare-add-to-order-thumbnail"
        />
      </div>
      <div class="ato-product-info">
        <div
          data-cy="product-compare-add-to-order-brand-text"
          class="ato-brand-text"
        >
          {{ productDetails?.summary?.brand || ' ' | acronymTitleCase }}
        </div>
        <div
          class="ato-product-title bold"
          data-cy="product-compare-add-to-order-product-title"
        >
          {{
            productDetails?.summary?.productDescTxtl
              | translate
              | acronymTitleCase
          }}
        </div>
        <div class="ato-product-metadata">
          <div class="ato-product-num-small">
            <ng-container *ngTemplateOutlet="ProductNumDropdown"></ng-container>
          </div>
          <div class="ato-product-num-large">
            <ng-container
              *ngTemplateOutlet="ProductNumberPlainText"
            ></ng-container>
          </div>
          <img
            data-cy="ato-separator-image"
            src="assets/images/separator.png"
          />
          <span data-cy="product-compare-add-to-order-pack-size">
            {{ productDetails.summary.salesPackSize }}
          </span>
          <ng-container *ngIf="!!productDetails.costBreakdown">
            <img
              class="ato-price-per-portion"
              data-cy="ato-separator-image"
              src="assets/images/separator.png"
            />
            <span
              class="ato-price-per-portion"
              data-cy="product-compare-add-to-order-price-per-portion"
            >
              {{ productDetails.costBreakdown }}
            </span>
          </ng-container>
        </div>
        <div
          *ngIf="!!productDetails.vendorMsg"
          class="ato-product-message"
          data-cy="product-compare-add-to-order-vendor-message"
        >
          {{ productDetails.vendorMsg }}
        </div>
      </div>
    </div>
  </div>
  <div class="ato-price-container">
    <div class="price-col">
      <app-quantity-input
        [productNumber]="productDetails?.summary?.productNumber"
        [isEaches]="false"
        [platform]="platformService.platformType"
        [quantity]="orderItem?.unitsOrdered"
        [isDisabled]="false"
        [isOffline]="false === (panAppState.online$ | async)"
        (quantityUpdateEmitter)="changeQuantityHandler($event)"
      ></app-quantity-input>

      <app-price-display
        [price]="productDetails?.pricing"
        [displayCases]="true"
        [catchWeightFlag]="productDetails?.summary?.catchWeightFlag"
        [priceUom]="productDetails?.summary?.priceUom"
      ></app-price-display>
    </div>

    <div
      *ngIf="productDetails?.summary?.breakable"
      class="price-col"
      data-cy="product-compare-add-to-order-each-quantity-and-price"
    >
      <app-quantity-input
        [productNumber]="productDetails?.summary?.productNumber"
        [isEaches]="true"
        [platform]="platformService.platformType"
        [quantity]="orderItem?.eachesOrdered"
        [isDisabled]="false"
        [isOffline]="false === (panAppState.online$ | async)"
        (quantityUpdateEmitter)="changeQuantityHandler($event)"
      ></app-quantity-input>

      <app-price-display
        [price]="productDetails?.pricing"
        [displayCases]="false"
        [catchWeightFlag]="productDetails?.summary?.catchWeightFlag"
        [priceUom]="productDetails?.summary?.priceUom"
      ></app-price-display>
    </div>
  </div>
</div>

<!-- Templates -->
<ng-template #NoImageIcon>
  <ion-icon
    data-cy="product-compare-add-to-order-no-image-icon"
    class="no-image"
    name="restaurant"
  ></ion-icon>
</ng-template>
<ng-template #ProductNumDropdown>
  <div
    *ngIf="productDetails.customerProductNumber; else ProductNumberPlainText"
    data-cy="product-compare-add-to-order-nums-dropdown"
    class="prod-nums-dropdown"
    (click)="setProductCardNumSectionExpanded()"
  >
    <div
      data-cy="product-compare-add-to-order-product-numbers-dropdown-top-section"
      class="product-number-top"
      [class.minimized]="!isProductCardNumSectionExpanded"
      (click)="setSelectedNumberToShow($event)"
    >
      <div
        data-cy="product-compare-add-to-order-product-numbers-dropdown-text-section"
      >
        <p
          [attr.data-cy]="
            'product-compare-add-to-order-product-' + numToShowTrimmed + '-text'
          "
        >
          {{ numToShow }}
        </p>
      </div>
      <ion-icon
        *ngIf="!isProductCardNumSectionExpanded"
        [attr.data-cy]="
          'product-compare-add-to-order-gl-customer-product-number-' +
          numToShow +
          '-icon'
        "
        name="caret-down-outline"
      ></ion-icon>
    </div>
    <div
      *ngIf="isProductCardNumSectionExpanded"
      data-cy="product-compare-add-to-order-numbers-dropdown-bottom-section"
      class="product-number-bottom"
      (click)="setSelectedNumberToShow($event)"
    >
      <p
        [attr.data-cy]="
          'product-compare-add-to-order-product-' + numToHideTrimmed + '-text'
        "
      >
        {{ numToHide }}
      </p>
    </div>
  </div>
</ng-template>

<ng-template #ProductNumberPlainText>
  <span>#{{ productDetails.productNumber }}</span>
  <img
    *ngIf="!!productDetails.customerProductNumber"
    src="assets/images/separator.png"
  />
  <span
    *ngIf="!!productDetails.customerProductNumber"
    data-cy="product-compare-add-to-order-gl-number"
  >
    {{ productDetails.customerProductNumber }}
  </span>
</ng-template>
