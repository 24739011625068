import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InventoryStatus } from '@usf/inventory-types';
import { InventoryState } from '@usf/ngrx-inventory/lib/models/inventory/inventory.model';

@Component({
  selector: 'app-selectable-inventory',
  templateUrl: './selectable-inventory.component.html',
  styleUrls: ['./selectable-inventory.component.scss'],
})
export class SelectableInventoryComponent {
  readonly InventoryStatus = InventoryStatus;
  @Input() inventory: InventoryState;
  @Input() isSelected: boolean;

  @Output()
  selectInventoryEmitter: EventEmitter<InventoryState> = new EventEmitter();
  constructor() {}

  onSelectInventory() {
    this.selectInventoryEmitter.emit(this.inventory);
  }
}
