// Cases (isCatchWeight FALSE): unitPrice x unitsOrdered
// Eaches (isCatchWeight FALSE): eachPrice x eachesOrdered
// Cases (isCatchWeight TRUE): unitPrice x unitsOrdered x netWeight
// Eaches (isCatchWeight TRUE): eachPrice x eachesOrdered x netWeight / eachConversionFactor

export const calculatePrice = (
  quantity: number,
  price: string,
  isCases: boolean,
  isCatchWeight: boolean,
  netWeight: number,
  eachConversionFactor: number,
): number => {
  if (quantity && price) {
    if (isCases) {
      return (
        parseFloat(price || '0') * quantity * (isCatchWeight ? netWeight : 1)
      );
    } else {
      const adjustedConversionFactor =
        eachConversionFactor === 0 ? 1 : eachConversionFactor;
      return (
        (parseFloat(price || '0') *
          quantity *
          (isCatchWeight ? netWeight : 1)) /
        (isCatchWeight ? adjustedConversionFactor : 1)
      );
    }
  } else {
    return 0;
  }
};
