/* eslint-disable @typescript-eslint/naming-convention, no-shadow */

import { OrderStatus } from '@usf/ngrx-order';

export interface OrderStatusDetails {
  title: string;
  shortTitle: string;
  icon: string;
  displayType: DisplayType;
  filterText: string;
  sortOrder: number;
  isSubmittedStatus: boolean;
  translationKey?: string;
}

export interface DeliveryMethodConfigs extends OrderStatusDetails {}

export enum DisplayType {
  IN_PROGRESS,
  SUBMITTED_WITH_EXCEPTIONS,
  SUBMITTED,
  NO_ORDER,
  ORDER_ERROR,
  SUBMITTING,
  SUBMISSION_ERROR,
  PREPARING_TO_SHIP,
  QUANTITIES_UPDATED,
  OUT_FOR_DELIVERY,
  DELIVERY_DELAYED,
  ATTEMPTED_DELIVERY,
  ORDER_CANCELLED,
  EDITING_SUBMITTED,
  DELIVERY_ISSUE,
  DELIVERED,
}

export enum DeliveryMethod {
  'US_FOODS_DELIVERY' = 'US_FOODS_DELIVERY',
  'DIRECT_SHIPPING' = 'DIRECT_SHIPPING',
  'WILL_CALL' = 'WILL_CALL',
  'CASH_AND_CARRY' = 'CASH_AND_CARRY',
}

export enum DeliveryMethodAbbreviation {
  'RT' = 'US_FOODS_DELIVERY',
  'VS' = 'DIRECT_SHIPPING',
  'WC' = 'WILL_CALL',
  'CC' = 'CASH_AND_CARRY',
}
export const DeliveryMethodDetails: {
  [deliveryMethod in DeliveryMethod]: DeliveryMethodConfigs;
} = {
  US_FOODS_DELIVERY: {
    title: 'US Foods Delivery',
    shortTitle: 'US Foods Delivery',
    icon: null,
    displayType: null,
    filterText: 'i18n.deliveryMethodDisplayTitles.usFoodsDelivery',
    sortOrder: 101,
    isSubmittedStatus: false,
    translationKey: 'i18n.deliveryMethodDisplayTitles.usFoodsDelivery',
  },
  DIRECT_SHIPPING: {
    title: 'Direct Shipping',
    shortTitle: 'Direct Shipping',
    icon: null,
    displayType: null,
    filterText: 'i18n.deliveryMethodDisplayTitles.directShipping',
    sortOrder: 102,
    isSubmittedStatus: false,
    translationKey: 'i18n.deliveryMethodDisplayTitles.directShipping',
  },
  WILL_CALL: {
    title: 'Will Call',
    shortTitle: 'Will Call',
    icon: null,
    displayType: null,
    filterText: 'i18n.deliveryMethodDisplayTitles.willCall',
    sortOrder: 103,
    isSubmittedStatus: false,
    translationKey: 'i18n.deliveryMethodDisplayTitles.willCall',
  },
  CASH_AND_CARRY: {
    title: 'Cash & Carry',
    shortTitle: 'Cash & Carry',
    icon: null,
    displayType: null,
    filterText: 'i18n.deliveryMethodDisplayTitles.cashAndCarry',
    sortOrder: 104,
    isSubmittedStatus: false,
    translationKey: 'i18n.deliveryMethodDisplayTitles.cashAndCarry',
  },
};

export const OrderStatusDetails: {
  [status in OrderStatus]: OrderStatusDetails;
} = {
  NO_ORDER: {
    title: 'No Order',
    shortTitle: 'No Order',
    icon: 'assets/icon/no-order.svg',
    displayType: DisplayType.NO_ORDER,
    filterText: 'i18n.orderStatusDisplayTitles.noOrder',
    sortOrder: 101,
    isSubmittedStatus: false,
    translationKey: 'i18n.orderStatusDisplayTitles.noOrder',
  },
  IN_PROGRESS: {
    title: 'Not Submitted',
    shortTitle: 'Not Submitted',
    icon: 'assets/icon/not-submitted.svg',
    displayType: DisplayType.IN_PROGRESS,
    filterText: 'i18n.orderStatusDisplayTitles.notSubmitted',
    sortOrder: 1,
    isSubmittedStatus: false,
    translationKey: 'i18n.orderStatusDisplayTitles.notSubmitted',
  },
  SUBMISSION_ERROR: {
    title: 'Submission Error',
    shortTitle: 'Submission Error',
    icon: 'assets/icon/submission-error.svg',
    displayType: DisplayType.SUBMISSION_ERROR,
    filterText: 'i18n.orderStatusDisplayTitles.submissionError',
    sortOrder: 2,
    isSubmittedStatus: false,
    translationKey: 'i18n.orderStatusDisplayTitles.submissionError',
  },
  SUBMITTING: {
    title: 'Submitting',
    shortTitle: 'Submitting',
    icon: 'assets/icon/not-submitted.svg',
    displayType: DisplayType.SUBMITTING,
    filterText: 'i18n.orderStatusDisplayTitles.submitting',
    sortOrder: 3,
    isSubmittedStatus: true,
    translationKey: 'i18n.orderStatusDisplayTitles.submitting',
  },
  ORDER_ERROR: {
    title: 'Order Error',
    shortTitle: 'Order Error',
    icon: 'assets/icon/order-error.svg',
    displayType: DisplayType.ORDER_ERROR,
    filterText: 'i18n.orderStatusDisplayTitles.orderError',
    sortOrder: 4,
    isSubmittedStatus: true,
    translationKey: 'i18n.orderStatusDisplayTitles.orderError',
  },
  SUBMITTED_WITH_EXCEPTIONS: {
    title: 'Submitted w/ Exceptions',
    shortTitle: 'Sub. w/ Exceptions',
    icon: 'assets/icon/submitted-w-exceptions.svg',
    displayType: DisplayType.SUBMITTED_WITH_EXCEPTIONS,
    filterText: 'i18n.orderStatusDisplayTitles.submittedWithExceptions',
    sortOrder: 5,
    isSubmittedStatus: true,
    translationKey: 'i18n.orderStatusDisplayTitles.submittedWExceptions',
  },
  SUBMITTED: {
    title: 'Submitted',
    shortTitle: 'Submitted',
    icon: 'assets/icon/submitted-successfully.svg',
    displayType: DisplayType.SUBMITTED,
    filterText: 'i18n.orderStatusDisplayTitles.submitted',
    sortOrder: 6,
    isSubmittedStatus: true,
    translationKey: 'i18n.orderStatusDisplayTitles.submitted',
  },
  SUBMITTED_CREDIT_HOLD: {
    title: 'Submitted',
    shortTitle: 'Submitted',
    icon: 'assets/icon/submitted-successfully.svg',
    displayType: DisplayType.SUBMITTED,
    filterText: null,
    sortOrder: 7,
    isSubmittedStatus: true,
    translationKey: 'i18n.orderStatusDisplayTitles.submitted',
  },
  PICKING: {
    title: 'Picking',
    shortTitle: 'Picking',
    icon: 'assets/icon/picking.svg',
    displayType: DisplayType.SUBMITTED,
    filterText: 'i18n.orderStatusDisplayTitles.picking',
    sortOrder: 8,
    isSubmittedStatus: false,
    translationKey: 'i18n.orderStatusDisplayTitles.picking',
  },
  PREPARING_TO_SHIP: {
    title: 'Preparing to Ship',
    shortTitle: 'Preparing',
    icon: 'assets/icon/preparing-ship-icon.svg',
    displayType: DisplayType.PREPARING_TO_SHIP,
    filterText: 'i18n.orderStatusDisplayTitles.preparing',
    sortOrder: 9,
    isSubmittedStatus: true,
    translationKey: 'i18n.orderStatusDisplayTitles.preparing',
  },
  INVOICE: {
    title: 'Invoiced',
    shortTitle: 'Invoiced',
    icon: 'assets/icon/invoiced.svg',
    displayType: DisplayType.SUBMITTED,
    filterText: 'i18n.orderStatusDisplayTitles.invoiced',
    sortOrder: 10,
    isSubmittedStatus: false,
    translationKey: 'i18n.orderStatusDisplayTitles.invoiced',
  },
  SHIPPED_WITH_EXCEPTIONS: {
    title: 'Shipped w/ Exceptions',
    shortTitle: 'Shipped w/ Exceptions',
    icon: 'assets/icon/shipped-w-exceptions.svg',
    displayType: DisplayType.SUBMITTED_WITH_EXCEPTIONS,
    filterText: 'i18n.orderStatusDisplayTitles.shippedWithExceptions',
    sortOrder: 11,
    isSubmittedStatus: false,
    translationKey: 'i18n.orderStatusDisplayTitles.shippedWExceptions',
  },
  SHIPPED: {
    title: 'Shipped',
    shortTitle: 'Shipped',
    icon: 'assets/icon/order-shipped.svg',
    displayType: DisplayType.SUBMITTED,
    filterText: 'i18n.orderStatusDisplayTitles.shipped',
    sortOrder: 12,
    isSubmittedStatus: false,
    translationKey: 'i18n.orderStatusDisplayTitles.shipped',
  },
  DELIVERED: {
    title: 'Delivered',
    shortTitle: 'Delivered',
    icon: 'assets/icon/order-delivered.svg',
    displayType: DisplayType.DELIVERED,
    filterText: 'i18n.orderStatusDisplayTitles.delivered',
    sortOrder: 13,
    isSubmittedStatus: false,
    translationKey: 'i18n.orderStatusDisplayTitles.delivered',
  },
  QUANTITIES_UPDATED: {
    title: 'Quantities Updated',
    shortTitle: 'Quantities Updated',
    icon: 'assets/icon/invoiced.svg',
    displayType: DisplayType.QUANTITIES_UPDATED,
    filterText: 'i18n.orderStatusDisplayTitles.quantitiesUpdated',
    sortOrder: 14,
    isSubmittedStatus: true,
    translationKey: 'i18n.orderStatusDisplayTitles.quantitiesUpdated',
  },
  OUT_FOR_DELIVERY: {
    title: 'Out for Delivery',
    shortTitle: 'Out for Delivery',
    icon: 'assets/icon/order-delivered.svg',
    displayType: DisplayType.OUT_FOR_DELIVERY,
    filterText: 'i18n.orderStatusDisplayTitles.outforDelivery',
    sortOrder: 15,
    isSubmittedStatus: true,
    translationKey: 'i18n.orderStatusDisplayTitles.outforDelivery',
  },
  DELIVERY_DELAYED: {
    title: 'Delivery Delayed',
    shortTitle: 'Delivery Delayed',
    icon: 'assets/icon/alert-red.svg',
    displayType: DisplayType.DELIVERY_DELAYED,
    filterText: 'i18n.orderStatusDisplayTitles.deliveryDelayed',
    sortOrder: 16,
    isSubmittedStatus: false,
    translationKey: 'i18n.orderStatusDisplayTitles.deliveryDelayed',
  },
  ATTEMPTED_DELIVERY: {
    title: 'Attempted Delivery',
    shortTitle: 'Attempted Delivery',
    icon: 'assets/icon/alert-icon-green.svg',
    displayType: DisplayType.ATTEMPTED_DELIVERY,
    filterText: 'i18n.orderStatusDisplayTitles.attemptedDelivery',
    sortOrder: 17,
    isSubmittedStatus: true,
    translationKey: 'i18n.orderStatusDisplayTitles.attemptedDelivery',
  },
  ORDER_CANCELLED: {
    title: 'Order Cancelled',
    shortTitle: 'Order Cancelled',
    icon: 'assets/icon/cancel-order-red.svg',
    displayType: DisplayType.ORDER_CANCELLED,
    filterText: 'i18n.orderStatusDisplayTitles.orderCancelled',
    sortOrder: 18,
    isSubmittedStatus: false,
    translationKey: 'i18n.orderStatusDisplayTitles.orderCancelled',
  },
  DELIVERY_ISSUE: {
    title: 'Delivery Issue',
    shortTitle: 'Delivery Issue',
    icon: 'assets/icon/alert-red.svg',
    displayType: DisplayType.DELIVERY_ISSUE,
    filterText: 'i18n.orderStatusDisplayTitles.deliveryIssue',
    sortOrder: 19,
    isSubmittedStatus: false,
    translationKey: 'i18n.orderStatusDisplayTitles.deliveryIssue',
  },
  EDITING_SUBMITTED: {
    title: null,
    shortTitle: null,
    icon: null,
    displayType: null,
    filterText: null,
    sortOrder: 100, //We will not get this status in my-orders. So setting sort order to some high value.
    isSubmittedStatus: true,
  },
  //currently only used for past orders tab
  TANDEM_DELETED: {
    title: null,
    shortTitle: null,
    icon: null,
    displayType: DisplayType.SUBMITTED,
    filterText: null,
    sortOrder: 100,
    isSubmittedStatus: true,
  },
  DELETED: undefined,
  CANCELLED: undefined,
  CANCELLING: undefined,
  SUBMITTED_NEW: undefined,
};
