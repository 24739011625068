<ion-header
  class="ion-no-border"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="(platformService?.isTouchExperience$() | async) === false"
      slot="end"
    >
      <ion-button
        id="close-modal-btn"
        data-cy="close-edit-name-modal-button"
        (click)="dismissModal()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label data-cy="edit-name-label">
      {{
        (isGroup ? 'i18n.lists.editGroupName' : 'i18n.lists.editListName')
          | translate
      }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="edit-name_content"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <div *ngIf="!isGroup" class="edit-name-input">
    <ion-input
      id="new-list-name"
      data-cy="new-list-name-input"
      [class.name-exists]="nameExists"
      maxlength="30"
      type="text"
      value="{{ name }}"
      [(ngModel)]="newName"
      (ngModelChange)="getListNameExists()"
    >
    </ion-input>
  </div>
  <div *ngIf="isGroup" class="edit-name-input">
    <ion-input
      id="new-group-name"
      data-cy="new-group-name-input"
      [class.name-exists]="nameExists"
      maxlength="30"
      type="text"
      value="{{ name }}"
      [(ngModel)]="newName"
      (ngModelChange)="getGroupNameExists()"
    >
    </ion-input>
  </div>
  <p
    *ngIf="nameExists"
    class="name-exists-error"
    data-cy="group-name-exists-message-text"
  >
    {{
      (isGroup ? 'i18n.lists.groupNameExists' : 'i18n.lists.listNameExists')
        | translate
    }}
  </p>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    (platformService?.isTouchExperience$() | async) === false
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="submit-modal-btn"
        data-cy="submit-new-name-modal-button"
        [ngClass]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? !newName || nameExists || newName === name
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : !newName || nameExists || newName === name
            ? 'usf-fill-disabled-mobile-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="onEditName()"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
