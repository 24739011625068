<ion-item lines="none" (click)="selectInventory()">
  <div
    *ngIf="inventory?.isCompleted"
    class="complete-banner"
    [attr.data-cy]="'completed-banner-' + index"
  >
    <p>{{ 'i18n.inventory.complete' | translate }}</p>
  </div>

  <ion-radio
    mode="md"
    [value]="inventory?.inventoryId"
    [attr.data-cy]="'radio-btn-' + index"
  >
    <div class="radio-container">
      <div class="label">
        <p [attr.data-cy]="'inventory-name-' + index" class="name bold">
          {{ inventory?.inventoryName }}
        </p>
        <p [attr.data-cy]="'last-updated-' + index" class="date">
          {{ inventory?.lastUpdated }}
        </p>
        <p [attr.data-cy]="'inventory-value-' + index" class="value">
          {{ inventory?.value }}
        </p>
      </div>

      <div class="badge">
        <ion-badge
          [attr.data-cy]="'selectable-inventory-num-products-icon' + index"
          class="bold inventory-badge"
          mode="ios"
        >
          {{ inventory?.count }}
        </ion-badge>
      </div>
    </div>
  </ion-radio>
</ion-item>
