import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlatformEnum } from '@panamax/app-state';
import { RadioBtnListComponent } from '../../../shared/components/radio-btn-list/radio-btn-list.component';
import {
  CostReportIncludeOptions,
  CostReportSortOptions,
} from '../../models/cost-report-model.model';
import { MobileSelectableInventoryListComponent } from '../mobile-selectable-inventory-list/mobile-selectable-inventory-list.component';
import { InventoryState } from '@usf/ngrx-inventory/lib/models/inventory/inventory.model';
import { CostComparisonReportModalOutput } from '@usf/ngrx-inventory';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cost-comparison-report-modal',
  templateUrl: './cost-comparison-report-modal.component.html',
  styleUrls: ['./cost-comparison-report-modal.component.scss'],
})
export class CostComparisonReportModalComponent implements OnInit {
  @Input() platformType: PlatformEnum;
  @Input() allInventories: InventoryState[];
  @Input() glFlag$: Observable<boolean>;

  platformEnum = PlatformEnum;

  firstAvailableInventories: InventoryState[];
  secondAvailableInventories: InventoryState[];
  firstSelectedInventory: InventoryState;
  firstInventorySubTitle: string;
  secondSelectedInventory: InventoryState;
  secondInventorySubTitle: string;
  selectedFormat: string;
  selectedSort: string;
  productsToInclude: string;
  selectedOption: string;
  includeGlCodes = false;

  formatOptions = ['PDF', 'CSV'];
  sortOptions = [
    CostReportSortOptions.descriptionAsc,
    CostReportSortOptions.percentPriceChange,
  ];
  productsToIncludeOptions = [
    CostReportIncludeOptions.allProducts,
    CostReportIncludeOptions.productsWithPriceChanges,
  ];

  constructor(
    private datePipe: DatePipe,
    private modalController: ModalController,
    private ionNav: IonNav,
  ) {}

  ngOnInit() {
    this.selectedFormat = 'PDF';
    this.selectedSort = CostReportSortOptions.descriptionAsc;
    this.productsToInclude = CostReportIncludeOptions.allProducts;
    this.firstInventorySubTitle =
      'i18n.inventory.costComparisonReportModal.selectInventoryToCompare';
    this.secondInventorySubTitle =
      'i18n.inventory.costComparisonReportModal.selectInventoryToCompare';
    this.selectedOption = 'firstSelectedInventory';
    this.setFirstAvailableInventories();
    this.setSecondAvailableInventories();
  }

  changeSelectedOption(selectedOption: string) {
    if (this.platformType !== this.platformEnum.mobile) {
      this.selectedOption = selectedOption;
    } else {
      if (selectedOption === 'firstSelectedInventory') {
        this.pushSelectFirstInventoryComponent();
      } else if (selectedOption === 'secondSelectedInventory') {
        this.pushSelectSecondInventoryComponent();
      } else if (selectedOption === 'selectFormat') {
        this.pushFormatComponent();
      } else if (selectedOption === 'sortBy') {
        this.pushSortComponent();
      } else if (selectedOption === 'productsToInclude') {
        this.pushIncludeComponent();
      }
    }
  }

  pushSelectFirstInventoryComponent() {
    this.ionNav.push(MobileSelectableInventoryListComponent, {
      label: 'i18n.inventory.costComparisonReportModal.selectFirstInventory',
      inventories: this.firstAvailableInventories,
      callBack: this.callBackFirstInventory.bind(this),
      selectedInventory: this.firstSelectedInventory,
    });
  }

  callBackFirstInventory(selectedInventory: InventoryState) {
    this.firstSelectedInventory = selectedInventory;
    const formattedDate = this.datePipe.transform(
      selectedInventory.inventoryDate,
      'M/d/yyyy',
    );
    this.firstInventorySubTitle =
      selectedInventory.inventoryName + ' ' + formattedDate;
    this.setSecondAvailableInventories();
  }

  selectFirstInventory(selectedInventory: InventoryState) {
    if (this.firstSelectedInventory === selectedInventory) {
      this.firstSelectedInventory = undefined;
      this.firstInventorySubTitle =
        'i18n.inventory.costComparisonReportModal.selectInventoryToCompare';
    } else {
      this.firstSelectedInventory = selectedInventory;
      const formattedDate = this.datePipe.transform(
        selectedInventory.inventoryDate,
        'M/d/yyyy',
      );
      this.firstInventorySubTitle =
        selectedInventory.inventoryName + ' ' + formattedDate;
    }

    this.setSecondAvailableInventories();
  }

  pushSelectSecondInventoryComponent() {
    this.ionNav.push(MobileSelectableInventoryListComponent, {
      label: 'i18n.inventory.costComparisonReportModal.selectSecondInventory',
      inventories: this.secondAvailableInventories,
      callBack: this.callBackSecondInventory.bind(this),
      selectedInventory: this.secondSelectedInventory,
    });
  }

  callBackSecondInventory(selectedInventory: InventoryState) {
    this.secondSelectedInventory = selectedInventory;
    const formattedDate = this.datePipe.transform(
      selectedInventory.inventoryDate,
      'M/d/yyyy',
    );
    this.secondInventorySubTitle =
      selectedInventory.inventoryName + ' ' + formattedDate;
    this.setFirstAvailableInventories();
  }

  selectSecondInventory(selectedInventory: InventoryState) {
    if (this.secondSelectedInventory === selectedInventory) {
      this.secondSelectedInventory = undefined;
      this.secondInventorySubTitle =
        'i18n.inventory.costComparisonReportModal.selectInventoryToCompare';
    } else {
      this.secondSelectedInventory = selectedInventory;
      const formattedDate = this.datePipe.transform(
        selectedInventory.inventoryDate,
        'M/d/yyyy',
      );
      this.secondInventorySubTitle =
        selectedInventory.inventoryName + ' ' + formattedDate;
    }
    this.setFirstAvailableInventories();
  }

  pushFormatComponent() {
    this.ionNav.push(RadioBtnListComponent, {
      label: 'i18n.common.selectFormat',
      listOfSelectableLabels: this.formatOptions,
      selectedLabel: this.selectedFormat,
      callBack: this.callBackFormat.bind(this),
      platformType: this.platformType,
    });
  }

  callBackFormat(selectedFormat: string) {
    this.selectedFormat = selectedFormat;
  }

  pushSortComponent() {
    this.ionNav.push(RadioBtnListComponent, {
      label: 'i18n.common.sortBy',
      listOfSelectableLabels: this.sortOptions,
      selectedLabel: this.selectedSort,
      callBack: this.callBackSort.bind(this),
      platformType: this.platformType,
    });
  }

  callBackSort(selectedSort: string) {
    this.selectedSort = selectedSort;
  }

  pushIncludeComponent() {
    this.ionNav.push(RadioBtnListComponent, {
      label: 'i18n.common.include',
      listOfSelectableLabels: this.productsToIncludeOptions,
      selectedLabel: this.productsToInclude,
      callBack: this.callBackInclude.bind(this),
      platformType: this.platformType,
    });
  }

  callBackInclude(productsToInclude: string) {
    this.productsToInclude = productsToInclude;
  }

  setFirstAvailableInventories() {
    this.firstAvailableInventories = this.allInventories?.filter(inventory => {
      return (
        inventory.inventoryName !== this.secondSelectedInventory?.inventoryName
      );
    });
  }

  setSecondAvailableInventories() {
    this.secondAvailableInventories = this.allInventories?.filter(inventory => {
      return (
        inventory.inventoryName !==
          this.firstSelectedInventory?.inventoryName ||
        inventory.inventoryDate !== this.firstSelectedInventory.inventoryDate
      );
    });
  }

  download() {
    this.modalController.dismiss({
      firstSelectedInventory: this.firstSelectedInventory,
      secondSelectedInventory: this.secondSelectedInventory,
      selectedFormat: this.selectedFormat,
      productsToInclude: this.productsToInclude,
      selectedSort: this.selectedSort,
      includeGlCodes: this.includeGlCodes,
    } as CostComparisonReportModalOutput);
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
