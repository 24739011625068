import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';
import { HOME_ANALYTICS_ACTIONS } from './home-analytics.actions';
import { HomeAnalyticsMessages } from './home-analytics.constants';
import {
  homePageLoad,
  interactBannerClick,
  interactBannersInViewport,
} from './home-analytics.transformers';

@Injectable({
  providedIn: 'root',
})
export class HomeAnalyticsService {
  constructor(private store: Store) {}

  trackPageLoad(
    noteIds?: string | null,
    delivery?: number | null,
    dealsTileDisplayed?: number | null,
    newsPageDisplayed?: number | null,
    interactBannersDisplayed?: string | null,
  ): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          pageName: HomeAnalyticsMessages.pageName,
          event: HomeAnalyticsMessages.pageLoadedEvent,
          capability: HomeAnalyticsMessages.capability,
          promo: {
            list:
              (noteIds ? noteIds : '') +
              (noteIds && interactBannersDisplayed ? ',' : '') +
              (interactBannersDisplayed ? interactBannersDisplayed : ''),
            view: 1,
          },
          wheresMyTruck: {
            numberOfShipments: delivery,
          },
          dealsTile: {
            displayed: dealsTileDisplayed,
          },
          newsTile: {
            displayed: newsPageDisplayed,
          },
        },
        transformFunc: homePageLoad,
      },
    };
    this.store.dispatch(HOME_ANALYTICS_ACTIONS.trackPageLoad({ tracking }));
  }

  trackInteractBannerClick(bannerId: string, bannerUrl?: string): void {
    let tracking: Tracking = {
      analytics: {
        data: {
          event: HomeAnalyticsMessages.trackInteractBannerClickEvent,
          capability: HomeAnalyticsMessages.alertsCapability,
          activePromo: {
            promoId: bannerId,
          },
          link: {
            url: bannerUrl ? bannerUrl : null,
          },
        },
        transformFunc: interactBannerClick,
      },
    };
    this.store.dispatch(
      HOME_ANALYTICS_ACTIONS.trackInteractBannerClick({ tracking }),
    );
  }

  trackInteractBannersInViewport(banners: string) {
    let tracking: Tracking = {
      analytics: {
        data: {
          event: HomeAnalyticsMessages.trackInteractBannersInViewport,
          capability: HomeAnalyticsMessages.alertsCapability,
          engagement: {
            engagementType:
              HomeAnalyticsMessages.trackInteractBannersEngagementType,
          },
          promo: {
            list: banners,
          },
        },
        transformFunc: interactBannersInViewport,
      },
    };
    this.store.dispatch(
      HOME_ANALYTICS_ACTIONS.trackInteractBannersInViewport({ tracking }),
    );
  }
}
