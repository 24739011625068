/* eslint-disable no-shadow */
export enum HotKeys {
  globalKeyHandler = 'globalkeyhandler',
  listSearchBarId = 'list-search-bar',
  inventorySearchBarId = 'search-bar',
  enter = 'Enter',
  numPadEnter = 'NumpadEnter',
  tab = 'Tab',
  escape = 'Escape',
  arrowLeft = 'ArrowLeft',
  arrowRight = 'ArrowRight',
  arrowDown = 'ArrowDown',
  arrowUp = 'ArrowUp',
  backSpace = 'Backspace',
  button = 'button',
  period = 'Period',
  outOfStock = 'OOS',
  betterBuys = 'bb',
  each = 'ea',
  ellipsisPopoverButtonClass = 'ellipsis-popover-button',
  case = 'cs',
  substitute = 'sub',
  group = 'group',
  description = 'description',
  price = 'price',
  search = 'search',
  listScrollId = 'list-scroll-',
  inventoryScrollId = 'inventory-worksheet-scroll-',
  paste = 'paste',
  lineNumber = 'lineNumber',
}
