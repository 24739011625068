<div id="loading-spinner-modal">
  <div class="loading-spinner-content">
    <div class="loading-spinner-container">
      <span class="loading-spinner-text">
        {{ loadingSpinnerModalMessage }}
      </span>
      <ion-spinner name="circles" data-cy="loading-spinner-icon"></ion-spinner>
    </div>
    <div *ngIf="!!cancelButton">
      <br />
      <ion-button
        class="usf-fill-orange-button"
        data-cy="cancel-submitted-order-button"
        (click)="handleCancelClick()"
      >
        {{ 'i18n.orderPage.cancel' | translate }}
      </ion-button>
    </div>
  </div>
</div>
