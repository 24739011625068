<div
  *ngIf="pricingAvailable && (savings > 0 || annualSavings > 0)"
  class="savings-row"
  [class.row]="flexDirection === 'row'"
  [class.column]="flexDirection === 'column'"
>
  <div *ngIf="savings > 0" class="case-savings-column">
    <ion-icon name="pricetag"></ion-icon>
    <span
      >{{ 'i18n.betterBuys.save' | translate }}
      <span class="savings-amount">{{ savings | currency }}</span>
      {{
        (savingsUom === 'CS'
          ? 'i18n.betterBuys.perCase'
          : savingsUom === 'SV'
          ? 'i18n.betterBuys.perServing'
          : 'i18n.betterBuys.perPound'
        ) | translate
      }}
    </span>
  </div>
  <div *ngIf="annualSavings > 0" class="annual-savings-column">
    <ion-icon name="pricetag"></ion-icon>
    <span
      >{{ 'i18n.betterBuys.save' | translate }}
      <span class="savings-amount">{{ annualSavings | currency }}</span>
      {{ 'i18n.betterBuys.annually' | translate }}</span
    >
  </div>
</div>
