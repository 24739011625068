const SAFETY_DATA_SHEET = 'SafetyDataSheet';
const CHILD_NUTRITION = 'ChildNutritionLabel';

export const DOCUMENT_WHITELIST = {
  [SAFETY_DATA_SHEET]: {
    label: 'i18n.productDetail.downloads.safetyDataSheet',
  },
  [CHILD_NUTRITION]: {
    label: 'i18n.productDetail.downloads.childNutrition',
  },
};

export const SLIDER_OPTIONS = {
  initialSlide: 0,
  slidesPerView: 1,
  slidesPerGroup: 4,
  spaceBetween: 0,
  loop: false,
  freeMode: false,
  grabCursor: false,
  allowSlideNext: true,
  allowSlidePrev: true,
  allowTouchMove: true,
  slideShadows: true,
  watchSlidesVisibility: true,
  direction: 'horizontal',
  scrollbar: false,
  breakpoints: {
    320: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    375: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    390: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    600: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    768: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    820: {
      slidesPerView: 2,
      slidesPerGroup: 1.8,
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    1440: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
  },
};

export const IMAGE_CODE_INDEX = {
  imageType: 0,
  facingIndicator: 1,
  angleIndicator: 2,
  packagingIndicator: 3,
};

export const PRODUCT_DETAIL = 'product details';
export const SELLER_PRODUCT_DETAIL = 'seller product details';

export const SUBSTITUTE_TYPE = {
  divsionSub: 'divisionSub',
  mslSub: 'mslSub',
};

export const PDP_SHARESHEET_CONSTANTS = {
  GENERIC_INTRO: 'Check out this product!\n',
  GENERIC_TITLE: 'US Foods Product',
  PRODUCT_NUMBER: '&productNumber=',
  PDP_DEEPLINK_ORIGIN: 'share-moxe-pdpShare',
  PDP_ORIGIN_LINK_TYPE: 'PRODDETAILS',
};

export const DIRECT_STAT2_PDP_OOS_MESSAGE = {
  PDP_DEFAULT_MESSAGE: 'i18n.productDetail.inventoryInfo.defaultMessage',
  PDP_NEXT_RESTOCK_DATE_MESSAGE:
    'i18n.productDetail.inventoryInfo.nextExpectedRestock',
  PDP_PO_DATE_AVAILABLE_MESSAGE:
    'i18n.productDetail.inventoryInfo.oosDateAvailable',
  PRODUCT_CARD_PRIMARY_MESSAGE:
    'i18n.productDetail.inventoryInfo.oosPrimaryMessage',
  PRODUCT_CARD_NO_PO_DATE_SECONDARY:
    'i18n.productDetail.inventoryInfo.noInventoryDate',
  PRODUCT_CARD_PO_DATE_SECONDARY: 'i18n.productDetail.inventoryInfo.nextPoDate',
  TODAY: 'i18n.productDetail.inventoryInfo.today',
};
