import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import {
  productPricingAdapter,
  selectProductPricingState,
} from '@usf/ngrx-pricing';
import { ProductPricing } from '@usf/price-types';
import { OrderItem, orderItemSelectors } from '@usf/ngrx-order';
import { OrderItemState } from '@usf/ngrx-order';
import { calculatePrice } from '@shared/selectors/helpers/order-total-price.selectors.helper';
import {
  selectProductSummaryEntities,
  selectProductInventoryEntities,
} from '@usf/ngrx-product';
export const selectOrderTotalPrice = (updateOrderItem: OrderItem) =>
  createSelector(
    selectProductSummaryEntities,
    selectProductInventoryEntities,
    productPricingAdapter.getSelectors(selectProductPricingState)
      .selectEntities,
    orderItemSelectors.selectOrderItemState,
    (
      summaries,
      inventories,
      pricingState: Dictionary<ProductPricing>,
      orderItemState: OrderItemState,
    ) => {
      let totalPrice = 0;
      let updateIsQuantityOnly = false;
      orderItemState?.ids?.forEach(productNumber => {
        const summary = summaries[productNumber];
        const inventory = inventories[productNumber];

        let unitsOrdered =
          orderItemState?.entities[productNumber]?.unitsOrdered?.currentValue;
        let eachesOrdered =
          orderItemState.entities[productNumber]?.eachesOrdered?.currentValue;
        let unitPrice = 0;
        let eachPrice = 0;
        let unitPriceOverride = 0;
        let newUnitPrice;
        let newEachPrice;
        // need to check if updateOrderItem has a value, other we might get undefined === undefined ==> true
        if (
          !!updateOrderItem &&
          productNumber === updateOrderItem?.productNumber
        ) {
          //qty changed or hand-price changed
          unitsOrdered = updateOrderItem.unitsOrdered.currentValue;
          eachesOrdered = updateOrderItem.eachesOrdered.currentValue;
          updateIsQuantityOnly = true;
          unitPrice = updateOrderItem.unitPrice;
          unitPriceOverride = updateOrderItem.unitPriceOverride;
          newUnitPrice = unitPriceOverride
            ? unitPrice.toString()
            : pricingState[productNumber]?.unitPrice?.toString();
        } else {
          //remaining item in order including previously changed hand-pricing
          unitPrice = orderItemState.entities[productNumber]?.unitPrice;
          eachPrice = orderItemState.entities[productNumber]?.eachPrice;
          unitPriceOverride =
            orderItemState.entities[productNumber].unitPriceOverride;
          newUnitPrice = unitPriceOverride
            ? unitPrice?.toString()
            : pricingState[productNumber]?.unitPrice?.toString();
          newEachPrice =
            unitPriceOverride && !!eachPrice && summary?.breakable === true
              ? eachPrice.toString()
              : pricingState[productNumber]?.eachPrice?.toString();
        }
        totalPrice +=
          calculatePrice(
            unitsOrdered,
            newUnitPrice,
            true,
            summary?.catchWeightFlag,
            summary?.netWeight,
            inventory?.eachConversionFactor,
          ) +
          calculatePrice(
            eachesOrdered,
            newEachPrice,
            false,
            summary?.catchWeightFlag,
            summary?.netWeight,
            inventory?.eachConversionFactor,
          );
      });
      if (updateOrderItem && !updateIsQuantityOnly) {
        const summary = summaries[updateOrderItem?.productNumber];
        const inventory = inventories[updateOrderItem?.productNumber];

        totalPrice +=
          calculatePrice(
            updateOrderItem.unitsOrdered.currentValue,
            pricingState[updateOrderItem.productNumber]?.unitPrice?.toString(),
            true,
            summary?.catchWeightFlag,
            summary?.netWeight,
            inventory?.eachConversionFactor,
          ) +
          calculatePrice(
            updateOrderItem.eachesOrdered.currentValue,
            pricingState[updateOrderItem.productNumber]?.eachPrice?.toString(),
            false,
            summary?.catchWeightFlag,
            summary?.netWeight,
            inventory?.eachConversionFactor,
          );
      }
      return totalPrice;
    },
  );
