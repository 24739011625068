import { Component, Input } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PanAppState, PlatformService } from '@panamax/app-state';
import { DeliveryStoreService } from '@usf/ngrx-order';
import { HomeService } from 'src/app/home/services/home.service';
import { LoginService } from 'src/app/login/services';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { OrderService } from 'src/app/order/services/order.service';
import { NavigationHelperService } from 'src/app/shared/helpers/navigation.helpers.service';
import { PopoverHelperService } from 'src/app/shared/helpers/popover.helpers';
import { PATHS } from '@shared/constants/paths';
import { CustomerSelectionDesktopComponent } from '../customer-selection/customer-selection-desktop/customer-selection-desktop.component';
import { HeaderComponent } from '../header.component';
import { HeaderService } from '../header.service';
import { DetailHistoryService } from '../history/detail-history.service';
import { take } from 'rxjs/operators';
import { ModalService } from '@shared/services/modal/modal.service';
import { OrderSyncingComponent } from '@shared/components/popovers/order-syncing/order-syncing.component';
import { MenuOption } from '@shared/constants/header';
import { MenuHoverComponent } from '../../popovers/menu/menu-hover.component';
import { UserPopoverComponent } from '../user-popover/user-popover.component';
import { CustomerStoreService } from '@app/ngrx-customer/services';
import { CustomerService } from '@app/customer/services/customer.service';
import { UserService } from '@app/user/services';
import { ZipCodeChangeDesktopComponent } from '@shared/components/header/zip-code-change/zip-code-change-desktop/zip-code-change-desktop.component';
import { LogoutOnlyPopoverComponent } from '../logout-only-popover/logout-only-popover.component';
import { ListUpdatesService } from '@app/lists/services/list-updates.service';
import { environment } from 'src/environments/environment';
import { AuthConnectService } from '@panamax/app-state';
@Component({
  selector: 'app-usf-header-desktop',
  templateUrl: './usf-header-desktop.component.html',
  styleUrls: [
    './usf-header-desktop.component.scss',
    '../header.component.scss',
  ],
})
export class UsfHeaderDesktopComponent extends HeaderComponent {
  @Input() isSuperUser: boolean;
  @Input() isGuestUser: boolean;
  @Input() listUpdatesExist: boolean;

  isClicked = false;
  activeButton: number;
  becomeACustomerUrl: string = environment.becomeACustomer;

  constructor(
    modalController: ModalController,
    popoverController: PopoverController,
    panAppState: PanAppState,
    customerService: CustomerService,
    customerStoreService: CustomerStoreService,
    deliveryStoreService: DeliveryStoreService,
    detailHistoryService: DetailHistoryService,
    headerService: HeaderService,
    homeService: HomeService,
    loginService: LoginService,
    modalService: ModalService,
    navigationHelperService: NavigationHelperService,
    notificationsService: NotificationsService,
    orderService: OrderService,
    platformService: PlatformService,
    popoverHelperService: PopoverHelperService,
    translateService: TranslateService,
    userService: UserService,
    listUpdatesService: ListUpdatesService,
    private authConnectService: AuthConnectService,
  ) {
    super(
      modalController,
      popoverController,
      panAppState,
      customerService,
      customerStoreService,
      deliveryStoreService,
      detailHistoryService,
      headerService,
      homeService,
      loginService,
      modalService,
      navigationHelperService,
      notificationsService,
      orderService,
      platformService,
      popoverHelperService,
      translateService,
      userService,
      listUpdatesService,
    );
  }

  createNewOrder(): void {
    this.orderService
      .shouldShowCartSyncingPopover$()
      .pipe(take(1))
      .subscribe(async showPopover => {
        if (showPopover) {
          await this.presentSyncingModal();
        } else {
          this.orderService.createNewOrder();
          this.routeTo(PATHS.LISTS);
        }
      });
  }

  async presentSyncingModal() {
    this.modalService.setModalOptions(
      this.platformService?.isTouch.value,
      OrderSyncingComponent,
      { customerSwitch: false, orderSwitch: true },
      'simple-action-modal',
    );

    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );
    modal.onDidDismiss().then(resp => {
      if (resp?.data?.continueClick) {
        this.orderService.createNewOrder();
        this.routeTo(PATHS.LISTS);
      }
    });

    return await modal.present();
  }

  openCustomerSelection(ev: any) {
    if (!this.isOnline) {
      this.openWarningPopover();
    } else {
      this.isSuperUser
        ? this.customerService.setIsSuperUserCustomersModalOpen(true)
        : this.openCustomerSelectionPopover(ev);
    }
  }

  async openCustomerSelectionPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: CustomerSelectionDesktopComponent,
      componentProps: {
        searchText$: this.searchText$,
        vm$: this.getCustomerSelectionVM$(),
      },
      cssClass: 'customer-selection-popover-desktop',
      event: ev,
      mode: 'md',
      showBackdrop: true,
      backdropDismiss: true,
      translucent: true,
    });
    await popover.present();
    await popover.onDidDismiss().then((resp: any) => {
      if (resp?.data) {
        this.onCustomerDropdownDismiss(resp.data);
      }
    });
  }

  async openZipCodeChangePopover(ev: any) {
    const popover = await this.popoverController.create({
      component: ZipCodeChangeDesktopComponent,
      componentProps: {
        searchText$: this.searchText$,
      },
      cssClass: 'zip-code-change-popover-desktop',
      event: ev,
      mode: 'ios',
      showBackdrop: true,
      backdropDismiss: true,
      translucent: true,
    });
    await popover.present();
  }

  async openMoxeLinksPopover(
    ev: any,
    menuOptions: MenuOption[],
    activeButtonNumber: number,
  ) {
    if (await this.popoverController?.getTop()) {
      this.popoverController?.dismiss();
    }

    const moxeLinksPopover = await this.popoverController.create({
      component: MenuHoverComponent,
      componentProps: {
        menuOptions,
      },
      cssClass: 'header-menu-popover-desktop',
      mode: 'md',
      event: ev,
    });
    this.activeButton = activeButtonNumber;
    this.isClicked = true;
    moxeLinksPopover.present();

    await moxeLinksPopover.onDidDismiss().then(event => {
      this.isClicked = false;
    });
  }

  async openUserPopover(ev: any) {
    if (await this.popoverController?.getTop())
      this.popoverController?.dismiss();

    const userPopover = await this.popoverController.create({
      component: UserPopoverComponent,
      cssClass: 'user-popover-desktop',
      mode: 'md',
      event: ev,
    });
    this.activeButton = 4;
    this.isClicked = true;
    userPopover.present();

    await userPopover.onDidDismiss().then(event => {
      this.isClicked = false;
    });
  }

  async openLogoutOnlyPopover(ev: any) {
    const logoutPopover = await this.popoverController.create({
      component: LogoutOnlyPopoverComponent,
      cssClass: 'user-popover-desktop',
      mode: 'md',
      event: ev,
    });
    this.activeButton = 4;
    this.isClicked = true;
    logoutPopover.present();

    await logoutPopover.onDidDismiss().then(event => {
      this.isClicked = false;
    });
  }

  async logout(): Promise<void> {
    await this.authConnectService.logout();
  }
}
