import { ProductConversionContainer } from '../../shared/models/product-conversion.model';

export interface ListUpdatesViewModel {
  productConversionContainers: ProductConversionContainer[];
  isOnline: boolean;
  pricingDisabled: boolean;
  updateCount?: number;
}

export enum ProductConversionOrigin {
  listUpdate = 'LIST_UPDATE',
  betterBuy = 'BETTER_BUYS',
}
