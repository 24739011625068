import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Division } from '@usf/customer-types/division';
import * as DivisionActions from '../actions/division.actions';

export const divisionAdapter: EntityAdapter<Division> = createEntityAdapter<Division>(
  {
    selectId: (division) => division.divisionNumber,
  },
);

export const initialDivisionState = divisionAdapter.getInitialState({
  loading: false,
  error: null,
  division: null,
});

export const divisionReducer = createReducer(
  initialDivisionState,
  on(DivisionActions.getDivisionList, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),

  on(DivisionActions.getDivisionSuccess, (state, {divisions}) => {
    return divisionAdapter.upsertMany(divisions, state);
  }),

  on(DivisionActions.getDivisionFail, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
);

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = divisionAdapter.getSelectors();

// select the array of division ids
export const selectDivisionIds = selectIds;

// select the dictionary of division entities
export const selectDivisionEntities = selectEntities;

// select the array of divisions
export const selectAllDivisions = selectAll;

// select the total division count
export const selectDivisionTotal = selectTotal;
