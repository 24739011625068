export const SHARESHEET_CONSTANTS = {
  MIN_VERSION: '1.15.4',
  UPDATE_MSG:
    'To use the share feature, please go to the App/Play Store and update the MOXē app',
  ERROR_MSG: 'An Error has occurred. Please contact support',
  DESKTOP_MSG: 'Web is not supported yet for this feature',
  CUSTOMER_NUMBER: '&customerNumber=',
  DEPARTMENT_NUMBER: '&departmentNumber=',
  DIVISION_NUMBER: '&divisionNumber=',
  ORIGIN: 'originType=',
  ORIGIN_LINK_TYPE: '&originLinkType=',
  DEVICE_NOT_AVAILABLE: 'Share API not available in this device',
};
