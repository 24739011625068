import { PoDate } from '@usf/product-types';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
// PO Dates follow an UTC format
dayjs.extend(utc);

const maxPoDates = 3;

export const extractPoDates = (
  poDates: PoDate[],
  currentDate = dayjs().utcOffset(0).startOf('date'),
): PoDate[] => {
  if (!poDates) {
    return [];
  }

  return poDates
    .filter(poDate => {
      return (
        dayjs(poDate.scheduledArrivalDate).isSame(currentDate) ||
        dayjs(poDate.scheduledArrivalDate).isAfter(currentDate)
      );
    })
    .sort((lhs, rhs) =>
      dayjs(lhs.scheduledArrivalDate).diff(rhs.scheduledArrivalDate),
    )
    .slice(0, maxPoDates)
    .map(poDate => ({
      ...poDate,
      formattedArrivalDate: dayjs(poDate.scheduledArrivalDate).utc().format(
        // 02/29/2022
        'MM/DD/YYYY',
      ),
    }));
};

export const daysInTheFuture = (
  poDate: PoDate,
  currentDate = dayjs().utcOffset(0).startOf('date'),
): number => {
  if (!!poDate) {
    return dayjs(poDate.scheduledArrivalDate).diff(currentDate, 'day', false);
  }

  return 0;
};

export const isCurrentDate = (
  poDate: PoDate,
  currentDate = dayjs().utcOffset(0).startOf('date'),
): boolean => {
  if (!!poDate) {
    return dayjs(poDate.scheduledArrivalDate).isSame(currentDate, 'day');
  }

  return false;
};
