import { SubstituteTrackingInfo } from '@usf/ngrx-product/lib/models/analytics.model';
import { Product } from '../../shared/models/product.model';

/* eslint-disable no-shadow */
export enum ProductAlternativeTypeEnum {
  substitute = 'substitute',
  replacement = 'replacement',
}

/* eslint-enable no-shadow */

export interface ProductAlternative {
  product: Product;
  type: ProductAlternativeTypeEnum;
  required: boolean;
  available: boolean;
  alternativeIds?: string[];
  substituteInfo?: SubstituteTrackingInfo;
  /**
   * @deprecated since hotfix-R4PS-942-PDP - Modify logic to use *prioritizeAlternative* instead
   * The idea is that when both base and substitute could be ordered, the substitute takes precedence;
   * this is done to clear the inventory of the substitute product before the base product
   */
  prioritizeBaseProduct?: boolean;
  prioritizeAlternative?: boolean;
}
