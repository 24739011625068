import { ProductClaim } from '@usf/product-types';
import * as dayjs from 'dayjs';

export const extractClaims = (
  productClaims: ProductClaim[],
  currentDate = dayjs(),
): ProductClaim[] => {
  if (!productClaims) {
    return [];
  }

  const allClaims = [...marketingClaims, ...nutritionalClaims].map(claim =>
    claim.toLowerCase(),
  );

  return (
    productClaims
      .filter(
        claim =>
          allClaims.some(claimName =>
            claim.name.toLowerCase().includes(claimName),
          ) &&
          claim.value === true &&
          (!claim.certificationEffectiveEndDate ||
            dayjs(claim.certificationEffectiveEndDate).isAfter(currentDate)),
      )
      // Use the names in the 'approved' list
      .map(claim => {
        if (claim.name.toLowerCase().includes('child nutrition certificate')) {
          return {
            ...claim,
            name: 'child nutr. cert.',
          };
        }
        if (claim.name.toLowerCase().includes('certified angus beef')) {
          return {
            ...claim,
            name: 'cert. angus beef',
          };
        }
        return {
          ...claim,
          name: allClaims.find(claimName =>
            claim.name.toLowerCase().includes(claimName),
          ),
        };
      })
  );
};

const marketingClaims = [
  'Gluten Free',
  'Halal',
  'Kosher',
  'Organic',
  'Recyclable',
  'Certified Angus beef',
];

const nutritionalClaims = [
  'Child Nutrition certificate',
  'Cholesterol free',
  'Low Fat',
  'MSG free',
  'No Sugar Added',
  'Trans Fat Free',
  'Fat free',
];
