import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { PowerReviewRollupModel } from '@usf/ngrx-product/lib/models/power-review-product.model';
import {
  RatingStarsCSSEnum,
  RatingAverageCSSEnum,
} from '../../models/power-reviews/pwr-rating-css';
import { ProductPricing } from '@usf/price-types';
import { ProductRow } from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { FEATURES } from '@shared/constants/splitio-features';
import { PriceViewModel } from '@shared/models/price-view.model';

@Component({
  selector: 'app-product-card-information',
  templateUrl: './product-card-information.component.html',
  styleUrls: ['./product-card-information.component.scss'],
})
export class ProductCardInformationComponent {
  @Input() brand: string;
  @Input() description: string;
  @Input() productNumber: number;
  @Input() salesPackSize: string;
  @Input() note: string;
  @Input() showNote: boolean;
  @Input() notFound: boolean;
  @Input() isSplitCard: boolean;
  @Input() customerProductNumber: string;
  @Input() powerReviewRollupModel: PowerReviewRollupModel;
  @Input() showPowerReview: boolean;
  @Input() pricePerPortion: string;
  @Input() truncateAmount = 53;
  @Input() price: PriceViewModel;
  @Input() clickable = true;
  @Input() isGuestUser = false;

  @Output() itemNoteClickEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  productDescriptionClickEmitter: EventEmitter<any> = new EventEmitter();

  readonly split_global_product_price_per_portion_card_flag =
    FEATURES.split_global_product_price_per_portion_card_flag;

  constructor() {}

  itemNoteClick() {
    this.itemNoteClickEmitter.emit();
  }

  productDescriptionClick() {
    this.productDescriptionClickEmitter.emit();
  }

  public get ratingStarsCSSEnum(): typeof RatingStarsCSSEnum {
    return RatingStarsCSSEnum;
  }

  public get ratingAverageCSSEnum(): typeof RatingAverageCSSEnum {
    return RatingAverageCSSEnum;
  }
}
