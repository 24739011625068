import {
  ProductAlternative,
  ProductAlternativeTypeEnum,
} from '../models/product-alternative';
import { ProductInventory, SubstituteProduct } from '@usf/product-types';
import {
  isBreakableAndInStock,
  productIsInStatus,
} from '../utils/product-summary.utils';
import {
  MslSecondary,
  ProductPropertiesEnum,
} from '@usf/product-types/Product-Summary';
import { Product } from '../../shared/models/product.model';
import { isSupplierOutOfStock, productHasStatus } from '@usf/ngrx-product';
import {
  ListFlagsEnum,
  OGRestrictionsEnum,
  SubRestrictionsEnum,
} from '@shared/constants/sub-restrictions-enum';
import {
  enrichProductWithListData,
  enrichProductWithMasterListData,
  mslProductHasSecondaries,
} from '@shared/selectors/helpers/product-info.selectors.helper';
import { AppStateForProduct } from '../../shared/models/app-state-for-product';
import { ListDataForProduct } from '../../shared/models/list-data-for-product';
import { SubstituteTrackingInfo } from '@usf/ngrx-product/lib/models/analytics.model';
import { SUBSTITUTE_TYPE } from '@product-detail/constants/constants';
import {
  calculateMslRestriction,
  isCustomerMslRestricted,
} from '@shared/selectors/helpers/msl-unrestrict-helper';

export const getProductSubstitute = (
  baseProduct: Product,
  productsMap: Map<number, Product>,
  appStateForProduct: AppStateForProduct,
): ProductAlternative => {
  const productInventory = baseProduct?.inventory;

  // Wait until subs are available
  if (
    productInventory?.substituteProducts?.length > 0 &&
    productInventory?.substituteProducts.some(
      sub => productsMap.get(sub.productNumber) === undefined,
    )
  ) {
    // Cannot be defined until substitute data is ready
    return undefined;
  }
  // Filter out products that don't require a substitute
  if (!isSubstituteCandidate(baseProduct)) {
    return buildAlternative(false);
  }

  // Case 1: Sub has priority ?
  if (
    doesSubstituteHavePriority(baseProduct, productsMap, appStateForProduct)
  ) {
    const firstSubstitute = getFirstSubstitute(productInventory, productsMap);
    return buildAlternative(
      true,
      true,
      true,
      firstSubstitute?.product,
      firstSubstitute?.tracking,
    );
  }
  // Case 2: Status 2 that has inventory does not required substitute
  if (
    isSupplierOutOfStock(productInventory) &&
    productInventory.isInStock &&
    productInventory.casesOnHand > 0
  ) {
    return buildAlternative(false);
  }

  // Case 3: Product is breakable and has eaches or cases available
  if (isBreakableAndInStock(baseProduct)) {
    return buildAlternative(false);
  }

  // Case 4: Try to find regular substitutes
  return findSubstitutes(productInventory, productsMap, appStateForProduct);
};

const isSubstituteCandidate = (product: Product) => {
  // Should sub OOS products in these statuses
  if (
    productIsInStatus(['0', '2', '3', '4'], product) &&
    product?.inventory?.isInStock === false
  ) {
    return true;
  }
  // Supplier is out of stock, should sub
  if (isSupplierOutOfStock(product?.inventory)) {
    return true;
  }
  return false;
};

const doesSubstituteHavePriority = (
  product: Product,
  productsMap: Map<number, Product>,
  appStateForProduct: AppStateForProduct,
) => {
  const firstSubstitute = getFirstSubstitute(
    product?.inventory,
    productsMap,
  )?.product;
  if (
    isSupplierOutOfStock(product?.inventory) &&
    isValidSubstitute(
      firstSubstitute,
      isDivisionalSubAtIndex(product?.inventory?.substituteProducts, 1),
      product?.inventory?.ignoreSubAvailability,
    ) &&
    productHasStatus('3', firstSubstitute?.inventory) &&
    (isCustomerMslRestricted(appStateForProduct)
      ? calculateMslRestriction(
          isCustomerMslRestricted(appStateForProduct),
          product?.mslProduct?.isOrderableOnMsl,
          mslProductHasSecondaries(product?.mslProduct),
          product?.summary?.mslUnrestrict ?? false,
        )
      : true)
  ) {
    return true;
  }
  return false;
};

const getFirstSubstitute = (
  productInventory: ProductInventory,
  productsMap: Map<number, Product>,
): { product: Product; tracking: SubstituteTrackingInfo } => {
  const firstSubstitute = productInventory?.substituteProducts?.find(
    substitute => substitute.index === 1,
  );
  if (!!firstSubstitute) {
    return {
      product: productsMap.get(firstSubstitute.productNumber),
      tracking: {
        substitutePriority: 1,
        substituteType:
          firstSubstitute.substituteType ?? SUBSTITUTE_TYPE.divsionSub,
      } as SubstituteTrackingInfo,
    };
  }
  return undefined;
};

const findSubstitutes = (
  productInventory: ProductInventory,
  productsMap: Map<number, Product>,
  appStateForProduct: AppStateForProduct,
): ProductAlternative => {
  const sortedSubstitutes = [
    ...(productInventory.substituteProducts ?? []),
  ].sort((lhs, rhs) => lhs.index - rhs.index);

  let validSubstitute = sortedSubstitutes?.find(substitute =>
    isValidSubstitute(
      productsMap.get(substitute.productNumber),
      isDivisionalSub(substitute),
      productInventory?.ignoreSubAvailability,
    ),
  );

  if (isCustomerMslRestricted(appStateForProduct)) {
    const stat0OutOfStockProduct = sortedSubstitutes?.find(substitute => {
      const product = productsMap?.get(substitute?.productNumber);
      return (
        substitute?.nonDivisional &&
        substitute?.substituteType === SUBSTITUTE_TYPE.mslSub &&
        productHasStatus('0', product?.inventory) &&
        product?.inventory?.isInStockForSub === false
      );
    });

    const validSubProduct = productsMap?.get(validSubstitute?.productNumber);
    if (
      !!stat0OutOfStockProduct &&
      (!validSubstitute?.productNumber ||
        isDivisionalSub(validSubstitute) ||
        !(
          productIsInStatus(['0', '2', '3', '4', '8'], validSubProduct) &&
          validSubProduct?.inventory?.isInStockForSub === true
        ))
    ) {
      validSubstitute = stat0OutOfStockProduct;
    }
  }

  let replacement: ProductAlternative;
  // If you find it return it
  if (!!validSubstitute?.productNumber) {
    const substituteProduct = productsMap.get(validSubstitute?.productNumber);
    replacement = buildAlternative(true, true, false, substituteProduct);
    replacement.substituteInfo = {
      substitutePriority: validSubstitute.index,
      substituteType:
        validSubstitute.substituteType ?? SUBSTITUTE_TYPE.divsionSub,
    } as SubstituteTrackingInfo;
  } else {
    replacement = buildAlternative(true, false);
  }
  return replacement;
};

const isValidSubstitute = (
  product: Product,
  divisionalSub: boolean,
  ignoreSubAvailability = false,
): boolean => {
  if (!product || !product.summary || !product.inventory) {
    return false;
  }

  if (divisionalSub && ignoreSubAvailability) {
    return true;
  }

  if (
    product.summary.properties?.has(ProductPropertiesEnum.discontinued) ||
    product.summary.properties?.has(ProductPropertiesEnum.supplierOutOfStock)
  ) {
    return false;
  }
  if (!product.inventory.isInStockForSub) {
    return false;
  }
  return true;
};

const buildAlternative = (
  required: boolean,
  available?: boolean,
  prioritizeAlternative?: boolean,
  product?: Product,
  substituteInfo?: SubstituteTrackingInfo,
): ProductAlternative => ({
  required,
  available,
  product,
  substituteInfo,
  type: ProductAlternativeTypeEnum.substitute,
  prioritizeAlternative,
});

export const filterSubsByProperty = (
  substituteProducts: SubstituteProduct[],
  productsMap: Map<number, Product>,
  property: ProductPropertiesEnum,
): SubstituteProduct[] => {
  return substituteProducts.filter(sub => {
    const subProduct = productsMap.get(sub.productNumber);

    if (!!subProduct) {
      return subProduct.summary?.properties?.has(property);
    }
    return false;
  });
};

export const filterCashAndCarryProducts = (
  productsMap: Map<number, Product>,
  substituteProducts: SubstituteProduct[],
) => {
  return substituteProducts.filter(
    substitute =>
      productsMap.get(substitute.productNumber)?.inventory
        ?.cashCarryIndicator !== '2' &&
      productsMap.get(substitute.productNumber)?.inventory
        ?.cashCarryIndicator !== '3',
  );
};

export const filterOrSortSubs = (
  product: Product,
  productsMap: Map<number, Product>,
  appStateForProduct: AppStateForProduct,
  listDataForProduct: ListDataForProduct,
): SubstituteProduct[] => {
  if (product?.inventory?.substituteProducts) {
    // if ML split flag is off and customer is restricted to ML, do nothing and return unaltered list
    // do the same for OG split flag
    if (
      !appStateForProduct.subsFeatureFlag ||
      (!appStateForProduct.masterListFeatureFlag &&
        appStateForProduct.isMslRestricted) ||
      (!appStateForProduct.orderGuideFeatureFlag &&
        appStateForProduct.isOgRestricted)
    ) {
      return product.inventory.substituteProducts;
    }

    switch (appStateForProduct.subsCase) {
      // MSL cases
      // cust restricted to master list, filter non msl subs
      case ListFlagsEnum.CASE_M_M:
      case ListFlagsEnum.CASE_Y_M:
      case ListFlagsEnum.CASE_M_O:
      case ListFlagsEnum.CASE_N_M:
        return filterCashAndCarryProducts(
          productsMap,
          sortMSLSecondaries(product.mslProduct?.mslSecondaries),
        );
      // restrict to msl, sort giving prio to msl subs
      case ListFlagsEnum.CASE_N_O:
      case ListFlagsEnum.CASE_N_Y:
      case ListFlagsEnum.CASE_M_Y:
        const mslSecondaries = sortMSLSecondaries(
          product?.mslProduct?.mslSecondaries,
        );

        const usSubs = (
          !!product.inventory.substituteProducts
            ? [...product.inventory.substituteProducts]
            : []
        ).sort((lhs, rhs) => lhs.index - rhs.index);

        // ensure that no duplicate products are added to array, filter out invalid product numbers
        // when filtering duplicates, need to keep MSL secondaries over USF subs
        const subsMap = new Map();
        [...mslSecondaries, ...usSubs].forEach(sub => {
          if (!subsMap.has(sub.productNumber)) {
            subsMap.set(sub.productNumber, sub);
          }
        });
        let ret = [...subsMap.values()]
          .filter(sub => !!sub && sub.productNumber !== 0)
          .map((sub, i) => ({
            ...sub,
            index: i + 1,
          }));
        return filterCashAndCarryProducts(productsMap, ret);
      // OG cases
      // cust restricted to og, filter non og subs
      case ListFlagsEnum.CASE_Y_O:
        enrichProductSubsWithListData(
          product,
          productsMap,
          appStateForProduct,
          listDataForProduct,
        );
        return filterSubsByProperty(
          product.inventory.substituteProducts,
          productsMap,
          ProductPropertiesEnum.onOrderGuide,
        );
      // restrict to og, sort giving prio to og subs
      case ListFlagsEnum.CASE_Y_Y:
        enrichProductSubsWithListData(
          product,
          productsMap,
          appStateForProduct,
          listDataForProduct,
        );
        return sortBySubsAndOrderGuidePriority(
          product.inventory.substituteProducts,
          productsMap,
        );

      // no subs allowed for this customer, return empty list of subs
      case ListFlagsEnum.CASE_M_N:
      case ListFlagsEnum.CASE_Y_N:
      case ListFlagsEnum.CASE_N_N:
        return [];

      // return unaltered list by default
      default:
        return product.inventory.substituteProducts;
    }
  } else {
    return product?.inventory?.substituteProducts;
  }
};

const enrichProductSubsWithListData = (
  product: Product,
  productsMap: Map<number, Product>,
  appStateForProduct: AppStateForProduct,
  listDataForProduct: ListDataForProduct,
) => {
  product.inventory.substituteProducts.forEach(sub => {
    let substituteProduct = productsMap.get(sub.productNumber);
    if (!!substituteProduct) {
      enrichProductWithListData(
        product,
        listDataForProduct.listItemProductMap,
        appStateForProduct.isGlOwner,
      );
      if (appStateForProduct.masterListFeatureFlag) {
        enrichProductWithMasterListData(
          product,
          listDataForProduct.masterListItemProductMap,
          appStateForProduct.isMslRestricted,
        );
      }
    }
  });
};

export const sortBySubsAndOrderGuidePriority = (
  substituteProducts: SubstituteProduct[],
  productsMap: Map<number, Product>,
): SubstituteProduct[] => {
  // SORT BY INDEX PRIORITY
  substituteProducts = [...substituteProducts].sort(function (a, b) {
    return a.index - b.index;
  });
  // MOVE ORDER GUIDE PRODUCT TO THE LEFT
  const substituteProductsReturn: SubstituteProduct[] = [];
  for (let i = 0; i < substituteProducts.length; i++) {
    let sub = productsMap.get(substituteProducts[i].productNumber);
    if (
      !!sub?.summary &&
      sub.summary?.properties?.has(ProductPropertiesEnum.onOrderGuide)
    ) {
      substituteProductsReturn.push(substituteProducts[i]);
      substituteProducts.splice(i, 1);
      i--;
    }
  }
  for (let i = 0; i < substituteProducts.length; i++) {
    substituteProductsReturn.push(substituteProducts[i]);
  }
  return substituteProductsReturn.map((sub, i) => ({
    ...sub,
    index: i + 1,
  }));
};

// Returns an enum corresponding to the case to be addressed based on subs flags a customer has.
// Returns the most permissive case by default if one of the flags is unexpected
export const calculateSubFlags = (
  restrictToOG: string,
  subsAllowed: string,
): ListFlagsEnum => {
  // M
  if (restrictToOG === OGRestrictionsEnum.RESTRICT_TO_ML) {
    switch (subsAllowed) {
      case SubRestrictionsEnum.MASTER_LIST:
        return ListFlagsEnum.CASE_M_M;
      case SubRestrictionsEnum.NO_SUBS:
        return ListFlagsEnum.CASE_M_N;
      case SubRestrictionsEnum.ORDER_GUIDE:
        return ListFlagsEnum.CASE_M_O;
      case SubRestrictionsEnum.UNRESTRICTED:
        return ListFlagsEnum.CASE_M_Y;
      default:
        return ListFlagsEnum.CASE_M_M;
    }
    // Y
  } else if (restrictToOG === OGRestrictionsEnum.RESTRICT_TO_OG) {
    switch (subsAllowed) {
      case SubRestrictionsEnum.MASTER_LIST:
        return ListFlagsEnum.CASE_Y_M;
      case SubRestrictionsEnum.NO_SUBS:
        return ListFlagsEnum.CASE_Y_N;
      case SubRestrictionsEnum.ORDER_GUIDE:
        return ListFlagsEnum.CASE_Y_O;
      case SubRestrictionsEnum.UNRESTRICTED:
        return ListFlagsEnum.CASE_Y_Y;
      default:
        return ListFlagsEnum.CASE_Y_Y;
    }
    // N
  } else if (restrictToOG === OGRestrictionsEnum.UNRESTRICTED) {
    switch (subsAllowed) {
      case SubRestrictionsEnum.MASTER_LIST:
        return ListFlagsEnum.CASE_N_M;
      case SubRestrictionsEnum.NO_SUBS:
        return ListFlagsEnum.CASE_N_N;
      case SubRestrictionsEnum.ORDER_GUIDE:
        return ListFlagsEnum.CASE_N_O;
      case SubRestrictionsEnum.UNRESTRICTED:
        return ListFlagsEnum.CASE_N_Y;
      default:
        return ListFlagsEnum.CASE_N_Y;
    }
  } else {
    return ListFlagsEnum.CASE_N_Y;
  }
};

export const sortMSLSecondaries = (mslSecondaries: {
  [itemKey: string]: MslSecondary[];
}): SubstituteProduct[] => {
  if (!mslSecondaries) {
    return [];
  }

  // take an array of lists, then flatten and sort, then convert to SubstituteProduct type
  return Object.values(mslSecondaries)
    .reduce((accumulator, arr) => {
      return [...accumulator, ...arr];
    }, [])
    .sort((a, b) => mslComparator(a, b))
    .filter(mslSecondary => mslSecondary != null)
    .map((mslSecondary, index) => {
      return {
        conversionFactor: 0.0,
        index: index + 1,
        nonDivisional: true,
        productNumber: mslSecondary.productNumber,
        substituteType: SUBSTITUTE_TYPE.mslSub,
      };
    });
};

export const mslComparator = (a: MslSecondary, b: MslSecondary): number => {
  if (a == null && b == null) {
    return 0;
  }
  if (a == null) {
    return 1;
  }
  if (b == null) {
    return -1;
  }

  // sort on product priority first
  if (a.productPriority !== b.productPriority) {
    return a.productPriority < b.productPriority ? -1 : 1;
  }

  // there was a tie, sort on user defined priority
  if (a.userPriority !== b.userPriority) {
    return a.userPriority < b.userPriority ? -1 : 1;
  }

  // if there's still a tie, return more recent product
  if (!!a.mlmProductUpdateDtm && !!b.mlmProductUpdateDtm) {
    return new Date(a.mlmProductUpdateDtm) > new Date(b.mlmProductUpdateDtm)
      ? -1
      : 1;
  }

  // if one of the dates is invalid, just return product a
  return -1;
};

/***
 * Sub index starts from 1
 * @param substitutes
 * @param subIndex
 */
const isDivisionalSubAtIndex = (
  substitutes: SubstituteProduct[],
  subIndex: number,
): boolean => {
  if (substitutes?.length > 0) {
    const foundSub = substitutes.find(sub => sub.index === subIndex);
    return isDivisionalSub(foundSub);
  }

  return false;
};

const isDivisionalSub = (substitute: SubstituteProduct): boolean => {
  return !!substitute && !substitute.nonDivisional;
};
