<ion-header class="ion-no-border">
  <ion-toolbar>
    <div class="title">
      <ion-icon (click)="goBack()" name="chevron-back-outline"></ion-icon>
      <ion-label class="bold">{{ label | translate }}</ion-label>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <app-selectable-inventory-list
    [inventories]="inventories"
    [selectedInventory]="selectedInventory"
    (selectInventoryEmitter)="setSelectedInventory($event)"
  ></app-selectable-inventory-list>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="cancel-download-list-modal-button"
        class="usf-outline-green-mobile-modal-button"
        (click)="goBack()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        data-cy="confirmation-button"
        class="usf-fill-green-mobile-modal-button"
        [disabled]="!selectedInventory"
        (click)="save()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
