<ion-header class="ion-no-border ion-header-modal-desktop">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button
        id="close-modal-btn"
        (click)="dismiss()"
        data-cy="copy-list-close-modal-button"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label data-cy="copy-list-option-text">
      {{ 'i18n.lists.copyList' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="ion-content-modal-desktop"
  [ngClass]="
  platformType !== platformEnum.desktop
    ? platformType === platformEnum?.tablet
      ? 'ion-content-modal-tablet'
      : 'ion-content-modal-mobile'
    : 'ion-content-modal-desktop'
"
  scroll-y="false"
  tabletTouchMove
>
  <ion-grid>
    <ion-row>
      <!-- Copy List Options -->
      <ion-col size="5" class="create-list-options">
        <ion-item
          detail
          (click)="onSelectCopyListOption(listCopyOptions.selectList)"
          [class.selected]="selectedCopyListOption === listCopyOptions.selectList"
        >
          <ion-label
            class="create-list-option"
            data-cy="copy-list-option-list-name"
          >
            {{ 'i18n.lists.copyListModal.selectListToCopy' | translate }}
            <div>{{selectedList?.listName}}</div>
          </ion-label>
        </ion-item>
        <ion-item
          detail
          (click)="onSelectCopyListOption(listCopyOptions.newListName)"
          [class.selected]="selectedCopyListOption === listCopyOptions.newListName"
        >
          <ion-label
            class="create-list-option"
            data-cy="copy-list-option-new-list-name"
          >
            {{ 'i18n.lists.copyListModal.newListName' | translate }}
            <div>
              {{newListName ? newListName :
              'i18n.lists.copyListModal.enterListName' | translate }}
            </div>
          </ion-label>
        </ion-item>
        <ion-item
          *ngIf="(listsPageData$ | async)?.userKind === 'Internal'"
          detail
          (click)="onSelectCopyListOption(listCopyOptions.listType)"
          [class.selected]="selectedCopyListOption === listCopyOptions.listType"
        >
          <ion-label
            class="create-list-option"
            data-cy="copy-list-option-list-type"
          >
            {{ 'i18n.lists.copyListModal.listType' | translate }}
            <div>{{listType}}</div>
          </ion-label>
        </ion-item>
        <ion-item
          *ngIf="showMassCopy"
          detail
          (click)="onSelectCopyListOption(listCopyOptions.markets)"
          [class.selected]="selectedCopyListOption === listCopyOptions.markets"
        >
          <ion-label
            class="create-list-option"
            data-cy="copy-list-option-markets"
          >
            {{ 'i18n.lists.copyListModal.markets' | translate }}
            <div>
              {{marketSelected ? ('i18n.lists.copyListModal.selected' |
              translate) : ('i18n.lists.copyListModal.selectMarkets' |
              translate)}} {{marketSelected ? marketSelectedCount : ''}}
            </div>
          </ion-label>
        </ion-item>
        <ion-item
          *ngIf="showMassCopy"
          detail
          (click)="onSelectCopyListOption(listCopyOptions.customers)"
          [class.selected]="selectedCopyListOption === listCopyOptions.customers"
        >
          <ion-label
            class="create-list-option"
            data-cy="copy-list-option-customers"
          >
            {{ 'i18n.lists.copyListModal.customers' | translate }}
            <div>
              {{customerSelected ? ('i18n.lists.copyListModal.selected' |
              translate) : ('i18n.lists.copyListModal.selectCustomers' |
              translate)}} {{customerSelected ? customerSelectedCount : ''}}
            </div>
          </ion-label>
        </ion-item>
        <!-- Copy List Selections-->
      </ion-col>
      <!-- List of Lists -->

      <ion-col
        offset="1.5"
        size="5.5"
        class="create-list-input"
        *ngIf="selectedCopyListOption === listCopyOptions.selectList"
      >
        <div class="scroll">
          <ion-searchbar
            data-cy="copy-list-lists-searchbar"
            class="search"
            mode="md"
            placeholder="{{ 'i18n.lists.copyListModal.searchByList' | translate }}"
            [(ngModel)]="searchKey"
            (ionClear)="resetSearch()"
            (ionInput)="search()"
          >
          </ion-searchbar>
          <div class="no-results-found" *ngIf="filteredListView?.length === 0">
            {{'i18n.lists.copyListModal.noResultsFound' | translate}}
          </div>

          <ion-radio-group
            *ngIf="filteredListView?.length > 0"
            name="list-selection"
            value="{{selectedList?.listName}}"
            (ionChange)="handleListSelect($event)"
          >
            <ion-item *ngFor="let list of filteredListView">
              <ion-radio
                mode="md"
                labelPlacement="start"
                value="{{list?.listName}}"
                data-cy="list-name-type-radio"
              >
                <div class="list-name">{{list.listName}}</div>
                <div class="product-count">
                  {{list.listItemCount}} {{ list.listItemCount === 1 ?
                  ('i18n.lists.copyListModal.product' | translate) :
                  ('i18n.lists.copyListModal.products' | translate) }}
                </div>
              </ion-radio>
            </ion-item>
          </ion-radio-group>
        </div>
      </ion-col>

      <!-- NewListName -->
      <ion-col
        offset="1.5"
        size="5.5"
        class="create-list-input"
        *ngIf="selectedCopyListOption === listCopyOptions.newListName"
        [class.list-name-exists]="(listNameExists)"
      >
        <ion-item class="create-list-name">
          <ion-input
            label="{{ 'i18n.lists.newListName' | translate }}"
            label-placement="floating"
            id="usf-line-input-style"
            placeholder="{{'i18n.lists.copyListModal.enterName' | translate}}"
            data-cy="list-name-input"
            maxlength="30"
            [(ngModel)]="newListName"
            (ngModelChange)="getListNameExists()"
            value="{{newListName}}"
            trimSpace
          ></ion-input>
        </ion-item>
        <p *ngIf="(listNameExists)" class="list-name-exists-error">
          {{ 'i18n.lists.listNameExists' | translate }}
        </p>
      </ion-col>

      <!-- ListType -->
      <ion-col
        offset="1.5"
        size="5.5"
        class="create-list-input"
        *ngIf="selectedCopyListOption === listCopyOptions.listType"
      >
        <ion-radio-group
          *ngIf="filteredListView?.length > 0"
          [(ngModel)]="listType"
          data-cy="list-type-radio-group"
        >
          <ion-item>
            <ion-label
              id="public-list"
              class="list-type"
              data-cy="public-list-type-label"
            >
              {{ 'i18n.lists.public' | translate }}
            </ion-label>
            <ion-radio
              aria-labelledby="public-list"
              mode="md"
              slot="end"
              value="{{ ListTypes.public }}"
              data-cy="public-list-type-radio"
            ></ion-radio>
          </ion-item>
          <ion-item>
            <ion-label
              id="internal-list"
              class="list-type"
              data-cy="internal-list-type-label"
            >
              {{ 'i18n.lists.internal' | translate }}
            </ion-label>
            <ion-radio
              aria-labelledby="internal-list"
              mode="md"
              slot="end"
              value="{{ ListTypes.internal }}"
              data-cy="internal-list-type-radio"
            ></ion-radio>
          </ion-item>
          <ion-item>
            <ion-label
              id="private-list"
              class="list-type"
              data-cy="private-list-type-label"
            >
              {{ 'i18n.lists.private' | translate }}
            </ion-label>
            <ion-radio
              aria-labelledby="private-list"
              mode="md"
              slot="end"
              value="{{ ListTypes.private }}"
              data-cy="private-list-type-radio"
            ></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-col>
      <!-- Markets -->
      <ion-col
        offset="1.5"
        size="5.5"
        class="create-list-input"
        *ngIf="selectedCopyListOption === listCopyOptions.markets"
      >
        <ion-content scroll-y="true">
          <div class="scroll-offset">
            <ion-item class="select-all">
              <ion-checkbox
                justify="end"
                [checked]="marketSelectedCount === divisions.length"
                (ionChange)="handleSelectAllChange($event)"
              >
                {{marketSelectedCount === divisions.length
                ?('i18n.lists.copyListModal.deselectAll' | translate) :
                ('i18n.lists.copyListModal.selectAll' | translate)}}
              </ion-checkbox>
            </ion-item>
            <ion-item *ngFor="let division of divisions">
              <ion-checkbox
                value="{{division?.divisionNumber}}"
                (ionChange)="handleMarketSelection($event)"
                [checked]="division?.isSelected"
                class="market-name"
              >
                {{division?.divisionName}} ({{division.divisionNumber}})
              </ion-checkbox>
            </ion-item>
          </div>
        </ion-content>
      </ion-col>
      <!-- Customers -->
      <ion-col
        offset="1.5"
        size="5.5"
        class="create-list-input"
        *ngIf="selectedCopyListOption === listCopyOptions.customers"
      >
        <ion-content scroll-y="true">
          <div class="scroll-offset">
            <ion-searchbar
              data-cy="customer-search-bar"
              class="search"
              mode="md"
              placeholder="{{ 'i18n.lists.copyListModal.searchForCustomers' | translate }}"
              [(ngModel)]="searchKey"
              (ionClear)="resetSearch()"
              (ionInput)="search()"
            >
            </ion-searchbar>
            <div
              class="no-results-found"
              *ngIf="filteredCustomersView.length === 0"
            >
              {{'i18n.lists.copyListModal.noResultsFound' | translate}}
            </div>
            <ion-item
              class="select-all"
              *ngIf="filteredCustomersView.length > 0"
            >
              <ion-checkbox
                justify="end"
                [checked]="maximumSelectableCustomerCount === customerSelectedCount"
                (ionChange)="handleSelectAllChange($event)"
              >
                {{customerSelectedCount === maximumSelectableCustomerCount
                ?('i18n.lists.copyListModal.deselectAll' | translate) :
                ('i18n.lists.copyListModal.selectAll' | translate)}}
              </ion-checkbox>
            </ion-item>
            <div *ngFor="let customer of filteredCustomersView">
              <ion-item *ngIf="customer.departments.length === 0">
                <div class="circle-badge">
                  {{findFirstLetter(customer.customerName)}}
                </div>
                <ion-checkbox
                  value="{{customer?.customerNumber}}"
                  (ionChange)="handleCustomerSelection($event)"
                  [checked]="customer?.isSelected"
                  class="customer-name"
                >
                  <div class="customer-info">
                    {{customer.customerName}} ({{customer.customerNumber}})
                  </div>
                </ion-checkbox>
              </ion-item>
              <ion-item *ngIf="customer.departments.length > 0">
                <div class="circle-badge">
                  {{findFirstLetter(customer.customerName)}}
                </div>
                <div class="customer-name customer-info">
                  {{customer.customerName}} ({{customer.customerNumber}})
                </div>
              </ion-item>
              <ion-item *ngFor="let department of customer.departments">
                <ion-checkbox
                  class="customer-name"
                  value="{{department.customerNumber}}-{{ department?.departmentNumber}}"
                  (ionChange)="handleDepartmentSelection($event)"
                  [checked]="department?.isSelected"
                >
                  <div class="dept-customer-info">
                    {{department.departmentName}}
                  </div>
                </ion-checkbox>
              </ion-item>
            </div>
          </div>
        </ion-content>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="platformType !== platformEnum.mobile"
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="cancel-copy-list-modal-button"
        [ngClass]="
          platformType !== platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismiss()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        data-cy="copy-list-modal-button"
        [ngClass]="
            listNameExists || !(newListName?.trim().length > 0) || !selectedList || (customerSelected && !marketSelected) || (!customerSelected && marketSelected) ? 'usf-fill-disabled-dsktp-tblt-modal-button'
            : 'usf-fill-green-dsktp-tblt-modal-button'
        "
        (click)="copy()"
      >
        {{ 'i18n.common.create' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
