<div
  *ngFor="let purchaseRecord of recentPurchaseRecords"
  data-cy="recent-purchase-record-section"
  class="recent-purchase-record"
>
  <span data-cy="recent-purchase-record-cases-text" class="cases">
    {{ purchaseRecord.casesOrdered }}&nbsp;CS
  </span>
  <span data-cy="recent-purchase-record-separator-text" class="separator">
    |
  </span>
  <span data-cy="recent-purchase-record-eaches-text" class="eaches">
    {{ purchaseRecord.eachesOrdered }}&nbsp;EA
  </span>
  <span data-cy="recent-purchase-record-separator-text" class="separator">
    |
  </span>
  <span data-cy="recent-purchase-record-date-text" class="date">
    {{ purchaseRecord.dateOrdered }}
  </span>
</div>
