<ng-container
  *ngIf="
    vm.rightView?.searchPane?.searchableObservable$
      | async as searchableInventories
  "
>
  <div
    class="flex-container"
    *ngIf="(vm?.rightView?.error | async) === false; else noData"
  >
    <ion-label
      [attr.data-cy]="dataCyTag + '-seaarch-title'"
      *ngIf="!!vm.rightView?.searchPane?.searchTitle"
      class="subtitle search-title"
    >
      {{ vm.rightView?.searchPane?.searchTitle | translate }}
    </ion-label>
    <ion-searchbar
      [attr.data-cy]="dataCyTag + '-search-bar'"
      class="search"
      mode="md"
      placeholder="{{
        vm.rightView?.searchPane?.searchPlaceholder | translate
      }}"
      [(ngModel)]="vm.rightView.searchPane.searchKey"
      (ionClear)="resetSearch()"
      (ionInput)="handleSearch()"
    ></ion-searchbar>

    <div
      data-cy="no-results-found"
      class="no-results-found"
      *ngIf="searchableInventories?.length === 0"
    >
      {{ (vm.rightView?.searchPane?.noResultsOverride ?? 'i18n.lists.copyListModal.noResultsFound') | translate }}
    </div>
    <ion-list>
      <ion-radio-group value="{{ vm?.rightView?.selectedItem }}">
        <app-multi-select-inventory-button
          *ngFor="let inventory of searchableInventories; let i = index"
          [inventory]="inventory"
          [index]="i"
          [showDate]="false"
          [showDepartment]="true"
          [platform]="platform"
          (selectInventoryEmitter)="inventorySelected($event)"
        >
        </app-multi-select-inventory-button>
      </ion-radio-group>
    </ion-list>
  </div>
</ng-container>

<ng-template #noData>
  <div>
    <p class="subtitle-medium">
      {{ (vm?.rightView?.errorMessage ?? '')| translate }}
    </p>
  </div>
</ng-template>
