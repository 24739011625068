import { Injectable } from '@angular/core';
import {
  CostComparisonReportModalOutput,
  CostReportModalOutput,
  DownloadInventoryModalOutput,
} from '@usf/ngrx-inventory';
import jsPDF from 'jspdf';
import {
  InventoryGroupViewModel,
  InventoryViewModel,
} from '../../../../inventory/models/inventory-view.model';
import { CustomerDivisionDepartment } from '../../../../ngrx-customer/models/customer-division-department.model';
import { Product } from '../../../models/product.model';
import { DownloadPrintBaseService } from '../download-print-base.service';
import {
  createMultipleSeperatedColumnsForInventory,
  filterInventoryItems,
  getCSVColumns,
  getCostReportCSVColumns,
  getCostReportCSVColumnsNoProductDetails,
  getCostReportFileName,
  getCostReportPDFColumns,
  getCostReportPDFColumnsNoProductDetails,
  getGrandTotal,
  getInventoryTotal,
  getPDFColumns,
  getTitleString,
  sortInventoryItemsForCostReportCSVFile,
  stringifyCSV,
} from '../helpers/download-inventory-helpers';

import { DatePipe, DecimalPipe } from '@angular/common';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDateToMMDDYYYY } from '@shared/helpers/calendar.helpers';
import { CostComparisonReportRow } from '../../../../inventory/models/cost-comparison-report-row.model';
import {
  CostReportIncludeOptions,
  CostReportSortOptions,
} from '../../../../inventory/models/cost-report-model.model';
import { ItemTypes } from '../../../../lists/shared/list-detail-management/model/list-detail-management-view.model';
import {
  getDocumentString,
  getRawDocumentCurrency,
} from '../../../helpers/document-creation.helpers';
import { CurrencyService } from '../../currency.service';
import {
  addCostComparisonRowsToPdf,
  addInventoryNamesToHeader,
  addTodaysDateToHeader,
  createCostComparisonColumns,
  getCostComparisonCsvColumnsConfig,
  getCostComparisonPdfColumnsConfig,
  getCostComparisonReportRows,
  sortCostComparisonRows,
  stringifyCostComparison,
} from '../helpers/cost-comparison-report.helpers';
import {
  addDataColumns,
  addFooterToPDF,
  addHeaderToPDF,
  addLogoToHeader,
  addNameToHeader,
  addUserDataToHeader,
  createHeadersForPDF,
  getColumnStyles,
} from '../helpers/general-pdf-helper';
import {
  addGrandTotalToPdf,
  addInventoryToPdf,
  createInventoryPdf,
} from '../helpers/inventory-pdf-helper';
import autoTable, { CellDef, CellHookData } from 'jspdf-autotable';
import { desiredColumn } from '../../../models/desired-column';
import { PDFColors } from '../../../constants/pdf-colors.enum';
import { BODY_FONT } from '../../../constants/pdf-font';

@Injectable({
  providedIn: 'root',
})
export class DownloadInventoryService {
  constructor(
    private downloadPrintBaseService: DownloadPrintBaseService,
    private translateService: TranslateService,
    public datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
  ) {}

  saveExtendedReportBlob = (
    response: string,
    dateStart: Date,
    dateEnd: Date,
  ) => {
    this.downloadPrintBaseService.saveDocument(
      response,
      'Inventory_Cost_Extended_Report_' +
        formatDateToMMDDYYYY(dateStart) +
        '_' +
        formatDateToMMDDYYYY(dateEnd),
      'CSV',
      true,
    );
  };

  downloadInventoryCostComparisonReport = (
    downloadOptions: CostComparisonReportModalOutput,
    inventoryWorksheets: InventoryViewModel[],
    productsMap: Map<number, Product>,
    customerDivisionDepartment: CustomerDivisionDepartment,
  ) => {
    const firstInventory = inventoryWorksheets[0];
    const secondInventory = inventoryWorksheets[1];
    let costComparisonReportRows = getCostComparisonReportRows(
      firstInventory,
      secondInventory,
      productsMap,
      downloadOptions.selectedFormat === 'PDF',
    );
    if (
      downloadOptions.productsToInclude ===
      CostReportIncludeOptions.productsWithPriceChanges
    ) {
      costComparisonReportRows = costComparisonReportRows.filter(
        costComparisonReportRow =>
          costComparisonReportRow.casePriceChange !== '"$0.00"',
      );
    }
    if (costComparisonReportRows.length > 0) {
      costComparisonReportRows = sortCostComparisonRows(
        costComparisonReportRows,
        downloadOptions.selectedSort,
      );
      try {
        switch (downloadOptions.selectedFormat) {
          case 'CSV':
            this.generateAndSaveCostComparisonCSV(
              downloadOptions,
              costComparisonReportRows,
              customerDivisionDepartment,
            );
            break;
          case 'PDF':
            this.generateAndSaveCostComparisonPDF(
              downloadOptions,
              costComparisonReportRows,
              customerDivisionDepartment,
            );
            break;
        }
      } catch (error) {
        console.error('Error creating file', error);
        this.downloadPrintBaseService.showErrorToast(
          'i18n.inventory.costComparisonReportModal.generalDownloadFailure',
        );
        this.downloadPrintBaseService.cleanUp();
      }
    } else {
      this.downloadPrintBaseService.showErrorToast(
        'i18n.inventory.costComparisonReportModal.noProductsDownloadFailure',
      );
      this.downloadPrintBaseService.cleanUp();
    }
  };

  generateAndSaveCostComparisonCSV = async (
    downloadOptions: CostComparisonReportModalOutput,
    costComparisonReportRows: CostComparisonReportRow[],
    customerDivisionDepartment: CustomerDivisionDepartment,
  ) => {
    const columnsConfig = getCostComparisonCsvColumnsConfig(
      downloadOptions.includeGlCodes,
    );
    const separatedColumns = createCostComparisonColumns(
      costComparisonReportRows,
      columnsConfig,
    );
    const firstInventoryDate = this.datePipe.transform(
      downloadOptions.firstSelectedInventory.inventoryDate,
      'M/d/yyyy',
    );
    const secondInventoryDate = this.datePipe.transform(
      downloadOptions.secondSelectedInventory.inventoryDate,
      'M/d/yyyy',
    );
    const headerName = this.getCostComparisonHeaderName(downloadOptions);
    const titleString = getTitleString(customerDivisionDepartment, headerName);

    let csv = '';
    csv += titleString;
    csv += stringifyCostComparison(
      separatedColumns,
      firstInventoryDate,
      secondInventoryDate,
    );
    const fileName = this.getCostComparisonFileName(downloadOptions);
    await this.downloadPrintBaseService.saveDocument(
      csv,
      fileName,
      'CSV',
      true,
    );
  };

  generateAndSaveCostComparisonPDF = async (
    downloadOptions: CostComparisonReportModalOutput,
    costComparisonReportRows: CostComparisonReportRow[],
    customerDivisionDepartment: CustomerDivisionDepartment,
  ) => {
    const columnsConfig = getCostComparisonPdfColumnsConfig(
      downloadOptions.includeGlCodes,
    );
    const fileName = this.getCostComparisonFileName(downloadOptions);
    const isMobile = Capacitor.isNativePlatform();
    const todaysDate = this.datePipe.transform(new Date(), 'M/d/yyyy');
    const firstInventoryDate = this.datePipe.transform(
      downloadOptions.firstSelectedInventory.inventoryDate,
      'M/d/yyyy',
    );
    const secondInventoryDate = this.datePipe.transform(
      downloadOptions.secondSelectedInventory.inventoryDate,
      'M/d/yyyy',
    );
    let doc = new jsPDF('l', 'px', [1440, 1055]);

    await addLogoToHeader(doc, isMobile);
    addNameToHeader(doc, 'Inventory Cost Comparison Report');
    addTodaysDateToHeader(doc, todaysDate);
    addInventoryNamesToHeader(
      doc,
      downloadOptions.firstSelectedInventory.inventoryName +
        ' ' +
        firstInventoryDate,
      downloadOptions.secondSelectedInventory.inventoryName +
        ' ' +
        secondInventoryDate,
    );
    addUserDataToHeader(doc, customerDivisionDepartment, isMobile);
    doc = await addCostComparisonRowsToPdf(
      doc,
      costComparisonReportRows,
      columnsConfig,
      downloadOptions.includeGlCodes,
    );

    addFooterToPDF(doc);

    doc.setProperties({ title: fileName });
    const pdf = doc.output('blob');
    await this.downloadPrintBaseService.saveDocument(
      pdf,
      fileName,
      'PDF',
      true,
    );
  };

  getCostComparisonHeaderName = (
    downloadOptions: CostComparisonReportModalOutput,
  ) => {
    const firstInventoryDate = this.datePipe.transform(
      downloadOptions.firstSelectedInventory.inventoryDate,
      'M/d/yyyy',
    );
    const secondInventoryDate = this.datePipe.transform(
      downloadOptions.secondSelectedInventory.inventoryDate,
      'M/d/yyyy',
    );
    return (
      'Inventory Cost Comparison: ' +
      downloadOptions.firstSelectedInventory.inventoryName +
      ' - ' +
      firstInventoryDate +
      ' and ' +
      downloadOptions.secondSelectedInventory.inventoryName +
      ' - ' +
      secondInventoryDate
    );
  };
  getCostComparisonFileName = (
    downloadOptions: CostComparisonReportModalOutput,
  ) => {
    const firstInventoryDate = this.datePipe.transform(
      downloadOptions.firstSelectedInventory.inventoryDate,
      'M/d/yyyy',
    );
    const secondInventoryDate = this.datePipe.transform(
      downloadOptions.secondSelectedInventory.inventoryDate,
      'M/d/yyyy',
    );
    const firstDateWithNoSlashes = firstInventoryDate.split('/').join('_');
    const secondDateWithNoSlashes = secondInventoryDate.split('/').join('_');
    return (
      'Inventory_Cost_Comparison_Report_' +
      firstDateWithNoSlashes +
      '_' +
      secondDateWithNoSlashes
    );
  };

  downloadInventoryCostReport = (
    downloadOptions: CostReportModalOutput,
    inventoryWorkSheets: InventoryViewModel[],
    productsMap: Map<number, Product>,
    customerDivisionDepartment: CustomerDivisionDepartment,
  ) => {
    try {
      switch (downloadOptions.selectedFormat) {
        case 'CSV':
          this.generateAndSaveCostCSV(
            downloadOptions,
            inventoryWorkSheets,
            productsMap,
            customerDivisionDepartment,
          );
          break;
        case 'PDF':
          this.generateAndSaveCostPDF(
            downloadOptions,
            inventoryWorkSheets,
            productsMap,
            customerDivisionDepartment,
          );
          break;
      }
    } catch (error) {
      console.error('Error creating file', error);
    }
  };

  downloadInventory = (
    downloadOptions: DownloadInventoryModalOutput,
    inventoryWorkSheet: InventoryViewModel,
    productsMap: Map<number, Product>,
    customerDivisionDepartment: CustomerDivisionDepartment,
  ) => {
    try {
      switch (downloadOptions.format) {
        case 'CSV':
          this.generateAndSaveCSV(
            downloadOptions,
            inventoryWorkSheet,
            productsMap,
            customerDivisionDepartment,
          );
          break;
        case 'PDF':
          this.generateAndSavePDF(
            downloadOptions,
            inventoryWorkSheet,
            productsMap,
            customerDivisionDepartment,
          );
          break;
      }
    } catch (error) {
      console.error('Error creating file', error);
    }
  };

  generateAndSaveInventorySummaryCSV = async (
    inventoryViewModel: InventoryViewModel,
    customerDivisionDepartment: CustomerDivisionDepartment,
  ) => {
    let csv = '';

    const fullInventoryName =
      inventoryViewModel.inventoryName +
      ' - ' +
      this.datePipe.transform(inventoryViewModel.inventoryDate, 'M/d/yy');

    const title = getTitleString(customerDivisionDepartment, fullInventoryName);

    csv += title;

    csv +=
      this.translateService.instant('i18n.inventory.summaryModal.group') + ',';
    csv +=
      this.translateService.instant('i18n.inventory.summaryModal.csCount') +
      ',';
    csv +=
      this.translateService.instant('i18n.inventory.summaryModal.eaCount') +
      ',';
    csv +=
      this.translateService.instant('i18n.inventory.summaryModal.totalPrice') +
      '\r\n';

    for (const item of inventoryViewModel?.items) {
      if (item?.itemType === ItemTypes.group) {
        const groupItem = item as InventoryGroupViewModel;
        csv += getDocumentString(groupItem?.groupName) + ',';
        csv +=
          getDocumentString(this.decimalPipe.transform(groupItem?.totalCases)) +
          ',';
        csv +=
          getDocumentString(
            this.decimalPipe.transform(groupItem?.totalEaches),
          ) + ',';
        csv += getRawDocumentCurrency(groupItem?.totalPrice) + '\r\n';
      }
    }

    csv +=
      this.translateService.instant('i18n.inventory.summaryModal.allGroups') +
      ',';
    csv +=
      getDocumentString(
        this.decimalPipe.transform(inventoryViewModel?.totalCases),
      ) + ',';
    csv +=
      getDocumentString(
        this.decimalPipe.transform(inventoryViewModel?.totalEaches),
      ) + ',';
    csv += getRawDocumentCurrency(inventoryViewModel?.totalPrice);

    const fileName = this.getInventorySummaryFileName(inventoryViewModel);

    await this.downloadPrintBaseService.saveDocument(
      csv,
      fileName,
      'CSV',
      true,
    );
  };

  generateAndSaveInventorySummaryPDF = async (
    inventoryViewModel: InventoryViewModel,
    customerDivisionDepartment: CustomerDivisionDepartment,
  ) => {
    const fullInventoryName =
      inventoryViewModel.inventoryName +
      ' - ' +
      this.datePipe.transform(inventoryViewModel.inventoryDate, 'M/d/yy');
    const isMobile = Capacitor.isNativePlatform();
    let doc = new jsPDF('l', 'px', [1440, 1055]);

    await addHeaderToPDF(
      doc,
      fullInventoryName,
      inventoryViewModel?.numProducts,
      customerDivisionDepartment,
      isMobile,
      inventoryViewModel,
    );
    this.addInventorySummaryDataTableToPdf(doc, inventoryViewModel);

    addFooterToPDF(doc);

    const fileName = this.getInventorySummaryFileName(inventoryViewModel);
    doc.setProperties({ title: fileName });
    const pdf = doc.output('blob');

    await this.downloadPrintBaseService.saveDocument(
      pdf,
      fileName,
      'PDF',
      true,
    );
  };

  getInventorySummaryDesiredColumns = () => {
    const groupNameHeader: desiredColumn = {
      columnName: this.translateService.instant(
        'i18n.inventory.summaryModal.group',
      ),
      cellWidth: 355,
    } as desiredColumn;
    const csCountHeader: desiredColumn = {
      columnName: this.translateService.instant(
        'i18n.inventory.summaryModal.csCount',
      ),
      cellWidth: 355,
    } as desiredColumn;
    const eaCountHeader: desiredColumn = {
      columnName: this.translateService.instant(
        'i18n.inventory.summaryModal.eaCount',
      ),
      cellWidth: 355,
    } as desiredColumn;
    const totalPriceHeader: desiredColumn = {
      columnName: this.translateService.instant(
        'i18n.inventory.summaryModal.totalPrice',
      ),
      cellWidth: 355,
    } as desiredColumn;
    const dataColumns: desiredColumn[] = [
      groupNameHeader,
      csCountHeader,
      eaCountHeader,
      totalPriceHeader,
    ];
    return dataColumns;
  };

  getInventorySummaryDataTable = (inventoryViewModel: InventoryViewModel) => {
    const dataTable: string[][] = [];
    let totalCsCount = 0;
    let totalEaCount = 0;
    for (const item of inventoryViewModel?.items) {
      if (item?.itemType === ItemTypes.group) {
        const groupItem = item as InventoryGroupViewModel;
        const groupName = groupItem?.groupName;
        const csCount = this.decimalPipe.transform(groupItem?.totalCases);
        const eaCount = this.decimalPipe.transform(groupItem?.totalEaches);
        const totalPrice = CurrencyService.toUSDString(groupItem?.totalPrice);
        totalCsCount += groupItem?.totalCases;
        totalEaCount += groupItem?.totalEaches;
        const dataRow: string[] = [groupName, csCount, eaCount, totalPrice];
        dataTable.push(dataRow);
      }
    }

    const allGroups = this.translateService.instant(
      'i18n.inventory.summaryModal.allGroups',
    );
    const totalPrice = CurrencyService.toUSDString(
      inventoryViewModel?.totalPrice,
    );

    const finalRow: string[] = [
      allGroups,
      this.decimalPipe.transform(totalCsCount),
      this.decimalPipe.transform(totalEaCount),
      totalPrice,
    ];
    dataTable.push(finalRow);
    return dataTable;
  };

  addInventorySummaryDataTableToPdf = (
    doc: jsPDF,
    inventoryViewModel: InventoryViewModel,
  ) => {
    const desiredColumns = this.getInventorySummaryDesiredColumns();
    const columnHeaders: CellDef[][] = createHeadersForPDF(desiredColumns);
    const columnStyles = getColumnStyles(desiredColumns);
    const dataTable: string[][] = this.getInventorySummaryDataTable(
      inventoryViewModel,
    );
    const isMobile = Capacitor.isNativePlatform();
    const nextTableYPosition = isMobile ? 135 : 145;
    autoTable(doc, {
      head: columnHeaders,
      body: dataTable,
      startY: nextTableYPosition,
      margin: {
        left: 10,
        right: 10,
        bottom: 100,
      },
      theme: 'grid',
      tableWidth: 'auto',
      pageBreak: 'auto',
      rowPageBreak: 'avoid',
      styles: {
        fontSize: BODY_FONT,
        halign: 'left',
        valign: 'middle',
        lineColor: PDFColors.pdfBlack,
        lineWidth: 1,
        minCellHeight: 20,
        textColor: PDFColors.pdfBlack,
      },
      columnStyles,
      willDrawCell: (cellData: CellHookData) => {
        if (cellData.row.section === 'head') {
          doc.setTextColor(PDFColors.pdfWhite);
        }
        if (cellData.row.section == 'body') {
          doc.setTextColor(PDFColors.pdfBlack);
        }
        const isLastRow =
          cellData.row.index === cellData.table.body?.length - 1;
        if (isLastRow) {
          doc.setFont('helvetica', 'bold');
        } else {
          doc.setFont('helvetica', 'normal');
        }
      },
    });
  };

  getInventorySummaryFileName = (inventoryViewModel: InventoryViewModel) => {
    return (
      inventoryViewModel.inventoryName.replace(/\//g, '_') +
      ' ' +
      this.datePipe.transform(inventoryViewModel?.inventoryDate, 'MMddYYYY') +
      ' ' +
      this.translateService.instant('i18n.inventory.summaryModal.summary')
    );
  };

  generateAndSaveCSV = async (
    downloadOptions: DownloadInventoryModalOutput,
    inventoryWorkSheet: InventoryViewModel,
    productsMap: Map<number, Product>,
    customerDivisionDepartment: CustomerDivisionDepartment,
  ) => {
    let csv = '';
    const isDefaultFileName =
      downloadOptions.fileName ===
      inventoryWorkSheet.inventoryName +
        ' ' +
        this.datePipe.transform(inventoryWorkSheet.inventoryDate, 'M/d/yy');
    const columnsConfig = getCSVColumns(downloadOptions.includeProductStatus);
    const headers = columnsConfig.map(column => column.columnName).join();
    const inventoryItems = filterInventoryItems(
      inventoryWorkSheet.items,
      downloadOptions.selectedGroup,
    );
    const separatedColumns = createMultipleSeperatedColumnsForInventory(
      inventoryItems,
      productsMap,
      columnsConfig,
    );
    const inventoryWorksheetString = this.translateService.instant(
      'i18n.inventory.inventoryWorksheetTitle',
    );
    const title = getTitleString(
      customerDivisionDepartment,
      inventoryWorksheetString + ' - ' + inventoryWorkSheet.inventoryName,
    );
    // remove '/' so download functions on iOS
    const fileName = isDefaultFileName
      ? inventoryWorkSheet.inventoryName.replace(/\//g, '_') +
        '_' +
        this.datePipe.transform(inventoryWorkSheet.inventoryDate, 'Mddyyyy')
      : downloadOptions.fileName.replace(/\//g, '_');
    csv += title;
    // Ignore attempting to stringify columns if empty
    if (separatedColumns.length > 0) {
      csv += stringifyCSV(separatedColumns);
    } else {
      csv += headers;
    }
    await this.downloadPrintBaseService.saveDocument(
      csv,
      fileName,
      'CSV',
      true,
    );
  };

  generateAndSaveCostCSV = async (
    downloadOptions: CostReportModalOutput,
    inventoryWorkSheets: InventoryViewModel[],
    productsMap: Map<number, Product>,
    customerDivisionDepartment: CustomerDivisionDepartment,
  ) => {
    let csv = '';
    let i = 0;
    let grandTotal = 0;
    const noProductDetails =
      downloadOptions.selectedSort ===
      CostReportSortOptions.glCodesWithoutProductDetail;
    for (const inventory of inventoryWorkSheets) {
      let sortedItems = sortInventoryItemsForCostReportCSVFile(
        downloadOptions.selectedSort,
        inventory.items,
      );

      const columnsConfig = noProductDetails
        ? getCostReportCSVColumnsNoProductDetails()
        : getCostReportCSVColumns(downloadOptions.includeGlCodes);

      const separatedColumns = createMultipleSeperatedColumnsForInventory(
        sortedItems,
        productsMap,
        columnsConfig,
      );
      const title = getTitleString(
        customerDivisionDepartment,
        inventory.inventoryName + ' - ' + downloadOptions.selectedDate,
      );
      csv += title;
      csv += stringifyCSV(separatedColumns);
      csv += getInventoryTotal(
        inventory.totalPrice,
        inventory.inventoryName + ' - ' + downloadOptions.selectedDate,
        noProductDetails,
        downloadOptions.includeGlCodes,
      );
      grandTotal += inventory.totalPrice;
      i++;
      // Add two lines inbetween inventories
      if (i < inventoryWorkSheets.length) {
        csv += '\n\n';
      }
    }
    csv += getGrandTotal(
      grandTotal,
      noProductDetails,
      downloadOptions.includeGlCodes,
    );

    const fileName = getCostReportFileName(downloadOptions.selectedDate);
    await this.downloadPrintBaseService.saveDocument(
      csv,
      fileName,
      'CSV',
      true,
    );
  };

  generateAndSaveCostPDF = async (
    downloadOptions: CostReportModalOutput,
    inventoryWorkSheets: InventoryViewModel[],
    productsMap: Map<number, Product>,
    customerDivisionDepartment: CustomerDivisionDepartment,
  ) => {
    const columnsConfig =
      downloadOptions.selectedSort ===
      CostReportSortOptions.glCodesWithoutProductDetail
        ? getCostReportPDFColumnsNoProductDetails()
        : getCostReportPDFColumns(downloadOptions.includeGlCodes);
    const fileName = getCostReportFileName(downloadOptions.selectedDate);
    const isMobile = Capacitor.isNativePlatform();
    let doc = new jsPDF('l', 'px', [1440, 1055]);
    let i = 0;
    let grandTotal = 0;

    for (const inventory of inventoryWorkSheets) {
      if (i > 0) {
        doc.addPage('', 'l');
      }
      doc = await addInventoryToPdf(
        doc,
        downloadOptions,
        inventory,
        customerDivisionDepartment,
        columnsConfig,
        productsMap,
        isMobile,
      );
      grandTotal += inventory.totalPrice;
      i++;
    }

    addFooterToPDF(doc);
    doc = addGrandTotalToPdf(doc, CurrencyService.toUSDString(grandTotal));
    doc.setProperties({ title: fileName });
    const pdf = doc.output('blob');
    await this.downloadPrintBaseService.saveDocument(
      pdf,
      fileName,
      'PDF',
      true,
    );
  };

  generateAndSavePDF = async (
    downloadOptions: DownloadInventoryModalOutput,
    inventoryWorkSheet: InventoryViewModel,
    productsMap: Map<number, Product>,
    customerDivisionDepartment: CustomerDivisionDepartment,
  ) => {
    const columnsConfig = getPDFColumns(
      downloadOptions.includeProductStatus,
      downloadOptions.includeFreehandCount,
    );
    const isDefaultFileName =
      downloadOptions.fileName ===
      inventoryWorkSheet.inventoryName +
        ' ' +
        this.datePipe.transform(inventoryWorkSheet.inventoryDate, 'M/d/yy');
    const fileName = isDefaultFileName
      ? inventoryWorkSheet.inventoryName.replace(/\//g, '_') +
        '_' +
        this.datePipe.transform(inventoryWorkSheet.inventoryDate, 'Mddyyyy')
      : downloadOptions.fileName.replace(/\//g, '_');
    const isMobile = Capacitor.isNativePlatform();
    let doc = new jsPDF('l', 'px', [1440, 1055]);

    doc = await createInventoryPdf(
      doc,
      downloadOptions,
      inventoryWorkSheet,
      customerDivisionDepartment,
      columnsConfig,
      productsMap,
      isMobile,
    );

    addFooterToPDF(doc);
    doc.setProperties({ title: fileName });
    const pdf = doc.output('blob');
    await this.downloadPrintBaseService.saveDocument(
      pdf,
      fileName,
      'PDF',
      true,
    );
  };
}
