import { Component, Input } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { InventoryState } from '@usf/ngrx-inventory/lib/models/inventory/inventory.model';

@Component({
  selector: 'app-mobile-selectable-inventory-list',
  templateUrl: './mobile-selectable-inventory-list.component.html',
  styleUrls: ['./mobile-selectable-inventory-list.component.scss'],
})
export class MobileSelectableInventoryListComponent {
  @Input() label: string;
  @Input() inventories: InventoryState[];
  @Input() callBack: Function;
  @Input() selectedInventory: InventoryState;

  constructor(private ionNav: IonNav) {}

  setSelectedInventory(inventory: InventoryState) {
    if (inventory === this.selectedInventory) {
      this.selectedInventory = undefined;
    } else {
      this.selectedInventory = inventory;
    }
  }

  goBack() {
    this.ionNav.pop();
  }

  save() {
    this.callBack(this.selectedInventory);
    this.ionNav.pop();
  }
}
