import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';
import { notificationCountSelectors } from '@usf/ngrx-alerts';
import { INotificationCount } from '@usf/ngrx-alerts/lib/reducers/notification-count/notification-count.reducer';
import { UpsertTypeEnum } from 'src/app/ngrx-message/constants/messageTypeEnum';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Message } from '../../models/client/message';
import {
  upsertManyMessages,
  upsertMessage,
} from '../../store/actions/message/message.action';
import {
  otelGlobalErrorMes,
  otelHttpErrorMes,
} from '@app/ngrx-message/store/actions/message/message-tracking.action';
import { messageSelectors } from '../../store/selectors/message.selector';

@Injectable({
  providedIn: 'root',
})
export class MessageStoreService {
  constructor(private store: Store) {}

  upsertMessage(message: Message, type?: UpsertTypeEnum): void {
    if (message && !message.id) {
      message.id = uuidv4();
    }
    const logData: Tracking = {
      log: {
        data: {
          message,
        },
      },
    };
    this.store.dispatch(upsertMessage({ message }));
    if (type === 'global') {
      this.store.dispatch(otelGlobalErrorMes({ tracking: logData }));
    } else if (type === 'http') {
      this.store.dispatch(otelHttpErrorMes({ tracking: logData }));
    }
  }

  selectMessage$(): Observable<Message> {
    return this.store.select(messageSelectors.selectMessage);
  }

  selectAlertCountMessage$(): Observable<INotificationCount> {
    return this.store.select(
      notificationCountSelectors.selectUnreadAlertsCountState,
    );
  }

  selectToastMessage$(): Observable<Message[]> {
    return this.store.select(messageSelectors.selectToastMessage);
  }

  upsertManyMessages(messages: Message[]): void {
    for (const message of messages) {
      if (message && !message.id) {
        message.id = uuidv4();
      }
    }
    this.store.dispatch(upsertManyMessages({ messages }));
  }
}
