import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DivisionPhoneNumber } from '@usf/customer-types';
import { DIVISION_PHONE_NUMBER_KEY } from '../../constants/constants';
import {
  IDivisionPhoneNumberState,
  divisionPhoneNumberAdapter,
} from '../reducers/division-phone-number.reducers';

const selectDivisionPhoneNumberState = createFeatureSelector<IDivisionPhoneNumberState>(
  DIVISION_PHONE_NUMBER_KEY,
);

export const {
  selectIds: selectDivisionPhoneNumberIds,
  selectEntities: selectDivisionPhoneNumberEntities,
  selectAll: selectAllDivisionPhoneNumbers,
  selectTotal: selectDivisionPhoneNumberTotal,
} = divisionPhoneNumberAdapter.getSelectors();

export const getDivisionPhoneNumbers = createSelector(
  selectDivisionPhoneNumberState,
  selectAllDivisionPhoneNumbers,
);

export const selectDivisionPhoneNumbersLoading = createSelector(
  selectDivisionPhoneNumberState,
  (divisionPhoneNumberState: IDivisionPhoneNumberState) =>
    divisionPhoneNumberState.loading,
);

export const selectDivisionPhoneNumbersError = createSelector(
  selectDivisionPhoneNumberState,
  (divisionPhoneNumberState: IDivisionPhoneNumberState) =>
    divisionPhoneNumberState.error,
);

export const selectPhoneNumbersByDivisionNumber = (divisionNumber: number) =>
  createSelector(
    getDivisionPhoneNumbers,
    (divisionPhoneNumbers: DivisionPhoneNumber[]) =>
      divisionPhoneNumbers.find(
        divisionPhoneNumber =>
          divisionPhoneNumber?.divisionNumber === divisionNumber,
      ),
  );
