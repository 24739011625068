import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncateWithEllipsis' })
export class TruncateWithEllipsisPipe implements PipeTransform {
  transform(value: string, limit: number): string {
    if (!value || !limit || value.length <= limit) {
      return value;
    } else {
      return value.substring(0, limit - 3) + '...';
    }
  }
}
