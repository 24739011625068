import { Injectable } from '@angular/core';
import { CustomerDataService } from '@app/ngrx-customer/services/customer/customer-data.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DivisionPhoneNumber } from '@usf/customer-types';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { DIVISION_PHONE_NUMBER_ACTIONS } from '../actions/division-phone-number.actions';

@Injectable({
  providedIn: 'root',
})
export class DivisionPhoneNumberEffects {
  divisionPhoneNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DIVISION_PHONE_NUMBER_ACTIONS.getAllDivisionPhoneNumbers),
      concatMap(() => {
        return this.customerDataService
          .get<DivisionPhoneNumber>('division-phone-numbers')
          .pipe(
            map(divisionPhoneNumbers => {
              return DIVISION_PHONE_NUMBER_ACTIONS.getAllDivisionPhoneNumbersSuccess(
                {
                  divisionPhoneNumbers,
                },
              );
            }),
            catchError(error =>
              of(
                DIVISION_PHONE_NUMBER_ACTIONS.getAllDivisionPhoneNumbersFail({
                  error,
                }),
              ),
            ),
          );
      }),
    ),
  );

  phoneNumbersByDivisionNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DIVISION_PHONE_NUMBER_ACTIONS.getPhoneNumbersByDivisionNumber),
      concatMap(divisionNumber => {
        return this.customerDataService
          .get<DivisionPhoneNumber>('division-phone-number/' + divisionNumber)
          .pipe(
            map(divisionPhoneNumber => {
              return DIVISION_PHONE_NUMBER_ACTIONS.getPhoneNumbersByDivisionNumberSuccess(
                { divisionPhoneNumber: divisionPhoneNumber[0] },
              );
            }),
            catchError(error =>
              of(
                DIVISION_PHONE_NUMBER_ACTIONS.getPhoneNumbersByDivisionNumberFail(
                  {
                    error,
                  },
                ),
              ),
            ),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private customerDataService: CustomerDataService<any>,
  ) {}
}
