import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IonNav,
  ModalController,
  PopoverController,
  ViewWillEnter,
} from '@ionic/angular';
import { PlatformEnum } from '@panamax/app-state';
import { EllipsisOptionsPopoverComponent } from '../../../../shared/components/popovers/ellipsis-options/ellipsis-options-popover.component';
import { DownloadRequestFileType } from '@usf/list-types';
import { DownloadListOptions, ListState } from '@usf/ngrx-list';
import { ListTypes } from '@shared/constants/lists-constants';
import { ListCopyOptions } from '@shared/constants/lists-constants';
import { ListsPageData } from '@app/lists/model/lists-page.model';
import { ListsViewModel } from '@app/lists/model/lists-view.model';
import { ListsService } from '../../../services/lists.service';
import { CopyListModalBaseComponent } from '../copy-list-modal-base-component';
import { CopyListInputComponent } from './copy-list-input/copy-list-input-component';
import {
  CustomerSelection,
  DivisionSelection,
} from '@app/lists/model/copy-list-options.model';

@Component({
  selector: 'app-copy-list-modal',
  templateUrl: './copy-list-modal-touch-component.html',
  styleUrls: ['./copy-list-modal-touch-component.scss'],
})
export class CopyListModalTouchComponent extends CopyListModalBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    listsService: ListsService,
    popoverController: PopoverController,
  ) {
    super(nav, popoverController, modalController, listsService);
  }

  selectedCopyListOption: ListCopyOptions;

  onSelectCopyListOption(listOption: ListCopyOptions) {
    this.selectedCopyListOption = listOption;
    this.nav.push(CopyListInputComponent, {
      listData: this.listData,
      selectedCopyListOption: listOption,
      newListName: this.newListName,
      callBack: this.callBackInfo.bind(this),
      platformType: this.platformType,
      selectedList: this.selectedList,
      listType: this.listType,
      divisions: this.divisions,
      marketSelectedCount: this.marketSelectedCount,
      marketSelected: this.marketSelected,
      customers: this.customers,
      customersView: this.customersView,
      filteredCustomersView: this.filteredCustomersView,
      customerSelected: this.customerSelected,
      customerSelectedCount: this.customerSelectedCount,
      maximumSelectableCustomerCount: this.countCustomersAndDivisions(
        this.customersView,
      ),
    });
  }

  callBackInfo(
    newListName: string,
    selectedList: ListsViewModel,
    listType: ListTypes,
    divisionInfo: {
      divisions: DivisionSelection[];
      marketSelected: boolean;
      marketSelectedCount: number;
    },
    customerInfo: {
      customers: CustomerSelection[];
      customerSelected: boolean;
      customerSelectedCount: number;
    },
  ) {
    this.newListName = newListName;
    this.selectedList = selectedList;
    this.listType = listType;
    this.divisions = divisionInfo.divisions;
    this.marketSelected = divisionInfo.marketSelected;
    this.marketSelectedCount = divisionInfo.marketSelectedCount;
    this.customersView =
      customerInfo.customers.length > 0
        ? customerInfo.customers
        : this.getSelectedCustByMarket();
    this.customerSelected = customerInfo.customerSelected;
    this.customerSelectedCount = customerInfo.customerSelectedCount;
  }
}
