import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ProductDetailViewModel } from '@product-detail/models/product-detail-view-model';
import { PanAppState, PlatformService } from '@panamax/app-state';
import {
  getAlternativeThumbnail,
  getFirstImageURL,
} from '@product-detail/utils/product-images.util';
import { ProductState } from '@usf/ngrx-product';
import { QuantityUpdate } from '@shared/models/quantity-update';
import { Observable, Subscription } from 'rxjs';
import { OrderItem } from '@usf/ngrx-order';
import { OrderService } from '@order/services/order.service';
import { ListsPageData } from '@app/lists/model/lists-page.model';
import { ListsOverlayService } from '@app/lists/services/lists-overlay.service';
import { ListsService } from '@app/lists/services/lists.service';
import { ListState } from '@usf/ngrx-list';
import { Location } from '@angular/common';
import { ProductCompareEnum } from '@shared/constants/product-compare.enum';
import { customerIsGLCustomer } from '@shared/helpers/customer.helpers';
import { PATHS } from '@shared/constants/paths';
import { List } from '@usf/list-types';
import { TranslateService } from '@ngx-translate/core';
import { ImageVariantEnum } from '@product-detail/models/image-variant.enum';
import { ProductPropertiesEnum } from '@usf/product-types';
import { AddToListModalService } from '@shared/services/dual-pane/add-to-list-modal.service';

@Component({
  selector: 'app-product-detail-card',
  templateUrl: './product-detail-card.component.html',
  styleUrls: ['./product-detail-card.component.scss'],
})
export class ProductDetailCardComponent implements OnInit, AfterViewInit {
  @Output() quantityUpdateEmitter = new EventEmitter<QuantityUpdate>();
  @Output() closeCardEmitter = new EventEmitter<ProductDetailViewModel>();
  @Output() selectOrderSub = new EventEmitter<number>();
  @Output() selectExceptionSub = new EventEmitter<number>();
  @Output() selectBetterBuySub = new EventEmitter<number>();
  @Output() initializedElementEmitter = new EventEmitter<ElementRef>();
  @Output() clickEventEmitter = new EventEmitter<string>();

  @Input() productDetails: ProductDetailViewModel;
  @Input() isDisable = false;
  @Input() cardsNumber: number;
  @Input() isActionable = false;
  @Input() isGuestUser = false;
  @Input() listState: ListState;
  @Input() originalItemNumber: number;
  @Input() selectedProducts: number[];
  @Input() mode: ProductCompareEnum = ProductCompareEnum.default;
  @Input() navigationDisabled = true;
  @Input() recentlyViewedRecToken = '';
  @Input() carouselPosition = null;
  @Input() linkSource = null;
  @Input() merchFeature = '';
  @Input() showPills = false;
  @Input() useQuantityButtons = false;
  @Input() addMethod = '';
  @Input() parentApn = null;
  @Input() variant = null;

  @ViewChild('productCard', { read: ElementRef }) productCardRef: ElementRef;

  orderItem$: Observable<OrderItem>;
  listPageData$: Observable<ListsPageData>;
  listPageDateSub$: Subscription;
  listPageData: ListsPageData;
  isAddToOrderOpen = false;

  readonly propertiesEnum = ProductPropertiesEnum;

  constructor(
    readonly panAppState: PanAppState,
    readonly platformService: PlatformService,
    readonly addToListModalService: AddToListModalService,
    private readonly orderService: OrderService,
    private listsService: ListsService,
    private listsOverlayService: ListsOverlayService,
    private location: Location,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.orderItem$ = this.orderService.getOrderItemWithProductNumber$(
      this.productDetails?.productNumber,
    );

    if (this.productDetails) {
      this.productDetails.imageThumbnail = getFirstImageURL(
        this.productDetails?.summary,
        ImageVariantEnum.Small,
      );
    }

    this.listPageData$ = this.listsService.listsView$();
    this.listPageDateSub$ = this.listPageData$.subscribe(data => {
      this.listPageData = data;
    });
    this.populateCustomerProductNumber();
  }

  ngAfterViewInit(): void {
    this.initializedElementEmitter.emit(this.productCardRef);
  }

  populateCustomerProductNumber(): void {
    if (this.productDetails && this.productDetails.customerProductNumber) {
      let isGLCustomer = customerIsGLCustomer(
        this.productDetails.selectedCustomer,
      );
      this.productDetails.customerProductNumber = isGLCustomer
        ? 'GL: #' + this.productDetails.customerProductNumber
        : 'CP: #' + this.productDetails.customerProductNumber;
    }
  }

  getAternativeThumbnail = (thumbnailImageURL: string): string => {
    return getAlternativeThumbnail(
      this.productDetails?.summary as ProductState,
      thumbnailImageURL,
    );
  };

  changeQuantityHandler(quantityUpdate: QuantityUpdate) {
    this.quantityUpdateEmitter.emit(quantityUpdate);
  }

  closeCardHandler(productDetails: ProductDetailViewModel) {
    this.closeCardEmitter.emit(productDetails);
  }

  async openAddToListModal() {
    this.productDetails.merchFeatureName = this.merchFeature;
    this.productDetails.resultRank = this.carouselPosition;
    this.addToListModalService.openModalViaController(
      [this.productDetails],
      false,
      false,
      this.addMethod,
      this.merchFeature,
    );
  }

  async acceptReplace() {
    const alternativeProduct = this.selectedProducts?.filter(
      value => value !== this.originalItemNumber,
    );
    if (this.listPageData?.lastSelectedList) {
      const path = this.getListPath(this.listPageData?.lastSelectedList);
    }
    this.listsOverlayService.openReplaceProductModal(
      this.originalItemNumber,
      alternativeProduct[0],
      this.listPageData?.lastSelectedList, //check page refresh
      () =>
        !!this.listPageData?.lastSelectedList
          ? this.listsService.goToShoppingList(
              this.getListPath(this.listPageData?.lastSelectedList),
            )
          : this.location.back(),
    );
  }

  public get ProductCompareEnum(): typeof ProductCompareEnum {
    return ProductCompareEnum;
  }

  getListPath(list: List): string[] {
    let shoppingListPath = [PATHS.LISTS];
    const listPathId = list?.listKey?.listTypeId + '-' + list?.listKey?.listId;

    const isRecentlyPurchasedList =
      list.listName ===
      this.translateService.instant('i18n.lists.recentlyPurchased');
    const isMasterList = list?.listKey?.listTypeId === 'ML';

    if (isRecentlyPurchasedList) {
      shoppingListPath.push('recentlyPurchased');
    } else if (isMasterList) {
      shoppingListPath.push('masterList', listPathId);
    } else {
      shoppingListPath.push('detail', listPathId);
    }
    return shoppingListPath;
  }

  selectSubstitute() {
    if (this.mode === ProductCompareEnum.orderSubCompare) {
      this.selectOrderSub.emit(this.productDetails.productNumber);
    } else if (this.mode === ProductCompareEnum.exceptionSubCompare) {
      this.selectExceptionSub.emit(this.productDetails.productNumber);
    }
  }

  acceptSwap() {
    this.selectBetterBuySub.emit(this.originalItemNumber);
  }

  setAddToOrderOpen(isOpen: boolean) {
    this.isAddToOrderOpen = isOpen;
    console.log('Modal Open Set to ' + isOpen);
  }

  onNavigateToPDP() {
    this.clickEventEmitter.emit(this.recentlyViewedRecToken);
  }

  get shouldShowButtonSpacer(): boolean {
    const modeShouldShowButtonSpacer = [
      ProductCompareEnum.replacementCompare,
      ProductCompareEnum.replacementListsCompare,
      ProductCompareEnum.betterBuyCompare,
      ProductCompareEnum.betterBuyListsCompare,
      ProductCompareEnum.orderSubCompare,
      ProductCompareEnum.exceptionSubCompare,
    ].includes(this.mode);

    return (
      modeShouldShowButtonSpacer &&
      this.productDetails?.productNumber === this.originalItemNumber
    );
  }

  get productCardClass(): string {
    if (this.isGuestUser) {
      return 'has-normal-drawer';
    }

    const drawerMsg = this.productDetails?.drawerMsg;
    const warningMsg = this.productDetails?.productCardWarningMsg;

    if (!!drawerMsg?.message || !!warningMsg?.primaryText) {
      return !drawerMsg?.isWarning && !warningMsg?.display
        ? 'has-normal-drawer'
        : 'has-warning-drawer';
    }

    return 'has-normal-drawer';
  }
}
