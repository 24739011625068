<ion-content
  [ngClass]="(platformService?.isTouchExperience$() | async) ? platformService?.platformType === platformService?.platformEnum?.tablet ? 'tablet' : 'mobile' : 'desktop'"
  *ngIf="displayContent"
>
  <ion-grid>
    <!-- Header -->
    <ion-row class="login-usf-logo-row">
      <ion-col size-xl="4" size-lg="4" size-md="9" size-sm="9" size-xs="9">
        <img class="usf-logo" src="assets/images/usfoods-logo-color.svg" />
        <p>{{ 'i18n.login.welcomeGreeting' | translate }}</p>
      </ion-col>
    </ion-row>

    <!-- Microsoft Login Button -->
    <ion-row class="login-btn-row">
      <ion-col size-xl="4" size-lg="4" size-md="6" size-sm="9" size-xs="9">
        <ion-button class="usf-fill-green-button" [disabled]="true">
          <strong>Loading ...</strong></ion-button
        >
      </ion-col>
    </ion-row>

    <!-- Footer -->
    <ion-row
      *ngIf="(platformService?.isTouchExperience$() | async) === false"
      class="login-footer-row"
    >
      <ion-col>
        <div class="login-footer-text">
          <p>
            <a
              href="https://www.usfoods.com/content/dam/usf/EC/documents/termsofaccess.html"
              target="_blank"
            >
              {{ 'i18n.login.termsOfAccess' | translate }}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              href="https://www.usfoods.com/content/dam/usf/EC/documents/privacypolicy.html"
              target="_blank"
            >
              {{ 'i18n.login.privacyPolicy' | translate }}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              href="https://www.usfoods.com/content/dam/usf/EC/documents/customerpolicy.html"
              target="_blank"
            >
              {{ 'i18n.login.customerPolicy' | translate }}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              href="https://www.usfoods.com/content/dam/usf/EC/documents/aboutusfoods.html"
              target="_blank"
            >
              {{ 'i18n.login.aboutThisSite' | translate }}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              href="https://www.usfoods.com/content/dam/usf/EC/documents/contactusfoods.html"
              target="_blank"
            >
              {{ 'i18n.login.contactUs' | translate }}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="https://www.usfoods.com/" target="_blank">
              {{ 'i18n.login.usFoodsUrl' | translate}}
            </a>
          </p>
          <p>
            &copy; {{ currentYear }} US Foods. {{
            'i18n.footer.allRightsReserved' | translate }}
          </p>
        </div>
        <div class="login-footer-social-links">
          <a href="https://www.facebook.com/usfoods" target="_blank">
            <ion-icon name="logo-facebook"></ion-icon>
          </a>
          <a href="https://twitter.com/usfoods" target="_blank">
            <ion-icon name="logo-twitter"></ion-icon>
          </a>
          <a href="https://www.youtube.com/user/usfoods" target="_blank">
            <ion-icon name="logo-youtube"></ion-icon>
          </a>
        </div>
      </ion-col>
    </ion-row>
    <ion-row><ion-col>&nbsp;</ion-col></ion-row>
    <ion-row></ion-row>
    <ion-row class="login-msgs-row">
      <ion-col size-xl="4" size-lg="4" size-md="9" size-sm="9" size-xs="9">
        <p>
          If you are having issues logging in or need to reset your password,
          please contact 1-877-583-9659.
        </p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
