export enum OGRestrictionsEnum {
  RESTRICT_TO_OG = 'Y',
  RESTRICT_TO_ML = 'M',
  UNRESTRICTED = 'N',
}

export enum SubRestrictionsEnum {
  ORDER_GUIDE = 'O',
  MASTER_LIST = 'M',
  UNRESTRICTED = 'Y',
  NO_SUBS = 'N',
}

// enum for the combination of (restricted to OG, sub restrictions) flags
export enum ListFlagsEnum {
  CASE_M_Y = 0,
  CASE_M_N = 1,
  CASE_M_M = 2,
  CASE_M_O = 3,
  CASE_Y_Y = 4,
  CASE_Y_N = 5,
  CASE_Y_M = 6,
  CASE_Y_O = 7,
  CASE_N_Y = 8,
  CASE_N_N = 9,
  CASE_N_M = 10,
  CASE_N_O = 11,
}
