<section class="content">
  <div
    [ngClass]="ratingStarsCSSEnum"
    [ngStyle]="{
      'background-image':
        'url(\'../../../../assets/images/' +
        ratingAverageRounded +
        '-star-rating.svg\''
    }"
    class="rating-starts"
  ></div>

  <div [ngClass]="ratingAverageCSSEnum">
    {{ powerReviewRollupModel?.average_rating }}
  </div>
</section>
