import { createAction, props } from '@ngrx/store';
import { Customer } from '@usf/customer-types/customer';

export const scheduleAuthTokenRefresh = createAction(
  '[RefreshAuthToken] Start Auto Refresh Token Timer',
);

export const refreshAuthToken = createAction(
  '[RefreshAuthToken] Refresh Auth Token',
  props<{ customer: Customer }>(),
);

export const refreshAuthTokenSuccess = createAction(
  '[RefreshAuthToken Effect] RefreshAuthToken Success',
  props<{ payload: any }>(),
);

export const refreshAuthTokenFailed = createAction(
  '[RefreshAuthToken Effect] RefreshAuthToken Failed',
  props<{ error: any }>(),
);
