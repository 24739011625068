import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CART_TRACING_ACTIONS } from './cart-tracing.actions';
import { OrderHeader, OrderItem, OrderStoreService } from '@usf/ngrx-order';
import { Tracking } from '@panamax/app-state';
import { CartTracingMessages } from './cart-tracing.constants';
import { genericTracingTransformer } from '@shared/tracking/tracing/generic-tracing-transformer';
import { SharedTrackingService } from '@shared/services/analytics/shared-tracking.service';
import { Product } from '@shared/models/product.model';
import { CartSyncTracingService } from './cart-sync/cart-sync-tracing.service';

@Injectable({
  providedIn: 'root',
})
export class CartTracingService {
  constructor(private store: Store) {}
  // Create and end a trace for quantities changes in the cart products
  traceCartProductChangeQuantity(
    orderHeader: OrderHeader,
    orderItems: OrderItem[],
    updateOrderItem: OrderItem,
    productMap: Map<number, Product>,
  ) {
    // Integrate updateOrderItem into orderItems
    const order = OrderStoreService.applyUpdateOrderItem(
      orderHeader,
      orderItems,
      updateOrderItem,
    );
    // Get the products for tracing
    const tracingProducts = SharedTrackingService.tracingProductData(
      order.orderItems,
      productMap,
    );

    const tracking: Tracking = {
      tracing: {
        data: {
          isStartOfTrace: true,
          isEndOfTrace: true,
          traceContext: CartTracingMessages.CAPABILITY,
          attributes: {
            event: CartTracingMessages.CHANGE_QUANTITY,
            order: {
              orderId: orderHeader?.orderId,
              totalAmount: orderHeader?.totalPrice,
              origOrderId: orderHeader?.origOrderId,
              origSplitOrderId: orderHeader?.origSplitOrderId,
              tandemOrderNumber: orderHeader?.tandemOrderNumber,
              requestedDeliveryDate: orderHeader?.requestedDeliveryDate,
              confirmedDeliveryDate: orderHeader?.confirmedDeliveryDate,
            },
            products: tracingProducts,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      CART_TRACING_ACTIONS.traceCartProductChangeQuantity({
        tracking: CartSyncTracingService.deepRemoveUndefined(tracking),
      }),
    );
  }

  traceChangePO(orderHeader: OrderHeader) {
    const tracking: Tracking = {
      tracing: {
        data: {
          isStartOfTrace: true,
          isEndOfTrace: true,
          traceContext: CartTracingMessages.CAPABILITY,
          attributes: {
            event: CartTracingMessages.CHANGE_PO,
            order: {
              orderId: orderHeader?.orderId,
              totalAmount: orderHeader?.totalPrice,
              origOrderId: orderHeader?.origOrderId,
              origSplitOrderId: orderHeader?.origSplitOrderId,
              tandemOrderNumber: orderHeader?.tandemOrderNumber,
              requestedDeliveryDate: orderHeader?.requestedDeliveryDate,
              confirmedDeliveryDate: orderHeader?.confirmedDeliveryDate,
            },
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      CART_TRACING_ACTIONS.traceChangePO({
        tracking: CartSyncTracingService.deepRemoveUndefined(tracking),
      }),
    );
  }

  traceChangeDeliveryDate(orderHeader: OrderHeader) {
    const tracking: Tracking = {
      tracing: {
        data: {
          isStartOfTrace: true,
          isEndOfTrace: true,
          traceContext: CartTracingMessages.CAPABILITY,
          attributes: {
            event: CartTracingMessages.CHANGE_DELIVERY_DATE,
            order: {
              orderId: orderHeader?.orderId,
              totalAmount: orderHeader?.totalPrice,
              origOrderId: orderHeader?.origOrderId,
              origSplitOrderId: orderHeader?.origSplitOrderId,
              tandemOrderNumber: orderHeader?.tandemOrderNumber,
              requestedDeliveryDate: orderHeader?.requestedDeliveryDate,
              confirmedDeliveryDate: orderHeader?.confirmedDeliveryDate,
            },
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      CART_TRACING_ACTIONS.traceChangeDeliveryDate({
        tracking: CartSyncTracingService.deepRemoveUndefined(tracking),
      }),
    );
  }
}
