/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { Platform } from '@ionic/angular';
@Directive({
  selector: '[maxlengthAndroid]',
})
export class MaxLengthAndroidDirective implements OnInit {
  @Input() max: number;
  private isAndroid = false;
  constructor(private el: ElementRef, private platform: Platform) {}

  ngOnInit(): void {
    this.isAndroid = this.platform.is('android');
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    if (!this.isAndroid) {
      let current: string = event.target.value;
      if (current && current.length > this.max) {
        this.el.nativeElement.value = current.substring(0, this.max);
      }
    }
  }
}
