import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { SneakPeekActions } from '@usf/ngrx-product';
import { ScoopService } from '../services/scoop.service';

@Injectable({
  providedIn: 'root',
})
export class ClientScoopEffects {
  loadSneakPeeks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SneakPeekActions.FetchSneakPeekResultSuccess),
        tap(action => {
          const productNumbers = action?.response?.map(
            sneakPeek => sneakPeek?.apn,
          );

          if (productNumbers?.length > 0) {
            this.scoopService.loadProducts(productNumbers);
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private scoopService: ScoopService) {}
}
