import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PanAppState, PlatformService } from '@panamax/app-state';
import { ProductDetailViewModel } from '@product-detail/models/product-detail-view-model';
import { UsfProductCardModeEnum } from '@shared/constants/usf-product-card-mode.enum';
import { getAlternativeThumbnail } from '@product-detail/utils/product-images.util';
import { ProductState } from '@usf/ngrx-product';
import { TranslateService } from '@ngx-translate/core';
import { OrderItem } from '@usf/ngrx-order';
import { QuantityUpdate } from '@shared/models/quantity-update';

@Component({
  selector: 'app-product-detail-add-to-order-modal',
  templateUrl: './product-detail-add-to-order-modal.component.html',
  styleUrls: ['./product-detail-add-to-order-modal.component.scss'],
})
export class ProductDetailAddToOrderModalComponent implements OnInit {
  @Input() productDetails: ProductDetailViewModel;
  @Input() orderItem: OrderItem;
  @Output() quantityUpdateEmitter = new EventEmitter<QuantityUpdate>();

  readonly productCardModes = UsfProductCardModeEnum;
  pricePerPortion = '';
  isProductCardNumSectionExpanded = false;
  numToShow: string;
  numToShowTrimmed: string;
  numToHide: string;
  numToHideTrimmed: string;

  constructor(
    readonly platformService: PlatformService,
    readonly panAppState: PanAppState,
    readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.addToOrderProductDetailsDropDown();
  }

  getAternativeThumbnail = (thumbnailImageURL: string): string => {
    return getAlternativeThumbnail(
      this.productDetails?.summary as ProductState,
      thumbnailImageURL,
    );
  };

  changeQuantityHandler(quantityUpdate: QuantityUpdate) {
    this.quantityUpdateEmitter.emit(quantityUpdate);
  }

  setProductCardNumSectionExpanded() {
    this.isProductCardNumSectionExpanded = !this
      .isProductCardNumSectionExpanded;
  }

  setSelectedNumberToShow(event: any) {
    if (event?.target?.innerText) {
      this.numToShow = event.target.innerText;
      this.numToShowTrimmed = this.numToShow.replace(/\s/g, '').toLowerCase();
      this.numToHide =
        this.numToShow === this.productDetails.customerProductNumber
          ? 'Prod #' + this.productDetails.productNumber.toString()
          : this.productDetails.customerProductNumber;
    }
    this.numToHideTrimmed = this.numToHide.replace(/\s/g, '').toLowerCase();
  }

  addToOrderProductDetailsDropDown() {
    if (this.productDetails?.customerProductNumber) {
      if (this.productDetails.isGLCustomer) {
        this.numToShow =
          'Prod #' + this.productDetails.productNumber.toString();
        this.numToHide = this.productDetails.customerProductNumber;
      } else {
        this.numToShow = this.productDetails.customerProductNumber;
        this.numToHide =
          'Prod #' + this.productDetails.productNumber.toString();
      }
      this.numToShowTrimmed = this.numToShow.replace(/\s/g, '').toLowerCase();
      this.numToHideTrimmed = this.numToHide.replace(/\s/g, '').toLowerCase();
    }
  }
}
