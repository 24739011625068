import { Component } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { ListsService } from 'src/app/lists/services/lists.service';
import { PlatformService } from '@panamax/app-state';
import { CreateListBaseComponent } from '../create-list-base.component';
import { CreateListOptions } from '../../../../shared/constants/lists-constants';

@Component({
  selector: 'app-create-list-desktop',
  templateUrl: './create-list-desktop.component.html',
  styleUrls: ['./create-list-desktop.component.scss'],
})
export class CreateListDesktopComponent extends CreateListBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    listsService: ListsService,
  ) {
    super(nav, modalController, platformService, listsService);
  }

  onSelectCreateListOption(listOption: CreateListOptions) {
    this.selectedCreateListOption = listOption;
  }
}
