<ion-header class="ion-no-border ion-header-modal-mobile">
  <ion-toolbar>
    <ion-label data-cy="add-to-inventory-label">
      {{ 'i18n.inventory.addToInventoryTitle' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-content-modal-mobile">
  <div
    class="add-to-inventory-options-header"
    data-cy="add-to-inventory-info-label"
  >
    <ion-label>
      {{ 'i18n.inventory.addInstructions' | translate }}
    </ion-label>
  </div>
  <ion-item-divider></ion-item-divider>
  <!-- Choose List -->
  <ion-item
    [class.selected]="
      selectedAddToinventoryOption === addToInventory.chooseInventory
    "
    detail
    (click)="changeSelectedAddToinventoryOption(addToInventory.chooseInventory)"
    data-cy="click-to-choose-inventory-item"
  >
    <ion-label data-cy="choose-inventory-label">
      {{ 'i18n.search.chooseInventory' | translate }}
      <span data-cy="last-inventtory-name-text">
        {{ selectedInventory?.inventoryName }}&nbsp;{{
          selectedInventory?.inventoryDate | date: 'M/d/yyyy'
        }}
      </span>
    </ion-label>
  </ion-item>
  <!-- Choose Group -->
  <ion-item
    [class.selected]="
      selectedAddToinventoryOption === addToInventory.chooseGroup
    "
    detail
    (click)="changeSelectedAddToinventoryOption(addToInventory.chooseGroup)"
    data-cy="click-to-choose-group-item"
  >
    <ion-label data-cy="choose-group-label">
      {{ 'i18n.lists.chooseGroup' | translate }}
      <span data-cy="group-name-text">
        {{ group?.groupName ? group?.groupName : '' }}
      </span>
    </ion-label>
  </ion-item>
  <!-- Choose Position -->
  <ion-item
    [class.selected]="
      selectedAddToinventoryOption === addToInventory.choosePosition
    "
    detail
    (click)="changeSelectedAddToinventoryOption(addToInventory.choosePosition)"
    data-cy="click-to-choose-position-item"
  >
    <ion-label data-cy="choose-position-label">
      {{ 'i18n.lists.choosePosition' | translate }}
      <span data-cy="position-text">
        {{
          (groupPosition === addToInventory.top
            ? 'i18n.inventory.moveToTop'
            : 'i18n.inventory.moveToBottom'
          ) | translate
        }}
      </span>
    </ion-label>
  </ion-item>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="add-to-inventory-cancel-button"
        class="usf-outline-green-mobile-modal-button"
        (click)="dismissModal()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        id="submit-modal-btn"
        data-cy="submit-add-to-inventory-button"
        [ngClass]="
          !isValid()
            ? 'usf-fill-disabled-mobile-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="onSubmit()"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
