import { AbstractControl, ValidatorFn } from '@angular/forms';

export function mimeTypeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const file: File = control.value;
    if (file && !isTextBasedFile(file)) {
      return { mimeTypeError: true };
    }
    return null;
  };
}

function isTextBasedFile(file: File): boolean {
  // Define the allowed text-based MIME types
  const allowedMimeTypes = [
    'text/plain',
    'text/csv',
    'text/xml',
    'application/xml',
    '',
  ];

  // Check if the file's MIME type is in the allowed list
  return allowedMimeTypes.includes(file.type);
}
