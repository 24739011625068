<div class="customer-selection" *ngIf="vm$ | async; let vm">
  <ion-item *ngIf="showSearchbar" lines="full">
    <ion-searchbar
      id="customer-selection-searchbar"
      data-cy="customer-selection-searchbar"
      class="customer-search"
      mode="ios"
      placeholder="{{ 'i18n.header.search' | translate }}"
      (ionInput)="filterCustomers()"
      [(ngModel)]="searchText"
    >
    </ion-searchbar>
  </ion-item>
  <ng-container *ngIf="!vm.customerSelectionOptions.length">
    <div class="no-customers-found" data-cy="no-customers-found-text">
      <p>
        {{ 'i18n.header.noCustomersFound' | translate }}
      </p>
    </div>
  </ng-container>

  <cdk-virtual-scroll-viewport
    class="customer-list full-size-dropdown"
    orientation="vertical"
    appCustomVs
    [itemHeights]="vm.rowHeights"
    [ngClass]="[
      vm.customerSelectionOptions.length > 5 ? 'full-size-dropdown' : '',
      vm.customerSelectionOptions.length === 4 ? 'large-dropdown' : '',
      vm.customerSelectionOptions.length === 3 ? 'medium-dropdown' : '',
      vm.customerSelectionOptions.length === 2 ? 'small-dropdown' : '',
      vm.customerSelectionOptions.length === 1 ? 'single-dropdown' : ''
    ]"
  >
    <ion-item
      *cdkVirtualFor="
        let customerSelectionOption of vm.customerSelectionOptions;
        let i = index
      "
      lines="none"
      (click)="switchTo(customerSelectionOption)"
      [class.selected]="
        !!selectedDepartmentNumber
          ? customerSelectionOption?.departmentNumber ===
            selectedDepartmentNumber
          : customerSelectionOption?.isSelected
      "
      [class.not-selectable]="customerSelectionOption.hasDepartments"
      [attr.data-cy]="'customer-' + i + '-list-item'"
    >
      <ion-icon
        class="customer-dept-icon"
        src="assets/icon/customer-locations-lightgreen.svg"
        slot="start"
        *ngIf="customerSelectionOption.isDepartment"
        [attr.data-cy]="'customer-' + i + '-department-indicator-icon'"
      ></ion-icon>
      <ion-label
        [class.department-label]="customerSelectionOption.hasDepartments"
        [attr.data-cy]="'customer-' + i + '-department-label'"
      >
        <span [class.is-department]="customerSelectionOption.isDepartment">{{
          customerSelectionOption.title | titlecase
        }}</span>
        <br />
        <span
          class="customer-location"
          [attr.data-cy]="'customer-' + i + '-location-subtitle'"
        >
          {{ customerSelectionOption.subtitle }}</span
        >
      </ion-label>
      <ion-icon
        *ngIf="
          !!selectedDepartmentNumber
            ? customerSelectionOption?.departmentNumber ===
              selectedDepartmentNumber
            : customerSelectionOption?.isSelected
        "
        name="checkmark-circle-outline"
        slot="end"
        [attr.data-cy]="'customer-' + i + '-selected-indicator-icon'"
      ></ion-icon>
    </ion-item>
  </cdk-virtual-scroll-viewport>
</div>
