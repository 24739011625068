import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-selectable-product',
  templateUrl: './selectable-product.component.html',
  styleUrls: ['./selectable-product.component.scss'],
})
export class SelectableProductComponent {
  @Input() product: SelectableProduct;

  @Output()
  selectProductEmitter: EventEmitter<number> = new EventEmitter();
  constructor() {}

  onSelectProduct() {
    this.selectProductEmitter.emit(this.product.productNumber);
  }
}

export interface SelectableProduct {
  thumbnail: string;
  productNumber: number;
  productPackSize: string;
  productDescription: string;
}
