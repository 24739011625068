import { Tracking } from '@panamax/app-state';
import { OrderTracingMessages } from '../order-tracing.constants';

export const shipToConversionTrace: Tracking = {
  tracing: {
    data: {
      traceContext: OrderTracingMessages.submitShipToOrderApiResponse,
      isEndOfTrace: undefined,
      isStartOfTrace: undefined,
      attributes: {
        order: {
          tandemOrderNumber: undefined,
          orderId: undefined,
          origOrderId: undefined,
          origSplitOrderId: undefined,
          errorDetails: undefined,
        },
        event: OrderTracingMessages.submitShipToOrder,
      },
    },
  },
};

export const shipToConversionWebsocketTrace: Tracking = {
  tracing: {
    data: {
      traceContext: OrderTracingMessages.shipToContext,
      isEndOfTrace: undefined,
      isStartOfTrace: undefined,
      attributes: {
        order: {
          tandemOrderNumber: undefined,
          orderId: undefined,
          origOrderId: undefined,
          origSplitOrderId: undefined,
          errorDetails: undefined,
        },
        event: OrderTracingMessages.submitShipToOrderEvent,
      },
    },
  },
};
