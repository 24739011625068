import { ListRange } from '@angular/cdk/collections';
import {
  CdkVirtualScrollViewport,
  VIRTUAL_SCROLL_STRATEGY,
  VirtualScrollStrategy,
} from '@angular/cdk/scrolling';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { PlatformService } from '@panamax/app-state';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ListRow } from '../../../lists/shared/list-detail-management/model/list-detail-management-view.model';
import { HotKeys } from '../../constants/hot-key.enum';

export type ItemHeight = number[];
export type Range = [number, number];
export const intersects = (a: Range, b: Range): boolean =>
  (a[0] <= b[0] && b[0] <= a[1]) ||
  (a[0] <= b[1] && b[1] <= a[1]) ||
  (b[0] < a[0] && a[1] < b[1]);
export const clamp = (min: number, value: number, max: number): number =>
  Math.min(Math.max(min, value), max);
export const isEqual = <T>(a: T, b: T) => a === b;
export const last = <T>(value: T[]): T => value[value.length - 1];
export class CustomVirtualScrollStrategy implements VirtualScrollStrategy {
  constructor(
    private itemHeights: ItemHeight,
    private indexOffset: number,
    private bufferBefore: number,
    private bufferAfter: number,
    private items: any[],
    private platformService: PlatformService,
    private listId: number | string,
    private measureViewportSize: boolean,
  ) {}
  private viewport?: CdkVirtualScrollViewport;
  private scrolledIndexChange$ = new Subject<number>();
  public scrolledIndexChange: Observable<number> = this.scrolledIndexChange$.pipe(
    distinctUntilChanged(),
  );
  attach(viewport: CdkVirtualScrollViewport) {
    this.viewport = viewport;
    this.updateTotalContentSize();
    this.updateRenderedRange();
  }
  detach() {
    this.scrolledIndexChange$.complete();
    delete this.viewport;
  }
  public updateItemHeights(
    itemHeights: ItemHeight,
    indexOffset: number,
    bufferBefore: number,
    bufferAfter: number,
    items: any[],
    listId: number | string,
    measureViewportSize: boolean,
  ) {
    this.itemHeights = itemHeights;
    this.indexOffset = indexOffset;
    this.bufferBefore = bufferBefore;
    this.bufferAfter = bufferAfter;
    this.items = items;
    this.listId = listId;
    this.measureViewportSize = measureViewportSize;
    this.updateTotalContentSize();
    this.updateRenderedRange();
  }
  private getItemOffset(index: number): number {
    return this.itemHeights
      ?.slice(this.indexOffset, index + this.indexOffset)
      .reduce((acc, itemHeight) => acc + itemHeight, 0);
  }
  private getTotalContentSize(): number {
    return this.itemHeights?.reduce((a, b) => a + b, 0);
  }
  private getListRangeAt(itemsInRange: number[]): ListRange {
    let start = clamp(
      0,
      (itemsInRange ? itemsInRange[0] : 0 ?? 0) - this.bufferBefore,
      this.itemHeights?.length - 1,
    );
    let end = clamp(
      0,
      (itemsInRange ? last(itemsInRange) : 0 ?? 0) + this.bufferAfter,
      this.itemHeights?.length,
    );
    return {
      start,
      end,
    };
  }
  calculateItemsInRange(scrollOffset: number, viewportSize: number): number[] {
    type Acc = { itemIndexesInRange: number[]; currentOffset: number };
    const visibleOffsetRange: Range = [
      scrollOffset,
      scrollOffset + viewportSize,
    ];
    return this.itemHeights?.reduce<Acc>(
      (acc, itemHeight, index) => {
        const itemOffsetRange: Range = [
          acc.currentOffset,
          acc.currentOffset + itemHeight,
        ];
        return {
          currentOffset: acc.currentOffset + itemHeight,
          itemIndexesInRange: intersects(itemOffsetRange, visibleOffsetRange)
            ? [...acc.itemIndexesInRange, index]
            : acc.itemIndexesInRange,
        };
      },
      { itemIndexesInRange: [], currentOffset: 0 },
    ).itemIndexesInRange;
  }
  setQuantitiesOfProductsInRange(productsInRange: number[]) {
    const activeIonicInput = this.getIonicInputFromActiveElement();
    productsInRange?.forEach(index => {
      const trueIndex = index - this.indexOffset;
      const item = this.items[trueIndex];
      if (item) this.setQtyInput(item, trueIndex, activeIonicInput);
      if (item && item.alternative)
        this.setQtyInput(
          item.alternative?.product,
          trueIndex,
          activeIonicInput,
        );
      if (item && item?.betterBuy)
        this.setQtyInput(item.betterBuy.product, trueIndex, activeIonicInput);
    });
  }
  setQtyInput(item: any, index: number, activeIonicInput: HTMLElement) {
    if (item?.hotkeyIds) {
      item.hotkeyIds.forEach(hotkeyId => {
        this.listId = this.listId ? this.listId : undefined;
        const elementId = hotkeyId + this.listId + '-' + index;
        const ionicInputElement = document.getElementById(elementId);
        const isCases = elementId.includes('cs');
        const isEaches = elementId.includes('ea');
        const inputExistsForCasesAndCasesIsUndefined =
          ionicInputElement && isCases && !item?.casesOrdered?.currentValue;
        if (
          inputExistsForCasesAndCasesIsUndefined &&
          ionicInputElement !== activeIonicInput
        ) {
          ionicInputElement.setAttribute('value', '');
        }
        const inputExistsForEachesAndEachesIsUndefined =
          ionicInputElement && isEaches && !item?.eachesOrdered?.currentValue;
        if (
          inputExistsForEachesAndEachesIsUndefined &&
          ionicInputElement !== activeIonicInput
        ) {
          ionicInputElement.setAttribute('value', '');
        }
      });
    }
  }

  private getIonicInputFromActiveElement() {
    let element: HTMLElement;
    // document.activeElement returns native <input>
    const activeInput = document.activeElement as HTMLElement;
    let parent = activeInput?.offsetParent as HTMLElement;
    for (let i = 0; i < 3; i++) {
      // Ionic input has globalKeyHandlerAttribute
      if (parent?.hasAttribute(HotKeys.globalKeyHandler)) {
        element = parent;
        break;
      } else {
        parent = parent?.offsetParent as HTMLElement;
      }
    }
    return element;
  }

  private updateRenderedRange() {
    if (!this.viewport) return;
    const viewportSize = this.measureViewportSize
      ? this.viewport.measureViewportSize('vertical')
      : this.viewport.getViewportSize();
    let scrollOffset = this.viewport.measureScrollOffset();
    const itemsInRange = this.calculateItemsInRange(scrollOffset, viewportSize);
    const newRange = this.getListRangeAt(itemsInRange);
    const oldRange = this.viewport?.getRenderedRange();
    if (newRange.start === oldRange.start && oldRange.end === newRange.end)
      return;

    this.blurActiveInput();
    this.viewport.setRenderedRange(newRange);
    let offset = this.getItemOffset(newRange.start);
    this.viewport.setRenderedContentOffset(offset);
    this.scrolledIndexChange$.next(newRange.start);
    this.setQuantitiesOfProductsInRange(itemsInRange);
  }

  private blurActiveInput() {
    const virtualScroll = this.viewport?.elementRef?.nativeElement;
    if (virtualScroll) {
      const myFocusedInput = virtualScroll.querySelector(
        ':focus',
      ) as HTMLElement;
      if (this.elementIsTextInput(myFocusedInput)) {
        myFocusedInput.dispatchEvent(new Event('blur'));
      }
    }
  }

  private elementIsTextInput(element: HTMLElement) {
    const inputType = element?.getAttribute('type');
    return element instanceof HTMLInputElement && inputType === 'text';
  }

  private updateTotalContentSize() {
    const contentSize = this.getTotalContentSize();
    this.viewport?.setTotalContentSize(contentSize);
  }
  onContentScrolled() {
    this.updateRenderedRange();
  }
  onDataLengthChanged() {
    this.updateTotalContentSize();
    this.updateRenderedRange();
  }
  onContentRendered() {}
  onRenderedOffsetChanged() {}
  scrollToIndex(index: number, behavior: ScrollBehavior) {
    this.viewport?.scrollToOffset(this.getItemOffset(index), behavior);
  }
}

function factory(dir: CustomSizeVirtualScrollDirective) {
  return dir.scrollStrategy;
}

@Directive({
  selector: 'cdk-virtual-scroll-viewport[appCustomVs]',
  providers: [
    {
      provide: VIRTUAL_SCROLL_STRATEGY,
      useFactory: factory,
      deps: [forwardRef(() => CustomSizeVirtualScrollDirective)],
    },
  ],
})
export class CustomSizeVirtualScrollDirective implements OnChanges {
  constructor(
    private elRef: ElementRef,
    private cd: ChangeDetectorRef,
    private platformService: PlatformService,
  ) {}
  @Input() itemHeights: ItemHeight = [];
  @Input() indexOffset = 0;
  @Input() bufferBefore? = 10;
  @Input() bufferAfter? = 10;
  @Input() items: ListRow[] = [];
  @Input() listId: number | string = '';
  @Input() measureViewportSize = true;
  scrollStrategy: CustomVirtualScrollStrategy = new CustomVirtualScrollStrategy(
    this.itemHeights,
    this.indexOffset,
    this.bufferBefore,
    this.bufferAfter,
    this.items,
    this.platformService,
    this.listId,
    this.measureViewportSize,
  );
  ngOnChanges(changes: SimpleChanges) {
    if (
      'itemHeights' in changes ||
      'indexOffset' in changes ||
      'bufferBefore' in changes ||
      'bufferAfter' in changes ||
      'listId' in changes ||
      'measureViewportSize' in changes
    ) {
      this.scrollStrategy.updateItemHeights(
        this.itemHeights,
        this.indexOffset,
        this.bufferBefore,
        this.bufferAfter,
        this.items,
        this.listId,
        this.measureViewportSize,
      );
      this.cd.detectChanges();
    }
  }
}
