export enum ToastMessages {
  securityAndPrivacy = 'You have successfully cleared your cache and history.',
  replaceProductSuccess = 'Discontinued product replaced!',
  opportunityCreatedSuccessfully = 'Your opportunity was successfully created.',
  convertToWillCallWebSocketSuccess = 'Your order has been successfully converted to will call.',
  addOrUpdateOrderFail = 'We’re sorry, your product could not be updated. Please try again.',
  customerSwitchForbidden = 'This page is not currently available.',
  customerSwitchError = 'i18n.header.customerSwitchError',
  guestSwitchError = 'Please enter a different zip code',
}
