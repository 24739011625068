import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import {
  MASTER_LIST_ITEM_ACTIONS,
  MasterListItemProductState,
} from '@usf/ngrx-list';
import { MslProductActions, MslProduct, LoadingState } from '@usf/ngrx-product';
import { ProductInventoryState } from '@usf/ngrx-product/lib/models/product-inventory-state.model';
import { MslSecondary } from '@usf/product-types/Product-Summary';
import { State } from 'src/app/store';
import { MslItemWithInventory } from '../model/msl-item-with-product.model';
import { PrimaryWithSecondaryProducts } from '../model/primary-with-secondary-products.model';
import {
  createMasterListItemKey,
  createMasterListItemProductKey,
} from '../selectors/helpers/master-list.selectors.helpers';

@Injectable({
  providedIn: 'root',
})
export class MasterListItemService {
  // Key: mslItemKey, Value: array of mslItemProducts that belong to this mslItem
  private mslItemKeyMapWithListOfProducts: Map<string, MslItemWithInventory[]> =
    new Map();

  // Key: mslItemKey, Value: Object that includes Primary Product and list of secondaries
  private mslItemKeyMapWithPrimaryAndSecondaryProducts: Map<
    string,
    PrimaryWithSecondaryProducts
  > = new Map();

  // Key: product number, Value: product summary
  // Map of Msl Products that we will update in state
  private mslProductsToUpdateMap: Map<number, MslProduct> = new Map();

  // Key: mslItemKey, Value: array of mslItemProductKeys in order of primary, secondary1, secondary2...
  // Dictionary and not map because needs to be serializable to save to state
  // Need for faster lookup when building master list page view model
  private itemProductDictionary: Dictionary<string[]> = {};

  constructor(private store: Store<State>) {}

  calculateMslProducts(
    masterListItems: MasterListItemProductState[],
    inventories: Dictionary<ProductInventoryState>,
    isCustomerMslRestricted: boolean,
  ) {
    this.generateMapOfMslItemKeyWithTheirListOfProducts(
      inventories,
      masterListItems,
    );
    this.generateMapOfMslItemKeyWithPrimaryAndSecondaryProducts();
    this.mslItemKeyMapWithPrimaryAndSecondaryProducts.forEach(
      primaryWithSecondaryProducts => {
        this.createMapOfMslProducts(
          primaryWithSecondaryProducts,
          isCustomerMslRestricted,
        );
      },
    );
    this.saveNewMslProduts();
    this.createItemProductDictionary();
    this.saveItemProductDictionaryToState();
    this.resetServiceVariables();
  }

  private generateMapOfMslItemKeyWithTheirListOfProducts(
    inventories: Dictionary<ProductInventoryState>,
    masterListItemProducts: MasterListItemProductState[],
  ) {
    masterListItemProducts.forEach(masterListItem => {
      this.appendProductToMslItemMap(masterListItem, inventories);
    });
  }

  private appendProductToMslItemMap(
    masterListItem: MasterListItemProductState,
    inventories: Dictionary<ProductInventoryState>,
  ) {
    const listItemKey = createMasterListItemKey(
      masterListItem.listId,
      masterListItem.listGroupId,
      masterListItem.listItemId,
    );
    const currentValueInMap =
      this.mslItemKeyMapWithListOfProducts.get(listItemKey);
    const inventory = inventories[masterListItem.productNumber];
    if (inventory?.productNumber) {
      const mslItemAndProduct: MslItemWithInventory = {
        inventory,
        masterListItem,
      };
      if (currentValueInMap) {
        currentValueInMap.push(mslItemAndProduct);
      } else {
        this.mslItemKeyMapWithListOfProducts.set(listItemKey, [
          mslItemAndProduct,
        ]);
      }
    }
  }

  private generateMapOfMslItemKeyWithPrimaryAndSecondaryProducts() {
    this.mslItemKeyMapWithListOfProducts.forEach(
      (mslItemAndProductArray: MslItemWithInventory[], key: string) => {
        this.setPrimaryAndSecondaryProductsInMap(mslItemAndProductArray, key);
      },
    );
  }

  private setPrimaryAndSecondaryProductsInMap(
    mslItemAndProductArray: MslItemWithInventory[],
    key: string,
  ) {
    let currentPrimaryProduct: MslItemWithInventory = undefined;
    let secondaryProducts: MslItemWithInventory[] = [];
    for (const mslItemAndProduct of mslItemAndProductArray) {
      if (!currentPrimaryProduct) {
        currentPrimaryProduct = mslItemAndProduct;
        continue;
      }
      if (
        this.productOneHasHigherPriority(
          mslItemAndProduct,
          currentPrimaryProduct,
        )
      ) {
        // Discontinued product and not found product cant be a secondary product
        if (
          !this.productIsPriorityFour(currentPrimaryProduct.inventory) &&
          currentPrimaryProduct.inventory.loadingState !== LoadingState.notFound
        ) {
          secondaryProducts.push(currentPrimaryProduct);
        }
        currentPrimaryProduct = mslItemAndProduct;
      } else {
        if (
          !this.productIsPriorityFour(mslItemAndProduct.inventory) &&
          mslItemAndProduct.inventory.loadingState !== LoadingState.notFound
        ) {
          secondaryProducts.push(mslItemAndProduct);
        }
      }
    }

    this.sortSecondaryProducts(secondaryProducts);

    // Discontinued product and not found product cant be a primary product
    if (
      !this.productIsPriorityFour(currentPrimaryProduct.inventory) &&
      currentPrimaryProduct.inventory.loadingState !== LoadingState.notFound
    ) {
      this.mslItemKeyMapWithPrimaryAndSecondaryProducts.set(key, {
        primaryProduct: currentPrimaryProduct,
        secondaryProducts,
      });
    }
  }

  private sortSecondaryProducts(secondaryProducts: MslItemWithInventory[]) {
    secondaryProducts.sort((a, b) =>
      this.productOneHasHigherPriority(a, b) ? -1 : 1,
    );
  }

  private productOneHasHigherPriority(
    mslItemAndProductOne: MslItemWithInventory,
    mslItemAndProductTwo: MslItemWithInventory,
  ): boolean {
    const productOnePriority = this.findProductPriority(
      mslItemAndProductOne.inventory,
    );
    const productTwoPriority = this.findProductPriority(
      mslItemAndProductTwo.inventory,
    );
    const userDefinedPriority =
      mslItemAndProductOne.masterListItem.productPriority <
      mslItemAndProductTwo.masterListItem.productPriority;
    const productDefinedPriority = productOnePriority < productTwoPriority;
    return productOnePriority === productTwoPriority
      ? userDefinedPriority
      : productDefinedPriority;
  }

  private findProductPriority(inventory: ProductInventoryState) {
    let priority = 5;
    if (this.productIsPriorityOne(inventory)) {
      priority = 1;
    } else if (this.productIsPriorityTwo(inventory)) {
      priority = 2;
    } else if (this.productIsPriorityThree(inventory)) {
      priority = 3;
    } else if (this.productIsPriorityFour(inventory)) {
      priority = 4;
    }
    return priority;
  }

  private productIsPriorityOne(inventory: ProductInventoryState): boolean {
    return (
      inventory?.productStatus === '0' ||
      inventory?.productStatus === '2' ||
      inventory?.productStatus === '3' ||
      inventory?.productStatus === '4' ||
      inventory?.productStatus === '8'
    );
  }

  private productIsPriorityTwo(inventory: ProductInventoryState): boolean {
    return (
      (inventory?.isCES || inventory?.isDirect) &&
      inventory?.productStatus !== '9'
    );
  }

  private productIsPriorityThree(inventory: ProductInventoryState): boolean {
    return inventory?.isSpecialOrder && inventory?.productStatus !== '9';
  }

  private productIsPriorityFour(inventory: ProductInventoryState): boolean {
    return inventory?.productStatus === '9';
  }

  private createMapOfMslProducts(
    primaryWithSecondaryProducts: PrimaryWithSecondaryProducts,
    isCustomerMslRestricted: boolean,
  ) {
    let secondaryProducts = primaryWithSecondaryProducts.secondaryProducts;
    const mslSecondaries: MslSecondary[] = [];
    const primaryProductItemKey = createMasterListItemKey(
      primaryWithSecondaryProducts.primaryProduct?.masterListItem.listId,
      primaryWithSecondaryProducts.primaryProduct?.masterListItem.listGroupId,
      primaryWithSecondaryProducts.primaryProduct?.masterListItem.listItemId,
    );
    let primaryMslProduct: MslProduct = {
      productNumber:
        primaryWithSecondaryProducts.primaryProduct.inventory?.productNumber,
      mslSecondaries: {},
      isOrderableOnMsl: false,
    };
    if (primaryWithSecondaryProducts.primaryProduct.inventory?.productNumber) {
      primaryMslProduct = this.updatePrimaryMslProduct(
        primaryMslProduct,
        primaryWithSecondaryProducts.primaryProduct,
      );
      let haveSetOrderableProduct = primaryMslProduct.isOrderableOnMsl;
      if (!haveSetOrderableProduct) {
        haveSetOrderableProduct = this.checkStat0OOSSecondaryOrderability(
          secondaryProducts,
          isCustomerMslRestricted,
        );
      }
      secondaryProducts.forEach(secondaryMslItemWithProduct => {
        haveSetOrderableProduct = this.updateSecondaryMslProduct(
          secondaryMslItemWithProduct,
          haveSetOrderableProduct,
          mslSecondaries,
        );
      });
      this.updateMslSecondariesOnMslProduct(
        primaryProductItemKey,
        primaryMslProduct,
        mslSecondaries,
      );
      this.updateMslProductInMap(primaryMslProduct);
    }
  }

  private updatePrimaryMslProduct(
    primaryMslProduct: MslProduct,
    mslItemWithInventory: MslItemWithInventory,
  ): MslProduct {
    const primaryProductToUpdateInMap = this.mslProductsToUpdateMap.get(
      mslItemWithInventory.inventory.productNumber,
    );
    if (primaryProductToUpdateInMap) {
      primaryMslProduct = primaryProductToUpdateInMap;
      if (!primaryMslProduct.mslSecondaries) {
        primaryMslProduct.mslSecondaries = {};
      }
    } else {
      primaryMslProduct.mslSecondaries = {};
    }

    if (mslItemWithInventory.inventory?.isInStock) {
      primaryMslProduct.isOrderableOnMsl = true;
    }

    return primaryMslProduct;
  }

  private updateSecondaryMslProduct(
    secondaryMslItemWithProduct: MslItemWithInventory,
    haveSetOrderableProduct: boolean,
    mslSecondaries: MslSecondary[],
  ): boolean {
    let secondaryProductInventory = secondaryMslItemWithProduct.inventory;
    const secondaryProductToUpdateInMap = this.mslProductsToUpdateMap.get(
      secondaryProductInventory.productNumber,
    );
    let secondaryMslProduct: MslProduct = {
      productNumber: secondaryMslItemWithProduct.inventory.productNumber,
      mslSecondaries: {},
      isOrderableOnMsl: false,
    };
    if (secondaryProductToUpdateInMap) {
      secondaryMslProduct = secondaryProductToUpdateInMap;
    }

    if (!haveSetOrderableProduct && secondaryProductInventory?.isInStock) {
      haveSetOrderableProduct = true;
      secondaryMslProduct.isOrderableOnMsl = true;
    }
    const mslSecondary = this.createMslSecondary(secondaryMslItemWithProduct);
    mslSecondaries.push(mslSecondary);
    this.updateMslProductInMap(secondaryMslProduct);
    return haveSetOrderableProduct;
  }

  private updateMslSecondariesOnMslProduct(
    primaryProductItemKey: string,
    primaryMslProduct: MslProduct,
    mslSecondaries: MslSecondary[],
  ) {
    primaryMslProduct.mslSecondaries[primaryProductItemKey] = mslSecondaries;
  }

  private checkStat0OOSSecondaryOrderability(
    secondaryProducts: MslItemWithInventory[],
    isCustomerMslRestricted: boolean,
  ) {
    const priorityOneInStockSecondary = secondaryProducts?.find(
      secondaryProduct =>
        this.productIsPriorityOne(secondaryProduct?.inventory) &&
        secondaryProduct?.inventory?.isInStock === true,
    );

    const stat0OutOfStockSecondary = secondaryProducts?.find(
      secondaryProduct =>
        secondaryProduct?.inventory?.productStatus === '0' &&
        secondaryProduct?.inventory?.isInStock === false,
    );

    if (
      !priorityOneInStockSecondary &&
      !!stat0OutOfStockSecondary &&
      isCustomerMslRestricted
    ) {
      const stat0OutOfStockMslProduct: MslProduct =
        this.mslProductsToUpdateMap?.get(
          stat0OutOfStockSecondary?.inventory?.productNumber,
        ) || {
          productNumber: stat0OutOfStockSecondary?.inventory?.productNumber,
          mslSecondaries: {},
          isOrderableOnMsl: false,
        };

      stat0OutOfStockMslProduct.isOrderableOnMsl = true;
      this.updateMslProductInMap(stat0OutOfStockMslProduct);

      return true;
    } else {
      return false;
    }
  }

  private updateMslProductInMap(mslProduct: MslProduct) {
    this.mslProductsToUpdateMap.set(mslProduct.productNumber, mslProduct);
  }

  private saveNewMslProduts() {
    const mslProducts = this.createArrayOfProductSummariesFromMap();
    this.store.dispatch(MslProductActions.insertMslProducts({ mslProducts }));
  }

  private createArrayOfProductSummariesFromMap() {
    const mslProducts: MslProduct[] = [];
    this.mslProductsToUpdateMap.forEach(mslProductState => {
      if (mslProductState?.productNumber) {
        mslProducts.push(mslProductState);
      }
    });
    return mslProducts;
  }

  private createItemProductDictionary() {
    this.mslItemKeyMapWithPrimaryAndSecondaryProducts?.forEach(
      (
        primaryWithSecondaryProducts: PrimaryWithSecondaryProducts,
        key: string,
      ) => {
        const productKeys: string[] = [];
        const primaryProduct = primaryWithSecondaryProducts.primaryProduct;
        const primaryProductKey = createMasterListItemProductKey(
          primaryProduct.masterListItem.listId,
          primaryProduct.masterListItem.listGroupId,
          primaryProduct.masterListItem.listItemId,
          primaryProduct.masterListItem.listItemProductId,
        );
        productKeys.push(primaryProductKey);
        primaryWithSecondaryProducts.secondaryProducts.forEach(
          (secondaryProduct: MslItemWithInventory) => {
            // Only add secondary product if it is found in this division
            if (secondaryProduct?.inventory?.productNumber) {
              const secondaryProductKey = createMasterListItemProductKey(
                secondaryProduct.masterListItem.listId,
                secondaryProduct.masterListItem.listGroupId,
                secondaryProduct.masterListItem.listItemId,
                secondaryProduct.masterListItem.listItemProductId,
              );
              productKeys.push(secondaryProductKey);
            }
          },
        );
        this.itemProductDictionary[key] = productKeys;
      },
    );
  }

  private saveItemProductDictionaryToState() {
    this.store.dispatch(
      MASTER_LIST_ITEM_ACTIONS.updateItemKeyProductKeysDict({
        itemKeyProductKeysDict: this.itemProductDictionary,
      }),
    );
  }

  private createMslSecondary(
    secondaryMslItemWithProduct: MslItemWithInventory,
  ): MslSecondary {
    return {
      productNumber: secondaryMslItemWithProduct?.masterListItem?.productNumber,
      productPriority: this.findProductPriority(
        secondaryMslItemWithProduct?.inventory,
      ),
      userPriority:
        secondaryMslItemWithProduct?.masterListItem?.productPriority,
      mlmProductUpdateDtm:
        secondaryMslItemWithProduct?.masterListItem?.mlmProductUpdateDtm,
    } as MslSecondary;
  }

  private resetServiceVariables() {
    this.mslItemKeyMapWithListOfProducts = undefined;
    this.mslItemKeyMapWithPrimaryAndSecondaryProducts = undefined;
    this.mslProductsToUpdateMap = undefined;
    this.itemProductDictionary = {};
    this.mslItemKeyMapWithListOfProducts = new Map();
    this.mslItemKeyMapWithPrimaryAndSecondaryProducts = new Map();
    this.mslProductsToUpdateMap = new Map();
  }
}
