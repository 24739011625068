/* eslint-disable no-shadow */
export enum SortingDirection {
  asc = 'asc',
  desc = 'desc',
}

export enum UsfListDetailManagementHeights {
  /* COMMON - List Management, List Details, Recently Purchased */
  bannerHeightDesktop = 172, // Breadcrumbs (48) + List Banner<My Lists, List Name, # Products, Ellipsis> (124)
  bannerHeightTablet = 74,
  bannerHeightMobile = 48,
  groupHeightDesktop = 65,
  groupHeightTablet = 64.5,
  groupHeightMobile = 64,
  footerHeightDesktop = 351,
  footerWithMarginHeightDesktop = 601.5, // Footer (351) + Padding (250)

  /* List Details, Recently Purchased */
  searchSortFilterHeight = 56,
  orderInfoSidebarBaseHeightDesktop = 331,
  orderInfoSidebarBaseHeightTablet = 332,
  orderInfoSidebarBaseTopPositionDesktop = 246,
  orderInfoSidebarBaseTopPositionTablet = 150,
  orderInfoSidebarDesktopHeight = 392.88,

  /* List Management */
  groupsSidebarBaseHeightDesktopTablet = 398,
  groupsSidebarBaseTopPositionDesktop = 186,
  groupsSidebarBaseTopPositionTablet = 92,
  groupsSidebarIncrementDesktop = 77,
  groupsSectionMaxHeight = 224,
  desktopListManagementHeaderHeight = 208,
  tabletListManagementHeaderHeight = 122,
  mobileListManagementHeaderHeight = 97.59,
}

export enum MasterListHeights {
  primaryProductLabel = 48,
  alternateProductLabel = 72,
  lastSecondaryProductDivider = 41,
}

export enum ListActions {
  create = 'Create',
  copy = 'Copy',
  delete = 'Delete',
  edit = 'Edit',
  move = 'Move',
  viewSelected = 'viewSelectedProducts',
}

export enum AddToListStrings {
  // Add to List Options
  chooseList = 'Choose List',
  chooseGroup = 'Choose Group',
  choosePosition = 'Choose Position',
  // Group Positions
  top = 'TOP',
  bottom = 'BOTTOM',
}

export enum CreateListOptions {
  newList = 'New List',
  copyList = 'Copy List',
  importList = 'Import List',
}

export enum ListTypes {
  public = 'Public',
  private = 'Private',
  internal = 'Internal',
  og = 'OG',
  recentlyPurchased = 'Recently Purchased',
  masterList = 'ML',
}

export enum ListCopyOptions {
  selectList = 'Select List to Copy',
  newListName = 'New List Name',
  listType = 'List Type',
  markets = 'Markets',
  customers = 'Choose Customer(s)',
}

export enum ImportListMapOptions {
  fileSelect = 'file select',
  listName = 'list name',
  listType = 'list type',
}

export const QUICK_VIEW_SEGMENT = 'quickView';

export const FRONT_END_IMPORT_ERROR = 'Frontend shortcircut';

export const BAD_PRODUCT_NUMBER = -999;

export const IMPORT_ERROR_TOAST_HEIGHT = 64;
export const IMPORT_ERROR_TOAST_MOBILE_HEIGHT = 116;

export const MSL_MAX_SELECTABLE_PRODUCTS_FOR_COMPARISON = 4;
