import { AnalyticsTransformer, TrackingData } from '@panamax/app-state';
export const homePageLoad: AnalyticsTransformer = (tracking: TrackingData) => {
  let trackingObj = {
    page: {
      pageName: tracking.data.pageName,
    },
    event: tracking.data.event,
    capability: tracking.data.capability,
    promo: tracking.data.promo,
    wheresMyTruck: tracking.data.wheresMyTruck,
    deals: {
      view: tracking.data.dealsTile.displayed,
    },
    newsPage: { view: tracking.data.newsTile.displayed },
  };

  if (!tracking.data.promo.list) delete trackingObj.promo;
  if (!tracking.data.newsTile.displayed) delete trackingObj.newsPage;
  if (!tracking.data.wheresMyTruck.numberOfShipments)
    delete trackingObj.wheresMyTruck;

  return trackingObj;
};

export const interactBannerClick: AnalyticsTransformer = (
  tracking: TrackingData,
) => {
  let trackingObj = {
    event: tracking.data.event,
    capability: tracking.data.capability,
    activePromo: tracking.data.activePromo,
    link: tracking.data.link,
  };

  if (!tracking.data.link.url) delete trackingObj.link;

  return trackingObj;
};

export const interactBannersInViewport: AnalyticsTransformer = (
  tracking: TrackingData,
) => {
  let trackingObj = {
    event: tracking.data.event,
    capability: tracking.data.capability,
    engagement: tracking.data.engagement,
    promo: tracking.data.promo,
  };

  return trackingObj;
};
