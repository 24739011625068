import { Component, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ListDetailManagementService } from '../list-detail-management/services/list-detail-management.service';
import { ListState } from '@usf/ngrx-list';
import { Router } from '@angular/router';
import { PATHS } from 'src/app/shared/constants/paths';
import { MassDeleteDualPaneContainerComponent } from './mass-delete-dual-pane-container/mass-delete-dual-pane-container.component';

@Component({
  selector: 'app-delete-list',
  templateUrl: './delete-list.component.html',
  styleUrls: ['./delete-list.component.scss'],
})
export class DeleteListComponent {
  @Input() list: ListState;
  @Input() goBack: boolean;

  constructor(
    private router: Router,
    private modalController: ModalController,
    readonly platformService: PlatformService,
    private listDetailManagementService: ListDetailManagementService,
  ) {}

  onDeleteList() {
    this.listDetailManagementService.deleteList(this.list);
    this.dismissModal();
    if (this.goBack && this.goBack === true) {
      this.router.navigate([PATHS.LISTS]);
    }
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }
}
