<ion-item
  [attr.data-cy]="'highlighted-selectable-item-' + index"
  class="highlighted-selectable-item"
  [class.selected]="selected"
  detail="true"
  (click)="clickValue()"
>
  <ion-label>
    <p data-cy="label" class="label bold">{{ label | translate }}</p>
    <p data-cy="value" class="value">{{ value | translate }}</p>
  </ion-label>
</ion-item>
