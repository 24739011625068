import { Tracking } from '@panamax/app-state';
import { OrderTracingMessages } from '../order-tracing.constants';

export const getOrderDetailTrace: Tracking = {
  tracing: {
    data: {
      traceContext: OrderTracingMessages.capability,
      isEndOfTrace: true,
      isStartOfTrace: false,
      attributes: {
        order: {
          orderId: undefined,
          tandemOrderNumber: undefined,
        },
        event: OrderTracingMessages.getOrderDetailEvent,
      },
    },
  },
};
