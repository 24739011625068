import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DownloadPrintBaseService } from '../download-print-base.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import {
  DownloadRequestListType,
  DownloadRequestFileType,
} from '@usf/list-types';
import { throwError } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { DatePipe } from '@angular/common';
import { Platform } from '@ionic/angular';
import { LoadingSpinnerService } from '@shared/services/loading-spinner/loading-spinner.service';
import { ListAnalyticsHelperService } from '@app/lists/services/list-analytics-helper.service';
import { SentenceCasePipe } from '@shared/pipes/sentence-case.pipe';
import { MessageStoreService } from '@app/ngrx-message/services/message/message-store.service';
@Injectable({
  providedIn: 'root',
})
export class PrintListService extends DownloadPrintBaseService {
  constructor(
    http: HttpClient,
    toastService: ToastService,
    translateService: TranslateService,
    platform: Platform,
    datePipe: DatePipe,
    loadingSpinnerService: LoadingSpinnerService,
    listAnalyticsHelperService: ListAnalyticsHelperService,
    sentenceCasePipe: SentenceCasePipe,
    private sanitizer: DomSanitizer,
    messageStoreService: MessageStoreService,
  ) {
    super(
      http,
      toastService,
      translateService,
      platform,
      datePipe,
      loadingSpinnerService,
      listAnalyticsHelperService,
      sentenceCasePipe,
      messageStoreService,
    );
  }

  public printList = (
    listId: number,
    hidePrice: boolean,
    listType: DownloadRequestListType,
    fileType: DownloadRequestFileType,
    fileName: string,
    errorMessageKey: string,
    listState: string,
  ) => {
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.trackDownloadPrintAnalytics(
        listId,
        listType,
        listState,
        'print:list:menu',
      );
      this.downloadPrintSubs = this.getData(
        listId,
        hidePrice,
        listType,
        fileType,
        errorMessageKey,
      ).subscribe(resp => {
        this.showPrintDialogue(resp, fileName, errorMessageKey);
      });
    });
  };

  async showPrintDialogue(
    resp: string,
    fileName: string,
    errorMessageKey: string,
  ) {
    try {
      if (this.platform.is('hybrid')) {
        const savedFile = await Filesystem.writeFile({
          path: fileName,
          data: resp,
          directory: this.platform.is('android')
            ? Directory.Data
            : Directory.Documents,
        });
        FileOpener.open({
          filePath: savedFile.uri,
          contentType: 'application/pdf',
        });
      } else {
        const byteArray = this.convertBase64ToByteArray(resp);
        var blob = new Blob([byteArray], { type: 'application/pdf' });
        var blobURL = URL.createObjectURL(blob);
        const healthyUrl = this.sanitizer.sanitize(
          SecurityContext.RESOURCE_URL,
          this.sanitizer.bypassSecurityTrustResourceUrl(blobURL),
        );
        var a: HTMLAnchorElement = document.createElement('a');
        a.href = healthyUrl;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      this.cleanUp();
    } catch (e) {
      this.showErrorToast(errorMessageKey);
      this.cleanUp();
      return throwError(e);
    }
  }
}
