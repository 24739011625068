import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_KEYS, PanAppState } from '@panamax/app-state';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { jwtDecode } from '@panamax/app-state';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  hasAccess: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(private panamax: PanAppState, private location: Location) {}

  /**
   * Returns true/false based on token return from subscription
   */
  public isAuthenticated(): boolean {
    this.evaluateLogin();
    return this.hasAccess.value;
  }

  evaluateLogin(): void {
    this.panamax.token$
      .pipe(
        filter(token => !!token),
        take(1),
      )
      .subscribe(token => {
        this.hasAccess.next(
          this.hasR4Access(token.accessToken) || this.hasProductDetailPath(),
        );
      });
  }

  evaluateLogin$(): Observable<boolean> {
    return this.panamax.token$.pipe(
      map(token => {
        const accessToken =
          !!token && token.accessToken ? token.accessToken : '';
        if (!accessToken) {
          return false;
        } else if (
          this.hasR4Access(accessToken) ||
          this.hasProductDetailPath()
        ) {
          return true;
        } else {
          return false;
        }
      }),
    );
  }

  hasR4Access(token: string): boolean {
    const decoded = jwtDecode(token);
    if (!decoded) {
      return false;
    } else {
      return decoded['usf-claims'].r4Redirect === 'Y';
    }
  }

  hasProductDetailPath() {
    const params = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.params));
    if (this.location.path().includes('b2c')) {
      if (!!params && params?.returnUrl?.includes('products')) {
        return true;
      }
    } else if (this.location.path().includes('products')) {
      return true;
    } else {
      return false;
    }
  }
}
