import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  PLATFORM_TYPE,
  PlatformType,
} from '@panamax/app-state/lib/models/platform.model';

@Component({
  selector: 'app-product-card-item-note',
  templateUrl: './product-card-item-note.component.html',
  styleUrls: ['./product-card-item-note.component.scss'],
})
export class ProductCardItemNoteComponent {
  @Input() note: string;
  @Input() productNumber: number;
  @Input() truncateAmount: number;

  @Output() itemNoteClickEmitter: EventEmitter<any> = new EventEmitter();
  constructor() {}

  itemNoteClick() {
    this.itemNoteClickEmitter.emit();
  }
}
