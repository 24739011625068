/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
  selector: '[customDecimal]',
})
export class CustomDecimalDirective implements OnInit {
  @Input() maxLeftHandDigits: number;
  @Input() maxPrecision: number;
  private scrubAlphaRegex: RegExp;
  private isAndroid = false;

  constructor(private el: ElementRef, private platform: Platform) {}

  ngOnInit() {
    let targetNonDigits = '[^\\d.]';
    this.scrubAlphaRegex = new RegExp(targetNonDigits, 'g');
    this.isAndroid = this.platform.is('android');
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    if (!this.isAndroid) {
      let regexValue = event.target.value.replace(this.scrubAlphaRegex, '');
      if (regexValue.includes('.')) {
        let regexArray = regexValue.split('.');
        if (regexArray.length > 1) {
          regexValue =
            regexArray[0].slice(0, this.maxLeftHandDigits) +
            '.' +
            regexArray[1].slice(0, this.maxPrecision);
        } else {
          regexValue = regexArray[0].slice(0, this.maxLeftHandDigits);
        }
      } else {
        regexValue = regexValue.slice(0, this.maxLeftHandDigits);
      }

      this.el.nativeElement.value = regexValue;

      if (regexValue !== this.el.nativeElement.value) {
        event.target.value = regexValue;
      }
    }
  }
}
