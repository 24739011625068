import { Component, Input, OnInit } from '@angular/core';
import { ViewWillLeave } from '@ionic/angular';
import { WorksWellWithEnum } from '@shared/constants/works-well-with.enum';
import { Product } from '@shared/models/product.model';
import { WorksWellWithService } from '@shared/services/works-well-with/works-well-with.service';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'app-works-well-with-container',
  templateUrl: './works-well-with-container.component.html',
  styleUrls: ['./works-well-with-container.component.scss'],
})
export class WorksWellWithContainerComponent implements OnInit, ViewWillLeave {
  @Input() product: Product;
  @Input() isMobile = false;
  productsMap: Map<number, Product>;
  productList: Product[];
  numberOfWWWProducts: number;
  firstFourWWWProducts: Product[];
  worksWellWithProducts$: Subscription;
  worksWellWithMode: WorksWellWithEnum;
  readonly worksWellWithModes = WorksWellWithEnum;

  constructor(private worksWellWithService: WorksWellWithService) {}

  ngOnInit(): void {
    if (!!this.product?.showWorksWellWith) {
      this.worksWellWithProducts$ = this.worksWellWithService
        .getWorksWellWithProducts(this.product.summary.worksWellWith.complement)
        .pipe(take(1))
        .subscribe(products => {
          this.productsMap = products;
        });
    }
    if (!!this.productsMap) {
      this.productList = this.worksWellWithService.filterAndSortProducts(
        this.productsMap,
      );
      this.firstFourWWWProducts = [];

      for (let index = 0; index < this.productList?.length; index++) {
        if (index >= 4) {
          break;
        }

        this.firstFourWWWProducts.push(this.productList[index]);
      }
    }

    if (this.productList?.length < 1) {
      this.worksWellWithMode = WorksWellWithEnum.NO_PRODUCTS;
    } else if (this.productList?.length === 1 && this.isMobile === false) {
      this.worksWellWithMode = WorksWellWithEnum.ONE_PRODUCT_DESKTOP;
    } else if (this.productList?.length === 2 && this.isMobile === false) {
      this.worksWellWithMode = WorksWellWithEnum.TWO_PRODUCT_DESKTOP;
    } else if (this.productList?.length > 2 && this.isMobile === false) {
      this.worksWellWithMode = WorksWellWithEnum.THREEPLUS_PRODUCT_DESKTOP;
    } else if (this.isMobile === true) {
      this.worksWellWithMode = WorksWellWithEnum.MOBILE;
    }
  }

  ionViewWillLeave(): void {
    if (!!this.worksWellWithProducts$) {
      this.worksWellWithProducts$.unsubscribe();
    }
  }

  viewButtonClick() {
    this.worksWellWithService
      .handleViewButtonClick(this.product, this.productList)
      .then(() => {
        this.worksWellWithService.trackProductWorksWellWithButtonEvent(
          this.productList,
        );
      });
  }
}
