import {
  ProductAlternative,
  ProductAlternativeTypeEnum,
} from '../models/product-alternative';
import { ProductSummary, ProductInventory } from '@usf/product-types';
import { ProductPropertiesEnum } from '@usf/product-types/Product-Summary';
import { Product } from '../../shared/models/product.model';

export const getProductReplacement = (
  baseProduct: Product,
  productsMap: Map<number, Product>,
): ProductAlternative => {
  const productSummary = baseProduct?.summary;
  if (!isReplacementRequired(productSummary)) {
    return buildAlternative(false);
  } else if (!isReplacementReferenceAvailable(productSummary)) {
    return buildAlternative(true, false);
  } else {
    return findReplacement(baseProduct, productsMap);
  }
};

const isReplacementRequired = (productSummary: ProductSummary): boolean =>
  !!productSummary &&
  productSummary.properties?.has(ProductPropertiesEnum.discontinued);

const isReplacementReferenceAvailable = (
  productSummary: ProductSummary,
): boolean => productSummary.replacementProductNumber > 0;

const isValidReplacement = (productInventory: ProductInventory): boolean =>
  !!productInventory &&
  productInventory.productStatus !== '9' &&
  productInventory.isInStock;

const findReplacement = (
  baseProduct: Product,
  productsMap: Map<number, Product>,
): ProductAlternative => {
  const replacementProduct = productsMap.get(
    baseProduct?.summary?.replacementProductNumber,
  );
  if (replacementProduct === undefined) {
    return undefined; // CANNOT BE RESOLVED YET
  }
  let replacement: ProductAlternative;
  if (isValidReplacement(replacementProduct.inventory)) {
    replacement = buildAlternative(true, true, replacementProduct);
  } else {
    replacement = buildAlternative(true, false);
  }
  return replacement;
};

const buildAlternative = (
  required: boolean,
  available?: boolean,
  product?: Product,
): ProductAlternative => ({
  required,
  available,
  product,
  type: ProductAlternativeTypeEnum.replacement,
});
