import { Component } from '@angular/core';
import { InventoryWorksheetService } from '@inventory/pages/inventory-worksheet/services/inventory-worksheet.service';
import { IonNav, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '@panamax/app-state';
import { InventoryActionModalBaseComponent } from '../inventory-action-modal-base.component';

@Component({
  selector: 'app-inventory-action-modal-mobile',
  templateUrl: './inventory-action-modal-mobile.component.html',
  styleUrls: ['./inventory-action-modal-mobile.component.scss'],
})
export class InventoryActionModalMobileComponent extends InventoryActionModalBaseComponent {
  constructor(
    ionNav: IonNav,
    modalController: ModalController,
    translateService: TranslateService,
    platformService: PlatformService,
    inventoryWorksheetService: InventoryWorksheetService,
  ) {
    super(
      ionNav,
      modalController,
      translateService,
      platformService,
      inventoryWorksheetService,
    );
  }
}
