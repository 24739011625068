import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ellipsis-options-popover',
  templateUrl: './ellipsis-options-popover.component.html',
  styleUrls: ['./ellipsis-options-popover.component.scss'],
})
export class EllipsisOptionsPopoverComponent {
  @Input() buttons: any[];

  constructor() {}
}
