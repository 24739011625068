import { Injectable, OnDestroy } from '@angular/core';
import { ProfileAnalyticsService } from '@app/profile/analytics/profile-analytics.service';
import { PopoverController } from '@ionic/angular';
import {
  PageInfoService,
  PanAppState,
  PlatformService,
} from '@panamax/app-state';
import { ErrorPopoverComponent } from '@shared/components/header/error-popover/error-popover.component';
import { AppService } from '@shared/services/app.service';
import { ClaimTicketService } from '@shared/services/claim-ticket.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PopoverHelperService } from '../../helpers/popover.helpers';

@Injectable({
  providedIn: 'root',
})
export class UpcService implements OnDestroy {
  constructor(
    private claimTicketService: ClaimTicketService,
    private profileAnalyticsService: ProfileAnalyticsService,
    private appService: AppService,
    private popoverHelperService: PopoverHelperService,
    public popoverController: PopoverController,
    readonly platformService: PlatformService,
    protected panAppState: PanAppState,
    private pageInfoService: PageInfoService,
  ) {}

  isDesktop: boolean;
  claimTicketSub$: Subscription = new Subscription();

  ngOnDestroy(): void {
    if (this.claimTicketSub$) {
      this.claimTicketSub$.unsubscribe();
    }
  }

  openUPC() {
    this.isDesktop =
      this.platformService.platformType ===
      this.platformService.platformEnum.desktop;

    this.claimTicketSub$ = this.claimTicketService.getClaimTicket()?.subscribe(
      res => {
        if (res?.claimTicketValue) {
          const origin =
            this.pageInfoService
              .getUrlInfo()
              ?.hostName?.toLowerCase()
              ?.indexOf('premier') > -1
              ? 'PREMIER'
              : 'ORDER';
          const upcUrl =
            environment.upcUrl +
            `?claimTicket=${
              res?.claimTicketValue
            }&R4=true&origin=${origin}&platformType=${
              this.isDesktop ? 'desktop' : 'mobile'
            }`;
          if (this.isDesktop) {
            window.open(upcUrl, '_self');
          } else {
            this.appService.openUrlInBrowser(upcUrl);
          }
          this.profileAnalyticsService.trackUPCLink(upcUrl);
        } else {
          this.popoverHelperService.presentPopover(
            this.popoverController,
            ErrorPopoverComponent,
            'Unable to take you to preference center at this time. Please try again later.',
          );
        }
      },
      () => {
        this.popoverHelperService.presentPopover(
          this.popoverController,
          ErrorPopoverComponent,
          'Unable to take you to preference center at this time. Please try again later.',
        );
      },
    );
  }

  openUpcMobileOnly() {
    this.claimTicketSub$ = this.claimTicketService.getClaimTicket()?.subscribe(
      res => {
        if (res?.claimTicketValue) {
          const origin =
            this.pageInfoService
              .getUrlInfo()
              ?.hostName?.toLowerCase()
              ?.indexOf('premier') > -1
              ? 'PREMIER'
              : 'ORDER';
          const upcUrl =
            environment.upcUrl +
            `?claimTicket=${res?.claimTicketValue}&R4=true&origin=${origin}&platformType=mobile`;
          this.appService.openUrlInBrowser(upcUrl);
          this.profileAnalyticsService.trackUPCLink(upcUrl);
        } else {
          this.popoverHelperService.presentPopover(
            this.popoverController,
            ErrorPopoverComponent,
            'Unable to take you to preference center at this time. Please try again later.',
          );
        }
      },
      () => {
        this.popoverHelperService.presentPopover(
          this.popoverController,
          ErrorPopoverComponent,
          'Unable to take you to preference center at this time. Please try again later.',
        );
      },
    );
  }

  openUpcDesktopOnly() {
    this.claimTicketSub$ = this.claimTicketService.getClaimTicket()?.subscribe(
      res => {
        if (res?.claimTicketValue) {
          const origin =
            this.pageInfoService
              .getUrlInfo()
              ?.hostName?.toLowerCase()
              ?.indexOf('premier') > -1
              ? 'PREMIER'
              : 'ORDER';
          const upcUrl =
            environment.upcUrl +
            `?claimTicket=${res?.claimTicketValue}&R4=true&origin=${origin}&platformType=desktop`;
          this.profileAnalyticsService.trackUPCLink(upcUrl);
          window.open(upcUrl, '_self');
        } else {
          this.popoverHelperService.presentPopover(
            this.popoverController,
            ErrorPopoverComponent,
            'Unable to take you to preference center at this time. Please try again later.',
          );
        }
      },
      () => {
        this.popoverHelperService.presentPopover(
          this.popoverController,
          ErrorPopoverComponent,
          'Unable to take you to preference center at this time. Please try again later.',
        );
      },
    );
  }
}
