import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';

export const LOGIN_ANALYTICS_ACTIONS = {
  trackNewUserSignUp: createAction(
    '[NgRxClient Analytics] - Track New User Sign up',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackNewUserPageLoad: createAction(
    '[NgRxClient Analytics] - Track New User Page Load',
    props<{
      tracking: Tracking;
    }>(),
  ),
};
