<ng-template #content><ng-content></ng-content></ng-template>

<div
  id="usf-product-card"
  [class.selectable-card]="
    vm?.mode === productCardModes.replacementSearch ||
    vm?.mode === productCardModes.addToListSearch ||
    vm?.mode === productCardModes.compareProducts ||
    vm?.mode === productCardModes.listManagement ||
    vm?.mode === productCardModes.exceptionSearch ||
    vm?.mode === productCardModes.cartReplacementSearch ||
    vm?.mode === productCardModes.addToInventory ||
    vm?.mode === productCardModes.addToInventorySearch ||
    vm?.mode === productCardModes.productNumberSearch ||
    vm?.mode === productCardModes.defaultSearch ||
    vm?.mode === productCardModes.bannerSearch ||
    vm?.mode === productCardModes.recentlyPurchased ||
    vm?.mode === productCardModes.listDetail
  "
  [class.selected-card]="vm?.isSelected"
  [class.mobile]="
    platformService?.platformType === platformService?.platformEnum?.mobile
  "
  [class.disabled]="vm?.checkboxDisabled"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <ion-card
    class="usf-product-card-content"
    [class.split-card]="vm?.product?.hasAlternative && !isGuestUser"
    [class.has-warning-drawer]="
      vm?.product?.drawerMsg?.isWarning && !isGuestUser
    "
  >
    <!-- Original Product Card Top Drawer (when not split) -->
    <ng-container
      *ngIf="
        !vm?.product?.hasAlternative &&
        !!vm?.product?.drawerMsg?.message &&
        !isGuestUser
      "
      [ngTemplateOutlet]="topDrawer"
    >
    </ng-container>

    <!-- Better Buys Drawer -->
    <div
      *ngIf="
        !!vm?.product?.betterBuy &&
        !vm?.product?.hasAlternative &&
        (vm?.mode === productCardModes.listDetail ||
          vm?.mode === productCardModes.recentlyPurchased)
      "
      (click)="handleProductConversionClick()"
      data-cy="product-card-better-buy-message"
      class="product-conversion-drawer sf-pro-display"
    >
      <p>{{ 'i18n.betterBuys.productConversionOpportunity' | translate }}</p>
    </div>

    <!-- Forced Updates Drawer -->
    <div
      *ngIf="
        !!vm?.product?.item?.forcedUpdate &&
        (!vm?.product?.betterBuy ||
          vm?.mode === productCardModes.listManagement) &&
        (vm?.mode === productCardModes.listDetail ||
          vm?.mode === productCardModes.listManagement)
      "
      (click)="handleProductConversionClick()"
      data-cy="product-card-better-buy-message"
      class="product-conversion-drawer sf-pro-display"
    >
      <p>
        {{ 'i18n.lists.listUpdatesProductCardMessage' | translate }}
        {{ vm?.product?.item?.forcedUpdate?.reason | translate }}
      </p>
    </div>

    <!-- ORIGINAL PRODUCT CARD -->
    <div
      class="original-product-card"
      [class.split-with-drawer]="
        vm?.product?.hasAlternative &&
        !!vm?.product?.drawerMsg?.message &&
        !isGuestUser
      "
      [class.product-conversion-wrapper]="
        (!!vm?.product?.betterBuy &&
          !vm?.product?.hasAlternative &&
          vm?.mode !== productCardModes.listManagement) ||
        (!!vm?.product?.item?.forcedUpdate &&
          !vm?.product?.betterBuy &&
          (vm?.mode === productCardModes.listDetail ||
            vm?.mode === productCardModes.listManagement))
      "
    >
      <app-usf-product-card-info-touch
        [isOriginalProduct]="true"
        [index]="vm?.index"
        [lnkSrc]="lnkSrc"
        [mode]="vm?.mode"
        [powerReviewRollupModel]="powerReviewRollupModel"
        [alternatePowerReviewRollupModel]="alternatePowerReviewRollupModel"
        [product]="vm?.product"
        [itemType]="vm?.itemType"
        [pricingDisabled]="vm?.pricingDisabled"
        [sequenceNumber]="vm?.product?.sequenceNumber"
        [hasAlternative]="vm?.product?.hasAlternative && !isGuestUser"
        [hasPromotions]="vm?.product?.promotionDetails ? true : false"
        [hasAlternativePromotions]="
          vm?.product?.alternative?.product?.promotionDetails ? true : false
        "
        [showNotes]="showNotes"
        [listId]="vm?.product?.listId"
        [showConfirmQuantityButton]="vm?.showConfirmQuantityButton"
        [showAddTolistButton]="showAddToList"
        [useOrderObservable]="useOrderObservable"
        (ellipsisEmitter)="openProductCardOptions($event, true)"
        (itemNoteEmitter)="onItemNoteClick(true)"
        (productCardClickEmitter)="onProductCardClick($event, true)"
        (promotionClickEmitter)="onPromotionClick($event, true)"
        (quantityInputEmitter)="sendUpQuantityInput($event)"
        (quantityUpdateEmitter)="onQuantityUpdate($event)"
        (resequenceProductEmitter)="resequenceProduct($event)"
        (confirmQuantityEmitter)="confirmUnusualQuantity($event)"
        [clickable]="
          vm?.mode !== productCardModes.exceptionSearch &&
          vm?.mode !== productCardModes.modifyVS
        "
        [isGuestUser]="isGuestUser"
      ></app-usf-product-card-info-touch>
      <app-works-well-with-container
        *ngIf="
          (vm?.mode === productCardModes.replacementSearch ||
            vm?.mode === productCardModes.addToListSearch ||
            vm?.mode === productCardModes.compareProducts ||
            vm?.mode === productCardModes.exceptionSearch ||
            vm?.mode === productCardModes.cartReplacementSearch ||
            vm?.mode === productCardModes.defaultSearch ||
            vm?.mode === productCardModes.bannerSearch) &&
          !!vm?.product?.showWorksWellWith &&
          !vm?.product?.hasAlternative
        "
        [product]="vm?.product"
        [isMobile]="
          platformService?.getPlatformType() ===
          platformService?.platformEnum.mobile
        "
      ></app-works-well-with-container>
      <!-- Better Buys Card -->
      <div
        *ngIf="
          !!vm?.product?.betterBuy &&
          !vm?.product?.hasAlternative &&
          (vm?.mode === productCardModes.listDetail ||
            vm?.mode === productCardModes.recentlyPurchased)
        "
        class="better-buy-card"
      >
        <app-usf-better-buy-card-touch
          [index]="vm?.index"
          [mode]="vm?.mode"
          [listId]="vm?.product?.listId"
          [betterBuyProduct]="vm?.product?.betterBuy"
          [pricingDisabled]="vm?.pricingDisabled"
          [platformType]="platformService?.platformType"
          [isOnline]="panAppState.online$ | async"
          (quantityElementEmitter)="sendUpQuantityInput($event)"
          (quantityUpdateEmitter)="onQuantityUpdate($event)"
          (declineSwapEmitter)="onDeclineClick($event)"
          (acceptSwapEmitter)="acceptSwap()"
          (productCardClickEmitter)="onProductCardClick($event, false)"
          [product]="vm?.product"
        ></app-usf-better-buy-card-touch>
      </div>
    </div>

    <!-- Product Card Top Drawer (when split) -->
    <ng-container
      *ngIf="
        !isGuestUser &&
        vm?.product?.hasAlternative &&
        !!vm?.product?.drawerMsg?.message
      "
      [ngTemplateOutlet]="topDrawer"
    ></ng-container>

    <!-- SUBSTITUTE/REPLACEMENT PRODUCT CARD -->
    <div
      *ngIf="vm?.product?.hasAlternative && !isGuestUser"
      class="alternative-product-card"
    >
      <app-usf-product-card-info-touch
        [isOriginalProduct]="false"
        [index]="vm?.index"
        [lnkSrc]="lnkSrc"
        [mode]="vm?.mode"
        [powerReviewRollupModel]="powerReviewRollupModel"
        [alternatePowerReviewRollupModel]="alternatePowerReviewRollupModel"
        [product]="vm?.product?.alternative?.product"
        [itemType]="vm?.itemType"
        [pricingDisabled]="vm?.pricingDisabled"
        [sequenceNumber]="vm?.product?.sequenceNumber"
        [hasAlternative]="vm?.product?.hasAlternative"
        [hasPromotions]="vm?.product?.promotionDetails ? true : false"
        [hasAlternativePromotions]="
          vm?.product?.alternative?.product?.promotionDetails ? true : false
        "
        [productCardResolutionMsg]="vm?.product?.productCardResolutionMsg"
        [showNotes]="showNotes"
        [listId]="vm?.product?.listId"
        (ellipsisEmitter)="openProductCardOptions($event, true)"
        (itemNoteEmitter)="onItemNoteClick(true)"
        (productCardClickEmitter)="onProductCardClick($event)"
        (promotionClickEmitter)="onPromotionClick($event, true)"
        (quantityInputEmitter)="sendUpQuantityInput($event)"
        (quantityUpdateEmitter)="onQuantityUpdate($event)"
        (resequenceProductEmitter)="resequenceProduct($event)"
        [clickable]="
          vm?.mode !== productCardModes.exceptionSearch &&
          vm?.mode !== productCardModes.modifyVS
        "
        [isGuestUser]="isGuestUser"
      ></app-usf-product-card-info-touch>
    </div>
  </ion-card>
</div>

<!-- Top Drawer Template -->
<ng-template #topDrawer>
  <div
    *ngIf="!!vm?.product?.drawerMsg?.message"
    data-cy="product-card-top-drawer-section"
    class="top-drawer"
  >
    <ion-icon
      *ngIf="!vm?.product?.drawerMsg?.isAutoSubbed"
      data-cy="product-card-top-drawer-icon"
      name="{{ vm?.product?.drawerMsg?.iconName }}"
      (click)="
        vm?.product?.drawerMsg?.isWarning ? null : onInfoIconClick(false)
      "
    ></ion-icon>
    <p data-cy="product-card-top-drawer-text">
      {{ vm?.product?.drawerMsg?.message | translate }}
      <span
        *ngIf="vm?.product?.drawerMsg?.secondaryMessage"
        data-cy="product-card-top-drawer-subtext-1"
      >
        &nbsp;{{ vm?.product?.drawerMsg?.secondaryMessage | translate }}
      </span>
      <span
        *ngIf="vm?.product?.drawerMsg?.secondaryMessageDate"
        data-cy="product-card-top-drawer-subtext-2"
      >
        &nbsp;{{ vm?.product?.drawerMsg?.secondaryMessageDate }}
      </span>
      <span
        *ngIf="vm?.product?.drawerMsg?.boldedText"
        data-cy="product-card-top-drawer-boldtext-1"
      >
        <b>{{ vm?.product?.drawerMsg?.boldedText | translate }}</b>
      </span>
    </p>
  </div>
</ng-template>
