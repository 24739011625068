import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { IonNav, ViewWillEnter } from '@ionic/angular';
import { PlatformEnum } from '@panamax/app-state';
import {
  FilePicker,
  PickFilesOptions,
} from '@capawesome/capacitor-file-picker';
import {
  dualPaneDefaultDesktopTabletSelection,
  dualPaneDefaultTouchSelection,
} from '@shared/components/modal-with-nav-inline/dual-pane-container/Helpers/dual-pane-helper-functions';
import { DualPaneView } from '@shared/constants/dual-pane.enum';
import {
  BasicListItem,
  DualPaneViewModel,
} from '@shared/models/dual-pane-model';
import { BehaviorSubject, of, tap, Observable } from 'rxjs';
import { b64toBlob } from '@shared/helpers/file.helpers';

@Component({
  selector: 'app-dual-pane-modal',
  templateUrl: './dual-pane-modal.component.html',
  styleUrls: ['./dual-pane-modal.component.scss', '../dual-pane-shared.scss'],
})
export class DualPaneModalComponent implements ViewWillEnter {
  @Input() dataCyTag = 'dual-pane';
  @Input() viewModel: DualPaneViewModel;
  @Input() platform: PlatformEnum;
  @Input() saveChanges: Function;
  @Input() modalOpenState$: BehaviorSubject<boolean>;
  @Input() submit$: BehaviorSubject<Map<string, any | any[]>>;

  platformRef = PlatformEnum;
  dualPaneViewRef = DualPaneView;
  dataPayload: any;
  outsideLifecyleOperator$: Observable<any>;
  @Input()
  updateViewModelOnSelectionDesktop = dualPaneDefaultDesktopTabletSelection;
  @Input() updateViewModelOnSelectionTouch = dualPaneDefaultTouchSelection;
  @Input() outsideLifecycle$: BehaviorSubject<any>;
  constructor(private ionNav: IonNav) {}

  ionViewWillEnter(): void {
    this.validateSubmitOrSave();
    this.outsideLifecyleOperator$ = this.outsideLifecycle$?.pipe(
      tap(value => {
        if (value === undefined) {
          return;
        }
        this.viewModel?.outsideLifecycleOperation(this.viewModel, value);
      }),
    );
  }

  // Left Pane / First Page selection
  basicListItemSelected(item: BasicListItem) {
    this.viewModel.leftView.content = this.viewModel.leftView.content.map(
      iterateItem => {
        if (iterateItem.label !== item.label) {
          return { ...iterateItem, selected: false };
        } else {
          return { ...iterateItem, selected: true };
        }
      },
    );

    if (this.platform !== PlatformEnum.mobile) {
      this.viewModel = this.updateViewModelOnSelectionDesktop(
        this.viewModel,
        item,
      );
    } else {
      const updatedViewModel = this.updateViewModelOnSelectionTouch(
        this.viewModel,
        item,
      );
      this.ionNav.push(DualPaneModalComponent, {
        viewModel: updatedViewModel,
        dataCyTag: this.dataCyTag,
        platform: this.platform,
        modalOpenState$: this.modalOpenState$,
        submit$: this.submit$,
        saveChanges: this.saveChangeFunction.bind(this),
        outsideLifecycle$: this.outsideLifecycle$,
      });
    }
  }

  leftCheckboxClicked = (item: BasicListItem) => {
    this.viewModel.leftView.content = this.viewModel.leftView.content.map(
      iterateItem => {
        if (iterateItem.label === item.label) {
          return { ...iterateItem, value: item.value };
        } else {
          return iterateItem;
        }
      },
    );
  };

  // File handling
  handleFileAddRequest = async () => {
    this.viewModel.rightView.error = of(false);
    this.viewModel.rightView.filePane.showSpinner = true;
    try {
      const selectedFiles = await FilePicker.pickFiles({
        types: this.viewModel.rightView?.filePane?.fileTypes,
        multiple: false,
        readData: true,
      } as PickFilesOptions);
      let file: File;

      if (selectedFiles.files.length > 0) {
        selectedFiles.files[0].blob = b64toBlob(selectedFiles.files[0].data);
        file = new File(
          [selectedFiles.files[0].blob],
          selectedFiles.files[0].name,
        );
      }

      if (!!file && this.viewModel.rightView?.filePane?.verifyContent) {
        const fileIsValid =
          await this.viewModel.rightView?.filePane?.verifyContent(
            file,
            this.viewModel,
          );
        if (fileIsValid) {
          this.viewModel.rightView.value = file;
          this.viewModel.rightView.filePane?.fileSelected(
            this.viewModel,
            this.platform,
            this.viewModel.rightView,
            file,
          );
        } else {
          this.viewModel.rightView.error = of(true);
        }
      }
      this.validateSubmitOrSave();
    } catch (ignore) {}
    this.viewModel.rightView.filePane.showSpinner = false;
  };

  handleFileDeleteRequest() {
    this.viewModel.rightView.value = undefined;
    this.viewModel.rightView.filePane?.fileSelected(
      this.viewModel,
      this.platform,
      this.viewModel.rightView,
      undefined,
    );
    this.validateSubmitOrSave();
  }

  // Submission and Navigation
  validateSubmitOrSave = () => {
    if (this.viewModel.submitIsBackButton) {
      if (this.viewModel?.rightView?.verifySaveButton)
        this.viewModel.disableSave =
          !this.viewModel?.rightView?.verifySaveButton(this.viewModel);
    } else {
      if (this.viewModel?.verifySubmissionValidity)
        this.viewModel.disableSubmit =
          !this.viewModel?.verifySubmissionValidity(this.viewModel);
    }
  };

  saveChangeFunction(
    view: DualPaneView,
    value: any,
    potentialOverrideFunction?: Function,
  ) {
    this.viewModel.leftView.content = this.viewModel.leftView.content.map(
      item => {
        if (item.selected) {
          if (potentialOverrideFunction) {
            return potentialOverrideFunction(this.viewModel, item, value);
          } else {
            item.value = value;
            item.subLabel = value;
          }
        }
        return item;
      },
    );
    this.viewModel.disableSubmit = !this.viewModel.verifySubmissionValidity(
      this.viewModel,
    );
  }

  goBack() {
    this.ionNav.pop();
  }

  goBackAndSave() {
    const leftPane = this.viewModel.leftView.content.find(item => {
      return item.selected;
    });
    this.saveChanges(
      this.viewModel.rightView.view,
      leftPane?.value,
      this.viewModel.rightView.overrideSaveOnNavigation,
    );
    this.goBack();
  }

  submit = (vm: DualPaneViewModel) => {
    this.submit$.next(this.viewModel.handleSubmission(this.viewModel));
    if (vm.dismissOnSubmit) this.dismiss();
  };

  dismiss = () => {
    if (this.viewModel.overrideDismiss) {
      this.viewModel.overrideDismiss();
    } else {
      this.modalOpenState$.next(false);
    }
  };
}
