import { Component } from '@angular/core';
import { RightPaneComponent } from '../right-pane/right-pane.component';

@Component({
  template: '',
})
export class SearchablePaneComponent extends RightPaneComponent {
  constructor() {
    super();
  }
  resetSearch = () => {
    this.vm.rightView.searchPane.searchKey = '';
    this.vm?.rightView?.searchPane?.searchBehavior.next('');
  };

  handleSearch = () => {
    this.vm?.rightView?.searchPane?.searchBehavior.next(
      this.vm?.rightView?.searchPane?.searchKey ?? '',
    );
  };
}
