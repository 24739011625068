<ng-container
  *ngIf="
    vm.rightView?.searchPane?.searchableObservable$ | async as searchableList
  "
>
  <div class="multiselect-container">
    <ion-label
      [attr.data-cy]="dataCyTag + '-seaarch-title'"
      *ngIf="!!vm.rightView?.searchPane?.searchTitle"
      class="subtitle search-title"
    >
      {{ vm.rightView?.searchPane?.searchTitle | translate }}
    </ion-label>
    <ion-searchbar
      *ngIf="!vm.rightView?.searchPane?.hideSearch"
      [attr.data-cy]="dataCyTag + '-radio-searchbar'"
      class="search"
      mode="md"
      placeholder="{{
        vm.rightView?.searchPane?.searchPlaceholder | translate
      }}"
      [(ngModel)]="vm.rightView.searchPane.searchKey"
      (ionClear)="resetSearch()"
      (ionInput)="handleSearch()"
    >
    </ion-searchbar>
    <div class="no-results-found" *ngIf="searchableList?.length === 0">
      {{ (vm.rightView?.searchPane?.noResultsOverride ?? 'i18n.lists.copyListModal.noResultsFound') | translate}}
    </div>
    <div class="select-all-container">
      <ion-item
        *ngIf="searchableList?.length > 0"
        class="checkbox-item select-all"
      >
        <ion-checkbox
          justify="end"
          [checked]="
            vm?.rightView?.countPane?.secondaryCount ===
            vm?.rightView?.countPane?.primaryCount
          "
          (ionChange)="handleSelectAllChange($event, searchableList)"
          mode="ios"
        >
          {{
            vm?.rightView?.countPane?.primaryCount ===
            vm?.rightView?.countPane?.secondaryCount
              ? ('i18n.lists.copyListModal.deselectAll' | translate)
              : ('i18n.lists.copyListModal.selectAll' | translate)
          }}
        </ion-checkbox>
      </ion-item>
    </div>
    <div class="item-container">
      <ion-item
        *ngFor="let item of searchableList; let i = index"
        class="checkbox-item"
      >
        <div
          *ngIf="
            item?.type === MultiSelectItemsRef.iconBasic ||
            item?.type === MultiSelectItemsRef.iconHeader
          "
          class="circle-badge"
        >
          {{ findFirstLetter(item?.label) }}
        </div>
        <ion-checkbox
          *ngIf="item?.type !== MultiSelectItemsRef.iconHeader"
          value="{{ item?.label }}"
          [checked]="item?.selected"
          (ionChange)="handleCheckboxSelection($event, item)"
          mode="ios"
          justify="space-between"
          labelPlacement="start"
          class="checkbox"
          [attr.data-cy]="dataCyTag + '-radio-checkbox-' + i"
        >
          <div class="label">
            {{ item?.label | titlecase }}
          </div>
        </ion-checkbox>
        <div
          class="checkbox"
          *ngIf="item?.type === MultiSelectItemsRef.iconHeader"
        >
          {{ item?.label | titlecase }}
        </div>
      </ion-item>
    </div>
  </div>
</ng-container>
