import { AngularFrameworkDelegate } from '@ionic/angular/common/providers/angular-delegate';
import { ComponentProps, ComponentRef } from '@ionic/core';

export interface TooltipOptions<T extends ComponentRef = ComponentRef> {
  component: T;
  componentProps?: ComponentProps<T>;
  popoverCssClass?: string[];
  hoverCssClass?: string[];
}

export interface Tooltip {
  present: () => Promise<void>;
  dismiss: () => Promise<void>;
  isOpen: () => boolean;
}

export class TooltipTouch implements Tooltip {
  private touchElement: HTMLIonPopoverElement;
  private _isOpen = false;

  constructor(popover: HTMLIonPopoverElement) {
    this.touchElement = popover;
  }

  async present(): Promise<void> {
    await this.touchElement.present();
    this._isOpen = true;
  }

  async dismiss(): Promise<void> {
    await this.touchElement.dismiss();
    this._isOpen = false;
  }

  isOpen(): boolean {
    return this._isOpen;
  }
}

export class TooltipDesktop implements Tooltip {
  private _desktopElement: any;
  private _delegate: AngularFrameworkDelegate;
  private readonly _container: any;
  private _isOpen = false;
  private _options: TooltipOptions;

  constructor(
    container: any,
    delegate: AngularFrameworkDelegate,
    options: TooltipOptions,
  ) {
    this._delegate = delegate;
    this._container = container;
    this._options = options;
  }

  async present(): Promise<void> {
    this._desktopElement = await this._delegate.attachViewToDom(
      this._container,
      this._options.component,
      this._options.componentProps,
      this._options.hoverCssClass,
    );
    this._isOpen = true;
  }

  async dismiss(): Promise<void> {
    await this._delegate.removeViewFromDom(
      this._container,
      this._desktopElement,
    );
    this._isOpen = false;
  }

  isOpen(): boolean {
    return this._isOpen;
  }
}
