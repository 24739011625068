import { createSelector } from '@ngrx/store';
import { Note } from '@usf/alert-types';
import { noteSelectors, NoteState } from '@usf/ngrx-alerts';
import { Product } from '@usf/product-types';
import { selectedCustomer } from 'src/app/ngrx-customer/store';
import { productWithAlternativesSelector } from 'src/app/shared/selectors/product.selectors';
import { selectUser } from '../../user/store/selectors/user.selectors';

export const selectNotes = createSelector(
  noteSelectors.selectNoteContextState,
  selectedCustomer,
  selectUser,
  productWithAlternativesSelector,
  (
    noteState: NoteState,
    customer,
    user,
    productsMap: Map<number, Product>,
  ): { notes: Note[]; loading: boolean; loaded: boolean } => {
    let notes = (noteState?.ids as Array<string | number>)
      ?.map((id: string | number) => {
        const note = { ...noteState.entities[id] };
        note.read = note?.usersRead?.indexOf(user?.user?.userId) > -1;
        if (productsMap && note?.noteProdNbrs) {
          const products: Product[] = [];
          for (let index = 0; index < note?.noteProdNbrs?.length; index++) {
            const prod = productsMap?.get(note?.noteProdNbrs[index]);
            if (prod) {
              products.push(prod);
            }
          }
          note.products = products;
        }
        return note;
      })
      ?.sort(
        (a, b) => new Date(b.updDate).getTime() - new Date(a.updDate).getTime(),
      );
    if (customer) {
      notes = notes.filter(n => n.custNbr === customer?.customerNumber);
    }
    return {
      notes: notes,
      loading: noteState?.loading,
      loaded: noteState?.loaded,
    };
  },
);
