<ion-header
  class="ion-no-border"
  [ngClass]="
    platformType !== platformEnum.desktop
      ? platformType === platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons *ngIf="platformType === platformEnum.desktop" slot="end">
      <ion-button
        id="close-modal-btn"
        data-cy="close-cost-report-modal-button"
        (click)="dismiss()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label
      *ngIf="platformType !== platformEnum.desktop"
      class="bold"
      data-cy="cost-report-label"
    >
      {{ 'i18n.inventory.downloadCostComparisonReport' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  [ngClass]="
    platformType !== platformEnum.desktop
      ? platformType === platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
  scroll-y="false"
  tabletTouchMove
>
  <div
    class="body-content"
    [class.is-touch]="platformType !== platformEnum.desktop"
    [class.is-mobile]="platformType === platformEnum.mobile"
  >
    <div *ngIf="platformType === platformEnum.desktop" class="title">
      <ion-label class="bold" data-cy="download-cost-report-label">
        {{ 'i18n.inventory.downloadCostComparisonReport' | translate }}
      </ion-label>
    </div>
    <div class="instructions">
      <span class="pro-display">
        {{
          'i18n.inventory.costComparisonReportModal.firstHalfSubTitle'
            | translate
        }}
      </span>
      <span class="bold"
        >{{ 'i18n.common.completed' | translate | lowercase }}
      </span>
      <span class="pro-display">{{
        'i18n.inventory.costComparisonReportModal.secondHalfSubTitle'
          | translate
      }}</span>
    </div>

    <div class="selections">
      <div class="options">
        <!-- Select First Inventory -->
        <app-highlighted-selectable-item
          [label]="
            'i18n.inventory.costComparisonReportModal.selectFirstInventory'
          "
          [value]="firstInventorySubTitle"
          [selected]="
            selectedOption === 'firstSelectedInventory' &&
            platformType !== platformEnum.mobile
          "
          (clickValueEmitter)="changeSelectedOption('firstSelectedInventory')"
          [index]="1"
        ></app-highlighted-selectable-item>
        <!-- Select Second Inventory -->
        <app-highlighted-selectable-item
          [label]="
            'i18n.inventory.costComparisonReportModal.selectSecondInventory'
          "
          [value]="secondInventorySubTitle"
          [selected]="
            selectedOption === 'secondSelectedInventory' &&
            platformType !== platformEnum.mobile
          "
          (clickValueEmitter)="changeSelectedOption('secondSelectedInventory')"
          [index]="2"
        ></app-highlighted-selectable-item>
        <!-- Select Format -->
        <app-highlighted-selectable-item
          [label]="'i18n.common.selectFormat'"
          [value]="selectedFormat"
          [selected]="
            selectedOption === 'selectFormat' &&
            platformType !== platformEnum.mobile
          "
          (clickValueEmitter)="changeSelectedOption('selectFormat')"
          [index]="3"
        ></app-highlighted-selectable-item>
        <!-- Sort By -->
        <app-highlighted-selectable-item
          [label]="'i18n.common.sortBy'"
          [value]="selectedSort"
          [selected]="
            selectedOption === 'sortBy' && platformType !== platformEnum.mobile
          "
          (clickValueEmitter)="changeSelectedOption('sortBy')"
          [index]="4"
        ></app-highlighted-selectable-item>
        <!-- Include -->
        <app-highlighted-selectable-item
          [label]="'i18n.common.include'"
          [value]="productsToInclude"
          [selected]="
            selectedOption === 'productsToInclude' &&
            platformType !== platformEnum.mobile
          "
          (clickValueEmitter)="changeSelectedOption('productsToInclude')"
          [index]="5"
        ></app-highlighted-selectable-item>
        <ion-item *ngIf="glFlag$ | async" class="gl-checkbox">
          <ion-checkbox
            data-cy="include-gl-codes-checkbox"
            [(ngModel)]="includeGlCodes"
            mode="ios"
            justify="space-between"
            labelPlacement="start"
            >{{
              'i18n.inventory.costReportModal.includeGlCodes' | translate
            }}</ion-checkbox
          >
        </ion-item>
      </div>

      <div *ngIf="platformType !== platformEnum.mobile" class="option-content">
        <!-- Select First Inventory -->
        <app-selectable-inventory-list
          *ngIf="selectedOption === 'firstSelectedInventory'"
          [inventories]="firstAvailableInventories"
          [selectedInventory]="firstSelectedInventory"
          (selectInventoryEmitter)="selectFirstInventory($event)"
        >
        </app-selectable-inventory-list>
        <!-- Select Second Inventory -->
        <app-selectable-inventory-list
          *ngIf="selectedOption === 'secondSelectedInventory'"
          [inventories]="secondAvailableInventories"
          [selectedInventory]="secondSelectedInventory"
          (selectInventoryEmitter)="selectSecondInventory($event)"
        >
        </app-selectable-inventory-list>
        <!-- Select Format -->
        <ion-list *ngIf="selectedOption === 'selectFormat'">
          <ion-radio-group [(ngModel)]="selectedFormat">
            <ion-item *ngFor="let format of formatOptions">
              <ion-radio
                labelPlacement="start"
                mode="md"
                [value]="format"
                [attr.data-cy]="'radio-' + format"
                >{{ format | translate }}</ion-radio
              >
            </ion-item>
          </ion-radio-group>
        </ion-list>
        <!-- Select Sort -->
        <ion-list *ngIf="selectedOption === 'sortBy'">
          <ion-radio-group [(ngModel)]="selectedSort">
            <ion-item *ngFor="let sort of sortOptions">
              <ion-radio
                labelPlacement="start"
                mode="md"
                [value]="sort"
                [attr.data-cy]="'radio-' + sort"
                >{{ sort | translate }}</ion-radio
              >
            </ion-item>
          </ion-radio-group>
        </ion-list>
        <!-- Select Include Products -->
        <ion-list *ngIf="selectedOption === 'productsToInclude'">
          <ion-radio-group [(ngModel)]="productsToInclude">
            <ion-item *ngFor="let option of productsToIncludeOptions">
              <ion-radio
                labelPlacement="start"
                mode="md"
                [value]="option"
                [attr.data-cy]="'radio-' + option"
                >{{ option | translate }}</ion-radio
              >
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="platformType !== platformEnum.mobile"
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="cancel-cost-report-modal-button"
        [ngClass]="
          platformType !== platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismiss()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        data-cy="cost-report-modal-button"
        [ngClass]="
          platformType !== platformEnum.mobile
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        [disabled]="!firstSelectedInventory || !secondSelectedInventory"
        (click)="download()"
      >
        {{ 'i18n.common.download' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
