import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenAuthMode, UsfTokenService } from '@panamax/app-state';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor(private tokenService: UsfTokenService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const traceContext = localStorage.getItem('trace-context'); //check on this

    let requestHeaders = {
      'consumer-id': 'ecom',
      'correlation-id': 'ecomr4-' + uuidv4(),
      'transaction-id': Date.now().toString(),
      // 'trace-context': traceContext
    };

    if (req.url.startsWith('https://sdk.split.io/api')) {
      return next.handle(req);
    }

    if (!this.needsCredentials(req.url)) {
      return next.handle(
        req.clone({
          setHeaders: {
            ...requestHeaders,
          },
        }),
      );
    }

    return from(this.tokenService.authorize(TokenAuthMode.Local)).pipe(
      switchMap(token => {
        const newRequest = req.clone({
          setHeaders: {
            ...requestHeaders,
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log('Token: Interceptor adding token', req.url);
        return next.handle(newRequest);
      }),
    );
  }

  //TODO: look into using HttpContextToken for this
  private needsCredentials(url: string): boolean {
    if (
      !url.match('http[s]*://') ||
      url.match(`${environment.powerReviewsUrl}`)
    ) {
      return false;
    }
    if (url.indexOf('/token') > -1) {
      return false;
    }
    if (url.indexOf('/minimumVersions') > -1) {
      return false;
    }
    return true;
  }
}
