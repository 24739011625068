import { Component, Input, OnInit } from '@angular/core';
import { RecentPurchase } from '@usf/list-types';
import { RecentPurchaseHelper } from '../../../helpers/recent-purchase.helpers';
import { RecentPurchaseRecord } from '../../../models/recent-purchase.record';

@Component({
  selector: 'app-recent-purchase-popover',
  templateUrl: './recent-purchase-popover.component.html',
  styleUrls: ['./recent-purchase-popover.component.scss'],
})
export class RecentPurchasePopoverComponent implements OnInit {
  @Input() recentPurchase: RecentPurchase;
  recentPurchaseRecords: RecentPurchaseRecord[] = [];

  constructor() {}

  ngOnInit() {
    this.recentPurchaseRecords = RecentPurchaseHelper.formatRecentPurchase(
      this.recentPurchase,
      'MMM DD',
    );
  }
}
