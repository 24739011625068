import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InventoryState } from '@usf/ngrx-inventory/lib/models/inventory/inventory.model';

@Component({
  selector: 'app-selectable-inventory-list',
  templateUrl: './selectable-inventory-list.component.html',
  styleUrls: ['./selectable-inventory-list.component.scss'],
})
export class SelectableInventoryListComponent implements OnInit {
  @Input() inventories: InventoryState[];
  @Input() selectedInventory: InventoryState;

  @Output()
  selectInventoryEmitter: EventEmitter<InventoryState> = new EventEmitter();

  filteredInventories: InventoryState[];
  searchKey: string;

  constructor() {}

  ngOnInit() {
    this.filteredInventories = this.inventories;
  }

  inventorySelected(inventory: InventoryState) {
    this.selectInventoryEmitter.emit(inventory);
  }

  search() {
    this.filteredInventories = this.inventories.filter(inventory => {
      const inventoryDate = new Date(inventory?.inventoryDate);
      return (
        inventory?.inventoryName
          ?.toUpperCase()
          .includes((this.searchKey || '').toUpperCase()) ||
        inventoryDate
          ?.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
          .includes(this.searchKey || '')
      );
    });
  }

  resetSearch() {
    this.searchKey = '';
    this.filteredInventories = this.inventories;
  }
}
