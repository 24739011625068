import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadingSpinnerComponent } from '../../components/popovers/loading-spinner/loading-spinner.component';

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  loadingSpinnerModal: HTMLIonModalElement;
  interval: any;

  constructor(private modalController: ModalController) {}

  async createSpinnerModal() {
    this.loadingSpinnerModal = await this.modalController.create({
      component: LoadingSpinnerComponent,
      backdropDismiss: false,
      cssClass: 'modal-loading-spinner',
    });
    return this.loadingSpinnerModal?.present();
  }

  async dismissSpinnerModal() {
    return this.loadingSpinnerModal?.dismiss();
  }
}
