// default should never be hit --- blank page
// populate is the true default for the 1st and left slide
// rest of values corolate to various views shown after detail selection.
export enum CreateInventoryModal {
  default = '000',
  populateInventory = '999',
  pastInventory = '110',
  shoppingList = '210',
  pastPurchase = '220',
  manual = '230',
}

export enum InventoryWorksheetMode {
  count = 'count',
  edit = 'edit',
}

export enum InventoriesItemHeights {
  // Inventory Landing (Recent Inventories)
  RECENT_INVENTORIES_HEADER_DESKTOP = 554, // Header (200) + Tiles (232) + Title (61) + Sort Headers (61)
  RECENT_INVENTORIES_HEADER_TABLET = 365, // Tiles (232) + Title/Actions Dropdown (72) + Sort Headers (61)
  RECENT_INVENTORIES_HEADER_MOBILE = 242, // Tiles (130) + Actions Dropdown (72) + Title/Sort Headers (40)
  // All Inventories
  ALL_INVENTORIES_HEADER_DESKTOP = 293, // Header (144) + Search/Create/Completed (88) + Sort Headers (61)
  ALL_INVENTORIES_HEADER_TABLET = 145, // Search/Create/Completed (84) + Sort Headers (61)
  ALL_INVENTORIES_HEADER_MOBILE = 112, // Search (48) + Create/Completed (64)
  // Inventories Cards
  INVENTORIES_CARD_DESKTOP = 76,
  INVENTORIES_CARD_TABLET = 107,
  INVENTORIES_CARD_MOBILE = 135,
  // Inventories Footer
  INVENTORIES_FOOTER_DESKTOP = 375, // Footer (351) + Last Row Extra Padding (24)
  INVENTORIES_FOOTER_TABLET = 24, // Last Row Extra Padding (24)
}

export enum InventoryWorksheetItemHeights {
  // Headers
  INVENTORY_HEADER_DESKTOP = 391.59, // Header (311.59) + Search/Buttons (80)
  INVENTORY_HEADER_TABLET = 291.188, // Header (227.188) + Search/Buttons (64)
  INVENTORY_HEADER_MOBILE = 306.875, // Header (202.875) + Search/Buttons (104)
  // No Search Results
  INVENTORY_NO_SEARCH_RESULTS = 203,
  // Groups
  INVENTORY_GROUP = 60,
  INVENTORY_EMPTY_GROUP_DESKTOP = 160, // Group Name (84) + Empty Product Card (76)
  INVENTORY_EMPTY_GROUP_TOUCH = 152, // Group Name (76) + Empty Product Card (76)
  INVENTORY_GROUP_LABEL = 35,
  INVENTORY_GROUP_LABEL_MINI = 54,
  // Sort
  INVENTORY_SORT_DESKTOP = 49.594,
  // Products
  INVENTORY_COUNT_PRODUCT_DESKTOP = 92,
  INVENTORY_COUNT_PRODUCT_TABLET = 182,
  INVENTORY_COUNT_PRODUCT_MOBILE = 217,
  INVENTORY_EDIT_PRODUCT_DESKTOP_TABLET = 92,
  INVENTORY_EDIT_PRODUCT_MOBILE = 130,
  // Footer
  INVENTORY_FOOTER_DESKTOP = 391.5,
}

export enum InventoryActions {
  moveToGroup = 'moveToGroup',
  copyToGroup = 'copyToGroup',
  changeUnits = 'changeUnits',
  addToGroup = 'addToGroup',
  delete = 'delete',
  deleteGroup = 'deleteGroup',
  deleteGroupAndMove = 'deleteGroupAndMove',
  createCustomUOM = 'createCustomUOM',
  createNONUSF = 'createNONUSF',
}

export enum AddToInventoryStrings {
  // Add to Inventory Options
  chooseInventory = 'Choose Inventory',
  chooseGroup = 'Choose Group',
  choosePosition = 'Choose Position',
  // Group Positions
  top = 'TOP',
  bottom = 'BOTTOM',
}

export enum InventoryToastMessages {
  copyToGroupFail = 'i18n.inventory.copyProductsFailure',
  copyToGroupSuccess = 'i18n.inventory.copyProductsSuccess',
  copyToGroupDuplicates = 'i18n.inventory.productAlreadyExists',
  moveToGroupFail = 'i18n.inventory.moveProductsFailure',
  moveToGroupSuccess = 'i18n.inventory.moveProductsSuccess',
  changeUnitsFail = 'i18n.inventory.changeUnitsFailure',
  changeUnitsSuccess = 'i18n.inventory.changeUnitsSuccess',
  delete = 'delete',
  addToGroupFail = 'i18n.inventory.addToGroupFailure',
  addToGroupSuccess = 'i18n.inventory.addToGroupSuccess',
  changeUnits = 'changeUnits',
  deleteGroupSuccess = 'i18n.inventory.deleteGroupSuccess',
  deleteGroupFail = 'i18n.inventory.deleteGroupFail',
  deleteGroupAndMoveSuccess = 'i18n.inventory.deleteGroupAndMoveSuccess',
  deleteGroupAndMoveFail = 'i18n.inventory.deleteGroupFail',
  deleteSuccess = 'i18n.inventory.deleteProductSuccess',
  deleteFail = 'i18n.inventory.deleteProductFailure',
  createCustomUOMSuccess = 'i18n.inventory.customUOMSuccess',
  createCustomUOMFail = 'i18n.inventory.customUOMFailure',
  createNONUSFSuccess = 'i18n.inventory.addToGroupNonUSFSuccess',
  createNONUSFFail = 'i18n.inventory.addToGroupNonUSFFailure',
  moveItemDuplicateFailure = 'i18n.inventory.moveItemDuplicateFailure',
}

export enum InventoryEllipsisModalModes {
  delete = 'delete',
  complete = 'complete',
  viewSummary = 'viewSummary',
}

export enum ChangeUnitsOptions {
  default = 'Select',
  each = 'EA',
  pound = 'LB',
  ounce = 'OZ',
  grams = 'G',
  gallon = 'GA',
  batch = 'Batch',
  piece = 'Piece',
  custom = 'Custom',
}

export const InventoryExtendedReportCalendarLimit = 730;
export const InventoryExtendedReportCalendarRange = 6;
export const CompleteInventoryConflictErrorMessage =
  'invalid edit inventory request - inventory has been completed';
