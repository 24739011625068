import { Component, Input } from '@angular/core';
import {
  ActionSheetController,
  ModalController,
  PopoverController,
  ViewWillEnter,
} from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DownloadRequestListType } from '@usf/list-types';
import { PrintListService } from '@shared/services/document/print-list/print-list.service';
import { TranslateService } from '@ngx-translate/core';
import { EllipsisOptionsPopoverComponent } from '../../../shared/components/popovers/ellipsis-options/ellipsis-options-popover.component';

@Component({
  selector: 'app-print-list',
  templateUrl: './print-list.component.html',
  styleUrls: ['./print-list.component.scss'],
})
export class PrintListComponent implements ViewWillEnter {
  @Input() listId: number;
  @Input() listTypeId: string;
  @Input() listName: string;
  @Input() customerNumber: number;
  @Input() listState: string;

  printListForm: FormGroup;
  isPriceOptionsPopoverOpen: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private popoverController: PopoverController,
    readonly platformService: PlatformService,
    private printListService: PrintListService,
    private translateService: TranslateService,
  ) {}

  ionViewWillEnter(): void {
    this.isPriceOptionsPopoverOpen = false;
    this.printListForm = this.formBuilder.group({
      priceOptions: new FormControl(
        this.translateService.instant('i18n.lists.hideProductPrices'),
        Validators.compose([Validators.required]),
      ),
    });
  }

  isValid() {
    return this.printListForm ? this.printListForm.valid : false;
  }

  onPrintList() {
    const formValues = this.printListForm.value;
    const fileName = this.printListService.buildDefaultFileName(
      this.listName,
      this.customerNumber,
    );
    this.printListService.printList(
      this.listId,
      formValues?.priceOptions ===
        this.translateService.instant('i18n.lists.hideProductPrices')
        ? true
        : false,
      this.listTypeId as DownloadRequestListType,
      'PDF',
      fileName,
      'i18n.lists.printListError',
      this.listState,
    );
    this.dismissModal();
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }

  async openPricingOptions(event: Event) {
    event.stopPropagation();
    const buttons: any[] = [
      {
        text: this.translateService.instant('i18n.lists.includeProductPrices'),
        handler: () => {
          this.printListForm.controls['priceOptions'].setValue(
            this.translateService.instant('i18n.lists.includeProductPrices'),
          );
          this.dismissPricingOptions();
        },
      },
      {
        text: this.translateService.instant('i18n.lists.hideProductPrices'),
        handler: () => {
          this.printListForm.controls['priceOptions'].setValue(
            this.translateService.instant('i18n.lists.hideProductPrices'),
          );
          this.dismissPricingOptions();
        },
      },
    ];

    if (!this.platformService.isTouch.value) {
      this.openPriceOptionsDesktop(event, buttons);
    } else {
      this.openPriceOptionsTouch(buttons);
    }
  }

  async openPriceOptionsDesktop(event: Event, buttons: any[]) {
    this.isPriceOptionsPopoverOpen = true;

    const popover = await this.popoverController.create({
      component: EllipsisOptionsPopoverComponent,
      componentProps: {
        buttons,
      },
      showBackdrop: false,
      cssClass: 'download-pricing-options-popover-desktop',
      event,
      mode: 'ios',
      alignment: 'center',
    });
    popover.onDidDismiss().then(() => {
      this.isPriceOptionsPopoverOpen = false;
    });
    await popover.present();
  }

  async openPriceOptionsTouch(buttons: any[]) {
    buttons.push({
      text: this.translateService.instant('i18n.common.cancel'),
      role: 'cancel',
      cssClass: 'cancel-button',
    });
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'action-sheet-touch',
      mode: 'ios',
      buttons,
    });
    await actionSheet.present();
  }

  async dismissPricingOptions() {
    this.platformService.isTouch.value
      ? this.actionSheetController.dismiss()
      : this.popoverController.dismiss();
  }
}
