import { Pipe, PipeTransform } from '@angular/core';

const acronyms = ['usda'];
@Pipe({
  name: 'acronymTitleCase',
})
export class AcronymTitleCasePipe implements PipeTransform {
  private titleCase(word: string): string {
    const specialCharactersRegEx = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const beginWithSpecialCharacter = word
      .charAt(0)
      .match(specialCharactersRegEx);
    const position = beginWithSpecialCharacter ? 1 : 0;
    return (
      (beginWithSpecialCharacter ? word.charAt(0) : '') +
      word.charAt(position).toUpperCase() +
      word.slice(position + 1).toLowerCase()
    );
  }

  transform(value?: string): string {
    if (!value) {
      return;
    }
    return value
      .split(' ')
      .map(word =>
        acronyms.find(acronym => acronym.toLowerCase() === word.toLowerCase())
          ? word.toUpperCase()
          : this.titleCase(word),
      )
      .join(' ');
  }
}
