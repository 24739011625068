import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ServiceHandlerService } from './service-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ClaimTicketService {
  constructor(private serviceHandler: ServiceHandlerService) {}

  getClaimTicket(): Observable<any> {
    return this.serviceHandler.get(`${environment.claimTicketApiUrl}`)?.pipe(
      take(1),
      filter(res => !!res),
      catchError(error => throwError(error)),
    );
  }
}
