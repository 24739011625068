import { PlatformEnum } from '@panamax/app-state';
import { ItemTypes } from '../../../lists/shared/list-detail-management/model/list-detail-management-view.model';
import {
  InventoriesItemHeights,
  InventoryWorksheetItemHeights,
} from '../../constants/inventory-constants';
import {
  InventoryGroupViewModel,
  InventoryItem,
  InventoryViewModel,
} from '../../models/inventory-view.model';

// Inventory Landing (Recent Inventories)
export const calcRecentInventoriesItemHeights = (
  items: InventoryViewModel[],
  platform: PlatformEnum,
) => {
  let itemHeights: number[] = [];
  switch (platform) {
    case PlatformEnum.desktop:
      itemHeights = items.map(() => {
        return InventoriesItemHeights.INVENTORIES_CARD_DESKTOP;
      });
      itemHeights.push(InventoriesItemHeights.INVENTORIES_FOOTER_DESKTOP);
      itemHeights.unshift(
        InventoriesItemHeights.RECENT_INVENTORIES_HEADER_DESKTOP,
      );
      break;
    case PlatformEnum.tablet:
      itemHeights = items.map(() => {
        return InventoriesItemHeights.INVENTORIES_CARD_TABLET;
      });
      itemHeights.push(InventoriesItemHeights.INVENTORIES_FOOTER_TABLET);
      itemHeights.unshift(
        InventoriesItemHeights.RECENT_INVENTORIES_HEADER_TABLET,
      );
      break;
    case PlatformEnum.mobile:
      itemHeights = items.map(() => {
        return InventoriesItemHeights.INVENTORIES_CARD_MOBILE;
      });
      itemHeights.unshift(
        InventoriesItemHeights.RECENT_INVENTORIES_HEADER_MOBILE,
      );
      break;
  }
  return itemHeights;
};

// All Inventories
export const calcAllInventoriesItemHeights = (
  items: InventoryViewModel[],
  platform: PlatformEnum,
) => {
  let itemHeights: number[] = [];
  switch (platform) {
    case PlatformEnum.desktop:
      itemHeights = items.map(() => {
        return InventoriesItemHeights.INVENTORIES_CARD_DESKTOP;
      });
      itemHeights.push(InventoriesItemHeights.INVENTORIES_FOOTER_DESKTOP);
      itemHeights.unshift(
        InventoriesItemHeights.ALL_INVENTORIES_HEADER_DESKTOP,
      );
      break;
    case PlatformEnum.tablet:
      itemHeights = items.map(() => {
        return InventoriesItemHeights.INVENTORIES_CARD_TABLET;
      });
      itemHeights.push(InventoriesItemHeights.INVENTORIES_FOOTER_TABLET);
      itemHeights.unshift(InventoriesItemHeights.ALL_INVENTORIES_HEADER_TABLET);
      break;
    case PlatformEnum.mobile:
      itemHeights = items.map(() => {
        return InventoriesItemHeights.INVENTORIES_CARD_MOBILE;
      });
      itemHeights.unshift(InventoriesItemHeights.ALL_INVENTORIES_HEADER_MOBILE);
      break;
  }
  return itemHeights;
};

// Inventory Worksheet
export const calcInventoryWorksheetItemHeights = (
  items: InventoryItem[],
  platform: PlatformEnum,
  isCount: boolean = true,
) => {
  let itemHeights: number[] = [];
  itemHeights = addHeaderToItemHeights(itemHeights, platform);

  if (!items.length) {
    itemHeights = addNoSearchResultsToItemHeights(itemHeights, platform);
  }

  items.forEach(item => {
    if (item.itemType === ItemTypes.group) {
      if ((item as InventoryGroupViewModel).hasProducts) {
        itemHeights = addGroupToItemHeights(itemHeights, platform);
      } else {
        itemHeights = addEmptyGroupToItemHeights(itemHeights, platform);
      }
    } else if (item.itemType === ItemTypes.sort) {
      itemHeights = addSortToItemHeights(itemHeights, platform);
    } else if (item.itemType === ItemTypes.product) {
      itemHeights = addProductToItemHeights(itemHeights, platform, isCount);
    } else if (item.itemType === ItemTypes.justALabel) {
      // devices smaller than 950 will need more height for labels
      itemHeights.push(
        window.innerWidth > 950
          ? InventoryWorksheetItemHeights.INVENTORY_GROUP_LABEL
          : InventoryWorksheetItemHeights.INVENTORY_GROUP_LABEL_MINI,
      );
    }
  });

  if (platform === PlatformEnum.desktop) {
    itemHeights.push(InventoryWorksheetItemHeights.INVENTORY_FOOTER_DESKTOP);
  }
  return itemHeights;
};

export const addHeaderToItemHeights = (
  itemHeights: number[],
  platform: PlatformEnum,
) => {
  switch (platform) {
    case PlatformEnum.desktop:
      itemHeights.push(InventoryWorksheetItemHeights.INVENTORY_HEADER_DESKTOP);
      break;
    case PlatformEnum.tablet:
      itemHeights.push(InventoryWorksheetItemHeights.INVENTORY_HEADER_TABLET);
      break;
    case PlatformEnum.mobile:
      itemHeights.push(InventoryWorksheetItemHeights.INVENTORY_HEADER_MOBILE);
      break;
  }
  return itemHeights;
};

export const addNoSearchResultsToItemHeights = (
  itemHeights: number[],
  platform: PlatformEnum,
) => {
  switch (platform) {
    case PlatformEnum.desktop:
    case PlatformEnum.tablet:
    case PlatformEnum.mobile:
      itemHeights.push(
        InventoryWorksheetItemHeights.INVENTORY_NO_SEARCH_RESULTS,
      );
      break;
  }
  return itemHeights;
};

export const addGroupToItemHeights = (
  itemHeights: number[],
  platform: PlatformEnum,
) => {
  switch (platform) {
    case PlatformEnum.desktop:
    case PlatformEnum.tablet:
    case PlatformEnum.mobile:
      itemHeights.push(InventoryWorksheetItemHeights.INVENTORY_GROUP);
      break;
  }
  return itemHeights;
};

export const addEmptyGroupToItemHeights = (
  itemHeights: number[],
  platform: PlatformEnum,
) => {
  switch (platform) {
    case PlatformEnum.desktop:
      itemHeights.push(
        InventoryWorksheetItemHeights.INVENTORY_EMPTY_GROUP_DESKTOP,
      );
      break;
    case PlatformEnum.tablet:
    case PlatformEnum.mobile:
      itemHeights.push(
        InventoryWorksheetItemHeights.INVENTORY_EMPTY_GROUP_TOUCH,
      );
      break;
  }
  return itemHeights;
};

export const addSortToItemHeights = (
  itemHeights: number[],
  platform: PlatformEnum,
) => {
  switch (platform) {
    case PlatformEnum.desktop:
      itemHeights.push(InventoryWorksheetItemHeights.INVENTORY_SORT_DESKTOP);
      break;
  }

  return itemHeights;
};

export const addProductToItemHeights = (
  itemHeights: number[],
  platform: PlatformEnum,
  isCount: boolean,
) => {
  switch (platform) {
    case PlatformEnum.desktop:
      itemHeights.push(
        isCount
          ? InventoryWorksheetItemHeights.INVENTORY_COUNT_PRODUCT_DESKTOP
          : InventoryWorksheetItemHeights.INVENTORY_EDIT_PRODUCT_DESKTOP_TABLET,
      );
      break;
    case PlatformEnum.tablet:
      itemHeights.push(
        isCount
          ? InventoryWorksheetItemHeights.INVENTORY_COUNT_PRODUCT_TABLET
          : InventoryWorksheetItemHeights.INVENTORY_EDIT_PRODUCT_DESKTOP_TABLET,
      );
      break;
    case PlatformEnum.mobile:
      itemHeights.push(
        isCount
          ? InventoryWorksheetItemHeights.INVENTORY_COUNT_PRODUCT_MOBILE
          : InventoryWorksheetItemHeights.INVENTORY_EDIT_PRODUCT_MOBILE,
      );
      break;
  }
  return itemHeights;
};
