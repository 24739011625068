import { Component } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { ListDetailManagementService } from '../../../../lists/shared/list-detail-management/services/list-detail-management.service';
import { PlatformService } from '@panamax/app-state';
import { AddToListBaseComponent } from '../add-to-list-base.component';

/**
 * @deprecated
 */
@Component({
  selector: 'app-add-to-list-desktop-tablet',
  templateUrl: './add-to-list-desktop-tablet.component.html',
  styleUrls: ['./add-to-list-desktop-tablet.component.scss'],
})
export class AddToListDesktopTabletComponent extends AddToListBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    listDetailManagementService: ListDetailManagementService,
  ) {
    super(nav, modalController, platformService, listDetailManagementService);
  }
}
