export enum NonUsfProductHeights {
  NON_USF_PRODUCT_HEADER_DESKTOP = 203,
  NON_USF_PRODUCT_ITEM_DESKTOP = 76,
  NON_USF_PRODUCT_FOOTER_DESKTOP = 371,

  NON_USF_PRODUCT_HEADER_TABLET = 203,
  NON_USF_PRODUCT_ITEM_TABLET = 76,
  NON_USF_PRODUCT_FOOTER_TABLET = 371,

  //167px for header + 84px for search bar and button
  NON_USF_PRODUCT_HEADER_MOBILE = 252,
  NON_USF_PRODUCT_ITEM_MOBILE = 123,
  NON_USF_PRODUCT_FOOTER_MOBILE = 0,
}
