import { Component } from '@angular/core';

@Component({
  selector: 'app-catch-weight-popover',
  templateUrl: './catch-weight-popover.component.html',
  styleUrls: ['./catch-weight-popover.component.scss'],
})
export class CatchWeightPopoverComponent {
  constructor() {}
}
