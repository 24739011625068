import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from '@shared/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import {
  PRODUCT_PRICING_ACTIONS,
  SELLER_PRICING_ACTIONS,
} from '@usf/ngrx-pricing';
import { debounceTime, tap } from 'rxjs/operators';
import { MessageTypeEnum } from '@app/ngrx-message/constants/messageTypeEnum';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PriceNotificationEffects {
  priceRequestFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PRODUCT_PRICING_ACTIONS.fetchProductPricingFail,
          SELLER_PRICING_ACTIONS.loadSellerPricingFail,
        ),
        debounceTime(500),
        tap(action => {
          this.toastService.presentToastMsg(
            this.translateService.instant('i18n.common.pricingUnavailable'),
            'red-toast',
            MessageTypeEnum.error,
            [],
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}
}
