import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'optionalAsync$',
})
export class OptionalAsyncPipe implements PipeTransform {
  transform(
    value$: Observable<any> | undefined,
    defaultValue: any,
  ): Observable<any> {
    return value$ ? value$ : of(defaultValue);
  }
}
