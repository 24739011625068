import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PunchoutReturnLinkData } from '../models/punchout-return-link-data.model';
import { selectPunchoutReturnLinkData } from '../selectors/punchout.selector';

@Injectable({
  providedIn: 'root',
})
export class PunchoutService {
  constructor(private store: Store) {}

  punchoutReturnLinkData$(): Observable<PunchoutReturnLinkData> {
    return this.store.select(selectPunchoutReturnLinkData);
  }
}
