import { createSelector } from '@ngrx/store';
import { productWithAlternativesSelector } from '@shared/selectors/product.selectors';
import { selectSneakPeekState } from '@usf/ngrx-product';
import { SneakPeekProduct } from '../models/sneak-peek-product-view.model';

export const selectSneakPeeks = createSelector(
  selectSneakPeekState,
  productWithAlternativesSelector,
  (sneakPeekState, products): SneakPeekProduct[] => {
    const sneakPeekProducts: SneakPeekProduct[] = [];

    if (
      sneakPeekState?.sneakPeeks?.every(
        sneakPeek => products.get(sneakPeek?.apn) !== undefined,
      )
    ) {
      for (const sneakPeek of sneakPeekState?.sneakPeeks) {
        const product = products?.get(sneakPeek?.apn);

        if (product?.notFound === false) {
          sneakPeekProducts.push({
            ...product,
            isInd: sneakPeek?.ind?.toLowerCase() === 'yes',
            isPremier: sneakPeek?.premier?.toLowerCase() === 'yes',
            isVizient: sneakPeek?.vizient?.toLowerCase() === 'yes',
            isAvendra: sneakPeek?.avendra?.toLowerCase() === 'yes',
          });
        }
      }
    }

    return sneakPeekProducts;
  },
);
