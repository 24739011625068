import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { CustomerDataService } from '../../services/customer/customer-data.service';
import * as DivisionActions from '../actions/division.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { Division } from '@usf/customer-types/division';

@Injectable({
  providedIn: 'root',
})
export class DivisionEffects {
  division$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DivisionActions.getDivisionList),
      concatMap(action =>
        this.customerDataService
          .get<Division>(`divisions`)
          .pipe(
            map(
              divisions  => {
                return DivisionActions.getDivisionSuccess({divisions});
              },
              catchError((error: HttpErrorResponse) =>
                of(DivisionActions.getDivisionFail({ error })),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private customerDataService: CustomerDataService<any>,
  ) {}
}
