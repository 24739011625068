import { Component, Input } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { PlatformEnum } from '@panamax/app-state';

@Component({
  selector: 'app-radio-btn-list',
  templateUrl: './radio-btn-list.component.html',
  styleUrls: ['./radio-btn-list.component.scss'],
})
export class RadioBtnListComponent {
  @Input() label: string;
  @Input() listOfSelectableLabels: string[];
  @Input() callBack: Function;
  @Input() selectedLabel: string;
  @Input() platformType: PlatformEnum;

  readonly platformEnum = PlatformEnum;

  constructor(private ionNav: IonNav) {}

  goBack() {
    this.ionNav.pop();
  }

  save() {
    this.callBack(this.selectedLabel);
    this.ionNav.pop();
  }
}
