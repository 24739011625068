import { CommonModule } from '@angular/common';
import {
  APP_INITIALIZER,
  Inject,
  ModuleWithProviders,
  NgModule,
  Optional,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PanAppState } from '@panamax/app-state';
import { WebSocketService } from '@panamax/websocket';
import { Subscription } from 'rxjs';
import { SharedModule } from '../shared/shared.module';
import {
  NgRxUserLibConfig,
  NGRX_USER_LIB_CONFIG,
} from './models/websocket.model';
import * as fromServices from './services';
import { UserWebSocketService } from './services/websocket.service';
import * as fromUserStore from './store';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    SharedModule,
    EffectsModule.forFeature([fromUserStore.UserEffects]),
  ],
  providers: [...fromServices.services],
})
export class UserModule {
  subscripions: Subscription[] = [];
  constructor(
    @Inject(NGRX_USER_LIB_CONFIG)
    private ngRxUserLibConfig: NgRxUserLibConfig,
    private store: Store<any>,
    private panAppState: PanAppState,
    @Optional() private webSocketService?: WebSocketService,
  ) {}

  static forRoot(
    ngRxUserLibConfig: NgRxUserLibConfig,
    store?: any,
    panAppState?: PanAppState,
    webSocketService?: WebSocketService,
  ): ModuleWithProviders<UserModule> {
    return {
      ngModule: UserModule,
      providers: [
        {
          provide: NGRX_USER_LIB_CONFIG,
          useValue: ngRxUserLibConfig,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: initializeWebSocket,
          deps: [UserWebSocketService],
          multi: true,
        },
      ],
    };
  }
}

export function initializeWebSocket(
  webSocketService: UserWebSocketService,
): any {
  const websocket = () => webSocketService.initialize();
  return websocket;
}
