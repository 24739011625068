import { Product } from '@shared/models/product.model';
import {
  getAvailableCases,
  getAvailableEaches,
  productHasStatus,
} from '@usf/ngrx-product';
import { ProductPropertiesEnum } from '@usf/product-types/Product-Summary';
import { JitConfigMessage } from '../models/jit-config-message.model';

export const getDisplayVendorMessage = (product: Product): string => {
  if (
    !!product?.inventory &&
    product?.summary?.properties?.has(ProductPropertiesEnum.ces)
  ) {
    return product.inventory.vendorMessage
      ? product.inventory.vendorMessage
      : `Shipped and delivered separately. Expected lead time ${
          product.inventory.vendorLeadTime
        } ${product.inventory.vendorLeadTime === 1 ? 'day' : 'days'}.`;
  }
  return '';
};

export const getCESMessageLong = (product: Product): string => {
  return product.inventory.vendorMessage
    ? product.inventory.vendorMessage
    : `Shipped and delivered separately. Expected lead time ${
        product.inventory.vendorLeadTime
      } ${product.inventory.vendorLeadTime === 1 ? 'day' : 'days'}.`;
};

export const getCESMessageShort = (product: Product): string => {
  return product.inventory.vendorMessage
    ? product.inventory.vendorMessage
    : `Delivered separately. Exp. lead time ${
        product.inventory.vendorLeadTime
      } ${product.inventory.vendorLeadTime === 1 ? 'day' : 'days'}.`;
};

export const getJITMessage = (product: Product): string => {
  return product.inventory.vendorMessage
    ? `Early cutoff: ${product.inventory.vendorMessage}`
    : 'Early cutoff: This product must be ordered by a specific time. Contact your local division for cutoff schedule.';
};

export const getDirectMessage = (
  product: Product,
  directAllowReturns: boolean,
): string => {
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + product.inventory.vendorLeadTime);

  const returnsMsg = !directAllowReturns
    ? 'No returns or cancellations.'
    : product?.summary?.returnable !== 'Y'
      ? 'Item not returnable.'
      : '';

  if (!isNaN(futureDate.getTime())) {
    const dd = String(futureDate.getDate()).padStart(2, '0');
    const mm = String(futureDate.getMonth() + 1).padStart(2, '0');
    return `Order today, estimated delivery ${mm}/${dd}. ${returnsMsg}`;
  } else {
    return `Order today, estimated delivery Invalid Date. ${returnsMsg}`;
  }
};

export const getJITConfig = (product: Product): JitConfigMessage => {
  const { vendorMessage } = product?.inventory;
  const showJITMessage =
    product.summary?.properties?.has(ProductPropertiesEnum.jit) &&
    !(
      product?.summary?.properties?.has(ProductPropertiesEnum.ces) ||
      product?.summary?.properties?.has(ProductPropertiesEnum.direct) ||
      product?.summary?.properties?.has(ProductPropertiesEnum.specialOrder)
    );
  return {
    showMessage: showJITMessage,
    message:
      vendorMessage ||
      'Early cutoff: This product must be ordered by a specific time. Contact your local division for cutoff schedule.',
    default: !vendorMessage,
  };
};

export const productIsInStatus = (statusList: string[], product: Product) =>
  statusList.some(status => productHasStatus(status, product?.inventory));

export const productNonFoodClassDescriptions = new Set([
  'EQUIPMENT & SUPPLIES',
  'ADMINISTRATIVE',
  'DISPOSABLES',
  'CHEMICALS & CLEANING AGENTS',
]);

export const isBreakableAndInStock = (product: Product) => {
  return (
    // Any status 1 product (direct, CES, special order) should not validate if it is breakable and in stock and keep current logic
    !productHasStatus('1', product.inventory) &&
    product.summary?.breakable &&
    !product?.alternative?.prioritizeAlternative &&
    (getAvailableCases(product?.inventory) > 0 ||
      getAvailableEaches(product?.inventory) > 0)
  );
};
