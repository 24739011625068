import { NgRxProductLibConfig } from '@usf/ngrx-product';
import { environment } from 'src/environments/environment';

export const NG_RX_PRODUCT_LIB_CONFIG: NgRxProductLibConfig = {
  apiUrl: environment.productApiUrl,
  apiBaseUrl: environment.productApiBaseUrl,
  webSocketUrl: environment.webSocketUrl,
  powerReviewMerchantId: environment.powerReview.merchantId,
  powerReviewLocale: environment.powerReview.locale,
  powerReviewApiKey: environment.powerReview.apiKey,
  powerReviewApiUrl: environment.powerReviewsUrl,
  prefetchedMerchandising: environment.prefetchedMerchandising,
};
