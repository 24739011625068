<ion-content
  class="invite-new-user-content"
  [ngClass]="{
    'ion-content-modal-tablet':
      platform.platformType === platform?.platformEnum?.tablet,
    'ion-content-modal-desktop':
      platform.platformType === platform?.platformEnum?.desktop,
  }"
>
  <ion-grid>
    <ion-row>
      <!-- Options Column -->
      <ion-col
        size-xl="5.5"
        size-lg="5.5"
        size-md="5.5"
        size-sm="12"
        size-xs="12"
        [ngClass]="{
          hidden:
            platform.platformType === platform?.platformEnum?.mobile &&
            selectedOption !== '',
        }"
        class="invite-new-user-options"
      >
        <ion-item
          *ngFor="let section of SECTIONS"
          [attr.data-cy]="'invite-new-user-' + section + '-option'"
          [class.selected]="selectedOption === section"
          detail
          [disabled]="section === 'customers' && !inviteNewUserForm.valid"
          (click)="setSelectedOption(section)"
        >
          <ion-label>
            {{ 'i18n.profilePage.inviteUser.' + section | translate }}
            <span
              class="selected-user"
              *ngIf="section === 'userInformation' && inviteNewUserForm.valid"
              [attr.data-cy]="'invite-new-user-' + section + '-selected'"
            >
              {{ getSectionValue('newUserEmail', false) }}
            </span>
            <span
              class="selected-user"
              *ngIf="section === 'userInformation' && !inviteNewUserForm.valid"
              [attr.data-cy]="'invite-new-user-' + section + '-not-selected'"
            >
              {{ 'i18n.profilePage.inviteNewUser.invalidEmail' | translate }}
            </span>
            <span
              class="selected-user"
              *ngIf="section === 'customers' && isCustomersSelected()"
              [attr.data-cy]="'invite-new-user-' + section + '-selected'"
            >
              {{ getSelectedCustomersLabel() }}
            </span>
          </ion-label>
        </ion-item>
        <ion-accordion-group
          class="accordion-advanced-options"
          *ngIf="advancedOptionsFlag$ | async"
          (ionChange)="advancedOptionsOpened = !advancedOptionsOpened"
        >
          <ion-accordion value="invite-new-user-advanced-options">
            <ion-item slot="header" class="advanced-options-header">
              <ion-label class="advanced-options-header-title">{{
                'i18n.profilePage.inviteNewUser.advancedOptions.title'
                  | translate
              }}</ion-label>
              <ion-label class="advanced-options-header-label">
                {{
                  advancedOptionsOpened
                    ? ('i18n.common.viewLess' | translate)
                    : ('i18n.common.viewMore' | translate)
                }}
              </ion-label>
            </ion-item>
            <div slot="content">
              <ion-item
                *ngFor="let option of ADV_OPTIONS"
                [attr.data-cy]="'invite-new-user-' + option + '-option'"
                [class.selected]="selectedOption === option"
                detail
                (click)="setSelectedOption(option)"
              >
                <ion-label>
                  {{
                    'i18n.profilePage.inviteNewUser.advancedOptions.' + option
                      | translate
                  }}
                  <span
                    class="selected-user"
                    *ngIf="option === 'organization'"
                    [attr.data-cy]="'invite-new-user-' + option + '-selected'"
                  >
                    {{ this.newUserAdvOptions.organization.savedValue }}
                  </span>
                  <span
                    class="selected-user"
                    *ngIf="option === 'siteRole'"
                    [attr.data-cy]="'invite-new-user-' + option + '-selected'"
                  >
                    {{
                      'i18n.profilePage.inviteNewUser.advancedOptions.' +
                        getSectionValue('siteRole', true) | translate
                    }}
                  </span>
                  <span
                    class="selected-user"
                    *ngIf="
                      option === 'userAccessOptions' &&
                      isUserAccessOptionsSelected()
                    "
                    [attr.data-cy]="'invite-new-user-' + option + '-selected'"
                  >
                    {{ getUserAccessOptionsLabel() }}
                  </span>
                  <span
                    class="selected-user"
                    *ngIf="
                      option === 'nationalCustomization' &&
                      nationalCustomerSelected
                    "
                    [attr.data-cy]="'invite-new-user-' + option + '-selected'"
                  >
                    {{ getSectionValue('nationalCustomization', true) }}
                  </span>
                  <span
                    class="selected-user"
                    *ngIf="
                      option === 'nationalCustomization' &&
                      !nationalCustomerSelected
                    "
                    [attr.data-cy]="'invite-new-user-' + option + '-selected'"
                  >
                    {{
                      'i18n.profilePage.inviteNewUser.advancedOptions.selectANationalCustomer'
                        | translate
                    }}
                  </span>
                </ion-label>
              </ion-item>
              <ion-item
                [attr.data-cy]="'invite-new-user-national-customization-option'"
                [class.selected]="selectedOption === 'nationalCustomization'"
                detail
                [disabled]="!nationalCustomerSelected"
                (click)="setSelectedOption('nationalCustomization')"
              >
                <ion-label>
                  {{
                    'i18n.profilePage.inviteNewUser.advancedOptions.nationalCustomization'
                      | translate
                  }}
                  <span
                    class="selected-user"
                    *ngIf="nationalCustomerSelected"
                    [attr.data-cy]="
                      'invite-new-user-national-customization-option-selected'
                    "
                  >
                    {{ getSectionValue('nationalCustomization', true) }}
                  </span>
                  <span
                    class="selected-user"
                    *ngIf="!nationalCustomerSelected"
                    [attr.data-cy]="
                      'invite-new-user-national-customization-option-selected'
                    "
                  >
                    {{
                      'i18n.profilePage.inviteNewUser.advancedOptions.selectANationalCustomer'
                        | translate
                    }}
                  </span>
                </ion-label>
              </ion-item>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-col>

      <!-- User Information Column -->
      <ion-col
        size-xl="5.5"
        size-lg="5.5"
        size-md="5.5"
        size-sm="12"
        size-xs="12"
        offset-xl="1"
        offset-lg="1"
        offset-md="1"
        offset-sm="0"
        offset-xs="0"
        [ngClass]="{
          hidden:
            platform.platformType === platform?.platformEnum?.mobile &&
            selectedOption === '',
        }"
        class="invite-new-user-input"
      >
        <!-- Users -->
        <div
          *ngIf="selectedOption === 'userInformation'"
          class="user-info-container"
        >
          <ion-label class="select-user-label">{{
            'i18n.profilePage.inviteNewUser.newModalDescription' | translate
          }}</ion-label>
          <p class="input-title">
            <ion-label class="select-user-label">{{
              'i18n.profilePage.inviteNewUser.modalNewUsersEmail' | translate
            }}</ion-label>
          </p>
          <form [formGroup]="inviteNewUserForm" class="invite-new-user-form">
            <ion-item
              [ngClass]="
                !inviteNewUserForm?.valid &&
                getSectionValue('newUserEmail', false)?.length > 0
                  ? 'invalid-input'
                  : ''
              "
            >
              <ion-input
                aria-label="invite-new-user"
                data-cy="invite-new-user-input"
                placeholder="name@example.com"
                type="email"
                maxlength="80"
                formControlName="newUserEmailAddress"
                (ionInput)="onInviteNewUser()"
              ></ion-input>
            </ion-item>
            <span
              *ngIf="
                !inviteNewUserForm?.valid &&
                getSectionValue('newUserEmail', false)?.length > 0
              "
              class="max-customers-reached"
            >
              {{ 'i18n.profilePage.inviteNewUser.invalidEmail' | translate }}
            </span>
          </form>
        </div>
        <!-- Customers -->
        <div *ngIf="selectedOption === 'customers'" class="user-info-container">
          <ion-label class="select-user-label">{{
            'i18n.profilePage.inviteNewUser.selectCustomers' | translate
          }}</ion-label>
          <ion-searchbar
            aria-label="customer-searchbar"
            data-cy="customers-searchbar"
            class="search"
            mode="md"
            inputmode="search"
            enterkeyhint="search"
            placeholder="{{ 'i18n.common.searchCustomers' | translate }}"
            (ionBlur)="searchCustomer($event)"
            (ionClear)="resetSearch()"
            (ionInput)="searchCustomer($event)"
            (ionChange)="searchCustomer($event)"
          ></ion-searchbar>
          <span *ngIf="customersMaxLimitReached" class="max-customers-reached">
            {{ 'i18n.profilePage.inviteUser.maxCustomers' | translate }}
          </span>
          <div *ngIf="searchedCustomers.length === 0" class="no-user-found">
            {{ 'i18n.profilePage.inviteUser.noCustomer' | translate }}
          </div>
          <ion-list
            class="customers-list"
            *ngIf="searchedCustomers.length > 0"
            [disabled]="this.result.newUserEmail"
          >
            <ion-item-divider></ion-item-divider>
            <ion-item
              *ngFor="
                let customer of searchedCustomers;
                trackBy: trackByCustomer
              "
              [disabled]="
                customer.disabled ||
                (customersMaxLimitReached && !customer.selected)
              "
              (click)="toggleCustomerSelection(customer, $event)"
            >
              <div class="checkbox-label">
                <span>
                  {{ customer.customerName | titlecase }}&nbsp;#{{
                    customer.customerNumber
                  }}
                </span>
              </div>
              <ion-checkbox
                mode="ios"
                slot="end"
                [attr.data-cy]="
                  'invite-existing-user-customer-' +
                  customer.customerNumber +
                  '-option'
                "
                [checked]="customer?.selected"
                labelPlacement="start"
                [aria-label]="
                  customer.customerName + ' #' + customer.customerNumber
                "
              ></ion-checkbox>
            </ion-item>
          </ion-list>
        </div>
        <!-- Advanced Options -->
        <!-- Organization -->
        <div
          *ngIf="selectedOption === 'organization'"
          class="user-info-container"
        >
          <ion-label class="select-user-label">{{
            'i18n.profilePage.inviteNewUser.advancedOptions.selectOrganization'
              | translate
          }}</ion-label>
          <ion-item>
            <ion-input
              aria-label="advanced-options-organization"
              data-cy="advanced-options-organization"
              [placeholder]="
                'i18n.profilePage.inviteNewUser.advancedOptions.enterName'
                  | translate
              "
              [value]="newUserAdvOptions.organization.currentValue"
              type="text"
              maxlength="50"
              (ionInput)="onOrganizationChange($event)"
            ></ion-input>
          </ion-item>
        </div>
        <!-- Site Role -->
        <div *ngIf="selectedOption === 'siteRole'" class="user-info-container">
          <ion-label class="select-user-label">{{
            'i18n.profilePage.inviteNewUser.advancedOptions.selectSiteRole'
              | translate
          }}</ion-label>
          <ion-radio-group
            mode="md"
            [value]="newUserAdvOptions.siteRole.currentValue"
            (ionChange)="onSiteRoleChange($event)"
          >
            <ion-item-divider
              class="advanced-options-divider"
            ></ion-item-divider>
            <ion-item *ngFor="let role of SITE_ROLES">
              <ion-radio
                class="advanced-options-radio"
                [value]="role"
                [attr.data-cy]="'invite-new-user-site-role-' + role"
              >
                {{
                  'i18n.profilePage.inviteNewUser.advancedOptions.' + role
                    | translate
                }}
              </ion-radio>
            </ion-item>
          </ion-radio-group>
        </div>
        <!-- User Access Options -->
        <div
          *ngIf="selectedOption === 'userAccessOptions'"
          class="user-info-container"
        >
          <ion-label class="select-user-label">{{
            'i18n.profilePage.inviteNewUser.advancedOptions.selectAllThatApply'
              | translate
          }}</ion-label>
          <ion-list class="customers-list">
            <ion-item-divider
              class="advanced-options-divider"
            ></ion-item-divider>
            <ion-item
              *ngFor="let userAccessOption of USER_ACCESS_OPTIONS"
              (click)="toggleUserAccessRoleSelection(userAccessOption, $event)"
            >
              <div class="checkbox-label">
                <span>
                  {{
                    'i18n.profilePage.inviteNewUser.advancedOptions.' +
                      userAccessOption | translate
                  }}
                </span>
              </div>
              <ion-checkbox
                mode="ios"
                slot="end"
                [attr.data-cy]="
                  'invite-existing-user-advanced-options-userAccess-' +
                  userAccessOption +
                  '-option'
                "
                [checked]="
                  newUserAdvOptions.userAccessOptions.currentValue.includes(
                    userAccessOption
                  )
                "
                labelPlacement="start"
                [aria-label]="
                  'i18n.profilePage.inviteNewUser.advancedOptions.' +
                    userAccessOption | translate
                "
              ></ion-checkbox>
            </ion-item>
          </ion-list>
        </div>
        <!-- National Customization -->
        <div
          *ngIf="selectedOption === 'nationalCustomization'"
          class="user-info-container"
        >
          <ion-label class="select-user-label">{{
            'i18n.profilePage.inviteNewUser.advancedOptions.selectNationalCustomization'
              | translate
          }}</ion-label>
          <ion-searchbar
            aria-label="national-customizations-searchbar"
            data-cy="national-customizations-searchbar"
            class="search"
            mode="md"
            inputmode="search"
            enterkeyhint="search"
            placeholder="{{ 'i18n.header.search' | translate }}"
            (ionBlur)="searchNationalCustomization($event)"
            (ionClear)="resetSearchNationalCustomization()"
            (ionInput)="searchNationalCustomization($event)"
            (ionChange)="searchNationalCustomization($event)"
          ></ion-searchbar>
          <div
            *ngIf="searchedNationalCustomizations.length === 0"
            class="no-user-found"
          >
            {{ 'i18n.inventory.noInventoryToShow' | translate | titlecase }}
          </div>
          <div class="advanced-options-radio-group">
            <ion-radio-group
              mode="md"
              [value]="newUserAdvOptions.nationalCustomization.currentValue"
              (ionChange)="onNationalCustomizationChange($event)"
            >
              <ion-item-divider
                *ngIf="searchedNationalCustomizations.length > 0"
                class="advanced-options-divider"
              ></ion-item-divider>
              <ion-item
                *ngFor="
                  let nationalCustomization of searchedNationalCustomizations
                "
              >
                <ion-radio
                  class="advanced-options-radio"
                  [value]="nationalCustomization.profileName"
                  [attr.data-cy]="
                    'invite-new-user-national-customization-' +
                    nationalCustomization.profileName
                  "
                >
                  {{ nationalCustomization.profileName }}
                </ion-radio>
              </ion-item>
            </ion-radio-group>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-spinner
    *ngIf="isLoading"
    class="loading-spinner"
    name="circles"
    data-cy="loading-spinner-icon"
  ></ion-spinner>
</ion-content>
