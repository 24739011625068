<ion-header class="ion-no-border ion-header-modal-touch">
  <ion-toolbar>
    <ion-label data-cy="copy-list-option-text">
      {{ 'i18n.lists.copyList' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-content-modal-touch" scroll-y="true" tabletTouchMove>
  <!-- Copy List Options -->
  <div class="copy-list-options">
    <ion-item
      detail
      (click)="onSelectCopyListOption(listCopyOptions.selectList)"
      [class.selected]="selectedCopyListOption === listCopyOptions.selectList"
    >
      <ion-label
        class="create-list-option"
        data-cy="copy-list-option-select-list"
      >
        {{ 'i18n.lists.copyListModal.selectListToCopy' | translate }}
        <div>{{selectedList?.listName}}</div>
      </ion-label>
    </ion-item>
    <ion-item
      detail
      (click)="onSelectCopyListOption(listCopyOptions.newListName)"
      [class.selected]="selectedCopyListOption === listCopyOptions.newListName"
    >
      <ion-label
        class="create-list-option"
        data-cy="copy-list-option-new-list-name"
      >
        {{ 'i18n.lists.copyListModal.newListName' | translate }}
        <div>
          {{newListName ? newListName : 'i18n.lists.copyListModal.enterListName'
          | translate }}
        </div>
      </ion-label>
    </ion-item>
    <ion-item
      *ngIf="(listsPageData$ | async)?.userKind === 'Internal'"
      detail
      (click)="onSelectCopyListOption(listCopyOptions.listType)"
      [class.selected]="selectedCopyListOption === listCopyOptions.listType"
    >
      <ion-label
        class="create-list-option"
        data-cy="copy-list-option-list-type"
      >
        {{ 'i18n.lists.copyListModal.listType' | translate }}
        <div>{{ listType }}</div>
      </ion-label>
    </ion-item>
    <!-- Markets -->
    <ion-item
      *ngIf="showMassCopy"
      detail
      (click)="onSelectCopyListOption(listCopyOptions.markets)"
      [class.selected]="selectedCopyListOption === listCopyOptions.markets"
    >
      <ion-label class="create-list-option" data-cy="copy-list-option-markets">
        {{ 'i18n.lists.copyListModal.markets' | translate }}
        <div>
          {{marketSelected ? ('i18n.lists.copyListModal.selected' | translate) :
          ('i18n.lists.copyListModal.selectMarkets' | translate)}}
          {{marketSelected ? marketSelectedCount : ''}}
        </div>
      </ion-label>
    </ion-item>

    <!-- Customers -->
    <ion-item
      *ngIf="showMassCopy"
      detail
      (click)="onSelectCopyListOption(listCopyOptions.customers)"
      [class.selected]="selectedCopyListOption === listCopyOptions.customers"
    >
      <ion-label
        class="create-list-option"
        data-cy="copy-list-option-customers"
      >
        {{ 'i18n.lists.copyListModal.customers' | translate }}
        <div>
          {{customerSelected ? ('i18n.lists.copyListModal.selected' | translate)
          : ('i18n.lists.copyListModal.selectCustomers' | translate)}}
          {{customerSelected ? customerSelectedCount : ''}}
        </div>
      </ion-label>
    </ion-item>
    <!-- Copy List Selections-->
  </div>
</ion-content>
<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="platformType !== platformEnum.mobile"
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="cancel-copy-list-modal-button"
        [ngClass]="
          platformType !== platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismiss()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        data-cy="copy-list-modal-button"
        [ngClass]="
         listNameExists || !(newListName?.trim().length > 0) || !selectedList || (customerSelected && !marketSelected) || (!customerSelected && marketSelected)
            ? 'usf-fill-disabled-mobile-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="copy()"
      >
        {{ 'i18n.common.create' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
