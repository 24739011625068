<div
  class="product-information-container"
  [class.desktop]="platform === PlatformEnum?.desktop"
  [class.tablet]="platform === PlatformEnum?.tablet"
  [class.mobile]="platform === PlatformEnum?.mobile"
>
  <div class="heading">
    <div class="title" [class.mobile]="platform ===PlatformEnum?.mobile">
      {{ "i18n.worksWellWith.worksWellWith" | translate }} ({{
      worksWellWithProducts?.length }})
    </div>
    <button
      *ngIf="platform === PlatformEnum?.desktop"
      id="close-modal-button"
      data-cy="simple-action-close-modal-icon"
      (click)="dismiss()"
      class="close-button"
    >
      <ion-icon slot="icon-only" name="close-outline"></ion-icon>
    </button>
  </div>
  <div class="original-product">
    <div
      class="original-product-image"
      [class.mobile]="platform ===PlatformEnum?.mobile"
    >
      <app-product-card-image
        [isOnline]="true"
        [imageThumbnail]="product?.imageThumbnail"
        [productNumber]="product?.productNumber"
        [clickable]="false"
        [isMedium]="true"
      >
      </app-product-card-image>
    </div>
    <app-product-card-information
      [brand]="product?.summary?.brand"
      [description]="product?.summary?.productDescTxtl"
      [productNumber]="product?.productNumber"
      [salesPackSize]="product?.summary?.salesPackSize"
      [showNote]="false"
      [notFound]="product?.notFound"
      [customerProductNumber]="product?.customerProductNumber"
      [showPowerReview]="false"
      [price]="product?.pricing"
      [clickable]="false"
    >
    </app-product-card-information>
  </div>
  <div
    class="works-well-with-content"
    [class.mobile]="platform === PlatformEnum?.mobile"
  >
    <div
      *ngIf="platform !== PlatformEnum?.mobile"
      class="product-carousel"
      [class.desktop]="platform === PlatformEnum?.desktop"
    >
      <button
        *ngIf="platform === PlatformEnum?.desktop"
        class="previous-arrow"
        [class.swiper-button-disabled]="productSwiperRef?.swiper?.isBeginning"
        (click)="productSwiperRef?.swiper?.slidePrev()"
      >
        <ion-icon
          slot="icon-only"
          name="arrow-back-circle-outline"
          data-cy="swiper-back-icon"
        ></ion-icon>
      </button>
      <swiper-container
        #productSwiperRef
        resize-observer="true"
        observer="true"
        slides-per-view="auto"
        data-cy="card-swiper"
        class="www-swiper"
        (touchmove)="scrollBlock($event)"
      >
        <swiper-slide
          *ngFor="
            let product of worksWellWithProducts;
            let index = index
          "
        >
          <app-product-detail-card
            [productDetails]="product"
            [navigationDisabled]="false"
            (quantityUpdateEmitter)="orderQuantityUpdated($event, index)"
            (initializedElementEmitter)="emitProductCardInitialization($event)"
            (clickEventEmitter)="productDetailCardClick()"
            [carouselPosition]="index + 1"
            [linkSource]="linkSource"
            [merchFeature]="WORKS_WELL_WITH_FEATURE_NAME"
            [addMethod]="WORKS_WELL_WITH_FEATURE_NAME"
            [useQuantityButtons]="platform === PlatformEnum.tablet"
            [showPills]="true"
            [parentApn]="parentApn"
          >
          </app-product-detail-card>
        </swiper-slide>
      </swiper-container>
      <button
        *ngIf="platform === PlatformEnum?.desktop"
        class="next-arrow"
        [class.swiper-button-disabled]="productSwiperRef?.swiper?.isEnd"
        (click)="productSwiperRef?.swiper?.slideNext()"
      >
        <ion-icon
          slot="icon-only"
          name="arrow-forward-circle-outline"
          data-cy="forward-swiper-icon"
        ></ion-icon>
      </button>
    </div>
    <div
      *ngIf="platform === PlatformEnum?.mobile"
      class="mobile-card-scroll-container"
      (touchmove)="scrollBlock($event)"
    >
      <div
        *ngFor="
          let product of worksWellWithProducts;
          let index = index
        "
        class="mobile-product-card-container"
      >
        <app-usf-product-card-touch
          [vm]="{
            mode: UsfProductCardModeEnum.worksWellWith,
            product: product,
            index: index,
            pricingDisabled: false,
          }"
          (quantityUpdateEmitter)="orderQuantityUpdated($event.quantityUpdate, index)"
          (productCardClickEmitter)="clickCard($event)"
          [showAddToList]="true"
          [useOrderObservable]="true"
        >
        </app-usf-product-card-touch>
      </div>
    </div>
  </div>
</div>
<ion-footer
  *ngIf="platform !== PlatformEnum.desktop"
  class="works-well-with-modal-footer"
>
  <ion-button
    *ngIf="platform === PlatformEnum?.tablet || platform === PlatformEnum?.mobile"
    (click)="dismiss()"
    class="usf-fill-green-button"
  >
    {{ 'i18n.common.close' | translate}}
  </ion-button>
</ion-footer>
