import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss'],
})
export class MessageBarComponent implements OnChanges {
  @Input() message: string;
  @Input() isMobile: boolean;
  @Input() shouldDisplay: boolean;
  @Input() radius = 0;
  @Input() textAlign = 'center';
  @Input() paddingX = 20;
  @Input() paddingY = 16;

  constructor() {}
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges() {
    /** Hook requires to update the DOM */
  }
}
