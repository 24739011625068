/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
@Directive({
  selector: '[numeric-alt]',
})
export class NumericAltDirective {
  private regex = new RegExp(/^([0-9]+?)*$/);
  private antiRegex = new RegExp(/[^\d]+/g);

  @Input() maxLen: number;

  constructor(private el: ElementRef) {}

  @HostListener('paste', ['$event'])
  formatPaste(event: any) {
    event.preventDefault();
    let current: string = this.el.nativeElement.value;
    const cursorPos = event.target.selectionStart;
    const pasteText = event.clipboardData.getData('text') as string;
    const next: string =
      current.slice(0, cursorPos) + pasteText + current.slice(cursorPos);
    const formattedPaste = next
      .replace(this.antiRegex, '')
      .substring(0, this.maxLen);
    this.el.nativeElement.value = this.maxLen
      ? formattedPaste.substring(0, this.maxLen)
      : formattedPaste;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (
      next &&
      !String(next).match(this.regex) &&
      event.key !== 'Backspace' &&
      !event.ctrlKey &&
      !event.metaKey
    ) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    let current: string = event?.target?.value;
    const cleanInput = current.replace(this.antiRegex, '');
    event.target.value = cleanInput;
    this.el.nativeElement.value = cleanInput;
  }
}
