export enum ConvertToWillCallTracingMessages {
  traceContext = 'my-orders',
  convertToWillCall = 'convert to will call button click',
  editConvertToWillCall = 'edit convert to will call button click',
  willCallOrderConfirmationModalDismissEvent = 'Submit Will Call Order Start',
  editWillCallApplyEvent = 'Edit Will Call Order Submission Start',
  submitWillCallOrderEvent = 'submit will call order',
  willCallConfirmationModalName = 'convert to will call confirmation modal',
  willCallConfirmationModalCancelClick = 'submit will call confirmation modal cancel',
  willCallFormModalCancelClick = 'submit will call form modal cancel',
}
