import { ContractPill } from './contract-pills.model';

export enum CONTRACT_PILLS_ENUM {
  orangeVizient = 'VMA',
  greenVizient = 'VMAS',
  NEW = 'NEW',
  GBUY = 'GBUY',
  CMP = 'CMP',
  CMA = 'CMA',
  CMAR = 'CMAR',
  TERM = 'TERM',
  NEWR = 'NEWR',
  HTR = 'HTR',
  HTS1 = 'HTS1',
  HTS2 = 'HTS2',
  HTS3 = 'HTS3',
  LEGENDS = 'LEGENDS',
  DOLL = 'DOLL',
  //DOLL2
  DLR2 = 'DLR2',
  BJS = 'BJS',
  BJSC = 'BJSC',
  HMS = 'HMS',
  FIRST_WATCH = 'FIRST WATCH',
}

export const CONTRACT_PILLS = {
  orangeVizient: {
    iconUrl: 'assets/icon/vizient-orange.svg',
    size: 6.25,
  } as ContractPill,
  greenVizient: {
    iconUrl: 'assets/icon/vizient-green.svg',
    size: 6.25,
  } as ContractPill,
  NEW: {
    iconUrl: 'assets/icon/NEW.svg',
    size: 3.75,
  } as ContractPill,
  GBUY: {
    iconUrl: 'assets/icon/GBUY.svg',
    size: 3.75,
  } as ContractPill,
  TERM: {
    iconUrl: 'assets/icon/TERM.svg',
    size: 4,
  } as ContractPill,
  NEWR: {
    iconUrl: 'assets/icon/NEWR.svg',
    size: 4,
  } as ContractPill,
  CMA: {
    iconUrl: 'assets/icon/CMA.svg',
    size: 3.75,
  } as ContractPill,
  CMAR: {
    iconUrl: 'assets/icon/CMAR.svg',
    size: 4,
  } as ContractPill,
  CMP: {
    iconUrl: 'assets/icon/CMP.svg',
    size: 3.5,
  } as ContractPill,
  HTR: {
    iconUrl: 'assets/icon/HTR.svg',
    size: 4,
  } as ContractPill,
  HTS1: {
    iconUrl: 'assets/icon/HTS1.svg',
    size: 4,
  } as ContractPill,
  HTS2: {
    iconUrl: 'assets/icon/HTS2.svg',
    size: 4,
  } as ContractPill,
  HTS3: {
    iconUrl: 'assets/icon/HTS3.svg',
    size: 4,
  } as ContractPill,
  LEGENDS: {
    iconUrl: 'assets/icon/LegendsContract.svg',
    size: 5,
  },
  BJS: {
    iconUrl: 'assets/icon/BJs-Contracted.svg',
    size: 6.75,
  },
  HMS: {
    iconUrl: 'assets/icon/HMSHost.svg',
    size: 5.75,
  },
  FIRST_WATCH: {
    iconUrl: 'assets/icon/FWRGContract.svg',
    size: 6.75,
  },
};
