import { PriceViewModel } from '@shared/models/price-view.model';
import { Product } from '@shared/models/product.model';
import { ProductInventory, ProductSummary } from '@usf/product-types';
import { NonUSFProductViewModel } from '../models/non-usf-products-view-model';

export const transformNonUsfProductToProduct = (
  productToTransform: NonUSFProductViewModel,
): Product => {
  const productToReturn = {
    productNumber: productToTransform.productNumber,
    summary: {
      divisionNumber: productToTransform.divisionNumber,
      productNumber: productToTransform.productNumber,
      brand: productToTransform.brand,
      productDescLong: productToTransform.productDescLong,
      productDescTxtl: productToTransform.productDescLong,
      priceUom: productToTransform.isPricePerUnitInd ? 'EA' : 'CS',
      salesPackSize: productToTransform.salesPackSize,
      netWeight: productToTransform.averageNetWeight,
      eachUom: productToTransform.priceUom,
      catchWeightFlag: false,
      breakable: false,
      properties: new Set(),
      groupCode: productToTransform.groupId,
      categoryCode: productToTransform.category,
      classCode: productToTransform.pimClass,
    } as ProductSummary,
    inventory: {
      divisionNumber: productToTransform.divisionNumber,
      productNumber: productToTransform.productNumber,
      eachConversionFactor: productToTransform.unitsPerCase,
    } as ProductInventory,
    pricing: {
      loading: false,
      productNumber: productToTransform.productNumber,
      priceUom: productToTransform.priceUom,
    } as PriceViewModel,
    nonUSFData: {
      manufacturerProductNumber: productToTransform.manufacturerProductNumber,
      vendorName: productToTransform.vendorName,
    },
  } as Product;

  if (productToTransform.isPricePerUnitInd && productToTransform.price) {
    productToReturn.pricing.eachPrice = productToTransform.price;
    if (productToTransform.unitsPerCase)
      productToReturn.pricing.unitPrice =
        productToTransform.price * productToTransform.unitsPerCase;
  } else {
    productToReturn.pricing.unitPrice = productToTransform.price;
    if (productToTransform.unitsPerCase)
      productToReturn.pricing.eachPrice =
        productToTransform.price / productToTransform.unitsPerCase;
  }

  return productToReturn;
};
