export enum RecentOrderTileBannerMessage {
  submittedModifiableOrder = 'i18n.home.recentOrdersTile.upsellingModifiableMessage',
  inProgressDayBeforeDelivery = 'i18n.home.recentOrdersTile.dayBeforeDeliveryMessage',
  inProgressNotDayBeforeDelivery = 'i18n.home.recentOrdersTile.cartHasItemsMessage',
  // submittedAndHasStartedModifyingOrder = 'Submit your order to save your modifications.',
  submittedNotModifiable = '',
  noRecentOrders = 'i18n.home.recentOrdersTile.noRecentOrdersMessage',
  noOrderStarted = 'i18n.home.recentOrdersTile.noOrderStatedMessage',
}

export enum RecentOrderTileActionLinkText {
  modify = 'i18n.common.modify',
  resolve = 'i18n.common.resolve',
  goToCart = 'i18n.common.goToCart',
  details = 'i18n.common.details',
  createOrder = 'i18n.common.createOrder',
}

export enum RecentOrderTileShippingText {
  direct = 'direct',
  usFoodsDelivery = 'i18n.home.recentOrdersTile.usFoodsDelivery',
}
