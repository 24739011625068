<a
  (click)="isNavRestricted ? null : routeToHome()"
  data-cy="desktop-header-logo-home-link"
>
  <img
    [ngClass]="getClass()"
    [class.no-route]="isNavRestricted"
    [src]="getImgSrc()"
    [attr.data-cy]="getDataCy()"
  />
</a>
