<ion-header
  class="ion-no-border"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="(platformService?.isTouchExperience$() | async) === false"
      slot="end"
    >
      <ion-button
        id="close-modal-btn"
        data-cy="close-print-list-modal-button"
        (click)="dismissModal()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label data-cy="print-list-label">
      {{ 'i18n.lists.printList' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="print-list_content"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <form *ngIf="printListForm" [formGroup]="printListForm">
    <ion-row>
      <span data-cy="confirm-print-label">
        {{ 'i18n.lists.pricingOptionSelectPrint' | translate }}
      </span>
    </ion-row>

    <ion-row class="print-form-row">
      <ion-col size="12" class="print-form-col">
        <span data-cy="pricing-option-label">
          {{ 'i18n.lists.pricingOptions' | translate }}
        </span>
        <ion-item
          data-cy="pricing-options-section"
          button
          detail="true"
          [attr.detail-icon]="
            isPriceOptionsPopoverOpen
              ? 'chevron-up-outline'
              : 'chevron-down-outline'
          "
          (click)="openPricingOptions($event)"
        >
          <ion-input
            data-cy="pricing-options-input"
            formControlName="priceOptions"
            required="true"
            [readonly]="true"
          >
          </ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
  </form>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="cancel-print-list-modal-button"
        [ngClass]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismissModal()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        data-cy="print-list-modal-button"
        [ngClass]="
          platformService.platformType !== platformService.platformEnum.mobile
            ? !isValid()
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : !isValid()
            ? 'usf-fill-disabled-mobile-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="onPrintList()"
      >
        {{ 'i18n.common.continue' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
