<!-- Header -->
<ion-header
  class="ion-no-border"
  [class.ion-header-modal-desktop]="platform === PlatformEnum.desktop"
  [class.ion-header-modal-tablet]="platform === PlatformEnum.tablet"
  [class.ion-header-modal-mobile]="platform === PlatformEnum.mobile"
>
  <ion-toolbar class="md">
    <ion-buttons *ngIf="platform === PlatformEnum.desktop" slot="end">
      <ion-button data-cy="better-buy-decline-close-button" (click)="dismiss()">
        <ion-icon
          data-cy="better-buy-decline-close-button-icon"
          slot="icon-only"
          name="close-outline"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content
  class="better-buy-decline-content"
  [class.ion-content-modal-desktop]="platform === PlatformEnum.desktop"
  [class.ion-content-modal-tablet]="platform === PlatformEnum.tablet"
  [class.ion-content-modal-mobile]="platform === PlatformEnum.mobile"
>
  <!-- Page Content -->
  <ion-grid data-cy="better-buy-decline-grid" [fixed]="true">
    <ion-row class="better-buy-title">
      <ion-col size="12">
        <ion-label>
          {{ 'i18n.betterBuys.declineModal.title' | translate }}
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row class="better-buy-sub-title">
      <ion-col size="12">
        <ion-label>
          {{ 'i18n.betterBuys.declineModal.subTitle' | translate }}
        </ion-label>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-list class="better-buy-decline-list">
          <ion-radio-group [(ngModel)]="selectedCode">
            <ion-item
              *ngFor="let code of codes; let i = index"
              [attr.data-cy]="'better-buy-decline-' + code.code"
              class="better-buy-decline-item"
            >
              <ion-label
                id="better-buy-decline"
                [attr.data-cy]="'better-buy-decline-' + code.code + '-text'"
              >
                {{ code.label | titlecase }}
              </ion-label>

              <ion-radio
                aria-labelledby="better-buy-decline"
                [attr.data-cy]="
                  'ibetter-buy-decline-' + code.code + '-radio-button'
                "
                mode="ios"
                slot="end"
                value="{{ code?.code }}"
              ></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="platform !== PlatformEnum.mobile"
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="better-buy-decline-cancel-button"
        [class.usf-outline-green-mobile-modal-button]="
          platform === PlatformEnum.mobile
        "
        [class.usf-outline-green-dsktp-tblt-modal-button]="
          platform !== PlatformEnum.mobile
        "
        (click)="dismiss()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        data-cy="better-buy-decline-modal-next-button"
        [ngClass]="
          platform === PlatformEnum.mobile
            ? 'usf-fill-green-mobile-modal-button'
            : 'usf-fill-green-dsktp-tblt-modal-button'
        "
        (click)="submit()"
      >
        {{ 'i18n.common.continue' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
