export const SEARCH_RECORD_PER_BATCH = 50;
export const ELEMENTS_BEFORE_PAGES_END = 15;
export const DISCLAIMER_HEIGHT = 350;
export const DISCLAIMER_WIDTH = 360;
export const SMALL_RESULT_COUNT_SPACER_HEIGHT = 70;

export const BROWSE_CATALOG = 'browse catalog';
export const SEARCH_FOR_SUBS = 'search for subs';
export const SEARCH_RESULT = 'search results';
export const PRODUCT_COMPARE = 'product compare';

export const LAST_SEARCH_TERM = 'last-search-term';
