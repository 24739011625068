import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverHelperService } from '../../helpers/popover.helpers';
import { MessageBarComponent } from '../message-bar/message-bar.component';
import { ErrorPopoverComponent } from './error-popover/error-popover.component';
import { HeaderComponent } from './header.component';
import { UserPopoverComponent } from './user-popover/user-popover.component';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { UsfHeaderDesktopComponent } from './usf-header-desktop/usf-header-desktop.component';
import { UsfHeaderTouchComponent } from './usf-header-touch/usf-header-touch.component';
import { UsfSearchTypeaheadComponent } from './search-typeahead/usf-search-typeahead.component';
import { CustomerSelectionDesktopComponent } from './customer-selection/customer-selection-desktop/customer-selection-desktop.component';
import { CustomerSelectionTouchComponent } from './customer-selection/customer-selection-touch/customer-selection-touch.component';
import { MenuHoverComponent } from '../popovers/menu/menu-hover.component';
import { ShowFeatureModule } from '../../directives/show-feature.module';
import { CustomerSelectionPaymentsTouchComponent } from './customer-selection/customer-selection-payments-touch/customer-selection-payments-touch.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CustomScrollingModule } from '../custom-virtual-scroll/virtual-scroll.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerSelectionPaymentsFilterMobileComponent } from './customer-selection/customer-selection-payments-filter-mobile/customer-selection-payments-filter-mobile.component';
import { LogoComponent } from './logo/logo.component';
import { ValidationMessageComponent } from '../import-order/validation-message/validation-message.component';
import { ImportOrderModalComponent } from '../import-order/import-order-modal-desktop-tablet/import-order-modal.component';
import { ToggleNewSearchComponent } from '@shared/components/toggle-new-search/toggle-new-search.component';
import { SuperUserCustomersModalComponent } from './super-user-customers-modal/super-user-customers-modal.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SuperUserCustomerOptionComponent } from './super-user-customers-modal/super-user-customer-option/super-user-customer-option.component';
import { ZipCodeChangeTouchComponent } from '@shared/components/header/zip-code-change/zip-code-change-touch/zip-code-change-touch.component';
import { ZipCodeChangeDesktopComponent } from '@shared/components/header/zip-code-change/zip-code-change-desktop/zip-code-change-desktop.component';
import { LogoutOnlyPopoverComponent } from './logout-only-popover/logout-only-popover.component';
import { ZipCodeFormatPipe } from '@shared/components/header/zipCodeFormat';

@NgModule({
  declarations: [
    HeaderComponent,
    CustomerSelectionDesktopComponent,
    CustomerSelectionTouchComponent,
    ZipCodeChangeTouchComponent,
    ZipCodeChangeDesktopComponent,
    UserPopoverComponent,
    ErrorPopoverComponent,
    MessageBarComponent,
    SearchModalComponent,
    UsfHeaderDesktopComponent,
    UsfHeaderTouchComponent,
    LogoComponent,
    UsfSearchTypeaheadComponent,
    MenuHoverComponent,
    CustomerSelectionPaymentsTouchComponent,
    CustomerSelectionPaymentsFilterMobileComponent,
    ImportOrderModalComponent,
    ValidationMessageComponent,
    ToggleNewSearchComponent,
    SuperUserCustomersModalComponent,
    SuperUserCustomerOptionComponent,
    LogoutOnlyPopoverComponent,
    ZipCodeFormatPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    ShowFeatureModule,
    ScrollingModule,
    CustomScrollingModule,
    ReactiveFormsModule,
    NgxPaginationModule,
  ],
  exports: [
    HeaderComponent,
    CustomerSelectionDesktopComponent,
    CustomerSelectionTouchComponent,
    ZipCodeChangeTouchComponent,
    ZipCodeChangeDesktopComponent,
    UserPopoverComponent,
    ErrorPopoverComponent,
    MessageBarComponent,
    LogoComponent,
    SearchModalComponent,
    CustomerSelectionPaymentsTouchComponent,
    CustomerSelectionPaymentsFilterMobileComponent,
    ImportOrderModalComponent,
    ValidationMessageComponent,
    SuperUserCustomersModalComponent,
    SuperUserCustomerOptionComponent,
    LogoutOnlyPopoverComponent,
  ],
  providers: [PopoverHelperService],
})
export class HeaderModule {}
