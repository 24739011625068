<ion-header class="ion-no-border ion-header-modal-desktop">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button
        id="close-modal-btn"
        (click)="dismissModal()"
        data-cy="create-list-close-modal-button"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label data-cy="create-list-option-text">
      {{ 'i18n.lists.selectYourOption' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-content-modal-desktop">
  <ion-grid>
    <ion-row>
      <!-- CREATE LIST OPTIONS -->
      <ion-col size="5" class="create-list-options">
        <ion-item-divider></ion-item-divider>
        <ion-item
          detail
          (click)="onSelectCreateListOption(CreateListOptions.newList)"
          [class.selected]="
            selectedCreateListOption === CreateListOptions.newList
          "
        >
          <ion-label class="create-list-option" data-cy="new-list-label">
            {{ 'i18n.lists.newList' | translate }}
          </ion-label>
        </ion-item>
        <!-- <ion-item
          detail
          (click)="onSelectCreateListOption(CreateListOptions.copyList)"
          [class.selected]="
            selectedCreateListOption === CreateListOptions.copyList
          "
        >
          <ion-label class="create-list-option">
            {{ 'i18n.lists.copyList' | translate }}
          </ion-label>
        </ion-item>
        <ion-item
          detail
          (click)="onSelectCreateListOption(CreateListOptions.importList)"
          [class.selected]="
            selectedCreateListOption === CreateListOptions.importList
          "
        >
          <ion-label class="create-list-option">
            {{ 'i18n.lists.importList' | translate }}
          </ion-label>
        </ion-item> -->
      </ion-col>

      <!-- CREATE LIST INPUT -->
      <ion-col offset="1.5" size="5.5" class="create-list-input">
        <div class="create-list-memos">
          <span
            *ngIf="selectedCreateListOption === CreateListOptions.newList"
            data-cy="blank-list-label"
          >
            {{ 'i18n.lists.startWithBlankList' | translate }}
          </span>
        </div>
        <div class="create-list-common-container">
          <ion-item
            class="create-list-name"
            [class.list-name-exists]="listNameExists"
          >
            <ion-input
              placeholder="{{ 'i18n.lists.enterName' | translate }}"
              label="{{ 'i18n.lists.newListName' | translate }}"
              label-placement="floating"
              id="usf-line-input-style"
              data-cy="list-name-input"
              maxlength="30"
              [(ngModel)]="listName"
              (ngModelChange)="getListNameExists()"
            ></ion-input>
          </ion-item>
          <p *ngIf="listNameExists" class="list-name-exists-error">
            {{ 'i18n.lists.listNameExists' | translate }}
          </p>
          <div
            *ngIf="listData?.userKind === UserKinds.Internal"
            class="create-list-type"
          >
            <ion-list lines="none">
              <ion-list-header>
                <ion-label data-cy="list-type-label">
                  {{ 'i18n.lists.listType' | translate }}
                </ion-label>
              </ion-list-header>
              <ion-radio-group [(ngModel)]="listType" data-cy="list-type-radio">
                <ion-item>
                  <ion-label id="public-list" data-cy="public-list-type-label">
                    {{ 'i18n.lists.public' | translate }}
                  </ion-label>
                  <ion-radio
                    aria-labelledby="public-list"
                    mode="md"
                    slot="start"
                    value="{{ ListTypes.public }}"
                    data-cy="public-list-type-radio"
                  ></ion-radio>
                </ion-item>
                <ion-item>
                  <ion-label
                    id="internal-list"
                    data-cy="internal-list-type-label"
                  >
                    {{ 'i18n.lists.internal' | translate }}
                  </ion-label>
                  <ion-radio
                    aria-labelledby="internal-list"
                    mode="md"
                    slot="start"
                    value="{{ ListTypes.internal }}"
                    data-cy="internal-list-type-radio"
                  ></ion-radio>
                </ion-item>
                <ion-item>
                  <ion-label
                    if="private-list"
                    data-cy="private-list-type-label"
                  >
                    {{ 'i18n.lists.private' | translate }}
                  </ion-label>
                  <ion-radio
                    aria-labelledby="private-list"
                    mode="md"
                    slot="start"
                    value="{{ ListTypes.private }}"
                    data-cy="private-list-type-radio"
                  ></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    (platformService?.isTouchExperience$() | async) === false
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="create-modal-btn"
        data-cy="create-list-modal-button"
        [ngClass]="
          (!trimmedListName || !listType ? true : false) || listNameExists
            ? 'usf-fill-disabled-dsktp-tblt-modal-button'
            : 'usf-fill-green-dsktp-tblt-modal-button'
        "
        (click)="onCreateList()"
      >
        {{ 'i18n.common.create' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
