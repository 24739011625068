import { createAction, props } from '@ngrx/store';
import { Message } from 'src/app/ngrx-message/models/client/message';

export const upsertMessage = createAction(
  '[Message] Upsert a message',
  props<{ message: Message }>(),
);
export const upsertManyMessages = createAction(
  '[Message] Upsert many messages',
  props<{ messages: Message[] }>(),
);
