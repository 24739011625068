import { createSelector } from '@ngrx/store';
import { SelectedCustomerState } from '@panamax/app-state';
import { Customer, Department } from '@usf/customer-types';
import { UserKinds } from '@usf/user-types/user';
import { UserProfile } from '@usf/user-types/user-profile';
import {
  getCustomers,
  selectAppStateSelectedCustomer,
  selectedCustomer,
} from '../../ngrx-customer/store';
import { selectUserKind, selectUserProfiles } from '../../user/store';
import {
  SelectedCustUserProfiles,
  UserCustomerInfo,
} from '../models/user-customer-info.model';

export const getCustUserProfiles = createSelector(
  selectedCustomer,
  selectUserProfiles,
  (selectedCust: Customer, userProfiles: UserProfile[]) => {
    const ret: SelectedCustUserProfiles = {
      selectedCustomer: selectedCust,
      userProfiles,
    };
    return ret;
  },
);

export const getUserCustomerInfo = createSelector(
  getCustomers,
  selectAppStateSelectedCustomer,
  selectUserKind,
  getCustUserProfiles,
  (
    customers: Customer[],
    selectedCustomerState: SelectedCustomerState,
    userKinds: UserKinds,
    selectedCustProfiles: SelectedCustUserProfiles,
  ) => {
    const department = selectedCustProfiles.selectedCustomer?.departments?.find(
      dept =>
        dept.departmentNumber ===
        selectedCustomerState?.departmentNumber?.toString(),
    );
    const customerInfo: UserCustomerInfo = {
      customers,
      selectedCustomer: selectedCustProfiles.selectedCustomer,
      selectedCustomerState: selectedCustomerState,
      selectedDepartment: department,
      userKinds,
      userProfiles: selectedCustProfiles.userProfiles,
    };
    return customerInfo;
  },
);
