import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  @Input() loadingSpinnerModalMessage: string;
  @Input() cancelButton?: false;
  @Input() redirectPath?: string;

  constructor(
    private modalController: ModalController,
    readonly router: Router,
  ) {}

  async dismissLoadingSpinnerModal() {
    this.modalController.dismiss();
  }

  async handleCancelClick() {
    await this.dismissLoadingSpinnerModal();
    this.router.navigate([this.redirectPath]);
  }
}
