import { selectedCustomer } from '@app/ngrx-customer/store';
import {
  InspiredPicksProductInfo,
  InspiredPicksViewModel,
} from '@app/order/models/inspired-picks-view-model';
import { ProductCardModelHelper } from '@app/shared/helpers/product-card-model-helper.service';
import { createSelector } from '@ngrx/store';
import { selectAllFeaturesEnabled, selectOnline } from '@panamax/app-state';
import { ProductFilteringEnum } from '@shared/constants/product-filtering.enum';
import { UsfProductCardModeEnum } from '@shared/constants/usf-product-card-mode.enum';
import { ProductFilter } from '@shared/helpers/product-filter.helpers';
import { Product } from '@shared/models/product.model';
import { inspiredPicksSelectors, orderItemSelectors } from '@usf/ngrx-order';
import { OrderItemState } from '@usf/ngrx-order';
import { ImageVariantEnum } from 'src/app/product-detail/models/image-variant.enum';
import { getFirstImageURL } from 'src/app/product-detail/utils/product-images.util';
import { FEATURES } from 'src/app/shared/constants/splitio-features';
import { productWithAlternativesSelector } from 'src/app/shared/selectors/product.selectors';

export const selectInspiredPickViewModel = () =>
  createSelector(
    selectAllFeaturesEnabled([FEATURES.split_global_inspired_pick_products]),
    selectedCustomer,
    inspiredPicksSelectors.selectInspiredPicks,
    orderItemSelectors.selectOrderItemState,
    productWithAlternativesSelector,
    selectOnline(),
    (
      inspiredPicksEnabled,
      selectedCustomer,
      inspiredPickState,
      orderItemState: OrderItemState,
      products,
      online,
    ): InspiredPicksViewModel => {
      if (!inspiredPicksEnabled) {
        return {
          products: [],
          displayText: null,
        };
      }
      let inspiredPickProductInfos: InspiredPicksProductInfo[] = [];
      if (!!inspiredPickState?.productNumberList) {
        for (const num of inspiredPickState?.productNumberList) {
          const product = products.get(num);
          if (
            ProductFilter.applyFilter(
              product,
              selectedCustomer,
              ProductFilteringEnum.INSPIRED_PICKS,
            )
          ) {
            const inspiredPickProductInfo: InspiredPicksProductInfo = {
              product,
              productCardVM: ProductCardModelHelper.create(
                product,
                orderItemState,
                online,
                selectedCustomer,
                UsfProductCardModeEnum.didYouForget,
              ),
              imageUrl: getFirstImageURL(
                product?.summary,
                ImageVariantEnum.Small,
              ),
            };
            inspiredPickProductInfos.push(inspiredPickProductInfo);
          }
        }
      }
      const inspiredPickVM: InspiredPicksViewModel = {
        products: inspiredPickProductInfos,
        displayText: {
          headerTextDesktop: inspiredPickState?.displayText?.headerTextDesktop,
          subHeaderTextDesktop:
            inspiredPickState?.displayText?.subHeaderTextDesktop,
          headerTextMobile: inspiredPickState?.displayText?.headerTextMobile,
          subHeaderTextMobile:
            inspiredPickState?.displayText?.subHeaderTextMobile,
        },
      };
      return inspiredPickVM;
    },
  );

export const selectInspiredPickProductNumbers = createSelector(
  selectAllFeaturesEnabled([FEATURES.split_global_inspired_pick_products]),
  inspiredPicksSelectors.selectInspiredPicks,
  (inspiredPicksEnabled, inspiredPicks): number[] => {
    if (!inspiredPicksEnabled) {
      return [];
    }
    return inspiredPicks?.productNumberList;
  },
);
