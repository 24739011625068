import { Component } from '@angular/core';
import { SelectableCountableItem } from '../../../selectable-countable-item/selectable-countable-item.component';
import { SearchablePaneComponent } from '../searchable-pane/searchable-pane.component';

@Component({
  selector: 'app-single-select-countable-item',
  templateUrl: './single-select-countable-item.component.html',
  styleUrls: [
    './single-select-countable-item.component.scss',
    '../../dual-pane-shared.scss',
  ],
})
export class SingleSelectCountableItemComponent extends SearchablePaneComponent {
  constructor() {
    super();
  }

  itemSelected(item: SelectableCountableItem) {
    if (this.vm?.rightView?.selectedItem === item) {
      this.vm.rightView.selectedItem = undefined;
    } else {
      this.vm.rightView.selectedItem = item;
    }
    this.vm = this.vm.rightView?.handleItemSelection(
      this.vm,
      this.platform,
      this.vm.rightView,
      this.vm?.rightView?.selectedItem?.title,
    );
    this.checkValidity.emit();
  }
}
