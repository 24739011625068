<div
  [attr.data-cy]="'product-brand-' + productNumber + '-text'"
  class="brand-row"
  [class.not-found]="notFound"
>
  {{ brand || ' ' | acronymTitleCase }}
</div>
<div
  data-cy="product-description-text"
  class="description-row"
  [class.not-found]="notFound"
  [class.is-split]="isSplitCard"
  [class.click-disabled]="!clickable"
  (click)="clickable ? productDescriptionClick() : undefined"
>
  {{ description || ' ' | acronymTitleCase }}
</div>
<app-power-custom-review-rating-stars
  *ngIf="showPowerReview"
  [powerReviewRollupModel]="powerReviewRollupModel"
  [ratingStarsCSSEnum]="ratingStarsCSSEnum.ORIGINAL_ALTERNATIVE_PRODUCT"
  [ratingAverageCSSEnum]="ratingAverageCSSEnum.ORIGINAL_ALTERNATIVE_PRODUCT"
></app-power-custom-review-rating-stars>
<div
  class="number-salespack-row"
  [class.is-split]="isSplitCard"
  [class.bold]="notFound"
>
  <p [attr.data-cy]="'product-number-' + productNumber + '-text'">
    #{{ productNumber || ' ' }}
  </p>
  <img
    *ngIf="!notFound"
    data-cy="product-card-separator-image"
    src="assets/images/separator.png"
  />
  <p [attr.data-cy]="'product-packsize-' + productNumber + '-text'">
    {{ salesPackSize }}
  </p>

  <ng-container *ngIf="!!price && !isGuestUser">
    <p class="pricing-container" [attr.data-cy]="'product-price-display'">
      <img
        data-cy="product-card-separator-image"
        src="assets/images/separator.png"
      />
      <app-price-display [price]="price"></app-price-display>
    </p>
  </ng-container>

  <ng-container
    *appShowSplit="[split_global_product_price_per_portion_card_flag]"
  >
    <p
      *ngIf="pricePerPortion && !isGuestUser"
      [attr.data-cy]="'product-' + pricePerPortion + '-text'"
    >
      &nbsp;({{ pricePerPortion }})
    </p>
  </ng-container>
  <img
    *ngIf="!notFound && customerProductNumber"
    data-cy="product-card-separator-image"
    src="assets/images/separator.png"
  />
  <p
    *ngIf="customerProductNumber"
    class="usf-product-card-gl-cust-prod-number-text"
    [attr.data-cy]="'product-' + customerProductNumber + '-text'"
  >
    {{ customerProductNumber }}
  </p>
  <img
    *ngIf="showNote && !notFound"
    data-cy="product-card-separator-image"
    src="assets/images/separator.png"
  />
  <app-product-card-item-note
    *ngIf="showNote && !notFound"
    [note]="note"
    [productNumber]="productNumber"
    [truncateAmount]="truncateAmount"
    (itemNoteClickEmitter)="itemNoteClick()"
  ></app-product-card-item-note>
</div>
