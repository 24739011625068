import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '@panamax/app-state';

@Component({
  selector: 'app-order-syncing',
  templateUrl: './order-syncing.component.html',
  styleUrls: ['./order-syncing.component.scss'],
})
export class OrderSyncingComponent {
  @Input() customerSwitch: false;
  @Input() orderSwitch: false;

  constructor(
    private modalController: ModalController,
    readonly platformService: PlatformService,
  ) {}

  async continueClicked() {
    await this.modalController.dismiss({
      continueClick: true,
    });
  }

  async dismiss() {
    await this.modalController.dismiss();
  }
}
