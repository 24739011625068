import { Tracking } from '@panamax/app-state';
import { OrderTracingMessages } from '../order-tracing.constants';

export const createNewOrderTrace: Tracking = {
  tracing: {
    data: {
      traceContext: OrderTracingMessages.capability,
      isEndOfTrace: true,
      isStartOfTrace: true,
      attributes: {
        id: undefined,
        order: {
          openMethod: undefined,
          requestedDeliveryDate: undefined,
          confirmedDeliveryDate: undefined,
        },
        event: OrderTracingMessages.createNewOrderEvent,
      },
    },
  },
};
