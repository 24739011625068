import { ProductPropertiesEnum } from '@usf/product-types';

export enum ProductTagDisplay {
  direct = 'DIRECT',
  dwo = 'DWO',
  locallySourced = 'Locally Sourced',
  nonUsFoods = 'Non US Foods',
  onMasterList = 'On ML',
  onMasterListRestricted = 'On R-ML',
  onMyLists = 'On My Lists',
  recentPurchase = 'Recent Purchase',
  scoop = 'SCOOP',
}

export const ProductTagDisplayOrder = [
  ProductPropertiesEnum.contract,
  ProductPropertiesEnum.direct,
  ProductPropertiesEnum.recentlyPurchased,
  ProductPropertiesEnum.customerPill,
  ProductPropertiesEnum.onMasterList,
  ProductPropertiesEnum.dwo,
  ProductPropertiesEnum.onOrderGuideOrOnShoppingList,
  ProductPropertiesEnum.scoop,
  ProductPropertiesEnum.locallySourced,
];
