import { Tracking } from '@panamax/app-state';
import { PunchoutTracingConstants } from '../punchout-tracing.constants';
import { genericTracingTransformer } from '../../generic-tracing-transformer';

export const createPunchoutTransferClickTrace = (): Tracking => {
  return {
    tracing: {
      data: {
        traceContext: PunchoutTracingConstants.transferButtonClickContext,
        isEndOfTrace: true,
        isStartOfTrace: true,
        attributes: {
          event: PunchoutTracingConstants.event,
        },
      },
      transformFunc: genericTracingTransformer,
    },
  };
};
