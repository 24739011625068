import { Injectable, OnDestroy } from '@angular/core';
import { MessageTypeEnum } from '@app/ngrx-message/constants/messageTypeEnum';
import { ProfileAnalyticsService } from '@app/profile/analytics/profile-analytics.service';
import { UserActions } from '@app/user/store/actions/action-types';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { jwtDecode, PanAppState } from '@panamax/app-state';
import { FEATURES } from '@shared/constants/splitio-features';
import { Observable, Subscription, combineLatest, map } from 'rxjs';
import { ToastService } from '../toast/toast.service';
import { getAllSiteCustomizationProfiles } from '../../../user/store/actions/user.actions';
import {
  updateCustomersByUserId,
  updateCustomersByUserIdFail,
  updateCustomersByUserIdSuccess,
} from '@app/ngrx-customer/store';

@Injectable({
  providedIn: 'root',
})
export class InviteNewUserService implements OnDestroy {
  onInviteNewUserSuccess$: Subscription;
  onInviteNewUserFail$: Subscription;
  onInviteExistingUserSuccess$: Subscription;
  onInviteExistingUserFail$: Subscription;
  lastInvitedUserName: string;

  constructor(
    readonly panAppState: PanAppState,
    private store: Store,
    private actionSubject: ActionsSubject,
    private toastService: ToastService,
    private translateService: TranslateService,
    private profileAnalyticsService: ProfileAnalyticsService,
  ) {
    this.setupInviteUserSubscriptions();
    this.setupInviteExistingUserSubscriptions();
  }

  getInviteNewUserVisibility(): Observable<boolean> {
    return combineLatest([
      this.panAppState.feature$([FEATURES.split_global_invite_new_user]),
      this.panAppState.feature$([FEATURES.split_division_invite_new_user]),
      this.panAppState.feature$([
        FEATURES.split_division_local_only_invite_user,
      ]),
      this.panAppState.customer$,
      this.panAppState.accessToken$,
    ]).pipe(
      map(([globalFlag, divisionFlag, isLocalCustomer, customer, token]) => {
        const showInviteNewUser =
          jwtDecode(token)?.['usf-claims']?.userType === 'corp-ad' &&
          (!isLocalCustomer || customer?.customerType !== 'NA') &&
          globalFlag &&
          divisionFlag;

        return showInviteNewUser;
      }),
    );
  }

  setupInviteExistingUserSubscriptions(): void {
    this.onInviteExistingUserSuccess$ = this.actionSubject
      .pipe(ofType(updateCustomersByUserIdSuccess))
      .subscribe(data => this.showSuccessToastInviteExistingUser());

    this.onInviteExistingUserFail$ = this.actionSubject
      .pipe(ofType(updateCustomersByUserIdFail))
      .subscribe(data => this.showErrorToastInviteExistingUser());
  }

  setupInviteUserSubscriptions(): void {
    this.onInviteNewUserSuccess$ = this.actionSubject
      .pipe(ofType(UserActions.inviteNewUserSuccess))
      .subscribe(data => this.showSuccessToastInviteNewUser(data.inviteEmail));

    this.onInviteNewUserFail$ = this.actionSubject
      .pipe(ofType(UserActions.inviteNewUserFail))
      .subscribe(data => this.showErrorToastInviteNewUser(data.inviteEmail));
  }

  inviteUserFlag$ = (): Observable<boolean> => {
    return this.panAppState.feature$([FEATURES.split_global_alerts_user_mgmt]);
  };

  advancedOptionsFlag$ = (): Observable<boolean> => {
    return this.panAppState.feature$([
      FEATURES.split_global_alerts_user_mgmt_adv_opts,
    ]);
  };

  nationalCustomizationPremierFlag$ = (): Observable<boolean> => {
    return this.panAppState.feature$([
      FEATURES.split_global_alerts_user_mgmt_premier,
    ]);
  };

  getAllSiteCustomizationProfiles() {
    this.store.dispatch(getAllSiteCustomizationProfiles());
  }

  private showSuccessToastInviteNewUser(inviteEmail: string): void {
    this.toastService.presentToastMsg(
      this.translateService.instant(
        'i18n.profilePage.inviteNewUser.successMsg',
      ) + inviteEmail,
      'green-toast',
      MessageTypeEnum.success,
      [],
    );
  }

  private showErrorToastInviteNewUser(inviteEmail: string): void {
    this.toastService.presentToastMsg(
      this.translateService.instant('i18n.profilePage.inviteNewUser.errorMsg', {
        value: inviteEmail,
      }),
      'red-toast',
      MessageTypeEnum.failure,
      [],
    );
  }

  private showSuccessToastInviteExistingUser(): void {
    this.toastService.presentToastMsg(
      this.translateService.instant('i18n.profilePage.inviteUser.successMsg', {
        value: this.lastInvitedUserName,
      }),
      'green-toast',
      MessageTypeEnum.success,
      [],
    );
  }

  private showErrorToastInviteExistingUser(): void {
    this.toastService.presentToastMsg(
      this.translateService.instant('i18n.profilePage.inviteUser.errorMsg', {
        value: this.lastInvitedUserName,
      }),
      'red-toast',
      MessageTypeEnum.failure,
      [],
    );
  }

  inviteNewUser(inviteEmail: string, customers?: any[]) {
    this.store.dispatch(
      UserActions.inviteNewUser({
        inviteEmail: inviteEmail,
        customers: customers,
      }),
    );
    this.trackInviteUserModalSubmit(true, customers?.length);
  }

  inviteExistingUser(
    userId: number,
    userName: string,
    customers: { customerNumber: number; divisionNumber: number }[],
  ) {
    this.lastInvitedUserName = userName;
    this.store.dispatch(
      updateCustomersByUserId({
        userId: userId,
        customerIds: customers,
      }),
    );
    this.trackInviteUserModalSubmit(false, customers?.length);
  }

  trackInviteUserModalOpen(isNew: boolean) {
    this.profileAnalyticsService.trackInviteUserModalOpen(isNew);
  }

  trackInviteUserModalSubmit(isNew: boolean, customerCount: number) {
    this.profileAnalyticsService.trackInviteUserModalSubmit(
      isNew,
      customerCount,
    );
  }

  ngOnDestroy(): void {
    if (this.onInviteNewUserSuccess$) {
      this.onInviteNewUserSuccess$.unsubscribe();
    }
    if (this.onInviteNewUserFail$) {
      this.onInviteNewUserFail$.unsubscribe();
    }
    if (this.onInviteExistingUserSuccess$) {
      this.onInviteExistingUserSuccess$.unsubscribe();
    }
    if (this.onInviteExistingUserFail$) {
      this.onInviteExistingUserFail$.unsubscribe();
    }
  }
}
