import { AnalyticsTransformer, TrackingData } from '@panamax/app-state';
export const newUserSignUpTransformer: AnalyticsTransformer = (
  tracking: TrackingData,
) => {
  let trackingObj = {
    page: {
      pageName: tracking.data.pageName,
    },
    event: tracking.data.event,
    capability: tracking.data.capability,
  };
  return trackingObj;
};

export const newUserPageLoadTransformer: AnalyticsTransformer = (
  tracking: TrackingData,
) => {
  let trackingObj = {
    page: {
      pageName: tracking.data.pageName,
    },
    event: tracking.data.event,
    capability: tracking.data.capability,
  };
  return trackingObj;
};
