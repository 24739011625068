<ion-header
  class="ion-no-border"
  [ngClass]="
    platformType !== platformEnum.desktop
      ? platformType === platformEnum.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons *ngIf="platformType === platformEnum.desktop" slot="end">
      <ion-button
        id="close-modal-btn"
        data-cy="close-delete-group-modal-button"
        (click)="dismissModal()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label data-cy="delete-group-modal-label">
      {{ 'i18n.lists.deleteGroup' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="delete-group_content"
  [ngClass]="
    platformType !== platformEnum?.desktop
      ? platformType === platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <div class="delete-group-num-products" data-cy="products-in-group-text">
    {{
      numberOfProducts > 1
        ? ('i18n.lists.productsInGroup'
          | translate
            : {
                numProducts: numberOfProducts,
                groupName: groupName
              })
        : ('i18n.lists.productInGroup'
          | translate
            : {
                numProducts: numberOfProducts,
                groupName: groupName
              })
    }}
  </div>
  <ion-list lines="none">
    <ion-radio-group class="radio-options" [(ngModel)]="deleteAllProducts">
      <ion-item>
        <ion-label data-cy="delete-group-products-label">
          {{ 'i18n.lists.deleteGroupProducts' | translate }}
        </ion-label>
        <ion-radio mode="md" slot="start" [value]="true"></ion-radio>
      </ion-item>
      <ion-item>
        <ion-label
          id="delete-and-move-products"
          data-cy="delete-move-products-label"
        >
          {{ 'i18n.lists.deleteAndMoveProducts' | translate }}
        </ion-label>
        <ion-radio
          aria-labelledby="delete-and-move-products"
          mode="md"
          slot="start"
          [value]="false"
          data-cy="delete-or-move-group-radio"
        ></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <p data-cy="continue-confirm-message-text">
      {{ 'i18n.common.note' | translate }}&nbsp;
      <span>
        {{ 'i18n.lists.continueConfirmation' | translate }}
      </span>
    </p>
    <ion-buttons>
      <ion-button
        id="cancel-modal-btn"
        data-cy="cancel-delete-group-modal-button"
        [ngClass]="
          platformType !== platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismissModal()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        id="continue-modal-btn"
        data-cy="continue-delete-group-modal-button"
        [ngClass]="
          platformType !== platformEnum?.mobile
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="onDeleteGroup()"
      >
        {{ 'i18n.common.continue' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
