import { selectAppStateSelectedCustomer } from '@app/ngrx-customer/store';
import { createSelector } from '@ngrx/store';
import { IAppState, jwtDecode, selectAccessToken } from '@panamax/app-state';
import { USER_STATE_KEY } from '../../constants/constants';
import {
  hideContractedSugesstiveSellSummaryProfile,
  hideInventoryOutOfStockYellowModule,
  HideInvoiceAndPayments,
  hideOGProfile,
  hideSupplierUnavailable,
  MSLRestrictionOverride,
  PayInvoiceUser,
  SuperUser,
} from '../../constants/user-profile.constants';
import { UserState } from '../../models/user-state';

export const userFeature = (state: IAppState) => state[USER_STATE_KEY];

export const selectUser = createSelector(
  userFeature,
  (userState: UserState) => userState,
);

export const selectUserKind = createSelector(
  selectUser,
  (userState: UserState) => userState?.user?.userKind,
);

export const selectUserStatus = createSelector(
  selectUser,
  (userState: UserState) => userState?.user?.userStatus,
);

export const selectUserProfiles = createSelector(
  selectUser,
  (userState: UserState) => userState?.profilesState?.profiles,
);

export const selectUserProfilesState = createSelector(
  selectUser,
  (userState: UserState) => userState?.profilesState,
);

export const selectUserCustomizations = createSelector(
  selectUser,
  (userState: UserState) => userState?.customizations,
);

export const showMLM = createSelector(selectUserProfiles, userProfiles => {
  const userProfileMLMAdmin = userProfiles?.find(
    userProf => userProf.key === 'MLMAdmin',
  );
  const userProfileMLMReporting = userProfiles?.find(
    userProf => userProf.key === 'MLM_REPORTING',
  );
  if (
    (userProfileMLMAdmin && userProfileMLMAdmin.value === 'Y') ||
    (userProfileMLMReporting && userProfileMLMReporting.value === 'Y')
  ) {
    return true;
  } else {
    return false;
  }
});

export const showMPP = createSelector(selectUserProfiles, userProfiles => {
  const userProfile = userProfiles?.find(
    userProf => userProf.key === 'MPP_USER',
  );
  if (userProfile && userProfile.value === 'Y') {
    return true;
  } else {
    return false;
  }
});

export const showGL = createSelector(selectUserProfiles, userProfiles => {
  const userProfile = userProfiles?.find(
    userProf => userProf.key === 'GL_ADMIN',
  );
  if (userProfile && userProfile.value === 'Y') {
    return true;
  } else {
    return false;
  }
});

export const showBusinessAnalytics = createSelector(
  selectUserProfiles,
  userProfiles => {
    const userProfile = userProfiles?.find(
      userProf => userProf.key === 'HideBusinessAnalytics',
    );
    if (userProfile?.value === 'Y') {
      return false;
    } else {
      return true;
    }
  },
);

export const showInvoiceAndPaymentsMenu = createSelector(
  selectUserProfiles,
  userProfiles => {
    const hideInvoiceAndPayments = userProfiles?.find(
      userProf => userProf.key === HideInvoiceAndPayments,
    );
    const payInvoiceUser = userProfiles?.find(
      userProf => userProf.key === PayInvoiceUser,
    );
    if (hideInvoiceAndPayments?.value === 'Y') {
      return null;
    } else {
      return payInvoiceUser?.value === 'Y'
        ? 'i18n.moxe-links.invoicesAndPayments'
        : 'i18n.moxe-links.invoices';
    }
  },
);

export const showInvoiceAndPayments = createSelector(
  selectUserProfiles,
  userProfiles => {
    const hideInvoiceAndPayments = userProfiles?.find(
      userProf => userProf.key === HideInvoiceAndPayments,
    );
    const payInvoiceUser = userProfiles?.find(
      userProf => userProf.key === PayInvoiceUser,
    );
    if (hideInvoiceAndPayments?.value === 'Y') {
      return false;
    } else {
      return payInvoiceUser?.value === 'Y';
    }
  },
);

export const showRecipesOnDemand = createSelector(
  selectUserProfiles,
  userProfiles => {
    const userProfile = userProfiles?.find(
      userProf => userProf.key === 'HideRecipesOnDemand',
    );
    if (userProfile?.value === 'Y') {
      return false;
    } else {
      return true;
    }
  },
);

export const showValueAddedServices = createSelector(
  selectUserProfiles,
  userProfiles => {
    const userProfile = userProfiles?.find(
      userProf => userProf.key === 'HideValueAddedServices',
    );
    if (userProfile?.value === 'Y') {
      return false;
    } else {
      return true;
    }
  },
);

export const showTv360 = createSelector(
  selectAccessToken(),
  (token: string) => {
    try {
      const decodedToken = jwtDecode(token);
      if (!decodedToken) {
        return false;
      } else {
        if (decodedToken['usf-claims']?.trendview360User) return true;
        else return false;
      }
    } catch (Error) {
      return false;
    }
  },
);

export const loadRecentPurchaseInOg = createSelector(
  selectAccessToken(),
  (token: string) => {
    try {
      const decodedToken = jwtDecode(token);
      if (!decodedToken) {
        return false;
      } else {
        if (
          decodedToken['usf-claims']?.restrictToOG === 'N' ||
          decodedToken['usf-claims']?.restrictToOG === 'M' ||
          !decodedToken['usf-claims']?.restrictToOG
        ) {
          if (
            decodedToken['usf-claims']?.loadRecentlyPurchasedInOG !== 'N' ||
            !decodedToken['usf-claims']?.loadRecentlyPurchasedInOG
          ) {
            return true;
          }
        }
        return false;
      }
    } catch (Error) {
      return false;
    }
  },
);

export const selectUserPreferences = createSelector(
  selectUser,
  (userState: UserState) => userState?.userPreferencesState?.preferences,
);

export const selectFoodCostRangePreferences = createSelector(
  selectUser,
  // TODO: Update user types library so we dont have to cast to any
  (userState: any) =>
    userState?.userPreferencesState?.preferences?.preferences?.foodCostRange,
);

export const selectUserPreferencesState = createSelector(
  selectUser,
  (userState: UserState) => userState?.userPreferencesState,
);

export const selectUserEmailAndPhone = createSelector(
  selectUser,
  (userState: UserState) => userState?.emailAndPhone,
);

export const selectInteractBanners = createSelector(
  selectUser,
  (userState: UserState) => userState?.interactBanners,
);

export const showOrderGuides = createSelector(
  selectUserProfiles,
  selectAppStateSelectedCustomer,
  (userProfiles, selectedCustomer) => {
    const userProfile = userProfiles?.find(
      userProf => userProf.key === hideOGProfile,
    );
    if (
      (userProfile && userProfile.value === 'TRUE') ||
      selectedCustomer?.restrictToOG === 'M'
    ) {
      return false;
    } else {
      return true;
    }
  },
);

export const hideContractedSugesstiveSellSummary = createSelector(
  selectUserProfiles,
  userProfiles => {
    const userProfile = userProfiles?.find(
      userProf => userProf.key === hideContractedSugesstiveSellSummaryProfile,
    );
    if (userProfile && userProfile.value === 'Y') {
      return true;
    } else {
      return false;
    }
  },
);

export const selectCustomMessage = createSelector(
  selectUser,
  (userState: UserState) => userState?.customMessage,
);

export const selectCustomMessages = createSelector(
  selectUser,
  (userState: UserState) => {
    let result = [];
    if (userState?.customMessages) {
      const sortedMessages = [...userState?.customMessages].filter(
        m => m.startDate.toString().indexOf('1970') < 0,
      );
      const emptyDateMessages = [...userState?.customMessages].filter(
        m => m.startDate.toString().indexOf('1970') > -1,
      );
      if (sortedMessages) {
        result = sortedMessages.sort(
          (a, b) =>
            b['startDate']
              .toString()
              .localeCompare(a['startDate'].toString()) ||
            b['addDtm'].toString().localeCompare(a['addDtm'].toString()),
        );
        if (emptyDateMessages) {
          emptyDateMessages.forEach(em => {
            result.unshift(em);
          });
        }
      }
    }
    return result;
  },
);

export const isMSLRestrictionOverrideY = createSelector(
  selectUserProfiles,
  userProfiles => {
    const userProfile = userProfiles?.find(
      userProf => userProf.key === MSLRestrictionOverride,
    );
    if (userProfile && userProfile.value === 'Y') {
      return true;
    } else {
      return false;
    }
  },
);

export const forceRegularOrderProduct = createSelector(
  selectUserProfiles,
  userProfiles => {
    const userProfileHSU = userProfiles?.find(
      userProf => userProf.key === hideSupplierUnavailable,
    );

    const userProfileOOS = userProfiles?.find(
      userProf => userProf.key === hideInventoryOutOfStockYellowModule,
    );

    return (
      !!userProfileHSU &&
      userProfileHSU.value === 'Y' &&
      !!userProfileOOS &&
      userProfileOOS.value === 'Y'
    );
  },
);

export const forceOutOfStockOrderProduct = createSelector(
  selectUserProfiles,
  userProfiles => {
    const userProfileOOS = userProfiles?.find(
      userProf => userProf.key === hideInventoryOutOfStockYellowModule,
    );

    return !!userProfileOOS && userProfileOOS.value === 'Y';
  },
);

export const isSuperUser = createSelector(selectUserProfiles, userProfiles => {
  const superUserProfile = userProfiles?.find(
    userProf => userProf.key === SuperUser,
  );
  return !!superUserProfile && superUserProfile?.value === 'Y';
});

export const showMassDelete = createSelector(
  selectUserProfiles,
  userProfiles => {
    const userProfile = userProfiles?.find(
      userProf => userProf.key === 'MASS_SL_DELETE',
    );
    if (userProfile && userProfile.value === 'Y') {
      return true;
    } else {
      return false;
    }
  },
);

export const hideInvoiceAndPayments = createSelector(
  selectUserProfiles,
  userProfiles => {
    const hideInvoiceAndPayments = userProfiles?.find(
      userProf => userProf.key === HideInvoiceAndPayments,
    );
    let paymentFlag = false;
    if (hideInvoiceAndPayments?.value === 'Y') {
      paymentFlag = true;
    }
    return paymentFlag;
  },
);
