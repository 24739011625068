import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AuthConnectService } from '@panamax/app-state';

@Component({
  selector: 'app-logout-only-popover',
  templateUrl: './logout-only-popover.component.html',
  styleUrls: ['./logout-only-popover.component.scss'],
})
export class LogoutOnlyPopoverComponent {
  constructor(
    private popoverController: PopoverController,
    private authConnectService: AuthConnectService,
  ) {}

  async logout(): Promise<void> {
    await this.popoverController.dismiss();
    await this.authConnectService.logout();
  }
}
