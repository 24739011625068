import { Component } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ListAnalyticsService } from '@usf/ngrx-list';
import { ListDetailManagementService } from '../../list-detail-management/services/list-detail-management.service';
import { SortFilterListBaseComponent } from '../sort-filter-list-base.component';

@Component({
  selector: 'app-sort-filter-list-desktop-tablet',
  templateUrl: './sort-filter-list-desktop-tablet.component.html',
  styleUrls: ['./sort-filter-list-desktop-tablet.component.scss'],
})
export class SortFilterListDesktopTabletComponent extends SortFilterListBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    listAnalyticsService: ListAnalyticsService,
  ) {
    super(nav, modalController, platformService, listAnalyticsService);
  }
}
