import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PanAppState } from '@panamax/app-state';
import { WebSocketService } from '@panamax/websocket';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AuthConnectService } from '@panamax/app-state';
import { NG_RX_USER_EVENTS, WebSocketEvent } from '../models/websocket.model';

@Injectable({
  providedIn: 'root',
})
export class UserWebSocketService {
  subscriptions: Subscription[] = [];
  constructor(
    private webSocketService: WebSocketService,
    private store: Store<any>,
    private authConnectService: AuthConnectService,
    protected panAppState: PanAppState,
  ) {}

  initialize(): void {
    // if there is no websocket service injected, return out
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (!this.webSocketService['baseUrl']) {
      // eslint-disable-next-line no-console
      console.log(
        '%c** no websocket injected for user domain **',
        'color: black; font-weight:bold;',
      );
      return;
    }

    // subscribe to the websocket events scoped to the user domain
    // user websocket service lib will handle keeping the websocket connection
    // alive and will update the isConnected$ observable accordingly
    this.subscriptions.push(
      this.webSocketService?.isConnected$.subscribe(connected => {
        if (connected) {
          // Register websocket namespace and event types to subscribe to
          this.webSocketService.register([NG_RX_USER_EVENTS]);

          this.subscriptions.push(
            this.webSocketService?.getTopicData().subscribe((event: any) => {
              let eventData: WebSocketEvent;
              try {
                if (this.isObject(JSON.parse(event?.data))) {
                  eventData = JSON.parse(event?.data);
                  // dispatch appropriate NgRx action based on incoming websocket event action
                  if (
                    eventData?.data?.namespace === 'Panamax' &&
                    eventData?.data?.eventAction ===
                      'UserSessionExpirationRequested'
                  ) {
                    if (
                      eventData?.data?.messageContent &&
                      eventData?.data?.messageContent?.length > 0
                    ) {
                      this.panAppState.user$
                        .pipe(
                          filter(user => !!user),
                          take(1),
                        )
                        .subscribe(user => {
                          if (
                            user.ecomUserId.toString() ===
                            eventData?.data?.messageContent[0]?.userId
                          ) {
                            this.authConnectService.logout();
                          }
                        });
                    }
                  }
                } else {
                  eventData = null;
                }
              } catch (e) {}
            }),
          );
        } else {
          // eslint-disable-next-line no-console
          console.log(
            '%c** user domain web socket disconnected. **',
            'color: red; font-weight:bold;',
          );
        }
      }),
    );
  }

  isObject(obj): boolean {
    return obj === Object(obj);
  }
}
