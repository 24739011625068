import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';
import { OrderHeader, OrderItem } from '@usf/ngrx-order';

export const CART_ANALYTICS_ACTIONS = {
  createAddToOrderTracking: createAction(
    '[NGRXAnalytics - Order] Create Add to Order Tracking',
    props<{
      trackingAnalytics: Tracking;
      updateOrderHeader: OrderHeader;
      total: number;
      origOrderItemsClone: OrderItem[];
      updateOrderItemSequence: any;
    }>(),
  ),
  createRemoveFromOrderTracking: createAction(
    '[NGRXAnalytics - Order] Create Remove from Order Tracking',
    props<{
      trackingAnalytics: Tracking;
      updateOrderHeader: OrderHeader;
      total: number;
      origOrderItemsClone: OrderItem[];
      updateOrderItemSequence: any;
    }>(),
  ),
  createUpdateUqeTracking: createAction(
    '[NGRXAnalytics - Order] Create Update Uqe Tracking',
    props<{
      trackingAnalytics: Tracking;
      updateOrderHeader: OrderHeader;
      total: number;
      origOrderItemsClone: OrderItem[];
      updateOrderItemSequence: any;
    }>(),
  ),
  trackAddToOrder: createAction(
    '[NGRXAnalytics - Order] Track Add to Order',
    props<{ tracking: Tracking }>(),
  ),
  trackRemoveFromOrder: createAction(
    '[NGRXAnalytics - Order] Track Remove From Order',
    props<{ tracking: Tracking }>(),
  ),
  trackUpdateUqeQuantity: createAction(
    '[NGRXAnalytics - Order] Track Update Uqe Quantity',
    props<{ tracking: Tracking }>(),
  ),
  trackCopyOrderSuccess: createAction(
    '[NGRXAnalytics - Order] Track Copy Order Success',
    props<{ tracking: Tracking }>(),
  ),
  trackCancelSubmittedOrder: createAction(
    '[NGRXAnalytics - Order] Track Cancel Submitted Order',
    props<{ tracking: Tracking }>(),
  ),
  trackCancelIPOrder: createAction(
    '[NGRXAnalytics - Order] Track Cancel IP Order',
    props<{ tracking: Tracking }>(),
  ),
};
