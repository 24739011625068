import { AnalyticsTransformer, TrackingData } from '@panamax/app-state';

export const myOrdersPageLoad: AnalyticsTransformer = (
  tracking: TrackingData,
) => ({
  page: {
    pageName: tracking.data.pageName,
  },
  event: tracking.data.event,
  capability: tracking.data.capability,
  filters: tracking.data.filters,
});
