import { Component, Input } from '@angular/core';
import { Platform, PopoverController, ViewWillEnter } from '@ionic/angular';
import { PlatformEnum } from '@panamax/app-state';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-force-app-update',
  templateUrl: './force-app-update.component.html',
  styleUrls: ['./force-app-update.component.scss'],
})
export class ForceAppUpdateComponent implements ViewWillEnter {
  @Input() platformType: PlatformEnum;
  readonly PlatformEnum = PlatformEnum;
  appStoreLogo: string;
  appStoreLink: string;

  constructor(
    private platform: Platform,
    private popoverController: PopoverController,
  ) {}

  ionViewWillEnter(): void {
    this.getOSAndAppStoreLink();
  }

  getOSAndAppStoreLink() {
    if (this.platform.is('ios')) {
      this.appStoreLogo = 'assets/images/app-store-logo.png';
      this.appStoreLink = environment.appleAppStoreLink;
    } else {
      this.appStoreLogo = 'assets/images/google-play-store-logo.png';
      this.appStoreLink = environment.googlePlayStoreLink;
    }
  }

  async openAppStore() {
    window.open(this.appStoreLink);
    await this.dismissModal();
  }

  async dismissModal() {
    await this.popoverController.dismiss();
  }
}
