import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductRow } from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { IonInput } from '@ionic/angular';
import { PlatformEnum } from '@panamax/app-state';
import { PATHS } from '@shared/constants/paths';
import { ProductCompareEnum } from '@shared/constants/product-compare.enum';
import { NavigationHelperService } from '@shared/helpers/navigation.helpers.service';
import { ProductDetailViewModel } from '../../../product-detail/models/product-detail-view-model';
import { UsfProductCardModeEnum } from '../../constants/usf-product-card-mode.enum';
import { BetterBuyProduct } from '../../models/better-buy-card.model';
import { QuantityUpdate } from '../../models/quantity-update';

@Component({
  template: '',
})
export class UsfBetterBuyCardBaseComponent {
  @Input() index: number;
  @Input() listId: string | number;
  @Input() mode: UsfProductCardModeEnum;
  @Input() isOnline: boolean;
  @Input() pricingDisabled: boolean;
  @Input() betterBuyProduct: BetterBuyProduct;
  @Input() platformType: PlatformEnum;
  @Input() product: ProductRow;

  @Output() quantityUpdateEmitter = new EventEmitter<QuantityUpdate>();
  @Output() quantityElementEmitter = new EventEmitter<IonInput>();
  @Output() declineSwapEmitter = new EventEmitter<BetterBuyProduct>();
  @Output() acceptSwapEmitter = new EventEmitter<any>();
  @Output() productCardClickEmitter = new EventEmitter<number>();

  platformRef = PlatformEnum;

  constructor(private navigationHelperService: NavigationHelperService) {}

  changeQuantityHandler(event: QuantityUpdate, betterBuy?: any) {
    let quantityUpdate: any = event;
    if (betterBuy) {
      quantityUpdate.betterBuy = betterBuy;
    }
    this.quantityUpdateEmitter.emit(quantityUpdate);
  }

  quantityInputUpdate(event: IonInput) {
    this.quantityElementEmitter.emit(event);
  }

  declineSwapHandler() {
    this.declineSwapEmitter.emit(this.betterBuyProduct);
  }
  acceptSwap() {
    this.acceptSwapEmitter.emit();
  }
  goToCompareProducts() {
    const isFromListsPage = this.navigationHelperService
      .getLocationPath()
      ?.includes('lists/detail');
    this.navigationHelperService.routeTo(PATHS.SEARCH_COMPARE, {
      selectedProducts: `${this.product.productNumber},${this.betterBuyProduct.product.productNumber}`,
      originalItem: this.product.productNumber,
      mode: isFromListsPage
        ? ProductCompareEnum.betterBuyListsCompare
        : ProductCompareEnum.betterBuyCompare,
    });
  }
  productClick() {
    this.productCardClickEmitter.emit(
      this.betterBuyProduct.product.productNumber,
    );
  }
  transformProductToProductDetail() {
    return {
      ...this.betterBuyProduct?.product,
    } as ProductDetailViewModel;
  }
}
