import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MESSAGE_STATE_KEY } from '../../constants/constants';
import { Message } from '../../models/client/message';
import { MessageState } from '../../models/state/message-state';

const selectFeature = createFeatureSelector<MessageState>(MESSAGE_STATE_KEY);

const selectMessage = createSelector(
  selectFeature,
  (messageState: MessageState) => {
    if (messageState) {
      return Object.values(messageState.entities).find(
        message => !message.read && !message.toast,
      );
    } else {
      return;
    }
  },
);

const selectUnreadMessages = createSelector(
  selectFeature,
  (messageState: MessageState) => {
    if (messageState) {
      return Object.values(messageState.entities).filter(
        message => !message.read,
      );
    } else {
      return [];
    }
  },
);

const selectToastMessage = createSelector(
  selectUnreadMessages,
  (messages: Message[]) =>
    messages.filter(message => !!message.toast && !message.read),
);

export const messageSelectors = {
  selectMessage,
  selectUnreadMessages,
  selectToastMessage,
};
