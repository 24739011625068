import { Injectable } from '@angular/core';
import { OrderItem } from '@usf/ngrx-order';
import {
  AnalyticsProduct,
  AnalyticsSubstituteProduct,
} from '../../models/tracking/analytics/analytics-product';
import { Product } from '../../models/product.model';
import { QuantityType } from '../../models/quantity-type';
import { TracingProduct } from '../../models/tracking/tracing/tracing-product';

@Injectable({
  providedIn: 'root',
})
export class SharedTrackingService {
  /**
   *
   * @param orderItems Array of Client Domain OrderItems
   * @param productMap Product Map of product number to summary/inventory/contract
   * @param divisionNumber For prepending to product number
   * @returns Array of analytics products
   */
  static analyticsProductData(
    orderItems: OrderItem[],
    productMap: Map<number, Product>,
    divisionNumber: number,
    showSubs: boolean = false,
    exceptionCode?: string | number,
    addEnhancedFields: boolean = false,
  ): Array<AnalyticsProduct | AnalyticsSubstituteProduct> {
    const products: Array<AnalyticsProduct | AnalyticsSubstituteProduct> = [];
    if (!!orderItems?.length && !!productMap?.size) {
      orderItems.forEach(orderItem => {
        const product = productMap.get(orderItem.productNumber);
        if (orderItem.eachesOrdered?.currentValue && product) {
          let productToPush: AnalyticsProduct = {
            divisionApn: `${divisionNumber}-${product.productNumber}`,
            quantity: orderItem.eachesOrdered.currentValue,
            quantityUnits: QuantityType.Eaches,
            attributes: productMap.get(product.productNumber)
              ?.trackingAttributes,
          };
          if (addEnhancedFields) {
            productToPush = {
              ...productToPush,
              category: `${product?.summary?.classDescription}; ${product?.summary?.classDescription} |  ${product?.summary?.categoryDescription}; ${product?.summary?.classDescription} |  ${product?.summary?.categoryDescription} | ${product?.summary?.groupDescription}`,
              name: product?.summary?.productDescTxtl,
              brand: product?.summary?.brand,
              price: product?.pricing?.unitPrice ?? product?.pricing?.eachPrice,
            };
          }
          products.push(productToPush);
        }
        if (orderItem?.unitsOrdered?.currentValue && product) {
          let pushProduct: AnalyticsProduct = {
            divisionApn: `${divisionNumber}-${product.productNumber}`,
            quantity: orderItem.unitsOrdered.currentValue,
            quantityUnits: QuantityType.Cases,
            attributes: productMap.get(product.productNumber)
              ?.trackingAttributes,
          };
          if (exceptionCode) {
            pushProduct.exceptionCode = exceptionCode;
          }
          if (addEnhancedFields) {
            pushProduct = {
              ...pushProduct,
              category: `${product?.summary?.classDescription}; ${product?.summary?.classDescription} |  ${product?.summary?.categoryDescription}; ${product?.summary?.classDescription} |  ${product?.summary?.categoryDescription} | ${product?.summary?.groupDescription}`,
              name: product?.summary?.productDescTxtl,
              brand: product?.summary?.brand,
              price: product?.pricing?.unitPrice ?? product?.pricing?.eachPrice,
            };
          }
          // Show handpricing if price override
          if (!!orderItem.unitPriceOverride) {
            pushProduct = {
              ...pushProduct,
              handPricing: '1',
            };
          }

          let pushSubstitute: AnalyticsSubstituteProduct;
          if (
            showSubs &&
            product.alternative?.available &&
            product.alternative?.required
          ) {
            pushProduct = {
              ...pushProduct,
              exceptionView: '1',
              exceptionNumberOfSubs: 1,
            };
            const substituteProduct = product.alternative.product;
            pushSubstitute = {
              divisionApn: `${divisionNumber}-${substituteProduct.productNumber}`,
              attributes: productMap.get(substituteProduct.productNumber)
                ?.trackingAttributes,
              substitutionView: '1',
              substitutionParentApn: `${divisionNumber}-${product.productNumber}`,
            };
            if (addEnhancedFields) {
              pushSubstitute = {
                ...pushSubstitute,
                category: `${product?.summary?.classDescription}; ${product?.summary?.classDescription} |  ${product?.summary?.categoryDescription}; ${product?.summary?.classDescription} |  ${product?.summary?.categoryDescription} | ${product?.summary?.groupDescription}`,
                name: product?.summary?.productDescTxtl,
                brand: product?.summary?.brand,
                price:
                  product?.pricing?.unitPrice ?? product?.pricing?.eachPrice,
              };
            }
          }

          products.push(pushProduct);
          if (showSubs && pushSubstitute) {
            products.push(pushSubstitute);
          }
        }
      });
    }
    return products;
  }

  /**
   *
   * @param dyfProducts Array of dyf product number
   * @param productMap Product Map of product number to summary/inventory/contract
   * @param divisionNumber For prepending to product number
   * @returns Array of analytics products
   */
  static analyticsAdditionalProductData(
    dyfProducts: number[],
    productMap: Map<number, Product>,
    divisionNumber: number,
    merchFeatureName: string,
  ): AnalyticsProduct[] {
    return (dyfProducts || []).map(product => ({
      divisionApn: `${divisionNumber}-${product}`,
      attributes: productMap.get(product)?.trackingAttributes,
      merchFeatureView: '1',
      merchFeatureName,
    }));
  }

  /**
   *
   * @param orderItems Array of Client Domain OrderItems
   * @param productMap Product Map of product number to summary/inventory/contract
   * @returns Array of tracing products
   */
  static tracingProductData(
    orderItems: OrderItem[],
    productMap: Map<number, Product>,
  ): TracingProduct[] {
    const products: TracingProduct[] = [];
    if (!!orderItems?.length && !!productMap?.size) {
      orderItems.forEach(orderItem => {
        const product = productMap.get(orderItem?.productNumber);
        if (orderItem?.eachesOrdered?.currentValue && product) {
          products.push({
            apn: product.productNumber,
            quantity: orderItem.eachesOrdered.currentValue,
            quantityUnits: QuantityType.Eaches,
            attributes: productMap.get(product.productNumber)
              ?.trackingAttributes,
          });
        }
        if (orderItem?.unitsOrdered?.currentValue && product) {
          let pushProduct: TracingProduct = {
            apn: product.productNumber,
            quantity: orderItem.unitsOrdered.currentValue,
            quantityUnits: QuantityType.Cases,
            attributes: productMap.get(product.productNumber)
              ?.trackingAttributes,
          };
          // Show handpricing if price override
          if (!!orderItem.unitPriceOverride) {
            pushProduct = {
              ...pushProduct,
              handPricing: '1',
            };
          }
          products.push(pushProduct);
        }
      });
    }
    return products;
  }

  static getAdditionalProductAttributes = (
    products: Array<Object>,
    productMap: Map<number, Product>,
  ): string[] => {
    const attributesArray = [];
    for (let i = 1; i < products?.length; i++) {
      if (products[i]['divisionApn']) {
        const splitApn = products[i]['divisionApn'].split('-');
        if (!!splitApn[1]) {
          const productNumber = parseInt(splitApn[1]);
          attributesArray[i] =
            productMap.get(productNumber)?.trackingAttributes;
        }
      }
    }

    return attributesArray;
  };
}
