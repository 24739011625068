import { Component } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { CustomerSelectionBaseComponent } from '../customer-selection-base.component';

@Component({
  selector: 'app-customer-selection-touch',
  templateUrl: './customer-selection-touch.component.html',
  styleUrls: ['./customer-selection-touch.component.scss'],
})
export class CustomerSelectionTouchComponent extends CustomerSelectionBaseComponent {
  constructor(
    modalController: ModalController,
    popoverController: PopoverController,
    platformService: PlatformService,
  ) {
    super(modalController, popoverController, platformService);
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit() {
    window.dispatchEvent(new Event('resize'));
  }
}
