import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-with-nav',
  templateUrl: './modal-with-nav.component.html',
  styleUrls: ['./modal-with-nav.component.scss'],
})
export class ModalWithNavComponent {
  rootPage: any;
  rootPageParams: any;

  constructor() { }
}
