<ion-header
  class="ion-no-border"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-header-modal-tablet'
      : 'ion-header-modal-mobile'
  "
>
  <ion-toolbar>
    <ion-label data-cy="select-list-option-label">{{
      'i18n.lists.selectYourOption' | translate
    }}</ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="create-list_content"
  [ngClass]="
    platformService?.platformType === platformService?.platformEnum?.tablet
      ? 'ion-content-modal-tablet'
      : 'ion-content-modal-mobile'
  "
>
  <ion-item-divider></ion-item-divider>
  <ion-item
    id="new-list-btn"
    data-cy="create-new-list-button"
    detail
    (click)="onSelectCreateListOption(CreateListOptions.newList)"
  >
    <ion-label data-cy="new-list-label">{{
      'i18n.lists.newList' | translate
    }}</ion-label>
  </ion-item>
  <!-- <ion-item
    id="copy-list-btn"
    data-cy="copy-list-btn"
    detail
    (click)="onSelectCreateListOption(CreateListOptions.copyList)"
  >
    <ion-label>{{ 'i18n.lists.copyList' | translate }}</ion-label>
  </ion-item>
  <ion-item
    id="import-list-btn"
    data-cy="import-list-btn"
    detail
    (click)="onSelectCreateListOption(CreateListOptions.importList)"
  >
    <ion-label>
      {{ 'i18n.lists.importList' | translate }}
    </ion-label>
  </ion-item> -->
</ion-content>
