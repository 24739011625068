import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UsfProductCardModeEnum } from '../../constants/usf-product-card-mode.enum';
import { PanAppState, PlatformService } from '@panamax/app-state';
import {
  EllipsisClickData,
  ProductClickData,
  ResequenceProductData,
  UsfProductCardViewModel,
} from '../../models/usf-product-card.model';
import { IonInput } from '@ionic/angular';
import { PowerReviewRollupModel } from '@usf/ngrx-product/lib/models/power-review-product.model';
import {
  BetterBuyDeclineEvent,
  BetterBuyProduct,
} from '@shared/models/better-buy-card.model';
import { Product } from '../../models/product.model';
@Component({
  template: '',
})
export class UsfProductCardBaseComponent {
  readonly productCardModes = UsfProductCardModeEnum;
  @Input() vm: UsfProductCardViewModel;
  @Input() lnkSrc: string;
  @Input() powerReviewRollupModel: PowerReviewRollupModel;
  @Input() alternatePowerReviewRollupModel: PowerReviewRollupModel;
  @Input() showNotes: boolean;
  @Input() listItemNoteFeatureFlag: boolean;
  @Input() showAddToList = false;
  @Input() useOrderObservable = false;

  @Input() isGuestUser = false;

  @Output()
  resequenceProductEmitter: EventEmitter<ResequenceProductData> =
    new EventEmitter();
  @Output() quantityUpdateEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  ellipsisEmitter: EventEmitter<EllipsisClickData> = new EventEmitter();
  @Output()
  promotionClickEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  productCardClickEmitter: EventEmitter<ProductClickData> = new EventEmitter();
  @Output()
  itemNoteClickEmitter: EventEmitter<EllipsisClickData> = new EventEmitter();
  @Output()
  infoIconClickEmitter: EventEmitter<EllipsisClickData> = new EventEmitter();
  @Output()
  quantityInputEmitter: EventEmitter<IonInput> = new EventEmitter();
  @Output()
  declineBetterBuyEmitter: EventEmitter<BetterBuyDeclineEvent> =
    new EventEmitter();
  @Output() acceptSwapEmitter = new EventEmitter<Product>();
  @Output() confirmQuantityEmitter = new EventEmitter();
  @Output() productConversionEmitter = new EventEmitter<Product>();

  constructor(
    readonly platformService: PlatformService,
    protected translateService: TranslateService,
    protected panAppState: PanAppState,
  ) {}

  resequenceProduct(newSequenceNumber: any) {
    this.resequenceProductEmitter.emit({
      newSeq: Number(newSequenceNumber),
      oldSeq: this.vm?.product?.sequenceNumber,
      productNum: this.vm?.product?.productNumber,
    });
  }

  onQuantityUpdate(quantityUpdate: any) {
    this.quantityUpdateEmitter.emit({
      quantityUpdate,
      product: this.vm?.product,
      alternative: this.vm?.product?.alternative,
    });
  }

  onProductCardClick(productNumber: number, currentProduct?: boolean) {
    const betterBuyCard =
      !!this.vm?.product?.betterBuy &&
      !this.vm?.product?.hasAlternative &&
      (this.vm?.mode === this.productCardModes.listDetail ||
        this.vm?.mode === this.productCardModes.recentlyPurchased);
    this.productCardClickEmitter.emit({
      productNumber,
      isQuickView: false,
      currentProduct,
      betterBuyCard,
    });
  }

  confirmUnusualQuantity(event) {
    this.confirmQuantityEmitter.emit(event);
  }

  openProductCardOptions(event: Event, isTouch: boolean) {
    this.ellipsisEmitter.emit({
      event,
      isTouch,
      product: this.vm.product,
      mode: this.vm.mode,
      itemType: this.vm.itemType,
      list: this.vm.list,
      pricingDisabled: this.vm.pricingDisabled,
      isSelected: this.vm.isSelected,
      allowHandPricing: this.vm.allowHandPricing,
      handPricingDetails: this.vm.handPricingDetails,
      listItemNoteFeatureFlag: this.listItemNoteFeatureFlag,
    });
  }

  onPromotionClick(event: any, isTouch: boolean) {
    this.promotionClickEmitter.emit({
      event,
      isTouch,
      product: this.vm.product,
      mode: this.vm.mode,
      itemType: this.vm.itemType,
      list: this.vm.list,
      isSelected: this.vm.isSelected,
    });
  }

  onItemNoteClick(isTouch: boolean) {
    this.itemNoteClickEmitter.emit({
      event: null,
      isTouch,
      product: this.vm.product,
      mode: this.vm.mode,
      itemType: this.vm.itemType,
      list: this.vm.list,
      pricingDisabled: this.vm.pricingDisabled,
      isSelected: this.vm.isSelected,
    });
  }

  onInfoIconClick(isTouch: boolean) {
    this.infoIconClickEmitter.emit({
      event: null,
      isTouch,
      product: this.vm.product,
      mode: this.vm.mode,
      itemType: this.vm.itemType,
      list: this.vm.list,
      pricingDisabled: this.vm.pricingDisabled,
      isSelected: this.vm.isSelected,
    });
  }

  sendUpQuantityInput(input: IonInput) {
    this.quantityInputEmitter.emit(input);
  }

  onDeclineClick(betterBuy: BetterBuyProduct) {
    this.declineBetterBuyEmitter.emit({
      betterBuy,
      original: this.vm.product,
    });
  }
  acceptSwap() {
    this.acceptSwapEmitter.emit(this.vm?.product);
  }

  handleProductConversionClick() {
    this.productConversionEmitter.emit(this.vm?.product);
  }
}
