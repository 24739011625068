import { NgModule } from '@angular/core';
import { CustomSizeVirtualScrollDirective } from './custom-virtual-scroll';
import { InventoryVirtualScrollDirective } from './Inventory/inventory-virtual-scroll';

@NgModule({
  exports: [CustomSizeVirtualScrollDirective, InventoryVirtualScrollDirective],
  declarations: [
    CustomSizeVirtualScrollDirective,
    InventoryVirtualScrollDirective,
  ],
})
export class CustomScrollingModule {}
