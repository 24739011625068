import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LOCAL_STORAGE_KEYS, PanAppState } from '@panamax/app-state';
import { of, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { selectedCustomer } from 'src/app/ngrx-customer/store';
import { AuthenticationService } from 'src/app/shared';
import { LoginDataService } from '../../services';
import { RefreshAuthTokenActions } from '../actions/action-types';

@Injectable({
  providedIn: 'root',
})
export class RefreshAuthTokenEffects {
  autoRefreshAuthToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshAuthTokenActions.scheduleAuthTokenRefresh),
      filter(() => this.authenticationService.isAuthenticated()),
      withLatestFrom(this.panamax.tokenExpiration$),
      map(
        ([_, tokenExpiration]) =>
          Number(tokenExpiration) - Date.now() - this.paddingTime * 1000,
      ),
      concatMap(timeRemaining =>
        timer(Math.max(timeRemaining, 1)).pipe(
          withLatestFrom(this.store.select(selectedCustomer)),
          switchMap(([_, customer]) => [
            RefreshAuthTokenActions.refreshAuthToken({ customer }),
          ]),
        ),
      ),
    ),
  );

  refreshAuthToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefreshAuthTokenActions.refreshAuthToken),
      filter(emptyCheck => !!emptyCheck),
      filter(() => this.authenticationService.isAuthenticated()),
      map(payload => payload.customer),
      switchMap(() =>
        this.loginDataService.refreshAuthToken().pipe(
          switchMap(payload => [
            RefreshAuthTokenActions.refreshAuthTokenSuccess({ payload }),
            RefreshAuthTokenActions.scheduleAuthTokenRefresh(),
          ]),
          catchError(err => {
            const error = { status: err.status, message: err.message };
            return of(
              RefreshAuthTokenActions.refreshAuthTokenFailed({ error }),
            );
          }),
        ),
      ),
    ),
  );

  refreshAuthTokenSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RefreshAuthTokenActions.refreshAuthTokenSuccess),
        tap(action => {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.token,
            JSON.stringify(action.payload),
          );
          this.panamax.persistToken(action.payload);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private loginDataService: LoginDataService,
    private panamax: PanAppState,
    private store: Store,
    private authenticationService: AuthenticationService,
    @Inject('refreshAuthTokenPaddingTimeSeconds') public paddingTime: number,
  ) {}
}
