import { SelectedCustomerState } from '@panamax/app-state';
import { Customer } from '@usf/customer-types/customer';
import { OrderHeader, OrderItem, Tallies } from '@usf/ngrx-order';
import { QuickFilter } from '../components/usf-filtering-banner/model/quick-filter.model';
import { DisplayType } from '../constants/order-status-title.enum';
import { ImportedOrdersFiltersState } from '@usf/ngrx-order/lib/models/state/imported-orders-filters-state';

export interface MyOrdersViewModel {
  myOrdersRows: MyOrdersRow[];
  customers: Customer[];
  filters: any;
  defaultDateFilter: Object;
  itemHeights: number[];
  filterCounts: MyOrdersFilterCounts;
  quickFilters?: QuickFilter[];
  orderStatusDisplayEnabled?: boolean;
}

export interface CustomerOrdersViewModel {
  myOrdersRows: MyOrdersRow[];
  pastOrdersRows: PastOrdersRow[];
  customers: Customer[];
  itemHeights: number[];
  pastOrderItemHeights: number[];
  orderCounts: CustomerOrdersCounts;
  selectedCustomer: SelectedCustomerState;
}

export interface ImportedOrdersViewModel {
  myOrdersRows: MyOrdersRow[];
  customers: Customer[];
  filters: ImportedOrdersFiltersState;
  defaultDateFilter: Object;
  itemHeights: number[];
  orderCounts: ImportedOrdersCounts;
  quickFilters?: QuickFilter[];
}

export interface MyOrdersFilterCounts {
  submittedCount: number;
  submittedWithExceptionsCount: number;
  myDeliveriesCount: number;
  openOrdersCount: number;
}

export interface CustomerOrdersCounts {
  submittedCount: number;
  openOrdersCount: number;
  totalCount: number;
}

export interface ImportedOrdersCounts {
  totalCount: number;
  submittedCount: number;
  submittedWithExceptionsCount: number;
}

export interface MyOrdersTallies extends Tallies {
  totalCasesOrdered: number;
  totalCasesReserved: number;
  totalEachesOrdered: number;
  totalEachesReserved: number;
}

export enum MyOrdersRowType {
  DateRow,
  OrderRow,
  NoOrderRow,
  TitleRow,
}

export enum MyOrdersQuickFilters {
  MY_DELIVERIES = 'MY_DELIVERIES',
  OPEN_ORDERS = 'OPEN_ORDERS',
}

export enum ImportedOrdersQuickFilters {
  ALL_IMPORTED_ORDERS = 'ALL_IMPORTED_ORDERS',
  SUBMITTED_WITH_EXCEPTIONS = 'SUBMITTED_WITH_EXCEPTIONS',
  SUBMITTED = 'SUBMITTED',
}

export interface MyOrdersRow {
  rowType: MyOrdersRowType;
}

export class TitleRow implements MyOrdersRow {
  rowType: MyOrdersRowType = MyOrdersRowType.TitleRow;
  displayType: DisplayType;
  title: string;
  itemCount: number;

  constructor(title: string, itemCount: number, displayType: DisplayType) {
    this.title = title;
    this.itemCount = itemCount;
    this.displayType = displayType;
  }
}
export class DateRow implements MyOrdersRow {
  rowType: MyOrdersRowType = MyOrdersRowType.DateRow;
  date: string;

  constructor(date: string) {
    this.date = date;
  }
}
export class NoOrderRow implements MyOrdersRow {
  rowType: MyOrdersRowType = MyOrdersRowType.NoOrderRow;
  deliveryDate: Date | string;
  customer: Customer;
  currentCustomerInState: SelectedCustomerState;
  displayType: DisplayType;
  orderHeader: OrderHeader;
}

export class OrderRow implements MyOrdersRow {
  rowType: MyOrdersRowType = MyOrdersRowType.OrderRow;
  orderHeader: OrderHeader;
  tallies: MyOrdersTallies;
  displayType: DisplayType;
  displayTitle: string;
  itemInCart: boolean;
  iconUrl: string;
  customer: Customer;
  isEditable: boolean;
  isCancellable: boolean;
  deliveryDate: Date | string;
  hasDeliveryInfo: boolean;
  wmtDeliveryDate?: {
    isDelivered: boolean;
    start: Date;
    end: Date;
    deliveryDate: Date;
  };
  deliveredDtm: Date | string;
  orderDelivered: boolean;
  isTmUser: boolean;
  currentCustomerInState: SelectedCustomerState;
  restrictToOG: boolean;
  hasETADetails?: boolean;
  modifyLinkText: string;
  totalPrice: number;
  orderStatusDisplayEnabled?: boolean;
}
export class PastOrdersRow extends OrderRow {
  orderItems: OrderItem[];
}
