import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { AuthConnectService, LOCAL_STORAGE_KEYS } from '@panamax/app-state';
import { TranslationResolverService } from '../shared/services/translation-resolver.service';
import { PATHS } from '../shared/constants/paths';
import { UserService } from '../user/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.page.html',
  styleUrls: ['./login-error.page.scss'],
})
export class LoginErrorPage implements ViewWillEnter {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authConnectService: AuthConnectService,
    private translationResolverService: TranslationResolverService,
    private userService: UserService,
  ) {}

  errorMessage = '';
  userStatus$: Observable<string>;

  ionViewWillEnter() {
    this.authConnectService.clearStorage();
    this.translationResolverService.clearTranslationFileCache();
    // TODO PAN22-3664: Will update this logic after auth api changes for addressing 401s, 403s, etc.
    // this.setErrorMessage();
  }

  setErrorMessage() {
    switch (this.getErrorStatus()) {
      case '401':
        this.getUserStatus();
        this.errorMessage = 'Authorization Error';
        break;
      case '403':
        this.errorMessage = 'Authentication Error';
        break;
      default:
        this.errorMessage =
          'Your session has timed out due to invalid tokens or missing data';
        break;
    }
  }

  getErrorStatus() {
    return this.activatedRoute.snapshot.queryParams['errorStatus'] || 0;
  }

  getUserStatus() {
    this.userStatus$ = this.userService.userStatus$();
  }

  tryAgain() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.params);
    this.authConnectService.logout().then(() => {
      this.router.navigate([PATHS.LOGIN]);
    });
  }
}
