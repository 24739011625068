import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PATHS } from '@shared/constants/paths';
import { Product } from '@shared/models/product.model';
import { LoadingSpinnerService } from '@shared/services/loading-spinner/loading-spinner.service';
import {
  VISUAL_PUNCHTHRU_ACTIONS,
  Order,
  OrderItem,
  OrderHeader,
} from '@usf/ngrx-order';

import { ProductPropertiesEnum } from '@usf/product-types';

@Injectable({
  providedIn: 'root',
})
export class VisualPunchThruService {
  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private router: Router,
    private loadingSpinnerService: LoadingSpinnerService,
  ) {}

  finalizeAndSubmitVptOrder(
    order: Order,
    productMap: Map<number, Product>,
    nextDeliveryDate: string,
  ): void {
    const orderHeader: OrderHeader = this.finalizeOrderHeaderBeforeSubmission(
      order.orderHeader,
      nextDeliveryDate,
    );
    const orderItems: OrderItem[] = this.enrichOrderItems(
      order.orderItems,
      productMap,
    );
    const finalOrder = {
      orderHeader,
      orderItems,
    };

    this.store.dispatch(
      VISUAL_PUNCHTHRU_ACTIONS.submitVptOrder({ order: finalOrder }),
    );
  }

  finalizeOrderHeaderBeforeSubmission(
    orderHeader: OrderHeader,
    nextDeliveryDate: string,
  ): OrderHeader {
    let requestedDeliveryDate;
    let confirmedDeliveryDate;
    if (
      !!orderHeader.requestedDeliveryDate &&
      this.isDeliveryDateInFuture(orderHeader.requestedDeliveryDate)
    ) {
      requestedDeliveryDate = orderHeader.requestedDeliveryDate;
      confirmedDeliveryDate = !!orderHeader?.confirmedDeliveryDate
        ? orderHeader.confirmedDeliveryDate
        : orderHeader?.requestedDeliveryDate;
    } else {
      requestedDeliveryDate = new Date(nextDeliveryDate).toISOString();
      confirmedDeliveryDate = new Date(nextDeliveryDate).toISOString();
    }

    return (orderHeader = {
      ...orderHeader,
      decomposeFlag: true,
      requestedDeliveryDate,
      confirmedDeliveryDate,
    });
  }

  isDeliveryDateInFuture(deliveryDate: string | Date) {
    const dateToCompare = new Date(deliveryDate);
    const today = new Date();
    return dateToCompare > today;
  }

  handleVptSubmissionFailure() {
    this.loadingSpinnerService.dismissSpinnerModal();
    this.router.navigate([PATHS.ERROR]);
  }

  enrichOrderItems(
    orderItems: OrderItem[],
    productMap: Map<number, Product>,
  ): OrderItem[] {
    const enrichedOrderItems = [];
    for (const orderItem of orderItems) {
      const product = productMap.get(orderItem.productNumber);

      const updatedOrderItem = {
        ...orderItem,
        specialVendor: this.isSpecialVendor(product),
        vendorLeadTime: product?.summary?.vendorLeadTime,
        purchasedFromVendor: product?.inventory?.purchaseFromVendor,
      };
      enrichedOrderItems.push(updatedOrderItem);
    }
    return enrichedOrderItems;
  }

  isSpecialVendor(product: Product): boolean {
    return !!product?.summary.properties?.has(
      ProductPropertiesEnum.specialVendor,
    );
  }
}
