<ion-header class="ion-no-border ion-header-modal-mobile">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        id="back-modal-btn"
        data-cy="selected-product-back-modal-button"
        text=""
      ></ion-back-button>
    </ion-buttons>
    <ion-label data-cy="selected-action-text">
      {{ 'i18n.inventory.inventoryWorksheet.' + selectedAction | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-content-modal-mobile">
  <!-- MOVE/COPY -->
  <div class="selected-items-memos">
    <div
      *ngIf="selectedAction !== InventoryActions.changeUnits"
      class="move-copy"
    >
      <span data-cy="select-item-memo-copy-text">
        {{ 'i18n.inventory.inventoryWorksheet.currentGroup' | translate }}
      </span>
      <ion-item class="current-group-text">
        <ion-label>
          {{ currentGroupText }}
        </ion-label>
      </ion-item>
      <span
        *ngIf="selectedAction === InventoryActions.moveToGroup"
        data-cy="select-product-memo-move-text"
      >
        {{ 'i18n.inventory.inventoryWorksheet.moveToGroupText' | translate }}
        {{
          (inventory?.inventoryOptions?.selections?.length > 1
            ? 'i18n.inventory.inventoryWorksheet.theseItems'
            : 'i18n.inventory.inventoryWorksheet.thisItem'
          ) | translate
        }}
      </span>
      <span
        *ngIf="selectedAction === InventoryActions.copyToGroup"
        data-cy="select-product-memo-copy-text"
      >
        {{ 'i18n.inventory.inventoryWorksheet.copyToGroupText' | translate }}
        {{
          (inventory?.inventoryOptions?.selections?.length > 1
            ? 'i18n.inventory.inventoryWorksheet.theseItems'
            : 'i18n.inventory.inventoryWorksheet.thisItem'
          ) | translate
        }}
      </span>
      <div *ngIf="sortedGroups" class="groups-list">
        <ion-radio-group
          [(ngModel)]="selectedGroup"
          (ionChange)="onSelectGroup($event)"
        >
          <ion-item *ngFor="let group of sortedGroups">
            <ion-label
              [attr.data-cy]="'group-name' + group?.groupName + 'name'"
            >
              {{ group?.groupName }}
            </ion-label>
            <ion-radio
              mode="md"
              slot="end"
              [value]="group"
              [attr.data-cy]="'group-name' + group?.groupName + 'radio'"
            ></ion-radio>
          </ion-item>
        </ion-radio-group>
      </div>
    </div>
    <!-- CHANGE UNITS -->
    <div *ngIf="selectedAction === InventoryActions.changeUnits">
      <ion-row class="inventory-change-unit-header">
        <ion-label class="inventory-unit">
          {{
            (inventory?.inventoryOptions?.selections?.length > 1
              ? 'i18n.inventory.inventoryWorksheet.inventoryItemModal.useFieldsMultiple'
              : 'i18n.inventory.inventoryWorksheet.inventoryItemModal.useFields'
            ) | translate
          }}
        </ion-label>
        <ion-label
          *ngIf="inventoryWorksheetService.someCatchWeight"
          class="catchweight-disclaimer"
        >
          <span class="bold">
            {{ 'i18n.common.noteCaps' | translate }}&nbsp;
          </span>
          {{
            'i18n.inventory.inventoryWorksheet.inventoryItemModal.catchWeightDisclaimerStart'
              | translate
          }}
          <span class="italic"
            >{{
              'i18n.inventory.inventoryWorksheet.inventoryItemModal.pounds'
                | translate
            }}.</span
          >
          {{
            'i18n.inventory.inventoryWorksheet.inventoryItemModal.catchWeightDisclaimerEnd'
              | translate
          }}
        </ion-label>
      </ion-row>
      <ion-row class="ion-justify-content-between">
        <ion-col class="inventory-change-uom-column" size="5.4">
          <ion-label>
            {{
              'i18n.inventory.inventoryWorksheet.inventoryItemModal.inventoryUnit'
                | translate
            }}
          </ion-label>
          <ion-item
            data-cy="inventory-action-modal-change-uom-button"
            button
            detail-icon="caret-down-outline"
            (click)="selectInventoryItemUOM($event)"
          >
            <ion-label
              id="unit-of-measure"
              data-cy="inventory-action-modal-change-uom-button-label"
            >
              {{ selectedUOM }}
            </ion-label>
          </ion-item>
          <ion-item
            *ngIf="selectedUOM === 'Custom'"
            lines="none"
            mode="ios"
            class="text-input"
            [class.error-highlight]="
              customUnitInput.value &&
              existingLabels.includes(customUnitInput.value.toUpperCase())
            "
          >
            <ion-input
              aria-labelledby="unit-of-measure"
              alphanumericExceptAndroid
              data-cy="inventory-action-modal-unit-by-case-input"
              inputmode="text"
              class="custom-uom-input"
              maxlengthAndroid
              [max]="8"
              type="text"
              placeholder="{{
                'i18n.inventory.inventoryWorksheet.inventoryItemModal.custom'
                  | translate
              }}"
              (ionChange)="setCustomUOM($event)"
              [formControl]="customUnitInput"
            ></ion-input>
            <span *ngIf="customUnitInput.errors?.pattern" slot="error"
              >{{
                'i18n.inventory.inventoryWorksheet.inventoryItemModal.specialCharacters'
                  | translate
              }}
            </span>
            <span
              *ngIf="
                customUnitInput.errors?.maxlength &&
                !customUnitInput.errors?.pattern
              "
              slot="error"
              >{{
                'i18n.inventory.inventoryWorksheet.inventoryItemModal.customUnitMaxLength'
                  | translate
              }}
            </span>
          </ion-item>
          <span
            class="error"
            *ngIf="
              customUnitInput.value &&
              customUnitInput.valid &&
              existingLabels.includes(customUnitInput.value.toUpperCase())
            "
          >
            {{
              'i18n.inventory.inventoryWorksheet.inventoryCustomModal.uniqueError'
                | translate
            }}
          </span>
        </ion-col>
        <ion-col class="inventory-conversion-column" size="5.4">
          <ion-label id="inventory-unit">
            {{
              'i18n.inventory.inventoryWorksheet.inventoryItemModal.inventoryUnitFull'
                | translate
            }}
          </ion-label>
          <ion-item class="text-input">
            <ion-input
              aria-labelledby="inventory-unit"
              data-cy="inventory-action-modal-unit-by-case-input"
              inputmode="numeric"
              maxlength="5"
              type="text"
              [value]="newConversionFactor"
              (ionChange)="setConversionFactor($event)"
              [maxLeftHandDigits]="3"
              [maxPrecision]="2"
              customDecimal
            >
            </ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="cancel-modal-btn"
        data-cy="cancel-selected-items-modal-button"
        class="usf-outline-green-mobile-modal-button"
        (click)="dismissModal()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        id="submit-modal-btn"
        data-cy="submit-selected-items-modal-button"
        [ngClass]="
          selectedAction === InventoryActions.changeUnits
            ? selectedUOM === 'Custom'
              ? customUOM &&
                !customUnitInput.errors &&
                !existingLabels.includes(customUOM.toUpperCase())
                ? 'usf-fill-green-dsktp-tblt-modal-button'
                : 'usf-fill-disabled-dsktp-tblt-modal-button'
              : newConversionFactor || newUOM
              ? 'usf-fill-green-dsktp-tblt-modal-button'
              : 'usf-fill-disabled-dsktp-tblt-modal-button'
            : selectedGroup?.groupName
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-disabled-dsktp-tblt-modal-button'
        "
        (click)="dispatchAction(selectedAction)"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
