import {
  DeliveryDetailsState,
  Order,
  OrderHeader,
  OrderState,
  OrderStatus,
} from '@usf/ngrx-order';
import { PartnerState } from '@usf/ngrx-order/lib/models/state/partner-state';
import { DeliveryDetails } from '@usf/order-types';
import { SiteCustomizationProfile } from '@usf/user-types/site-customization';
import { OrderStatusDetails } from 'src/app/shared/constants/order-status-title.enum';

export class OrderHeaderService {
  static orderIsPreparingToShip = (orderHeader: OrderHeader) => {
    if (
      orderHeader?.orderType === 'VS' &&
      orderHeader.orderModifiable === true &&
      (orderHeader.orderStatus === OrderStatus.SUBMITTED ||
        orderHeader.orderStatus === OrderStatus.SUBMITTED_WITH_EXCEPTIONS ||
        orderHeader.orderStatus === OrderStatus.SUBMITTED_CREDIT_HOLD) &&
      (!orderHeader?.orderTracking?.length || !orderHeader?.trackingAvailable)
    ) {
      var minutesElapsed = 0;
      if (orderHeader?.orderPlacedDtm) {
        const orderPlacedDtm = new Date(orderHeader.orderPlacedDtm);

        const now = new Date();
        const differenceInMilliseconds =
          now.getTime() - orderPlacedDtm.getTime();

        minutesElapsed = differenceInMilliseconds / (1000 * 60);
      } else if (orderHeader.updateDtm) {
        const updateDtm = new Date(orderHeader.updateDtm);

        const now = new Date();
        const differenceInMilliseconds = now.getTime() - updateDtm.getTime();

        minutesElapsed = differenceInMilliseconds / (1000 * 60);
      }
      return minutesElapsed > 30;
    } else {
      return false;
    }
  };

  static isOrderEditable = (
    header: OrderHeader,
    allowVendorShipped: boolean,
  ) => {
    if (
      allowVendorShipped &&
      header?.orderType === 'VS' &&
      header.orderModifiable === true &&
      header.orderStatus !== OrderStatus.SUBMITTING
    ) {
      var minutesElapsed = 0;
      if (header?.orderPlacedDtm) {
        const orderPlacedDtm = new Date(header.orderPlacedDtm);

        const now = new Date();
        const differenceInMilliseconds =
          now.getTime() - orderPlacedDtm.getTime();

        minutesElapsed = differenceInMilliseconds / (1000 * 60);
      } else if (header.updateDtm) {
        const updateDtm = new Date(header.updateDtm);

        const now = new Date();
        const differenceInMilliseconds = now.getTime() - updateDtm.getTime();

        minutesElapsed = differenceInMilliseconds / (1000 * 60);
      }
      return minutesElapsed <= 30;
    } else {
      return (
        header !== undefined &&
        header !== null &&
        header.orderModifiable === true &&
        header.orderType !== 'VS' &&
        header.orderStatus !== OrderStatus.SUBMITTING
      );
    }
  };

  static isOrderCancellable = (
    header: OrderHeader,
    allowVendorShipped: boolean,
  ) => {
    if (allowVendorShipped && header?.orderStatus !== OrderStatus.SUBMITTING) {
      return OrderHeaderService.isOrderEditable(header, allowVendorShipped);
    } else {
      return (
        header !== undefined &&
        header !== null &&
        header.orderModifiable === true &&
        header.orderType !== 'VS'
      );
    }
  };

  static getDisplayTitle = (
    orderStatusDetails: OrderStatusDetails,
    orderHasWmtInfo: boolean,
    etaRangeMinimum: string | Date,
    etaRangeMaximum: string | Date,
    orderDelivered?: boolean,
    order?: Order,
    siteCustomizations?: SiteCustomizationProfile,
    partnerState?: PartnerState,
  ) => {
    let displayTitle =
      orderStatusDetails?.translationKey || orderStatusDetails?.title;
    if (orderStatusDetails === OrderStatusDetails.PREPARING_TO_SHIP) {
      displayTitle = orderStatusDetails?.translationKey;
    } else if (
      (orderDelivered ?? false) ||
      (!etaRangeMinimum &&
        !etaRangeMaximum &&
        !!order?.orderHeader?.deliveredDtm &&
        orderHasWmtInfo)
    ) {
      displayTitle = 'i18n.orderStatusDisplayTitles.delivered';
    } else if (
      !!partnerState?.partnerId &&
      !!order?.orderHeader?.purchaseOrderNumber &&
      siteCustomizations?.profileName?.toLowerCase() === 'brookdale'
    ) {
      if (
        order.orderHeader.orderStatus === OrderStatus.SUBMITTED ||
        order.orderHeader.orderStatus === OrderStatus.SUBMITTED_CREDIT_HOLD
      ) {
        return 'i18n.orderStatusDisplayTitles.transmitted';
      } else if (
        order.orderHeader.orderStatus === OrderStatus.SUBMITTED_WITH_EXCEPTIONS
      ) {
        return 'i18n.orderStatusDisplayTitles.transmittedWExceptions';
      }
    }
    return displayTitle;
  };

  static getListOfWMTOrders = (orderState: OrderState): Set<number> => {
    const deliveryDetailsArr = (orderState.ids as string[])
      .filter(id => !!orderState.entities[id])
      .map(id => orderState.entities[id]);
    const res = new Set<number>();
    deliveryDetailsArr.forEach(order => {
      const orderNumber: string = order?.orderHeader?.tandemOrderNumber?.toString();
      orderNumber
        ?.split('|')
        .map(orderNumber => Number(orderNumber))
        ?.forEach(number => {
          res.add(number);
        });
    });
    return res;
  };

  static getDeliveryDateRange = (
    actualDeliveryDate: string | Date,
    estimatedDeliveryDate: string | Date,
    isDelivered: boolean,
    etaMin: string | Date,
    etaMax: string | Date,
  ): {
    isDelivered: boolean;
    start: string | Date;
    end: string | Date;
    deliveryDate: string | Date;
  } => {
    const deliveryDate = new Date(
      isDelivered ? actualDeliveryDate : estimatedDeliveryDate,
    );
    return {
      isDelivered,
      deliveryDate,
      start: new Date(etaMin),
      end: new Date(etaMax),
    };
  };
}
