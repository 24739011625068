<div class="popover-error-inner">
  <ion-icon
    color="success"
    name="close"
    size="large"
    data-cy="error-popover-close-modal-icon"
    (click)="dismiss()"
  >
  </ion-icon>
  <div class="popover-error-content">
    <img
      src="../../../../../assets/images/alert-orange.svg"
      class="alert-icon-border"
    />
    <div class="popover-error-description">
      <div class="error-text">
        <h3 data-cy="error-popover-error-title">Error</h3>
        <p data-cy="error-popover-error-description-text">
          <b>{{ description }}</b>
        </p>
        <span data-cy="error-popover-error-help-text">
          {{ 'i18n.orderPage.confirmDescription' | translate }}</span
        >
      </div>
      <ion-button
        class="usf-fill-orange-dsktp-tblt-modal-button"
        data-cy="error-popover-continue-button"
        (click)="dismiss()"
        >{{ 'i18n.common.continue' | translate }}</ion-button
      >
    </div>
  </div>
</div>
