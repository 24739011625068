import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';
import { LOGIN_ANALYTICS_ACTIONS } from './login-analytics.actions';
import { LoginAnalyticsMessages } from './login-analytics.constants';
import {
  newUserSignUpTransformer,
  newUserPageLoadTransformer,
} from './login-analytics.transformers';

@Injectable({
  providedIn: 'root',
})
export class LoginAnalyticsService {
  constructor(private store: Store) {}

  trackNewUserSignup(): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          pageName: LoginAnalyticsMessages.pageName,
          event: LoginAnalyticsMessages.newUserSignupEvent,
          capability: LoginAnalyticsMessages.capability,
        },
        transformFunc: newUserSignUpTransformer,
      },
    };
    this.store.dispatch(
      LOGIN_ANALYTICS_ACTIONS.trackNewUserSignUp({ tracking }),
    );
  }

  trackNewUserPageLoad(): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          pageName: LoginAnalyticsMessages.pageName,
          event: LoginAnalyticsMessages.pageLoadedEvent,
          capability: LoginAnalyticsMessages.capability,
        },
        transformFunc: newUserPageLoadTransformer,
      },
    };
    this.store.dispatch(
      LOGIN_ANALYTICS_ACTIONS.trackNewUserPageLoad({ tracking }),
    );
  }
}
