/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
  selector: '[zipcode]',
})
export class ZipcodeDirective implements OnInit {
  private regex = new RegExp(/^[0-9]{0,9}$|^[0-9]{5}-[0-9]{0,4}$/);
  private isAndroid = false;
  constructor(private el: ElementRef, private platform: Platform) {}

  ngOnInit(): void {
    this.isAndroid = this.platform.is('android');
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: any) {
    this.onInput(event, true);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.isAndroid) {
      let current: string = this.el.nativeElement.value;
      let next: string = current.concat(event.key);
      const match = String(next).match(this.regex);
      if (next && !match && event.key !== 'Backspace') {
        event.preventDefault();
      }
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: any, backspace: boolean) {
    if (!this.isAndroid) {
      const initialValue = event?.target?.value;
      let newVal = initialValue.replace(/\D/g, '');

      if (backspace && newVal.length === 6) {
        newVal = newVal.substring(0, newVal.length);
        event.target.value = newVal;
        this.el.nativeElement.value = newVal;
        return;
      }

      if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length > 5) {
        newVal = newVal.replace(/^(\d{0,5})(\d{0,4})/, '$1-$2');
      }

      event.target.value = newVal;
      this.el.nativeElement.value = newVal;
    }
  }
}
