import { Customer } from '@usf/customer-types/customer';

export const compareCustomers = (cust1: Customer, cust2: Customer): number => {
  if (cust1.customerName > cust2.customerName) {
    return 1;
  } else if (cust1.customerName < cust2.customerName) {
    return -1;
  }
  return 0;
};
