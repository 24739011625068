<div class="message-banner-container">
  <app-message-bar
    [isMobile]="false"
    [shouldDisplay]="true"
    [radius]="8"
    [textAlign]="'left'"
    [paddingX]="16"
    [paddingY]="16"
  >
    <div class="text-message">
      <ion-icon
        name="alert-circle-outline"
        data-cy="text-message-icon"
      ></ion-icon>
      <p class="primary-text" data-cy="alert-primary-text">
        {{ alertMessage?.primaryText | translate }}
        <span class="secondary-text" data-cy="alert-secondary-text">
          {{ alertMessage?.secondaryText | translate }}
        </span>
        <span class="bold-text" data-cy="alert-bold-text">
          <b>{{ alertMessage?.boldedText | translate }}</b>
        </span>
      </p>
    </div>
  </app-message-bar>
</div>
