import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';
import { Message } from 'src/app/ngrx-message/models/client/message';

export const otelGlobalErrorMes = createAction(
  '[NgRxTrace] Unhandled Angular Exception Log',
  props<{ tracking: Tracking }>(),
);
export const otelHttpErrorMes = createAction(
  '[NgRxTrace] Http Error Exception Log',
  props<{ tracking: Tracking }>(),
);
