import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { ChipTypeEnum } from './models/chip-type.enum';
import { IconConfig } from './models/icon-config.model';
import { PlatformService } from '@panamax/app-state';
import { PopoverController } from '@ionic/angular';
import { UsfChipPopoverOptions } from './models/usf-chip-popover-options.model';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-usf-chip',
  templateUrl: './usf-chip.component.html',
  styleUrls: ['./usf-chip.component.scss'],
})
export class UsfChipComponent implements OnDestroy {
  @Input() type: ChipTypeEnum;
  @Input() clickable = false;
  @Input() iconConfig: IconConfig = {
    iconWidth: 0.8125,
    iconHeight: 0.8125,
    color: '#000',
  };
  @Input() iconUrl: string;
  @Input() popoverOptions: UsfChipPopoverOptions;
  @Input() textColor = '';
  @Input() backgroundColor = '';
  @Output() clickEmitter = new EventEmitter<boolean>();
  @Output() imageError = new EventEmitter<void>();

  triggerId: string = 'popover-trigger-' + uuidv4();
  chipType = ChipTypeEnum;
  productPillPopover: HTMLIonPopoverElement;
  hidePill = false;

  constructor(
    readonly platformService: PlatformService,
    private popoverController: PopoverController,
  ) {}

  ngOnDestroy(): void {
    this.productPillPopover?.dismiss();
  }

  onProductPillClick($event: any) {
    if (!!this.popoverOptions && this.platformService.isTouch.value) {
      this.openProductPillPopover($event);
    } else {
      if (this.clickable) {
        this.clickEmitter.emit();
      }
    }
  }

  async openProductPillPopover(ev: any) {
    if (!!this.popoverOptions && !this.popoverOptions.inline) {
      this.productPillPopover?.dismiss();

      this.productPillPopover = await this.popoverController.create({
        ...this.popoverOptions,
        event: ev,
      });

      this.productPillPopover.present();
    }
  }

  closeProductPillPopover() {
    if (!!this.popoverOptions && !this.popoverOptions.inline) {
      this.productPillPopover?.dismiss();
      this.productPillPopover = undefined;
    }
  }

  onImageLoadError() {
    this.hidePill = true;
    this.imageError.emit();
  }
}
