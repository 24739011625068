<ion-header
  class="ion-no-border"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="(platformService?.isTouchExperience$() | async) === false"
      slot="end"
    >
      <ion-button
        data-cy="close-create-non-usf-modal-button"
        (click)="dismissModal()"
      >
        <ion-icon
          slot="icon-only"
          name="close-outline"
          data-cy="create-vendor-close-icon"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label>
      {{
        (mode === ListActions.create
          ? 'i18n.vendors.addNewVendor'
          : 'i18n.vendors.editVendor'
        ) | translate
      }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="create-edit-non-usf-vendor_content"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <form
    [formGroup]="nonUsfVendorForm"
    (ngSubmit)="onSaveExternalVendor()"
    (keydown.enter)="onSaveExternalVendor()"
  >
    <!-- DESKTOP -->
    <ion-grid
      *ngIf="
        platformService.platformType === platformService.platformEnum.desktop
      "
    >
      <!-- Row 1 - Code, Name, Terms, Contact -->
      <ion-row
        class="vendor-form-row"
        [class.required-input]="
          !nonUsfVendorForm?.controls['code']?.valid &&
          nonUsfVendorForm?.controls['code']?.dirty &&
          (nonUsfVendorForm?.controls['code']?.errors['vendorIdExists'] ||
            nonUsfVendorForm?.controls['code']?.errors['required'])
        "
      >
        <ion-col class="vendor-form-col">
          <span id="vendor-code" data-cy="vendor-code-label">
            {{ 'i18n.vendors.code' | translate }}
            <span
              class="vendor-form-required-text"
              data-cy="vendor-form-required-text"
            >
              {{ 'i18n.common.requiredInputIndicator' | translate }}
            </span>
          </span>
          <ion-input
            aria-labelledby="vendor-code"
            id="vendor-code-input"
            data-cy="vendor-code-input"
            formControlName="code"
            maxlength="10"
            placeholder="{{ 'i18n.vendors.codePlaceholder' | translate }}"
            type="tel"
            [class.input-error]="
              !nonUsfVendorForm?.controls['code']?.valid &&
              (nonUsfVendorForm?.controls['code']?.touched ||
                nonUsfVendorForm?.controls['code']?.dirty) &&
              (nonUsfVendorForm?.controls['code']?.errors['required'] ||
                nonUsfVendorForm?.controls['code']?.errors['vendorIdExists'])
            "
            trimSpace
          >
          </ion-input>
          <div
            *ngIf="
              !nonUsfVendorForm?.controls['code']?.valid &&
              nonUsfVendorForm?.controls['code']?.dirty
            "
            class="vendor-form-error-msg"
          >
            <span
              *ngIf="
                nonUsfVendorForm?.controls['code']?.errors['vendorIdExists'] &&
                !nonUsfVendorForm?.controls['code']?.errors['required']
              "
              data-cy="vendor-id-exists-label"
            >
              {{ 'i18n.vendors.vendorIdExists' | translate }}
            </span>
            <span
              *ngIf="
                !nonUsfVendorForm?.controls['code']?.errors['vendorIdExists'] &&
                nonUsfVendorForm?.controls['code']?.errors['required']
              "
              data-cy="vendor-id-required-field-label"
            >
              {{ 'i18n.requestCredit.requiredField' | translate }}
            </span>
          </div>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span data-cy="vendor-name-label">
            {{ 'i18n.vendors.vendorName' | translate }}
            <span
              class="vendor-form-required-text"
              data-cy="vendor-form-required-label"
            >
              {{ 'i18n.common.requiredInputIndicator' | translate }}
            </span>
          </span>
          <ion-input
            id="vendor-name-input"
            data-cy="vendor-name-input"
            formControlName="name"
            maxlength="25"
            placeholder="{{ 'i18n.vendors.namePlaceholder' | translate }}"
            type="text"
            [class.input-error]="
              !nonUsfVendorForm?.controls['name']?.valid &&
              (nonUsfVendorForm?.controls['name']?.touched ||
                nonUsfVendorForm?.controls['name']?.dirty) &&
              nonUsfVendorForm.controls['name'].errors['required']
            "
            trimSpace
          >
          </ion-input>
          <div
            *ngIf="
              !nonUsfVendorForm?.controls['name']?.valid &&
              nonUsfVendorForm?.controls['name']?.dirty &&
              nonUsfVendorForm?.controls['name']?.errors['required']
            "
            class="vendor-form-error-msg"
          >
            <span data-cy="vendor-request-credit-label">
              {{ 'i18n.requestCredit.requiredField' | translate }}
            </span>
          </div>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span id="vendor-terms" data-cy="vendor-terms-label">{{
            'i18n.vendors.terms' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-terms"
            id="vendor-terms-input"
            data-cy="vendor-terms-input"
            formControlName="terms"
            maxlength="20"
            placeholder="{{ 'i18n.vendors.termsPlaceholder' | translate }}"
            type="text"
            trimSpace
          >
          </ion-input>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span id="vendor-contact" data-cy="vendor-contact-label">{{
            'i18n.vendors.contact' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-contact"
            id="vendor-contact-input"
            data-cy="vendor-contact-input"
            formControlName="contact"
            maxlength="20"
            placeholder="{{ 'i18n.vendors.contactPlaceholder' | translate }}"
            type="text"
            trimSpace
          >
          </ion-input>
        </ion-col>
      </ion-row>

      <!-- Row 2 - Address 1, Address 2, City, State  -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-address" data-cy="vendor-address1-label">
            {{ 'i18n.vendors.address1' | translate }}
          </span>
          <ion-input
            aria-labelledby="vendor-address"
            id="vendor-address1-input"
            data-cy="vendor-address1-input"
            formControlName="address1"
            maxlength="25"
            placeholder="{{ 'i18n.vendors.addressPlaceholder' | translate }}"
            type="text"
            trimSpace
          >
          </ion-input>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span id="vendor-address-2" data-cy="vendor-address2-label">
            {{ 'i18n.vendors.address2' | translate }}
          </span>
          <ion-input
            aria-labelledby="vendor-address-2"
            id="vendor-address2-input"
            data-cy="vendor-address2-input"
            formControlName="address2"
            maxlength="25"
            placeholder="{{ 'i18n.vendors.optional' | translate }}"
            type="text"
            trimSpace
          >
          </ion-input>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span id="vendor-city" data-cy="vendor-city-label">{{
            'i18n.vendors.city' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-city"
            id="vendor-city-input"
            data-cy="vendor-city-input"
            formControlName="city"
            maxlength="20"
            placeholder="{{ 'i18n.vendors.cityPlaceholder' | translate }}"
            type="text"
            alphanumeric
            (paste)="pasteFired($event, 'city')"
            (specialRemovedEmitter)="setShowPasteMsg($event)"
          >
          </ion-input>
          <div *ngIf="showPasteMsg" class="vendor-form-error-msg">
            <span class="non-warning" data-cy="vendor-request-credit-label">
              {{ 'i18n.vendors.specialCharactersRemoved' | translate }}
            </span>
          </div>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span data-cy="vendor-state-label">
            {{ 'i18n.vendors.state' | translate }}
          </span>
          <ion-select
            *ngIf="(platformService?.isTouchExperience$() | async) === false"
            [interfaceOptions]="{
              cssClass: 'states-popover-desktop',
              reference: 'trigger',
              alignment: 'center'
            }"
            interface="popover"
            formControlName="state"
            placeholder="{{ 'i18n.vendors.selectState' | translate }}"
            (ionBlur)="setChevronDirection()"
            (ionFocus)="isPopoverOpen = false"
            data-cy="vendor-select-state-input"
            toggleIcon="chevron-down-outline"
            expandedIcon="chevron-up-outline"
            justify="space-between"
          >
            <ion-select-option
              *ngFor="let state of states"
              [value]="state"
              data-cy="vendor-state-option"
            >
              {{ state }}
            </ion-select-option>
          </ion-select>
          <ion-button
            *ngIf="platformService?.isTouchExperience$() | async"
            fill="clear"
            (click)="openStateOptionsTouch()"
            data-cy="open-state-options-button"
          >
            <div class="add-non-usf-product-vendor">
              <span
                [ngClass]="selectedState === '' ? 'placeholder' : ''"
                data-cy="vendor-selected-state-text"
              >
                {{
                  (selectedState ? selectedState : 'i18n.vendors.selectState')
                    | translate
                }}
              </span>
              <ion-icon
                name="chevron-forward-outline"
                data-cy="select-state-chevron-icon"
              ></ion-icon>
            </div>
          </ion-button>
        </ion-col>
      </ion-row>

      <!-- Row 3 - Zip, Phone, Email -->
      <ion-row class="vendor-form-row">
        <ion-col size="3.2" class="vendor-form-col">
          <span id="vendor-zipcode" data-cy="vendor-zipcode-label">{{
            'i18n.vendors.zipcode' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-zipcode"
            id="vendor-zip-code-input"
            data-cy="vendor-zipcode-input"
            formControlName="zip"
            maxlength="10"
            placeholder="{{ 'i18n.vendors.zipCodePlaceholder' | translate }}"
            zipcode
          >
          </ion-input>
        </ion-col>
        <ion-col size="3.2" class="vendor-form-col">
          <span id="vendor-phone-number" data-cy="vendor-phone-label">{{
            'i18n.vendors.phone' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-phone-number"
            id="vendor-phone-input"
            data-cy="vendor-phone-input"
            formControlName="phone"
            maxlength="14"
            placeholder="{{ 'i18n.vendors.phonePlaceholder' | translate }}"
            type="tel"
            phone
          >
          </ion-input>
        </ion-col>
        <ion-col size="1.5" class="vendor-form-col">
          <span id="vendor-phone-extension" data-cy="vendor-phone-ext-label">{{
            'i18n.vendors.ext' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-phone-extension"
            id="vendor-ext-input"
            data-cy="vendor-phone-ext-input"
            formControlName="ext"
            maxlength="4"
            placeholder="{{ 'i18n.vendors.extensionPlaceholder' | translate }}"
            type="tel"
            numeric-alt
            [maxLen]="4"
            (paste)="pasteFired($event, 'ext')"
          >
          </ion-input>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span id="vendor-email" data-cy="vendor-email-label">{{
            'i18n.vendors.email' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-email"
            id="vendor-email-input"
            data-cy="vendor-email-input"
            formControlName="email"
            maxlength="80"
            placeholder="{{ 'i18n.vendors.emailPlaceholder' | translate }}"
            type="email"
            [class.input-error]="
              !nonUsfVendorForm?.controls['email']?.valid &&
              (nonUsfVendorForm?.controls['email']?.touched ||
                nonUsfVendorForm?.controls['email']?.dirty) &&
              nonUsfVendorForm?.controls['email']?.errors
            "
            trimSpace
          >
          </ion-input>
          <div
            *ngIf="
              !nonUsfVendorForm?.controls['email']?.valid &&
              nonUsfVendorForm?.controls['email']?.dirty
            "
            class="vendor-form-error-msg"
          >
            <span
              *ngIf="nonUsfVendorForm?.controls['email']?.errors"
              data-cy="vendor-invalid-email-label"
            >
              {{ 'i18n.vendors.emailValidation' | translate }}
            </span>
          </div>
        </ion-col>
      </ion-row>

      <!-- Row 4 - Comments -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-comments" data-cy="vendor-comments-label">
            {{ 'i18n.vendors.comments' | translate }}
          </span>
          <ion-input
            aria-labelledby="vendor-comments"
            id="vendor-comment-input"
            data-cy="vendor-comments-input"
            formControlName="comment"
            maxlength="120"
            placeholder="{{ 'i18n.vendors.commentPlaceholder' | translate }}"
            type="text"
            trimSpace
          ></ion-input>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- TABLET -->
    <ion-grid
      *ngIf="
        platformService.platformType === platformService.platformEnum.tablet
      "
    >
      <!-- Row 1 - Code, Name, Terms -->
      <ion-row
        class="vendor-form-row"
        [class.required-input]="
          !nonUsfVendorForm?.controls['code']?.valid &&
          nonUsfVendorForm?.controls['code']?.dirty &&
          (nonUsfVendorForm?.controls['code']?.errors['vendorIdExists'] ||
            nonUsfVendorForm?.controls['code']?.errors['required'])
        "
      >
        <ion-col class="vendor-form-col">
          <span id="vendor-code" data-cy="vendor-code-label">
            {{ 'i18n.vendors.code' | translate }}
            <span
              class="vendor-form-required-text"
              data-cy="vendor-form-required-text"
            >
              {{ 'i18n.common.requiredInputIndicator' | translate }}
            </span>
          </span>
          <ion-input
            aria-labelledby="vendor-code"
            id="vendor-code-input"
            data-cy="vendor-code-input"
            formControlName="code"
            maxlength="10"
            placeholder="{{ 'i18n.vendors.codePlaceholder' | translate }}"
            type="tel"
            [class.input-error]="
              !nonUsfVendorForm?.controls['code']?.valid &&
              (nonUsfVendorForm?.controls['code']?.touched ||
                nonUsfVendorForm?.controls['code']?.dirty) &&
              (nonUsfVendorForm?.controls['code']?.errors['required'] ||
                nonUsfVendorForm?.controls['code']?.errors['vendorIdExists'])
            "
            trimSpace
          >
          </ion-input>
          <div
            *ngIf="
              !nonUsfVendorForm?.controls['code']?.valid &&
              nonUsfVendorForm?.controls['code']?.dirty
            "
            class="vendor-form-error-msg"
          >
            <span
              *ngIf="
                nonUsfVendorForm?.controls['code']?.errors['vendorIdExists'] &&
                !nonUsfVendorForm?.controls['code']?.errors['required']
              "
              data-cy="vendor-id-exists-label"
            >
              {{ 'i18n.vendors.vendorIdExists' | translate }}
            </span>
            <span
              *ngIf="
                !nonUsfVendorForm?.controls['code']?.errors['vendorIdExists'] &&
                nonUsfVendorForm?.controls['code']?.errors['required']
              "
              data-cy="vendor-id-required-field-label"
            >
              {{ 'i18n.requestCredit.requiredField' | translate }}
            </span>
          </div>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span id="vendor-name" data-cy="vendor-name-label">
            {{ 'i18n.vendors.vendorName' | translate }}
            <span
              class="vendor-form-required-text"
              data-cy="vendor-form-required-label"
            >
              {{ 'i18n.common.requiredInputIndicator' | translate }}
            </span>
          </span>
          <ion-input
            aria-labelledby="vendor-name"
            id="vendor-name-input"
            data-cy="vendor-name-input"
            formControlName="name"
            maxlength="25"
            placeholder="{{ 'i18n.vendors.namePlaceholder' | translate }}"
            type="text"
            [class.input-error]="
              !nonUsfVendorForm?.controls['name']?.valid &&
              (nonUsfVendorForm?.controls['name']?.touched ||
                nonUsfVendorForm?.controls['name']?.dirty) &&
              nonUsfVendorForm.controls['name'].errors['required']
            "
            trimSpace
          >
          </ion-input>
          <div
            *ngIf="
              !nonUsfVendorForm?.controls['name']?.valid &&
              nonUsfVendorForm?.controls['name']?.dirty &&
              nonUsfVendorForm?.controls['name']?.errors['required']
            "
            class="vendor-form-error-msg"
          >
            <span data-cy="vendor-request-credit-label">
              {{ 'i18n.requestCredit.requiredField' | translate }}
            </span>
          </div>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span id="vendor-terms" data-cy="vendor-terms-label">{{
            'i18n.vendors.terms' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-terms"
            id="vendor-terms-input"
            data-cy="vendor-terms-input"
            formControlName="terms"
            maxlength="20"
            placeholder="{{ 'i18n.vendors.termsPlaceholder' | translate }}"
            type="text"
            trimSpace
          >
          </ion-input>
        </ion-col>
      </ion-row>

      <!-- Row 2 - Contact, Address 1, Address 2 -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-contact" data-cy="vendor-contact-label">{{
            'i18n.vendors.contact' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-contact"
            id="vendor-contact-input"
            data-cy="vendor-contact-input"
            formControlName="contact"
            maxlength="20"
            placeholder="{{ 'i18n.vendors.contactPlaceholder' | translate }}"
            type="text"
            trimSpace
          >
          </ion-input>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span id="vendor-address" data-cy="vendor-address1-label">
            {{ 'i18n.vendors.address1' | translate }}
          </span>
          <ion-input
            aria-labelledby="vendor-address"
            id="vendor-address1-input"
            data-cy="vendor-address1-input"
            formControlName="address1"
            maxlength="25"
            placeholder="{{ 'i18n.vendors.addressPlaceholder' | translate }}"
            type="text"
            trimSpace
          >
          </ion-input>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span id="vendor-address-2" data-cy="vendor-address2-label">
            {{ 'i18n.vendors.address2' | translate }}
          </span>
          <ion-input
            aria-labelledby="vendor-address-2"
            id="vendor-address2-input"
            data-cy="vendor-address2-input"
            formControlName="address2"
            maxlength="25"
            placeholder="{{ 'i18n.vendors.optional' | translate }}"
            type="text"
            trimSpace
          >
          </ion-input>
        </ion-col>
      </ion-row>

      <!-- Row 3 - City, State, Zip -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-address" data-cy="vendor-city-label">{{
            'i18n.vendors.city' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-address"
            id="vendor-city-input"
            data-cy="vendor-city-input"
            formControlName="city"
            maxlength="20"
            placeholder="{{ 'i18n.vendors.cityPlaceholder' | translate }}"
            type="text"
            alphanumeric
            (paste)="pasteFired($event, 'city')"
            (specialRemovedEmitter)="setShowPasteMsg($event)"
          >
          </ion-input>
          <div *ngIf="showPasteMsg" class="vendor-form-error-msg">
            <span class="non-warning" data-cy="vendor-request-credit-label">
              {{ 'i18n.vendors.specialCharactersRemoved' | translate }}
            </span>
          </div>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span id="vendor-state" data-cy="vendor-state-label">
            {{ 'i18n.vendors.state' | translate }}
          </span>
          <ion-select
            aria-labelledby="vendor-state"
            *ngIf="(platformService?.isTouchExperience$() | async) === false"
            [interfaceOptions]="{
              cssClass: 'states-popover-desktop',
              reference: 'trigger'
            }"
            interface="popover"
            formControlName="state"
            placeholder="{{ 'i18n.vendors.selectState' | translate }}"
            (ionBlur)="setChevronDirection()"
            (ionFocus)="isPopoverOpen = false"
            [ngClass]="isPopoverOpen ? 'popover-open' : 'popover-closed'"
            data-cy="vendor-select-state-input"
          >
            <ion-select-option
              *ngFor="let state of states"
              [value]="state"
              data-cy="vendor-state-option"
            >
              {{ state }}
            </ion-select-option>
          </ion-select>
          <ion-button
            *ngIf="platformService?.isTouchExperience$() | async"
            fill="clear"
            (click)="openStateOptionsTouch()"
            data-cy="open-state-options-button"
          >
            <div class="add-non-usf-product-vendor">
              <span
                [ngClass]="selectedState === '' ? 'placeholder' : ''"
                data-cy="vendor-selected-state-text"
              >
                {{
                  (selectedState ? selectedState : 'i18n.vendors.selectState')
                    | translate
                }}
              </span>
              <ion-icon
                name="chevron-forward-outline"
                data-cy="select-state-chevron-icon"
              ></ion-icon>
            </div>
          </ion-button>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span id="vendor-zipcode" data-cy="vendor-zipcode-label">{{
            'i18n.vendors.zipcode' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-zipcode"
            id="vendor-zip-code-input"
            data-cy="vendor-zipcode-input"
            formControlName="zip"
            maxlength="10"
            placeholder="{{ 'i18n.vendors.zipCodePlaceholder' | translate }}"
            zipcode
          >
          </ion-input>
        </ion-col>
      </ion-row>

      <!-- Row 4 - Phone and Email -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-phone-number" data-cy="vendor-phone-label">{{
            'i18n.vendors.phone' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-phone-number"
            id="vendor-phone-input"
            data-cy="vendor-phone-input"
            formControlName="phone"
            maxlength="14"
            placeholder="{{ 'i18n.vendors.phonePlaceholder' | translate }}"
            type="tel"
            phone
          >
          </ion-input>
        </ion-col>
        <ion-col size="2" class="vendor-form-col">
          <span id="vendor-phone-extension" data-cy="vendor-phone-ext-label">{{
            'i18n.vendors.ext' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-phone-extension"
            id="vendor-ext-input"
            data-cy="vendor-phone-ext-input"
            formControlName="ext"
            maxlength="4"
            placeholder="{{ 'i18n.vendors.extensionPlaceholder' | translate }}"
            type="tel"
            numeric-alt
            [maxLen]="4"
            (paste)="pasteFired($event, 'ext')"
          >
          </ion-input>
        </ion-col>
        <ion-col class="vendor-form-col">
          <span id="vendor-email" data-cy="vendor-email-label">{{
            'i18n.vendors.email' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-email"
            id="vendor-email-input"
            data-cy="vendor-email-input"
            formControlName="email"
            maxlength="80"
            placeholder="{{ 'i18n.vendors.emailPlaceholder' | translate }}"
            type="text"
            trimSpace
          >
          </ion-input>
          <div
            *ngIf="
              !nonUsfVendorForm?.controls['email']?.valid &&
              nonUsfVendorForm?.controls['email']?.dirty
            "
            class="vendor-form-error-msg"
          >
            <span
              *ngIf="nonUsfVendorForm?.controls['email']?.errors"
              data-cy="vendor-invalid-email-label"
            >
              {{ 'i18n.vendors.emailValidation' | translate }}
            </span>
          </div>
        </ion-col>
      </ion-row>

      <!-- Row 4 - Comments -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-comments" data-cy="vendor-comments-label">
            {{ 'i18n.vendors.comments' | translate }}
          </span>
          <ion-input
            aria-labelledby="vendor-comments"
            id="vendor-comment-input"
            data-cy="vendor-comments-input"
            formControlName="comment"
            maxlength="120"
            placeholder="{{ 'i18n.vendors.commentPlaceholder' | translate }}"
            type="text"
            trimSpace
          ></ion-input>
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- MOBILE -->
    <ion-grid
      *ngIf="
        platformService.platformType === platformService.platformEnum.mobile
      "
    >
      <!-- Code -->
      <ion-row
        class="vendor-form-row"
        [class.required-input]="
          !nonUsfVendorForm?.controls['code']?.valid &&
          nonUsfVendorForm?.controls['code']?.dirty &&
          nonUsfVendorForm?.controls['code']?.errors['vendorIdExists'] &&
          !nonUsfVendorForm?.controls['code']?.errors['required']
        "
      >
        <ion-col class="vendor-form-col">
          <span id="vendor-code" data-cy="vendor-code-label">
            {{ 'i18n.vendors.code' | translate }}
            <span
              class="vendor-form-required-text"
              data-cy="vendor-code-required-text"
            >
              {{ 'i18n.common.requiredInputIndicator' | translate }}
            </span>
          </span>
          <ion-input
            aria-labelledby="vendor-code"
            id="vendor-code-input"
            data-cy="vendor-code-input"
            formControlName="code"
            maxlength="10"
            placeholder="{{ 'i18n.vendors.codePlaceholder' | translate }}"
            type="tel"
            [class.input-error]="
              !nonUsfVendorForm?.controls['code']?.valid &&
              (nonUsfVendorForm?.controls['code']?.touched ||
                nonUsfVendorForm?.controls['code']?.dirty) &&
              (nonUsfVendorForm?.controls['code']?.errors['required'] ||
                nonUsfVendorForm?.controls['code']?.errors['vendorIdExists'])
            "
            trimSpace
          >
          </ion-input>
          <div
            *ngIf="
              !nonUsfVendorForm?.controls['code']?.valid &&
              nonUsfVendorForm?.controls['code']?.dirty
            "
            class="vendor-form-error-msg"
          >
            <span
              *ngIf="
                nonUsfVendorForm?.controls['code']?.errors['vendorIdExists'] &&
                !nonUsfVendorForm?.controls['code']?.errors['required']
              "
              data-cy="vendor-id-exists-label"
            >
              {{ 'i18n.vendors.vendorIdExists' | translate }}
            </span>
            <span
              *ngIf="
                !nonUsfVendorForm?.controls['code']?.errors['vendorIdExists'] &&
                nonUsfVendorForm?.controls['code']?.errors['required']
              "
              data-cy="vendor-request-credit-label"
            >
              {{ 'i18n.requestCredit.requiredField' | translate }}
            </span>
          </div>
        </ion-col>
      </ion-row>

      <!-- Vendor's Name -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-name" data-cy="vendor-name-label">
            {{ 'i18n.vendors.vendorName' | translate }}
            <span
              class="vendor-form-required-text"
              data-cy="vendor-form-required-text"
            >
              {{ 'i18n.common.requiredInputIndicator' | translate }}
            </span>
          </span>
          <ion-input
            aria-labelledby="vendor-name"
            id="vendor-name-input"
            data-cy="vendor-name-input"
            formControlName="name"
            maxlength="25"
            placeholder="{{ 'i18n.vendors.namePlaceholder' | translate }}"
            type="text"
            [class.input-error]="
              !nonUsfVendorForm?.controls['name']?.valid &&
              (nonUsfVendorForm?.controls['name']?.touched ||
                nonUsfVendorForm?.controls['name']?.dirty) &&
              nonUsfVendorForm.controls['name'].errors['required']
            "
            trimSpace
          >
          </ion-input>
          <div
            *ngIf="
              !nonUsfVendorForm?.controls['name']?.valid &&
              nonUsfVendorForm?.controls['name']?.dirty &&
              nonUsfVendorForm?.controls['name']?.errors['required']
            "
            class="vendor-form-error-msg"
          >
            <span data-cy="vendor-request-credit-required-text">
              {{ 'i18n.requestCredit.requiredField' | translate }}
            </span>
          </div>
        </ion-col>
      </ion-row>

      <!-- Terms -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-terms" data-cy="vendor-terms-label">{{
            'i18n.vendors.terms' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-terms"
            id="vendor-terms-input"
            data-cy="vendor-terms-input"
            formControlName="terms"
            maxlength="20"
            placeholder="{{ 'i18n.vendors.termsPlaceholder' | translate }}"
            type="text"
            trimSpace
          >
          </ion-input>
        </ion-col>
      </ion-row>

      <!-- Contact Name -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-contact" data-cy="vendor-contact-label">{{
            'i18n.vendors.contact' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-contact"
            id="vendor-contact-input"
            data-cy="vendor-contact-input"
            formControlName="contact"
            maxlength="20"
            placeholder="{{ 'i18n.vendors.contactPlaceholder' | translate }}"
            type="text"
            trimSpace
          >
          </ion-input>
        </ion-col>
      </ion-row>

      <!-- Address 1 -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-address" data-cy="vendor-address1-label">
            {{ 'i18n.vendors.address1' | translate }}
          </span>
          <ion-input
            aria-labelledby="vendor-address"
            id="vendor-address1-input"
            data-cy="vendor-address1-input"
            formControlName="address1"
            maxlength="25"
            placeholder="{{ 'i18n.vendors.addressPlaceholder' | translate }}"
            type="text"
            trimSpace
          >
          </ion-input>
        </ion-col>
      </ion-row>

      <!-- Address 2 -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-address-2" data-cy="vendor-address2-label">
            {{ 'i18n.vendors.address2' | translate }}
          </span>
          <ion-input
            aria-labelledby="vendor-address-2"
            id="vendor-address2-input"
            data-cy="vendor-address2-input"
            formControlName="address2"
            maxlength="25"
            placeholder="{{ 'i18n.vendors.optional' | translate }}"
            type="text"
            trimSpace
          >
          </ion-input>
        </ion-col>
      </ion-row>

      <!-- City -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-city" data-cy="vendor-city-label">{{
            'i18n.vendors.city' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-city"
            id="vendor-city-input"
            data-cy="vendor-city-input"
            formControlName="city"
            maxlength="20"
            placeholder="{{ 'i18n.vendors.cityPlaceholder' | translate }}"
            type="text"
            alphanumeric
            (paste)="pasteFired($event, 'city')"
            (specialRemovedEmitter)="setShowPasteMsg($event)"
            [class.input-error]="
              !nonUsfVendorForm?.controls['city']?.valid &&
              nonUsfVendorForm?.controls['city']?.dirty &&
              nonUsfVendorForm.controls['city'].errors &&
              platform.is('android')
            "
          >
          </ion-input>
          <div
            *ngIf="
              !nonUsfVendorForm?.controls['city']?.valid &&
              nonUsfVendorForm?.controls['city']?.dirty &&
              nonUsfVendorForm?.controls['city']?.errors &&
              platform.is('android')
            "
            class="vendor-form-error-msg"
          >
            <span data-cy="vendor-request-credit-required-text">
              {{ 'i18n.vendors.specialCharactersNotAllowed' | translate }}
            </span>
          </div>
          <div
            *ngIf="showPasteMsg && nonUsfVendorForm?.controls['city']?.valid"
            class="vendor-form-error-msg"
          >
            <span class="non-warning" data-cy="vendor-request-credit-label">
              {{ 'i18n.vendors.specialCharactersRemoved' | translate }}
            </span>
          </div>
        </ion-col>
      </ion-row>

      <!-- State -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span data-cy="vendor-state-label">
            {{ 'i18n.vendors.state' | translate }}
          </span>
          <ion-button fill="clear" (click)="openStateOptionsTouch()">
            <div class="add-non-usf-product-vendor">
              <span
                [ngClass]="selectedState === '' ? 'placeholder' : ''"
                data-cy="vendor-selected-state-text"
              >
                {{
                  (selectedState ? selectedState : 'i18n.vendors.selectState')
                    | translate
                }}
              </span>
              <ion-icon
                name="chevron-forward-outline"
                data-cy="select-state-chevron-icon"
              ></ion-icon>
            </div>
          </ion-button>
        </ion-col>
      </ion-row>

      <!-- Zip Code -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-zipcode" data-cy="vendor-zipcode-label">{{
            'i18n.vendors.zipcode' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-zipcode"
            id="vendor-zip-code-input"
            data-cy="vendor-zipcode-input"
            formControlName="zip"
            maxlength="10"
            placeholder="{{ 'i18n.vendors.zipCodePlaceholder' | translate }}"
            zipcode
            [class.input-error]="
              !nonUsfVendorForm?.controls['zip']?.valid &&
              nonUsfVendorForm?.controls['zip']?.dirty &&
              nonUsfVendorForm.controls['zip'].errors &&
              platform.is('android')
            "
          >
          </ion-input>
          <div
            *ngIf="
              !nonUsfVendorForm?.controls['zip']?.valid &&
              nonUsfVendorForm?.controls['zip']?.dirty &&
              nonUsfVendorForm?.controls['zip']?.errors &&
              platform.is('android')
            "
            class="vendor-form-error-msg"
          >
            <span data-cy="vendor-request-credit-required-text">
              {{ 'i18n.vendors.zipcodeValidation' | translate }}
            </span>
          </div>
        </ion-col>
      </ion-row>

      <!-- Phone, Ext -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-phone-number" data-cy="vendor-phone-label">{{
            'i18n.vendors.phone' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-phone-number"
            id="vendor-phone-input"
            data-cy="vendor-phone-input"
            formControlName="phone"
            maxlength="14"
            placeholder="{{ 'i18n.vendors.phonePlaceholder' | translate }}"
            type="tel"
            phone
          >
          </ion-input>
        </ion-col>
        <ion-col size="3" class="vendor-form-col">
          <span id="vendor-phone-extension" data-cy="vendor-phone-ext-label">{{
            'i18n.vendors.ext' | translate
          }}</span>
          <ion-input
            aria-labelledby="vendor-phone-extension"
            id="vendor-ext-input"
            data-cy="vendor-phone-ext-input"
            formControlName="ext"
            maxlength="4"
            placeholder="{{ 'i18n.vendors.extensionPlaceholder' | translate }}"
            type="tel"
            numeric-alt
            [maxLen]="4"
            (paste)="pasteFired($event, 'ext')"
          >
          </ion-input>
        </ion-col>
      </ion-row>

      <!-- Email-->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span id="vendor-email" data-cy="vendor-email-label">{{
            'i18n.vendors.email' | translate
          }}</span>
          <ion-textarea
            aria-labelledby="vendor-email"
            id="vendor-email-input"
            data-cy="vendor-email-input"
            formControlName="email"
            maxlength="80"
            rows="1"
            type="text"
            placeholder="{{ 'i18n.vendors.emailPlaceholder' | translate }}"
            trimSpace
          >
          </ion-textarea>
          <div
            *ngIf="
              !nonUsfVendorForm?.controls['email']?.valid &&
              nonUsfVendorForm?.controls['email']?.dirty
            "
            class="vendor-form-error-msg"
          >
            <span
              *ngIf="nonUsfVendorForm?.controls['email']?.errors"
              data-cy="vendor-invalid-email-label"
            >
              {{ 'i18n.vendors.emailValidation' | translate }}
            </span>
          </div>
        </ion-col>
      </ion-row>

      <!-- Comments -->
      <ion-row class="vendor-form-row">
        <ion-col class="vendor-form-col">
          <span data-cy="vendor-comments-label">
            {{ 'i18n.vendors.comments' | translate }}
          </span>
          <ion-textarea
            id="vendor-comment-input"
            class="vendor-input-large"
            data-cy="vendor-comments-input"
            formControlName="comment"
            maxlength="120"
            rows="1"
            type="text"
            placeholder="{{ 'i18n.vendors.commentPlaceholder' | translate }}"
            trimSpace
          ></ion-textarea>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    (platformService?.isTouchExperience$() | async) === false
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="cancel-modal-btn"
        data-cy="cancel-create-edit-non-usf-vendor-modal-button"
        [ngClass]="
          platformService.platformType !== platformService.platformEnum.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismissModal()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        id="save-modal-btn"
        data-cy="save-create-edit-non-usf-vendor-modal-button"
        type="submit"
        [ngClass]="
          platformService.platformType !== platformService.platformEnum.mobile
            ? nonUsfVendorForm?.invalid ||
              (!valuesDiffer && mode === ListActions.edit)
              ? 'usf-fill-disabled-dsktp-tblt-modal-button'
              : 'usf-fill-green-dsktp-tblt-modal-button'
            : nonUsfVendorForm?.invalid ||
              (!valuesDiffer && mode === ListActions.edit)
            ? 'usf-fill-disabled-mobile-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="onSaveExternalVendor()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
