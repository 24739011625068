import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ExternalVendorService } from '../../services/external-vendor.service';

@Component({
  selector: 'app-vendors-mobile-sort',
  templateUrl: './vendors-mobile-sort.component.html',
  styleUrls: ['./vendors-mobile-sort.component.scss'],
})
export class VendorsMobileSortComponent {
  @Input() selectedSort: string;

  constructor(
    private externalVendorsService: ExternalVendorService,
    private modalController: ModalController,
  ) {}

  async onSaveSort() {
    const splitSort = this.selectedSort.split('-');
    this.externalVendorsService.changeSortMobile(splitSort[0], splitSort[1]);
    await this.modalController.dismiss();
  }

  async dismiss() {
    await this.modalController.dismiss();
  }
}
