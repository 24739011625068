import { Component, Input, OnInit } from '@angular/core';
import { InventoryEllipsisModalModes } from '@inventory/constants/inventory-constants';
import {
  InventoryGroupViewModel,
  InventoryViewModel,
} from '@inventory/models/inventory-view.model';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { PlatformService } from '@panamax/app-state';
import { Observable, firstValueFrom } from 'rxjs';
import { State } from '@app/store';
import {
  INVENTORY_ACTIONS,
  InventoryTracingConstants,
  deleteInventoryTransformer,
} from '@usf/ngrx-inventory';
import { TranslateService } from '@ngx-translate/core';
import { CustomerStoreService } from '../../../../../ngrx-customer/services';
import { DownloadInventoryService } from '../../../../../shared/services/document/download-inventory/download-inventory.service';
import { Button } from '../../../../../shared/models/ellipsis-option.model';

@Component({
  selector: 'app-inventory-complete-delete-modal',
  templateUrl: './inventory-complete-delete-modal.component.html',
  styleUrls: ['./inventory-complete-delete-modal.component.scss'],
})
export class InventoryCompleteDeleteModalComponent
  implements ViewWillEnter, OnInit {
  @Input() completedInventory$: Observable<InventoryViewModel>;
  @Input() mode: InventoryEllipsisModalModes;

  InventoryEllipsisModalModes = InventoryEllipsisModalModes;
  date: Date;
  downloadOptionsButtons = [];
  isTouchDownloadOpen = false;

  constructor(
    private modalController: ModalController,
    readonly platformService: PlatformService,
    private store: Store<State>,
    private translateService: TranslateService,
    private customerStoreService: CustomerStoreService,
    private downloadInventoryService: DownloadInventoryService,
  ) {}

  ngOnInit(): void {
    const downloadPdfButton = this.getDownloadPDFButton();
    const downloadCsvButton = this.getDownloadCSVButton();
    this.downloadOptionsButtons = [downloadPdfButton, downloadCsvButton];
    if (
      this.platformService.platformType !==
      this.platformService.platformEnum.desktop
    ) {
      this.downloadOptionsButtons.push({
        text: this.translateService.instant('i18n.common.cancel'),
        role: 'cancel',
        cssClass: 'cancel-button',
      });
    }
  }

  getDownloadPDFButton() {
    return {
      text: this.translateService.instant(
        'i18n.inventory.summaryModal.downloadPDF',
      ),
      handler: async () => {
        const inventoryViewModel = await firstValueFrom(
          this.completedInventory$,
        );
        const customerDivisionDepartment = await firstValueFrom(
          this.customerStoreService.selectCustomerDivisionDepartment$(),
        );
        this.downloadInventoryService.generateAndSaveInventorySummaryPDF(
          inventoryViewModel,
          customerDivisionDepartment,
        );
      },
    } as Button;
  }

  getDownloadCSVButton() {
    return {
      text: this.translateService.instant(
        'i18n.inventory.summaryModal.downloadCSV',
      ),
      handler: async () => {
        const inventoryViewModel = await firstValueFrom(
          this.completedInventory$,
        );
        const customerDivisionDepartment = await firstValueFrom(
          this.customerStoreService.selectCustomerDivisionDepartment$(),
        );
        this.downloadInventoryService.generateAndSaveInventorySummaryCSV(
          inventoryViewModel,
          customerDivisionDepartment,
        );
      },
    } as Button;
  }

  ionViewWillEnter() {
    this.date = new Date();
  }

  async dismissModalAndNav(nav: boolean) {
    await this.modalController.dismiss({ navigate: nav });
  }

  async goToWorksheet(inventoryId: string) {
    await this.modalController.dismiss({ inventoryId });
  }

  asGroup(val): InventoryGroupViewModel {
    return val;
  }

  deleteInventory(
    inventoryId: string,
    inventoryName: string,
    inventoryDate: Date | string,
  ) {
    this.dismissModalAndNav(false);

    this.store.dispatch(
      INVENTORY_ACTIONS.delete({
        inventoryId,
        inventoryName,
        tracking: {
          tracing: {
            data: {
              traceContext: InventoryTracingConstants.inventory,
              isEndOfTrace: false,
              isStartOfTrace: true,
              attributes: {
                inventoryName: inventoryName + ' ' + inventoryDate,
                inventoryId: inventoryId,
                event: InventoryTracingConstants.deleteInventoryEvent,
                spanName: InventoryTracingConstants.deleteInventorySpan,
              },
            },
            transformFunc: deleteInventoryTransformer,
          },
        },
      }),
    );
  }

  openDownloadOptions() {
    this.isTouchDownloadOpen =
      this.platformService.platformType !==
      this.platformService.platformEnum.desktop;
  }
}
