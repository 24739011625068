import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';

export const PUNCHOUT_TRACING_ACTIONS = {
  transfer: createAction('[NGRXTrace - Order] Transfer'),
  transferClick: createAction(
    '[NGRXTrace - Order] Transfer Button Clicked',
    props<{
      tracking: Tracking;
    }>(),
  ),
};
