import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { HotKeys } from '../../constants/hot-key.enum';
import { PlatformService } from '@panamax/app-state';

@Injectable({
  providedIn: 'root',
})
export class ListHotkeyService {
  interval: any;
  private productCardIds: Set<string> = new Set();
  private itemHeights: number[] = [];
  private listId = '';

  constructor(private platformService: PlatformService) {}

  setItemHeights(itemHeights: number[]) {
    this.itemHeights = itemHeights;
  }

  getItemHeights() {
    return this.itemHeights;
  }

  setProductCardIds(productCardIds: Set<string>) {
    this.productCardIds = productCardIds;
  }

  getProductCardIds() {
    return this.productCardIds;
  }

  setListId(listId: string) {
    this.listId = listId;
  }

  onKeyEvent(event: KeyboardEvent, ionicElementCurrentlyFocused: HTMLElement) {
    if (
      ionicElementCurrentlyFocused &&
      (this.isEscapeKeyWithinSearchBar(event, ionicElementCurrentlyFocused) ||
        this.isPeriodWithinSearchBar(event, ionicElementCurrentlyFocused))
    ) {
      return;
    }

    if (ionicElementCurrentlyFocused && this.isKeyWeCareAbout(event.code)) {
      event.preventDefault();
      let idToFocus;
      if (
        this.platformService.platformType ===
        this.platformService.platformEnum.desktop
      ) {
        idToFocus = this.handleProductCardHotKeyDesktop(
          ionicElementCurrentlyFocused?.getAttribute('id'),
          event.code,
          event.shiftKey,
        );
      } else {
        idToFocus = this.handleProductCardHotKeyTouch(
          ionicElementCurrentlyFocused?.getAttribute('id'),
          event.code,
          event.shiftKey,
        );
      }
      this.focusOnId(idToFocus);
    }
  }

  isKeyWeCareAbout(code: string) {
    return (
      code === HotKeys.escape ||
      code === HotKeys.enter ||
      code === HotKeys.tab ||
      code === HotKeys.arrowDown ||
      code === HotKeys.arrowUp ||
      code === HotKeys.arrowLeft ||
      code === HotKeys.arrowRight ||
      code === HotKeys.period ||
      code === HotKeys.numPadEnter
    );
  }

  isEscapeKeyWithinSearchBar(
    event: KeyboardEvent,
    ionicElementCurrentlyFocused: HTMLElement,
  ): boolean {
    const id = ionicElementCurrentlyFocused.id;
    const searchBarId = this.createId(HotKeys.listSearchBarId, 0);
    return id === searchBarId && event.code === HotKeys.escape;
  }

  isPeriodWithinSearchBar(
    event: KeyboardEvent,
    ionicElementCurrentlyFocused: HTMLElement,
  ): boolean {
    const id = ionicElementCurrentlyFocused.id;
    const searchBarId = this.createId(HotKeys.listSearchBarId, 0);
    return id === searchBarId && event.code === HotKeys.period;
  }

  handleProductCardHotKeyDesktop(
    id: string,
    code: string,
    shiftKey: boolean,
  ): string {
    let idToFocus = '';
    if (code === HotKeys.escape) {
      idToFocus = this.createId(HotKeys.listSearchBarId, 0);
    } else if (
      (code === HotKeys.enter || code === HotKeys.numPadEnter) &&
      shiftKey
    ) {
      idToFocus = this.handleProductCardShiftEnter(id);
    } else if (code === HotKeys.tab && shiftKey) {
      idToFocus = this.handleProductCardShiftTabKey(id);
    } else if (code === HotKeys.enter || code === HotKeys.numPadEnter) {
      idToFocus = this.handleProductCardEnter(id);
    } else if (code === HotKeys.tab) {
      idToFocus = this.handleProductCardTabKey(id);
    } else if (code === HotKeys.arrowLeft) {
      idToFocus = this.handleProductCardLeftArrow(id);
    } else if (code === HotKeys.arrowRight) {
      idToFocus = this.handleProductCardRightArrow(id);
    } else if (code === HotKeys.arrowUp) {
      idToFocus = this.handleProductCardUpArrow(id);
    } else if (code === HotKeys.arrowDown) {
      idToFocus = this.handleProductCardDownArrow(id);
    } else if (code === HotKeys.period) {
      idToFocus = this.handleProductCardPeriodKey(id);
    }
    return idToFocus;
  }

  handleProductCardHotKeyTouch(
    id: string,
    code: string,
    shiftKey: boolean,
  ): string {
    let idToFocus = '';

    if (code === HotKeys.escape) {
      idToFocus = this.createId(HotKeys.listSearchBarId, 0);
    } else if (
      (code === HotKeys.enter || code === HotKeys.numPadEnter) &&
      shiftKey
    ) {
      idToFocus = this.handleProductCardShiftEnter(id);
    } else if (code === HotKeys.tab && shiftKey) {
      idToFocus = this.handleProductCardShiftTabKeyTouch(id);
    } else if (code === HotKeys.enter || code === HotKeys.numPadEnter) {
      idToFocus = this.handleProductCardEnter(id);
    } else if (code === HotKeys.tab) {
      idToFocus = this.handleProductCardTabKeyTouch(id);
    } else if (code === HotKeys.arrowLeft) {
      idToFocus = this.handleProductLeftArrowTouch(id);
    } else if (code === HotKeys.arrowRight) {
      idToFocus = this.handleProductCardRightArrowTouch(id);
    } else if (code === HotKeys.arrowUp) {
      idToFocus = this.handleProductCardUpArrowTouch(id);
    } else if (code === HotKeys.arrowDown) {
      idToFocus = this.handleProductCardDownArrowTouch(id);
    }

    return idToFocus;
  }

  focusOnId(id: string) {
    const element = document.getElementById(id) as HTMLIonInputElement;
    if (element) {
      this.setFocusAndScroll(id, element);
    } else if (!element && id) {
      this.scrollAndSetFocus(id);
    }
  }

  setFocusAndScroll(id: string, element: HTMLIonInputElement) {
    element.setFocus();
    const scroll = document.getElementById(
      HotKeys.listScrollId + this.listId,
    ) as HTMLElement;
    const searchBarId = this.createId(HotKeys.listSearchBarId, 0);
    if (id === searchBarId && scroll.scrollTop > 1000) {
      let i = 0;
      this.interval = setInterval(() => {
        scroll.scrollTop = 0;
        i++;
        if (i === 10) {
          clearInterval(this.interval);
        }
      }, 10);
    } else {
      scroll.scrollTop = this.findTargetScrollHeight(id);
    }
  }

  scrollAndSetFocus(id: string) {
    const scroll = document.getElementById(
      HotKeys.listScrollId + this.listId,
    ) as HTMLElement;
    scroll.scrollTop = this.findTargetScrollHeight(id);

    setTimeout(() => {
      const newElement = document.getElementById(id) as HTMLIonInputElement;
      if (newElement) {
        newElement.setFocus();
      }
    }, 500);
  }

  findTargetScrollHeight(id: string) {
    const separatedId = id.split('-');
    let targetIndex = Number(separatedId[separatedId.length - 1]);
    if (
      this.platformService.platformType !==
      this.platformService.platformEnum.desktop
    ) {
      // Need to set target index to 1 higher so that product cards are better centered on devices
      targetIndex++;
    }

    let targetScrollHeight = 0;
    for (let i = 0; i < targetIndex; i++) {
      targetScrollHeight += this.itemHeights[i];
    }

    return targetScrollHeight;
  }

  createId(column: string, index: number) {
    return column + '-' + this.listId + '-' + index;
  }

  private handleProductCardEnter(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    while (!idToFocus) {
      currentIndex += 1;
      idToFocus = this.findTopRight(currentIndex);
      if (currentIndex > this.itemHeights?.length) {
        return idToFocus;
      }
    }
    return idToFocus;
  }

  private handleProductCardShiftEnter(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    while (!idToFocus) {
      currentIndex -= 1;
      idToFocus = this.findTopRight(currentIndex);
      if (currentIndex <= 0) {
        idToFocus = this.createId(HotKeys.listSearchBarId, 0);
      }
    }
    return idToFocus;
  }

  private handleProductCardLeftArrow(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    if (this.isTopRight(id)) {
      idToFocus = this.findTopLeft(currentIndex);
    } else if (this.isBottomRight(id)) {
      idToFocus = this.findBottomLeft(currentIndex);
    }
    return idToFocus;
  }

  private handleProductLeftArrowTouch(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    if (this.isTopRightTouch(id)) {
      idToFocus = this.findTopLeft(currentIndex);
    } else if (this.isBottomRight(id)) {
      idToFocus = this.findBottomLeftTouch(currentIndex);
    }
    return idToFocus;
  }

  private handleProductCardRightArrow(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    if (this.isTopLeft(id)) {
      idToFocus = this.findTopRight(currentIndex);
    } else if (this.isBottomLeft(id)) {
      idToFocus = this.findBottomRight(currentIndex);
    }
    return idToFocus;
  }

  private handleProductCardRightArrowTouch(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    if (this.isTopLeft(id)) {
      idToFocus = this.findTopRightTouch(currentIndex);
    } else if (this.isBottomLeftTouch(id)) {
      idToFocus = this.findBottomRight(currentIndex);
    }
    return idToFocus;
  }

  private handleProductCardDownArrow(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    if (this.isTopLeft(id)) {
      idToFocus = this.findBottomLeft(currentIndex);
      if (!idToFocus) {
        currentIndex += 1;
        idToFocus = this.downArrowLeftSideHelper(currentIndex);
      }
    } else if (this.isBottomLeft(id)) {
      currentIndex += 1;
      idToFocus = this.downArrowLeftSideHelper(currentIndex);
    } else if (this.isTopRight(id)) {
      idToFocus = this.findBottomRight(currentIndex);
      if (!idToFocus) {
        currentIndex += 1;
        idToFocus = this.downArrowRightSideHelper(currentIndex);
      }
    } else if (this.isBottomRight(id)) {
      currentIndex += 1;
      idToFocus = this.downArrowRightSideHelper(currentIndex);
    }

    return idToFocus;
  }

  private handleProductCardDownArrowTouch(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    if (this.isTopLeft(id)) {
      idToFocus = this.findBottomLeftTouch(currentIndex);
    } else if (this.isTopRightTouch(id)) {
      idToFocus = this.findBottomRight(currentIndex);
      if (!idToFocus) {
        idToFocus = this.findBottomLeftTouch(currentIndex);
      }
    } else if (this.isBottomLeftTouch(id)) {
      currentIndex += 1;
      idToFocus = this.downArrowLeftSideHelperTouch(currentIndex);
    } else if (this.isBottomRight(id)) {
      currentIndex += 1;
      idToFocus = this.downArrowRightSideHelperTouch(currentIndex);
    }

    return idToFocus;
  }

  private handleProductCardUpArrow(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    if (this.isTopLeft(id)) {
      currentIndex -= 1;
      while (!idToFocus) {
        idToFocus = this.findBottomLeft(currentIndex);
        if (!idToFocus) {
          idToFocus = this.findTopLeft(currentIndex);
        }
        if (!idToFocus) {
          idToFocus = this.findBottomRight(currentIndex);
        }
        if (!idToFocus) {
          idToFocus = this.findTopRight(currentIndex);
        }
        if (currentIndex === 0) {
          idToFocus = this.createId(HotKeys.listSearchBarId, 0);
        }
        currentIndex -= 1;
      }
    } else if (this.isBottomLeft(id)) {
      idToFocus = this.findTopLeft(currentIndex);
    } else if (this.isTopRight(id)) {
      currentIndex -= 1;
      while (!idToFocus) {
        idToFocus = this.findBottomRight(currentIndex);
        if (!idToFocus) {
          idToFocus = this.findTopRight(currentIndex);
        }
        if (currentIndex === 0) {
          idToFocus = this.createId(HotKeys.listSearchBarId, 0);
        }
        currentIndex -= 1;
      }
    } else if (this.isBottomRight(id)) {
      idToFocus = this.findTopRight(currentIndex);
    }
    return idToFocus;
  }

  private handleProductCardUpArrowTouch(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    if (this.isTopLeft(id)) {
      currentIndex -= 1;
      while (!idToFocus) {
        idToFocus = this.findBottomLeftTouch(currentIndex);
        if (!idToFocus) {
          idToFocus = this.findTopLeft(currentIndex);
        }
        if (!idToFocus) {
          idToFocus = this.findBottomRight(currentIndex);
        }
        if (!idToFocus) {
          idToFocus = this.findTopRightTouch(currentIndex);
        }
        if (currentIndex === 0) {
          idToFocus = this.createId(HotKeys.listSearchBarId, 0);
        }
        currentIndex -= 1;
      }
    } else if (this.isTopRightTouch(id)) {
      currentIndex -= 1;

      while (!idToFocus) {
        idToFocus = this.findBottomRight(currentIndex);
        if (!idToFocus) {
          idToFocus = this.findTopRightTouch(currentIndex);
        }
        if (!idToFocus) {
          idToFocus = this.findBottomLeftTouch(currentIndex);
        }
        if (!idToFocus) {
          idToFocus = this.findTopLeft(currentIndex);
        }
        if (currentIndex === 0) {
          idToFocus = this.createId(HotKeys.listSearchBarId, 0);
        }
        currentIndex -= 1;
      }
    } else if (this.isBottomLeftTouch(id)) {
      idToFocus = this.findTopLeft(currentIndex);
      if (!idToFocus) {
        currentIndex -= 1;
        while (!idToFocus) {
          idToFocus = this.findBottomLeftTouch(currentIndex);
          if (currentIndex === 0) {
            idToFocus = this.createId(HotKeys.listSearchBarId, 0);
          }
          currentIndex -= 1;
        }
      }
    } else if (this.isBottomRight(id)) {
      idToFocus = this.findTopRightTouch(currentIndex);
      if (!idToFocus) {
        currentIndex -= 1;
        while (!idToFocus) {
          idToFocus = this.findBottomRight(currentIndex);
          if (!idToFocus) {
            idToFocus = this.findBottomLeftTouch(currentIndex);
          }
          if (currentIndex === 0) {
            idToFocus = this.createId(HotKeys.listSearchBarId, 0);
          }
          currentIndex -= 1;
        }
      }
    }
    return idToFocus;
  }

  private handleProductCardPeriodKey(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    if (this.isTopLeft(id)) {
      idToFocus = this.findBottomLeft(currentIndex);
    } else if (this.isTopRight(id)) {
      idToFocus = this.findBottomRight(currentIndex);
    }
    return idToFocus;
  }

  private handleProductCardTabKey(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    if (currentIndex === 0) {
      currentIndex += 1;
      idToFocus = this.tabKeyHelper(currentIndex);
    } else if (this.isTopLeft(id)) {
      idToFocus = this.findBottomLeft(currentIndex);
      if (!idToFocus) {
        idToFocus = this.findTopRight(currentIndex);
      }
    } else if (this.isBottomLeft(id)) {
      idToFocus = this.findTopRight(currentIndex);
    } else if (this.isTopRight(id)) {
      idToFocus = this.findBottomRight(currentIndex);
      if (!idToFocus) {
        currentIndex += 1;
        idToFocus = this.tabKeyHelper(currentIndex);
      }
    } else if (this.isBottomRight(id)) {
      currentIndex += 1;
      idToFocus = this.tabKeyHelper(currentIndex);
    }
    return idToFocus;
  }

  private handleProductCardTabKeyTouch(id: string): string {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    if (currentIndex === 0) {
      currentIndex += 1;
      idToFocus = this.tabKeyHelperTouch(currentIndex);
    } else if (this.isTopLeft(id)) {
      idToFocus = this.findTopRightTouch(currentIndex);
      if (!idToFocus) {
        idToFocus = this.findBottomLeftTouch(currentIndex);
      }
    } else if (this.isBottomLeftTouch(id)) {
      idToFocus = this.findBottomRight(currentIndex);
      if (!idToFocus) {
        currentIndex += 1;
        idToFocus = this.tabKeyHelperTouch(currentIndex);
      }
    } else if (this.isTopRightTouch(id)) {
      idToFocus = this.findBottomLeftTouch(currentIndex);
    } else if (this.isBottomRight(id)) {
      currentIndex += 1;
      idToFocus = this.tabKeyHelperTouch(currentIndex);
    }
    return idToFocus;
  }

  private handleProductCardShiftTabKey(id: string) {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    if (currentIndex === 0) {
      return '';
    } else if (this.isTopLeft(id)) {
      currentIndex -= 1;
      idToFocus = this.shiftTabKeyHelper(currentIndex);
    } else if (this.isBottomLeft(id)) {
      idToFocus = this.findTopLeft(currentIndex);
    } else if (this.isTopRight(id)) {
      idToFocus = this.findBottomLeft(currentIndex);
      if (!idToFocus) {
        idToFocus = this.findTopLeft(currentIndex);
      }
      currentIndex -= 1;
      if (!idToFocus) {
        idToFocus = this.shiftTabKeyHelper(currentIndex);
      }
    } else if (this.isBottomRight(id)) {
      idToFocus = this.findTopRight(currentIndex);
    }
    return idToFocus;
  }

  private handleProductCardShiftTabKeyTouch(id: string) {
    let idToFocus = '';
    let separatedId = id.split('-');
    let currentIndex = Number(separatedId[separatedId.length - 1]);
    if (currentIndex === 0) {
      return '';
    } else if (this.isTopLeft(id)) {
      currentIndex -= 1;
      idToFocus = this.shiftTabKeyHelperTouch(currentIndex);
    } else if (this.isBottomLeftTouch(id)) {
      idToFocus = this.findTopRightTouch(currentIndex);
      if (!idToFocus) {
        idToFocus = this.findTopLeft(currentIndex);
      }
      if (!idToFocus) {
        currentIndex -= 1;
        idToFocus = this.shiftTabKeyHelperTouch(currentIndex);
      }
    } else if (this.isTopRightTouch(id)) {
      idToFocus = this.findTopLeft(currentIndex);
    } else if (this.isBottomRight(id)) {
      idToFocus = this.findBottomLeftTouch(currentIndex);
    }
    return idToFocus;
  }

  private isTopLeft(id: string) {
    let separatedId = id.split('-');
    return (
      separatedId[0] === HotKeys.outOfStock && separatedId[1] === HotKeys.case
    );
  }

  private isTopRight(id: string) {
    let separatedId = id.split('-');

    return (
      separatedId[0] === HotKeys.case ||
      (separatedId[0] === HotKeys.substitute && separatedId[1] === HotKeys.case)
    );
  }

  private isTopRightTouch(id: string) {
    let separatedId = id.split('-');
    return (
      separatedId[0] === HotKeys.outOfStock && separatedId[1] === HotKeys.each
    );
  }

  private isBottomLeft(id: string) {
    let separatedId = id.split('-');

    return (
      separatedId[0] === HotKeys.outOfStock && separatedId[1] === HotKeys.each
    );
  }

  private isBottomLeftTouch(id: string) {
    let separatedId = id.split('-');
    return (
      separatedId[0] === HotKeys.case ||
      (separatedId[0] === HotKeys.substitute && separatedId[1] === HotKeys.case)
    );
  }

  private isBottomRight(id: string) {
    let separatedId = id.split('-');
    return (
      separatedId[0] === HotKeys.each ||
      (separatedId[0] === HotKeys.substitute && separatedId[1] === HotKeys.each)
    );
  }

  private findTopLeft(currentIndex: number) {
    const targetId = this.getOOSCaseId(currentIndex);
    if (this.productCardIds.has(targetId)) {
      return targetId;
    }
    return '';
  }

  private findTopRight(currentIndex: number) {
    const targetCaseId = this.getCaseId(currentIndex);
    const targetSubCaseId = this.getSubCaseId(currentIndex);
    if (this.productCardIds.has(targetCaseId)) {
      return targetCaseId;
    } else if (this.productCardIds.has(targetSubCaseId)) {
      return targetSubCaseId;
    }
    return '';
  }

  private findTopRightTouch(currentIndex: number) {
    const targetId = this.getOOSEachId(currentIndex);
    if (this.productCardIds.has(targetId)) {
      return targetId;
    }
    return '';
  }

  private findBottomLeft(currentIndex: number) {
    const targetId = this.getOOSEachId(currentIndex);
    if (this.productCardIds.has(targetId)) {
      return targetId;
    }
    return '';
  }

  private findBottomLeftTouch(currentIndex: number) {
    const targetCaseId = this.getCaseId(currentIndex);
    const targetSubCaseId = this.getSubCaseId(currentIndex);
    if (this.productCardIds.has(targetCaseId)) {
      return targetCaseId;
    } else if (this.productCardIds.has(targetSubCaseId)) {
      return targetSubCaseId;
    }
    return '';
  }

  private findBottomRight(currentIndex: number) {
    const targetEachId = this.getEachId(currentIndex);
    const targetSubEachId = this.getSubEachId(currentIndex);

    if (this.productCardIds.has(targetEachId)) {
      return targetEachId;
    } else if (this.productCardIds.has(targetSubEachId)) {
      return targetSubEachId;
    }
    return '';
  }

  private tabKeyHelper(currentIndex: number) {
    let idToFocus = '';
    while (!idToFocus) {
      idToFocus = this.findTopLeft(currentIndex);
      if (!idToFocus) {
        idToFocus = this.findTopRight(currentIndex);
      }
      if (currentIndex > this.itemHeights?.length) {
        return idToFocus;
      }
      currentIndex += 1;
    }
    return idToFocus;
  }

  private tabKeyHelperTouch(currentIndex: number) {
    let idToFocus = '';
    while (!idToFocus) {
      idToFocus = this.findTopLeft(currentIndex);
      if (!idToFocus) {
        idToFocus = this.findBottomLeftTouch(currentIndex);
      }
      if (currentIndex > this.itemHeights?.length) {
        return idToFocus;
      }
      currentIndex += 1;
    }
    return idToFocus;
  }

  private shiftTabKeyHelper(currentIndex: number) {
    let idToFocus = '';
    while (!idToFocus) {
      idToFocus = this.findBottomRight(currentIndex);
      if (!idToFocus) {
        idToFocus = this.findTopRight(currentIndex);
      }
      if (currentIndex === 0) {
        idToFocus = this.createId(HotKeys.listSearchBarId, 0);
      }
      currentIndex -= 1;
    }
    return idToFocus;
  }

  private shiftTabKeyHelperTouch(currentIndex: number) {
    let idToFocus = '';
    while (!idToFocus) {
      idToFocus = this.findBottomRight(currentIndex);
      if (!idToFocus) {
        idToFocus = this.findBottomLeftTouch(currentIndex);
      }
      if (currentIndex === 0) {
        idToFocus = this.createId(HotKeys.listSearchBarId, 0);
      }
      currentIndex -= 1;
    }
    return idToFocus;
  }

  private downArrowLeftSideHelper(currentIndex: number) {
    let idToFocus = '';
    while (!idToFocus) {
      idToFocus = this.findTopLeft(currentIndex);
      if (!idToFocus) {
        idToFocus = this.findTopRight(currentIndex);
      }
      if (currentIndex > this.itemHeights?.length) {
        return idToFocus;
      }
      currentIndex += 1;
    }
    return idToFocus;
  }

  private downArrowLeftSideHelperTouch(currentIndex: number) {
    let idToFocus = '';
    while (!idToFocus) {
      idToFocus = this.findTopLeft(currentIndex);
      if (!idToFocus) {
        idToFocus = this.findBottomLeftTouch(currentIndex);
      }
      if (currentIndex > this.itemHeights?.length) {
        return idToFocus;
      }
      currentIndex += 1;
    }
    return idToFocus;
  }

  private downArrowRightSideHelper(currentIndex: number) {
    let idToFocus = '';
    while (!idToFocus) {
      idToFocus = this.findTopRight(currentIndex);
      if (!idToFocus) {
        idToFocus = this.findTopLeft(currentIndex);
      }
      if (!idToFocus) {
        idToFocus = this.findBottomRight(currentIndex);
      }
      if (!idToFocus) {
        idToFocus = this.findBottomLeftTouch(currentIndex);
      }
      if (currentIndex > this.itemHeights?.length) {
        return idToFocus;
      }
      currentIndex += 1;
    }
    return idToFocus;
  }

  private downArrowRightSideHelperTouch(currentIndex: number) {
    let idToFocus = '';
    while (!idToFocus) {
      idToFocus = this.findTopRightTouch(currentIndex);
      if (!idToFocus) {
        idToFocus = this.findTopLeft(currentIndex);
      }
      if (!idToFocus) {
        idToFocus = this.findBottomRight(currentIndex);
      }
      if (!idToFocus) {
        idToFocus = this.findBottomLeftTouch(currentIndex);
      }
      if (currentIndex > this.itemHeights?.length) {
        return idToFocus;
      }
      currentIndex += 1;
    }
    return idToFocus;
  }

  private getCaseId(index: number) {
    return HotKeys.case + '-' + this.listId + '-' + index;
  }
  private getEachId(index: number) {
    return HotKeys.each + '-' + this.listId + '-' + index;
  }
  private getOOSCaseId(index: number) {
    return (
      HotKeys.outOfStock + '-' + HotKeys.case + '-' + this.listId + '-' + index
    );
  }
  private getOOSEachId(index: number) {
    return (
      HotKeys.outOfStock + '-' + HotKeys.each + '-' + this.listId + '-' + index
    );
  }
  private getSubCaseId(index: number) {
    return (
      HotKeys.substitute + '-' + HotKeys.case + '-' + this.listId + '-' + index
    );
  }
  private getSubEachId(index: number) {
    return (
      HotKeys.substitute + '-' + HotKeys.each + '-' + this.listId + '-' + index
    );
  }
}
