import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IonNav,
  ModalController,
  PopoverController,
  ViewWillEnter,
} from '@ionic/angular';
import { PlatformEnum } from '@panamax/app-state';
import { CopyListModalOutput, ListState } from '@usf/ngrx-list';
import { ListTypes, ListCopyOptions } from '@shared/constants/lists-constants';
import { ListsPageData } from '@app/lists/model/lists-page.model';
import { ListsViewModel } from '@app/lists/model/lists-view.model';
import { ListsService } from '../../services/lists.service';
import { Observable } from 'rxjs';
import {
  DivisionSelection,
  CustomerSelection,
} from '@app/lists/model/copy-list-options.model';
import { CustDivDeptInfo } from '@usf/list-types';

@Component({
  template: '',
})
export class CopyListModalBaseComponent implements ViewWillEnter {
  @Input() platformType: PlatformEnum;
  @Input() listState?: any;
  @Input() listData?: ListsPageData;
  @Input() divisions?: DivisionSelection[];
  @Input() customers?: CustomerSelection[];
  @Input() showMassCopy: boolean;

  readonly platformEnum = PlatformEnum;
  readonly listCopyOptions = ListCopyOptions;
  readonly ListTypes = ListTypes;

  selectedList: ListsViewModel = undefined;
  sortedListView: ListsViewModel[] = [];
  filteredListView: ListsViewModel[] = [];
  customersView: CustomerSelection[] = [];
  filteredCustomersView: CustomerSelection[] = [];
  selectedCopyListOption = ListCopyOptions.selectList;
  searchKey = '';
  newListName: string;
  listNameExists = false;
  listType = ListTypes.public;
  marketSelected = false;
  marketSelectedCount = 0;
  customerSelected = false;
  customerSelectedCount = 0;
  maximumSelectableCustomerCount = 0;

  @Output() dismissEvent: EventEmitter<void> = new EventEmitter();
  listsPageData$: Observable<ListsPageData>;
  constructor(
    protected nav: IonNav,
    private popoverController: PopoverController,
    readonly modalController: ModalController,
    private listsService: ListsService,
  ) {}

  ionViewWillEnter(): void {
    this.listsPageData$ = this.listsService.listsView$(this.platformType);

    this.sortedListView = this.localSortListVMArrays(
      this.combineListVMArrays(
        this.listData.lists.publicLists,
        this.listData.lists.privateLists,
        this.listData.lists.internalLists,
        this.listData.managedByUsfLists,
      ),
    );
    this.resetSearch();
    if (!this.selectedList) {
      const listName =
        this.listState?.listKey?.listTypeId === ListTypes.og
          ? `Order Guide #${this.listState?.listKey.listId}`
          : this.listState?.listName
          ? this.listState?.listName
          : '';
      this.selectedList = listName
        ? this.filteredListView.find(list => list.listName === listName)
        : undefined;
    }
    this.filteredListView = [...this.sortedListView];

    this.newListName = this.newListName
      ? this.newListName
      : this.selectedList?.listName;

    this.divisions = this.divisions.sort((d1, d2) => {
      if (d1.divisionName.toLowerCase() > d2.divisionName.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    });
    this.getListNameExists();
  }

  dismiss() {
    this.modalController.dismiss();
  }
  onSelectCopyListOption(listOption: ListCopyOptions) {
    this.selectedCopyListOption = listOption;
    this.resetSearch();
  }

  copy() {
    let custDivDeptInfo = [];
    if (this.customerSelected && this.marketSelected) {
      custDivDeptInfo = this.getSelectedCustomers();
    }
    this.modalController.dismiss({
      listName: this.newListName,
      listType: this.listType.toUpperCase(),
      selectedList: this.selectedList,
      custDivDeptInfo,
      customerCount: this.customerSelectedCount,
      marketCount: this.marketSelectedCount,
    } as CopyListModalOutput);
  }

  search() {
    if (this.selectedCopyListOption === ListCopyOptions.selectList)
      this.filteredListView = this.sortedListView.filter(listItem =>
        listItem?.listName
          .toLowerCase()
          .includes(this.searchKey?.toLowerCase()),
      );
    if (this.selectedCopyListOption === ListCopyOptions.customers)
      this.filteredCustomersView = this.customersView.filter(
        customer =>
          customer?.customerName
            .toLowerCase()
            .includes(this.searchKey?.toLowerCase()) ||
          customer?.customerNumber.toString().includes(this.searchKey),
      );
  }

  resetSearch() {
    this.searchKey = '';
    this.filteredListView = [...this.sortedListView];
    this.filteredCustomersView = [...this.customersView];
  }

  combineListVMArrays(
    publicLists: ListsViewModel[],
    internalLists: ListsViewModel[],
    privateLists: ListsViewModel[],
    usfLists: ListsViewModel[],
  ): ListsViewModel[] {
    return [...publicLists, ...internalLists, ...privateLists, ...usfLists];
  }

  localSortListVMArrays(listVMs: ListsViewModel[]) {
    return listVMs.sort((a, b) => {
      return a.listName.localeCompare(b.listName, 'en', {
        sensitivity: 'base',
      });
    });
  }

  getListNameExists() {
    if (
      this.newListName?.trim() !== this.selectedList?.listName.trim() ||
      this.selectedList?.listKey?.listTypeId === undefined ||
      this.selectedList?.listKey?.listTypeId === ListTypes.recentlyPurchased
    ) {
      this.listNameExists = this.listsService.listNameExists(
        this.listData,
        this.newListName ?? '',
      );
    }
  }

  handleListSelect(event) {
    const listName = event.detail.value;
    this.selectedList = this.filteredListView.find(
      list => list.listName === listName,
    );
    this.newListName = this.selectedList?.listName;
    this.listNameExists = false;
    this.getListNameExists();
  }

  handleSelectAllChange(event) {
    if (this.selectedCopyListOption === ListCopyOptions.markets) {
      this.divisions = this.divisions.map(division => ({
        ...division,
        isSelected: event?.detail?.checked,
      }));
      this.marketSelected = event?.detail?.checked;
      this.marketSelectedCount = event?.detail?.checked
        ? this.divisions?.length
        : 0;
      this.resetCustomerSelections();
      this.customersView = this.getSelectedCustByMarket();
      this.maximumSelectableCustomerCount = this.countCustomersAndDivisions(
        this.customersView,
      );
    }

    if (this.selectedCopyListOption === ListCopyOptions.customers) {
      this.customerSelectedCount = event?.detail?.checked
        ? 0
        : this.customerSelectedCount;
      this.customersView = this.customersView.map(customer => {
        if (customer.departments.length > 0) {
          const depts = customer.departments.map(department => {
            this.calculateCustomerCounts(event.detail.checked);
            return {
              ...department,
              isSelected: event?.detail?.checked,
            };
          });
          return {
            ...customer,
            isSelected: event.detail.checked,
            departments: depts,
          };
        } else {
          this.calculateCustomerCounts(event.detail.checked);
          return { ...customer, isSelected: event?.detail?.checked };
        }
      });
      this.resetSearch();
      this.filteredCustomersView = [...this.customersView];
      this.customerSelected = event?.detail?.checked;
    }
  }

  handleMarketSelection(event) {
    this.divisions = this.toggleGenericSelection(
      event.detail.value,
      'divisionNumber',
      this.divisions,
    );
    this.calculateSelectedMarketCounts(event.detail.checked);
    this.resetCustomerSelections();
    this.customersView = this.getSelectedCustByMarket();
    this.maximumSelectableCustomerCount = this.countCustomersAndDivisions(
      this.customersView,
    );
  }

  handleCustomerSelection(event) {
    this.toggleGenericSelection(
      event.detail.value,
      'customerNumber',
      this.customersView,
    );
    this.calculateCustomerCounts(event.detail.checked);
  }

  handleDepartmentSelection(event) {
    this.customersView = this.toggleDepartmentSelection(event.detail.value);
    this.calculateCustomerCounts(event.detail.checked);
  }

  calculateSelectedMarketCounts(checked: boolean) {
    this.marketSelectedCount = checked
      ? (this.marketSelectedCount += 1)
      : (this.marketSelectedCount -= 1);
    this.marketSelected = this.marketSelectedCount > 0;
  }

  calculateCustomerCounts(checked: boolean) {
    this.customerSelectedCount = checked
      ? (this.customerSelectedCount += 1)
      : (this.customerSelectedCount -= 1);
    this.customerSelected = this.customerSelectedCount > 0;
  }

  toggleDepartmentSelection(departmentId: string) {
    const identifiers = departmentId.split('-');
    let customerNum = Number(identifiers[0]);
    let departmentNum = identifiers[1];

    return this.customersView.map(customer => {
      if (customer.customerNumber === customerNum) {
        const depts = customer.departments.map(department => {
          if (department.departmentNumber === departmentNum) {
            return {
              ...department,
              isSelected: !department.isSelected,
            };
          }
          return { ...department };
        });
        return {
          ...customer,
          isSelected: depts.some(dept => dept.isSelected),
          departments: depts,
        };
      }
      return { ...customer };
    });
  }

  toggleGenericSelection(id: number, property: string, array: any[]) {
    let selectedItem = array?.findIndex(
      item => Number(item[property]) === Number(id),
    );
    if (selectedItem >= 0) {
      let temp = array;
      temp[selectedItem] = {
        ...temp[selectedItem],
        isSelected: !temp[selectedItem].isSelected,
      };
      array = [...temp];
    }
    return array;
  }

  findFirstLetter(customerName: String) {
    const regex = /([a-zA-Z]{1})/;
    return customerName[customerName.search(regex)];
  }

  countCustomersAndDivisions(customers: CustomerSelection[]): number {
    let departmentCount = 0;
    this.customersView.forEach(customer => {
      if (customer.departments.length > 0) {
        // One is subtracted to account for the fact that the customer cannot select a customer if it has departments.
        departmentCount += customer.departments.length - 1;
      }
    });
    return departmentCount + customers.length;
  }

  getSelectedCustomers(): CustDivDeptInfo[] {
    const cddi = [];
    this.customersView
      .filter(customer => customer.isSelected)
      .forEach(customer => {
        if (customer.departments.length > 0) {
          customer.departments.forEach(dept => {
            if (dept.isSelected) {
              cddi.push({
                customerNumber: customer.customerNumber,
                divisionNumber: customer.divisionNumber,
                departmentNumber: Number(dept.departmentNumber),
              });
            }
          });
        } else {
          cddi.push({
            customerNumber: customer.customerNumber,
            divisionNumber: customer.divisionNumber,
            departmentNumber: 0,
          });
        }
      });
    return cddi;
  }

  resetCustomerSelections() {
    this.customersView = this.customersView.map(customer => {
      if (customer.departments.length > 0) {
        const depts = customer.departments.map(dept => {
          return { ...dept, isSelected: false };
        });
        return { ...customer, isSelected: false, departments: depts };
      }
      return { ...customer, isSelected: false };
    });
    this.customerSelected = false;
    this.customerSelectedCount = 0;
    this.filteredCustomersView = [...this.customersView];
  }

  getSelectedCustByMarket(): CustomerSelection[] {
    let customers = [];
    const selectedMarkets = this.divisions
      .filter(division => division.isSelected)
      .map(division => division.divisionNumber);
    selectedMarkets.forEach(
      marketNumber =>
        (customers = [
          ...customers,
          ...this.customers.filter(
            customer => customer.divisionNumber === marketNumber,
          ),
        ]),
    );
    return customers;
  }
}
