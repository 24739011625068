import { Injectable } from '@angular/core';
import { PanAppState, SelectedCustomerState } from '@panamax/app-state';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoginServiceHandlerService } from './login-service-handler.service';

@Injectable({
  providedIn: 'root',
})
export class LoginDataService {
  private loginUrl: string;

  constructor(
    private serviceHandler: LoginServiceHandlerService,
    private panAppState: PanAppState,
  ) {}

  refreshAuthToken(): Observable<any> {
    let refreshToken: string;
    let platform: string;
    let selectedCustomer: SelectedCustomerState;
    combineLatest([
      this.panAppState.customer$,
      this.panAppState.token$,
      this.panAppState.platform$,
    ])
      .pipe(
        map(([customer, token, plat]) => {
          if (token && plat && customer) {
            refreshToken = token.refreshToken;
            platform = plat.platform;
            selectedCustomer = customer;
          }
        }, take(1)),
      )
      .subscribe();
    if (selectedCustomer && refreshToken && platform) {
      return this.serviceHandler.refreshAuthToken(
        selectedCustomer,
        refreshToken,
        platform,
        selectedCustomer?.departmentNumber?.toString(),
      );
    } else {
      return of(null);
    }
  }
}
