import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  SearchActions,
  TRACE_CONTEXT,
  whileSuppliesLastTransformer,
} from '@usf/ngrx-product';

@Injectable({
  providedIn: 'root',
})
export class SearchTracingService {
  private searchResultsRenderedSubject = new Subject<number>();
  private searchResultsLoadedSubject = new Subject<number>();

  private searchResultsLoaded$: Subscription;

  constructor(protected store: Store) {}

  resetSearchPageTracing() {
    this.searchResultsLoaded$?.unsubscribe();

    this.registerSearchResultsLoadedEvents();
  }

  emitSearchResultsRendered(offset: number) {
    this.searchResultsRenderedSubject.next(offset ?? 0);
  }

  emitSearchResultsLoaded(offset: number) {
    this.searchResultsLoadedSubject.next(offset ?? 0);
  }

  private registerSearchResultsLoadedEvents() {
    this.searchResultsLoaded$ = this.searchResultsLoadedSubject
      .pipe(
        distinctUntilChanged((prev, curr) => {
          // If the current offset is less than
          // or the same as the previous offset,
          // the offset hasn't changed.
          return curr <= prev;
        }),
        mergeMap(resultOffset =>
          this.searchResultsRenderedSubject.pipe(
            filter(renderOffset => renderOffset === resultOffset),
            distinctUntilChanged((prev, curr) => {
              // If the current offset is less than
              // or the same as the previous offset,
              // the offset hasn't changed.
              return curr <= prev;
            }),
            map(renderOffset => resultOffset),
          ),
        ),
      )
      .subscribe(() => {
        this.traceSearchResultsPageLoaded();
      });
  }

  private traceSearchResultsPageLoaded() {
    this.store.dispatch(
      SearchActions.SearchResultsPageLoaded({
        tracking: {
          tracing: {
            data: {
              traceContext: TRACE_CONTEXT.search,
              isStartOfTrace: false,
              isEndOfTrace: true,
            },
          },
        },
      }),
    );
  }

  traceWhileSuppliesLast() {
    this.store.dispatch(
      SearchActions.WhileSuppliesLast({
        tracking: {
          tracing: {
            data: {
              traceContext: TRACE_CONTEXT.search,
              isEndOfTrace: false,
              isStartOfTrace: true,
              attributes: {
                event: 'While Supplies Last',
              },
            },
            transformFunc: whileSuppliesLastTransformer,
          },
        },
      }),
    );
  }

  traceWhileSuppliesLastSuccess() {
    this.store.dispatch(
      SearchActions.WhileSuppliesLastSuccess({
        tracking: {
          tracing: {
            data: {
              traceContext: TRACE_CONTEXT.search,
              isEndOfTrace: true,
              isStartOfTrace: false,
              attributes: {
                event: 'While Supplies Last',
              },
            },
            transformFunc: whileSuppliesLastTransformer,
          },
        },
      }),
    );
  }
}
