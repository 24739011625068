/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { Platform } from '@ionic/angular';

@Directive({
  selector: '[customNegativeDecimal]',
})
export class CustomNegativeDecimalDirective implements OnInit {
  @Input() maxLeftHandDigits: number;
  @Input() maxPrecision: number;
  private scrubAlphaRegex: RegExp;
  private scrubBackRegex: RegExp;
  private isAndroid = false;

  constructor(private el: ElementRef, private platform: Platform) {}

  ngOnInit() {
    let targetNonDigits1 = '[^\\d.-]';
    let targetNonDigits2 = '[^\\d.]';
    this.scrubAlphaRegex = new RegExp(targetNonDigits1, 'g');
    this.scrubBackRegex = new RegExp(targetNonDigits2, 'g');
    this.isAndroid = this.platform.is('android');
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    if (!this.isAndroid) {
      let regexValue = event.target.value.replace(this.scrubAlphaRegex, '');
      if (regexValue.includes('.')) {
        let regexArray = regexValue.split('.');
        if (regexArray.length > 1) {
          regexValue =
            regexArray[0].slice(
              0,
              regexArray[0].includes('-')
                ? this.maxLeftHandDigits + 1
                : this.maxLeftHandDigits,
            ) +
            '.' +
            regexArray[1]
              .replace(this.scrubBackRegex, '')
              .slice(0, this.maxPrecision);
        }
      } else {
        regexValue = regexValue.slice(
          0,
          regexValue.includes('-')
            ? this.maxLeftHandDigits + 1
            : this.maxLeftHandDigits,
        );
      }

      if (regexValue.includes('-')) {
        regexValue = '-' + regexValue.split('-').join('');
      }

      this.el.nativeElement.value = regexValue;

      if (regexValue !== this.el.nativeElement.value) {
        event.target.value = regexValue;
      }
    }
  }
}
