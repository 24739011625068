import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppStateForProduct } from '@shared/models/app-state-for-product';
import { ListDataForProduct } from '@shared/models/list-data-for-product';
import { Product } from '@shared/models/product.model';
import {
  enrichProductWithListRelatedData,
  enrichProductWithPricingData,
  enrichProductWithThumbnail,
} from '@shared/selectors/helpers/product-info.selectors.helper';
import {
  appStateForProductsSelector,
  listDataForProduct,
  productSelector,
} from '@shared/selectors/product.selectors';
import {
  ProductPricingDetailState,
  selectProductPricingEntities,
} from '@usf/ngrx-pricing';

@Injectable({
  providedIn: 'root',
})
export class InventoryCreateSelectors {
  constructor() {}
  public productsForInventoryById = (productNumbers: number[]) =>
    createSelector(
      productSelector,
      selectProductPricingEntities,
      appStateForProductsSelector,
      listDataForProduct,
      (
        productsMap: Map<number, Product>,
        pricing: Dictionary<ProductPricingDetailState>,
        appStateForProduct: AppStateForProduct,
        listDataForProduct: ListDataForProduct,
      ) => {
        const mapToReturn = new Map<number, Product>();
        productNumbers?.forEach(productNumber => {
          const product = productsMap.get(productNumber);
          if (product && product?.productNumber) {
            enrichProductWithPricingData(product, pricing);
            enrichProductWithListRelatedData(
              product,
              listDataForProduct,
              appStateForProduct,
            );
            enrichProductWithThumbnail(product);
          }
          mapToReturn.set(productNumber, product);
        });
        return mapToReturn;
      },
    );
}
