import { IonicAuthOptions } from '@ionic-enterprise/auth';
import { PanEnv, SIT_FEATURES, sumFeatures } from './environment.features';

export const environment = {
  production: false,
  envName: 'sit',
  defaultZipCode: 29708,
  jwtPadding: 900000,
  authApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/auth-api/v1/oauth/token',
  minimumVersionsConfigApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/config-domain-api/v1/minimumVersions',
  impersonationApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/auth-api/v1/oauth/impersonation',
  userApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/user-domain-api/v1',
  productApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/product-domain-api/v1',
  productApiBaseUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/product-domain-api',
  mockListApi: false,
  listApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/list-domain-api/v1',
  alertsApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/alerts-domain-api/v1',
  fileShareApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/file-share-domain-api/v1',
  customerApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/customer-domain-api/v1',
  superUserApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/customer-domain-api/v2',
  recentlyViewedApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/product-domain-api/v1/recently-viewed/product-numbers',
  monetateAnalyticsApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/product-domain-api/v1/analytics',
  imageBase64ApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/product-domain-api/v1/image/base64',
  orderApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/order-domain-api/v1',
  orderSubmissionApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/order-submission-domain-api/v1',
  orderRequestReplyApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/order-request-reply-domain-api/v1',
  orderImportApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/order-import-api/v1',
  orderAgentApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/order-agent-domain-api/v1',
  partnerApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/partner-domain-api/v1',
  pricingApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/price-domain-api/v1',
  promotionApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/promotion-domain-api/v1',
  paymentApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/payment-domain-api/v1',
  paymentBankApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/payment-bank-domain-api/v1',
  paymentPayApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/payment-pay-domain-api/v1',
  paymentMetricsApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/payment-metric-domain-api/v1',
  inventoryApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/inventory-domain-api/v1',
  inventoryMigrationApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/inventory-migration-domain-api/v1',
  webSocketUrl: 'wss://e4409gs6fa.execute-api.us-west-2.amazonaws.com/sit',
  becomeACustomer:
    'https://aem-sit.cloud.usfoods.com/become-a-customer.html?experience=moxeguest',
  devToolsEnabled: false,
  upcUrl: 'https://upc-nonprod.a0.usfoods.com/sit/index.html',
  claimTicketApiUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/auth-api/v1/oauth/claims',
  mlmUrl: 'https://mlm-nonprod.a0.usfoods.com/sit/index.html?claimTicket=',
  paymentsUrl:
    'https://ecomsit2.usfood.com/order/faces/pub/sec/ecPortal.jspx?ORIGIN_TYPE=R4&DESTINATION_URL=PAYMENTS&claimTicket=',
  inventoryUrl:
    'https://ecomsit2.usfood.com/order/faces/pub/sec/ecPortal.jspx?ORIGIN_TYPE=R4&DESTINATION_URL=INVENTORY&claimTicket=',
  mppUrl:
    'https://ecomsit2.usfood.com/order/faces/pub/sec/ecPortal.jspx?ORIGIN_TYPE=R4&DESTINATION_URL=MPP&claimTicket=',
  baUrl:
    'https://ecomsit2.usfood.com/order/faces/pub/sec/ecPortal.jspx?ORIGIN_TYPE=R4&DESTINATION_URL=BUSINESS_ANALYTICS&claimTicket=',
  glUrl:
    'https://ecomsit2.usfood.com/order/faces/pub/sec/ecPortal.jspx?ORIGIN_TYPE=R4&DESTINATION_URL=GL&claimTicket=',
  checkUrl: 'https://aem-uat.cloud.usfoods.com/our-services/check.html',
  recipesUrl:
    'https://ecomsit2.usfood.com/order/faces/pub/sec/ecPortal.jspx?ORIGIN_TYPE=R4&DESTINATION_URL=RECIPES&claimTicket=',
  serveGoodUrl:
    'https://aem-uat.cloud.usfoods.com/why-us-foods/local-sustainable-wellbeing/sustainable-solutions/serve-good.html',
  mediaServerUrl: 'https://www.usfoods.com/content/dam/usf/images/category',
  farmersReportUrl:
    'https://aem-sit.cloud.usfoods.com/our-services/business-trends/farmers-report.html',
  tv360Url:
    'https://tv360api-staging.azurewebsites.net/api/Account/SSOToken?AccessToken=',
  aogUrl: 'https://staging.aog-usfoods.com/moxe',
  alertMarkAsReadTimeout: 3000,
  usabillaScriptSrc: 'assets/js/desktop_tablet_version.js',
  usabillaScriptId: 'feedbackScript',
  usabillaAppId: '730b5aad-f224-4dd0-a2ca-aac0e0fab50a',
  usabillaFormId: '6089a76e6f28d463a54834fe',
  usabillaButtonId: 'e3d8c6651236',
  usabillaCampaingIdReportDiscrepancy: '6bbd098562e2',
  usabillaCampaingIdDeleteAccount: 'f22750d3e929',
  usabillaCampaingIdPaymentSubmitted: '84fd25961871',
  logRocketScriptSrc: 'assets/js/logrocket_script.js',
  logRocketInit: 'v4ndkb/moxe-sit',
  logRocketBaseHref: 'https://ecomr4-sit.ama-nonprod.usfoods.com',
  adobeScriptSrc:
    'https://assets.adobedtm.com/c46e8e7e5a22/8d6faab2ead7/launch-33f848b85530-development.min.js',
  adobeScriptId: 'adobe-analytics',
  stateVersion: 19,
  googleMapsAPIKey: 'AIzaSyCLiu8I5mGdXcY41DPnsyA_BKG97BaorCk',
  upsTrackingUrl:
    'https://wwwapps.ups.com/WebTracking/processInputRequest?HTMLVersion=5.0&sort_by=status&term_warn=yes&button_index=201&tracknums_displayed=5&TypeOfInquiryNumber=T&loc=en_US&InquiryNumber1={TRACKING_NUMBER}&InquiryNumber2=&InquiryNumber3=&InquiryNumber4=&InquiryNumber5=&AgreeToTermsAndConditions=yes&track.x=18&track.y=9',
  uspsTrackingUrl:
    'https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1={TRACKING_NUMBER}&amp;qtc_senddate1=&amp;qtc_zipcode1=',
  fedexTrackingUrl:
    'https://www.fedex.com/fedextrack/?tracknumbers={TRACKING_NUMBER}&amp;cntry_code=us&amp;language=english',
  foodFanaticsUrl:
    'https://aem-uat.cloud.usfoods.com/great-food/food-fanatics/food-fanatics-magazine.html',
  careersUrl: 'https://aem-uat.cloud.usfoods.com/careers.html',
  ourBrandsUrl:
    'https://aem-uat.cloud.usfoods.com/about-us-foods/our-brands.html',
  myAccountUrl:
    'https://deeplink-nonprod.cloudtestusf.com/sit/deeplink/myaccount/index.html?originType=ECOM',
  deeplinkUrl:
    'https://deeplink-nonprod.cloudtestusf.com/sit/deeplink/ecomr4/index.html?',
  powerReviewsUrl: 'https://readservices-b2c.powerreviews.com/',
  coveoTokenUrl:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/auth-api/v1/coveo/searchToken',
  mobileLoginRetries: 5,
  nonUsfVendorFeatureFlag: true,
  flags: {
    // ALWAYS SET THIS TO A CONSTANT VALUE THAT MATCHES THE ENVIRONMENT
    // EX.) DEV = PanEnv.DEV, SIT = PanEnv.SIT, UAT = PanEnv.UAT etc.
    environment: PanEnv.SIT,
    // ADD ALL OF THE ADDITIONAL FEATURE FLAGS IN THE APP HERE
    // ADD ENABLED FEATURES HERE
    features: sumFeatures(SIT_FEATURES),
    splitIOAuthorizationKey: 'oustul5u26ccr3esqbhqrk1m27h7vmcpih1m',
  },
  /**
   * OPEN TELEMETRY SETTINGS
   */
  appName: 'ecomr4-sit',
  openTelemetryUrl: 'https://otel-sit.ama-nonprod.usfoods.com/v1/trace',
  otelConcurrencyLimit: 10,
  /**
   * BRIGHTCOVE VIDEO SETTINGS
   */
  brightCoveAccountId: '6297152412001',
  brightCovePlayerId: 'KbDOhL4KgB',
  /**
   * DEEP LINK SETTINGS
   */
  webDomain: 'ecomr4-sit.ama-nonprod.usfoods.com',
  premierWebDomain: 'sit.premierfsdo.com',
  mobileDomain: 'com.usfoods.ecomr4.sit',
  /**
   * POWER REVIEW SETTINGS
   */
  powerReview: {
    apiKey: '0c5f006d-e26c-44f3-8056-899b32b8d40b',
    locale: 'en_US',
    merchantGroupId: '80324',
    merchantId: '2091619667',
    reviewWrapperUrl: '/review-products?',
    styleSheet: '/assets/power-reviews.css',
  },
  /**
   * PRICING SETTINGS
   */
  pricingTimeout: 30000,
  priceBatchSize: 2000,
  /**
   * SEARCH PAGES FILTER CODES
   */
  whileSuppliesLast: 4294890060,
  direct: 4294953273,
  searchDeliveryFilterDirectShipped: '4293949871',
  searchDeliveryFilterSpecialOrder: '4293949876',
  searchDeliveryFilterUsFoodsDelivered: '4293949875',
  allScoop: '4293877485',
  /**
   * LOGROCKET SIT ENV AND KEYS
   * (To be added at a later date)
   */
  // baseHref: 'https://ecomr4-sit.ama-nonprod.usfoods.com',
  // logRocketKey: 'v4ndkb/moxe-sit',
  /**
   * APPCUES SETTINGS
   */
  appcuesAccountId: 141419,
  appcuesApplicationId: 'b0312e3c-bfc4-4553-9e56-ea8c29498366',
  appcuesLoggingLevel: true,
  appcues: {
    src: 'https://fast.appcues.com/141419.js',
  },
  /**
   * KIBO SETTINGS
   */
  kibo: {
    src: 'https://se.monetate.net/js/2/a-1fe50366/d/ecomr4-sit.ama-nonprod.usfoods.com/custom.js',
    personalizationEndpoint: '/recently-viewed/personalization',
  },
  /**
   * COVEO SETTINGS
   */
  coveoOrganizationId: 'usfoodsnonproduction2m26vaih1',
  /**
   * MERCHANDISING SETTINGS
   */
  prefetchedMerchandising: [{ name: 'ReviewOrderMerchZone' }],
  /**
   * APP STORE URLS
   */
  appleAppStoreLink:
    'https://apps.apple.com/us/app/us-foods-mox%C4%93-sit/id1583099677',
  googlePlayStoreLink:
    'https://play.google.com/store/apps/details?id=com.usfoods.ecomr4.sit',
  /**
   * HTTP BASIC CREDENTIALS
   */
  basicAuthPassword: 'G0VpVNrVYeLRHG7exfwt0zpn',
  basicAuthUsername: 'j5rg3WaydKSr9r7ZpcIlTnMV',
  /**
   * AZURE LOGIN URLS
   */
  azureSellerCustomerLogin:
    'https://usfoodsb2cnonprod.b2clogin.com/usfoodsb2cnonprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SIGNIN_SELLERSANDCUSTOMERS',
  azureCustomerLogin:
    'https://usfoodsb2cnonprod.b2clogin.com/usfoodsb2cnonprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SIGNIN_CUSTOMERS',
  azureSellerLogin:
    'https://usfoodsb2cnonprod.b2clogin.com/usfoodsb2cnonprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SIGNIN_SELLERS',
  azureEmailLogin:
    'https://usfoodsb2cnonprod.b2clogin.com/usfoodsb2cnonprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SIGNUP_INVITATION',
  impersonationLogin:
    'https://usfoodsb2cnonprod.b2clogin.com/usfoodsb2cnonprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_IMPERSONATION',
  automationLogin:
    'https://usfoodsb2cnonprod.b2clogin.com/usfoodsb2cnonprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SIGNIN_TESTS',
  invitationLogin:
    'https://usfoodsb2cnonprod.b2clogin.com/usfoodsb2cnonprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_Invite_User',
  azureChangeLoginInformation:
    'https://usfoodsb2cnonprod.b2clogin.com/usfoodsb2cnonprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_EDITMFAOPTIONS_CUSTOMERS',
  azurePremierLogin:
    'https://usfoodsb2cnonprod.b2clogin.com/usfoodsb2cnonprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SIGNIN_PREMIER',
  azureLogoutAllSessions:
    'https://panamax-api-sit.ama-nonprod.usfoods.com/auth-api/v1/oauth/signoutall',
  passwordlessInvitationLogin:
    'https://usfoodsb2cnonprod.b2clogin.com/usfoodsb2cnonprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_PASSWORDLESS_INVITE_USER',
};

export const azureWebConfig: IonicAuthOptions = {
  // the auth provider
  authConfig: 'azure',
  // The platform which we are running on
  platform: 'web',
  // client or application id for provider
  clientID: '81f0a718-fca4-43c3-8229-d30f858fc314',
  // the discovery url for the provider
  // OpenID configuration
  discoveryUrl:
    'https://usfoodsb2cnonprod.b2clogin.com/usfoodsb2cnonprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SIGNIN_SELLERSANDCUSTOMERS',
  // the URI to redirect to after log in
  redirectUri: 'https://ecomr4-sit.ama-nonprod.usfoods.com/desktop/b2c',
  // requested scopes from provider
  scope:
    'openid offline_access https://usfoodsb2cnonprod.onmicrosoft.com/f6a7438c-72bf-48d4-b4a0-ec951e11fcb7/tasks.read https://usfoodsb2cnonprod.onmicrosoft.com/f6a7438c-72bf-48d4-b4a0-ec951e11fcb7/tasks.write',
  // the URL to redirect to after log out
  logoutUrl: 'https://ecomr4-sit.ama-nonprod.usfoods.com/logout',
  // Show provider login in either current window or new tab
  implicitLogin: 'CURRENT',
};

export const premierWebConfig: IonicAuthOptions = {
  // the auth provider
  authConfig: 'azure',
  // The platform which we are running on
  platform: 'web',
  // client or application id for provider
  clientID: '81f0a718-fca4-43c3-8229-d30f858fc314',
  // the discovery url for the provider
  // OpenID configuration
  discoveryUrl:
    'https://usfoodsb2cnonprod.b2clogin.com/usfoodsb2cnonprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SIGNIN_PREMIER',
  // the URI to redirect to after log in
  redirectUri: 'https://sit.premierfsdo.com/desktop/b2c',
  // requested scopes from provider
  scope:
    'openid offline_access https://usfoodsb2cnonprod.onmicrosoft.com/f6a7438c-72bf-48d4-b4a0-ec951e11fcb7/tasks.read https://usfoodsb2cnonprod.onmicrosoft.com/f6a7438c-72bf-48d4-b4a0-ec951e11fcb7/tasks.write',
  // the URL to redirect to after log out
  logoutUrl: 'https://sit.premierfsdo.com/logout',
  // Show provider login in either current window or new tab
  implicitLogin: 'CURRENT',
};

export const azureNativeConfig: IonicAuthOptions = {
  // the auth provider
  authConfig: 'azure',
  // The platform which we are running on
  platform: 'capacitor',
  // client or application id for provider
  clientID: '165164b9-1183-4c9d-a047-ec9ac8d4687e',
  // the discovery url for the provider
  // OpenID configuration
  discoveryUrl:
    'https://usfoodsb2cnonprod.b2clogin.com/usfoodsb2cnonprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_SIGNIN_SELLERSANDCUSTOMERS_Mobile',
  // the URI to redirect to after log in
  redirectUri: 'com.usfoods.ecomr4.sit://touch/b2c',
  // requested scopes from provider
  scope:
    'openid offline_access https://usfoodsb2cnonprod.onmicrosoft.com/f6a7438c-72bf-48d4-b4a0-ec951e11fcb7/tasks.read https://usfoodsb2cnonprod.onmicrosoft.com/f6a7438c-72bf-48d4-b4a0-ec951e11fcb7/tasks.write',
  // the URL to redirect to after log out
  logoutUrl: 'com.usfoods.ecomr4.sit://logout',
  // The type of iOS webview to use. 'shared' will use a webview that can share session/cookies
  // on iOS to provide SSO across multiple apps but will cause a prompt for the user which asks them
  // to confirm they want to share site data with the app. 'private' uses a webview which will not
  // prompt the user but will not be able to share session/cookie data either for true SSO across
  // multiple apps.
  iosWebView: 'private',
};
