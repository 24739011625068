import { Component } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { CustomerSelectionBaseComponent } from '../customer-selection-base.component';

@Component({
  selector: 'app-customer-selection-desktop',
  templateUrl: './customer-selection-desktop.component.html',
  styleUrls: ['./customer-selection-desktop.component.scss'],
})
export class CustomerSelectionDesktopComponent extends CustomerSelectionBaseComponent {
  constructor(
    modalController: ModalController,
    popoverController: PopoverController,
    platformService: PlatformService,
  ) {
    super(modalController, popoverController, platformService);
  }
}
