import { SeperatedColumn } from '@shared/models/seperated-columns';
import { ListColumns } from './document-helper-models-and-enums';
import { desiredColumn } from '@shared/models/desired-column';

/**
 *
 * @param seperatedColumns Contains List Data
 * @param numberOfRows The Number of Data Rows
 * @param seperator
 * @returns
 */
export const stringifyPRICE = (
  seperatedColumns: SeperatedColumn[],
  numberOfRows: number,
  seperator: any,
  includeProductPrices: boolean,
): string => {
  // Variable to hold the seperated columns as one string
  let stringified = '';
  // note: no header row needed for price file
  // Add every row of data to return string
  for (let i = 0; i < numberOfRows; i++) {
    const line = seperatedColumns
      .map(pm => {
        const value = pm.columnValues ? pm.columnValues[i] : pm.singleValue;
        if (pm.columnName !== 'Product Price') return value;
        // if observed column is not product price, return retrieved value
        else if (includeProductPrices) return value;
        // if we are including prices, return the retrieved price value
        else return '""'; // if we are not including prices, insert empty string as price
      })
      .join(seperator);
    stringified += line + '\r\n';
  }
  return stringified;
};

export const getPRICEColumns = (
  includePoductPrices: boolean,
  includePoductNotes: boolean,
  includeProductType: boolean,
  includeProductStatus: boolean,
): desiredColumn[] => {
  const seperatedColumns = [];
  seperatedColumns.push({
    columnName: 'Product Number',
    columnType: ListColumns.productNumber,
  });
  seperatedColumns.push({
    columnName: 'Product Description',
    columnType: ListColumns.productDescription,
  });
  seperatedColumns.push({
    columnName: 'Product Brand',
    columnType: ListColumns.productBrand,
  });
  seperatedColumns.push({
    columnName: 'Product Package Size',
    columnType: ListColumns.productPackageSize,
  });
  if (includePoductPrices)
    seperatedColumns.push({
      columnName: 'Product Price',
      columnType: ListColumns.productPrice,
    });
  if (includePoductPrices)
    seperatedColumns.push({
      columnName: 'Product UOM',
      columnType: ListColumns.productUom,
    });
  seperatedColumns.push({
    columnName: 'Net Weight',
    columnType: ListColumns.netWeight,
  });
  seperatedColumns.push({
    columnName: 'Customer Product Number',
    columnType: ListColumns.customerProductNumber,
  });
  seperatedColumns.push({
    columnName: 'Catch Weight Product',
    columnType: ListColumns.catchWeightFlag,
  });
  seperatedColumns.push({
    columnName: 'Breakable Product',
    columnType: ListColumns.breakable,
  });
  return seperatedColumns;
};
