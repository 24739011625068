import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../shared/shared.module';
import * as fromServices from '../ngrx-customer/services';
import { CustomerEffects } from '../ngrx-customer/store/effects/customer.effects';
import { DivisionEffects } from '../ngrx-customer/store/effects/division.effects';
import { DivisionPhoneNumberEffects } from '@app/ngrx-customer/store/effects/division-phone-number.effects';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    SharedModule,
    EffectsModule.forFeature([
      CustomerEffects,
      DivisionEffects,
      DivisionPhoneNumberEffects,
    ]),
  ],
  providers: [...fromServices.services],
})
export class CustomerModule {}
