<div
  data-cy="product-card-pricing-section"
  class="usf-product-card-price"
  [class.flex-direction-row]="platformType !== platformEnum?.desktop"
  [class.list-management-price]="mode === productCardModes.listManagement"
  *ngIf="!isGuestUser"
>
  <div
    class="usf-product-card-case-container"
    [class.split-card-case-margin]="isSplitCard"
    [class.touch]="platformType !== platformEnum?.desktop"
  >
    <div
      data-cy="product-card-pricing-cases-section"
      class="usf-product-card-case"
      [class.touch]="platformType !== platformEnum?.desktop"
      [class.separate-shipping]="isProductShippingPriceEnabled$ | async"
    >
      <app-quantity-input
        *ngIf="
          !(mode === productCardModes.listManagement) && !hideQuantityBoxes
        "
        [productNumber]="productSummary?.productNumber"
        [quantity]="casesOrdered"
        [isDisabled]="
          (!isOnline && false === (offlineQtyBoxEnableFlag$ | async)) ||
          productSummary?.properties?.has(propertiesEnum.specialOrder) ||
          !isOrderable ||
          notFound
        "
        [isOffline]="!isOnline"
        [isEaches]="false"
        [platform]="platformType"
        (quantityUpdateEmitter)="changeQuantityHandler($event)"
        (quantityElementEmitter)="quantityInputUpdate($event)"
        [id]="hotkeyIds ? hotkeyIds[0] + listId + '-' + index : undefined"
        [isProductCard]="true"
      >
      </app-quantity-input>
      <div
        [class.split-card]="isSplitCard"
        class="price-text"
        data-cy="product-card-pricing-cases-text-section"
      >
        <div
          *ngIf="
            !productSummary?.properties?.has(propertiesEnum.specialOrder) &&
            !notFound
          "
          data-cy="product-card-pricing-cases-text"
          (mouseover)="handleMouseOver($event)"
          (mouseleave)="handleMouseLeave()"
        >
          <app-price-display
            [disabled]="pricingDisabled"
            [price]="pricing"
            [displayCases]="true"
            [catchWeightFlag]="productSummary?.catchWeightFlag"
            [priceUom]="productSummary?.priceUom"
          ></app-price-display>
        </div>
        <span
          *ngIf="productSummary?.properties?.has(propertiesEnum.specialOrder)"
          [attr.data-cy]="
            'product-special-order-message-' +
            productSummary?.productNumber +
            '-text'
          "
        >
          {{ 'i18n.lists.specialOrderProductMessage' | translate }}
        </span>
      </div>
    </div>
    <div class="product-shipping-price-text">
      <app-product-shipping-price
        *ngIf="
          productSummary?.properties?.has(propertiesEnum.direct) &&
          (isProductShippingPriceEnabled$ | async) &&
          !productSummary?.properties?.has(propertiesEnum.specialOrder) &&
          !notFound
        "
        [class.split-card]="isSplitCard"
        [pricing]="pricing"
        [platformType]="platformType"
        [disabled]="pricingDisabled"
        [isSingleLineDisplay]="
          isShippingPriceSingleLineDisplay ||
          platformType !== platformEnum?.desktop
        "
      ></app-product-shipping-price>
    </div>
  </div>
  <div
    *ngIf="productSummary?.breakable && !notFound"
    data-cy="product-card-pricing-eaches-section"
    class="usf-product-card-each"
    [class.touch]="platformType !== platformEnum?.desktop"
  >
    <app-quantity-input
      *ngIf="!(mode === productCardModes.listManagement) && !hideQuantityBoxes"
      [productNumber]="productSummary?.productNumber"
      [quantity]="eachesOrdered"
      [isDisabled]="
        (!isOnline && false === (offlineQtyBoxEnableFlag$ | async)) ||
        productSummary?.properties?.has(propertiesEnum.specialOrder) ||
        !isOrderable ||
        notFound
      "
      [isOffline]="!isOnline"
      [isEaches]="true"
      [platform]="platformType"
      (quantityUpdateEmitter)="changeQuantityHandler($event)"
      (quantityElementEmitter)="quantityInputUpdate($event)"
      [id]="hotkeyIds ? hotkeyIds[1] + listId + '-' + index : undefined"
      [isProductCard]="true"
    >
    </app-quantity-input>
    <div
      [class.split-card]="isSplitCard"
      class="price-text"
      data-cy="product-card-pricing-eaches-text-section"
    >
      <span
        *ngIf="
          productSummary?.properties?.has(propertiesEnum.specialOrder);
          else priceDisplay
        "
        [attr.data-cy]="
          'product-special-order-message-' +
          productSummary?.productNumber +
          '-text'
        "
      >
        {{ 'i18n.lists.specialOrderProductMessage' | translate }}
      </span>
      <ng-template #priceDisplay>
        <app-price-display
          data-cy="product-card-pricing-eaches-text"
          [disabled]="pricingDisabled"
          [price]="pricing"
          [displayCases]="false"
          [eachUom]="productSummary?.eachUom"
        ></app-price-display>
      </ng-template>
    </div>
  </div>
</div>
