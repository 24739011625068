import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { PriceViewModel } from '@shared/models/price-view.model';

export enum PriceDisplayStatus {
  initial = 'initial',
  disabled = 'disabled',
  loading = 'loading',
  unavailable = 'unavailable',
  available = 'available',
  specialOrder = 'special order',
}

@Component({
  selector: 'app-price-display',
  templateUrl: './price-display.component.html',
  styleUrls: ['./price-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PriceDisplayComponent implements OnChanges {
  @Input() price: PriceViewModel;
  @Input() displayCases = true;
  @Input() catchWeightFlag: boolean;
  @Input() priceUom;
  @Input() eachUom;
  @Input() disabled = false;
  @Input() addMargin = true;
  @Input() specialOrder = false;
  value: number;
  uom = '';

  status: PriceDisplayStatus = PriceDisplayStatus.initial;
  statusList = PriceDisplayStatus;

  constructor() {}

  resetValues() {
    this.value = undefined;
    this.uom = undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled?.currentValue || this.disabled) {
      this.status = PriceDisplayStatus.disabled;
      return;
    }
    if (!changes.price?.currentValue) {
      // Price not defined, do nothing
      this.status = PriceDisplayStatus.initial;
      return;
    }
    if (changes.price?.currentValue?.loading) {
      this.status = PriceDisplayStatus.loading;
      return;
    }
    if (this.displayCases) {
      if (
        changes.price?.currentValue?.unitPrice != undefined &&
        changes.price?.currentValue?.unitPrice * 1 > 0
      ) {
        this.status = this.specialOrder
          ? PriceDisplayStatus.specialOrder
          : PriceDisplayStatus.available;
        this.value = changes.price?.currentValue.unitPrice;
        this.uom = this.catchWeightFlag
          ? '/' + this.priceUom
          : this.priceUom || 'CS';
      } else {
        this.status = PriceDisplayStatus.unavailable;
        this.resetValues();
      }
    } else {
      if (
        changes.price?.currentValue?.eachPrice != undefined &&
        changes.price?.currentValue?.eachPrice * 1 > 0
      ) {
        this.status = this.specialOrder
          ? PriceDisplayStatus.specialOrder
          : PriceDisplayStatus.available;
        this.value = changes.price?.currentValue.eachPrice;
        this.uom = this.eachUom || 'EA';
      } else {
        this.status = PriceDisplayStatus.unavailable;
        this.resetValues();
      }
    }
  }
}
