import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';
import { OrderHeader, OrderItem } from '@usf/ngrx-order';

export const ORDER_ANALYTICS_ACTIONS = {
  trackHandPricingModalOpen: createAction(
    '[NgRxClient Order-Analytics] - Track Hand Pricing Modal Open',
    props<{
      tracking: Tracking;
    }>(),
  ),

  trackHandPricingChange: createAction(
    '[NgRxClient Order-Analytics] - Track Hand Pricing Value Change',
    props<{
      tracking: Tracking;
    }>(),
  ),

  trackDidYouForgetClick: createAction(
    '[NgRxOrder Analytics] - Track Did You Forget Click',
    props<{ tracking: Tracking }>(),
  ),

  trackInspiredPicksClick: createAction(
    '[NgRxOrder Analytics] - Track Inspired Picks Click',
    props<{ tracking: Tracking }>(),
  ),

  trackBuyMoreAndSaveClick: createAction(
    '[NgRxOrder Analytics] - Track By More and Save Click',
    props<{ tracking: Tracking }>(),
  ),

  trackAddToOrder: createAction(
    '[NgRxOrder Analytics] - Track Add to Order',
    props<{
      trackingAnalytics: Tracking;
      updateOrderHeader: OrderHeader;
      total: number;
      origOrderItemsClone: OrderItem[];
      updateOrderItemSequence: any;
    }>(),
  ),

  trackAddToOrderSuccess: createAction(
    '[NgRxOrder Analytics] - Track Add to Order Success',
    props<{
      tracking: Tracking;
    }>(),
  ),

  trackSearchForSubs: createAction(
    '[NgRxOrder Analytics] - Track Search for Subs',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackOrderPageLoad: createAction(
    '[NgRxOrder Analytics] Track Order Page Load',
    props<{ tracking: Tracking }>(),
  ),
  jumpToDirectClick: createAction(
    '[NgRxOrder Analytics] Track Jump To Direct Click',
    props<{ tracking: Tracking }>(),
  ),
  trackPrintSubmittedOrder: createAction(
    '[NGRXAnalytics - Order] Track Print Submitted Order',
    props<{ tracking: Tracking }>(),
  ),
  trackPrintOrderInCart: createAction(
    '[NGRXAnalytics - Order] Track Print Order In Cart',
    props<{ tracking: Tracking }>(),
  ),
  trackQoeProductAddToOrder: createAction(
    '[NgRxOrder Analytics] - Track QOE Product Add to Order',
    props<{ tracking: Tracking }>(),
  ),
  trackConfirmQuantityException: createAction(
    '[NGRXAnalytics - Order] Confirm Quantity Exception',
    props<{ tracking: Tracking }>(),
  ),
  trackMerchZoneClick: createAction(
    '[NGRXAnalytics - Merch] Track Merch Zone Click',
    props<{ tracking: Tracking }>(),
  ),
  trackFrequentlyBoughtTogetherDisplayed: createAction(
    '[NGRXAnalytics - Merch] Track Frequently Bought Together Displayed',
    props<{ tracking: Tracking }>(),
  ),
  trackFrequentlyBoughtTogetherView: createAction(
    '[NGRXAnalytics - Merch] Track Frequently Bought Together View',
    props<{ tracking: Tracking }>(),
  ),
  trackQoePageLoad: createAction(
    '[NgRxOrder Analytics] Track Quick Order Entry Page Load',
    props<{ tracking: Tracking }>(),
  ),
  trackOrderInsightsPageLoad: createAction(
    '[NgRxOrder Analytics] Track Order Insights Page Load',
    props<{ tracking: Tracking }>(),
  ),
  trackOrderInsightProductAddToOrder: createAction(
    '[NgRxOrder Analytics] Track Order Insights Add to Order',
    props<{ tracking: Tracking }>(),
  ),
};
