import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import {
  LOCAL_STORAGE_KEYS,
  PlatformEnum,
  selectMinimumVersions,
} from '@panamax/app-state';
import { Deploy } from 'cordova-plugin-ionic';
import { firstValueFrom } from 'rxjs';
import semver from 'semver';
import { ForceAppUpdateComponent } from '../components/popovers/force-app-update/force-app-update.component';
import { PLATFORM_CAPACITOR_TYPE } from '../constants/platform-capacitor';

@Injectable({
  providedIn: 'root',
})
export class UpdateMoxeService {
  constructor(
    private store: Store,
    private popoverController: PopoverController,
  ) {}

  async checkForMoxeUpdates() {
    const hasUpdate = await Deploy.sync({ updateMethod: 'background' });

    typeof hasUpdate !== 'undefined'
      ? localStorage.setItem(
          LOCAL_STORAGE_KEYS.liveUpdateAvailable,
          JSON.stringify(true),
        )
      : localStorage.setItem(
          LOCAL_STORAGE_KEYS.liveUpdateAvailable,
          JSON.stringify(false),
        );
  }

  async executeBackgroundRefresh() {
    if (
      !JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEYS.liveUpdateAvailable),
      ) ||
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.shouldBlockReload))
    ) {
      return;
    }

    await Deploy.reloadApp();
  }

  async compareVersions(platformType: PlatformEnum) {
    let currentVersion = await this.getCurrentVersion();
    let minimumVersion = await this.getMinimumSupportedVersion();

    try {
      if (semver.lt(currentVersion, minimumVersion)) {
        await this.openForceAppUpdatePopover(platformType);
        return true;
      }
    } catch (e) {
      console.log('[UpdateMoxeService] ERROR: ' + e);
      return false;
    }

    return false;
  }

  async getCurrentVersion() {
    return (await App.getInfo()).version;
  }

  async getMinimumSupportedVersion() {
    let platform = Capacitor.getPlatform();
    let minimumVersions = await firstValueFrom(
      this.store.select(selectMinimumVersions()),
    );

    return platform === PLATFORM_CAPACITOR_TYPE.ANDROID
      ? minimumVersions.android
      : minimumVersions.ios;
  }

  async openForceAppUpdatePopover(platformType: PlatformEnum) {
    const popover = await this.popoverController.create({
      component: ForceAppUpdateComponent,
      componentProps: {
        platformType,
      },
      backdropDismiss: false,
      cssClass:
        platformType === PlatformEnum.tablet
          ? 'force-app-update-popover-tablet'
          : 'force-app-update-popover-mobile',
      mode: 'ios',
      translucent: false,
    });

    await popover.present();
  }
}
