import { Injectable } from '@angular/core';
import { PanAppState } from '@panamax/app-state';
import { environment } from 'src/environments/environment';
import { buildNumber } from 'src/buildNumber';
import { GetFeedbackCapacitor } from '@usf/getfeedback-capacitor';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class UsabillaSdkService {
  buildNumber = buildNumber;
  constructor(protected panAppState: PanAppState) {
    if (Capacitor.isNativePlatform()) {
      GetFeedbackCapacitor.initialize({ appID: environment.usabillaAppId });
      this.setUsabillaSDKVariables();
    }
  }

  campaignSDKTrigger(campaignName: string): Promise<any> {
    return GetFeedbackCapacitor.sendEvent({ eventName: campaignName });
  }

  // may never be called. Added for potential edge cases
  setSDKVariables(variables: any) {
    GetFeedbackCapacitor.setCustomVariables({
      customVariables: variables,
    });
  }

  loadSDKForm(): Promise<any> {
    return GetFeedbackCapacitor.loadFeedbackFormWithCurrentViewScreenshot({
      formID: environment.usabillaFormId,
    });
  }

  setUsabillaSDKVariables(): void {
    this.panAppState.user$
      .pipe(
        withLatestFrom(
          this.panAppState.customer$,
          this.panAppState.currentUrl$,
          this.panAppState.logrocketUrl$,
        ),
        filter(([user, customer]) => !!user && !!customer),
        take(1),
      )
      .subscribe(([user, customer, currentUrl, logrocketUrl]) => {
        const data = this.panAppState.buildFeedbackVariables(
          user,
          customer,
          currentUrl,
          logrocketUrl,
        );
        const customData = data || '';
        this.setSDKVariables(customData);
      });
  }
}
