import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiUpdatable } from '../../models/api-updatable';
import { PanAppState, PlatformEnum } from '@panamax/app-state';
import { IonInput } from '@ionic/angular';
import { QuantityUpdate } from '../../models/quantity-update';
import { ProductPropertiesEnum, ProductSummary } from '@usf/product-types';
import { PriceViewModel } from '../../models/price-view.model';
import { UsfProductCardModeEnum } from '../../constants/usf-product-card-mode.enum';
import { ProductShippingPriceService } from '@shared/services/product-shipping-price/product-shipping-price.service';
import { Observable } from 'rxjs';
import { FEATURES } from '@shared/constants/splitio-features';

@Component({
  selector: 'app-product-card-ordering',
  templateUrl: './product-card-ordering.component.html',
  styleUrls: ['./product-card-ordering.component.scss'],
})
export class ProductCardOrderingComponent implements OnInit {
  @Input() productSummary: ProductSummary;
  @Input() pricing: PriceViewModel;
  @Input() casesOrdered: ApiUpdatable<number>;
  @Input() eachesOrdered: ApiUpdatable<number>;
  @Input() isOrderable: boolean;
  @Input() isOnline: boolean;
  @Input() platformType: PlatformEnum;
  @Input() index: number;
  @Input() listId: string | number;
  @Input() hotkeyIds: string[];
  @Input() pricingDisabled: boolean;
  @Input() mode: UsfProductCardModeEnum;
  @Input() notFound: boolean;
  @Input() hideQuantityBoxes: boolean;
  @Input() isSplitCard: boolean;
  @Input() isShippingPriceSingleLineDisplay = false;
  @Input() isGuestUser: boolean;
  readonly productCardModes = UsfProductCardModeEnum;
  readonly propertiesEnum = ProductPropertiesEnum;
  readonly platformEnum = PlatformEnum;

  @Output() quantityUpdateEmitter = new EventEmitter<QuantityUpdate>();
  @Output() quantityElementEmitter = new EventEmitter<IonInput>();
  @Output() mouseOverEvent = new EventEmitter<any>();
  @Output() mouseLeaveEvent = new EventEmitter<any>();

  isProductShippingPriceEnabled$: Observable<boolean>;
  offlineQtyBoxEnableFlag$: Observable<boolean>;

  constructor(
    private productShippingPriceService: ProductShippingPriceService,
    readonly panAppState: PanAppState,
  ) {}

  ngOnInit() {
    this.isProductShippingPriceEnabled$ =
      this.productShippingPriceService.isProductShippingPriceEnabled$();
    this.offlineQtyBoxEnableFlag$ = this.panAppState.feature$([
      FEATURES.split_global_offline_update_quantities,
    ]);
  }

  changeQuantityHandler(event: QuantityUpdate) {
    this.quantityUpdateEmitter.emit(event);
  }

  quantityInputUpdate(event: IonInput) {
    this.quantityElementEmitter.emit(event);
  }

  handleMouseOver(event: any) {
    this.mouseOverEvent.emit(event);
  }

  handleMouseLeave() {
    this.mouseLeaveEvent.emit();
  }
}
