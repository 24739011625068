import { ModuleWithProviders, NgModule } from '@angular/core';
import { DragDropContainerDirective, DragDropItemDirective } from './drag-drop.directive';
@NgModule({
    imports: [],
    declarations: [
        DragDropContainerDirective,
        DragDropItemDirective
    ],
    exports: [
        DragDropContainerDirective,
        DragDropItemDirective
    ]
})
export class LMADragDropModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
            ngModule: LMADragDropModule,
        };
    }
}
