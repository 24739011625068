import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ActionSheetController,
  IonNav,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { TranslateService } from '@ngx-translate/core';
import { PlatformEnum } from '@panamax/app-state';
import { EllipsisOptionsPopoverComponent } from '@shared/components/popovers/ellipsis-options/ellipsis-options-popover.component';
import { RadioBtnListComponent } from '@shared/components/radio-btn-list/radio-btn-list.component';
import { DownloadRequestFileType } from '@usf/list-types';
import { InventoryGroupHeader, selectInventoryById } from '@usf/ngrx-inventory';
import { DownloadListOptions } from '@usf/ngrx-list';
import { Observable } from 'rxjs';
import { InventoryState } from '@usf/ngrx-inventory/lib/models/inventory/inventory.model';
import { DownloadInventoryModalOutput } from '@usf/ngrx-inventory';
import { DatePipe } from '@angular/common';
import { InventoryWorksheetService } from '@inventory/pages/inventory-worksheet/services/inventory-worksheet.service';

@Component({
  selector: 'app-download-inventory-modal',
  templateUrl: './download-inventory-modal.component.html',
  styleUrls: ['./download-inventory-modal.component.scss'],
})
export class DownloadInventoryModalComponent implements OnInit {
  @Input() platformType: PlatformEnum;
  @Input() inventoryId: string;
  @Input() inventoryName: string;
  @Input() inventoryDate: Date | string;
  @Input() groups: InventoryGroupHeader[];
  readonly platformEnum = PlatformEnum;

  fileName: string;
  format: DownloadRequestFileType;
  includeFreehandCount = false;
  includeProductStatus = false;
  selectedGroup: string;
  groupButtons: any;
  singleGroupChosen = false;
  groupRadioValue = 'allGroups';

  fileTypeButtons = [
    {
      text: 'PDF',
      handler: () => {
        this.format = 'PDF';
        if (this.platformType === PlatformEnum.desktop)
          this.popoverController.dismiss();
      },
    },
    {
      text: 'CSV',
      handler: () => {
        this.format = 'CSV';
        if (this.platformType === PlatformEnum.desktop)
          this.popoverController.dismiss();
      },
    },
  ];

  @Output()
  downloadEmitter: EventEmitter<DownloadListOptions> = new EventEmitter();
  @Output() dismissEvent: EventEmitter<void> = new EventEmitter();

  constructor(
    private popoverController: PopoverController,
    private nav: IonNav,
    private modalController: ModalController,
    private translateService: TranslateService,
    private actionSheetController: ActionSheetController,
    private store: Store<State>,
    private datePipe: DatePipe,
    private inventoryWorksheetService: InventoryWorksheetService,
  ) {}

  ngOnInit(): void {
    this.fileName =
      this.inventoryName +
      ' ' +
      this.datePipe.transform(this.inventoryDate, 'M/d/yy');
    this.format = 'PDF';
    this.groupButtons = this.groups.map(group => {
      return {
        text: group.groupName,
        handler: () => {
          this.selectedGroup = group.groupName;
          if (this.platformType === PlatformEnum.desktop)
            this.popoverController.dismiss();
        },
      };
    });
    if (this.platformType !== this.platformEnum.mobile) {
      this.selectedGroup = this.groups[0].groupName;
    }
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  download() {
    this.inventoryWorksheetService.trackDownloadInventoryWorksheet(
      this.inventoryId,
      this.format,
    );
    this.modalController.dismiss({
      inventoryId: this.inventoryId,
      selectedGroup: this.singleGroupChosen ? this.selectedGroup : undefined,
      fileName: this.fileName,
      format: this.format,
      includeFreehandCount: this.includeFreehandCount,
      includeProductStatus: this.includeProductStatus,
    } as DownloadInventoryModalOutput);
  }

  async openFiletypeOptions(event) {
    if (this.platformType === PlatformEnum.desktop) {
      this.openPopover(event, this.fileTypeButtons);
    } else {
      this.openActionSheet(this.fileTypeButtons);
    }
  }

  async openActionSheet(buttons: any[]) {
    buttons.push({
      text: this.translateService.instant('i18n.common.cancel'),
      role: 'cancel',
      cssClass: 'cancel-button',
    });
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'action-sheet-touch',
      mode: 'ios',
      buttons,
    });
    await actionSheet.present();
  }

  async openPopover(event: any, buttons: any[]) {
    const popover = await this.popoverController.create({
      component: EllipsisOptionsPopoverComponent,
      event,
      componentProps: {
        buttons,
      },
      cssClass: 'ellipsis-options-popover-desktop',
      mode: 'ios',
      showBackdrop: false,
    });
    await popover.present();
  }

  closeOptions() {
    if (this.platformType === this.platformEnum.desktop) {
      this.popoverController.dismiss();
    } else {
      this.actionSheetController.dismiss();
    }
  }

  async openGroupOptions(event) {
    if (this.platformType === PlatformEnum.desktop) {
      this.openPopover(event, this.groupButtons);
    } else {
      this.openActionSheet(this.groupButtons);
    }
  }

  onGroupSelection() {
    this.singleGroupChosen = !this.singleGroupChosen;
    if (!this.singleGroupChosen && this.platformType === PlatformEnum.mobile) {
      this.selectedGroup = '';
    }
  }

  onSelectGroupMobile() {
    this.singleGroupChosen = true;
    this.nav.push(RadioBtnListComponent, {
      label: 'i18n.inventory.downloadInventoryModal.selectGroup',
      listOfSelectableLabels: this.groups.map(group => group.groupName),
      selectedLabel: this.selectedGroup,
      callBack: this.callBackGroup.bind(this),
      platformType: this.platformType,
    });
  }

  callBackGroup(selectedGroup: string) {
    this.selectedGroup = selectedGroup;
    this.groupRadioValue = 'singleGroup';
    if (!this.selectedGroup) {
      this.groupRadioValue = 'allGroups';
      this.singleGroupChosen = false;
    }
  }
}
