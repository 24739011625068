import { Component } from '@angular/core';
import { PlatformService } from '@panamax/app-state';
import { ImportOrderBaseComponent } from '../import-order-base.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-import-order-modal',
  templateUrl: './import-order-modal.component.html',
  styleUrls: ['./import-order-modal.component.scss'],
})
export class ImportOrderModalComponent extends ImportOrderBaseComponent {
  constructor(
    platformService: PlatformService,
    translateService: TranslateService,
  ) {
    super(platformService, translateService);
  }
}
