import { Tracking } from '@panamax/app-state';
import { OrderAnalyticsMessages } from '../order-analytics.constants';
import { genericAnalyticsTransformer } from '@shared/tracking/analytics/generic-analytics-transformer';
import { AnalyticsProduct } from '@shared/models/tracking/analytics/analytics-product';

export const confirmQuantiyExceptionAnalytics = (
  orderId: string,
  origOrderId: string,
  products: AnalyticsProduct[],
): Tracking => {
  return {
    analytics: {
      data: {
        event: OrderAnalyticsMessages.confirmQuantiyException,
        capability: OrderAnalyticsMessages.capability,
        order: {
          id: origOrderId || orderId,
        },
        products,
      },
      transformFunc: genericAnalyticsTransformer,
    },
  };
};
