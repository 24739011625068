import { DatePipe } from '@angular/common';
import { CustomerDivisionDepartment } from '@app/ngrx-customer/models/customer-division-department.model';
import { desiredColumn } from '@shared/models/desired-column';
import jsPDF from 'jspdf';
import {
  addDataColumns,
  addFooterToPDF,
  addLogoToHeader,
  addNameToHeader,
  addProductCountToHeader,
  addUserDataToHeader,
  createHeadersForPDF,
  loadImagePair,
  setupPreHeader,
} from './general-pdf-helper';
import autoTable, { CellDef, CellHookData } from 'jspdf-autotable';
import {
  ImageIconPair,
  productConversionColumns,
} from './document-helper-models-and-enums';
import { getRemoteImages, isRemoteAsset } from './list-pdf-helper';
import { ServiceHandlerService } from '@shared/services/service-handler.service';
import { PDFColors } from '@shared/constants/pdf-colors.enum';
import { BODY_FONT } from '@shared/constants/pdf-font';

export const createProductComparisonPdf = async (
  doc: jsPDF,
  isBetterBuy: boolean,
  customerDivisionDepartment: CustomerDivisionDepartment,
  columns: desiredColumn[],
  isMobile: boolean,
  updates: number,
  imageKeys: Set<string>,
  serviceHandler: ServiceHandlerService,
  dataTable: string[][][],
  reasons: string[],
  dataPairs: string[][],
): Promise<jsPDF> => {
  const date = new DatePipe('en').transform(Date.now(), 'M/d/yyyy');

  const imageColumnIndexes = [3, 4];
  const imageMap = await prepImages(
    imageColumnIndexes,
    imageKeys,
    serviceHandler,
  );

  const fullName = (isBetterBuy ? 'BETTER BUYS ' : 'LIST UPDATES ') + date;
  await addUpdatesHeaderToPDF(
    doc,
    fullName,
    updates,
    customerDivisionDepartment,
    isMobile,
  );

  const cellDefinitions: CellDef[][] = createHeadersForPDF(columns);

  addProductCompareColumnDataToPDF(
    doc,
    dataTable,
    columns,
    cellDefinitions,
    imageMap,
    imageColumnIndexes,
    reasons,
    dataPairs,
  );

  addFooterToPDF(doc);
  return doc;
};

export const prepImages = async (
  imageIndexStatusType: number[],
  imageKeys: Set<string>,
  serviceHandler: ServiceHandlerService,
) => {
  let imagePromises: Promise<ImageIconPair>[] = [];
  const imageMap = new Map<string, HTMLImageElement>();
  if (imageIndexStatusType.length > 0) {
    const remoteImageMap = await getRemoteImages(serviceHandler, imageKeys);
    for (const imageSrc of imageKeys) {
      if (isRemoteAsset(imageSrc)) {
        const image = remoteImageMap.get(imageSrc);
        if (!!image) {
          let pair = new Promise<ImageIconPair>((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve({ icon: imageSrc, image: img });
            img.onerror = reject;
            img.src = image;
          });
          imagePromises.push(pair);
        }
      } else {
        imagePromises.push(loadImagePair(imageSrc));
      }
    }
  }
  const imageArray = await Promise.all(imagePromises);
  imageArray.forEach(imageIconPair => {
    imageMap.set(imageIconPair.icon, imageIconPair.image);
  });
  return imageMap;
};

export const addUpdatesHeaderToPDF = async (
  doc: jsPDF,
  name: string,
  numberOfProducts: number,
  customerDivisionDepartment: CustomerDivisionDepartment,
  isMobile: boolean,
) => {
  await addLogoToHeader(doc, isMobile);
  addNameToHeader(doc, name);
  addProductCountToHeader(doc, name, numberOfProducts, 'Update');
  addUserDataToHeader(doc, customerDivisionDepartment, isMobile);
};

export const addProductCompareColumnDataToPDF = (
  doc: jsPDF,
  dataTable: string[][][],
  desiredColumns: desiredColumn[],
  cellDefinitions: CellDef[][],
  imageMap: Map<string, HTMLImageElement>,
  imageColumnIndexes: number[],
  reasons: string[],
  dataPairs: string[][],
) => {
  let groupNameIndex = 0;
  let nextTableYPosition = 145;

  const valueToSkip = 720;
  dataTable.forEach(itemTablePerGroup => {
    if ((doc as any).previousAutoTable.finalY > valueToSkip) {
      doc.addPage();
      doc.setPage(doc.getNumberOfPages());
      nextTableYPosition = 20;
    }
    let reason = '';
    if (reasons.length > groupNameIndex) {
      reason = reasons[groupNameIndex];
    }

    // figure out reason code
    setupPreHeader(doc, reason, nextTableYPosition);
    nextTableYPosition = (doc as any).previousAutoTable.finalY;

    addDataColumns(
      doc,
      itemTablePerGroup,
      nextTableYPosition,
      cellDefinitions,
      desiredColumns,
      imageMap,
      imageColumnIndexes,
    );
    nextTableYPosition = (doc as any).previousAutoTable.finalY;
    setupPostFooter(doc, dataPairs[groupNameIndex], nextTableYPosition);
    nextTableYPosition = (doc as any).previousAutoTable.finalY + 16;
    groupNameIndex++;
  });
};

export const setupPostFooter = (
  doc: jsPDF,
  textToWrite: string[],
  nextTableYPosition: number,
) => {
  if (nextTableYPosition > 860) {
    doc.addPage('', 'l');
    nextTableYPosition = 25;
  }
  //inject up to 3 text objects
  autoTable(doc, {
    body: [
      [
        {
          content: '',
          styles: {
            fillColor: PDFColors.pdfWhite,
            halign: 'left',
            valign: 'middle',
            fontSize: BODY_FONT,
            textColor: PDFColors.pdfBlack,
            lineColor: PDFColors.pdfBlack,
            lineWidth: 1,
            minCellHeight: 34,
            font: 'helvetica',
            fontStyle: 'bold',
            cellPadding: { left: 10 },
          },
        },
      ],
    ],
    startY: nextTableYPosition,
    margin: {
      left: 10,
      right: 10,
      bottom: 100,
    },
    theme: 'grid',
    tableWidth: 'auto',
    pageBreak: 'auto',
    rowPageBreak: 'avoid',
    styles: {
      lineColor: PDFColors.pdfBlack,
      lineWidth: 1,
    },
    didDrawCell: (cellData: CellHookData) => {
      const cell = cellData.cell;
      doc.setTextColor(PDFColors.pdfRed);
      doc.text(textToWrite[0], cell.x + 10, cell.y + cell.height / 1.55);
      doc.setTextColor(PDFColors.pdfBlack);
      doc.text(
        textToWrite[1],
        cell.x + cell.width - 320,
        cell.y + cell.height / 1.55,
      );
      doc.setTextColor(PDFColors.pdfBlack);
      doc.text(
        textToWrite[2],
        cell.x + cell.width - 140,
        cell.y + cell.height / 1.55,
      );
    },
  });
};

export const getPDFColumns = (): desiredColumn[] => {
  const seperatedColumns: desiredColumn[] = [];

  seperatedColumns.push({
    columnName: ' ',
    columnType: productConversionColumns.pdfOriginality,
    cellWidth: 120,
  });
  seperatedColumns.push({
    columnName: 'Product #',
    columnType: productConversionColumns.productNumber,
    cellWidth: 100,
  });

  seperatedColumns.push({
    columnName: 'Product Description',
    columnType: productConversionColumns.productDescription,
    cellWidth: 610,
    halign: 'left',
    cellPadding: 10,
  });

  seperatedColumns.push({
    columnName: 'Product Status',
    columnType: productConversionColumns.productStatusImage,
    cellWidth: 125,
  });
  seperatedColumns.push({
    columnName: 'Product Type',
    columnType: productConversionColumns.productType,
    cellWidth: 125,
  });

  seperatedColumns.push({
    columnName: 'Brand',
    columnType: productConversionColumns.productBrand,
    cellWidth: 100,
    cellPadding: 5,
  });

  seperatedColumns.push({
    columnName: 'Pack Size',
    columnType: productConversionColumns.productPackageSizeForPDF,
    cellWidth: 120,
  });

  seperatedColumns.push({
    columnName: 'Case / Each Price',
    columnType: productConversionColumns.eachAndCasePrice,
    cellWidth: 120,
  });
  return seperatedColumns;
};
