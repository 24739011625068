export const timezoneByCode: Map<
  string,
  { abbr: string; location: string }
> = new Map([
  ['ET', { abbr: 'ET', location: 'America/New_York' }],
  ['CT', { abbr: 'CT', location: 'America/Chicago' }],
  ['MT', { abbr: 'MT', location: 'America/Phoenix' }],
  ['PT', { abbr: 'PT', location: 'America/Los_Angeles' }],
  ['HT', { abbr: 'HT', location: 'America/Honolulu' }],
  ['AKT', { abbr: 'AKT', location: 'America/Juneau' }],
]);
