import { createSelector } from '@ngrx/store';
import { selectAllFeaturesEnabled, selectOnline } from '@panamax/app-state';
import { ProductFilteringEnum } from '@shared/constants/product-filtering.enum';
import { ProductFilter } from '@shared/helpers/product-filter.helpers';
import {
  CART_SELECTORS,
  dyfSelectors,
  Order,
  orderHeaderSelectors,
  orderItemSelectors,
  orderSelectors,
} from '@usf/ngrx-order';
import { OrderItemState } from '@usf/ngrx-order';
import { OrderState } from '@usf/ngrx-order';
import { ItemTypes } from 'src/app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import {
  selectAppStateSelectedCustomer,
  selectedCustomer,
} from 'src/app/ngrx-customer/store';
import { ImageVariantEnum } from 'src/app/product-detail/models/image-variant.enum';
import { getFirstImageURL } from 'src/app/product-detail/utils/product-images.util';
import { FEATURES } from 'src/app/shared/constants/splitio-features';
import { UsfProductCardModeEnum } from 'src/app/shared/constants/usf-product-card-mode.enum';
import { Product } from 'src/app/shared/models/product.model';
import { productWithAlternativesSelector } from 'src/app/shared/selectors/product.selectors';
import { UsfProductCardViewModel } from '../../../shared/models/usf-product-card.model';
import { DyfProductInfo, DyfViewModel } from '../../models/dyf-view-model';
import {
  areDatesEqual,
  grabDateFromStringDateType,
} from '@shared/helpers/calendar.helpers';

export const selectOrdersForCurrentCustomerDepartmentAndDeliveryDate = createSelector(
  selectAllFeaturesEnabled([FEATURES.split_global_dyf_products]),
  orderSelectors.selectOrderContextState,
  orderHeaderSelectors.selectOrderHeader,
  selectAppStateSelectedCustomer,
  (dyfEnabled, orders: OrderState, orderHeader, selectedCustomer): Order[] => {
    if (!dyfEnabled) {
      return [];
    }
    return (orders.ids as Array<string | number>)
      .filter((id: string | number) =>
        id
          .toString()
          .startsWith(
            `${selectedCustomer?.customerNumber}:${selectedCustomer?.departmentNumber}`,
          ),
      )
      .filter(id => {
        const existingOrderDeliveryDate =
          orders.entities[id].orderHeader.confirmedDeliveryDate || null;
        const currentOrderDeliveryDate =
          orderHeader.confirmedDeliveryDate || null;
        return !existingOrderDeliveryDate || !currentOrderDeliveryDate
          ? false
          : areDatesEqual(
              grabDateFromStringDateType(existingOrderDeliveryDate),
              grabDateFromStringDateType(currentOrderDeliveryDate),
            );
      })
      .map(id => orders.entities[id]);
  },
);

export const selectDYFViewModel = (date?: Date) =>
  createSelector(
    selectAllFeaturesEnabled([FEATURES.split_global_dyf_products]),
    dyfSelectors.selectDYF,
    CART_SELECTORS.selectCart,
    orderItemSelectors.selectOrderItemState,
    selectOrdersForCurrentCustomerDepartmentAndDeliveryDate,
    productWithAlternativesSelector,
    selectOnline(),
    selectedCustomer,
    (
      dyfEnabled,
      dyfProducts,
      cart,
      orderItemState: OrderItemState,
      myOrders,
      products,
      online,
      selectedCustomer,
    ): DyfViewModel => {
      if (!dyfEnabled) {
        return {
          products: [],
          orderHeader: cart?.orderHeader,
          orderItemTallies: orderItemState?.tallies,
        };
      }
      // We want all product numbers from all orders except the order in cart
      // We want the carts product numbers

      // Remove the cart copy of the cart order from my-orders
      // Add the active cart order to the list of orders
      myOrders
        .filter(
          order => order.orderHeader?.orderId == cart?.orderHeader?.orderId,
        )
        .push({
          orderHeader: cart?.orderHeader,
          orderItems: Object.values(orderItemState.entities),
        });

      let orderedProductNumbers: number[] = Array.prototype.concat.apply(
        [],
        myOrders.map(order =>
          order?.orderItems
            .filter(
              orderItem =>
                !!orderItem.eachesOrdered?.currentValue ||
                !!orderItem.unitsOrdered?.currentValue,
            )
            ?.map(orderItem => orderItem.productNumber),
        ),
      );
      if (!date) {
        date = new Date();
        //Set Date to next date to so we include all products
        date.setDate(date.getDate() + 1);
      }

      orderedProductNumbers = orderedProductNumbers.concat(
        orderItemState?.ids
          .map(id => {
            return Number(id);
          })
          .filter(id => {
            return new Date(orderItemState?.entities[id]?.addDtm) < date;
          }),
      );
      const uniqueOrderedProductNumbers = new Set(orderedProductNumbers);

      let dyfProductInfos: DyfProductInfo[] = [];
      if (!!dyfProducts && !!dyfProducts.productsForDay) {
        const day = getDayText(cart?.orderHeader?.confirmedDeliveryDate);
        const productsForDay =
          dyfProducts?.productsForDay && dyfProducts.productsForDay[day];
        dyfProductInfos = productsForDay
          ?.filter(productRank => {
            const product = products.get(productRank.productNumber);
            const isOrdered = uniqueOrderedProductNumbers.has(
              productRank.productNumber,
            );
            if (product && !isOrdered) {
              // applyFilter will return true if the product should be included
              return ProductFilter.applyFilter(
                product,
                selectedCustomer,
                ProductFilteringEnum.DYF,
              );
            } else {
              return false;
            }
          })
          .filter(
            // remove newly added product from DYF list
            productRank =>
              !(orderItemState.ids as Number[]).includes(
                productRank.productNumber,
              ),
          )
          .map(productRank => {
            const product = products.get(productRank.productNumber);
            const productCardVM: UsfProductCardViewModel = createProductCardViewModel(
              product,
              orderItemState,
              online,
              selectedCustomer,
            );
            const dyfProduct: DyfProductInfo = {
              product,
              productCardVM,
              rank: productRank.rank,
              imageUrl: getFirstImageURL(
                product?.summary,
                ImageVariantEnum.Small,
              ),
            };
            return dyfProduct;
          })
          .sort((a, b) => a.rank - b.rank);
      }
      const dyfVM: DyfViewModel = {
        products: dyfProductInfos,
        orderHeader: cart?.orderHeader,
        orderItemTallies: orderItemState?.tallies,
      };
      return dyfVM;
    },
  );

export const selectDYFProductNumbers = createSelector(
  selectAllFeaturesEnabled([FEATURES.split_global_dyf_products]),
  dyfSelectors.selectDYF,
  orderHeaderSelectors.selectOrderHeader,
  (dyfEnabled, dyfProducts, orderHeader): number[] => {
    if (!dyfEnabled) {
      return [];
    }
    const day = getDayText(orderHeader?.confirmedDeliveryDate);
    const productsForDay =
      dyfProducts?.productsForDay && dyfProducts.productsForDay[day];
    if (productsForDay) {
      return productsForDay.map(productRank => productRank.productNumber);
    } else {
      return [];
    }
  },
);

//#region Helper Methods
export const getDayText = deliveryDate => {
  return ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][
    new Date(deliveryDate).getUTCDay()
  ];
};

// I will move this to a helper method after Carson is done with the refactoring of the product card
const createProductCardViewModel = (
  product: Product,
  inProgressOrderItems,
  online: boolean,
  selectedCustomer,
) => {
  const orderItem =
    inProgressOrderItems?.entities[
      inProgressOrderItems?.ids.find(id => id === product?.productNumber)
    ];
  if (!!product && !!orderItem) {
    product.eachesOrdered = orderItem?.eachesOrdered;
    product.casesOrdered = orderItem?.unitsOrdered;
  }
  const alternativeOrderItem =
    inProgressOrderItems?.entities[
      inProgressOrderItems?.ids.find(
        id => id === product?.alternative?.product?.productNumber,
      )
    ];
  if (!!product && !!alternativeOrderItem) {
    product.alternative.product.eachesOrdered =
      alternativeOrderItem?.eachesOrdered;
    product.alternative.product.casesOrdered =
      alternativeOrderItem?.unitsOrdered;
  }
  const productCardVM: UsfProductCardViewModel = {
    mode: UsfProductCardModeEnum.didYouForget,
    product,
    itemType: ItemTypes.product,
    isOnline: online,
    pricingDisabled: selectedCustomer?.ogPrintPriceInd === 'N',
  };
  return productCardVM;
};
//#endregion
