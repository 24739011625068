import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class I18nConfigService {
  currentLang: string;

  constructor(private translateService: TranslateService) {}

  languageInitialization(): void {
    this.translateService.addLangs(['en', 'es']);
  }

  /**
   * Find out default browser or device language
   *
   * @returns languageCode
   * Using ngx-translate api, we check PWA browser/device language.
   */
  getDefaultLanguage() {
    this.languageInitialization();
    if (
      localStorage.getItem('primaryLanguage') !== null ||
      localStorage.getItem('appLanguage') === null
    ) {
      this.currentLang = this.translateService.getBrowserLang();

      if (
        this.translateService.getLangs().find(lang => lang === this.currentLang)
      ) {
        this.translateService.use(this.currentLang);
      } else {
        this.translateService.use('en');
      }
    } else {
      this.currentLang = localStorage.getItem('appLanguage');
      this.translateService.setDefaultLang(this.currentLang);
    }
  }

  /**
   * setting up the application language
   *
   * @param setLang
   */
  setLanguage(langCode: string): void {
    this.translateService.use(langCode.match(/en|es/) ? langCode : 'en');
  }
}
