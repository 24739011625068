import {
  InventoryProductViewModel,
  InventoryViewModel,
} from '@inventory/models/inventory-view.model';
import { PlatformEnum } from '@panamax/app-state';
import {
  InventoriesSortOptions,
  InventoryWorksheetCountSortOptions,
  InventoryWorksheetEditSortOptions,
  SortDirections,
} from '@usf/ngrx-inventory';
import {
  InventoryHeaders,
  InventorySort,
} from '@usf/ngrx-inventory/lib/models/inventory/inventory.model';
import {
  SortableSectionHeader,
  SortableSectionViewModel,
} from '../../../shared/components/sortable-section/models/sortable-section.model';

export const productMatchesKeyword = (
  searchKey: string,
  productDescTxtl: string,
  productNumber: number,
  brandName: string,
): boolean => {
  if (!searchKey) return true;
  let searchDataArray: Array<string> = new Array<string>();
  if (
    productNumber !== undefined &&
    productNumber?.toString().toLowerCase().split(' ').length > 0
  ) {
    searchDataArray.push(...productNumber?.toString().toLowerCase().split(' '));
  }

  if (
    productDescTxtl !== undefined &&
    productDescTxtl !== '' &&
    productDescTxtl?.toLowerCase().split(' ').length > 0
  ) {
    searchDataArray.push(...productDescTxtl?.toLowerCase().split(' '));
  }

  if (
    brandName !== undefined &&
    brandName !== '' &&
    brandName?.toString().toLowerCase().split(' ').length > 0
  ) {
    searchDataArray.push(...brandName?.toLowerCase().split(' '));
  }

  if (searchDataArray.length === 0) {
    return false;
  }

  const matchDataArray: Array<string> = searchKey.toLowerCase().split(' ');
  return matchDataArray.every(
    matchData =>
      !!searchDataArray.find(searchData => {
        return !!~searchData?.indexOf(matchData);
      }),
  );
};

export const sortInventory = (
  inventorySort: InventorySort,
  inventorySection: string,
  values: InventoryViewModel[] | InventoryProductViewModel[],
) => {
  if (values) {
    const selectedHeader = inventorySort[inventorySection].selectedHeader;
    return setValuesForComparison(
      selectedHeader,
      values,
      inventorySort[inventorySection].headers[selectedHeader].sortDirection,
    );
  }
};

const setCollator = (numeric: boolean) => {
  return new Intl.Collator('en', {
    numeric,
    caseFirst: 'upper',
    sensitivity: 'base',
  });
};

export const setValuesForComparison = (
  headerName: string,
  values: any[],
  sortDirection: string,
) => {
  const collator = setCollator(
    headerName === InventoriesSortOptions.name ||
      headerName === 'productName' ||
      headerName === InventoryWorksheetEditSortOptions.group ||
      headerName === InventoryWorksheetEditSortOptions.unitDescription
      ? false
      : true,
  );

  return values.sort((a: any, b: any) => {
    const nameA =
      InventoriesSortOptions.name in a
        ? a[InventoriesSortOptions.name]
        : a['productName'];
    const nameB =
      InventoriesSortOptions.name in b
        ? b[InventoriesSortOptions.name]
        : b['productName'];

    let valueA;
    let valueB;
    if (
      headerName === InventoryWorksheetCountSortOptions.byCase ||
      headerName === InventoryWorksheetCountSortOptions.byUnit
    ) {
      valueA = a[headerName].currentValue;
      valueB = b[headerName].currentValue;
    } else {
      if (headerName === 'casePrice' && a['priceOverrideInd'] === 'Y') {
        valueA = a['overrideCasePrice'];
      } else if (a[headerName] === -1 && a['priceOverrideInd'] === 'N') {
        valueA = a['productUnitPrice'];
      } else {
        valueA = a[headerName];
      }
      if (headerName === 'casePrice' && b['priceOverrideInd'] === 'Y') {
        valueB = b['overrideCasePrice'];
      } else if (b[headerName] === -1 && b['priceOverrideInd'] === 'N') {
        valueB = b['productUnitPrice'];
      } else {
        valueB = b[headerName];
      }
    }
    return sort(collator, valueA, valueB, sortDirection, nameA, nameB);
  });
};

export const sort = (
  collator: Intl.Collator,
  valueA: any,
  valueB: any,
  sortDirection: string,
  nameA: string,
  nameB: string,
) => {
  let diff =
    sortDirection === SortDirections.asc
      ? collator.compare(valueA || 0, valueB || 0)
      : collator.compare(valueB || -Infinity, valueA || -Infinity);

  // if values are the same, use secondary sort (inventory/product name)
  if (diff === 0) {
    const nonNumericCollator = setCollator(false);
    return nonNumericCollator.compare(nameA, nameB);
  }

  return diff;
};

// INVENTORY LANDING PAGE/ALL INVENTORIES SORT FUNCTIONS
export const convertInventoriesSort = (
  platform: PlatformEnum,
  inventorySort: InventorySort,
) => {
  let convertedInventorySort: SortableSectionViewModel = {};
  if (!inventorySort) {
    return;
  }

  const inventorySections = Object.keys(inventorySort);

  inventorySections?.forEach(inventorySection => {
    const existingHeaders = inventorySort[inventorySection]?.headers;

    const updatedHeaders = convertInventoriesSortHeaders(
      platform,
      existingHeaders,
    );

    convertedInventorySort = {
      ...convertedInventorySort,
      [inventorySection]: {
        selectedHeader: inventorySort[inventorySection]?.selectedHeader,
        headers: updatedHeaders,
      },
    };
  });

  return convertedInventorySort;
};

export const convertInventoriesSortHeaders = (
  platform: PlatformEnum,
  existingHeaders: InventoryHeaders,
) => {
  return platform === PlatformEnum.desktop
    ? convertInventoriesSortHeadersDesktop(existingHeaders)
    : platform === PlatformEnum.tablet
    ? convertInventoriesSortHeadersTablet(existingHeaders)
    : convertInventoriesSortHeadersMobile(existingHeaders);
};

export const convertInventoriesSortHeadersDesktop = (
  existingHeaders: InventoryHeaders,
) => {
  const updatedHeaders: SortableSectionHeader[] = [];
  // key: headerName, value: displayText, sortDirection
  // size + offset values add up to 12 and should match ion-col values in inventory-section-desktop
  // except for last col, which may have a higher size if there are extra column elements (ellipsis, buttons, etc.)
  // but no more sort headers
  for (const [key, value] of Object.entries(existingHeaders)) {
    if (key === InventoriesSortOptions.name) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 3.05,
        offset: 0.25,
      });
    } else if (key === InventoriesSortOptions.lastUpdated) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 2,
        offset: 0.15,
      });
    } else if (key === InventoriesSortOptions.created) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 2,
        offset: 0.15,
      });
    } else if (key === InventoriesSortOptions.numProductsByQuantities) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1,
      });
    } else if (key === InventoriesSortOptions.totalValue) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 3.15,
        offset: 0.25,
      });
    }
  }
  return updatedHeaders;
};

export const convertInventoriesSortHeadersTablet = (
  existingHeaders: InventoryHeaders,
) => {
  const updatedHeaders: SortableSectionHeader[] = [];
  // key: headerName, value: displayText, sortDirection
  // size + offset values add up to 12 and should match ion-col values in inventory-section-tablet
  // except for last col, which may have a higher size if there are extra column elements (ellipsis, buttons, etc.)
  // but no more sort headers
  for (const [key, value] of Object.entries(existingHeaders)) {
    if (key === InventoriesSortOptions.name) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 3.65,
        offset: 0.35,
      });
    } else if (key === InventoriesSortOptions.lastUpdated) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
      });
    } else if (key === InventoriesSortOptions.created) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1.8,
        offset: 0.25,
      });
    } else if (key === InventoriesSortOptions.numProductsByQuantities) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1.35,
        offset: 0.25,
      });
    } else if (key === InventoriesSortOptions.totalValue) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 4.1,
        offset: 0.25,
      });
    }
  }
  return updatedHeaders;
};

export const convertInventoriesSortHeadersMobile = (
  existingHeaders: InventoryHeaders,
): SortableSectionHeader[] => {
  const updatedHeaders: SortableSectionHeader[] = [];
  // key: headerName, value: displayText, sortDirection
  // size + offset values add up to 12 minus the sectionTitleSize in inventory-section-mobile
  // but do NOT match size/offset values of last column b/c of custom spacing on cards
  for (const [key, value] of Object.entries(existingHeaders)) {
    if (key === InventoriesSortOptions.name) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
      });
    } else if (key === InventoriesSortOptions.lastUpdated) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
      });
    } else if (key === InventoriesSortOptions.created) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
      });
    } else if (key === InventoriesSortOptions.numProductsByQuantities) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 2.5,
        offset: 0.5,
      });
    } else if (key === InventoriesSortOptions.totalValue) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 3,
      });
    }
  }
  return updatedHeaders;
};

// INVENTORY WORKSHEET SORT FUNCTIONS
export const convertInventoryWorksheetSortHeaders = (
  platform: PlatformEnum,
  existingHeaders: InventoryHeaders,
  isCount: boolean,
) => {
  if (platform === PlatformEnum.desktop) {
    return isCount
      ? convertInventoryCountSortHeadersDesktop(existingHeaders)
      : convertInventoryEditSortHeadersDesktop(existingHeaders);
  } else if (platform === PlatformEnum.tablet && !isCount) {
    return convertInventoryEditSortHeadersTablet(existingHeaders);
  }
};

export const convertInventoryCountSortHeadersDesktop = (
  existingHeaders: InventoryHeaders,
) => {
  const updatedHeaders: SortableSectionHeader[] = [];
  // key: headerName, value: displayText, sortDirection
  // size + offset values add up to 12 and should match ion-col values in inventory-count-card
  // except for last col, which may have a higher size if there are extra column elements (ellipsis, buttons, etc.)
  // but no more sort headers
  for (const [key, value] of Object.entries(existingHeaders)) {
    if (key === InventoryWorksheetCountSortOptions.lineNum) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 0.55,
        offset: 0.2,
      });
    } else if (key === InventoryWorksheetCountSortOptions.productName) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 4.05,
        offset: 0.25,
      });
    } else if (key === InventoryWorksheetCountSortOptions.byCase) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 2.125,
        offset: 0.15,
      });
    } else if (key === InventoryWorksheetCountSortOptions.byUnit) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 2.125,
        offset: 0.15,
      });
    } else if (key === InventoryWorksheetCountSortOptions.total) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1,
        offset: 0.15,
      });
    } else if (key === InventoryWorksheetCountSortOptions.value) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1.25,
      });
    }
  }

  return updatedHeaders;
};

export const convertInventoryEditSortHeadersDesktop = (
  existingHeaders: InventoryHeaders,
) => {
  const updatedHeaders: SortableSectionHeader[] = [];
  // key: headerName, value: displayText, sortDirection
  // size + offset values add up to 12 and should match ion-col values in inventory-edit-card
  // except for last col, which may have a higher size if there are extra column elements (ellipsis, buttons, etc.)
  // but no more sort headers
  for (const [key, value] of Object.entries(existingHeaders)) {
    if (key === InventoryWorksheetEditSortOptions.lineNum) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 0.735,
        offset: 0.215,
      });
    } else if (key === InventoryWorksheetEditSortOptions.productName) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 3.3,
        offset: 0.1,
      });
    } else if (key === InventoryWorksheetEditSortOptions.group) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1.25,
        offset: 0.25,
      });
    } else if (key === InventoryWorksheetEditSortOptions.unitDescription) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1.4,
        offset: 0.75,
      });
    } else if (key === InventoryWorksheetEditSortOptions.unitByCase) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1.15,
        offset: 0.25,
      });
    } else if (key === InventoryWorksheetEditSortOptions.casePrice) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1,
        offset: 0.15,
      });
    } else if (key === InventoryWorksheetEditSortOptions.unitPrice) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1,
        offset: 0.45,
      });
    }
  }
  return updatedHeaders;
};

export const convertInventoryEditSortHeadersTablet = (
  existingHeaders: InventoryHeaders,
) => {
  const updatedHeaders: SortableSectionHeader[] = [];
  // key: headerName, value: displayText, sortDirection
  // size + offset values add up to 12 and should match ion-col values in inventory-edit-card
  // except for last col, which may have a higher size if there are extra column elements (ellipsis, buttons, etc.)
  // but no more sort headers
  for (const [key, value] of Object.entries(existingHeaders)) {
    if (key === InventoryWorksheetEditSortOptions.lineNum) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 0.9,
        offset: 0.35,
      });
    } else if (key === InventoryWorksheetEditSortOptions.productName) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 3.45,
        offset: 0.15,
      });
    } else if (key === InventoryWorksheetEditSortOptions.group) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
      });
    } else if (key === InventoryWorksheetEditSortOptions.unitDescription) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1.95,
        offset: 0.25,
      });
    } else if (key === InventoryWorksheetEditSortOptions.unitByCase) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1.55,
        offset: 0.15,
      });
    } else if (key === InventoryWorksheetEditSortOptions.casePrice) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1.5,
        offset: 0.25,
      });
    } else if (key === InventoryWorksheetEditSortOptions.unitPrice) {
      updatedHeaders.push({
        headerName: key,
        sortDirection: value.sortDirection,
        displayText: value.displayText,
        size: 1.25,
        offset: 0.25,
      });
    }
  }
  return updatedHeaders;
};
