import { Injectable } from '@angular/core';
import { PanAppState } from '@panamax/app-state';
import { FEATURES } from '@shared/constants/splitio-features';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductShippingPriceService {
  constructor(private panAppState: PanAppState) {}

  isProductShippingPriceEnabled$(): Observable<boolean> {
    return this.panAppState.feature$([
      FEATURES.split_customization_direct_separate_shipping,
    ]);
  }
}
