/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/*  Array Image Rule for priority order
    0 - Inner Pack
    1 - Prepared
    2 - Raw
    3 - Styled - Glamour
    4 - Open Case
    5 - Closed Case

    B - Inner Pack
    D - Prepared
    C - Raw
    F - Styled - Glamour
    M - Open Case
    A - Closed Case

    Index 0 class Code
    index 1 category Code
    Index 2 group Code
    Index 3 Thumbnail Asset Code
    Index 4-8 Back Images Asset Code
    */

export const packagingIndicatorCodeMap = {
  B: 0,
  D: 1,
  C: 2,
  1: 2,
  F: 3,
  M: 4,
  A: 5,
};

export const imageTypePriorityMap = {
  A: 7,
  B: 8,
  C: 1,
  D: 2,
  F: 4,
  Z: 9,
  E: 5,
  X: 10,
  L: 6,
  T: 3,
};

export const facingIndicatorPriorityMap = {
  '1': 1,
  '2': 2,
  '3': 5,
  '7': 4,
  '8': 3,
  '9': 6,
  'X': 8,
  '0': 7,
};

export const angleIndicatorPriorityMap = {
  C: 1,
  L: 2,
  R: 3,
  N: 4,
  X: 5,
};

export const imagePriorityList = [
  imageTypePriorityMap,
  facingIndicatorPriorityMap,
  angleIndicatorPriorityMap,
];

export const imageRulesLookupMap = {
  '15-130-1979': [2, 0, 1, 3, 4, 5],
  '15-130-1980': [2, 0, 1, 3, 4, 5],
  '15-130-2110': [2, 0, 1, 3, 4, 5],
  '15-130-2142': [2, 0, 1, 3, 4, 5],
  '15-130-4970': [2, 0, 1, 3, 4, 5],
  '15-130-4980': [2, 0, 1, 3, 4, 5],
  '15-130-6090': [2, 0, 1, 3, 4, 5],
  '15-130-6100': [2, 0, 1, 3, 4, 5],
  '15-130-6110': [2, 0, 1, 3, 4, 5],
  '15-130-6120': [2, 0, 1, 3, 4, 5],
  '15-130-6130': [2, 0, 1, 3, 4, 5],
  '15-130-6140': [2, 0, 1, 3, 4, 5],
  '15-130-7473': [2, 0, 1, 3, 4, 5],
  '15-130-7474': [2, 0, 1, 3, 4, 5],
  '15-130-7475': [2, 0, 1, 3, 4, 5],
  '15-130-7476': [2, 0, 1, 3, 4, 5],
  '15-130-7558': [2, 0, 1, 3, 4, 5],
  '15-130-7990': [2, 0, 1, 3, 4, 5],
  '15-130-7991': [2, 0, 1, 3, 4, 5],
  '15-130-8033': [2, 0, 1, 3, 4, 5],
  '15-130-8034': [2, 0, 1, 3, 4, 5],
  '15-130-8073': [2, 0, 1, 3, 4, 5],
  '15-130-8074': [2, 0, 1, 3, 4, 5],
  '15-130-8105': [2, 0, 1, 3, 4, 5],
  '15-130-8243': [2, 0, 1, 3, 4, 5],
  '15-130-8244': [2, 0, 1, 3, 4, 5],
  '15-130-8248': [2, 0, 1, 3, 4, 5],
  '15-130-8286': [2, 0, 1, 3, 4, 5],
  '15-130-8287': [2, 0, 1, 3, 4, 5],
  '15-130-8288': [2, 0, 1, 3, 4, 5],
  '15-131-1971': [2, 0, 1, 3, 4, 5],
  '15-131-1972': [2, 0, 1, 3, 4, 5],
  '15-131-1977': [2, 0, 1, 3, 4, 5],
  '15-131-1978': [2, 0, 1, 3, 4, 5],
  '15-131-7781': [2, 0, 1, 3, 4, 5],
  '15-131-7782': [2, 0, 1, 3, 4, 5],
  '15-131-7783': [2, 0, 1, 3, 4, 5],
  '15-131-7784': [2, 0, 1, 3, 4, 5],
  '15-131-7969': [2, 0, 1, 3, 4, 5],
  '15-131-7971': [2, 0, 1, 3, 4, 5],
  '15-131-7979': [2, 0, 1, 3, 4, 5],
  '15-131-7980': [2, 0, 1, 3, 4, 5],
  '15-132-2215': [2, 0, 1, 3, 4, 5],
  '15-132-2216': [2, 0, 1, 3, 4, 5],
  '15-132-2217': [2, 0, 1, 3, 4, 5],
  '15-132-2218': [2, 0, 1, 3, 4, 5],
  '15-132-2219': [2, 0, 1, 3, 4, 5],
  '15-132-2220': [2, 0, 1, 3, 4, 5],
  '15-132-2712': [2, 0, 1, 3, 4, 5],
  '15-132-7592': [2, 0, 1, 3, 4, 5],
  '15-132-7614': [2, 0, 1, 3, 4, 5],
  '15-132-7615': [2, 0, 1, 3, 4, 5],
  '15-132-7616': [2, 0, 1, 3, 4, 5],
  '15-132-7617': [2, 0, 1, 3, 4, 5],
  '15-132-7618': [2, 0, 1, 3, 4, 5],
  '15-132-7681': [2, 0, 1, 3, 4, 5],
  '15-132-8142': [2, 0, 1, 3, 4, 5],
  '15-132-8143': [2, 0, 1, 3, 4, 5],
  '15-132-8144': [2, 0, 1, 3, 4, 5],
  '15-132-8145': [2, 0, 1, 3, 4, 5],
  '15-132-8146': [2, 0, 1, 3, 4, 5],
  '15-132-8147': [2, 0, 1, 3, 4, 5],
  '15-254-1969': [2, 0, 1, 3, 4, 5],
  '15-254-1970': [2, 0, 1, 3, 4, 5],
  '15-254-1981': [2, 0, 1, 3, 4, 5],
  '15-254-1982': [2, 0, 1, 3, 4, 5],
  '15-254-1985': [2, 0, 1, 3, 4, 5],
  '15-254-1986': [2, 0, 1, 3, 4, 5],
  '15-254-2250': [2, 0, 1, 3, 4, 5],
  '15-254-2254': [2, 0, 1, 3, 4, 5],
  '15-254-2635': [2, 0, 1, 3, 4, 5],
  '15-254-3100': [2, 0, 1, 3, 4, 5],
  '15-254-6800': [2, 0, 1, 3, 4, 5],
  '15-254-6810': [2, 0, 1, 3, 4, 5],
  '15-254-7553': [2, 0, 1, 3, 4, 5],
  '15-254-7639': [2, 0, 1, 3, 4, 5],
  '15-254-7958': [2, 0, 1, 3, 4, 5],
  '15-254-7984': [2, 0, 1, 3, 4, 5],
  '15-254-7985': [2, 0, 1, 3, 4, 5],
  '15-254-8152': [2, 0, 1, 3, 4, 5],
  '15-254-8301': [2, 0, 1, 3, 4, 5],
  '15-364-7300': [2, 0, 1, 3, 4, 5],
  '15-364-7858': [2, 0, 1, 3, 4, 5],
  '15-364-7859': [2, 0, 1, 3, 4, 5],
  '15-364-7860': [2, 0, 1, 3, 4, 5],
  '15-364-7861': [2, 0, 1, 3, 4, 5],
  '15-364-7862': [2, 0, 1, 3, 4, 5],
  '15-364-7959': [2, 0, 1, 3, 4, 5],
  '15-364-7960': [2, 0, 1, 3, 4, 5],
  '15-364-7961': [2, 0, 1, 3, 4, 5],
  '15-364-7962': [2, 0, 1, 3, 4, 5],
  '15-364-7963': [2, 0, 1, 3, 4, 5],
  '15-364-7964': [2, 0, 1, 3, 4, 5],
  '15-365-7310': [2, 0, 1, 3, 4, 5],
  '15-365-7846': [2, 0, 1, 3, 4, 5],
  '15-365-7847': [2, 0, 1, 3, 4, 5],
  '15-365-7848': [2, 0, 1, 3, 4, 5],
  '15-365-7849': [2, 0, 1, 3, 4, 5],
  '15-365-7850': [2, 0, 1, 3, 4, 5],
  '15-366-2002': [2, 0, 1, 3, 4, 5],
  '15-366-2004': [2, 0, 1, 3, 4, 5],
  '15-366-2040': [2, 0, 1, 3, 4, 5],
  '15-366-2042': [2, 0, 1, 3, 4, 5],
  '15-366-2072': [2, 0, 1, 3, 4, 5],
  '15-366-7707': [2, 0, 1, 3, 4, 5],
  '15-366-7855': [2, 0, 1, 3, 4, 5],
  '15-366-7856': [2, 0, 1, 3, 4, 5],
  '15-366-7857': [2, 0, 1, 3, 4, 5],
  '15-366-7974': [2, 0, 1, 3, 4, 5],
  '15-366-7975': [2, 0, 1, 3, 4, 5],
  '15-366-7976': [2, 0, 1, 3, 4, 5],
  '15-366-7999': [2, 0, 1, 3, 4, 5],
  '15-366-8000': [2, 0, 1, 3, 4, 5],
  '15-366-8001': [2, 0, 1, 3, 4, 5],
  '15-366-8002': [2, 0, 1, 3, 4, 5],
  '15-366-8003': [2, 0, 1, 3, 4, 5],
  '15-366-8017': [2, 0, 1, 3, 4, 5],
  '15-367-2001': [2, 0, 1, 3, 4, 5],
  '15-367-2003': [2, 0, 1, 3, 4, 5],
  '15-367-2039': [2, 0, 1, 3, 4, 5],
  '15-367-2041': [2, 0, 1, 3, 4, 5],
  '15-367-2071': [2, 0, 1, 3, 4, 5],
  '15-367-7700': [2, 0, 1, 3, 4, 5],
  '15-367-7843': [2, 0, 1, 3, 4, 5],
  '15-367-7844': [2, 0, 1, 3, 4, 5],
  '15-367-7845': [2, 0, 1, 3, 4, 5],
  '15-368-1974': [2, 0, 1, 3, 4, 5],
  '15-368-1976': [2, 0, 1, 3, 4, 5],
  '15-368-4260': [2, 0, 1, 3, 4, 5],
  '15-368-7340': [2, 0, 1, 3, 4, 5],
  '15-368-7702': [2, 0, 1, 3, 4, 5],
  '15-368-7717': [2, 0, 1, 3, 4, 5],
  '15-368-7972': [2, 0, 1, 3, 4, 5],
  '15-368-7973': [2, 0, 1, 3, 4, 5],
  '15-368-7977': [2, 0, 1, 3, 4, 5],
  '15-368-7978': [2, 0, 1, 3, 4, 5],
  '15-368-7998': [2, 0, 1, 3, 4, 5],
  '15-368-8150': [2, 0, 1, 3, 4, 5],
  '15-369-1967': [2, 0, 1, 3, 4, 5],
  '15-369-1975': [2, 0, 1, 3, 4, 5],
  '15-369-7350': [2, 0, 1, 3, 4, 5],
  '15-369-7466': [2, 0, 1, 3, 4, 5],
  '15-369-7695': [2, 0, 1, 3, 4, 5],
  '15-369-7718': [2, 0, 1, 3, 4, 5],
  '15-370-2018': [2, 0, 1, 3, 4, 5],
  '15-370-2020': [2, 0, 1, 3, 4, 5],
  '15-370-7986': [2, 0, 1, 3, 4, 5],
  '15-370-7987': [2, 0, 1, 3, 4, 5],
  '15-371-2017': [2, 0, 1, 3, 4, 5],
  '15-371-2019': [2, 0, 1, 3, 4, 5],
  '15-372-2022': [2, 0, 1, 3, 4, 5],
  '15-372-2024': [2, 0, 1, 3, 4, 5],
  '15-372-2028': [2, 0, 1, 3, 4, 5],
  '15-372-2030': [2, 0, 1, 3, 4, 5],
  '15-372-7706': [2, 0, 1, 3, 4, 5],
  '15-372-7865': [2, 0, 1, 3, 4, 5],
  '15-372-7866': [2, 0, 1, 3, 4, 5],
  '15-372-7966': [2, 0, 1, 3, 4, 5],
  '15-372-7992': [2, 0, 1, 3, 4, 5],
  '15-372-7993': [2, 0, 1, 3, 4, 5],
  '15-372-7994': [2, 0, 1, 3, 4, 5],
  '15-372-7995': [2, 0, 1, 3, 4, 5],
  '15-372-7996': [2, 0, 1, 3, 4, 5],
  '15-372-7997': [2, 0, 1, 3, 4, 5],
  '15-373-2021': [2, 0, 1, 3, 4, 5],
  '15-373-2023': [2, 0, 1, 3, 4, 5],
  '15-373-2027': [2, 0, 1, 3, 4, 5],
  '15-373-2029': [2, 0, 1, 3, 4, 5],
  '15-373-7699': [2, 0, 1, 3, 4, 5],
  '15-373-7853': [2, 0, 1, 3, 4, 5],
  '15-373-7854': [2, 0, 1, 3, 4, 5],
  '15-374-1990': [2, 0, 1, 3, 4, 5],
  '15-374-7320': [2, 0, 1, 3, 4, 5],
  '15-374-7703': [2, 0, 1, 3, 4, 5],
  '15-374-7704': [2, 0, 1, 3, 4, 5],
  '15-374-7863': [2, 0, 1, 3, 4, 5],
  '15-374-7864': [2, 0, 1, 3, 4, 5],
  '15-374-7957': [2, 0, 1, 3, 4, 5],
  '15-374-7968': [2, 0, 1, 3, 4, 5],
  '15-374-7981': [2, 0, 1, 3, 4, 5],
  '15-374-7982': [2, 0, 1, 3, 4, 5],
  '15-374-7989': [2, 0, 1, 3, 4, 5],
  '15-375-1989': [2, 0, 1, 3, 4, 5],
  '15-375-7330': [2, 0, 1, 3, 4, 5],
  '15-375-7696': [2, 0, 1, 3, 4, 5],
  '15-375-7697': [2, 0, 1, 3, 4, 5],
  '15-375-7851': [2, 0, 1, 3, 4, 5],
  '15-375-7852': [2, 0, 1, 3, 4, 5],
  '15-376-7701': [2, 0, 1, 3, 4, 5],
  '15-377-7694': [2, 0, 1, 3, 4, 5],
  '15-378-7280': [2, 0, 1, 3, 4, 5],
  '15-378-8004': [2, 0, 1, 3, 4, 5],
  '15-379-7290': [2, 0, 1, 3, 4, 5],
  '15-380-2016': [2, 0, 1, 3, 4, 5],
  '15-380-2052': [2, 0, 1, 3, 4, 5],
  '15-380-7491': [2, 0, 1, 3, 4, 5],
  '15-380-7965': [2, 0, 1, 3, 4, 5],
  '15-380-8006': [2, 0, 1, 3, 4, 5],
  '15-380-8008': [2, 0, 1, 3, 4, 5],
  '15-381-2015': [2, 0, 1, 3, 4, 5],
  '15-381-2051': [2, 0, 1, 3, 4, 5],
  '15-381-7490': [2, 0, 1, 3, 4, 5],
  '15-382-2050': [2, 0, 1, 3, 4, 5],
  '15-382-2056': [2, 0, 1, 3, 4, 5],
  '15-382-2060': [2, 0, 1, 3, 4, 5],
  '15-382-2062': [2, 0, 1, 3, 4, 5],
  '15-382-2064': [2, 0, 1, 3, 4, 5],
  '15-382-2066': [2, 0, 1, 3, 4, 5],
  '15-382-8007': [2, 0, 1, 3, 4, 5],
  '15-382-8010': [2, 0, 1, 3, 4, 5],
  '15-382-8013': [2, 0, 1, 3, 4, 5],
  '15-382-8014': [2, 0, 1, 3, 4, 5],
  '15-382-8015': [2, 0, 1, 3, 4, 5],
  '15-382-8016': [2, 0, 1, 3, 4, 5],
  '15-383-2049': [2, 0, 1, 3, 4, 5],
  '15-383-2055': [2, 0, 1, 3, 4, 5],
  '15-383-2059': [2, 0, 1, 3, 4, 5],
  '15-383-2061': [2, 0, 1, 3, 4, 5],
  '15-383-2063': [2, 0, 1, 3, 4, 5],
  '15-383-2065': [2, 0, 1, 3, 4, 5],
  '15-384-7467': [2, 0, 1, 3, 4, 5],
  '15-384-7712': [2, 0, 1, 3, 4, 5],
  '15-384-7713': [2, 0, 1, 3, 4, 5],
  '15-384-7983': [2, 0, 1, 3, 4, 5],
  '15-384-8009': [2, 0, 1, 3, 4, 5],
  '15-385-7468': [2, 0, 1, 3, 4, 5],
  '15-385-7709': [2, 0, 1, 3, 4, 5],
  '15-385-7710': [2, 0, 1, 3, 4, 5],
  '15-386-2058': [2, 0, 1, 3, 4, 5],
  '15-386-8011': [2, 0, 1, 3, 4, 5],
  '15-387-2057': [2, 0, 1, 3, 4, 5],
  '15-388-7711': [2, 0, 1, 3, 4, 5],
  '15-388-8012': [2, 0, 1, 3, 4, 5],
  '15-389-7708': [2, 0, 1, 3, 4, 5],
  '15-390-2032': [2, 0, 1, 3, 4, 5],
  '15-390-2044': [2, 0, 1, 3, 4, 5],
  '15-390-2046': [2, 0, 1, 3, 4, 5],
  '15-390-7967': [2, 0, 1, 3, 4, 5],
  '15-390-7988': [2, 0, 1, 3, 4, 5],
  '15-390-8005': [2, 0, 1, 3, 4, 5],
  '15-391-2031': [2, 0, 1, 3, 4, 5],
  '15-391-2043': [2, 0, 1, 3, 4, 5],
  '15-391-2045': [2, 0, 1, 3, 4, 5],
  '16-133-1893': [0, 2, 3, 4, 5, 1],
  '16-133-2750': [0, 2, 3, 4, 5, 1],
  '16-134-1586': [0, 2, 3, 4, 5, 1],
  '16-134-1587': [0, 2, 3, 4, 5, 1],
  '16-134-7869': [0, 2, 3, 4, 5, 1],
  '16-135-1529': [0, 2, 3, 4, 5, 1],
  '16-135-1591': [0, 2, 3, 4, 5, 1],
  '16-135-1592': [0, 2, 3, 4, 5, 1],
  '16-135-1593': [0, 2, 3, 4, 5, 1],
  '16-135-1594': [0, 2, 3, 4, 5, 1],
  '16-135-1595': [0, 2, 3, 4, 5, 1],
  '16-135-1596': [0, 2, 3, 4, 5, 1],
  '16-135-1599': [0, 2, 3, 4, 5, 1],
  '16-135-1600': [0, 2, 3, 4, 5, 1],
  '16-135-1601': [0, 2, 3, 4, 5, 1],
  '16-135-3510': [0, 2, 3, 4, 5, 1],
  '16-135-3520': [0, 2, 3, 4, 5, 1],
  '16-135-7870': [0, 2, 3, 4, 5, 1],
  '16-136-1699': [0, 2, 3, 4, 5, 1],
  '16-136-1700': [0, 2, 3, 4, 5, 1],
  '16-136-1701': [0, 2, 3, 4, 5, 1],
  '16-136-1702': [0, 2, 3, 4, 5, 1],
  '16-136-1703': [0, 2, 3, 4, 5, 1],
  '16-136-1704': [0, 2, 3, 4, 5, 1],
  '16-136-1705': [0, 2, 3, 4, 5, 1],
  '16-136-1706': [0, 2, 3, 4, 5, 1],
  '16-136-1707': [0, 2, 3, 4, 5, 1],
  '16-136-1717': [0, 2, 3, 4, 5, 1],
  '16-136-1719': [0, 2, 3, 4, 5, 1],
  '16-136-3400': [0, 2, 3, 4, 5, 1],
  '16-136-7210': [0, 2, 3, 4, 5, 1],
  '16-136-7593': [0, 2, 3, 4, 5, 1],
  '16-136-7594': [0, 2, 3, 4, 5, 1],
  '16-136-7626': [0, 2, 3, 4, 5, 1],
  '16-136-7627': [0, 2, 3, 4, 5, 1],
  '16-136-7628': [0, 2, 3, 4, 5, 1],
  '16-136-7629': [0, 2, 3, 4, 5, 1],
  '16-136-7643': [0, 2, 3, 4, 5, 1],
  '16-136-7644': [0, 2, 3, 4, 5, 1],
  '16-136-7645': [0, 2, 3, 4, 5, 1],
  '16-136-7646': [0, 2, 3, 4, 5, 1],
  '16-136-7647': [0, 2, 3, 4, 5, 1],
  '16-136-7648': [0, 2, 3, 4, 5, 1],
  '16-136-7649': [0, 2, 3, 4, 5, 1],
  '16-136-7675': [0, 2, 3, 4, 5, 1],
  '16-136-7676': [0, 2, 3, 4, 5, 1],
  '16-136-7735': [0, 2, 3, 4, 5, 1],
  '16-136-7737': [0, 2, 3, 4, 5, 1],
  '16-136-7738': [0, 2, 3, 4, 5, 1],
  '16-136-7739': [0, 2, 3, 4, 5, 1],
  '16-136-7740': [0, 2, 3, 4, 5, 1],
  '16-136-7741': [0, 2, 3, 4, 5, 1],
  '16-136-7744': [0, 2, 3, 4, 5, 1],
  '16-136-7745': [0, 2, 3, 4, 5, 1],
  '16-136-7746': [0, 2, 3, 4, 5, 1],
  '16-136-8216': [0, 2, 3, 4, 5, 1],
  '16-136-8217': [0, 2, 3, 4, 5, 1],
  '16-136-8219': [0, 2, 3, 4, 5, 1],
  '16-136-8221': [0, 2, 3, 4, 5, 1],
  '16-136-8222': [0, 2, 3, 4, 5, 1],
  '16-137-1584': [0, 2, 3, 4, 5, 1],
  '16-137-1585': [0, 2, 3, 4, 5, 1],
  '16-137-1590': [0, 2, 3, 4, 5, 1],
  '16-137-1635': [0, 2, 3, 4, 5, 1],
  '16-137-1636': [0, 2, 3, 4, 5, 1],
  '16-137-1638': [0, 2, 3, 4, 5, 1],
  '16-137-1639': [0, 2, 3, 4, 5, 1],
  '16-137-1640': [0, 2, 3, 4, 5, 1],
  '16-137-1641': [0, 2, 3, 4, 5, 1],
  '16-137-1647': [0, 2, 3, 4, 5, 1],
  '16-137-1708': [0, 2, 3, 4, 5, 1],
  '16-137-1709': [0, 2, 3, 4, 5, 1],
  '16-137-1710': [0, 2, 3, 4, 5, 1],
  '16-137-1711': [0, 2, 3, 4, 5, 1],
  '16-137-1723': [0, 2, 3, 4, 5, 1],
  '16-137-1743': [0, 2, 3, 4, 5, 1],
  '16-137-1954': [0, 2, 3, 4, 5, 1],
  '16-137-1955': [0, 2, 3, 4, 5, 1],
  '16-137-1959': [0, 2, 3, 4, 5, 1],
  '16-137-2445': [0, 2, 3, 4, 5, 1],
  '16-137-3125': [0, 2, 3, 4, 5, 1],
  '16-137-3390': [0, 2, 3, 4, 5, 1],
  '16-137-4230': [0, 2, 3, 4, 5, 1],
  '16-137-4240': [0, 2, 3, 4, 5, 1],
  '16-137-4560': [0, 2, 3, 4, 5, 1],
  '16-137-4830': [0, 2, 3, 4, 5, 1],
  '16-137-5570': [0, 2, 3, 4, 5, 1],
  '16-137-5580': [0, 2, 3, 4, 5, 1],
  '16-137-5810': [0, 2, 3, 4, 5, 1],
  '16-137-5830': [0, 2, 3, 4, 5, 1],
  '16-137-6020': [0, 2, 3, 4, 5, 1],
  '16-137-7200': [0, 2, 3, 4, 5, 1],
  '16-137-7472': [0, 2, 3, 4, 5, 1],
  '16-137-7640': [0, 2, 3, 4, 5, 1],
  '16-137-7641': [0, 2, 3, 4, 5, 1],
  '16-137-7734': [0, 2, 3, 4, 5, 1],
  '16-137-7736': [0, 2, 3, 4, 5, 1],
  '16-137-7742': [0, 2, 3, 4, 5, 1],
  '16-137-7743': [0, 2, 3, 4, 5, 1],
  '16-137-7751': [0, 2, 3, 4, 5, 1],
  '16-137-7872': [0, 2, 3, 4, 5, 1],
  '16-137-8189': [0, 2, 3, 4, 5, 1],
  '16-137-8192': [0, 2, 3, 4, 5, 1],
  '16-137-8193': [0, 2, 3, 4, 5, 1],
  '16-137-8230': [0, 2, 3, 4, 5, 1],
  '16-137-8302': [0, 2, 3, 4, 5, 1],
  '16-138-1921': [0, 2, 3, 4, 5, 1],
  '16-138-1922': [0, 2, 3, 4, 5, 1],
  '16-138-1923': [0, 2, 3, 4, 5, 1],
  '16-138-1924': [0, 2, 3, 4, 5, 1],
  '16-138-1928': [0, 2, 3, 4, 5, 1],
  '16-138-1929': [0, 2, 3, 4, 5, 1],
  '16-138-1932': [0, 2, 3, 4, 5, 1],
  '16-138-6080': [0, 2, 3, 4, 5, 1],
  '16-138-7677': [0, 2, 3, 4, 5, 1],
  '16-138-7678': [0, 2, 3, 4, 5, 1],
  '16-138-7871': [0, 2, 3, 4, 5, 1],
  '16-297-4990': [0, 2, 3, 4, 5, 1],
  '16-297-8149': [0, 2, 3, 4, 5, 1],
  '17-139-1472': [0, 2, 3, 4, 1, 5],
  '17-139-1475': [0, 2, 3, 4, 1, 5],
  '17-139-1476': [0, 2, 3, 4, 1, 5],
  '17-139-1581': [0, 2, 3, 4, 1, 5],
  '17-139-1619': [0, 2, 3, 4, 1, 5],
  '17-139-1663': [0, 2, 3, 4, 1, 5],
  '17-139-1667': [0, 2, 3, 4, 1, 5],
  '17-139-1724': [0, 2, 3, 4, 1, 5],
  '17-139-1736': [0, 2, 3, 4, 1, 5],
  '17-139-1762': [0, 2, 3, 4, 1, 5],
  '17-139-1774': [0, 2, 3, 4, 1, 5],
  '17-139-1779': [0, 2, 3, 4, 1, 5],
  '17-139-1797': [0, 2, 3, 4, 1, 5],
  '17-139-1799': [0, 2, 3, 4, 1, 5],
  '17-139-1830': [0, 2, 3, 4, 1, 5],
  '17-140-1934': [0, 2, 3, 4, 1, 5],
  '17-140-1935': [0, 2, 3, 4, 1, 5],
  '17-140-1936': [0, 2, 3, 4, 1, 5],
  '17-140-1937': [0, 2, 3, 4, 1, 5],
  '17-140-5850': [0, 2, 3, 4, 1, 5],
  '17-140-7750': [0, 2, 3, 4, 1, 5],
  '17-142-1473': [2, 0, 3, 4, 1, 2],
  '17-142-1477': [2, 0, 3, 4, 1, 2],
  '17-142-1490': [2, 0, 3, 4, 1, 2],
  '17-142-1494': [2, 0, 3, 4, 1, 2],
  '17-142-1498': [2, 0, 3, 4, 1, 2],
  '17-142-1502': [2, 0, 3, 4, 1, 2],
  '17-142-1503': [2, 0, 3, 4, 1, 2],
  '17-142-1505': [2, 0, 3, 4, 1, 2],
  '17-142-1589': [2, 0, 3, 4, 1, 2],
  '17-142-1618': [2, 0, 3, 4, 1, 2],
  '17-142-1668': [2, 0, 3, 4, 1, 2],
  '17-142-1718': [2, 0, 3, 4, 1, 2],
  '17-142-1781': [2, 0, 3, 4, 1, 2],
  '17-142-1783': [2, 0, 3, 4, 1, 2],
  '17-142-1831': [2, 0, 3, 4, 1, 2],
  '17-142-1837': [2, 0, 3, 4, 1, 2],
  '17-142-1938': [2, 0, 3, 4, 1, 2],
  '17-142-1948': [2, 0, 3, 4, 1, 2],
  '17-142-7260': [2, 0, 3, 4, 1, 2],
  '17-142-7270': [2, 0, 3, 4, 1, 2],
  '17-142-7680': [2, 0, 3, 4, 1, 2],
  '17-143-1478': [0, 2, 3, 4, 1, 5],
  '17-143-1480': [0, 2, 3, 4, 1, 5],
  '17-143-1486': [0, 2, 3, 4, 1, 5],
  '17-143-1488': [0, 2, 3, 4, 1, 5],
  '17-143-1489': [0, 2, 3, 4, 1, 5],
  '17-143-1491': [0, 2, 3, 4, 1, 5],
  '17-143-1492': [0, 2, 3, 4, 1, 5],
  '17-143-1493': [0, 2, 3, 4, 1, 5],
  '17-143-1495': [0, 2, 3, 4, 1, 5],
  '17-143-1497': [0, 2, 3, 4, 1, 5],
  '17-143-1499': [0, 2, 3, 4, 1, 5],
  '17-143-1501': [0, 2, 3, 4, 1, 5],
  '17-143-1504': [0, 2, 3, 4, 1, 5],
  '17-143-1506': [0, 2, 3, 4, 1, 5],
  '17-143-1507': [0, 2, 3, 4, 1, 5],
  '17-143-1508': [0, 2, 3, 4, 1, 5],
  '17-143-1509': [0, 2, 3, 4, 1, 5],
  '17-143-1511': [0, 2, 3, 4, 1, 5],
  '17-143-1530': [0, 2, 3, 4, 1, 5],
  '17-143-1611': [0, 2, 3, 4, 1, 5],
  '17-143-1680': [0, 2, 3, 4, 1, 5],
  '17-143-1740': [0, 2, 3, 4, 1, 5],
  '17-143-1763': [0, 2, 3, 4, 1, 5],
  '17-143-1780': [0, 2, 3, 4, 1, 5],
  '17-143-1782': [0, 2, 3, 4, 1, 5],
  '17-143-1807': [0, 2, 3, 4, 1, 5],
  '17-143-1819': [0, 2, 3, 4, 1, 5],
  '17-143-1824': [0, 2, 3, 4, 1, 5],
  '17-143-1826': [0, 2, 3, 4, 1, 5],
  '17-143-1884': [0, 2, 3, 4, 1, 5],
  '17-143-1911': [0, 2, 3, 4, 1, 5],
  '17-143-1945': [0, 2, 3, 4, 1, 5],
  '17-143-1947': [0, 2, 3, 4, 1, 5],
  '17-143-1953': [0, 2, 3, 4, 1, 5],
  '17-143-2244': [0, 2, 3, 4, 1, 5],
  '17-143-2583': [0, 2, 3, 4, 1, 5],
  '17-143-7807': [0, 2, 3, 4, 1, 5],
  '17-143-7808': [0, 2, 3, 4, 1, 5],
  '18-289-1560': [0, 2, 3, 4, 5, 1],
  '18-289-1561': [0, 2, 3, 4, 5, 1],
  '18-289-1570': [0, 2, 3, 4, 5, 1],
  '18-289-5740': [0, 2, 3, 4, 5, 1],
  '18-289-5750': [0, 2, 3, 4, 5, 1],
  '18-289-6520': [0, 2, 3, 4, 5, 1],
  '18-289-7685': [0, 2, 3, 4, 5, 1],
  '18-289-8203': [0, 2, 3, 4, 5, 1],
  '18-289-8208': [0, 2, 3, 4, 5, 1],
  '18-290-1575': [0, 2, 3, 4, 5, 1],
  '18-290-6530': [0, 2, 3, 4, 5, 1],
  '18-290-6540': [0, 2, 3, 4, 5, 1],
  '18-292-1562': [0, 2, 3, 4, 5, 1],
  '18-292-1563': [0, 2, 3, 4, 5, 1],
  '18-292-1564': [0, 2, 3, 4, 5, 1],
  '18-292-1565': [0, 2, 3, 4, 5, 1],
  '18-292-8205': [0, 2, 3, 4, 5, 1],
  '18-293-1538': [0, 2, 3, 4, 5, 1],
  '18-293-1540': [0, 2, 3, 4, 5, 1],
  '18-293-1552': [0, 2, 3, 4, 5, 1],
  '18-293-1569': [0, 2, 3, 4, 5, 1],
  '18-293-1572': [0, 2, 3, 4, 5, 1],
  '18-293-1573': [0, 2, 3, 4, 5, 1],
  '18-293-5780': [0, 2, 3, 4, 5, 1],
  '18-294-1576': [0, 2, 3, 4, 5, 1],
  '18-294-1577': [0, 2, 3, 4, 5, 1],
  '18-294-1578': [0, 2, 3, 4, 5, 1],
  '18-294-1579': [0, 2, 3, 4, 5, 1],
  '18-295-1555': [0, 2, 3, 4, 5, 1],
  '18-295-1556': [0, 2, 3, 4, 5, 1],
  '18-295-1557': [0, 2, 3, 4, 5, 1],
  '18-295-1574': [0, 2, 3, 4, 5, 1],
  '18-295-4590': [0, 2, 3, 4, 5, 1],
  '18-295-5760': [0, 2, 3, 4, 5, 1],
  '18-295-6550': [0, 2, 3, 4, 5, 1],
  '18-295-6560': [0, 2, 3, 4, 5, 1],
  '18-295-8194': [0, 2, 3, 4, 5, 1],
  '18-295-8197': [0, 2, 3, 4, 5, 1],
  '18-295-8200': [0, 2, 3, 4, 5, 1],
  '18-296-1559': [0, 2, 3, 4, 5, 1],
  '18-336-1541': [0, 2, 3, 4, 5, 1],
  '18-336-1544': [0, 2, 3, 4, 5, 1],
  '18-336-1545': [0, 2, 3, 4, 5, 1],
  '18-336-8190': [0, 2, 3, 4, 5, 1],
  '18-336-8191': [0, 2, 3, 4, 5, 1],
  '18-337-1535': [0, 2, 3, 4, 5, 1],
  '18-337-1539': [0, 2, 3, 4, 5, 1],
  '18-337-1546': [0, 2, 3, 4, 5, 1],
  '18-337-1553': [0, 2, 3, 4, 5, 1],
  '18-337-1554': [0, 2, 3, 4, 5, 1],
  '18-337-1558': [0, 2, 3, 4, 5, 1],
  '18-337-1566': [0, 2, 3, 4, 5, 1],
  '18-337-1567': [0, 2, 3, 4, 5, 1],
  '18-337-1568': [0, 2, 3, 4, 5, 1],
  '18-337-4580': [0, 2, 3, 4, 5, 1],
  '18-337-7460': [0, 2, 3, 4, 5, 1],
  '18-337-7461': [0, 2, 3, 4, 5, 1],
  '18-337-7462': [0, 2, 3, 4, 5, 1],
  '18-337-7463': [0, 2, 3, 4, 5, 1],
  '18-337-7464': [0, 2, 3, 4, 5, 1],
  '18-337-7465': [0, 2, 3, 4, 5, 1],
  '18-337-7469': [0, 2, 3, 4, 5, 1],
  '18-337-7470': [0, 2, 3, 4, 5, 1],
  '19-147-2326': [0, 4, 3, 5, 2, 1],
  '19-147-2327': [0, 4, 3, 5, 2, 1],
  '19-147-2330': [0, 4, 3, 5, 2, 1],
  '19-147-2355': [0, 4, 3, 5, 2, 1],
  '19-147-2571': [0, 4, 3, 5, 2, 1],
  '19-147-6980': [0, 4, 3, 5, 2, 1],
  '19-147-7941': [0, 4, 3, 5, 2, 1],
  '19-147-7942': [0, 4, 3, 5, 2, 1],
  '19-148-2357': [0, 4, 3, 5, 2, 1],
  '19-148-7934': [0, 4, 3, 5, 2, 1],
  '19-148-7935': [0, 4, 3, 5, 2, 1],
  '19-148-7936': [0, 4, 3, 5, 2, 1],
  '19-148-7937': [0, 4, 3, 5, 2, 1],
  '19-274-2365': [0, 4, 3, 5, 2, 1],
  '19-274-2639': [0, 4, 3, 5, 2, 1],
  '19-274-7938': [0, 4, 3, 5, 2, 1],
  '19-274-7939': [0, 4, 3, 5, 2, 1],
  '19-392-2352': [0, 4, 3, 5, 2, 1],
  '19-392-7572': [0, 4, 3, 5, 2, 1],
  '19-393-2325': [0, 4, 3, 5, 2, 1],
  '19-393-2573': [0, 4, 3, 5, 2, 1],
  '19-393-7932': [0, 4, 3, 5, 2, 1],
  '19-393-7933': [0, 4, 3, 5, 2, 1],
  '19-394-2328': [0, 4, 3, 5, 2, 1],
  '19-394-2351': [0, 4, 3, 5, 2, 1],
  '19-395-2358': [0, 4, 3, 5, 2, 1],
  '19-395-2606': [0, 4, 3, 5, 2, 1],
  '19-395-7943': [0, 4, 3, 5, 2, 1],
  '19-395-7944': [0, 4, 3, 5, 2, 1],
  '19-396-2359': [0, 4, 3, 5, 2, 1],
  '19-396-2616': [0, 4, 3, 5, 2, 1],
  '19-397-2324': [0, 4, 3, 5, 2, 1],
  '19-397-2356': [0, 4, 3, 5, 2, 1],
  '19-397-2386': [0, 4, 3, 5, 2, 1],
  '19-397-2444': [0, 4, 3, 5, 2, 1],
  '19-397-2575': [0, 4, 3, 5, 2, 1],
  '19-397-7940': [0, 4, 3, 5, 2, 1],
  '19-397-7945': [0, 4, 3, 5, 2, 1],
  '20-150-1808': [1, 0, 2, 3, 4, 2],
  '20-150-1813': [1, 0, 2, 3, 4, 2],
  '20-150-1817': [1, 0, 2, 3, 4, 2],
  '20-150-1820': [1, 0, 2, 3, 4, 2],
  '20-151-2268': [1, 0, 2, 3, 4, 2],
  '20-151-2276': [1, 0, 2, 3, 4, 2],
  '20-151-2296': [1, 0, 2, 3, 4, 2],
  '20-151-2311': [1, 0, 2, 3, 4, 2],
  '20-151-2315': [1, 0, 2, 3, 4, 2],
  '20-151-2318': [1, 0, 2, 3, 4, 2],
  '20-151-2321': [1, 0, 2, 3, 4, 2],
  '20-151-2372': [1, 0, 2, 3, 4, 2],
  '20-151-2374': [1, 0, 2, 3, 4, 2],
  '20-151-2376': [1, 0, 2, 3, 4, 2],
  '20-151-2379': [1, 0, 2, 3, 4, 2],
  '20-151-2393': [1, 0, 2, 3, 4, 2],
  '20-151-2451': [1, 0, 2, 3, 4, 2],
  '20-151-2474': [1, 0, 2, 3, 4, 2],
  '20-151-2482': [1, 0, 2, 3, 4, 2],
  '20-151-2485': [1, 0, 2, 3, 4, 2],
  '20-151-2487': [1, 0, 2, 3, 4, 2],
  '20-151-2536': [1, 0, 2, 3, 4, 2],
  '20-151-2538': [1, 0, 2, 3, 4, 2],
  '20-151-2563': [1, 0, 2, 3, 4, 2],
  '20-151-2579': [1, 0, 2, 3, 4, 2],
  '20-151-2582': [1, 0, 2, 3, 4, 2],
  '20-151-2596': [1, 0, 2, 3, 4, 2],
  '20-151-2603': [1, 0, 2, 3, 4, 2],
  '20-151-2610': [1, 0, 2, 3, 4, 2],
  '20-151-2615': [1, 0, 2, 3, 4, 2],
  '20-151-2642': [1, 0, 2, 3, 4, 2],
  '20-151-2654': [1, 0, 2, 3, 4, 2],
  '20-151-2659': [1, 0, 2, 3, 4, 2],
  '20-151-2660': [1, 0, 2, 3, 4, 2],
  '20-151-2661': [1, 0, 2, 3, 4, 2],
  '20-151-2662': [1, 0, 2, 3, 4, 2],
  '20-151-2673': [1, 0, 2, 3, 4, 2],
  '20-151-2674': [1, 0, 2, 3, 4, 2],
  '20-151-2676': [1, 0, 2, 3, 4, 2],
  '20-151-2689': [1, 0, 2, 3, 4, 2],
  '20-151-2708': [1, 0, 2, 3, 4, 2],
  '20-151-5630': [1, 0, 2, 3, 4, 2],
  '20-151-5640': [1, 0, 2, 3, 4, 2],
  '20-151-6070': [1, 0, 2, 3, 4, 2],
  '20-151-6460': [1, 0, 2, 3, 4, 2],
  '20-151-7230': [1, 0, 2, 3, 4, 2],
  '20-151-7240': [1, 0, 2, 3, 4, 2],
  '20-151-7250': [1, 0, 2, 3, 4, 2],
  '20-151-7453': [1, 0, 2, 3, 4, 2],
  '20-151-7515': [1, 0, 2, 3, 4, 2],
  '20-151-7516': [1, 0, 2, 3, 4, 2],
  '20-151-7635': [1, 0, 2, 3, 4, 2],
  '20-151-7724': [1, 0, 2, 3, 4, 2],
  '20-151-7725': [1, 0, 2, 3, 4, 2],
  '20-151-8188': [1, 0, 2, 3, 4, 2],
  '20-151-8204': [1, 0, 2, 3, 4, 2],
  '20-151-8225': [1, 0, 2, 3, 4, 2],
  '20-153-1804': [1, 0, 2, 3, 4, 2],
  '20-153-1805': [1, 0, 2, 3, 4, 2],
  '20-153-1809': [1, 0, 2, 3, 4, 2],
  '20-153-1811': [1, 0, 2, 3, 4, 2],
  '20-153-1812': [1, 0, 2, 3, 4, 2],
  '20-153-1816': [1, 0, 2, 3, 4, 2],
  '20-153-1821': [1, 0, 2, 3, 4, 2],
  '20-153-1823': [1, 0, 2, 3, 4, 2],
  '20-153-1825': [1, 0, 2, 3, 4, 2],
  '20-153-1827': [1, 0, 2, 3, 4, 2],
  '20-153-2585': [1, 0, 2, 3, 4, 2],
  '20-153-4340': [1, 0, 2, 3, 4, 2],
  '20-153-4350': [1, 0, 2, 3, 4, 2],
  '20-153-7662': [1, 0, 2, 3, 4, 2],
  '20-153-7785': [1, 0, 2, 3, 4, 2],
  '20-153-8209': [1, 0, 2, 3, 4, 2],
  '20-153-8212': [1, 0, 2, 3, 4, 2],
  '20-155-1899': [1, 0, 2, 3, 4, 2],
  '20-155-1900': [1, 0, 2, 3, 4, 2],
  '20-155-2317': [1, 0, 2, 3, 4, 2],
  '20-155-7638': [1, 0, 2, 3, 4, 2],
  '20-155-8220': [1, 0, 2, 3, 4, 2],
  '20-155-8229': [1, 0, 2, 3, 4, 2],
  '20-334-1368': [1, 0, 2, 3, 4, 2],
  '20-334-1369': [1, 0, 2, 3, 4, 2],
  '20-334-5590': [1, 0, 2, 3, 4, 2],
  '20-334-8211': [1, 0, 2, 3, 4, 2],
  '20-335-1370': [1, 0, 2, 3, 4, 2],
  '20-335-1371': [1, 0, 2, 3, 4, 2],
  '20-335-1372': [1, 0, 2, 3, 4, 2],
  '20-335-3130': [1, 0, 2, 3, 4, 2],
  '20-335-4710': [1, 0, 2, 3, 4, 2],
  '20-335-7587': [1, 0, 2, 3, 4, 2],
  '20-335-7588': [1, 0, 2, 3, 4, 2],
  '20-335-7589': [1, 0, 2, 3, 4, 2],
  '20-335-7590': [1, 0, 2, 3, 4, 2],
  '20-335-7591': [1, 0, 2, 3, 4, 2],
  '21-156-1521': [0, 2, 3, 4, 5, 1],
  '21-156-3140': [0, 2, 3, 4, 5, 1],
  '21-156-5690': [0, 2, 3, 4, 5, 1],
  '21-156-5720': [0, 2, 3, 4, 5, 1],
  '21-156-7815': [0, 2, 3, 4, 5, 1],
  '21-156-7816': [0, 2, 3, 4, 5, 1],
  '21-156-8187': [0, 2, 3, 4, 5, 1],
  '21-157-1549': [0, 2, 3, 4, 5, 1],
  '21-157-1550': [0, 2, 3, 4, 5, 1],
  '21-157-1614': [0, 2, 3, 4, 5, 1],
  '21-157-1634': [0, 2, 3, 4, 5, 1],
  '21-157-1903': [0, 2, 3, 4, 5, 1],
  '21-157-1904': [0, 2, 3, 4, 5, 1],
  '21-157-1963': [0, 2, 3, 4, 5, 1],
  '21-157-3150': [0, 2, 3, 4, 5, 1],
  '21-157-3630': [0, 2, 3, 4, 5, 1],
  '21-157-8232': [0, 2, 3, 4, 5, 1],
  '21-158-1956': [0, 2, 3, 4, 5, 1],
  '21-158-1957': [0, 2, 3, 4, 5, 1],
  '21-158-5790': [0, 2, 3, 4, 5, 1],
  '21-158-5800': [0, 2, 3, 4, 5, 1],
  '21-159-1649': [0, 2, 3, 4, 5, 1],
  '21-159-1650': [0, 2, 3, 4, 5, 1],
  '21-159-1651': [0, 2, 3, 4, 5, 1],
  '21-159-1652': [0, 2, 3, 4, 5, 1],
  '21-159-1653': [0, 2, 3, 4, 5, 1],
  '21-159-5730': [0, 2, 3, 4, 5, 1],
  '21-159-7671': [0, 2, 3, 4, 5, 1],
  '21-159-8195': [0, 2, 3, 4, 5, 1],
  '21-159-8198': [0, 2, 3, 4, 5, 1],
  '21-160-1648': [0, 2, 3, 4, 5, 1],
  '21-160-5080': [0, 2, 3, 4, 5, 1],
  '21-160-5100': [0, 2, 3, 4, 5, 1],
  '21-160-5890': [0, 2, 3, 4, 5, 1],
  '21-160-7654': [0, 2, 3, 4, 5, 1],
  '21-161-1689': [0, 2, 3, 4, 5, 1],
  '21-161-1690': [0, 2, 3, 4, 5, 1],
  '21-161-1691': [0, 2, 3, 4, 5, 1],
  '21-161-1887': [0, 2, 3, 4, 5, 1],
  '21-161-1960': [0, 2, 3, 4, 5, 1],
  '21-161-1961': [0, 2, 3, 4, 5, 1],
  '21-161-1962': [0, 2, 3, 4, 5, 1],
  '21-161-3740': [0, 2, 3, 4, 5, 1],
  '21-161-5340': [0, 2, 3, 4, 5, 1],
  '21-161-5870': [0, 2, 3, 4, 5, 1],
  '21-161-5880': [0, 2, 3, 4, 5, 1],
  '21-161-5940': [0, 2, 3, 4, 5, 1],
  '21-161-5960': [0, 2, 3, 4, 5, 1],
  '21-161-5970': [0, 2, 3, 4, 5, 1],
  '21-161-7621': [0, 2, 3, 4, 5, 1],
  '21-161-8214': [0, 2, 3, 4, 5, 1],
  '21-161-8231': [0, 2, 3, 4, 5, 1],
  '21-162-1620': [0, 2, 3, 4, 5, 1],
  '21-162-1621': [0, 2, 3, 4, 5, 1],
  '21-162-1622': [0, 2, 3, 4, 5, 1],
  '21-162-1623': [0, 2, 3, 4, 5, 1],
  '21-162-1729': [0, 2, 3, 4, 5, 1],
  '21-162-1730': [0, 2, 3, 4, 5, 1],
  '21-162-1731': [0, 2, 3, 4, 5, 1],
  '21-162-1733': [0, 2, 3, 4, 5, 1],
  '21-162-1734': [0, 2, 3, 4, 5, 1],
  '21-162-1735': [0, 2, 3, 4, 5, 1],
  '21-162-3490': [0, 2, 3, 4, 5, 1],
  '21-162-3500': [0, 2, 3, 4, 5, 1],
  '21-162-5060': [0, 2, 3, 4, 5, 1],
  '21-162-5860': [0, 2, 3, 4, 5, 1],
  '21-162-7559': [0, 2, 3, 4, 5, 1],
  '21-162-7560': [0, 2, 3, 4, 5, 1],
  '21-162-7625': [0, 2, 3, 4, 5, 1],
  '21-162-7636': [0, 2, 3, 4, 5, 1],
  '21-162-7779': [0, 2, 3, 4, 5, 1],
  '22-163-1373': [0, 2, 3, 4, 1, 5],
  '22-163-1375': [0, 2, 3, 4, 1, 5],
  '22-163-1376': [0, 2, 3, 4, 1, 5],
  '22-163-1377': [0, 2, 3, 4, 1, 5],
  '22-163-1378': [0, 2, 3, 4, 1, 5],
  '22-163-1380': [0, 2, 3, 4, 1, 5],
  '22-163-1381': [0, 2, 3, 4, 1, 5],
  '22-163-1383': [0, 2, 3, 4, 1, 5],
  '22-163-1384': [0, 2, 3, 4, 1, 5],
  '22-163-1385': [0, 2, 3, 4, 1, 5],
  '22-163-1386': [0, 2, 3, 4, 1, 5],
  '22-163-1387': [0, 2, 3, 4, 1, 5],
  '22-163-1388': [0, 2, 3, 4, 1, 5],
  '22-163-1391': [0, 2, 3, 4, 1, 5],
  '22-163-1392': [0, 2, 3, 4, 1, 5],
  '22-163-1393': [0, 2, 3, 4, 1, 5],
  '22-163-1394': [0, 2, 3, 4, 1, 5],
  '22-163-1395': [0, 2, 3, 4, 1, 5],
  '22-163-1396': [0, 2, 3, 4, 1, 5],
  '22-163-1403': [0, 2, 3, 4, 1, 5],
  '22-163-1404': [0, 2, 3, 4, 1, 5],
  '22-163-1405': [0, 2, 3, 4, 1, 5],
  '22-163-1406': [0, 2, 3, 4, 1, 5],
  '22-163-1656': [0, 2, 3, 4, 1, 5],
  '22-163-1659': [0, 2, 3, 4, 1, 5],
  '22-163-1726': [0, 2, 3, 4, 1, 5],
  '22-163-1732': [0, 2, 3, 4, 1, 5],
  '22-163-2760': [0, 2, 3, 4, 1, 5],
  '22-163-2761': [0, 2, 3, 4, 1, 5],
  '22-163-2762': [0, 2, 3, 4, 1, 5],
  '22-163-3370': [0, 2, 3, 4, 1, 5],
  '22-163-7471': [0, 2, 3, 4, 1, 5],
  '22-164-1401': [2, 0, 3, 4, 1, 2],
  '22-164-1642': [0, 2, 3, 4, 1, 5],
  '22-164-1643': [0, 2, 3, 4, 1, 5],
  '22-164-1687': [0, 2, 3, 4, 1, 5],
  '22-164-1688': [0, 2, 3, 4, 1, 5],
  '22-165-1528': [0, 2, 3, 4, 1, 5],
  '22-165-1536': [0, 2, 3, 4, 1, 5],
  '22-165-1537': [0, 2, 3, 4, 1, 5],
  '22-165-1609': [0, 2, 3, 4, 1, 5],
  '22-165-1610': [0, 2, 3, 4, 1, 5],
  '22-165-1760': [0, 2, 3, 4, 1, 5],
  '22-165-1800': [0, 2, 3, 4, 1, 5],
  '22-165-1801': [0, 2, 3, 4, 1, 5],
  '22-165-1802': [0, 2, 3, 4, 1, 5],
  '22-165-1803': [0, 2, 3, 4, 1, 5],
  '22-165-1828': [0, 2, 3, 4, 1, 5],
  '22-165-1829': [0, 2, 3, 4, 1, 5],
  '22-165-1892': [0, 2, 3, 4, 1, 5],
  '22-165-1907': [0, 2, 3, 4, 1, 5],
  '22-165-1939': [0, 2, 3, 4, 1, 5],
  '22-165-1940': [0, 2, 3, 4, 1, 5],
  '22-165-3170': [0, 2, 3, 4, 1, 5],
  '22-165-3810': [0, 2, 3, 4, 1, 5],
  '22-165-6860': [0, 2, 3, 4, 1, 5],
  '22-165-6910': [0, 2, 3, 4, 1, 5],
  '22-165-6920': [0, 2, 3, 4, 1, 5],
  '22-165-7787': [0, 2, 3, 4, 1, 5],
  '22-165-7868': [0, 2, 3, 4, 1, 5],
  '22-167-1425': [2, 0, 3, 4, 1, 2],
  '22-167-1607': [2, 0, 3, 4, 1, 2],
  '22-167-1608': [2, 0, 3, 4, 1, 2],
  '22-167-7788': [2, 0, 3, 4, 1, 2],
  '22-167-7789': [2, 0, 3, 4, 1, 2],
  '22-167-7790': [2, 0, 3, 4, 1, 2],
  '22-167-7791': [2, 0, 3, 4, 1, 2],
  '22-167-7803': [2, 0, 3, 4, 1, 2],
  '22-167-8234': [2, 0, 3, 4, 1, 2],
  '22-167-8235': [2, 0, 3, 4, 1, 2],
  '22-167-8236': [2, 0, 3, 4, 1, 2],
  '22-167-8237': [2, 0, 3, 4, 1, 2],
  '22-167-8238': [2, 0, 3, 4, 1, 2],
  '22-167-8239': [2, 0, 3, 4, 1, 2],
  '22-168-1427': [2, 0, 3, 4, 1, 2],
  '22-168-1428': [2, 0, 3, 4, 1, 2],
  '22-168-1438': [2, 0, 3, 4, 1, 2],
  '22-168-1439': [2, 0, 3, 4, 1, 2],
  '22-168-1615': [2, 0, 3, 4, 1, 2],
  '22-168-1616': [2, 0, 3, 4, 1, 2],
  '22-168-1857': [2, 0, 3, 4, 1, 2],
  '22-168-1858': [2, 0, 3, 4, 1, 2],
  '22-168-5980': [2, 0, 3, 4, 1, 2],
  '22-168-5990': [2, 0, 3, 4, 1, 2],
  '22-168-7786': [2, 0, 3, 4, 1, 2],
  '22-170-1470': [0, 2, 3, 4, 1, 5],
  '22-170-1532': [0, 2, 3, 4, 1, 5],
  '22-170-1657': [0, 2, 3, 4, 1, 5],
  '22-170-1720': [0, 2, 3, 4, 1, 5],
  '22-170-1737': [0, 2, 3, 4, 1, 5],
  '22-170-1744': [0, 2, 3, 4, 1, 5],
  '22-170-1745': [0, 2, 3, 4, 1, 5],
  '22-170-1778': [0, 2, 3, 4, 1, 5],
  '22-170-1786': [0, 2, 3, 4, 1, 5],
  '22-170-1798': [0, 2, 3, 4, 1, 5],
  '22-170-1915': [0, 2, 3, 4, 1, 5],
  '22-170-1952': [0, 2, 3, 4, 1, 5],
  '22-171-1400': [0, 2, 3, 4, 1, 5],
  '22-171-1402': [0, 2, 3, 4, 1, 5],
  '22-171-3940': [0, 2, 3, 4, 1, 5],
  '22-172-1407': [0, 2, 3, 4, 1, 5],
  '22-172-1408': [0, 2, 3, 4, 1, 5],
  '22-172-1665': [0, 2, 3, 4, 1, 5],
  '22-172-1683': [0, 2, 3, 4, 1, 5],
  '22-172-1694': [0, 2, 3, 4, 1, 5],
  '22-172-1696': [0, 2, 3, 4, 1, 5],
  '22-172-1776': [0, 2, 3, 4, 1, 5],
  '22-172-1917': [0, 2, 3, 4, 1, 5],
  '22-172-1918': [0, 2, 3, 4, 1, 5],
  '22-173-1685': [0, 2, 3, 4, 1, 5],
  '22-174-1410': [0, 2, 3, 4, 1, 5],
  '22-174-1414': [0, 2, 3, 4, 1, 5],
  '22-174-1419': [0, 2, 3, 4, 1, 5],
  '22-174-1909': [0, 2, 3, 4, 1, 5],
  '22-176-1842': [0, 2, 3, 4, 1, 5],
  '22-176-1843': [0, 2, 3, 4, 1, 5],
  '22-176-1847': [0, 2, 3, 4, 1, 5],
  '22-176-7451': [0, 2, 3, 4, 1, 5],
  '22-176-7452': [0, 2, 3, 4, 1, 5],
  '22-177-1409': [0, 2, 3, 4, 1, 5],
  '22-177-1471': [0, 2, 3, 4, 1, 5],
  '22-177-1658': [0, 2, 3, 4, 1, 5],
  '22-177-1670': [0, 2, 3, 4, 1, 5],
  '22-177-1886': [0, 2, 3, 4, 1, 5],
  '22-177-1908': [0, 2, 3, 4, 1, 5],
  '22-177-2404': [0, 2, 3, 4, 1, 5],
  '22-177-3340': [0, 2, 3, 4, 1, 5],
  '22-177-3820': [0, 2, 3, 4, 1, 5],
  '22-177-7733': [0, 2, 3, 4, 1, 5],
  '22-179-1444': [2, 0, 3, 4, 1, 2],
  '22-179-1450': [2, 0, 3, 4, 1, 2],
  '22-179-1466': [2, 0, 3, 4, 1, 2],
  '22-179-7719': [2, 0, 3, 4, 1, 2],
  '22-180-1413': [0, 2, 3, 4, 1, 5],
  '22-180-1415': [0, 2, 3, 4, 1, 5],
  '22-180-1416': [0, 2, 3, 4, 1, 5],
  '22-180-1417': [0, 2, 3, 4, 1, 5],
  '22-180-1418': [0, 2, 3, 4, 1, 5],
  '22-223-2104': [0, 2, 3, 4, 1, 5],
  '22-223-2105': [0, 2, 3, 4, 1, 5],
  '22-223-2107': [0, 2, 3, 4, 1, 5],
  '22-223-2112': [0, 2, 3, 4, 1, 5],
  '22-223-2136': [0, 2, 3, 4, 1, 5],
  '22-223-2140': [0, 2, 3, 4, 1, 5],
  '22-223-2225': [0, 2, 3, 4, 1, 5],
  '22-223-2253': [0, 2, 3, 4, 1, 5],
  '22-223-2295': [0, 2, 3, 4, 1, 5],
  '22-223-2312': [0, 2, 3, 4, 1, 5],
  '22-223-2314': [0, 2, 3, 4, 1, 5],
  '22-223-2316': [0, 2, 3, 4, 1, 5],
  '22-223-2433': [0, 2, 3, 4, 1, 5],
  '22-223-2473': [0, 2, 3, 4, 1, 5],
  '22-223-2537': [0, 2, 3, 4, 1, 5],
  '22-223-2611': [0, 2, 3, 4, 1, 5],
  '22-223-2617': [0, 2, 3, 4, 1, 5],
  '22-223-2653': [0, 2, 3, 4, 1, 5],
  '22-223-2704': [0, 2, 3, 4, 1, 5],
  '22-223-3700': [0, 2, 3, 4, 1, 5],
  '22-224-1895': [0, 2, 3, 4, 1, 5],
  '22-224-1896': [0, 2, 3, 4, 1, 5],
  '22-224-1897': [0, 2, 3, 4, 1, 5],
  '22-224-1898': [0, 2, 3, 4, 1, 5],
  '22-226-1605': [0, 2, 3, 4, 1, 5],
  '22-226-1712': [0, 2, 3, 4, 1, 5],
  '22-226-1714': [0, 2, 3, 4, 1, 5],
  '22-226-1741': [0, 2, 3, 4, 1, 5],
  '22-226-1840': [0, 2, 3, 4, 1, 5],
  '22-226-1856': [0, 2, 3, 4, 1, 5],
  '22-226-1861': [0, 2, 3, 4, 1, 5],
  '22-226-1862': [0, 2, 3, 4, 1, 5],
  '22-226-1863': [0, 2, 3, 4, 1, 5],
  '22-226-1866': [0, 2, 3, 4, 1, 5],
  '22-226-1869': [0, 2, 3, 4, 1, 5],
  '22-226-1873': [0, 2, 3, 4, 1, 5],
  '22-226-1874': [0, 2, 3, 4, 1, 5],
  '22-226-1875': [0, 2, 3, 4, 1, 5],
  '22-226-1877': [0, 2, 3, 4, 1, 5],
  '22-226-1880': [0, 2, 3, 4, 1, 5],
  '22-226-1882': [0, 2, 3, 4, 1, 5],
  '22-226-7517': [0, 2, 3, 4, 1, 5],
  '22-226-7652': [0, 2, 3, 4, 1, 5],
  '22-227-1399': [0, 2, 3, 4, 1, 5],
  '22-227-1631': [0, 2, 3, 4, 1, 5],
  '22-227-3770': [0, 2, 3, 4, 1, 5],
  '22-229-1468': [0, 2, 3, 4, 1, 5],
  '22-229-1513': [0, 2, 3, 4, 1, 5],
  '22-229-1603': [0, 2, 3, 4, 1, 5],
  '22-229-1661': [0, 2, 3, 4, 1, 5],
  '22-229-1693': [0, 2, 3, 4, 1, 5],
  '22-229-1839': [0, 2, 3, 4, 1, 5],
  '22-229-1851': [0, 2, 3, 4, 1, 5],
  '22-229-1852': [0, 2, 3, 4, 1, 5],
  '22-230-1397': [0, 2, 3, 4, 1, 5],
  '22-230-1398': [0, 2, 3, 4, 1, 5],
  '22-230-1627': [0, 2, 3, 4, 1, 5],
  '22-230-1671': [0, 2, 3, 4, 1, 5],
  '22-230-1673': [0, 2, 3, 4, 1, 5],
  '22-230-1674': [0, 2, 3, 4, 1, 5],
  '22-230-1738': [0, 2, 3, 4, 1, 5],
  '22-230-1832': [0, 2, 3, 4, 1, 5],
  '22-230-1833': [0, 2, 3, 4, 1, 5],
  '22-230-1835': [0, 2, 3, 4, 1, 5],
  '22-230-5160': [0, 2, 3, 4, 1, 5],
  '22-230-5170': [0, 2, 3, 4, 1, 5],
  '22-232-1727': [0, 2, 3, 4, 1, 5],
  '22-232-1849': [0, 2, 3, 4, 1, 5],
  '22-233-1677': [2, 0, 3, 4, 1, 2],
  '22-233-1758': [2, 0, 3, 4, 1, 2],
  '22-233-1759': [2, 0, 3, 4, 1, 2],
  '22-233-1761': [2, 0, 3, 4, 1, 2],
  '22-233-1788': [2, 0, 3, 4, 1, 2],
  '22-233-1789': [2, 0, 3, 4, 1, 2],
  '22-233-1790': [2, 0, 3, 4, 1, 2],
  '22-234-1645': [0, 2, 3, 4, 1, 5],
  '22-234-1715': [0, 2, 3, 4, 1, 5],
  '22-234-1721': [0, 2, 3, 4, 1, 5],
  '22-234-1766': [0, 2, 3, 4, 1, 5],
  '22-234-1770': [0, 2, 3, 4, 1, 5],
  '22-234-1771': [0, 2, 3, 4, 1, 5],
  '22-234-1906': [0, 2, 3, 4, 1, 5],
  '22-234-3900': [0, 2, 3, 4, 1, 5],
  '22-234-7728': [0, 2, 3, 4, 1, 5],
  '22-234-7729': [0, 2, 3, 4, 1, 5],
  '22-234-7730': [0, 2, 3, 4, 1, 5],
  '22-234-7731': [0, 2, 3, 4, 1, 5],
  '22-234-7732': [0, 2, 3, 4, 1, 5],
  '22-235-1606': [0, 2, 3, 4, 1, 5],
  '22-235-1713': [0, 2, 3, 4, 1, 5],
  '22-235-1728': [0, 2, 3, 4, 1, 5],
  '22-235-1742': [0, 2, 3, 4, 1, 5],
  '22-235-1841': [0, 2, 3, 4, 1, 5],
  '22-235-1850': [0, 2, 3, 4, 1, 5],
  '22-235-1864': [0, 2, 3, 4, 1, 5],
  '22-235-1876': [0, 2, 3, 4, 1, 5],
  '22-235-1878': [0, 2, 3, 4, 1, 5],
  '22-235-1883': [0, 2, 3, 4, 1, 5],
  '22-235-3930': [0, 2, 3, 4, 1, 5],
  '22-237-1469': [0, 2, 3, 4, 1, 5],
  '22-237-1514': [0, 2, 3, 4, 1, 5],
  '22-237-1660': [0, 2, 3, 4, 1, 5],
  '22-237-1692': [0, 2, 3, 4, 1, 5],
  '22-237-1838': [0, 2, 3, 4, 1, 5],
  '22-237-1853': [0, 2, 3, 4, 1, 5],
  '22-238-1482': [0, 2, 3, 4, 1, 5],
  '22-238-1571': [0, 2, 3, 4, 1, 5],
  '22-238-1859': [0, 2, 3, 4, 1, 5],
  '22-238-1860': [0, 2, 3, 4, 1, 5],
  '22-238-1865': [0, 2, 3, 4, 1, 5],
  '22-238-1867': [0, 2, 3, 4, 1, 5],
  '22-238-1870': [0, 2, 3, 4, 1, 5],
  '22-238-1871': [0, 2, 3, 4, 1, 5],
  '22-238-1872': [0, 2, 3, 4, 1, 5],
  '22-238-3950': [0, 2, 3, 4, 1, 5],
  '22-238-7650': [0, 2, 3, 4, 1, 5],
  '22-238-7810': [0, 2, 3, 4, 1, 5],
  '22-283-3160': [0, 2, 3, 4, 1, 5],
  '22-298-1913': [2, 0, 3, 4, 1, 2],
  '22-298-7478': [2, 0, 3, 4, 1, 2],
  '22-299-1679': [0, 2, 3, 4, 1, 5],
  '22-300-2235': [0, 2, 3, 4, 1, 5],
  '22-300-5190': [0, 2, 3, 4, 1, 5],
  '22-301-2509': [0, 2, 3, 4, 1, 5],
  '22-301-6000': [0, 2, 3, 4, 1, 5],
  '22-301-6050': [0, 2, 3, 4, 1, 5],
  '22-302-2755': [0, 2, 3, 4, 1, 5],
  '22-303-1950': [0, 2, 3, 4, 1, 5],
  '22-303-1958': [0, 2, 3, 4, 1, 5],
  '22-325-1666': [0, 2, 3, 4, 1, 5],
  '22-325-1684': [0, 2, 3, 4, 1, 5],
  '22-325-1695': [0, 2, 3, 4, 1, 5],
  '22-325-1697': [0, 2, 3, 4, 1, 5],
  '22-325-1777': [0, 2, 3, 4, 1, 5],
  '22-325-1916': [0, 2, 3, 4, 1, 5],
  '22-333-1806': [0, 2, 3, 4, 1, 5],
  '22-333-1810': [0, 2, 3, 4, 1, 5],
  '22-333-1814': [0, 2, 3, 4, 1, 5],
  '22-333-1815': [0, 2, 3, 4, 1, 5],
  '22-333-1818': [0, 2, 3, 4, 1, 5],
  '22-333-1822': [0, 2, 3, 4, 1, 5],
  '23-181-4470': [2, 3, 0, 5, 4, 1],
  '23-181-4480': [2, 3, 0, 5, 4, 1],
  '23-181-4490': [2, 3, 0, 5, 4, 1],
  '23-181-4500': [2, 3, 0, 5, 4, 1],
  '23-181-4520': [2, 3, 0, 5, 4, 1],
  '23-181-4530': [2, 3, 0, 5, 4, 1],
  '23-181-4540': [2, 3, 0, 5, 4, 1],
  '23-181-4550': [2, 3, 0, 5, 4, 1],
  '23-182-2272': [2, 0, 5, 3, 4, 1],
  '23-182-2274': [2, 0, 5, 3, 4, 1],
  '23-182-2412': [2, 0, 5, 3, 4, 1],
  '23-182-2420': [2, 0, 5, 3, 4, 1],
  '23-182-2424': [2, 0, 5, 3, 4, 1],
  '23-182-2483': [2, 0, 5, 3, 4, 1],
  '23-182-2496': [2, 0, 5, 3, 4, 1],
  '23-182-2497': [2, 0, 5, 3, 4, 1],
  '23-182-2645': [2, 0, 5, 3, 4, 1],
  '23-182-2650': [2, 0, 5, 3, 4, 1],
  '23-182-2663': [2, 0, 5, 3, 4, 1],
  '23-182-2710': [2, 0, 5, 3, 4, 1],
  '23-183-2388': [2, 3, 0, 5, 4, 1],
  '23-183-4300': [2, 3, 0, 5, 4, 1],
  '23-183-4430': [2, 3, 0, 5, 4, 1],
  '23-183-6830': [2, 3, 0, 5, 4, 1],
  '23-183-7410': [2, 3, 0, 5, 4, 1],
  '23-184-2394': [2, 3, 0, 5, 4, 1],
  '23-184-5120': [2, 3, 0, 5, 4, 1],
  '23-184-5130': [2, 3, 0, 5, 4, 1],
  '23-184-5140': [2, 3, 0, 5, 4, 1],
  '23-185-2410': [2, 3, 0, 5, 4, 1],
  '23-185-2670': [2, 3, 0, 5, 4, 1],
  '23-185-3360': [2, 3, 0, 5, 4, 1],
  '23-185-4250': [2, 3, 0, 5, 4, 1],
  '23-186-2262': [2, 3, 0, 5, 4, 1],
  '23-186-2307': [2, 3, 0, 5, 4, 1],
  '23-186-2411': [2, 3, 0, 5, 4, 1],
  '23-186-2597': [2, 3, 0, 5, 4, 1],
  '23-186-2628': [2, 3, 0, 5, 4, 1],
  '23-186-4370': [2, 3, 0, 5, 4, 1],
  '23-186-4380': [2, 3, 0, 5, 4, 1],
  '23-187-2362': [2, 3, 0, 5, 4, 1],
  '23-188-2293': [2, 3, 0, 5, 4, 1],
  '23-188-2364': [2, 3, 0, 5, 4, 1],
  '23-188-2366': [2, 3, 0, 5, 4, 1],
  '23-188-2371': [2, 3, 0, 5, 4, 1],
  '23-188-2381': [2, 3, 0, 5, 4, 1],
  '23-188-2382': [2, 3, 0, 5, 4, 1],
  '23-188-2383': [2, 3, 0, 5, 4, 1],
  '23-188-2401': [2, 3, 0, 5, 4, 1],
  '23-188-2405': [2, 3, 0, 5, 4, 1],
  '23-188-2409': [2, 3, 0, 5, 4, 1],
  '23-188-2442': [2, 3, 0, 5, 4, 1],
  '23-188-2443': [2, 3, 0, 5, 4, 1],
  '23-188-2491': [2, 3, 0, 5, 4, 1],
  '23-188-2525': [2, 3, 0, 5, 4, 1],
  '23-188-2526': [2, 3, 0, 5, 4, 1],
  '23-188-2600': [2, 3, 0, 5, 4, 1],
  '23-188-2608': [2, 3, 0, 5, 4, 1],
  '23-188-2633': [2, 3, 0, 5, 4, 1],
  '23-188-2634': [2, 3, 0, 5, 4, 1],
  '23-188-2652': [2, 3, 0, 5, 4, 1],
  '23-188-2671': [2, 3, 0, 5, 4, 1],
  '23-188-2681': [2, 3, 0, 5, 4, 1],
  '23-188-4000': [2, 3, 0, 5, 4, 1],
  '23-188-4210': [2, 3, 0, 5, 4, 1],
  '23-188-4310': [2, 3, 0, 5, 4, 1],
  '23-188-4460': [2, 3, 0, 5, 4, 1],
  '23-188-4640': [2, 3, 0, 5, 4, 1],
  '23-188-4650': [2, 3, 0, 5, 4, 1],
  '23-188-4670': [2, 3, 0, 5, 4, 1],
  '23-188-4840': [2, 3, 0, 5, 4, 1],
  '23-188-4870': [2, 3, 0, 5, 4, 1],
  '23-188-4880': [2, 3, 0, 5, 4, 1],
  '23-188-4890': [2, 3, 0, 5, 4, 1],
  '23-188-4900': [2, 3, 0, 5, 4, 1],
  '23-188-6790': [2, 3, 0, 5, 4, 1],
  '23-190-2331': [2, 3, 0, 5, 4, 1],
  '23-190-2506': [2, 3, 0, 5, 4, 1],
  '23-190-2564': [2, 3, 0, 5, 4, 1],
  '23-190-2581': [2, 3, 0, 5, 4, 1],
  '23-190-2668': [2, 3, 0, 5, 4, 1],
  '23-190-2677': [2, 3, 0, 5, 4, 1],
  '23-190-4330': [2, 3, 0, 5, 4, 1],
  '23-191-2345': [2, 3, 0, 5, 4, 1],
  '23-191-2363': [2, 3, 0, 5, 4, 1],
  '23-191-2625': [2, 3, 0, 5, 4, 1],
  '23-192-2231': [2, 3, 0, 5, 4, 1],
  '23-192-2257': [2, 3, 0, 5, 4, 1],
  '23-192-2294': [2, 3, 0, 5, 4, 1],
  '23-192-2306': [2, 3, 0, 5, 4, 1],
  '23-192-2672': [2, 3, 0, 5, 4, 1],
  '23-192-2699': [2, 3, 0, 5, 4, 1],
  '23-192-3230': [2, 3, 0, 5, 4, 1],
  '23-192-3330': [2, 3, 0, 5, 4, 1],
  '23-192-3380': [2, 3, 0, 5, 4, 1],
  '23-192-3990': [2, 3, 0, 5, 4, 1],
  '23-192-4860': [2, 3, 0, 5, 4, 1],
  '23-273-2242': [2, 3, 0, 5, 4, 1],
  '23-273-2258': [2, 0, 3, 5, 4, 1],
  '23-273-2260': [2, 3, 0, 5, 4, 1],
  '23-273-2266': [2, 0, 3, 5, 4, 1],
  '23-273-2281': [2, 3, 0, 5, 4, 1],
  '23-273-2282': [2, 0, 3, 5, 4, 1],
  '23-273-2286': [2, 3, 0, 5, 4, 1],
  '23-273-2308': [2, 0, 3, 5, 4, 1],
  '23-273-2349': [2, 3, 0, 5, 4, 1],
  '23-273-2350': [2, 3, 0, 5, 4, 1],
  '23-273-2367': [2, 3, 0, 5, 4, 1],
  '23-273-2389': [0, 2, 4, 3, 5, 1],
  '23-273-2403': [2, 0, 3, 5, 4, 1],
  '23-273-2448': [2, 0, 3, 5, 4, 1],
  '23-273-2466': [2, 0, 3, 5, 4, 1],
  '23-273-2484': [2, 0, 3, 5, 4, 1],
  '23-273-2493': [2, 0, 3, 5, 4, 1],
  '23-273-2494': [2, 0, 3, 5, 4, 1],
  '23-273-2593': [2, 0, 3, 5, 4, 1],
  '23-273-2619': [2, 0, 3, 5, 4, 1],
  '23-273-2620': [2, 0, 3, 5, 4, 1],
  '23-273-2627': [2, 0, 3, 5, 4, 1],
  '23-273-2646': [2, 0, 3, 5, 4, 1],
  '23-273-2678': [2, 0, 3, 5, 4, 1],
  '23-273-3250': [2, 3, 0, 5, 4, 1],
  '23-273-3310': [2, 0, 3, 5, 4, 1],
  '23-273-4850': [2, 0, 3, 5, 4, 1],
  '23-285-3350': [2, 0, 3, 5, 4, 1],
  '23-285-3980': [2, 0, 3, 5, 4, 1],
  '23-285-4660': [2, 0, 3, 5, 4, 1],
  '23-285-7494': [2, 0, 3, 5, 4, 1],
  '23-304-5150': [2, 3, 0, 5, 4, 1],
  '24-194-2229': [2, 0, 3, 4, 2, 1],
  '24-194-2417': [2, 0, 3, 4, 2, 1],
  '24-194-2418': [2, 0, 3, 4, 2, 1],
  '24-194-2419': [2, 0, 3, 4, 2, 1],
  '24-194-3970': [2, 0, 3, 4, 2, 1],
  '24-194-4930': [2, 0, 3, 4, 2, 1],
  '24-194-4940': [2, 0, 3, 4, 2, 1],
  '24-194-4950': [2, 0, 3, 4, 2, 1],
  '24-194-4960': [2, 0, 3, 4, 2, 1],
  '24-194-6780': [2, 0, 3, 4, 2, 1],
  '24-195-2237': [2, 0, 3, 4, 2, 1],
  '24-195-2238': [2, 0, 3, 4, 2, 1],
  '24-195-2239': [2, 0, 3, 4, 2, 1],
  '24-195-2240': [2, 0, 3, 4, 2, 1],
  '24-195-2289': [2, 0, 3, 4, 2, 1],
  '24-195-2290': [2, 0, 3, 4, 2, 1],
  '24-195-2291': [2, 0, 3, 4, 2, 1],
  '24-195-2292': [2, 0, 3, 4, 2, 1],
  '24-195-2695': [2, 0, 3, 4, 2, 1],
  '24-195-2696': [2, 0, 3, 4, 2, 1],
  '24-195-2697': [2, 0, 3, 4, 2, 1],
  '24-195-2698': [2, 0, 3, 4, 2, 1],
  '24-195-2747': [2, 0, 3, 4, 2, 1],
  '24-195-3220': [2, 0, 3, 4, 2, 1],
  '24-195-3260': [2, 0, 3, 4, 2, 1],
  '24-195-3320': [2, 0, 3, 4, 2, 1],
  '24-195-4060': [2, 0, 3, 4, 2, 1],
  '24-195-4220': [2, 0, 3, 4, 2, 1],
  '24-195-4790': [2, 0, 3, 4, 2, 1],
  '24-195-6950': [2, 0, 3, 4, 2, 1],
  '24-195-7220': [2, 0, 3, 4, 2, 1],
  '24-195-7576': [2, 0, 3, 4, 2, 1],
  '24-195-7579': [2, 0, 3, 4, 2, 1],
  '24-195-7691': [2, 0, 3, 4, 2, 1],
  '24-195-7692': [2, 0, 3, 4, 2, 1],
  '24-195-7715': [2, 0, 3, 4, 2, 1],
  '24-195-7716': [2, 0, 3, 4, 2, 1],
  '24-195-7757': [2, 0, 3, 4, 2, 1],
  '24-195-7758': [2, 0, 3, 4, 2, 1],
  '24-195-7759': [2, 0, 3, 4, 2, 1],
  '24-195-7761': [2, 0, 3, 4, 2, 1],
  '24-195-7771': [2, 0, 3, 4, 2, 1],
  '24-196-2263': [2, 0, 3, 4, 2, 1],
  '24-196-2264': [2, 0, 3, 4, 2, 1],
  '24-196-2265': [2, 0, 3, 4, 2, 1],
  '24-196-2346': [2, 0, 3, 4, 2, 1],
  '24-196-2347': [2, 0, 3, 4, 2, 1],
  '24-196-2348': [2, 0, 3, 4, 2, 1],
  '24-196-2462': [2, 0, 3, 4, 2, 1],
  '24-196-2463': [2, 0, 3, 4, 2, 1],
  '24-196-2464': [2, 0, 3, 4, 2, 1],
  '24-196-2465': [2, 0, 3, 4, 2, 1],
  '24-196-2567': [2, 0, 3, 4, 2, 1],
  '24-196-2568': [2, 0, 3, 4, 2, 1],
  '24-196-2569': [2, 0, 3, 4, 2, 1],
  '24-196-3440': [2, 0, 3, 4, 2, 1],
  '24-196-4610': [2, 0, 3, 4, 2, 1],
  '24-196-4620': [2, 0, 3, 4, 2, 1],
  '24-196-7792': [2, 0, 3, 4, 2, 1],
  '24-197-2322': [2, 0, 3, 4, 2, 1],
  '24-197-2361': [2, 0, 3, 4, 2, 1],
  '24-197-2395': [2, 0, 3, 4, 2, 1],
  '24-197-4040': [2, 0, 3, 4, 2, 1],
  '24-197-4050': [2, 0, 3, 4, 2, 1],
  '24-197-5070': [2, 0, 3, 4, 2, 1],
  '24-197-5090': [2, 0, 3, 4, 2, 1],
  '24-197-5110': [2, 0, 3, 4, 2, 1],
  '24-197-6900': [2, 0, 3, 4, 2, 1],
  '24-198-2385': [2, 0, 3, 4, 2, 1],
  '24-198-2399': [2, 0, 3, 4, 2, 1],
  '24-198-2469': [2, 0, 3, 4, 2, 1],
  '24-198-2470': [2, 0, 3, 4, 2, 1],
  '24-198-2720': [2, 0, 3, 4, 2, 1],
  '24-198-2722': [2, 0, 3, 4, 2, 1],
  '24-198-2723': [2, 0, 3, 4, 2, 1],
  '24-198-2724': [2, 0, 3, 4, 2, 1],
  '24-198-3450': [2, 0, 3, 4, 2, 1],
  '24-198-4800': [2, 0, 3, 4, 2, 1],
  '24-198-7400': [2, 0, 3, 4, 2, 1],
  '24-198-7762': [2, 0, 3, 4, 2, 1],
  '24-198-7813': [2, 0, 3, 4, 2, 1],
  '24-199-2387': [2, 0, 3, 4, 2, 1],
  '24-200-2370': [2, 0, 3, 4, 2, 1],
  '24-200-2488': [2, 0, 3, 4, 2, 1],
  '24-200-2500': [2, 0, 3, 4, 2, 1],
  '24-200-2502': [2, 0, 3, 4, 2, 1],
  '24-200-2504': [2, 0, 3, 4, 2, 1],
  '24-200-2505': [2, 0, 3, 4, 2, 1],
  '24-200-2565': [2, 0, 3, 4, 2, 1],
  '24-200-2669': [2, 0, 3, 4, 2, 1],
  '24-200-2694': [2, 0, 3, 4, 2, 1],
  '24-200-4010': [2, 0, 3, 4, 2, 1],
  '24-200-4570': [2, 0, 3, 4, 2, 1],
  '24-200-4920': [2, 0, 3, 4, 2, 1],
  '24-203-2651': [2, 0, 3, 4, 5, 1],
  '24-203-2655': [2, 0, 3, 4, 5, 1],
  '24-203-2658': [2, 0, 3, 4, 5, 1],
  '24-203-2688': [2, 0, 3, 4, 5, 1],
  '24-203-6840': [2, 0, 3, 4, 5, 1],
  '24-203-6850': [2, 0, 3, 4, 5, 1],
  '24-203-7459': [2, 0, 3, 4, 5, 1],
  '24-203-7765': [2, 0, 3, 4, 5, 1],
  '24-203-7766': [2, 0, 3, 4, 5, 1],
  '24-204-2679': [2, 0, 3, 4, 2, 1],
  '24-204-2680': [2, 0, 3, 4, 2, 1],
  '24-204-2682': [2, 0, 3, 4, 2, 1],
  '24-204-2690': [2, 0, 3, 4, 2, 1],
  '24-204-2691': [2, 0, 3, 4, 2, 1],
  '24-204-2692': [2, 0, 3, 4, 2, 1],
  '24-204-7514': [2, 0, 3, 4, 2, 1],
  '24-305-2471': [2, 0, 3, 4, 2, 1],
  '25-207-2377': [2, 5, 3, 4, 0, 1],
  '25-207-2384': [2, 5, 3, 4, 0, 1],
  '25-207-2455': [2, 5, 3, 4, 0, 1],
  '25-207-2456': [2, 0, 2, 3, 4, 1],
  '25-207-2457': [2, 5, 3, 4, 0, 1],
  '25-207-2459': [2, 5, 3, 4, 0, 1],
  '25-207-2460': [2, 5, 3, 4, 0, 1],
  '25-207-2461': [2, 0, 2, 3, 4, 1],
  '25-207-2643': [2, 5, 3, 4, 0, 1],
  '25-207-3430': [2, 5, 3, 4, 0, 1],
  '25-207-4320': [2, 0, 2, 3, 4, 1],
  '25-207-7526': [2, 0, 2, 3, 4, 1],
  '25-207-7527': [2, 0, 2, 3, 4, 1],
  '25-207-7528': [2, 5, 3, 4, 0, 1],
  '25-207-7778': [2, 5, 3, 4, 0, 1],
  '25-207-7798': [2, 5, 3, 4, 0, 1],
  '25-208-2414': [2, 5, 3, 4, 0, 1],
  '25-208-2452': [2, 5, 3, 4, 0, 1],
  '25-208-2512': [2, 5, 3, 4, 0, 1],
  '25-208-2513': [2, 5, 3, 4, 0, 1],
  '25-208-2514': [2, 0, 2, 3, 4, 1],
  '25-208-2515': [2, 5, 3, 4, 0, 1],
  '25-208-2516': [2, 0, 2, 3, 4, 1],
  '25-208-2517': [2, 5, 3, 4, 0, 1],
  '25-208-2518': [2, 0, 2, 3, 4, 1],
  '25-208-2519': [2, 5, 3, 4, 0, 1],
  '25-208-2520': [2, 0, 2, 3, 4, 1],
  '25-208-4280': [2, 0, 2, 3, 4, 1],
  '25-208-7529': [2, 0, 2, 3, 4, 1],
  '25-208-7918': [2, 0, 2, 3, 4, 1],
  '25-208-7919': [2, 0, 2, 3, 4, 1],
  '25-209-2586': [2, 5, 3, 4, 0, 1],
  '25-209-2587': [2, 0, 2, 3, 4, 1],
  '25-209-2589': [2, 5, 3, 4, 0, 1],
  '25-209-2590': [2, 0, 2, 3, 4, 1],
  '25-209-2591': [2, 5, 3, 4, 0, 1],
  '25-209-2592': [2, 0, 2, 3, 4, 1],
  '25-209-7532': [2, 5, 3, 4, 0, 1],
  '25-209-7533': [2, 0, 2, 3, 4, 1],
  '25-209-7564': [2, 0, 2, 3, 4, 1],
  '25-209-7565': [2, 5, 3, 4, 0, 1],
  '25-210-2337': [2, 5, 3, 4, 0, 1],
  '25-210-2413': [2, 5, 3, 4, 0, 1],
  '25-210-7799': [2, 5, 3, 4, 0, 1],
  '25-211-2683': [2, 5, 3, 4, 0, 1],
  '25-211-2684': [2, 5, 3, 4, 0, 1],
  '25-211-2685': [2, 0, 2, 3, 4, 1],
  '25-211-2686': [2, 5, 3, 4, 0, 1],
  '25-211-2687': [2, 5, 3, 4, 0, 1],
  '25-211-7580': [2, 5, 3, 4, 0, 1],
  '25-340-2226': [2, 5, 3, 4, 0, 1],
  '25-340-2227': [2, 0, 2, 3, 4, 1],
  '25-340-2541': [2, 5, 3, 4, 0, 1],
  '25-340-2542': [2, 0, 2, 3, 4, 1],
  '25-340-7883': [2, 5, 3, 4, 0, 1],
  '25-340-7898': [2, 5, 3, 4, 0, 1],
  '25-340-7900': [2, 5, 3, 4, 0, 1],
  '25-340-7901': [2, 5, 3, 4, 0, 1],
  '25-340-7902': [2, 5, 3, 4, 0, 1],
  '25-340-7903': [2, 5, 3, 4, 0, 1],
  '25-340-7904': [2, 0, 2, 3, 4, 1],
  '25-341-2259': [2, 5, 3, 4, 0, 1],
  '25-341-2261': [2, 5, 3, 4, 0, 1],
  '25-341-2601': [2, 5, 3, 4, 0, 1],
  '25-341-2656': [2, 5, 3, 4, 0, 1],
  '25-341-7520': [2, 5, 3, 4, 0, 1],
  '25-341-7884': [2, 0, 2, 3, 4, 1],
  '25-341-7885': [2, 0, 2, 3, 4, 1],
  '25-341-7886': [2, 0, 2, 3, 4, 1],
  '25-342-2421': [2, 5, 3, 4, 0, 1],
  '25-342-2453': [2, 5, 3, 4, 0, 1],
  '25-342-2467': [2, 5, 3, 4, 0, 1],
  '25-342-2522': [2, 5, 3, 4, 0, 1],
  '25-342-2523': [2, 0, 2, 3, 4, 1],
  '25-342-2675': [2, 5, 3, 4, 0, 1],
  '25-342-7521': [2, 5, 3, 4, 0, 1],
  '25-342-7838': [2, 0, 2, 3, 4, 1],
  '25-342-7839': [2, 0, 2, 3, 4, 1],
  '25-342-7840': [2, 0, 2, 3, 4, 1],
  '25-342-7887': [2, 0, 2, 3, 4, 1],
  '25-342-7888': [2, 0, 2, 3, 4, 1],
  '25-342-7891': [2, 5, 3, 4, 0, 1],
  '25-343-2489': [2, 5, 3, 4, 0, 1],
  '25-343-7920': [2, 5, 3, 4, 0, 1],
  '25-343-7921': [2, 5, 3, 4, 0, 1],
  '25-344-2283': [2, 5, 3, 4, 0, 1],
  '25-344-2284': [2, 0, 2, 3, 4, 1],
  '25-344-2439': [2, 5, 3, 4, 0, 1],
  '25-344-2440': [2, 0, 2, 3, 4, 1],
  '25-344-2715': [2, 5, 3, 4, 0, 1],
  '25-344-2716': [2, 0, 2, 3, 4, 1],
  '25-344-7522': [2, 5, 3, 4, 0, 1],
  '25-344-7889': [2, 5, 3, 4, 0, 1],
  '25-344-7908': [2, 0, 2, 3, 4, 1],
  '25-345-2309': [2, 5, 3, 4, 0, 1],
  '25-345-2422': [2, 5, 3, 4, 0, 1],
  '25-345-2507': [2, 5, 3, 4, 0, 1],
  '25-345-2539': [2, 5, 3, 4, 0, 1],
  '25-345-2570': [2, 5, 3, 4, 0, 1],
  '25-345-7523': [2, 5, 3, 4, 0, 1],
  '25-345-7892': [2, 5, 3, 4, 0, 1],
  '25-345-7893': [2, 5, 3, 4, 0, 1],
  '25-345-7894': [2, 0, 2, 3, 4, 1],
  '25-345-7895': [2, 0, 2, 3, 4, 1],
  '25-345-7896': [2, 0, 2, 3, 4, 1],
  '25-345-7897': [2, 0, 2, 3, 4, 1],
  '25-346-2234': [2, 5, 3, 4, 0, 1],
  '25-346-2241': [2, 5, 3, 4, 0, 1],
  '25-346-2285': [2, 5, 3, 4, 0, 1],
  '25-346-2332': [2, 5, 3, 4, 0, 1],
  '25-346-2449': [2, 5, 3, 4, 0, 1],
  '25-346-2481': [2, 5, 3, 4, 0, 1],
  '25-346-2527': [2, 5, 3, 4, 0, 1],
  '25-346-2561': [2, 5, 3, 4, 0, 1],
  '25-346-2562': [2, 0, 2, 3, 4, 1],
  '25-346-2566': [2, 5, 3, 4, 0, 1],
  '25-346-7524': [2, 5, 3, 4, 0, 1],
  '25-346-7890': [2, 5, 3, 4, 0, 1],
  '25-346-7906': [2, 0, 2, 3, 4, 1],
  '25-346-7907': [2, 0, 2, 3, 4, 1],
  '25-346-7909': [2, 0, 2, 3, 4, 1],
  '25-347-2245': [2, 5, 3, 4, 0, 1],
  '25-347-2246': [2, 0, 2, 3, 4, 1],
  '25-347-2248': [2, 5, 3, 4, 0, 1],
  '25-347-2543': [2, 5, 3, 4, 0, 1],
  '25-347-7525': [2, 5, 3, 4, 0, 1],
  '25-347-7910': [2, 0, 2, 3, 4, 1],
  '25-347-7912': [2, 0, 2, 3, 4, 1],
  '25-347-7915': [2, 0, 2, 3, 4, 1],
  '25-348-2343': [2, 5, 3, 4, 0, 1],
  '25-348-2344': [2, 0, 2, 3, 4, 1],
  '25-348-2595': [2, 5, 3, 4, 0, 1],
  '25-348-2648': [2, 5, 3, 4, 0, 1],
  '25-348-2649': [2, 0, 2, 3, 4, 1],
  '25-348-2725': [2, 5, 3, 4, 0, 1],
  '25-348-2726': [2, 0, 2, 3, 4, 1],
  '25-349-2398': [2, 2, 3, 4, 0, 1],
  '25-350-2423': [2, 5, 3, 4, 0, 1],
  '25-350-2446': [2, 5, 3, 4, 0, 1],
  '25-350-2709': [2, 5, 3, 4, 0, 1],
  '25-351-2436': [2, 5, 3, 4, 0, 1],
  '25-351-2528': [2, 5, 3, 4, 0, 1],
  '25-351-2529': [2, 0, 2, 3, 4, 1],
  '25-351-7801': [2, 5, 3, 4, 0, 1],
  '25-351-7802': [2, 0, 2, 3, 4, 1],
  '25-352-2498': [2, 5, 3, 4, 0, 1],
  '25-352-2499': [2, 0, 2, 3, 4, 1],
  '25-352-7772': [2, 5, 3, 4, 0, 1],
  '25-352-7773': [2, 5, 3, 4, 0, 1],
  '25-352-7774': [2, 5, 3, 4, 0, 1],
  '25-352-7775': [2, 0, 2, 3, 4, 1],
  '25-352-7776': [2, 0, 2, 3, 4, 1],
  '25-352-7777': [2, 0, 2, 3, 4, 1],
  '25-353-2544': [2, 5, 3, 4, 0, 1],
  '25-353-2545': [2, 5, 3, 4, 0, 1],
  '25-353-2546': [2, 0, 2, 3, 4, 1],
  '25-353-2547': [2, 5, 3, 4, 0, 1],
  '25-353-2548': [2, 5, 3, 4, 0, 1],
  '25-353-2549': [2, 0, 2, 3, 4, 1],
  '25-353-2550': [2, 5, 3, 4, 0, 1],
  '25-353-2551': [2, 0, 2, 3, 4, 1],
  '25-353-2552': [2, 5, 3, 4, 0, 1],
  '25-353-2553': [2, 0, 2, 3, 4, 1],
  '25-353-7530': [2, 0, 2, 3, 4, 1],
  '25-353-7531': [2, 0, 2, 3, 4, 1],
  '25-354-2255': [2, 5, 3, 4, 0, 1],
  '25-354-2256': [2, 0, 2, 3, 4, 1],
  '25-354-2287': [2, 5, 3, 4, 0, 1],
  '25-354-2288': [2, 0, 2, 3, 4, 1],
  '25-354-2598': [2, 5, 3, 4, 0, 1],
  '25-354-2599': [2, 0, 2, 3, 4, 1],
  '25-354-2607': [2, 5, 3, 4, 0, 1],
  '25-354-7534': [2, 5, 3, 4, 0, 1],
  '25-354-7535': [2, 0, 2, 3, 4, 1],
  '25-355-2230': [2, 5, 3, 4, 0, 1],
  '25-355-2232': [2, 0, 2, 3, 4, 1],
  '25-355-2275': [2, 5, 3, 4, 0, 1],
  '25-355-2339': [2, 5, 3, 4, 0, 1],
  '25-355-2340': [2, 0, 2, 3, 4, 1],
  '25-355-2375': [2, 5, 3, 4, 0, 1],
  '25-355-2490': [2, 0, 2, 3, 4, 1],
  '25-355-2647': [2, 5, 3, 4, 0, 1],
  '25-355-2713': [2, 5, 3, 4, 0, 1],
  '25-355-5840': [2, 0, 2, 3, 4, 1],
  '25-355-7577': [2, 5, 3, 4, 0, 1],
  '25-355-7899': [2, 0, 2, 3, 4, 1],
  '25-355-7914': [2, 0, 2, 3, 4, 1],
  '25-355-7917': [2, 0, 2, 3, 4, 1],
  '25-356-2270': [2, 5, 3, 4, 0, 1],
  '25-356-2271': [2, 0, 2, 3, 4, 1],
  '25-356-2278': [2, 5, 3, 4, 0, 1],
  '25-356-2279': [2, 0, 2, 3, 4, 1],
  '25-356-2301': [2, 5, 3, 4, 0, 1],
  '25-356-2302': [2, 0, 2, 3, 4, 1],
  '25-356-2304': [2, 5, 3, 4, 0, 1],
  '25-356-2305': [2, 0, 2, 3, 4, 1],
  '25-356-7800': [2, 5, 3, 4, 0, 1],
  '26-178-3640': [2, 0, 1, 3, 4, 2],
  '26-178-3840': [2, 0, 1, 3, 4, 2],
  '26-178-5610': [2, 0, 1, 3, 4, 2],
  '26-178-7050': [2, 0, 1, 3, 4, 2],
  '26-178-8224': [2, 0, 1, 3, 4, 2],
  '26-214-1523': [3, 0, 1, 2, 4, 2],
  '26-214-1524': [3, 0, 1, 2, 4, 2],
  '26-214-1525': [3, 0, 1, 2, 4, 2],
  '26-214-1526': [3, 0, 1, 2, 4, 2],
  '26-214-1580': [3, 0, 1, 2, 4, 2],
  '26-214-1602': [3, 0, 1, 2, 4, 2],
  '26-214-1624': [3, 0, 1, 2, 4, 2],
  '26-215-1431': [0, 1, 2, 3, 4, 5],
  '26-215-1433': [0, 1, 2, 3, 4, 5],
  '26-215-5660': [0, 1, 2, 3, 4, 5],
  '26-216-1420': [2, 0, 1, 3, 4, 2],
  '26-216-1421': [2, 0, 1, 3, 4, 2],
  '26-216-1422': [2, 0, 1, 3, 4, 2],
  '26-216-1423': [2, 0, 1, 3, 4, 2],
  '26-216-1424': [2, 0, 1, 3, 4, 2],
  '26-216-1426': [2, 0, 1, 3, 4, 2],
  '26-216-1429': [2, 0, 1, 3, 4, 2],
  '26-216-1430': [2, 0, 1, 3, 4, 2],
  '26-216-1435': [2, 0, 1, 3, 4, 2],
  '26-216-1436': [2, 0, 1, 3, 4, 2],
  '26-216-1440': [2, 0, 1, 3, 4, 2],
  '26-216-1441': [2, 0, 1, 3, 4, 2],
  '26-216-1443': [2, 0, 1, 3, 4, 2],
  '26-216-1449': [2, 0, 1, 3, 4, 2],
  '26-216-1452': [2, 0, 1, 3, 4, 2],
  '26-216-1453': [2, 0, 1, 3, 4, 2],
  '26-216-1454': [2, 0, 1, 3, 4, 2],
  '26-216-1456': [2, 0, 1, 3, 4, 2],
  '26-216-3240': [2, 0, 1, 3, 4, 2],
  '26-216-3650': [2, 0, 1, 3, 4, 2],
  '26-216-3660': [2, 0, 1, 3, 4, 2],
  '26-216-3690': [2, 0, 1, 3, 4, 2],
  '26-216-3800': [2, 0, 1, 3, 4, 2],
  '26-216-7595': [2, 0, 1, 3, 4, 2],
  '26-216-7596': [2, 0, 1, 3, 4, 2],
  '26-216-7664': [2, 0, 1, 3, 4, 2],
  '26-216-7665': [2, 0, 1, 3, 4, 2],
  '26-216-7666': [2, 0, 1, 3, 4, 2],
  '26-216-7667': [2, 0, 1, 3, 4, 2],
  '26-216-7668': [2, 0, 1, 3, 4, 2],
  '26-216-7760': [2, 0, 1, 3, 4, 2],
  '26-216-7797': [2, 0, 1, 3, 4, 2],
  '26-216-7805': [2, 0, 1, 3, 4, 2],
  '26-216-8186': [2, 0, 1, 3, 4, 2],
  '26-216-8201': [2, 0, 1, 3, 4, 2],
  '26-216-8233': [2, 0, 1, 3, 4, 2],
  '26-217-1442': [3, 0, 1, 2, 4, 2],
  '26-217-1455': [3, 0, 1, 2, 4, 2],
  '26-217-1518': [3, 0, 1, 2, 4, 2],
  '26-217-1583': [3, 0, 1, 2, 4, 2],
  '26-217-1632': [3, 0, 1, 2, 4, 2],
  '26-217-1644': [3, 0, 1, 2, 4, 2],
  '26-217-1919': [3, 0, 1, 2, 4, 2],
  '26-217-1943': [3, 0, 1, 2, 4, 2],
  '26-217-3190': [3, 0, 1, 2, 4, 2],
  '26-217-5670': [3, 0, 1, 2, 4, 2],
  '26-217-7806': [3, 0, 1, 2, 4, 2],
  '26-218-1445': [2, 0, 1, 3, 4, 2],
  '26-218-1446': [2, 0, 1, 3, 4, 2],
  '26-218-1448': [2, 0, 1, 3, 4, 2],
  '26-218-1458': [2, 0, 1, 3, 4, 2],
  '26-218-1460': [2, 0, 1, 3, 4, 2],
  '26-218-1461': [2, 0, 1, 3, 4, 2],
  '26-218-1463': [2, 0, 1, 3, 4, 2],
  '26-218-3180': [2, 0, 1, 3, 4, 2],
  '26-218-7581': [2, 0, 1, 3, 4, 2],
  '26-218-7582': [2, 0, 1, 3, 4, 2],
  '26-219-1792': [0, 1, 2, 3, 4, 5],
  '26-219-1793': [0, 1, 2, 3, 4, 5],
  '26-219-1794': [0, 1, 2, 3, 4, 5],
  '26-219-1795': [0, 1, 2, 3, 4, 5],
  '26-219-8206': [0, 1, 2, 3, 4, 5],
  '26-220-1434': [2, 0, 1, 3, 4, 2],
  '26-220-1451': [2, 0, 1, 3, 4, 2],
  '26-220-1464': [2, 0, 1, 3, 4, 2],
  '26-220-1941': [2, 0, 1, 3, 4, 2],
  '26-220-1942': [2, 0, 1, 3, 4, 2],
  '26-220-2751': [2, 0, 1, 3, 4, 2],
  '26-220-5680': [2, 0, 1, 3, 4, 2],
  '26-220-7720': [2, 0, 1, 3, 4, 2],
  '26-220-7721': [2, 0, 1, 3, 4, 2],
  '26-220-8196': [2, 0, 1, 3, 4, 2],
  '26-220-8226': [2, 0, 1, 3, 4, 2],
  '26-220-8227': [2, 0, 1, 3, 4, 2],
  '26-221-1474': [0, 1, 2, 3, 4, 5],
  '26-221-1516': [0, 1, 2, 3, 4, 5],
  '26-221-1582': [0, 1, 2, 3, 4, 5],
  '26-221-1662': [0, 1, 2, 3, 4, 5],
  '26-221-1664': [0, 1, 2, 3, 4, 5],
  '26-221-1669': [0, 1, 2, 3, 4, 5],
  '26-221-1775': [0, 1, 2, 3, 4, 5],
  '26-221-1914': [0, 1, 2, 3, 4, 5],
  '26-221-3850': [0, 1, 2, 3, 4, 5],
  '26-221-3860': [0, 1, 2, 3, 4, 5],
  '26-221-4630': [0, 1, 2, 3, 4, 5],
  '26-222-1479': [1, 0, 2, 3, 4, 2],
  '26-222-1481': [1, 0, 2, 3, 4, 2],
  '26-222-1487': [1, 0, 2, 3, 4, 2],
  '26-222-1496': [1, 0, 2, 3, 4, 2],
  '26-222-1500': [1, 0, 2, 3, 4, 2],
  '26-222-1510': [1, 0, 2, 3, 4, 2],
  '26-222-1517': [1, 0, 2, 3, 4, 2],
  '26-222-1520': [1, 0, 2, 3, 4, 2],
  '26-222-1531': [1, 0, 2, 3, 4, 2],
  '26-222-1533': [1, 0, 2, 3, 4, 2],
  '26-222-1612': [1, 0, 2, 3, 4, 2],
  '26-222-1613': [1, 0, 2, 3, 4, 2],
  '26-222-1681': [1, 0, 2, 3, 4, 2],
  '26-222-1757': [1, 0, 2, 3, 4, 2],
  '26-222-1784': [1, 0, 2, 3, 4, 2],
  '26-222-1785': [1, 0, 2, 3, 4, 2],
  '26-222-1912': [1, 0, 2, 3, 4, 2],
  '26-222-1944': [1, 0, 2, 3, 4, 2],
  '26-222-1946': [1, 0, 2, 3, 4, 2],
  '26-222-1949': [1, 0, 2, 3, 4, 2],
  '26-222-3870': [1, 0, 2, 3, 4, 2],
  '26-222-3880': [1, 0, 2, 3, 4, 2],
  '26-222-3910': [1, 0, 2, 3, 4, 2],
  '26-222-8185': [1, 0, 2, 3, 4, 2],
  '26-222-8228': [1, 0, 2, 3, 4, 2],
  '26-307-1682': [0, 1, 2, 3, 4, 5],
  '26-307-5200': [0, 1, 2, 3, 4, 5],
  '26-307-5220': [0, 1, 2, 3, 4, 5],
  '26-307-5230': [0, 1, 2, 3, 4, 5],
  '26-307-5240': [0, 1, 2, 3, 4, 5],
  '26-307-5250': [0, 1, 2, 3, 4, 5],
  '26-307-5260': [0, 1, 2, 3, 4, 5],
  '26-307-5520': [0, 1, 2, 3, 4, 5],
  '26-307-5930': [0, 1, 2, 3, 4, 5],
  '26-307-6580': [0, 1, 2, 3, 4, 5],
  '26-307-6750': [0, 1, 2, 3, 4, 5],
  '26-307-6760': [0, 1, 2, 3, 4, 5],
  '26-307-6870': [0, 1, 2, 3, 4, 5],
  '26-307-7040': [0, 1, 2, 3, 4, 5],
  '26-307-8210': [0, 1, 2, 3, 4, 5],
  '26-307-8213': [0, 1, 2, 3, 4, 5],
  '26-308-1672': [0, 1, 2, 3, 4, 5],
  '26-308-1675': [0, 1, 2, 3, 4, 5],
  '26-308-1739': [0, 1, 2, 3, 4, 5],
  '26-308-1834': [0, 1, 2, 3, 4, 5],
  '26-308-5350': [0, 1, 2, 3, 4, 5],
  '26-308-5360': [0, 1, 2, 3, 4, 5],
  '26-308-5370': [0, 1, 2, 3, 4, 5],
  '26-308-5380': [0, 1, 2, 3, 4, 5],
  '26-308-6590': [0, 1, 2, 3, 4, 5],
  '26-308-6600': [0, 1, 2, 3, 4, 5],
  '26-308-6610': [0, 1, 2, 3, 4, 5],
  '26-308-6650': [0, 1, 2, 3, 4, 5],
  '26-308-6660': [0, 1, 2, 3, 4, 5],
  '26-308-6880': [0, 1, 2, 3, 4, 5],
  '26-309-3780': [0, 1, 2, 3, 4, 5],
  '26-309-3790': [0, 1, 2, 3, 4, 5],
  '26-309-5530': [0, 1, 2, 3, 4, 5],
  '26-309-6440': [0, 1, 2, 3, 4, 5],
  '26-309-6630': [0, 1, 2, 3, 4, 5],
  '26-309-7693': [0, 1, 2, 3, 4, 5],
  '26-310-5390': [0, 1, 2, 3, 4, 5],
  '26-310-5400': [0, 1, 2, 3, 4, 5],
  '26-311-2380': [0, 1, 2, 3, 4, 5],
  '26-311-6010': [0, 1, 2, 3, 4, 5],
  '26-311-6040': [1, 0, 2, 3, 4, 2],
  '26-311-6640': [0, 1, 2, 3, 4, 5],
  '26-311-7653': [0, 1, 2, 3, 4, 5],
  '26-311-7683': [0, 1, 2, 3, 4, 5],
  '26-312-1791': [2, 0, 1, 3, 4, 2],
  '26-312-1885': [2, 0, 1, 3, 4, 2],
  '26-312-5490': [2, 0, 1, 3, 4, 2],
  '26-312-5500': [2, 0, 1, 3, 4, 2],
  '26-312-5510': [2, 0, 1, 3, 4, 2],
  '26-312-7679': [2, 0, 1, 3, 4, 2],
  '26-313-1716': [0, 1, 2, 3, 4, 5],
  '26-313-1772': [0, 1, 2, 3, 4, 5],
  '26-313-1773': [0, 1, 2, 3, 4, 5],
  '26-313-1905': [0, 1, 2, 3, 4, 5],
  '26-313-3890': [0, 1, 2, 3, 4, 5],
  '26-313-8199': [0, 1, 2, 3, 4, 5],
  '26-313-8202': [0, 1, 2, 3, 4, 5],
  '26-314-5270': [0, 1, 2, 3, 4, 5],
  '26-314-5280': [0, 1, 2, 3, 4, 5],
  '26-314-5290': [0, 1, 2, 3, 4, 5],
  '26-314-5300': [0, 1, 2, 3, 4, 5],
  '26-314-5310': [0, 1, 2, 3, 4, 5],
  '26-314-5320': [0, 1, 2, 3, 4, 5],
  '26-314-5330': [0, 1, 2, 3, 4, 5],
  '26-315-5410': [0, 1, 2, 3, 4, 5],
  '26-315-5420': [0, 1, 2, 3, 4, 5],
  '26-315-5430': [0, 1, 2, 3, 4, 5],
  '26-315-5440': [0, 1, 2, 3, 4, 5],
  '26-315-5450': [0, 1, 2, 3, 4, 5],
  '26-315-5480': [0, 1, 2, 3, 4, 5],
  '26-316-3540': [0, 1, 2, 3, 4, 5],
  '26-316-3560': [0, 1, 2, 3, 4, 5],
  '26-316-3570': [0, 1, 2, 3, 4, 5],
  '26-316-3580': [0, 1, 2, 3, 4, 5],
  '26-316-3590': [0, 1, 2, 3, 4, 5],
  '26-316-3600': [0, 1, 2, 3, 4, 5],
  '26-316-3610': [0, 1, 2, 3, 4, 5],
  '26-316-3620': [0, 1, 2, 3, 4, 5],
  '26-316-3960': [0, 1, 2, 3, 4, 5],
  '26-316-5210': [0, 1, 2, 3, 4, 5],
  '26-316-6670': [0, 1, 2, 3, 4, 5],
  '26-316-6680': [0, 1, 2, 3, 4, 5],
  '26-316-6690': [0, 1, 2, 3, 4, 5],
  '26-316-6700': [0, 1, 2, 3, 4, 5],
  '26-316-6710': [0, 1, 2, 3, 4, 5],
  '26-316-6720': [0, 1, 2, 3, 4, 5],
  '26-316-6730': [0, 1, 2, 3, 4, 5],
  '26-316-6740': [0, 1, 2, 3, 4, 5],
  '26-316-6770': [0, 1, 2, 3, 4, 5],
  '26-316-6890': [0, 1, 2, 3, 4, 5],
  '26-316-7651': [0, 1, 2, 3, 4, 5],
  '26-316-7767': [0, 1, 2, 3, 4, 5],
  '26-316-7768': [0, 1, 2, 3, 4, 5],
  '26-316-7780': [0, 1, 2, 3, 4, 5],
  '26-316-7811': [0, 1, 2, 3, 4, 5],
  '26-316-7812': [0, 1, 2, 3, 4, 5],
  '26-316-8215': [0, 1, 2, 3, 4, 5],
  '26-316-8218': [0, 1, 2, 3, 4, 5],
  '26-317-3530': [0, 1, 2, 3, 4, 5],
  '26-317-6620': [0, 1, 2, 3, 4, 5],
  '26-317-8207': [0, 1, 2, 3, 4, 5],
  '26-318-2756': [0, 1, 2, 3, 4, 5],
  '26-318-2757': [0, 1, 2, 3, 4, 5],
  '26-319-5540': [0, 1, 2, 3, 4, 5],
  '26-324-3480': [0, 1, 2, 3, 4, 5],
  '26-324-6570': [0, 1, 2, 3, 4, 5],
  '26-327-7477': [0, 1, 2, 3, 4, 5],
  '26-327-7488': [0, 1, 2, 3, 4, 5],
  '26-327-7489': [0, 1, 2, 3, 4, 5],
  '26-327-7622': [0, 1, 2, 3, 4, 5],
  '26-327-7623': [0, 1, 2, 3, 4, 5],
  '26-327-7656': [0, 1, 2, 3, 4, 5],
  '26-327-7657': [0, 1, 2, 3, 4, 5],
  '26-327-7714': [0, 1, 2, 3, 4, 5],
  '26-327-7763': [0, 1, 2, 3, 4, 5],
  '26-327-8223': [0, 1, 2, 3, 4, 5],
  '29-239-1755': [0, 2, 3, 4, 1, 5],
  '29-239-1888': [0, 2, 3, 4, 1, 5],
  '29-328-1750': [0, 2, 3, 4, 1, 5],
  '29-328-6390': [0, 2, 3, 4, 1, 5],
  '29-329-1746': [0, 2, 3, 4, 1, 5],
  '29-329-1753': [0, 2, 3, 4, 1, 5],
  '29-329-6400': [0, 2, 3, 4, 1, 5],
  '29-330-6410': [0, 2, 3, 4, 1, 5],
  '29-330-6420': [0, 2, 3, 4, 1, 5],
  '29-330-6430': [0, 2, 3, 4, 1, 5],
  '29-331-1485': [0, 2, 3, 4, 1, 5],
  '29-331-6260': [0, 2, 3, 4, 1, 5],
  '29-331-6270': [0, 2, 3, 4, 1, 5],
  '29-332-6280': [0, 2, 3, 4, 1, 5],
  '29-332-6290': [0, 2, 3, 4, 1, 5],
  '29-332-6300': [0, 2, 3, 4, 1, 5],
  '29-332-6310': [0, 2, 3, 4, 1, 5],
  '29-332-6320': [0, 2, 3, 4, 1, 5],
  '29-332-6330': [0, 2, 3, 4, 1, 5],
  '29-332-6340': [0, 2, 3, 4, 1, 5],
  '29-332-6350': [0, 2, 3, 4, 1, 5],
  '29-332-6360': [0, 2, 3, 4, 1, 5],
  '29-332-6370': [0, 2, 3, 4, 1, 5],
  '29-338-1522': [0, 2, 3, 4, 1, 5],
  '29-338-1725': [0, 2, 3, 4, 1, 5],
  '29-338-5700': [0, 2, 3, 4, 1, 5],
  '29-338-5710': [0, 2, 3, 4, 1, 5],
  '29-338-7492': [0, 2, 3, 4, 1, 5],
  '29-338-7493': [0, 2, 3, 4, 1, 5],
  '30-240-2077': [2, 0, 1, 3, 4, 5],
  '30-240-2078': [2, 0, 1, 3, 4, 5],
  '30-240-2150': [2, 0, 1, 3, 4, 5],
  '30-240-2151': [2, 0, 1, 3, 4, 5],
  '30-240-2158': [2, 0, 1, 3, 4, 5],
  '30-240-2160': [2, 0, 1, 3, 4, 5],
  '30-240-2166': [2, 0, 1, 3, 4, 5],
  '30-240-2580': [2, 0, 1, 3, 4, 5],
  '30-240-3410': [2, 0, 1, 3, 4, 5],
  '30-240-3420': [2, 0, 1, 3, 4, 5],
  '30-240-6220': [2, 0, 1, 3, 4, 5],
  '30-240-7390': [2, 0, 1, 3, 4, 5],
  '30-240-7479': [2, 0, 1, 3, 4, 5],
  '30-240-7480': [2, 0, 1, 3, 4, 5],
  '30-240-7483': [2, 0, 1, 3, 4, 5],
  '30-240-7484': [2, 0, 1, 3, 4, 5],
  '30-240-7485': [2, 0, 1, 3, 4, 5],
  '30-240-7486': [2, 0, 1, 3, 4, 5],
  '30-240-7487': [2, 0, 1, 3, 4, 5],
  '30-240-7722': [2, 0, 1, 3, 4, 5],
  '30-240-7723': [2, 0, 1, 3, 4, 5],
  '30-240-7726': [2, 0, 1, 3, 4, 5],
  '30-240-7727': [2, 0, 1, 3, 4, 5],
  '30-240-7752': [2, 0, 1, 3, 4, 5],
  '30-240-7753': [2, 0, 1, 3, 4, 5],
  '30-240-7754': [2, 0, 1, 3, 4, 5],
  '30-240-7755': [2, 0, 1, 3, 4, 5],
  '30-240-8024': [2, 0, 1, 3, 4, 5],
  '30-240-8084': [2, 0, 1, 3, 4, 5],
  '30-240-8086': [2, 0, 1, 3, 4, 5],
  '30-240-8087': [2, 0, 1, 3, 4, 5],
  '30-240-8088': [2, 0, 1, 3, 4, 5],
  '30-240-8089': [2, 0, 1, 3, 4, 5],
  '30-240-8093': [2, 0, 1, 3, 4, 5],
  '30-240-8097': [2, 0, 1, 3, 4, 5],
  '30-240-8098': [2, 0, 1, 3, 4, 5],
  '30-240-8099': [2, 0, 1, 3, 4, 5],
  '30-240-8101': [2, 0, 1, 3, 4, 5],
  '30-240-8103': [2, 0, 1, 3, 4, 5],
  '30-241-2147': [2, 0, 1, 3, 4, 5],
  '30-241-2148': [2, 0, 1, 3, 4, 5],
  '30-241-2164': [2, 0, 1, 3, 4, 5],
  '30-241-4030': [2, 0, 1, 3, 4, 5],
  '30-241-7110': [2, 0, 1, 3, 4, 5],
  '30-241-7120': [2, 0, 1, 3, 4, 5],
  '30-241-7793': [2, 0, 1, 3, 4, 5],
  '30-241-7794': [2, 0, 1, 3, 4, 5],
  '30-241-8080': [2, 0, 1, 3, 4, 5],
  '30-241-8081': [2, 0, 1, 3, 4, 5],
  '30-241-8082': [2, 0, 1, 3, 4, 5],
  '30-241-8083': [2, 0, 1, 3, 4, 5],
  '30-242-2146': [2, 0, 1, 3, 4, 5],
  '30-242-2149': [2, 0, 1, 3, 4, 5],
  '30-242-2155': [2, 0, 1, 3, 4, 5],
  '30-242-2156': [2, 0, 1, 3, 4, 5],
  '30-242-2157': [2, 0, 1, 3, 4, 5],
  '30-242-2161': [2, 0, 1, 3, 4, 5],
  '30-242-2162': [2, 0, 1, 3, 4, 5],
  '30-242-2163': [2, 0, 1, 3, 4, 5],
  '30-242-7481': [2, 0, 1, 3, 4, 5],
  '30-242-8039': [2, 0, 1, 3, 4, 5],
  '30-242-8079': [2, 0, 1, 3, 4, 5],
  '30-242-8085': [2, 0, 1, 3, 4, 5],
  '30-242-8094': [2, 0, 1, 3, 4, 5],
  '30-242-8095': [2, 0, 1, 3, 4, 5],
  '30-242-8096': [2, 0, 1, 3, 4, 5],
  '30-242-8100': [2, 0, 1, 3, 4, 5],
  '30-242-8102': [2, 0, 1, 3, 4, 5],
  '30-242-8104': [2, 0, 1, 3, 4, 5],
  '30-243-2152': [2, 0, 1, 3, 4, 5],
  '30-243-4440': [2, 0, 1, 3, 4, 5],
  '30-243-4450': [2, 0, 1, 3, 4, 5],
  '30-243-4680': [2, 0, 1, 3, 4, 5],
  '30-243-6490': [2, 0, 1, 3, 4, 5],
  '30-276-6170': [2, 0, 1, 3, 4, 5],
  '30-276-6180': [2, 0, 1, 3, 4, 5],
  '30-276-6190': [2, 0, 1, 3, 4, 5],
  '30-276-6200': [2, 0, 1, 3, 4, 5],
  '30-276-6210': [2, 0, 1, 3, 4, 5],
  '30-276-6230': [2, 0, 1, 3, 4, 5],
  '30-276-6240': [2, 0, 1, 3, 4, 5],
  '30-276-6250': [2, 0, 1, 3, 4, 5],
  '30-276-7482': [2, 0, 1, 3, 4, 5],
  '30-357-2165': [2, 0, 1, 3, 4, 5],
  '30-357-6510': [2, 0, 1, 3, 4, 5],
  '30-357-8055': [2, 0, 1, 3, 4, 5],
  '30-358-7539': [2, 0, 1, 3, 4, 5],
  '30-358-7540': [2, 0, 1, 3, 4, 5],
  '30-358-7541': [2, 0, 1, 3, 4, 5],
  '30-358-7542': [2, 0, 1, 3, 4, 5],
  '30-358-8049': [2, 0, 1, 3, 4, 5],
  '30-358-8050': [2, 0, 1, 3, 4, 5],
  '30-358-8280': [2, 0, 1, 3, 4, 5],
  '30-358-8281': [2, 0, 1, 3, 4, 5],
  '30-358-8282': [2, 0, 1, 3, 4, 5],
  '30-359-7543': [2, 0, 1, 3, 4, 5],
  '30-359-7545': [2, 0, 1, 3, 4, 5],
  '30-359-7546': [2, 0, 1, 3, 4, 5],
  '30-359-8052': [2, 0, 1, 3, 4, 5],
  '30-359-8277': [2, 0, 1, 3, 4, 5],
  '30-359-8278': [2, 0, 1, 3, 4, 5],
  '30-359-8279': [2, 0, 1, 3, 4, 5],
  '30-360-2153': [0, 2, 1, 3, 4, 5],
  '30-360-7536': [2, 0, 1, 3, 4, 5],
  '30-360-7537': [2, 0, 1, 3, 4, 5],
  '30-360-7538': [2, 0, 1, 3, 4, 5],
  '30-360-8053': [2, 0, 1, 3, 4, 5],
  '30-361-4390': [2, 0, 1, 3, 4, 5],
  '30-361-4400': [2, 0, 1, 3, 4, 5],
  '30-361-4410': [2, 0, 1, 3, 4, 5],
  '30-361-7070': [2, 0, 1, 3, 4, 5],
  '30-361-7080': [2, 0, 1, 3, 4, 5],
  '30-361-7090': [2, 0, 1, 3, 4, 5],
  '30-361-7551': [2, 0, 1, 3, 4, 5],
  '30-361-7552': [2, 0, 1, 3, 4, 5],
  '30-361-8058': [2, 0, 1, 3, 4, 5],
  '30-361-8090': [2, 0, 1, 3, 4, 5],
  '30-361-8091': [2, 0, 1, 3, 4, 5],
  '30-361-8092': [2, 0, 1, 3, 4, 5],
  '30-361-8265': [2, 0, 1, 3, 4, 5],
  '30-361-8266': [2, 0, 1, 3, 4, 5],
  '30-361-8267': [2, 0, 1, 3, 4, 5],
  '30-361-8268': [2, 0, 1, 3, 4, 5],
  '30-361-8269': [2, 0, 1, 3, 4, 5],
  '30-361-8270': [2, 0, 1, 3, 4, 5],
  '30-361-8271': [2, 0, 1, 3, 4, 5],
  '30-361-8272': [2, 0, 1, 3, 4, 5],
  '30-361-8273': [2, 0, 1, 3, 4, 5],
  '30-361-8274': [2, 0, 1, 3, 4, 5],
  '30-361-8275': [2, 0, 1, 3, 4, 5],
  '30-361-8276': [2, 0, 1, 3, 4, 5],
  '30-362-7547': [2, 0, 1, 3, 4, 5],
  '30-362-7549': [2, 0, 1, 3, 4, 5],
  '30-362-7550': [2, 0, 1, 3, 4, 5],
  '30-362-8057': [2, 0, 1, 3, 4, 5],
  '30-362-8283': [2, 0, 1, 3, 4, 5],
  '30-362-8284': [2, 0, 1, 3, 4, 5],
  '30-362-8285': [2, 0, 1, 3, 4, 5],
  '30-363-1965': [2, 0, 1, 3, 4, 5],
  '30-363-2126': [2, 0, 1, 3, 4, 5],
  '30-363-7160': [2, 0, 1, 3, 4, 5],
  '30-363-7170': [3, 0, 1, 2, 4, 2],
  '30-363-7795': [2, 0, 1, 3, 4, 5],
  '30-363-7796': [2, 0, 1, 3, 4, 5],
  '30-363-7841': [2, 0, 1, 3, 4, 5],
  '30-363-7842': [2, 0, 1, 3, 4, 5],
  '30-363-7955': [2, 0, 1, 3, 4, 5],
  '30-363-7956': [2, 0, 1, 3, 4, 5],
  '30-363-8054': [2, 0, 1, 3, 4, 5],
  '31-247-2080': [2, 0, 1, 3, 4, 5],
  '31-247-2086': [2, 0, 1, 3, 4, 5],
  '31-247-2096': [2, 0, 1, 3, 4, 5],
  '31-247-2097': [2, 0, 1, 3, 4, 5],
  '31-247-2102': [2, 0, 1, 3, 4, 5],
  '31-247-2734': [2, 0, 1, 3, 4, 5],
  '31-247-3200': [2, 0, 1, 3, 4, 5],
  '31-247-4080': [2, 0, 1, 3, 4, 5],
  '31-247-4100': [2, 0, 1, 3, 4, 5],
  '31-247-4170': [2, 0, 1, 3, 4, 5],
  '31-248-2082': [2, 0, 1, 3, 4, 5],
  '31-248-2083': [2, 0, 1, 3, 4, 5],
  '31-248-2089': [2, 0, 1, 3, 4, 5],
  '31-248-2092': [2, 0, 1, 3, 4, 5],
  '31-248-2094': [2, 0, 1, 3, 4, 5],
  '31-248-2098': [2, 0, 1, 3, 4, 5],
  '31-248-2738': [2, 0, 1, 3, 4, 5],
  '31-248-3750': [2, 0, 1, 3, 4, 5],
  '31-248-3760': [2, 0, 1, 3, 4, 5],
  '31-248-4120': [2, 0, 1, 3, 4, 5],
  '31-248-4130': [2, 0, 1, 3, 4, 5],
  '31-248-4140': [2, 0, 1, 3, 4, 5],
  '31-248-4150': [2, 0, 1, 3, 4, 5],
  '31-248-4160': [2, 0, 1, 3, 4, 5],
  '31-248-4190': [2, 0, 1, 3, 4, 5],
  '31-248-4200': [2, 0, 1, 3, 4, 5],
  '31-248-6820': [2, 0, 1, 3, 4, 5],
  '31-248-6930': [2, 0, 1, 3, 4, 5],
  '31-248-7566': [2, 0, 1, 3, 4, 5],
  '31-248-7567': [2, 0, 1, 3, 4, 5],
  '31-248-7568': [2, 0, 1, 3, 4, 5],
  '31-248-7569': [2, 0, 1, 3, 4, 5],
  '31-248-7570': [2, 0, 1, 3, 4, 5],
  '31-248-7571': [2, 0, 1, 3, 4, 5],
  '31-248-7583': [2, 0, 1, 3, 4, 5],
  '31-248-7598': [2, 0, 1, 3, 4, 5],
  '31-248-7599': [2, 0, 1, 3, 4, 5],
  '31-248-7619': [2, 0, 1, 3, 4, 5],
  '31-248-7620': [2, 0, 1, 3, 4, 5],
  '31-248-7809': [2, 0, 1, 3, 4, 5],
  '31-248-8026': [2, 0, 1, 3, 4, 5],
  '31-248-8027': [2, 0, 1, 3, 4, 5],
  '31-248-8028': [2, 0, 1, 3, 4, 5],
  '31-248-8029': [2, 0, 1, 3, 4, 5],
  '31-248-8030': [2, 0, 1, 3, 4, 5],
  '31-249-2081': [2, 0, 1, 3, 4, 5],
  '31-249-2084': [2, 0, 1, 3, 4, 5],
  '31-249-2091': [2, 0, 1, 3, 4, 5],
  '31-249-2095': [2, 0, 1, 3, 4, 5],
  '31-249-2100': [2, 0, 1, 3, 4, 5],
  '31-249-2103': [2, 0, 1, 3, 4, 5],
  '31-249-2736': [2, 0, 1, 3, 4, 5],
  '31-249-2737': [2, 0, 1, 3, 4, 5],
  '31-249-4110': [2, 0, 1, 3, 4, 5],
  '31-249-4180': [2, 0, 1, 3, 4, 5],
  '31-249-7922': [2, 0, 1, 3, 4, 5],
  '31-249-7923': [2, 0, 1, 3, 4, 5],
  '31-249-7924': [2, 0, 1, 3, 4, 5],
  '31-249-7925': [2, 0, 1, 3, 4, 5],
  '31-249-7926': [2, 0, 1, 3, 4, 5],
  '31-249-7927': [2, 0, 1, 3, 4, 5],
  '31-249-7928': [2, 0, 1, 3, 4, 5],
  '31-249-7929': [2, 0, 1, 3, 4, 5],
  '31-249-7930': [2, 0, 1, 3, 4, 5],
  '31-249-7931': [2, 0, 1, 3, 4, 5],
  '31-320-2200': [2, 0, 1, 3, 4, 5],
  '31-320-2204': [2, 0, 1, 3, 4, 5],
  '31-320-2206': [2, 0, 1, 3, 4, 5],
  '31-320-2210': [2, 0, 1, 3, 4, 5],
  '31-320-2211': [2, 0, 1, 3, 4, 5],
  '31-320-2213': [2, 0, 1, 3, 4, 5],
  '31-320-2740': [2, 0, 1, 3, 4, 5],
  '31-320-7769': [2, 0, 1, 3, 4, 5],
  '31-320-8134': [2, 0, 1, 3, 4, 5],
  '31-320-8137': [2, 0, 1, 3, 4, 5],
  '31-320-8138': [2, 0, 1, 3, 4, 5],
  '31-320-8141': [2, 0, 1, 3, 4, 5],
  '31-320-8183': [2, 0, 1, 3, 4, 5],
  '31-321-2201': [2, 0, 1, 3, 4, 5],
  '31-321-2214': [2, 0, 1, 3, 4, 5],
  '31-321-7624': [2, 0, 1, 3, 4, 5],
  '31-321-7749': [2, 0, 1, 3, 4, 5],
  '31-322-2192': [2, 0, 1, 3, 4, 5],
  '31-322-2196': [2, 0, 1, 3, 4, 5],
  '31-322-2197': [2, 0, 1, 3, 4, 5],
  '31-322-2198': [2, 0, 1, 3, 4, 5],
  '31-322-2199': [2, 0, 1, 3, 4, 5],
  '31-322-2202': [2, 0, 1, 3, 4, 5],
  '31-322-2205': [2, 0, 1, 3, 4, 5],
  '31-322-2208': [2, 0, 1, 3, 4, 5],
  '31-322-2212': [2, 0, 1, 3, 4, 5],
  '31-322-6150': [2, 0, 1, 3, 4, 5],
  '31-322-6160': [2, 0, 1, 3, 4, 5],
  '31-322-7562': [2, 0, 1, 3, 4, 5],
  '31-322-7563': [2, 0, 1, 3, 4, 5],
  '31-322-7611': [2, 0, 1, 3, 4, 5],
  '31-322-7613': [2, 0, 1, 3, 4, 5],
  '31-322-7637': [2, 0, 1, 3, 4, 5],
  '31-322-7747': [2, 0, 1, 3, 4, 5],
  '31-322-7748': [2, 0, 1, 3, 4, 5],
  '31-322-7770': [2, 0, 1, 3, 4, 5],
  '31-322-7814': [2, 0, 1, 3, 4, 5],
  '31-322-8130': [2, 0, 1, 3, 4, 5],
  '31-322-8132': [2, 0, 1, 3, 4, 5],
  '31-322-8133': [2, 0, 1, 3, 4, 5],
  '31-322-8135': [2, 0, 1, 3, 4, 5],
  '31-322-8136': [2, 0, 1, 3, 4, 5],
  '31-322-8139': [2, 0, 1, 3, 4, 5],
  '31-322-8140': [2, 0, 1, 3, 4, 5],
  '31-322-8295': [2, 0, 1, 3, 4, 5],
  '31-322-8296': [2, 0, 1, 3, 4, 5],
  '31-322-8297': [2, 0, 1, 3, 4, 5],
  '31-322-8298': [2, 0, 1, 3, 4, 5],
  '31-322-8299': [2, 0, 1, 3, 4, 5],
  '31-322-8300': [2, 0, 1, 3, 4, 5],
  '32-252-2087': [2, 0, 1, 3, 4, 5],
  '32-252-2109': [2, 0, 1, 3, 4, 5],
  '32-252-2120': [2, 0, 1, 3, 4, 5],
  '32-252-2121': [2, 0, 1, 3, 4, 5],
  '32-252-2122': [2, 0, 1, 3, 4, 5],
  '32-252-2203': [2, 0, 1, 3, 4, 5],
  '32-252-7180': [2, 0, 1, 3, 4, 5],
  '32-252-7190': [2, 0, 1, 3, 4, 5],
  '32-252-7600': [2, 0, 1, 3, 4, 5],
  '32-252-7610': [2, 0, 1, 3, 4, 5],
  '32-252-8044': [2, 0, 1, 3, 4, 5],
  '32-252-8059': [2, 0, 1, 3, 4, 5],
  '32-252-8060': [2, 0, 1, 3, 4, 5],
  '32-252-8061': [2, 0, 1, 3, 4, 5],
  '32-252-8173': [2, 0, 1, 3, 4, 5],
  '32-253-2073': [2, 0, 1, 3, 4, 5],
  '32-253-2075': [2, 0, 1, 3, 4, 5],
  '32-253-2135': [2, 0, 1, 3, 4, 5],
  '32-253-2143': [2, 0, 1, 3, 4, 5],
  '32-253-2177': [2, 0, 1, 3, 4, 5],
  '32-253-2193': [2, 0, 1, 3, 4, 5],
  '32-253-7554': [2, 0, 1, 3, 4, 5],
  '32-253-7555': [2, 0, 1, 3, 4, 5],
  '32-253-7556': [2, 0, 1, 3, 4, 5],
  '32-253-7557': [2, 0, 1, 3, 4, 5],
  '32-253-7561': [2, 0, 1, 3, 4, 5],
  '32-253-7632': [2, 0, 1, 3, 4, 2],
  '32-253-7633': [2, 0, 1, 3, 4, 5],
  '32-253-8018': [2, 0, 1, 3, 4, 5],
  '32-253-8020': [2, 0, 1, 3, 4, 5],
  '32-253-8068': [2, 0, 1, 3, 4, 5],
  '32-253-8075': [2, 0, 1, 3, 4, 5],
  '32-253-8107': [2, 0, 1, 3, 4, 5],
  '32-253-8131': [2, 0, 1, 3, 4, 5],
  '32-253-8245': [2, 0, 1, 3, 4, 5],
  '32-253-8246': [2, 0, 1, 3, 4, 5],
  '32-253-8247': [2, 0, 1, 3, 4, 5],
  '32-253-8249': [2, 0, 1, 3, 4, 5],
  '32-253-8250': [2, 0, 1, 3, 4, 5],
  '32-253-8251': [2, 0, 1, 3, 4, 5],
  '32-253-8252': [2, 0, 1, 3, 4, 5],
  '32-253-8253': [2, 0, 1, 3, 4, 5],
  '32-253-8254': [2, 0, 1, 3, 4, 5],
  '32-253-8255': [2, 0, 1, 3, 4, 2],
  '32-253-8256': [2, 0, 1, 3, 4, 5],
  '32-253-8257': [2, 0, 1, 3, 4, 5],
  '32-253-8258': [2, 0, 1, 3, 4, 5],
  '32-253-8259': [2, 0, 1, 3, 4, 5],
  '32-253-8260': [2, 0, 1, 3, 4, 5],
  '32-253-8261': [2, 0, 1, 3, 4, 5],
  '32-253-8289': [2, 0, 1, 3, 4, 5],
  '32-253-8290': [2, 0, 1, 3, 4, 5],
  '32-253-8291': [2, 0, 1, 3, 4, 5],
  '32-253-8292': [2, 0, 1, 3, 4, 5],
  '32-253-8293': [2, 0, 1, 3, 4, 5],
  '32-253-8294': [2, 0, 1, 3, 4, 5],
  '32-256-2074': [2, 0, 1, 3, 4, 5],
  '32-256-2127': [2, 0, 1, 3, 4, 5],
  '32-256-2145': [2, 0, 1, 3, 4, 5],
  '32-256-2178': [2, 0, 1, 3, 4, 5],
  '32-256-7584': [2, 0, 1, 3, 4, 5],
  '32-256-7597': [2, 0, 1, 3, 4, 5],
  '32-256-7601': [2, 0, 1, 3, 4, 5],
  '32-256-7607': [2, 0, 1, 3, 4, 5],
  '32-256-7631': [2, 0, 1, 3, 4, 5],
  '32-256-7658': [2, 0, 1, 3, 4, 5],
  '32-256-7670': [2, 0, 1, 3, 4, 5],
  '32-256-8019': [2, 0, 1, 3, 4, 5],
  '32-256-8062': [2, 0, 1, 3, 4, 5],
  '32-256-8077': [2, 0, 1, 3, 4, 5],
  '32-256-8112': [2, 0, 1, 3, 4, 5],
  '32-256-8262': [2, 0, 1, 3, 4, 5],
  '32-256-8263': [2, 0, 1, 3, 4, 5],
  '32-256-8264': [2, 0, 1, 3, 4, 5],
  '32-323-2167': [2, 0, 1, 3, 4, 5],
  '32-323-2168': [2, 0, 1, 3, 4, 5],
  '32-323-2169': [2, 0, 1, 3, 4, 5],
  '32-323-2170': [2, 0, 1, 3, 4, 5],
  '32-323-2209': [2, 0, 1, 3, 4, 5],
  '32-323-7608': [2, 0, 1, 3, 4, 5],
  '32-323-7612': [2, 0, 1, 3, 4, 5],
  '32-323-7630': [2, 0, 1, 3, 4, 5],
  '32-323-7642': [2, 0, 1, 3, 4, 5],
  '32-323-7655': [2, 0, 1, 3, 4, 5],
  '32-323-8113': [2, 0, 1, 3, 4, 5],
  '32-323-8114': [2, 0, 1, 3, 4, 5],
  '32-323-8115': [2, 0, 1, 3, 4, 5],
  '32-323-8116': [2, 0, 1, 3, 4, 5],
  '32-323-8175': [2, 0, 1, 3, 4, 5],
  '32-323-8180': [2, 0, 1, 3, 4, 5],
  '32-339-2486': [2, 0, 1, 3, 4, 5],
  '32-339-5620': [2, 0, 1, 3, 4, 5],
  '32-339-8070': [2, 0, 1, 3, 4, 5],
  '33-257-2113': [2, 0, 1, 3, 4, 5],
  '33-257-2116': [2, 0, 1, 3, 4, 5],
  '33-257-2133': [2, 0, 1, 3, 4, 5],
  '33-257-2299': [2, 0, 1, 3, 4, 5],
  '33-257-2335': [2, 0, 1, 3, 4, 5],
  '33-257-2429': [2, 0, 1, 3, 4, 5],
  '33-257-2437': [2, 0, 1, 3, 4, 5],
  '33-257-2612': [2, 0, 1, 3, 4, 5],
  '33-257-2666': [2, 0, 1, 3, 4, 5],
  '33-257-2700': [2, 0, 1, 3, 4, 5],
  '33-257-2731': [2, 0, 1, 3, 4, 5],
  '33-257-2741': [2, 0, 1, 3, 4, 5],
  '33-257-2743': [2, 0, 1, 3, 4, 5],
  '33-257-2745': [2, 0, 1, 3, 4, 5],
  '33-257-3710': [2, 0, 1, 3, 4, 5],
  '33-257-4720': [2, 0, 1, 3, 4, 5],
  '33-257-5550': [2, 0, 1, 3, 4, 5],
  '33-257-5900': [2, 0, 1, 3, 4, 5],
  '33-257-5910': [2, 0, 1, 3, 4, 5],
  '33-257-7380': [2, 0, 1, 3, 4, 5],
  '33-257-7458': [2, 0, 1, 3, 4, 5],
  '33-257-7518': [2, 0, 1, 3, 4, 5],
  '33-257-7519': [2, 0, 1, 3, 4, 5],
  '33-257-7574': [2, 0, 1, 3, 4, 5],
  '33-257-7659': [2, 0, 1, 3, 4, 5],
  '33-257-7660': [2, 0, 1, 3, 4, 5],
  '33-257-7674': [2, 0, 1, 3, 4, 5],
  '33-257-7690': [2, 0, 1, 3, 4, 5],
  '33-257-7836': [2, 0, 1, 3, 4, 5],
  '33-257-7878': [2, 0, 1, 3, 4, 5],
  '33-257-7881': [2, 0, 1, 3, 4, 5],
  '33-258-2182': [2, 0, 1, 3, 4, 5],
  '33-258-7585': [2, 0, 1, 3, 4, 5],
  '33-258-7669': [2, 0, 1, 3, 4, 5],
  '33-260-2183': [2, 0, 1, 3, 4, 5],
  '33-260-2184': [2, 0, 1, 3, 4, 5],
  '33-260-2630': [2, 0, 1, 3, 4, 5],
  '33-260-7684': [2, 0, 1, 3, 4, 5],
  '33-260-7819': [2, 0, 1, 3, 4, 5],
  '33-260-7822': [2, 0, 1, 3, 4, 5],
  '33-260-7831': [2, 0, 1, 3, 4, 5],
  '33-260-7832': [2, 0, 1, 3, 4, 5],
  '33-261-2181': [2, 0, 1, 3, 4, 5],
  '33-261-2298': [2, 0, 1, 3, 4, 5],
  '33-261-2334': [2, 0, 1, 3, 4, 5],
  '33-261-2391': [2, 0, 1, 3, 4, 5],
  '33-261-2426': [2, 0, 1, 3, 4, 5],
  '33-261-2428': [2, 0, 1, 3, 4, 5],
  '33-261-2557': [2, 0, 1, 3, 4, 5],
  '33-261-2559': [2, 0, 1, 3, 4, 5],
  '33-261-2576': [2, 0, 1, 3, 4, 5],
  '33-261-2614': [2, 0, 1, 3, 4, 5],
  '33-261-2640': [2, 0, 1, 3, 4, 5],
  '33-261-2701': [2, 0, 1, 3, 4, 5],
  '33-261-2718': [2, 0, 1, 3, 4, 5],
  '33-261-2730': [2, 0, 1, 3, 4, 5],
  '33-261-7575': [2, 0, 1, 3, 4, 5],
  '33-261-7672': [2, 0, 1, 3, 4, 5],
  '33-261-7688': [2, 0, 1, 3, 4, 5],
  '33-261-7689': [2, 0, 1, 3, 4, 5],
  '33-261-7764': [2, 0, 1, 3, 4, 5],
  '33-261-7835': [2, 0, 1, 3, 4, 5],
  '33-261-7873': [2, 0, 1, 3, 4, 5],
  '33-261-7874': [2, 0, 1, 3, 4, 5],
  '33-261-7875': [2, 0, 1, 3, 4, 5],
  '33-261-7876': [2, 0, 1, 3, 4, 5],
  '33-261-7877': [2, 0, 1, 3, 4, 5],
  '33-261-7880': [2, 0, 1, 3, 4, 5],
  '33-261-7882': [2, 0, 1, 3, 4, 5],
  '33-261-8040': [2, 0, 1, 3, 4, 5],
  '33-261-8108': [2, 0, 1, 3, 4, 5],
  '33-261-8109': [2, 0, 1, 3, 4, 5],
  '33-261-8121': [2, 0, 1, 3, 4, 5],
  '33-262-2300': [2, 0, 1, 3, 4, 5],
  '33-262-2336': [2, 0, 1, 3, 4, 5],
  '33-262-2390': [2, 0, 1, 3, 4, 5],
  '33-262-2397': [2, 0, 1, 3, 4, 5],
  '33-262-2425': [2, 0, 1, 3, 4, 5],
  '33-262-2427': [2, 0, 1, 3, 4, 5],
  '33-262-2430': [2, 0, 1, 3, 4, 5],
  '33-262-2438': [2, 0, 1, 3, 4, 5],
  '33-262-2480': [2, 0, 1, 3, 4, 5],
  '33-262-2495': [2, 0, 1, 3, 4, 5],
  '33-262-2556': [2, 0, 1, 3, 4, 5],
  '33-262-2558': [2, 0, 1, 3, 4, 5],
  '33-262-2560': [2, 0, 1, 3, 4, 5],
  '33-262-2577': [2, 0, 1, 3, 4, 5],
  '33-262-2613': [2, 0, 1, 3, 4, 5],
  '33-262-2621': [2, 0, 1, 3, 4, 5],
  '33-262-2626': [2, 0, 1, 3, 4, 5],
  '33-262-2638': [2, 0, 1, 3, 4, 5],
  '33-262-2641': [2, 0, 1, 3, 4, 5],
  '33-262-2667': [2, 0, 1, 3, 4, 5],
  '33-262-2705': [2, 0, 1, 3, 4, 5],
  '33-262-2717': [2, 0, 1, 3, 4, 5],
  '33-262-2719': [2, 0, 1, 3, 4, 5],
  '33-262-2733': [2, 0, 1, 3, 4, 5],
  '33-262-7573': [2, 0, 1, 3, 4, 5],
  '33-262-7673': [2, 0, 1, 3, 4, 5],
  '33-262-7687': [2, 0, 1, 3, 4, 5],
  '33-262-7837': [2, 0, 1, 3, 4, 5],
  '33-262-7879': [2, 0, 1, 3, 4, 5],
  '33-262-8025': [2, 0, 1, 3, 4, 5],
  '33-262-8032': [2, 0, 1, 3, 4, 5],
  '33-262-8041': [2, 0, 1, 3, 4, 5],
  '33-262-8042': [2, 0, 1, 3, 4, 5],
  '33-262-8046': [2, 0, 1, 3, 4, 5],
  '33-262-8047': [2, 0, 1, 3, 4, 5],
  '33-262-8048': [2, 0, 1, 3, 4, 5],
  '33-262-8069': [2, 0, 1, 3, 4, 5],
  '33-262-8071': [2, 0, 1, 3, 4, 5],
  '33-262-8076': [2, 0, 1, 3, 4, 5],
  '33-262-8110': [2, 0, 1, 3, 4, 5],
  '33-262-8111': [2, 0, 1, 3, 4, 5],
  '33-262-8118': [2, 0, 1, 3, 4, 5],
  '33-262-8119': [2, 0, 1, 3, 4, 5],
  '33-262-8120': [2, 0, 1, 3, 4, 5],
  '33-262-8122': [2, 0, 1, 3, 4, 5],
  '33-262-8126': [2, 0, 1, 3, 4, 5],
  '33-262-8127': [2, 0, 1, 3, 4, 5],
  '33-262-8128': [2, 0, 1, 3, 4, 5],
  '33-262-8129': [2, 0, 1, 3, 4, 5],
  '33-262-8148': [2, 0, 1, 3, 4, 5],
  '33-263-2185': [2, 0, 1, 3, 4, 5],
  '33-263-2186': [2, 0, 1, 3, 4, 5],
  '33-263-2187': [2, 0, 1, 3, 4, 5],
  '33-263-7817': [2, 0, 1, 3, 4, 5],
  '33-263-7818': [2, 0, 1, 3, 4, 5],
  '33-263-7820': [2, 0, 1, 3, 4, 5],
  '33-263-7821': [2, 0, 1, 3, 4, 5],
  '33-263-7823': [2, 0, 1, 3, 4, 5],
  '33-263-7825': [2, 0, 1, 3, 4, 5],
  '33-263-7826': [2, 0, 1, 3, 4, 5],
  '33-263-7828': [2, 0, 1, 3, 4, 5],
  '33-263-7829': [2, 0, 1, 3, 4, 5],
  '33-263-7830': [2, 0, 1, 3, 4, 5],
  '33-264-2631': [2, 0, 1, 3, 4, 5],
  '33-264-2632': [2, 0, 1, 3, 4, 5],
  '33-264-7833': [2, 0, 1, 3, 4, 5],
  '33-264-7834': [2, 0, 1, 3, 4, 5],
  '33-264-7946': [2, 0, 1, 3, 4, 5],
  '33-264-7947': [2, 0, 1, 3, 4, 5],
  '33-265-2139': [2, 0, 1, 3, 4, 5],
  '33-265-2179': [2, 0, 1, 3, 4, 5],
  '33-265-2189': [2, 0, 1, 3, 4, 5],
  '33-265-4600': [2, 0, 1, 3, 4, 5],
  '33-265-4910': [2, 0, 1, 3, 4, 5],
  '33-265-5920': [2, 0, 1, 3, 4, 5],
  '33-265-7578': [2, 0, 1, 3, 4, 5],
  '33-265-7586': [2, 0, 1, 3, 4, 5],
  '33-265-7661': [2, 0, 1, 3, 4, 5],
  '33-265-7663': [2, 0, 1, 3, 4, 5],
  '33-265-7686': [2, 0, 1, 3, 4, 5],
  '33-266-2138': [2, 0, 1, 3, 4, 5],
  '33-266-2190': [2, 0, 1, 3, 4, 5],
  '33-266-2191': [2, 0, 1, 3, 4, 5],
  '33-266-2728': [2, 0, 1, 3, 4, 5],
  '33-266-3720': [2, 0, 1, 3, 4, 5],
  '33-266-3730': [2, 0, 1, 3, 4, 5],
  '33-266-8124': [2, 0, 1, 3, 4, 5],
  '33-266-8125': [2, 0, 1, 3, 4, 5],
  '33-266-8157': [2, 0, 1, 3, 4, 5],
  '33-267-2106': [2, 0, 1, 3, 4, 5],
  '33-267-2108': [2, 0, 1, 3, 4, 5],
  '33-267-2114': [2, 0, 1, 3, 4, 5],
  '33-267-2115': [2, 0, 1, 3, 4, 5],
  '33-267-2134': [2, 0, 1, 3, 4, 5],
  '33-267-2137': [2, 0, 1, 3, 4, 5],
  '33-267-2141': [2, 0, 1, 3, 4, 5],
  '33-267-2180': [2, 0, 1, 3, 4, 5],
  '33-267-5560': [2, 0, 1, 3, 4, 5],
  '33-267-7948': [2, 0, 1, 3, 4, 5],
  '33-267-7949': [2, 0, 1, 3, 4, 5],
  '33-267-7950': [2, 0, 1, 3, 4, 5],
  '33-267-7951': [2, 0, 1, 3, 4, 5],
  '33-267-7952': [2, 0, 1, 3, 4, 5],
  '33-267-8031': [2, 0, 1, 3, 4, 5],
  '33-267-8036': [2, 0, 1, 3, 4, 5],
  '33-267-8067': [2, 0, 1, 3, 4, 5],
  '33-267-8072': [2, 0, 1, 3, 4, 5],
  '33-267-8117': [2, 0, 1, 3, 4, 5],
  '33-267-8156': [2, 0, 1, 3, 4, 5],
  '34-269-1964': [2, 0, 1, 3, 4, 5],
  '34-269-2076': [2, 0, 1, 3, 4, 5],
  '34-269-2111': [2, 0, 1, 3, 4, 5],
  '34-269-2117': [2, 0, 1, 3, 4, 5],
  '34-269-2118': [2, 0, 1, 3, 4, 5],
  '34-269-2119': [2, 0, 1, 3, 4, 5],
  '34-269-2124': [2, 0, 1, 3, 4, 5],
  '34-269-2125': [2, 0, 1, 3, 4, 5],
  '34-269-2144': [2, 0, 1, 3, 4, 5],
  '34-269-2174': [2, 0, 1, 3, 4, 5],
  '34-269-2175': [2, 0, 1, 3, 4, 5],
  '34-269-2188': [2, 0, 1, 3, 4, 5],
  '34-269-2207': [2, 0, 1, 3, 4, 5],
  '34-269-3830': [2, 0, 1, 3, 4, 5],
  '34-269-4690': [2, 0, 1, 3, 4, 5],
  '34-269-4700': [2, 0, 1, 3, 4, 5],
  '34-269-4730': [2, 0, 1, 3, 4, 5],
  '34-269-4740': [2, 0, 1, 3, 4, 5],
  '34-269-4750': [2, 0, 1, 3, 4, 5],
  '34-269-4760': [2, 0, 1, 3, 4, 5],
  '34-269-4770': [2, 0, 1, 3, 4, 5],
  '34-269-5600': [2, 0, 1, 3, 4, 5],
  '34-269-6060': [2, 0, 1, 3, 4, 5],
  '34-269-6970': [2, 0, 1, 3, 4, 5],
  '34-269-6990': [2, 0, 1, 3, 4, 5],
  '34-269-7000': [2, 0, 1, 3, 4, 5],
  '34-269-7010': [2, 0, 1, 3, 4, 5],
  '34-269-7020': [2, 0, 1, 3, 4, 5],
  '34-269-7030': [2, 0, 1, 3, 4, 5],
  '34-269-7130': [2, 0, 1, 3, 4, 5],
  '34-269-7140': [2, 0, 1, 3, 4, 5],
  '34-269-7150': [2, 0, 1, 3, 4, 5],
  '34-269-7454': [2, 0, 1, 3, 4, 5],
  '34-269-7455': [2, 0, 1, 3, 4, 5],
  '34-269-7456': [2, 0, 1, 3, 4, 5],
  '34-269-7457': [2, 0, 1, 3, 4, 5],
  '34-269-7606': [2, 0, 1, 3, 4, 5],
  '34-269-7609': [2, 0, 1, 3, 4, 5],
  '34-269-7634': [2, 0, 1, 3, 4, 5],
  '34-269-7953': [2, 0, 1, 3, 4, 5],
  '34-269-7954': [2, 0, 1, 3, 4, 5],
  '34-269-8021': [2, 0, 1, 3, 4, 5],
  '34-269-8022': [2, 0, 1, 3, 4, 5],
  '34-269-8023': [2, 0, 1, 3, 4, 5],
  '34-269-8035': [2, 0, 1, 3, 4, 5],
  '34-269-8038': [2, 0, 1, 3, 4, 5],
  '34-269-8043': [2, 0, 1, 3, 4, 5],
  '34-269-8045': [2, 0, 1, 3, 4, 5],
  '34-269-8106': [2, 0, 1, 3, 4, 5],
  '34-269-8123': [2, 0, 1, 3, 4, 5],
  '34-269-8166': [2, 0, 1, 3, 4, 5],
  '34-269-8168': [2, 0, 1, 3, 4, 5],
  '34-269-8303': [2, 0, 1, 3, 4, 5],
  '34-269-8304': [2, 0, 1, 3, 4, 5],
  '34-269-8305': [2, 0, 1, 3, 4, 5],
  '34-269-8306': [2, 0, 1, 3, 4, 5],
  '34-269-8307': [2, 0, 1, 3, 4, 5],
  '34-270-2128': [2, 0, 1, 3, 4, 5],
  '34-270-2129': [2, 0, 1, 3, 4, 5],
  '34-270-2131': [2, 0, 1, 3, 4, 5],
  '34-270-2132': [2, 0, 1, 3, 4, 5],
  '34-270-7602': [2, 0, 1, 3, 4, 5],
  '34-270-7603': [2, 0, 1, 3, 4, 5],
  '34-270-7604': [2, 0, 1, 3, 4, 5],
  '34-270-7605': [2, 0, 1, 3, 4, 5],
  '34-270-8063': [2, 0, 1, 3, 4, 5],
  '34-270-8064': [2, 0, 1, 3, 4, 5],
  '34-270-8065': [2, 0, 1, 3, 4, 5],
  '34-270-8066': [2, 0, 1, 3, 4, 5],
  '34-270-8240': [2, 0, 1, 3, 4, 5],
  '34-270-8241': [2, 0, 1, 3, 4, 5],
  '34-270-8242': [2, 0, 1, 3, 4, 5],
  '34-271-1968': [2, 0, 1, 3, 4, 5],
  '34-271-8171': [2, 0, 1, 3, 4, 5],
  '35-272-1678': [2, 0, 1, 3, 4, 2],
  '35-272-2313': [2, 0, 1, 3, 4, 2],
  '35-272-2338': [2, 0, 1, 3, 4, 2],
  '35-272-2373': [2, 0, 1, 3, 4, 2],
  '35-272-2406': [2, 0, 1, 3, 4, 2],
  '35-272-2432': [2, 0, 1, 3, 4, 2],
  '35-272-2476': [2, 0, 1, 3, 4, 2],
  '35-272-2535': [2, 0, 1, 3, 4, 2],
  '35-272-2584': [2, 0, 1, 3, 4, 2],
  '35-272-2609': [2, 0, 1, 3, 4, 2],
  '35-272-2624': [2, 0, 1, 3, 4, 2],
  '35-272-2703': [2, 0, 1, 3, 4, 2],
  '35-272-3470': [2, 0, 1, 3, 4, 2],
  '35-272-3920': [2, 0, 1, 3, 4, 2],
  '35-272-6940': [2, 0, 1, 3, 4, 2],
  '37-282-1966': [3, 0, 1, 2, 4, 2],
  '37-282-2251': [2, 0, 1, 3, 4, 5],
  '37-282-2269': [2, 0, 1, 3, 4, 5],
  '37-282-2310': [2, 0, 1, 3, 4, 5],
  '37-282-2353': [2, 0, 1, 3, 4, 5],
  '37-282-2354': [2, 0, 1, 3, 4, 5],
  '37-282-2392': [2, 0, 1, 3, 4, 5],
  '37-282-7970': [2, 0, 1, 3, 4, 5],
  '37-282-8037': [2, 0, 1, 3, 4, 5],
  '37-282-8078': [2, 0, 1, 3, 4, 5],
  '37-282-8159': [2, 0, 1, 3, 4, 5],
  '37-282-8163': [2, 0, 1, 3, 4, 5],
};
