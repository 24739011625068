import { Component, Input } from '@angular/core';
import { IonNav, ModalController, ViewWillEnter } from '@ionic/angular';
import { ListConstants } from '@usf/ngrx-list';
import { PlatformService } from '@panamax/app-state';
import { Inventory, InventoryGroup } from '@usf/inventory-types';
import { InventoryService } from '@inventory/services/inventory.service';
import { finalize, take, tap } from 'rxjs/operators';
import { AddToInventoryStrings } from '@inventory/constants/inventory-constants';
import { filterInventory } from '@inventory/selectors/inventory-view-model.selector';
import { InventoryState } from '@usf/ngrx-inventory/lib/models/inventory/inventory.model';

@Component({
  template: '',
})
export class AddToInventoryBaseComponent implements ViewWillEnter {
  @Input() inventory: Inventory;
  @Input() productNumbers: number[];
  @Input() selectedAddToinventoryOption: AddToInventoryStrings =
    AddToInventoryStrings.chooseInventory;
  @Input() nonUSFFlag = false;

  searchKey: string;
  selectedInventory: Inventory;
  inventories: Inventory[];
  filteredInventories: Inventory[];
  groups: InventoryGroup[];
  filteredGroups: InventoryGroup[];
  group: InventoryGroup;
  groupPosition: string;

  constructor(
    public nav: IonNav,
    protected modalController: ModalController,
    readonly platformService: PlatformService,
    protected inventoryService: InventoryService,
  ) {}

  ionViewWillEnter(): void {
    if (this.nav.rootParams) {
      this.selectedInventory = this.nav.rootParams?.inventoryInput;
      this.inventory = this.nav.rootParams?.inventory;

      if (this.nav.rootParams?.groupInput) {
        this.group = this.nav.rootParams?.groupInput;
      } else if (
        this.selectedInventory === undefined ||
        this.selectedInventory === null ||
        !this.selectedInventory.groups?.includes(this.group)
      ) {
        this.group = undefined;
      }

      if (this.nav.rootParams?.groupPositionInput) {
        this.groupPosition = this.nav.rootParams?.groupPositionInput;
      } else if (!this.groupPosition) {
        this.groupPosition = AddToInventoryStrings.top;
      }
    } else if (!this.groupPosition) {
      this.groupPosition = AddToInventoryStrings.top;
    }

    this.searchKey = '';

    if (!this.inventories) {
      this.createInventories();
    } else {
      this.filteredInventories = this.inventories.slice();
      this.filteredInventories.sort(this.sortInventory);
    }

    this.setInitialValues();
  }

  get addToInventory(): typeof AddToInventoryStrings {
    return AddToInventoryStrings;
  }

  createInventories() {
    this.inventoryService
      .selectInventories$()
      .pipe(
        tap(inventories => {
          this.inventories = inventories;
          this.filteredInventories = inventories.slice();
          this.filteredInventories.sort(this.sortInventory);
        }),
        take(1),
        finalize(() => {}),
      )
      .subscribe(() => {});
  }

  getCount(inv: Inventory): number {
    if (
      inv === undefined ||
      inv === null ||
      inv.groups === undefined ||
      inv.groups === null
    ) {
      return 0;
    }
    let count = 0;
    inv.groups?.forEach(grp => (count += grp.groupItems?.length ?? 0));
    return count;
  }

  setInitialValues() {
    this.selectedInventory = this.selectedInventory
      ? this.filteredInventories.find(
          inv => inv?.inventoryName === this.selectedInventory?.inventoryName,
        )
      : null;

    if (this.selectedInventory) {
      this.groups = this.selectedInventory.groups;
      this.filteredGroups = this.groups.slice();
      this.filteredGroups.sort(this.sortGroup);
    } else {
      if (this.inventory) {
        this.selectedInventory = this.inventory;
        if (!this.groups) {
          this.setInitialGroupValues();
        } else {
          this.filteredGroups = this.groups.slice();
        }
        this.filteredGroups.sort(this.sortGroup);
      }
    }
  }

  setInitialGroupValues() {
    if (
      this.selectedInventory === undefined ||
      this.selectedInventory === null ||
      this.selectedInventory.groups === undefined ||
      this.selectedInventory.groups === null
    ) {
      this.groups = undefined;
      this.filteredGroups = undefined;
      this.group = undefined;
    } else {
      this.groups = this.selectedInventory.groups;
      this.filteredGroups = this.groups.slice();
      this.group = this.groups.find(
        group =>
          group?.groupName?.toUpperCase() ===
          ListConstants.unassignedGroup.toUpperCase(),
      );
    }
    this.groupPosition = AddToInventoryStrings.top;
  }

  changeSelectedAddToinventoryOption(
    AddToinventoryOption: AddToInventoryStrings,
  ) {
    this.selectedAddToinventoryOption = AddToinventoryOption;
    this.searchKey = '';
    this.searchInventory();
    this.searchGroup();
  }

  searchInventory() {
    if (this.inventories) {
      if (this.searchKey != '') {
        this.filteredInventories = this.inventories.filter(inventory =>
          filterInventory(inventory as InventoryState, this.searchKey),
        );
      } else {
        this.filteredInventories = this.inventories.slice();
      }
      this.filteredInventories.sort(this.sortInventory);
    }
  }

  hideSelected() {
    if (this.filteredInventories && this.selectedInventory) {
      return !this.filteredInventories.includes(this.selectedInventory);
    } else {
      return false;
    }
  }

  searchGroup() {
    if (this.groups) {
      if (this.searchKey != '') {
        this.filteredGroups = this.groups.filter(group =>
          group?.groupName
            ?.toUpperCase()
            .includes((this.searchKey || '').toUpperCase()),
        );
        this.filteredGroups.sort(this.sortGroup);
      } else {
        this.filteredGroups = this.groups.slice();
        this.filteredGroups.sort(this.sortGroup);
      }
    }
  }

  resetSearchInventory() {
    this.searchKey = '';
    if (this.inventories) {
      this.filteredInventories = this.inventories.slice();
      this.filteredInventories.sort(this.sortInventory);
    }
  }

  resetSearchGroup() {
    this.searchKey = '';
    if (this.groups) {
      this.filteredGroups = this.groups.slice();
      this.filteredGroups.sort(this.sortGroup);
    }
  }

  onChooseInventory(inv: Inventory) {
    if (this.selectedInventory?.inventoryName === inv.inventoryName) {
      this.selectedInventory = undefined;
    } else {
      this.selectedInventory = inv;
    }
    this.setInitialGroupValues();
  }

  isValid() {
    return this.selectedInventory && this.group && this.groupPosition;
  }

  onSubmit() {
    if (this.nonUSFFlag) {
      this.inventoryService.addNonUSFProductsToInventory(
        this.selectedInventory,
        this.group,
        this.productNumbers,
        this.groupPosition,
      );
    } else {
      this.inventoryService.addProductsToInventory(
        this.selectedInventory,
        this.group,
        this.productNumbers,
        this.groupPosition,
      );
    }

    this.dismissModal(true);
  }

  async dismissModal(didSubmit?: boolean) {
    await this.modalController.dismiss({ didSubmit });
  }

  sortInventory(a: Inventory, b: Inventory): number {
    if (a.inventoryName === undefined) {
      return 1;
    }
    if (b.inventoryName === undefined) {
      return -1;
    }
    if (a.inventoryName.toLowerCase() < b.inventoryName.toLowerCase()) {
      return -1;
    } else if (a.inventoryName.toLowerCase() > b.inventoryName.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  sortGroup(a: InventoryGroup, b: InventoryGroup): number {
    if (a.groupName === undefined) {
      return 1;
    }
    if (b.groupName === undefined) {
      return -1;
    }
    if (a.groupName.toLowerCase() < b.groupName.toLowerCase()) {
      return -1;
    } else if (a.groupName.toLowerCase() > b.groupName.toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }
}
