import { LoginService } from './login.service';
import { LoginDataService } from './login-data.service';
import { LoginServiceHandlerService } from './login-service-handler.service';

export const services = [
  LoginService,
  LoginDataService,
  LoginServiceHandlerService,
];

export * from './login.service';
export * from './login-data.service';
export * from './login-service-handler.service';
