import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProductPricingService } from '@usf/ngrx-pricing';
import {
  BetterBuysActions,
  selectMslProductStateLoaded,
} from '@usf/ngrx-product';
import { BetterBuy, BetterBuysResponse } from '@usf/product-types';
import { combineLatest, of } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { selectAppStateSelectedCustomer } from '../../ngrx-customer/store';
import {
  customerIsMslRestricted,
  customersAreSameContext,
} from '../../shared/helpers/customer.helpers';
import { Product } from '../../shared/models/product.model';
import { productSelector } from '../../shared/selectors/product.selectors';
import { mslProductHasSecondaries } from '../../shared/selectors/helpers/product-info.selectors.helper';
import { DownloadProductsService } from '@shared/services/document/download-products/download-products.service';
import { ListUpdatesService } from '@app/lists/services/list-updates.service';
import { selectCustomer } from '@panamax/app-state';
import { BetterBuysService } from '../services/better-buys.service';
import { ProductConversionOrigin } from '@app/lists/model/list-updates.model';
import { DownloadRequestFileType } from '@usf/list-types';
import { CurrencyService } from '@shared/services/currency.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class ClientBetterBuyEffects {
  loadPricesForBetterBuys$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BetterBuysActions.filterOutRestrictedBetterBuysSuccess),
        map(action => {
          const betterBuys = action.betterBuysResponse.betterBuys;
          const allProduct = new Set<number>();
          betterBuys.forEach(betterBuy => {
            allProduct.add(betterBuy.betterBuyProductNumber);
            allProduct.add(betterBuy.originalProductNumber);
          });

          const uniqueProducts = Array.from(allProduct);

          this.productPricingService.loadPrices(uniqueProducts);
        }),
      ),
    { dispatch: false },
  );

  filterOutRestrictedBetterBuys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BetterBuysActions.filterOutRestrictedBetterBuys),
      switchMap(action => {
        const customer$ = this.store.select(selectAppStateSelectedCustomer);
        const products$ = this.store.select(productSelector);
        const masterListItemsAreLoaded$ = this.store.select(
          selectMslProductStateLoaded,
        );
        return combineLatest([
          of(action),
          customer$,
          products$,
          masterListItemsAreLoaded$,
        ]).pipe(
          filter(([action, customer, productsMap, loaded]) => {
            if (customerIsMslRestricted(customer)) {
              return loaded;
            }
            return true;
          }),
          take(1),
          takeWhile(([action, customer, productsMap, loaded]) =>
            customersAreSameContext(action.selectedCustomer, customer),
          ),
        );
      }),
      map(([action, customer, productsMap, loaded]) => {
        let updatedResponse = action.betterBuysResponse;
        if (customerIsMslRestricted(customer)) {
          updatedResponse = this.filterOutNonMasterListPrimaryProducts(
            updatedResponse,
            productsMap,
          );
        }
        return BetterBuysActions.filterOutRestrictedBetterBuysSuccess({
          betterBuysResponse: updatedResponse,
          selectedCustomer: action.selectedCustomer,
        });
      }),
    ),
  );

  downloadProducts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BetterBuysActions.downloadProductConversion),
        // Load products and prices
        tap(action => {
          let products = [];
          action.products.forEach(element => {
            products.push(
              ...[element.originalProduct, element.replacementProduct],
            );
          });
        }),
        mergeMap(action => {
          let products = [];
          action.products.forEach(element => {
            products.push(
              ...[element.originalProduct, element.replacementProduct],
            );
          });
          return combineLatest([
            of(action),
            action.origin === ProductConversionOrigin.listUpdate
              ? this.listupdatesService.getListUpdatesWithDetails$(products)
              : this.betterBuysService.selectBetterBuysViewModel$(products),
            this.store.select(selectCustomer()),
          ]).pipe(take(1));
        }),
        tap(([action, viewModel, customer]) => {
          const noPrice = customer?.ogPrintPriceInd === 'N';
          let downloadableProducts = [];
          let reasons = [];
          let dataPairs = [];
          let spacesForUniquness = '';
          viewModel.productConversionContainers.forEach(container => {
            downloadableProducts.push({
              ...container.originalProduct,
              isOriginal: true,
            });
            downloadableProducts.push({
              ...container.replacementProduct,
              isOriginal: false,
            });
            let reason =
              this.translateService.instant(
                container.replacementProduct.reasonCode,
              ) + spacesForUniquness;
            reasons.push(reason);
            reasons.push(reason);
            let pairVal = [];
            if (!container.replacementProduct.samePackSize) {
              pairVal.push(
                this.translateService.instant(
                  'i18n.betterBuys.packSizeWarning',
                ),
              );
            } else {
              pairVal.push('');
            }

            if (!!container.replacementProduct.savings && !noPrice) {
              const savingsUom = container.replacementProduct.savingsUom;
              const perCase = this.translateService.instant(
                savingsUom === 'CS'
                  ? 'i18n.betterBuys.perCase'
                  : savingsUom === 'SV'
                    ? 'i18n.betterBuys.perServing'
                    : 'i18n.betterBuys.perPound',
              );
              pairVal.push(
                this.translateService.instant('i18n.betterBuys.save') +
                  ' ' +
                  CurrencyService.toUSDString(
                    container.replacementProduct.savings,
                  ) +
                  ' ' +
                  perCase,
              );
            } else {
              pairVal.push('');
            }

            if (!!container.replacementProduct.annualSavings && !noPrice) {
              pairVal.push(
                this.translateService.instant('i18n.betterBuys.save') +
                  ' ' +
                  CurrencyService.toUSDString(
                    container.replacementProduct.annualSavings,
                  ) +
                  ' ' +
                  this.translateService.instant('i18n.betterBuys.annually'),
              );
            } else {
              pairVal.push('');
            }

            dataPairs.push(pairVal);
            spacesForUniquness += ' ';
          });
          let fileName =
            action.origin === ProductConversionOrigin.listUpdate
              ? 'List Updates_'
              : 'Better Buys_';
          fileName += customer.customerNumber;
          this.downloadProductSerice.downloadProducts(
            action.fileType as DownloadRequestFileType,
            fileName,
            downloadableProducts,
            true,
            action.origin === ProductConversionOrigin.betterBuy,
            reasons,
            dataPairs,
          );
        }),
      ),
    {
      dispatch: false,
    },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private downloadProductSerice: DownloadProductsService,
    private listupdatesService: ListUpdatesService,
    private betterBuysService: BetterBuysService,
    private translateService: TranslateService,
    private productPricingService: ProductPricingService,
  ) {}

  filterOutNonMasterListPrimaryProducts(
    bbResponse: BetterBuysResponse,
    productsMap: Map<number, Product>,
  ): BetterBuysResponse {
    const updatedBetterBuys: BetterBuy[] = [];
    for (const betterBuy of bbResponse.betterBuys) {
      const product = productsMap.get(betterBuy.betterBuyProductNumber);
      if (
        (product && mslProductHasSecondaries(product?.mslProduct)) ||
        betterBuy?.betterBuyProductDetails?.summary?.mslUnrestrict
      ) {
        updatedBetterBuys.push(betterBuy);
      }
    }
    return { ...bbResponse, betterBuys: updatedBetterBuys };
  }
}
