export enum ReviewOrderMerchZone {
  experience = 'ReviewOrderMerchZone',
  topSellerVariant = 'ReviewOrderMerchZone-Top-Seller',
  trendingNowVariant = 'ReviewOrderMerchZone-Trending-Now',
}
export const FREQUENTLY_BOUGHT_TOGETHER_EXPERIENCE =
  'ReviewOrderFrequentlyBoughtTogether';

export const MERCH_ZONE_MAX_PRODUCTS = 12;

export const MOST_VIEWED_EXPERIENCE = 'ValidationMostViewed';
export const TRENDING_ITEMS_EXPERIENCE = 'ValidationTrendingItems';
export const SEARCHED_ALSO_PURCHASED_EXPERIENCE =
  'ValidationSearchedAlsoPurchased';
export const REPLENISHMENT_EXPERIENCE = 'ValidationReplenishment';
export const MOST_POPULAR_EXPERIENCE = 'ValidationMostPopular';

export const WIN_BACKS_FEATURE = 'revman winbacks';

export const BROWSE_TOP_SELLER_EXPERIENCE = 'BrowseTopSeller';
export const BROWSE_RECENTLY_VIEWED_EXPERIENCE = 'BrowseRecentlyViewed';
