<ng-container *ngIf="inventories?.length > 0; else notEnoughInventories">
  <ion-searchbar
    data-cy="inventory-searchbar"
    class="search"
    mode="md"
    placeholder="{{ 'i18n.inventory.searchForInventory' | translate }}"
    [(ngModel)]="searchKey"
    (ionInput)="search()"
    (ionClear)="resetSearch()"
  ></ion-searchbar>
  <ion-radio-group [(ngModel)]="selectedInventory">
    <ng-container *ngIf="filteredInventories?.length > 0; else noResultsFound">
      <app-selectable-inventory
        *ngFor="let inventory of filteredInventories"
        [inventory]="inventory"
        [isSelected]="selectedInventory === inventory"
        (selectInventoryEmitter)="inventorySelected($event)"
      ></app-selectable-inventory>
    </ng-container>
    <ng-template #noResultsFound>
      <div class="inventory-no-results-found">
        {{ 'i18n.inventory.noResultsFound' | translate }}
      </div>
    </ng-template>
  </ion-radio-group>
</ng-container>

<ng-template #notEnoughInventories>
  <div class="not-enough-inventories">
    {{
      'i18n.inventory.costComparisonReportModal.notEnoughInventories'
        | translate
    }}
  </div>
</ng-template>
