import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Resolve } from '@angular/router';
import { shareReplay } from 'rxjs/operators';

const FILE_CACHE_SIZE = 1;

@Injectable({
  providedIn: 'root',
})
export class TranslationResolverService implements Resolve<any> {
  private fileCache$: Observable<any>;

  constructor(private translateService: TranslateService) {}

  resolve(): Observable<any> {
    if (!this.fileCache$) {
      this.fileCache$ = this.getTranslations().pipe(
        shareReplay(FILE_CACHE_SIZE),
      );
    }
    return this.fileCache$;
  }

  getTranslations(): Observable<any> {
    return this.translateService.get('i18n');
  }

  clearTranslationFileCache(): void {
    this.fileCache$ = null;
  }
}
