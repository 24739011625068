<ion-header class="ion-no-border ion-header-modal-mobile">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        id="back-modal-btn"
        data-cy="back-add-to-list-input-modal-button"
        text=""
        (click)="returnToSelectedAddListActions()"
      ></ion-back-button>
    </ion-buttons>
    <ion-label data-cy="selected-add-to-list-option-text">
      {{ selectedAddToListOption }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-content-modal-mobile">
  <!-- Choose List Options -->
  <div *ngIf="selectedAddToListOption === addToList.chooseList">
    <ion-searchbar
      id="list-searchbar"
      data-cy="list-searchbar-input"
      class="search"
      mode="md"
      placeholder="{{ 'i18n.lists.searchList' | translate }}"
      [(ngModel)]="searchKey"
      (ionClear)="resetSearchList()"
      (ionInput)="searchList()"
    >
    </ion-searchbar>
    <div *ngIf="filteredLists" class="add-to-list-input-option">
      <ion-radio-group
        [(ngModel)]="lastSelectedList"
        (ionChange)="onChooseList($event)"
      >
        <ion-item-divider *ngIf="filteredLists?.length > 0"></ion-item-divider>
        <ion-item *ngFor="let list of filteredLists">
          <ion-radio
            mode="md"
            [value]="list"
            [attr.data-cy]="'list-name' + list?.listName + 'radio'"
            labelPlacement="start"
            justify="space-between"
          >
            {{ list?.listName }}
          </ion-radio>
        </ion-item>
        <div
          *ngIf="filteredLists?.length === 0 || !filteredLists"
          class="add-to-list-not-found"
        >
          <span data-cy="no-lists-show-messsage-text">
            {{ 'i18n.lists.noListsFound' | translate }}
          </span>
        </div>
      </ion-radio-group>
    </div>
    <div *ngIf="!filteredLists" class="add-to-list-input-option">
      <div class="add-to-list-not-found">
        <span data-cy="no-lists-show-text">
          {{ 'i18n.lists.noListsFound' | translate }}
        </span>
      </div>
    </div>
  </div>
  <!-- Choose Group Options -->
  <div *ngIf="selectedAddToListOption === addToList.chooseGroup">
    <ion-searchbar
      id="list-searchbar"
      data-cy="list-searchbar-input"
      class="search"
      mode="md"
      placeholder="{{ 'i18n.lists.searchGroup' | translate }}"
      [(ngModel)]="searchKey"
      (ionClear)="resetSearchGroup()"
      (ionInput)="searchGroup()"
    >
    </ion-searchbar>
    <div *ngIf="filteredGroups" class="add-to-list-input-option">
      <ion-radio-group [(ngModel)]="group" (ionChange)="onChooseGroup($event)">
        <ion-item-divider *ngIf="filteredGroups?.length > 0"></ion-item-divider>
        <ion-item *ngFor="let group of filteredGroups">
          <ion-radio
            mode="md"
            [value]="group"
            [attr.data-cy]="'group-name' + group?.groupName + 'radio'"
            labelPlacement="start"
            justify="space-between"
            >{{ group?.groupName }}</ion-radio
          >
        </ion-item>
        <div
          *ngIf="filteredGroups?.length === 0 || !filteredGroups"
          class="add-to-list-not-found"
        >
          <span data-cy="no-groups-show-messsage-text">
            {{ 'i18n.lists.noGroupsFound' | translate }}
          </span>
        </div>
      </ion-radio-group>
    </div>
    <div *ngIf="!filteredGroups" class="add-to-list-input-option">
      <div class="add-to-list-not-found">
        <span data-cy="no-groups-show-text">
          {{ 'i18n.lists.noGroupsFound' | translate }}
        </span>
      </div>
    </div>
  </div>
  <!-- Choose Position Options -->
  <div *ngIf="selectedAddToListOption === addToList.choosePosition">
    <div class="add-to-list-input-option">
      <ion-radio-group
        [(ngModel)]="groupPosition"
        (ionChange)="onChoosePosition($event)"
      >
        <ion-item-divider></ion-item-divider>
        <ion-item>
          <ion-radio
            mode="md"
            value="TOP"
            data-cy="top-of-group-radio"
            labelPlacement="start"
            justify="space-between"
            >{{ 'i18n.lists.topOfTheGroup' | translate }}</ion-radio
          >
        </ion-item>
        <ion-item>
          <ion-radio
            mode="md"
            value="BOTTOM"
            data-cy="bottom-of-group-radio"
            labelPlacement="start"
            justify="space-between"
            >{{ 'i18n.lists.bottomOfTheGroup' | translate }}</ion-radio
          >
        </ion-item>
      </ion-radio-group>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="add-to-list-cancel-button"
        class="usf-outline-green-mobile-modal-button"
        (click)="dismissModal()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        id="save-modal-btn"
        data-cy="save-add-to-list-modal-button"
        class="usf-fill-green-mobile-modal-button"
        (click)="returnToSelectedAddListActions()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
