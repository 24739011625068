import { TracingTransformer, TrackingData } from '@panamax/app-state';

export const genericTracingTransformer: TracingTransformer = (
  trackingData: TrackingData,
) => {
  const {
    traceContext,
    isStartOfTrace,
    isEndOfTrace,
    ...rest
  } = trackingData.data;

  return {
    traceContext,
    isStartOfTrace,
    isEndOfTrace,
    ...rest.attributes,
  };
};
