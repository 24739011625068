import { Dictionary } from '@ngrx/entity';
import {
  IMasterListGroupState,
  IMasterListItemProductState,
  ListConstants,
  ListSearchSortFilterOptions,
  MasterListItemProductState,
  MasterListState,
} from '@usf/ngrx-list';
import { Product } from '@usf/product-types';
import { PlatformEnum } from '../../../../../shared/constants/platform-type.enum';
import {
  GroupRow,
  ItemTypes,
  ListRow,
  ProductRow,
} from '../../../../shared/list-detail-management/model/list-detail-management-view.model';
import {
  calculateItemHeightsAndHotkeyIds,
  productIsInSearchAndFilter,
  sortListRowsAlphabetically,
} from '../../../../shared/list-detail-management/selectors/helpers/list-detail-management.selectors.helper';
import {
  ListTypes,
  MSL_MAX_SELECTABLE_PRODUCTS_FOR_COMPARISON,
} from '../../../../../shared/constants/lists-constants';

export class MasterListSelectorHelpers {
  static createMasterListViewModel(
    masterList: MasterListState,
    masterListGroupState: IMasterListGroupState,
    masterListItemState: IMasterListItemProductState,
    products: Map<number, Product>,
    platformType: PlatformEnum,
    listSearchSortFilterOptions: ListSearchSortFilterOptions,
  ) {
    // Creates baseItems which is all groups with respective primary products
    // Following three values are needed for sort modal
    // groupNames - string array of all group names
    // productClasses - string array of all productClasses
    // itemsInClasses - dictionary of all products that belong to that specific class
    const { baseItems, groupNames, productClasses, itemsInClasses } =
      createBaseItemsGroupsAndProductClasses(
        masterList,
        masterListGroupState,
        masterListItemState,
        products,
      );

    // Construct list rows based on sort, filters and search term
    let sortedItems: ListRow[] = [];
    sortedItems = sortAndFilterItems(
      baseItems,
      listSearchSortFilterOptions,
      productClasses,
      itemsInClasses,
    );

    // After sorting and filtering need to append secondaries
    // Returns - sorted items with their secondaries
    // - secondariesExist (if some product in these items have secondaries)
    // - showingAllSecondaries (if we are currently displaying all secondaries)
    const {
      sortedItemsWithSecondaries,
      secondariesExist,
      showingAllSecondaries,
      selectedProducts,
    } = calculateItemsWithTheirSecondaries(
      sortedItems,
      masterListItemState,
      products,
    );

    // After creating final view model, need to calculate their heights and possible desktopIds
    const { itemHeights, productCardIds } = calculateItemHeightsAndHotkeyIds(
      platformType,
      sortedItemsWithSecondaries,
      ListTypes.masterList,
    );

    return {
      items: sortedItemsWithSecondaries,
      secondariesExist,
      showingAllSecondaries,
      itemHeights,
      productCardIds,
      productClasses,
      groupNames,
      selectedProducts,
    };
  }
}

const createBaseItemsGroupsAndProductClasses = (
  masterList: MasterListState,
  masterListGroupState: IMasterListGroupState,
  masterListItemState: IMasterListItemProductState,
  products: Map<number, Product>,
) => {
  let baseItems: ListRow[] = [];
  let groupNames: string[] = [];
  let productClasses: string[] = [];
  let itemsInClasses: Dictionary<ProductRow[]> = {};
  let sequence = 1;
  // Loop through all groups for this master list
  masterList?.listGroupIds?.forEach(groupId => {
    let groupAndItemRows: ListRow[] = [];
    let groupItemCount = 0;
    const masterListGroupKey = createMasterListGroupKey(
      masterList?.listId,
      groupId,
    );
    const masterListGroup = masterListGroupState?.entities[masterListGroupKey];
    // Loop through item ids for this group
    masterListGroup?.listItemIds.forEach(itemId => {
      let productHasAlternatesToShow = false;
      const masterListItemKey = createMasterListItemKey(
        masterList?.listId,
        groupId,
        itemId,
      );
      let masterListItemProductKeys = [];
      // Get itemProduct keys that we save in state so we can get all products for this item
      if (masterListItemState.itemKeyProductKeysDict) {
        masterListItemProductKeys = masterListItemState.itemKeyProductKeysDict[
          masterListItemKey
        ] as string[];
      }

      if (masterListItemProductKeys?.length > 1) {
        productHasAlternatesToShow = true;
      }

      // At least one qualifying product exists for this item
      if (masterListItemProductKeys?.length > 0) {
        // Grab the itemProduct from the first key which is the primary product
        const masterListItemPrimaryProduct =
          masterListItemState?.entities[masterListItemProductKeys[0]];
        const primaryProduct = products.get(
          masterListItemPrimaryProduct.productNumber,
        );
        const masterListProductRow = createMasterListProductRow(
          primaryProduct,
          masterListGroup?.groupName,
          masterListGroup?.listGroupId?.toString(),
          masterListItemPrimaryProduct,
          true,
          productHasAlternatesToShow,
          sequence,
          masterListItemPrimaryProduct.isComparingWithAlternates,
        );
        sequence++;
        groupAndItemRows.push(masterListProductRow);
        groupItemCount++;
        // Add product class if this product has one
        const productClass = findProductClass(masterListProductRow);
        if (productClass) {
          if (!productClasses.includes(productClass)) {
            productClasses.push(productClass);
            itemsInClasses[productClass] = [masterListProductRow];
          } else {
            itemsInClasses[productClass].push(masterListProductRow);
          }
        }
      }
    });

    if (masterListGroup?.groupName) {
      groupNames.push(masterListGroup.groupName);
    }
    // Only add group if it has any items
    if (groupItemCount > 0) {
      const masterListGroupRow = createGroupRow(
        masterListGroup?.groupName,
        groupItemCount,
      );
      groupAndItemRows.unshift(masterListGroupRow);
    }
    baseItems = baseItems.concat(groupAndItemRows);
  });

  // Sort classes so they are in alphabetical order
  productClasses.sort((a, b) => (a > b ? 1 : -1));

  return {
    baseItems,
    groupNames,
    productClasses,
    itemsInClasses,
  };
};

const findProductClass = (productRow: ProductRow): string | undefined => {
  let productClass = undefined;
  if (productRow?.summary?.classDescription) {
    productClass = productRow?.summary?.classDescription;
  }
  return productClass;
};

export const sortAndFilterItems = (
  baseItems: ListRow[],
  listSearchSortFilterOptions: ListSearchSortFilterOptions,
  productClasses: string[],
  itemsInClasses: Dictionary<ProductRow[]>,
) => {
  let sortedItems: ListRow[] = [];
  if (
    listSearchSortFilterOptions.sortType === ListConstants.groupLine ||
    listSearchSortFilterOptions.sortType === ListConstants.groupAlpha
  ) {
    sortedItems = groupSort(baseItems, listSearchSortFilterOptions);
  } else if (
    listSearchSortFilterOptions.sortType === ListConstants.alphabetical
  ) {
    sortedItems = alphabeticalSort(baseItems, listSearchSortFilterOptions);
  } else if (
    listSearchSortFilterOptions.sortType === ListConstants.usFoodsClass
  ) {
    sortedItems = productClassSort(
      listSearchSortFilterOptions,
      productClasses,
      itemsInClasses,
    );
  }

  return sortedItems;
};

const groupSort = (
  baseItems: ListRow[],
  listSearchSortFilterOptions: ListSearchSortFilterOptions,
) => {
  let sortedItems: ListRow[] = [];
  let currentGroup: GroupRow;
  let currentGroupCount = 0;
  let setOfItems: ListRow[] = [];
  // Loop through all groups and primaries in this list
  for (const item of baseItems) {
    if (item?.itemType === ItemTypes.group) {
      // If we are on a group and we just finished going over a group that has at least 1 qualifying product
      if (currentGroup && currentGroupCount > 0) {
        // Add group and items and reset this current iteration of items and group
        const groupToAdd: GroupRow = {
          ...currentGroup,
          itemCount: currentGroupCount,
        };
        if (listSearchSortFilterOptions.sortType === ListConstants.groupAlpha) {
          setOfItems = sortListRowsAlphabetically(setOfItems);
        }
        setOfItems.unshift(groupToAdd);
        sortedItems = sortedItems.concat(setOfItems);
        setOfItems = [];
        currentGroup = undefined;
        currentGroupCount = 0;
      }
      const groupRow = item as GroupRow;
      const sortAllGroups =
        listSearchSortFilterOptions.groupToSort === ListConstants.allGroups;
      const sortThisGroup =
        groupRow?.groupName === listSearchSortFilterOptions.groupToSort;
      // Assign the current group and continue looping
      if (sortAllGroups || sortThisGroup) {
        currentGroup = groupRow;
        currentGroupCount = 0;
      }
    }
    if (item?.itemType === ItemTypes.product) {
      const productRow = item as ProductRow;
      // If we have set current group for these items see if we should append them
      if (currentGroup) {
        if (
          productIsInSearchAndFilter(productRow, listSearchSortFilterOptions)
        ) {
          setOfItems.push(productRow);
          currentGroupCount++;
        }
      }
    }
  }
  // After finishing looping through last group, need to see if we should append the last one
  if (currentGroup && currentGroupCount > 0) {
    // Add group and items and reset this current iteration of items and group
    const groupToAdd: GroupRow = {
      ...currentGroup,
      itemCount: currentGroupCount,
    };
    if (listSearchSortFilterOptions.sortType === ListConstants.groupAlpha) {
      setOfItems = sortListRowsAlphabetically(setOfItems);
    }
    setOfItems.unshift(groupToAdd);
    sortedItems = sortedItems.concat(setOfItems);
  }
  return sortedItems;
};

const productClassSort = (
  listSearchSortFilterOptions: ListSearchSortFilterOptions,
  productClasses: string[],
  itemsInClasses: Dictionary<ProductRow[]>,
) => {
  let sortedItems: ListRow[] = [];
  // Loop through all product classes that exist for all products on this list
  for (const productClass of productClasses) {
    let setOfItems: ListRow[] = [];
    let currentGroupCount = 0;
    const sortAllGroups =
      listSearchSortFilterOptions.groupToSort === ListConstants.allGroups;
    const sortThisGroup =
      productClass === listSearchSortFilterOptions.groupToSort;
    if (sortAllGroups || sortThisGroup) {
      // Loop through all products that belong to this class
      for (const item of itemsInClasses[productClass]) {
        const productRow = {
          ...item,
          groupName: productClass,
          groupId: productClass,
        } as ProductRow;
        if (
          productIsInSearchAndFilter(productRow, listSearchSortFilterOptions)
        ) {
          setOfItems.push(productRow);
          currentGroupCount++;
        }
      }
      setOfItems = sortListRowsAlphabetically(setOfItems);
      if (currentGroupCount > 0) {
        const groupRow = createGroupRow(productClass, currentGroupCount);
        setOfItems.unshift(groupRow);
        sortedItems = sortedItems.concat(setOfItems);
      }
    }
  }
  return sortedItems;
};

const alphabeticalSort = (
  baseItems: ListRow[],
  listSearchSortFilterOptions: ListSearchSortFilterOptions,
) => {
  let sortedItems: ListRow[] = [];
  let currentGroupCount = 0;
  // Loop through all items for this liist
  for (const item of baseItems) {
    if (item?.itemType === ItemTypes.product) {
      const productRow = {
        ...item,
        groupName: 'Alphabetical',
        groupId: 'Alphabetical',
      } as ProductRow;
      if (productIsInSearchAndFilter(productRow, listSearchSortFilterOptions)) {
        sortedItems.push(productRow);
        currentGroupCount++;
      }
    }
  }
  sortedItems = sortListRowsAlphabetically(sortedItems);
  if (currentGroupCount > 0) {
    const groupRow = createGroupRow(
      ListConstants.alphabetical,
      currentGroupCount,
    );
    sortedItems.unshift(groupRow);
  }
  return sortedItems;
};

const calculateItemsWithTheirSecondaries = (
  sortedItems: ListRow[],
  masterListItemState: IMasterListItemProductState,
  products: Map<number, Product>,
) => {
  let secondariesExist = false;
  let showingAllSecondaries = true;
  let sortedItemsWithSecondaries: ListRow[] = [];
  let selectedProducts: ProductRow[] = [];

  // Loop through all items that passed search and sort
  for (const item of sortedItems) {
    sortedItemsWithSecondaries.push(item);
    if (item?.itemType === ItemTypes.product) {
      const primaryProductRow = item as ProductRow;
      const masterListItemKey = createMasterListItemKey(
        primaryProductRow?.masterListItem?.listId,
        primaryProductRow?.masterListItem?.listGroupId,
        primaryProductRow?.masterListItem?.listItemId,
      );
      let masterListItemProductKeys = [];
      if (masterListItemState.itemKeyProductKeysDict) {
        masterListItemProductKeys = masterListItemState.itemKeyProductKeysDict[
          masterListItemKey
        ] as string[];
      }

      if (masterListItemProductKeys?.length > 1) {
        secondariesExist = true;
        if (
          primaryProductRow.masterListItem &&
          primaryProductRow.masterListItem.isComparingWithAlternates
        ) {
          primaryProductRow.masterListItem.isShowingAlternates = true;
          selectedProducts.push(primaryProductRow);
        }
        if (!primaryProductRow?.masterListItem?.isShowingAlternates) {
          showingAllSecondaries = false;
        }
      }
      if (primaryProductRow?.masterListItem?.isShowingAlternates) {
        const secondaryProducts: ProductRow[] = [];
        const selectedProductsQty = primaryProductRow?.masterListItem
          ?.isComparingWithAlternates
          ? Object.values(masterListItemState?.selections).filter(
              value => value === true,
            ).length + 1
          : 0; // Primary product is always selected if comparing with alternates
        let sequence = primaryProductRow?.sequenceNumber;
        // Loop through remaining keys which will be all the secondary products
        for (let i = 1; i < masterListItemProductKeys?.length; i++) {
          const masterListItemSecondaryProduct =
            masterListItemState?.entities[masterListItemProductKeys[i]];
          const secondaryProduct = products.get(
            masterListItemSecondaryProduct.productNumber,
          );
          sequence = sequence + 0.1;
          sequence = parseFloat(sequence.toFixed(1));
          const selected =
            masterListItemState?.selections[masterListItemProductKeys[i]];
          const disabled =
            !selected &&
            selectedProductsQty >= MSL_MAX_SELECTABLE_PRODUCTS_FOR_COMPARISON;
          const secondaryProductRow = createMasterListProductRow(
            secondaryProduct,
            primaryProductRow.groupName,
            primaryProductRow.groupId,
            masterListItemSecondaryProduct,
            false,
            false,
            sequence,
            primaryProductRow.masterListItem.isComparingWithAlternates, // Alternate value should be true if primary product is comparing with its alternates
            selected,
            disabled,
          );
          secondaryProducts.push(secondaryProductRow);
          if (selected) {
            selectedProducts.push(secondaryProductRow);
          }
        }
        // Keys are in order of orderability/substitute master list logic defined in R4LA-2279,
        // for master list page we need them in user defined priority (product priority on masterListItem set by user)
        secondaryProducts.sort((a, b) => {
          return a?.masterListItem?.productPriority >
            b?.masterListItem?.productPriority
            ? 1
            : -1;
        });
        // Need to set last secondary product so we can show divider before the next product/group in the list
        let lastSecondaryProduct =
          secondaryProducts[secondaryProducts.length - 1];
        lastSecondaryProduct = {
          ...lastSecondaryProduct,
          isLastSecondary: true,
        };
        secondaryProducts[secondaryProducts.length - 1] = lastSecondaryProduct;
        sortedItemsWithSecondaries =
          sortedItemsWithSecondaries.concat(secondaryProducts);
      }
    }
  }

  return {
    sortedItemsWithSecondaries,
    secondariesExist,
    showingAllSecondaries,
    selectedProducts,
  };
};

const createGroupRow = (groupName: string, itemCount: number): GroupRow => {
  return {
    itemType: ItemTypes.group,
    groupName,
    itemCount,
  } as GroupRow;
};

const createMasterListProductRow = (
  product: Product,
  groupName: string,
  groupId: string,
  masterListItem: MasterListItemProductState,
  isPrimaryProduct: boolean,
  hasAlternatesToShow: boolean,
  sequence: number,
  isComparingWithAlternates: boolean,
  selected?: boolean,
  disabled?: boolean,
): ProductRow => {
  return {
    ...product,
    isPrimaryProduct,
    hasAlternatesToShow,
    itemType: ItemTypes.product,
    masterListItem: {
      ...masterListItem,
      isComparingWithAlternates: isComparingWithAlternates,
    },
    groupName,
    groupId,
    sequenceNumber: sequence,
    listId: String(masterListItem?.listId),
    selected: selected || (isComparingWithAlternates && isPrimaryProduct),
    disabled: disabled || (isComparingWithAlternates && isPrimaryProduct),
  } as ProductRow;
};

export const createMasterListItemKey = (
  listId: number,
  listGroupId: number,
  listItemId: number,
): string => {
  return listId + '-' + listGroupId + '-' + listItemId;
};

export const createMasterListItemProductKey = (
  listId: number,
  listGroupId: number,
  listItemId: number,
  listItemProductId: number,
): string => {
  return (
    listId + '-' + listGroupId + '-' + listItemId + '-' + listItemProductId
  );
};

export const createMasterListGroupKey = (
  listId: number,
  listGroupId: number,
): string => {
  return listId + '-' + listGroupId;
};
