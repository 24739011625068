/* eslint-disable no-shadow */
export enum BetterBuysConstants {
  accepted = 'A',
  declined = 'Y',
  acceptLMASuccessWithMuleFail = 'List SL item replacement succeeded but Mulesoft request failed!',
  acceptSuccessWithMuleFail = 'Mulesoft request failed!',
  betterBuys = 'better buys',
  listsCapability = 'lists',
  productCapability = 'pdp',
}
