import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { SHARESHEET_CONSTANTS } from '@shared/constants/sharesheet-constants';
import { Share, ShareOptions } from '@capacitor/share';
import { filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerStoreService } from '@app/ngrx-customer/services';

export class VersionError extends Error {}
export class DeviceNotAvailableError extends Error {}
@Injectable({
  providedIn: 'root',
})
export class ShareSheetService {
  constructor(private customerStoreService: CustomerStoreService) {}

  buildDeeplink(source: string, originLinkType: string, params?: string[]) {
    let customerNumber: number;
    let divisionNumber: number;
    let departmentNumber: string;
    this.customerStoreService
      .loadSelectedCustomer$()
      ?.pipe(
        filter(customer => !!customer),
        take(1),
      )
      ?.subscribe(c => {
        customerNumber = c.customerNumber;
        divisionNumber = c.divisionNumber;
        if (!!c.departmentNumbers) {
          departmentNumber = c?.departmentNumbers[0] ?? '0';
        } else {
          departmentNumber = '0';
        }
      });
    let resultURL =
      environment.deeplinkUrl +
      SHARESHEET_CONSTANTS.ORIGIN +
      source +
      SHARESHEET_CONSTANTS.ORIGIN_LINK_TYPE +
      originLinkType +
      SHARESHEET_CONSTANTS.CUSTOMER_NUMBER +
      customerNumber +
      SHARESHEET_CONSTANTS.DIVISION_NUMBER +
      divisionNumber +
      SHARESHEET_CONSTANTS.DEPARTMENT_NUMBER +
      departmentNumber;

    if (params) {
      params?.forEach(param => {
        resultURL += param;
      });
    }

    return resultURL;
  }

  // to add desktop support, move share out of native check if statement
  async share(options: ShareOptions) {
    if (Capacitor.isNativePlatform()) {
      if (!Capacitor.isPluginAvailable('Share')) {
        return Promise.reject(new VersionError());
      }
      Share.share(options)
        .then(() => {
          return Promise.resolve();
        })
        .catch(() => {
          return Promise.reject(new Error());
        });
    } else {
      return Promise.reject(new DeviceNotAvailableError());
    }
  }
}
