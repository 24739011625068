import { Component } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { ListsService } from 'src/app/lists/services/lists.service';
import { PlatformService } from '@panamax/app-state';
import { CreateListBaseComponent } from '../create-list-base.component';
import { CreateListInputComponent } from './create-list-input/create-list-input.component';
import { CreateListOptions } from '../../../../shared/constants/lists-constants';

@Component({
  selector: 'app-create-list-touch',
  templateUrl: './create-list-touch.component.html',
  styleUrls: ['./create-list-touch.component.scss'],
})
export class CreateListTouchComponent extends CreateListBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    listsService: ListsService,
  ) {
    super(nav, modalController, platformService, listsService);
  }

  onSelectCreateListOption(listOption: CreateListOptions) {
    this.selectedCreateListOption = listOption;
    this.nav.push(CreateListInputComponent, {
      listData: this.listData,
      selectedCreateListOption: this.selectedCreateListOption,
    });
  }
}
