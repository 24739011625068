import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UserActions } from '@app/user/store/actions/action-types';
import { Capacitor } from '@capacitor/core';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  PlatformEnum,
  PlatformService,
  UsabillaService,
} from '@panamax/app-state';
import { User } from '@usf/user-types/user';
import { combineLatest, Subscription } from 'rxjs';
import {
  getActiveCustomersByUserId,
  getActiveCustomersByUserIdSuccess,
} from '@app/ngrx-customer/store/actions';
import { getCustomers, selectedCustomer } from '@app/ngrx-customer/store';
import { Customer } from '@usf/customer-types';

@Component({
  selector: 'app-invite-existing-user-modal',
  templateUrl: './invite-existing-user-modal.component.html',
  styleUrls: ['./invite-existing-user-modal.component.scss'],
})
export class InviteExistingUserModalComponent
  implements OnInit, OnDestroy, ViewWillEnter
{
  @Output() selectExistingUser = new EventEmitter<any>();
  @Output() dismissEvent: EventEmitter<void> = new EventEmitter();
  @Output() mobileExistingUserInfoSelected = new EventEmitter<boolean>();
  @Output() mobileExistingCustomersSelected = new EventEmitter<boolean>();

  SECTIONS = ['userInformation', 'customers'];
  MAX_CUSTOMERS = 100;

  searchKey = '';
  selectedOption = '';

  customers = [];
  searchedCustomers = [];
  selectedCustomer: Customer;

  result: {
    existingUser: {
      currentValue: User;
      savedValue: User;
      isUserSelected: boolean;
      wasUserSelected: boolean;
    };
    customers: {
      currentValue: { customerNumber: number; divisionNumber: number }[];
      savedValue: { customerNumber: number; divisionNumber: number }[];
    };
  } = {
    existingUser: {
      currentValue: null,
      savedValue: null,
      isUserSelected: false,
      wasUserSelected: false,
    },
    customers: { currentValue: [], savedValue: [] },
  };

  userSearch$: Subscription = new Subscription();
  selectedUserCustomers$: Subscription = new Subscription();
  customers$: Subscription = new Subscription();

  isLoading = true;
  customersMaxLimitReached = false;
  userHasSearched = false;

  constructor(
    readonly modalController: ModalController,
    public platform: PlatformService,
    public translateService: TranslateService,
    public store: Store,
    private usabillaService: UsabillaService,
    private actionSubject: ActionsSubject,
  ) {}

  ngOnInit() {
    if (!Capacitor.isNativePlatform()) {
      this.usabillaService.hide();
    }

    this.selectedOption =
      this.platform.platformType === PlatformEnum.mobile
        ? ''
        : 'userInformation';

    this.userSearch$ = this.actionSubject
      .pipe(ofType(UserActions.searchUserByUsernameSuccess))
      .subscribe(data => {
        this.result.existingUser.currentValue = data['user'];
        if (data['user']?.userName == '') {
          this.clearUser();
        }
        this.isLoading = false;
      });

    this.customers$ = combineLatest([
      this.store.select(getCustomers),
      this.store.select(selectedCustomer),
    ]).subscribe(([customers, selectedCustomer]) => {
      this.selectedCustomer = selectedCustomer;
      const selectedCustomerNumber =
        selectedCustomer?.customerNumber?.toString();
      this.result.customers.currentValue = selectedCustomerNumber
        ? [
            {
              customerNumber: selectedCustomer.customerNumber,
              divisionNumber: selectedCustomer.divisionNumber,
            },
          ]
        : [];
      this.customers = customers.map(customer => {
        const customerNumberStr = customer.customerNumber.toString();
        const isSelected = this.result.customers.currentValue.some(
          cust => cust.customerNumber === customer.customerNumber,
        );
        return {
          ...customer,
          selected: customerNumberStr === selectedCustomerNumber || isSelected,
          disabled: false,
        };
      });

      this.searchedCustomers = this.customers;
      this.isLoading = false;
    });

    this.selectedUserCustomers$ = this.actionSubject
      .pipe(ofType(getActiveCustomersByUserIdSuccess))
      .subscribe(data => {
        const userCustomerIds = data?.userCustomer?.customerIds || [];
        const selectedCustomerNumbers = userCustomerIds
          .filter((customer: any) => customer.selected)
          .map((customer: any) => ({
            customerNumber: customer.customerNumber,
            divisionNumber: customer.divisionNumber,
          }));

        this.customers = this.customers.map(customer => {
          const isSelected = selectedCustomerNumbers.some(
            cust => cust.customerNumber === customer.customerNumber,
          );
          const isDisabled = selectedCustomerNumbers.some(
            cust => cust.customerNumber === customer.customerNumber,
          );
          if (
            customer.customerNumber === this.selectedCustomer?.customerNumber
          ) {
            return {
              ...customer,
              selected: true,
              disabled: isDisabled,
            };
          }

          return {
            ...customer,
            selected: isSelected,
            disabled: isDisabled,
          };
        });
        this.searchedCustomers = this.customers;

        this.result.customers.currentValue =
          this.result.customers.currentValue.filter(cust =>
            this.customers.some(
              customer =>
                customer.customerNumber === cust.customerNumber &&
                !customer.disabled,
            ),
          );
        if (
          selectedCustomerNumbers.length &&
          !this.result.customers.currentValue.some(
            cust =>
              cust.customerNumber === this.selectedCustomer?.customerNumber,
          ) &&
          !this.customers.find(
            c => c.customerNumber === this.selectedCustomer?.customerNumber,
          )?.disabled
        ) {
          this.result.customers.currentValue.push({
            customerNumber: this.selectedCustomer.customerNumber,
            divisionNumber: this.selectedCustomer.divisionNumber,
          });
        }

        this.selectExistingUser.emit({
          user: this.result.existingUser.isUserSelected
            ? this.result.existingUser.currentValue
            : null,
          customers: this.result.customers.currentValue,
          isUserSelected: this.result.existingUser.isUserSelected,
          resetCustomers: this.result?.customers?.currentValue?.length === 0,
          saveCustomers:
            this.result?.customers?.savedValue?.length === 0 &&
            this.result?.customers?.currentValue?.length > 0,
        });

        this.isLoading = false;

        if (this.result?.customers?.currentValue?.length === 0) {
          this.result.customers.savedValue = [];
        } else {
          this.result.customers.savedValue = [
            ...this.result.customers.currentValue,
          ];
        }

        if (this.searchedCustomers.length === 0) {
          this.clearUser();
        }
      });
  }

  handleInput(event: any) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^a-zA-Z0-9-_]/g, '');
    this.searchKey = input.value;
    if (event.key === 'Enter' || event.keyCode === 13) {
      this.searchCustomer(event);
    }
  }

  searchCustomer(event: any) {
    this.searchKey = event?.target?.value || '';
    if (this.searchKey !== '') {
      this.searchedCustomers = this.customers
        .filter(
          c =>
            c.customerName
              .toLowerCase()
              .includes(this.searchKey.toLowerCase()) ||
            c.customerNumber.toString().includes(this.searchKey),
        )
        .map(c => ({
          ...c,
          disabled: c.disabled,
          selected: c.selected,
        }));
    } else {
      this.searchedCustomers = [...this.customers];
    }
  }

  isCustomerChecked(customer: any) {
    return (
      this.result?.customers?.currentValue?.indexOf(
        customer?.customerNumber?.toString(),
      ) > -1
    );
  }

  toggleCustomerSelection(customer: any, event: Event): void {
    event.stopPropagation();
    if (customer.disabled) {
      return;
    }

    const isSelected = !customer.selected;

    this.customers = this.customers.map(c => {
      if (c.customerNumber === customer.customerNumber) {
        return { ...c, selected: isSelected };
      }
      return c;
    });

    this.searchedCustomers = this.searchedCustomers.map(c => {
      if (c.customerNumber === customer.customerNumber) {
        return { ...c, selected: isSelected };
      }
      return c;
    });

    if (isSelected) {
      this.result.customers.currentValue.push({
        customerNumber: customer.customerNumber,
        divisionNumber: customer.divisionNumber,
      });
    } else {
      this.result.customers.currentValue =
        this.result.customers.currentValue.filter(
          cust => cust.customerNumber !== customer.customerNumber,
        );
    }

    if (
      isSelected &&
      this.result.customers.currentValue.length >= this.MAX_CUSTOMERS
    ) {
      this.customersMaxLimitReached = true;
    }

    if (
      !isSelected &&
      this.result.customers.currentValue.length <= this.MAX_CUSTOMERS
    ) {
      this.customersMaxLimitReached = false;
    }

    this.selectExistingUser.emit({
      user: this.result.existingUser.isUserSelected
        ? this.result.existingUser.currentValue
        : null,
      customers: this.result.customers.currentValue,
      isUserSelected: this.result.existingUser.isUserSelected,
    });
  }

  updateCustomersSelected(customerNumber: number, divisionNumber: number) {
    const customerKey = { customerNumber, divisionNumber };

    const existingCustomerIndex = this.result.customers.currentValue.findIndex(
      c =>
        c.customerNumber === customerNumber &&
        c.divisionNumber === divisionNumber,
    );

    if (existingCustomerIndex > -1) {
      this.result.customers.currentValue.splice(existingCustomerIndex, 1);
    } else {
      if (this.result.customers.currentValue.length >= this.MAX_CUSTOMERS) {
        this.customersMaxLimitReached = true;
        return;
      }
      this.result.customers.currentValue.push(customerKey);
    }
    this.customersMaxLimitReached =
      this.result.customers.currentValue.length >= this.MAX_CUSTOMERS;
    this.selectExistingUser.emit({
      user: this.result.existingUser.isUserSelected
        ? this.result.existingUser.currentValue
        : null,
      customers: this.result.customers.currentValue,
      isUserSelected: this.result.existingUser.isUserSelected,
    });
  }

  getSingleCustomerSelectedName() {
    const selectedCustomer = this.result.customers.currentValue[0];
    return this.customers.find(
      c => c.customerNumber === selectedCustomer?.customerNumber,
    )?.customerName;
  }

  getSelectedCustomersCount() {
    return (
      this.translateService.instant('i18n.notifications.selected') +
      ': ' +
      this.result.customers.currentValue.length
    );
  }

  setSelectedOption(option: string) {
    if (
      option === 'userInformation' &&
      this.platform.platformType === PlatformEnum.mobile
    ) {
      this.mobileExistingUserInfoSelected.emit(true);
      this.result.existingUser.currentValue =
        this.result.existingUser.savedValue;
    } else if (
      option === 'customers' &&
      this.platform.platformType === PlatformEnum.mobile
    ) {
      this.mobileExistingCustomersSelected.emit(true);
    }
    if (option === 'userInformation') {
      this.searchedCustomers = this.customers;
    }
    this.selectedOption = option;
  }

  searchUser(event: any) {
    const value = event?.target?.value?.trim() || '';
    if (!this.isLoading && value !== '') {
      this.userHasSearched = true;
      this.isLoading = true;
      this.result.existingUser.isUserSelected = false;
      this.selectExistingUser.emit({
        user: this.result.existingUser.currentValue,
        customers: this.result.customers.currentValue,
        isUserSelected: this.result.existingUser.isUserSelected,
      });
      this.store.dispatch(
        UserActions.searchUserByUsername({ userName: value }),
      );
    }
  }

  selectUser() {
    if (this.result.existingUser.isUserSelected) {
      this.result.existingUser.isUserSelected = false;
      this.resetCustomerSearch();
    } else {
      this.result.existingUser.isUserSelected = true;
      this.result.customers.currentValue = this.selectedCustomer
        ? [
            {
              customerNumber: this.selectedCustomer.customerNumber,
              divisionNumber: this.selectedCustomer.divisionNumber,
            },
          ]
        : [];
      this.isLoading = true;
      this.store.dispatch(
        getActiveCustomersByUserId({
          userId: this.result?.existingUser.currentValue?.userId,
        }),
      );
    }
    this.selectExistingUser.emit({
      user: this.result.existingUser.currentValue,
      customers: this.result.customers.currentValue,
      isUserSelected: false,
    });
  }

  clearUser(resetNoUserMessage?: boolean) {
    if (resetNoUserMessage) this.userHasSearched = false;
    this.resetSearch();
    this.resetCustomerSearch();
  }

  resetSearch() {
    this.searchKey = '';
  }

  resetCustomerSearch() {
    const selectedCustomerNumber = this.selectedCustomer?.customerNumber;
    this.customers = this.customers.map(customer => {
      const isSelected = this.result.customers.currentValue.some(
        cust => cust.customerNumber === customer.customerNumber,
      );
      return { ...customer, selected: isSelected };
    });

    this.searchedCustomers = [...this.customers];
    this.searchedCustomers = this.customers.map(customer => ({
      ...customer,
      selected: this.result.customers.currentValue.some(
        selected =>
          selected.customerNumber === customer.customerNumber &&
          selected.divisionNumber === customer.divisionNumber,
      ),
      disabled: this.customers.some(
        cust =>
          cust.customerNumber === customer.customerNumber && cust.disabled,
      ),
    }));

    this.result.customers.currentValue = selectedCustomerNumber
      ? [
          {
            customerNumber: selectedCustomerNumber,
            divisionNumber: this.selectedCustomer.divisionNumber,
          },
        ]
      : [];

    this.customersMaxLimitReached = false;
  }

  getSelectedUserLabel(): any {
    const selectedUser = this.result.existingUser;

    return this.platform.platformType === this.platform.platformEnum.mobile
      ? selectedUser?.savedValue?.userName
      : selectedUser?.currentValue?.userName;
  }

  isCustomersSelected(): boolean {
    const options =
      this.platform.platformType === this.platform.platformEnum.mobile
        ? this.result?.customers?.savedValue
        : this.result?.customers?.currentValue;
    return options.length > 0;
  }

  getSelectedCustomersLabel(): string {
    const options =
      this.platform.platformType === this.platform.platformEnum.mobile
        ? this.result?.customers?.savedValue
        : this.result?.customers?.currentValue;
    const selectedCustomer = options[0];

    return options.length === 1
      ? this.customers.find(
          c =>
            c.customerNumber === selectedCustomer.customerNumber &&
            c.divisionNumber === selectedCustomer.divisionNumber,
        )?.customerName
      : this.translateService.instant('i18n.notifications.selected') +
          ': ' +
          options.length;
  }

  clearSelectedOption() {
    this.selectedOption = '';
    this.searchedCustomers = this.customers;
  }

  mobileUserInfoSelectedNext() {
    this.setSelectedOption('customers');
  }

  updateResult(newResults: any) {
    this.result.existingUser.savedValue = newResults?.existingUser?.savedValue;
    this.result.customers.savedValue = newResults?.customers?.savedValue;
    this.result.existingUser.wasUserSelected =
      newResults?.existingUser?.isUserSelected;
  }

  revertChanges() {
    this.result.existingUser.currentValue = this.result.existingUser.savedValue;
    this.result.customers.currentValue = [...this.result.customers.savedValue];
    this.result.existingUser.isUserSelected =
      this.result.existingUser.wasUserSelected;

    this.store.dispatch(
      getActiveCustomersByUserId({
        userId: this.result?.existingUser.currentValue?.userId,
      }),
    );

    this.searchedCustomers = [...this.customers];

    this.selectExistingUser.emit({
      user: this.result.existingUser.currentValue,
      customers: this.result.customers.currentValue,
      isUserSelected: this.result.existingUser.isUserSelected,
    });
  }

  isMobile(): boolean {
    return this.platform?.platformType === this.platform?.platformEnum?.mobile;
  }

  dismiss() {
    this.modalController.dismiss();
  }

  getUserItem() {
    return (
      this.result?.existingUser?.currentValue &&
      this.result?.existingUser?.currentValue?.userName !== ''
    );
  }

  ngOnDestroy() {
    this.userSearch$.unsubscribe();
    this.customers$.unsubscribe();
    this.selectedUserCustomers$.unsubscribe();
  }

  ionViewWillEnter(): void {
    this.resetSearch();
  }

  trackByCustomer(index: number, customer: any): number {
    return customer.customerNumber;
  }
}
