/**
 * Feature Flag Management Per Environment
 *
 * @remarks
 * The PanEnv and PanFeature enums are used to enable feature flag functionality in the short term until a 3rd party
 * feature tag SAAS solution is available.  It will allow the app to do bitwise comparision
 * to determine what to show/hide per environment.
 */

/**
 * DEPLOYMENT ENVIRONMENTS
 * @deprecated use @panamax/app-state instead
 */
export enum PanEnv {
  LOCAL = 'LOCAL',
  DEV = 'DEV',
  SIT = 'SIT',
  UAT = 'UAT',
  PERF = 'PERF',
  HOTFIX = 'HOTFIX',
  NGP = 'NGP',
  PROD = 'PROD',
}

/**
 * APP WIDE FEATURE FLAGS
 * @deprecated use @panamax/app-state instead
 */
export enum PanFeature {
  FEATURE1 = 'FEATURE1',
  FEATURE2 = 'FEATURE2',
  FEATURE3 = 'FEATURE3',
  FEATURE4 = 'FEATURE4',
  ORDER_TEAM_JANUARY_RELEASE = 'ORDER_TEAM_JANUARY_RELEASE',
  ORDER_TEAM_MARCH_RELEASE = 'ORDER_TEAM_MARCH_RELEASE',
  LOCAL_LOGIN = 'LOCAL_LOGIN',
  MICROSOFT_LOGIN = 'MICROSOFT_LOGIN',
  ALERTS_TM_NOTE = 'ALERTS_TM_NOTE',
}

/**
 * LOCAL ENABLED FEATURE FLAGS
 * @deprecated no longer needed
 * @remarks
 * Flag must be entered in the PanFeature enum 1st in order to function
 *
 */
export enum LOCAL_FEATURES {
  FEATURE1,
  FEATURE2,
  FEATURE3,
  ORDER_TEAM_MARCH_RELEASE,
  LOCAL_LOGIN,
  MICROSOFT_LOGIN,
  ALERTS_TM_NOTE,
}

/**
 * DEV ENABLED FEATURE FLAGS
 *
 * @deprecated no longer needed
 * @remarks
 * Flag must be entered in the PanFeature enum 1st in order to function
 *
 */
export enum DEV_FEATURES {
  FEATURE1,
  FEATURE2,
  ORDER_TEAM_MARCH_RELEASE,
  MICROSOFT_LOGIN,
  LOCAL_LOGIN,
  ALERTS_TM_NOTE,
}

/**
 * SIT ENABLED FEATURE FLAGS
 *
 * @deprecated no longer needed
 * @remarks
 * Flag must be entered in the PanFeature enum 1st in order to function
 *
 */
export enum SIT_FEATURES {
  FEATURE1,
  FEATURE2,
  ORDER_TEAM_MARCH_RELEASE,
  MICROSOFT_LOGIN,
  LOCAL_LOGIN,
  ALERTS_TM_NOTE,
}

/**
 * UAT ENABLED FEATURE FLAGS
 *
 * @deprecated no longer needed
 * @remarks
 * Flag must be entered in the PanFeature enum 1st in order to function
 *
 */
export enum UAT_FEATURES {
  FEATURE1,
  FEATURE2,
  LOCAL_LOGIN,
  MICROSOFT_LOGIN,
  ORDER_TEAM_MARCH_RELEASE,
}

/**
 * PERF ENABLED FEATURE FLAGS
 *
 * @deprecated no longer needed
 * @remarks
 * Flag must be entered in the PanFeature enum 1st in order to function
 *
 */
export enum PERF_FEATURES {
  FEATURE1,
  FEATURE2,
}

export enum HOTFIX_FEATURES {
  FEATURE1,
  FEATURE2,
}

export enum NGP_FEATURES {
  FEATURE1,
  FEATURE2,
}

/**
 * PROD ENABLED FEATURE FLAGS
 *
 * @deprecated no longer needed
 * @remarks
 * Flag must be entered in the PanFeature enum 1st in order to function
 *
 */
export enum PROD_FEATURES {
  FEATURE1,
  FEATURE2,
  MICROSOFT_LOGIN,
  ORDER_TEAM_MARCH_RELEASE,
}

/**
 * @deprecated no longer needed
 * @param PanFeature
 * @returns OBJECT WITH APPROPRIATE BITWISE VALUES
 */
export function assignFeatureNums(PanFeature): any {
  const keys = enumKeys(PanFeature);
  let features = {};
  let val = 1;
  keys.forEach(key => {
    Object.defineProperty(features, key, {
      value: val,
      writable: false,
    });
    val = val * 2;
  });
  return features;
}

/**
 * @deprecated no longer needed
 * @param f enum used for the features in an environment
 * @returns sum to use for bitwise
 */
export function sumFeatures(f: any) {
  const features = assignFeatureNums(PanFeature);
  let sum = 0;
  Object.keys(f).forEach(key => (sum += features[key] || 0));
  return sum;
}

/**
 * @deprecated no longer needed
 * @param obj enum
 * @returns object from enum keys
 */
export function enumKeys<O extends object, K extends keyof O = keyof O>(
  obj: O,
): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}
