import { Tracking } from '@panamax/app-state';
import { ProfileAnalyticsMessages } from './profile-analytics.constants';

export const logoutAllSessionsTrace: Tracking = {
  tracing: {
    data: {
      traceContext: ProfileAnalyticsMessages.logoutTraceName,
      isEndOfTrace: false,
      isStartOfTrace: true,
      attributes: {
        event: ProfileAnalyticsMessages.logoutTraceNameEvent,
        userID: undefined,
      },
    },
  },
};

export const logoutAllSessionsTraceSuccess: Tracking = {
  tracing: {
    data: {
      traceContext: ProfileAnalyticsMessages.logoutTraceName,
      isEndOfTrace: true,
      isStartOfTrace: false,
      attributes: {
        event: ProfileAnalyticsMessages.logoutSuccessTraceNameEvent,
        userID: undefined,
      },
    },
  },
};

export const clearAppDataTrace: Tracking = {
  tracing: {
    data: {
      traceContext: ProfileAnalyticsMessages.clearAppDataTrackingEvent,
      isEndOfTrace: false,
      isStartOfTrace: true,
      attributes: {
        event: ProfileAnalyticsMessages.clearAppData,
        userID: undefined,
      },
    },
  },
};

export const clearAppDataSuccessTrace: Tracking = {
  tracing: {
    data: {
      traceContext: ProfileAnalyticsMessages.clearAppDataTrackingEvent,
      isEndOfTrace: true,
      isStartOfTrace: false,
      attributes: {
        event: ProfileAnalyticsMessages.clearAppDataSuccess,
        userID: undefined,
      },
    },
  },
};
