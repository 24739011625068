/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[numeric]',
})
export class NumberDirective {
  constructor(private el: ElementRef) {}
  @HostListener('input', ['$event'])
  onInput(event: any) {
    const initialValue = event.target.value;
    this.el.nativeElement.value = initialValue.replace(/[^0-9]*/g, '');
    if (initialValue !== this.el.nativeElement.value) {
      event.target.value = initialValue.replace(/[^0-9]*/g, '');
    }
  }
}
