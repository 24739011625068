import { DatePipe } from '@angular/common';
import { CostReportSortOptions } from '@inventory/models/cost-report-model.model';
import {
  BODY_FONT,
  FOOTER_FONT,
  SUB_HEADER_FONT,
} from '@shared/constants/pdf-font';
import { InventoryPdfOptions } from '@shared/models/inventory-pdf-options';
import {
  CostReportModalOutput,
  DownloadInventoryModalOutput,
} from '@usf/ngrx-inventory';
import jsPDF from 'jspdf';
import autoTable, { CellDef } from 'jspdf-autotable';
import {
  InventoryItem,
  InventoryProductViewModel,
  InventoryViewModel,
} from '../../../../inventory/models/inventory-view.model';
import { CustomerDivisionDepartment } from '../../../../ngrx-customer/models/customer-division-department.model';
import { PDFColors } from '../../../constants/pdf-colors.enum';
import { desiredColumn } from '../../../models/desired-column';
import { Product } from '../../../models/product.model';
import { SeperatedColumn } from '../../../models/seperated-columns';
import { CurrencyService } from '../../currency.service';
import { ProductRowsAndNeededImages } from './document-helper-models-and-enums';
import {
  createMultipleSeperatedColumnsForInventory,
  filterInventoryItems,
  getInventoryGroupSubTotals,
  sortInventoryItemsForCostReportPDFFile,
} from './download-inventory-helpers';
import {
  addDataColumns,
  addGroupRow,
  addHeaderToPDF,
  createHeadersForPDF,
  createProductRows,
  createProductRowsGLCodes,
  getImageMap,
} from './general-pdf-helper';

export const addColumnDataToPDF = (
  doc: jsPDF,
  dataTable: string[][][],
  groupNames: string[],
  groupSubTotals: string[],
  desiredColumns: desiredColumn[],
  cellDefinitions: CellDef[][],
  imageMap: Map<string, HTMLImageElement>,
  imageColumnIndexes: number[],
  isMobile: boolean,
  inventoryPdfOptions?: InventoryPdfOptions,
) => {
  let groupNameIndex = 0;
  let nextTableYPosition = isMobile ? 135 : 145;
  dataTable.forEach(itemTablePerGroup => {
    addGroupRow(
      doc,
      itemTablePerGroup,
      groupNames[groupNameIndex],
      nextTableYPosition,
    );
    nextTableYPosition = (doc as any).previousAutoTable.finalY;

    addDataColumns(
      doc,
      itemTablePerGroup,
      nextTableYPosition,
      cellDefinitions,
      desiredColumns,
      imageMap,
      imageColumnIndexes,
      inventoryPdfOptions,
    );

    if (groupSubTotals.length > 0) {
      let groupName = groupNames[groupNameIndex];
      groupName = groupName.includes('GL:')
        ? groupName.replace(/:/, '')
        : groupName;
      addGroupSubTotalAfterGroup(
        doc,
        groupSubTotals[groupNameIndex],
        groupName,
      );
    }
    nextTableYPosition = (doc as any).previousAutoTable.finalY;
    groupNameIndex++;
  });
  addLineAfterGroupSubTotal(doc, nextTableYPosition);
};

export const addColumnDataToGLSortedNoProductDetailsPDF = (
  doc: jsPDF,
  dataTable: string[][][],
  desiredColumns: desiredColumn[],
  cellDefinitions: CellDef[][],
  imageMap: Map<string, HTMLImageElement>,
  imageColumnIndexes: number[],
  isMobile: boolean,
  inventoryPdfOptions?: InventoryPdfOptions,
) => {
  let nextTableYPosition = isMobile ? 135 : 145;
  dataTable.forEach(itemTablePerGroup => {
    addDataColumns(
      doc,
      itemTablePerGroup,
      nextTableYPosition,
      cellDefinitions,
      desiredColumns,
      imageMap,
      imageColumnIndexes,
      inventoryPdfOptions,
    );
  });
  addLineAfterGroupSubTotal(doc, nextTableYPosition);
};

const addLineAfterGroupSubTotal = (doc: jsPDF, nextTableYPosition: number) => {
  const pageSize = doc.internal.pageSize;
  doc.line(
    10,
    nextTableYPosition,
    pageSize.getWidth() - 10,
    nextTableYPosition,
  );
};

const addGroupSubTotalAfterGroup = (
  doc: jsPDF,
  groupSubTotal: string,
  groupName: string,
) => {
  autoTable(doc, {
    body: [
      [
        {
          content: groupName + ` Subtotal: `,
          styles: {
            fillColor: PDFColors.pdfWhite,
            halign: 'left',
            valign: 'top',
            fontSize: BODY_FONT,
            textColor: PDFColors.pdfBlack,
            minCellHeight: 34,
            font: 'helvetica',
            fontStyle: 'bold',
          },
          colSpan: 10,
        },
        {
          content: `${groupSubTotal}`,
          styles: {
            fillColor: PDFColors.pdfWhite,
            halign: 'right',
            valign: 'top',
            fontSize: BODY_FONT,
            textColor: PDFColors.pdfBlack,
            minCellHeight: 34,
            font: 'helvetica',
            fontStyle: 'bold',
          },
          colSpan: 3,
        },
      ],
    ],
    startY: false,
    margin: {
      left: 10,
      right: 10,
      bottom: 100,
    },
    tableWidth: 'auto',
  });
};

export const getGroupNamesInOrder = (
  groupNamesForProducts: string[],
): string[] => {
  const groupNames: string[] = [];
  let currentGroup;
  groupNamesForProducts.forEach((groupName: string) => {
    if (!currentGroup || groupName !== currentGroup) {
      currentGroup = groupName;
      groupNames.push(groupName);
    }
  });
  return groupNames;
};

export const addInventoryTotal = (doc: jsPDF, inventoryTotal: string) => {
  autoTable(doc, {
    body: [
      [
        {
          content: 'Inventory Total: ',
          styles: {
            fillColor: PDFColors.pdfWhite,
            halign: 'left',
            valign: 'top',
            fontSize: SUB_HEADER_FONT,
            textColor: PDFColors.pdfBlack,
            minCellHeight: 34,
            font: 'helvetica',
            fontStyle: 'bold',
          },
          colSpan: 10,
        },
        {
          content: `${inventoryTotal}`,
          styles: {
            fillColor: PDFColors.pdfWhite,
            halign: 'right',
            valign: 'top',
            fontSize: SUB_HEADER_FONT,
            textColor: PDFColors.pdfBlack,
            minCellHeight: 34,
            font: 'helvetica',
            fontStyle: 'bold',
          },
          colSpan: 3,
        },
      ],
    ],
    startY: false,
    margin: {
      left: 10,
      right: 10,
      bottom: 100,
    },
    tableWidth: 'auto',
  });
};

export const addGrandTotalToPdf = (doc: jsPDF, grandTotal: string) => {
  const pageCount: number = (doc as any).internal.getNumberOfPages();
  const pageSize = doc.internal.pageSize;
  const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
  doc.setPage(pageCount);
  autoTable(doc, {
    body: [
      [
        {
          content: 'Grand Total: ',
          styles: {
            fillColor: PDFColors.pdfWhite,
            halign: 'left',
            valign: 'middle',
            fontSize: FOOTER_FONT,
            textColor: PDFColors.pdfBlack,
            minCellHeight: 34,
            font: 'helvetica',
            fontStyle: 'bold',
          },
          colSpan: 10,
        },
        {
          content: `${grandTotal}`,
          styles: {
            fillColor: PDFColors.pdfWhite,
            halign: 'right',
            valign: 'middle',
            fontSize: FOOTER_FONT,
            textColor: PDFColors.pdfBlack,
            minCellHeight: 34,
            font: 'helvetica',
            fontStyle: 'bold',
          },
          colSpan: 3,
        },
      ],
    ],
    startY: pageHeight - 100,
    margin: {
      left: 10,
      right: 10,
      bottom: 60,
    },
    tableWidth: 'auto',
  });
  return doc;
};

export const addInventoryToPdf = async (
  doc: jsPDF,
  downloadOptions: CostReportModalOutput,
  inventory: InventoryViewModel,
  customerDivisionDepartment: CustomerDivisionDepartment,
  columns: desiredColumn[],
  productsMap: Map<number, Product>,
  isMobile: boolean,
): Promise<jsPDF> => {
  await addHeaderToPDF(
    doc,
    inventory.inventoryName + ' - ' + downloadOptions.selectedDate,
    inventory.numProducts,
    customerDivisionDepartment,
    isMobile,
    inventory,
  );

  await addInventoryCostColumnData(
    doc,
    downloadOptions,
    inventory,
    columns,
    productsMap,
    isMobile,
  );

  addInventoryTotal(doc, CurrencyService.toUSDString(inventory.totalPrice));

  return doc;
};

export const createInventoryPdf = async (
  doc: jsPDF,
  downloadOptions: DownloadInventoryModalOutput,
  inventory: InventoryViewModel,
  customerDivisionDepartment: CustomerDivisionDepartment,
  columns: desiredColumn[],
  productsMap: Map<number, Product>,
  isMobile: boolean,
): Promise<jsPDF> => {
  const items = filterInventoryItems(
    inventory.items,
    downloadOptions.selectedGroup,
  );
  const date = new DatePipe('en').transform(
    inventory.inventoryDate,
    'M/d/yyyy',
  );
  const fullName = inventory.inventoryName + ' ' + date;
  await addHeaderToPDF(
    doc,
    fullName,
    items.length,
    customerDivisionDepartment,
    isMobile,
    inventory,
  );

  await addInventoryWorksheetColumnData(
    doc,
    downloadOptions,
    items,
    columns,
    productsMap,
    isMobile,
  );

  return doc;
};

export const addInventoryCostColumnData = async (
  doc: jsPDF,
  downloadOptions: CostReportModalOutput,
  inventory: InventoryViewModel,
  columns: desiredColumn[],
  productsMap: Map<number, Product>,
  isMobile: boolean,
) => {
  let glSort =
    downloadOptions.selectedSort ===
    CostReportSortOptions.glCodesWithProductDetail;
  const noProductDetails =
    downloadOptions.selectedSort ===
    CostReportSortOptions.glCodesWithoutProductDetail;
  let sortedItems = sortInventoryItemsForCostReportPDFFile(
    downloadOptions.selectedSort,
    inventory.items,
  ) as InventoryProductViewModel[];

  let groupNamesForProducts: string[];
  if (glSort) {
    groupNamesForProducts = sortedItems.map(
      (item: InventoryProductViewModel) => 'GL: ' + item.glString,
    );
  } else {
    groupNamesForProducts = sortedItems.map(
      (item: InventoryProductViewModel) => item.groupName,
    );
  }

  const groupSubTotals: string[] = getInventoryGroupSubTotals(
    sortedItems,
    glSort,
  );

  let seperatedColumns: SeperatedColumn[] = createMultipleSeperatedColumnsForInventory(
    sortedItems,
    productsMap,
    columns,
  );

  const groupNames = getGroupNamesInOrder(groupNamesForProducts);
  const cellDefinitions: CellDef[][] = createHeadersForPDF(columns);
  const productRowsAndNeededImages: ProductRowsAndNeededImages = noProductDetails
    ? createProductRowsGLCodes(seperatedColumns)
    : createProductRows(seperatedColumns, groupNamesForProducts, isMobile);
  const imageColumnIndexes = isMobile
    ? []
    : downloadOptions.includeGlCodes
    ? [4, 5]
    : [3, 4];
  const imageMap = await getImageMap(
    imageColumnIndexes,
    productRowsAndNeededImages.imageKeys,
  );

  noProductDetails
    ? addColumnDataToGLSortedNoProductDetailsPDF(
        doc,
        productRowsAndNeededImages.productRowsByGroup,
        columns,
        cellDefinitions,
        imageMap,
        imageColumnIndexes,
        isMobile,
      )
    : addColumnDataToPDF(
        doc,
        productRowsAndNeededImages.productRowsByGroup,
        groupNames,
        groupSubTotals,
        columns,
        cellDefinitions,
        imageMap,
        imageColumnIndexes,
        isMobile,
      );
};

export const addInventoryWorksheetColumnData = async (
  doc: jsPDF,
  downloadOptions: DownloadInventoryModalOutput,
  inventoryItems: InventoryItem[],
  columns: desiredColumn[],
  productsMap: Map<number, Product>,
  isMobile: boolean,
) => {
  const groupNamesForProducts: string[] = inventoryItems.map(
    item => item.groupName,
  );
  let separatedColumns: SeperatedColumn[] = createMultipleSeperatedColumnsForInventory(
    inventoryItems,
    productsMap,
    columns,
  );
  const groupNames = getGroupNamesInOrder(groupNamesForProducts);
  const cellDefinitions: CellDef[][] = createHeadersForPDF(columns);
  const productRowsAndNeededImages: ProductRowsAndNeededImages = createProductRows(
    separatedColumns,
    groupNamesForProducts,
    isMobile,
  );
  const imageColumnIndexes =
    isMobile || !downloadOptions.includeProductStatus ? [] : [3, 4];
  const imageMap = await getImageMap(
    imageColumnIndexes,
    productRowsAndNeededImages.imageKeys,
  );
  const inventoryPDFOptions: InventoryPdfOptions = {
    includeFreehandCount: downloadOptions.includeFreehandCount,
    includeProductStatus: downloadOptions.includeProductStatus,
  };
  addColumnDataToPDF(
    doc,
    productRowsAndNeededImages.productRowsByGroup,
    groupNames,
    [],
    columns,
    cellDefinitions,
    imageMap,
    imageColumnIndexes,
    isMobile,
    inventoryPDFOptions,
  );
};
