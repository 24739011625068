import { createAction, props } from '@ngrx/store';
import { OrderMinimumViewModel } from '@order/models/order-minimum-view-model';
import { SubmittedOrderTallies } from '@order/models/submitted-order-view-model';
import { OrderStatusDetails } from '@shared/constants/order-status-title.enum';
import { FileEntity } from '@shared/models/file-entity';
import { OrderInsightData } from '@shared/models/order-insights.model';
import { Customer } from '@usf/customer-types';
import { Order } from '@usf/ngrx-order/lib/models/client/order';

export const ORDER_ACTIONS = {
  createNewOrderAfterCustomerRelatedDataLoaded: createAction(
    '[NgRx - Order] - Create New Order After Customer Switch Complete',
    props<{
      customer: Customer;
      departmentNumber: string;
    }>(),
  ),
  openAddToOrderModal: createAction(
    '[NgRx - Order] Open AddToOrder Modal',
    props<{ modalData: any }>(),
  ),
  dismissAddToOrderModal: createAction(
    '[NgRx - Order] Dismiss AddToOrder Modal',
    props<{ modalData: any }>(),
  ),
  cancelAddToOrderModal: createAction(
    '[NgRx - Order] Cancel AddToOrder Modal',
    props<{ modalData: any }>(),
  ),
  printSubmittedOrder: createAction(
    '[NgRx - Order] Print Order',
    props<{
      orders: Order[];
      orderConfirmation: boolean;
      orderTallies: SubmittedOrderTallies;
      orderMinimum?: OrderMinimumViewModel;
      shipToCustomer?: Customer;
      shipToDepartmentName?: string;
      orderStatusDetails?: OrderStatusDetails;
    }>(),
  ),
  printCart: createAction('[NgRx - Order] Print Cart'),
  downloadImportedOrders: createAction(
    '[NgRx - Order] Download Imported Orders',
    props<{ fileName: string; fileType: FileEntity; orders: Order[] }>(),
  ),
  downloadOrderStatus: createAction(
    '[NgRx - Order] Download Order Status Orders',
    props<{ fileName: string; fileType: FileEntity; orders: Order[] }>(),
  ),
  downloadCart: createAction(
    '[NgRx - Order] Download Cart',
    props<{ fileName: string; fileType: FileEntity; orders: Order[] }>(),
  ),
  downloadOrderInsights: createAction(
    '[NgRx - Order] Download Order Insights',
    props<{
      fileName: string;
      fileType: FileEntity;
      orderNumber: number;
      insightInformation: Map<number, OrderInsightData>;
    }>(),
  ),
  ChangeDeliveryDate: createAction(
    '[NgRx - Order] Change Delivery Date',
    props<{ deliveryDate: string }>(),
  ),
};
