import { EnvironmentInjector, Injectable, Injector } from '@angular/core';
import { AngularDelegate, PopoverController } from '@ionic/angular';
import {
  Tooltip,
  TooltipDesktop,
  TooltipOptions,
  TooltipTouch,
} from './tooltip.component';
import { PlatformService } from '@panamax/app-state';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  constructor(
    private angularDelegate: AngularDelegate,
    private envInjector: EnvironmentInjector,
    private injector: Injector,
    private popoverController: PopoverController,
    private platformService: PlatformService,
  ) {}

  async create(options: TooltipOptions, $event: Event): Promise<Tooltip> {
    if (this.platformService.isTouch.value) {
      const popover = await this.popoverController.create({
        component: options.component,
        componentProps: options.componentProps,
        event: $event,
        backdropDismiss: true,
        translucent: true,
        cssClass: options.popoverCssClass,
      });
      return new TooltipTouch(popover);
    } else {
      const delegate = await this.angularDelegate.create(
        this.envInjector,
        this.injector,
      );
      const container = $event.target;
      return new TooltipDesktop(container, delegate, options);
    }
  }
}
