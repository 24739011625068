<div
  class="popover-app-error-inner"
  [ngClass]="{ touch: (this.platformService?.isTouchExperience$() | async) }"
>
  <div class="icon-container">
    <ion-icon
      color="success"
      name="close"
      size="large"
      data-cy="app-error-modal-close-button"
      (click)="dismiss()"
    ></ion-icon>
  </div>

  <div class="popover-app-error-content">
    <div class="list-container">
      <ion-list>
        <ion-item *ngFor="let error of modalAppErrors | async">
          <div class="alert-icon" (click)="dismissRoute(error.dismissRoute)">
            <img
              src="../../../../../assets/images/alert-orange.svg"
              class="alert-icon-border"
              data-cy="app-error-image"
            />
          </div>
          <ion-label class="list-label">
            <h3 data-cy="app-error-modal-title">
              {{ error.appErrorTitle | titlecase }}
            </h3>
            <p data-cy="app-error-modal-description-text">
              {{ error.appErrorDescription }}
            </p>
          </ion-label>
        </ion-item>
      </ion-list>
    </div>

    <ion-button
      class="usf-fill-orange-button"
      (click)="dismiss()"
      data-cy="app-error-modal-continue-button"
    >
      Close
    </ion-button>
  </div>
</div>
