<div
  *ngIf="
    !disabled &&
    pricing?.unitPrice * 1 > 0 &&
    !!pricing?.unitPriceBeforeShipping &&
    !!pricing?.unitShippingCharge
  "
  class="shipping-price-text"
>
  <ng-container *ngIf="isSingleLineDisplay">
    <div>
      {{ pricing?.unitPriceBeforeShipping | currency }} (Product) +
      {{ pricing?.unitShippingCharge | currency }} (S&H)
    </div>
  </ng-container>
  <ng-container *ngIf="!isSingleLineDisplay">
    <div [class.multi-line-display]="platformType === platformEnum?.desktop">
      <div>{{ pricing?.unitPriceBeforeShipping | currency }} (Product)</div>
      <div>{{ pricing?.unitShippingCharge | currency }} (S&H)</div>
    </div>
  </ng-container>
</div>
