<ion-header
  class="ion-no-border"
  [ngClass]="
    platformType !== platformEnum.desktop
      ? platformType === platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons *ngIf="platformType === platformEnum.desktop" slot="end">
      <ion-button
        id="close-modal-btn"
        data-cy="close-cost-report-modal-button"
        (click)="dismiss()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label
      *ngIf="platformType !== platformEnum.desktop"
      class="bold"
      data-cy="cost-report-label"
    >
      {{ 'i18n.inventory.downloadInventoryCostReport' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  [ngClass]="
    platformType !== platformEnum.desktop
      ? platformType === platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <div
    class="body-content"
    [class.is-touch]="platformType !== platformEnum.desktop"
    [class.is-mobile]="platformType === platformEnum.mobile"
  >
    <div *ngIf="platformType === platformEnum.desktop" class="title">
      <ion-label class="bold" data-cy="download-cost-report-label">
        {{ 'i18n.inventory.downloadInventoryCostReport' | translate }}
      </ion-label>
    </div>
    <div class="instructions">
      <span class="pro-display">
        {{ 'i18n.inventory.costReportModal.firstHalfSubTitle' | translate }}
      </span>
      <span class="bold"
        >{{ 'i18n.common.completed' | translate | lowercase }}
      </span>
      <span class="pro-display">{{
        'i18n.inventory.costReportModal.secondHalfSubTitle' | translate
      }}</span>
    </div>
    <div class="disclaimer">
      <div class="warning-icon">
        <ion-icon name="alert-circle-outline"></ion-icon>
      </div>
      <div class="warning-text">
        <span class="pro-display">
          {{ 'i18n.inventory.costReportModal.firstHalfDisclaimer' | translate }}
        </span>
        <span class="bold"
          >{{ 'i18n.inventory.completedInventories' | translate | lowercase }}
        </span>
        <span class="pro-display">{{
          'i18n.inventory.costReportModal.secondHalfDisclaimer' | translate
        }}</span>
      </div>
    </div>
    <div class="cost-report-options">
      <div class="top-section">
        <div class="date-section">
          <app-selectable-item
            [label]="'i18n.inventory.selectDate'"
            [value]="selectedDate"
            [detailIcon]="
              platformType === platformEnum.desktop
                ? 'chevron-down-outline'
                : 'chevron-forward-outline'
            "
            (clickValueEmitter)="openSelectDateDropdown($event)"
          ></app-selectable-item>
        </div>
        <div class="inventory-section">
          <app-selectable-item
            [label]="'i18n.inventory.selectCompletedInventory'"
            [value]="selectedInventoryDisplay"
            [detailIcon]="
              platformType === platformEnum.desktop
                ? 'chevron-down-outline'
                : 'chevron-forward-outline'
            "
            (clickValueEmitter)="openSelectInventoryDropdown($event)"
          ></app-selectable-item>
        </div>
      </div>

      <div class="bottom-section">
        <div class="format-section">
          <app-selectable-item
            [label]="'i18n.common.selectFormat'"
            [value]="selectedFormat"
            [detailIcon]="'chevron-down-outline'"
            (clickValueEmitter)="openSelectFormatDropdown($event)"
          ></app-selectable-item>
        </div>
        <div class="sort-section">
          <app-selectable-item
            [label]="'i18n.common.sortBy'"
            [value]="selectedSort"
            [detailIcon]="'chevron-down-outline'"
            (clickValueEmitter)="openSortByDropDown($event)"
          ></app-selectable-item>
        </div>
      </div>
      <ion-item *ngIf="glFlag" class="gl-checkbox" lines="none">
        <ion-checkbox
          data-cy="include-gl-codes-checkbox"
          [(ngModel)]="includeGlCodes"
          mode="ios"
          justify="start"
          labelPlacement="end"
          (click)="checkSortForGl()"
          >{{
            'i18n.inventory.costReportModal.includeGlCodes' | translate
          }}</ion-checkbox
        >
      </ion-item>
    </div>
  </div>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="platformType !== platformEnum.mobile"
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="cancel-cost-report-modal-button"
        [ngClass]="
          platformType !== platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismiss()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        data-cy="cost-report-modal-button"
        [ngClass]="
          platformType !== platformEnum.mobile
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="download()"
      >
        {{ 'i18n.common.download' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
