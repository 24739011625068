import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NavigationHelperService } from '@shared/helpers/navigation.helpers.service';
import { ListMessage } from '@usf/list-types';
import { ListsService } from 'src/app/lists/services/lists.service';
import { MessageTypeEnum } from 'src/app/ngrx-message/constants/messageTypeEnum';
import { Message } from 'src/app/ngrx-message/models/client/message';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toast: HTMLIonToastElement;

  constructor(
    public toastController: ToastController,
    private translateService: TranslateService,
    private listsService: ListsService,
    private navigationHelperService: NavigationHelperService,
  ) {}

  async presentToastMsg(
    display: string,
    cssClass: string,
    type: MessageTypeEnum,
    buttons: any[],
    length = 5000,
  ) {
    const toast = await this.toastController.create({
      message: display,
      cssClass,
      duration: length,
      buttons,
      position: 'top',
      icon: this.setToastIcon(type),
    } as ToastOptions);
    return await toast.present();
  }

  async presentToast(toastMessage: Message) {
    const toast = await this.toastController.create({
      message: toastMessage.display,
      cssClass: this.setToastClass(toastMessage.type),
      duration: !!toastMessage?.sticky ? null : 5000,
      buttons: this.setToastButtons(
        toastMessage.sticky,
        toastMessage?.id,
        toastMessage?.listMessage,
      ) as any,
      position: 'top',
      icon: this.setToastIcon(toastMessage.type),
    } as ToastOptions);
    await toast.present();

    await toast.onDidDismiss();
  }

  async dismissToast() {
    await this.toast?.dismiss();
  }

  setToastClass(styleType: MessageTypeEnum): string {
    switch (styleType) {
      case MessageTypeEnum.error:
        return 'red-toast';
      case MessageTypeEnum.warning:
        return 'red-toast';
      default:
        return 'green-toast';
    }
  }

  setToastButtons(sticky: boolean, id?: string, listMessage?: ListMessage) {
    const buttons = [];
    if (id === 'add-products-success') {
      buttons.push({
        text: this.translateService.instant('i18n.lists.goToList'),
        side: 'end',
        handler: () => {
          this.listsService.goToShoppingList([
            'lists',
            'management',
            'SL-' + listMessage?.additionalInfo?.listId,
          ]);
          this.dismissToast();
        },
      });
    }
    if (sticky) {
      buttons.push({
        icon: 'close-circle',
        role: 'cancel',
        side: 'end',
      });
    }
    return buttons;
  }

  setOrderErrorToastButtons(submittedOrderId?: string) {
    const buttons = [];
    buttons.push({
      text: this.translateService.instant(
        'i18n.orderSubmittedPage.orderDetails',
      ),
      side: 'end',
      handler: () => {
        this.navigationHelperService.routeToSubmittedOrder(submittedOrderId);
        this.dismissToast();
      },
    });
    return buttons;
  }
  setToastIcon(msgTypeEnum: MessageTypeEnum) {
    switch (msgTypeEnum) {
      case MessageTypeEnum.error:
      case MessageTypeEnum.failure:
      case MessageTypeEnum.warning:
        return 'alert-circle-outline';
      default:
        return 'checkmark-circle-outline';
    }
  }
}
