<div
  [attr.data-cy]="'selectable-countable-item'"
  class="selectable-countable-item"
  [class.is-selected]="isSelected"
  (click)="onSelectItem()"
>
  <div [attr.data-cy]="'checkbox-section'" class="checkbox-section">
    <app-usf-checkbox [isSelected]="isSelected"></app-usf-checkbox>
  </div>
  <ion-item
    [attr.data-cy]="'info-section-'"
    [class.completed]="item?.isComplete"
    lines="none"
  >
    <div
      *ngIf="item?.isComplete"
      [attr.data-cy]="'completed-banner-'"
      class="completed-banner"
    >
      <p [attr.data-cy]="'completed-banner-text-'" class="bold">
        {{ 'i18n.inventory.complete' | translate }}
      </p>
    </div>
    <ion-label
      id="count"
      [attr.data-cy]="'name-date-text-'"
      class="bold header-text ion-text-wrap"
    >
      {{ item?.title }}
      <p [attr.data-cy]="'last-updated-text-'" class="subheader-text">
        {{ item?.subtitle }}
      </p>
    </ion-label>
    <ion-badge
      aria-labelledby="count"
      [attr.data-cy]="'num-products-icon'"
      class="bold badge"
      mode="ios"
      slot="end"
    >
      {{ item?.count ?? 0 }}
    </ion-badge>
  </ion-item>
</div>
