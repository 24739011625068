<ion-header class="ion-no-border">
  <ion-toolbar>
    <div class="title">
      <ion-icon (click)="goBack()" name="chevron-back-outline"></ion-icon>
      <ion-label class="bold">{{ label | translate }}</ion-label>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-radio-group [(ngModel)]="selectedLabel">
      <ion-item *ngFor="let name of listOfSelectableLabels">
        <ion-radio
          labelPlacement="start"
          mode="md"
          [value]="name"
          [attr.data-cy]="'radio-' + name"
          >{{ name | translate }}</ion-radio
        >
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>

<ion-footer class="ion-footer-modal">
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="cancel-download-list-modal-button"
        [ngClass]="
          platformType !== platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="goBack()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        data-cy="confirmation-button"
        [ngClass]="
          platformType !== platformEnum.mobile
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="save()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
