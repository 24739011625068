<div
  *ngIf="!isListManagement"
  data-cy="product-card-line-number-section-non-edit"
  class="usf-product-card-line-num-non-edit"
>
  <ion-label data-cy="product-card-line-number-text-non-edit">
    {{ 'i18n.lists.lineNum' | translate }}
    {{ sequenceNumber }}
  </ion-label>
</div>
<div *ngIf="isListManagement" class="usf-product-card-line-num">
  <div data-cy="product-card-line-number-section" class="bold">
    <ion-label id="line-number" data-cy="product-card-line-number-text">
      {{ 'i18n.lists.lineNum' | translate }}
    </ion-label>
  </div>
  <div
    data-cy="product-card-line-number-input-section"
    class="usf-product-card-line-num-input"
  >
    <ion-input
      aria-labelledby="line-number"
      data-cy="product-card-line-number-input"
      name="productCardOrderNumber"
      type="text"
      inputmode="numeric"
      [value]="sequenceNumber"
      numeric
      (ionBlur)="resequenceProduct(sequenceNumberRef.value)"
      #sequenceNumberRef
    ></ion-input>
  </div>
</div>
