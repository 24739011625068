import { createAction, props } from '@ngrx/store';
import { DivisionPhoneNumber } from '@usf/customer-types';

export const DIVISION_PHONE_NUMBER_ACTIONS = {
  getAllDivisionPhoneNumbers: createAction(
    '[NgRxCustomer - Division Phone Number] Get All Division Phone Numbers',
  ),
  getAllDivisionPhoneNumbersSuccess: createAction(
    '[NgRxCustomer - Division Phone Number] Get All Division Phone Numbers Success',
    props<{ divisionPhoneNumbers: DivisionPhoneNumber[] }>(),
  ),
  getAllDivisionPhoneNumbersFail: createAction(
    '[NgRxCustomer - Division Phone Number] Get All Division Phone Numbers Fail',
    props<{ error: any }>(),
  ),
  getPhoneNumbersByDivisionNumber: createAction(
    '[NgRxCustomer - Division Phone Number] Get Phone Numbers by Division Number',
    props<{ divisionNumber: number }>(),
  ),
  getPhoneNumbersByDivisionNumberSuccess: createAction(
    '[NgRxCustomer - Division Phone Number] Get Phone Numbers by Division Number Success',
    props<{ divisionPhoneNumber: DivisionPhoneNumber }>(),
  ),
  getPhoneNumbersByDivisionNumberFail: createAction(
    '[NgRxCustomer - Division Phone Number] Get Phone Numbers by Division Number Fail',
    props<{ error: any }>(),
  ),
};
