import { Tracking } from '@panamax/app-state';
import { OrderTracingMessages } from '../order-tracing.constants';

export const cancelOrderTrace: Tracking = {
  tracing: {
    data: {
      traceContext: OrderTracingMessages.capability,
      isEndOfTrace: true,
      isStartOfTrace: false,
      attributes: {
        order: {
          id: undefined,
          tandemOrderNumber: undefined,
          totalAmt: undefined,
          requestedDeliveryDate: undefined,
          confirmedDeliveryDate: undefined,
        },
        products: undefined,
        event: OrderTracingMessages.cancelIPOrder,
      },
    },
  },
};
