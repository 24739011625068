import { Component, Input } from '@angular/core';
import { PlatformEnum } from '@panamax/app-state';
import { EllipsisOption } from '../../../shared/models/ellipsis-option.model';

@Component({
  selector: 'app-other-inventory-actions',
  templateUrl: './other-inventory-actions.component.html',
  styleUrls: ['./other-inventory-actions.component.scss'],
})
export class OtherInventoryActionsComponent {
  readonly PlatformEnum = PlatformEnum;

  @Input() ellipsisOptions: EllipsisOption[];
  @Input() platform: PlatformEnum;

  constructor() {}
}
