import { FontStyle, HAlignType, VAlignType } from 'jspdf-autotable';

export const PAGE_HEIGHT = 792;
export const PAGE_WIDTH = 612;
export const PAGE_MARGIN = 20;
export const PAGE_CONTENT_WIDTH = PAGE_WIDTH - PAGE_MARGIN * 2;

export const HEADER_HEIGHT = 84;
export const FOOTER_HEIGHT = 71;

export const PADDING = 8;
export const LINE_WIDTH = 1;

export const HEADING_FONT_SIZE = 14;
export const PARAGRAPH_FONT_SIZE = 12;
export const TEXT_LINE_HEIGHT = 1.15;

export const LOGO_WIDTH = 50;
export const LOGO_HEIGHT = 48;

export const FONT_NAME = 'helvetica';
export const WHITE = '#FFFFFF';
export const LIGHT_GRAY = '#EBEBEB';
export const GRAY = '#B6B6B6';
export const BLACK = '#000000';

export const JSPDF_TYPE_PLAIN = 'plain';
export const JSPDF_TYPE_BOLD: FontStyle = 'bold';
export const JSPDF_TABLE_BASE_STYLES = {
  fontSize: PARAGRAPH_FONT_SIZE,
  fillColor: WHITE,
  lineColor: GRAY,
};
export const JSPDF_HEADING_STYLES = {
  fontStyle: JSPDF_TYPE_BOLD,
  cellPadding: {
    top: PADDING,
    bottom: PADDING,
  },
};

export const MAX_PRODUCT_CLAIMS_ROWS = 5;

// in pt
export const PILL_X = 45;
export const PILL_Y = 12;
export const X_PADDING = 5;

const CENTER_H_ALIGN: HAlignType = 'center';
const MIDDLE_V_ALIGN: VAlignType = 'middle';

export const PRODUCT_COMPARE_TABLE_STYLES = {
  fontSize: 10,
  cellPadding: 5,
  halign: CENTER_H_ALIGN,
  valign: MIDDLE_V_ALIGN,
};

export const PRODUCT_COMPARE_DIMENSIONS = {
  firstColumnWidth: 112,
  productImageSize: 115,
  pillHeight: 15,
  pillVerticalPadding: 5,
};
