import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-highlighted-selectable-item',
  templateUrl: './highlighted-selectable-item.component.html',
  styleUrls: ['./highlighted-selectable-item.component.scss'],
})
export class HighlightedSelectableItemComponent {
  @Input() label: string;
  @Input() value: string;
  @Input() selected: boolean;
  @Input() index = 1;

  @Output() clickValueEmitter: EventEmitter<void> = new EventEmitter();
  constructor() {}

  clickValue() {
    this.clickValueEmitter.emit();
  }
}
