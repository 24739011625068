import { Component, Input } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { UserKinds } from '@usf/user-types/user';
import {
  CreateListOptions,
  ListTypes,
} from '../../../shared/constants/lists-constants';
import { ListsPageData } from '../../model/lists-page.model';
import { ListsViewModel } from '../../model/lists-view.model';
import { ListsService } from '../../services/lists.service';

@Component({
  template: '',
})
export class CreateListBaseComponent {
  @Input() listData: ListsPageData;
  readonly CreateListOptions = CreateListOptions;
  readonly ListTypes = ListTypes;
  readonly UserKinds = UserKinds;
  listToCopy: ListsViewModel;
  selectedCreateListOption = CreateListOptions.newList;
  listType = ListTypes.public;
  listName: string;
  trimmedListName: string;
  listNameExists = false;

  constructor(
    protected nav: IonNav,
    private modalController: ModalController,
    readonly platformService: PlatformService,
    private listsService: ListsService,
  ) {}

  getListNameExists() {
    this.trimmedListName = this.listName.trim();
    this.listNameExists = this.listsService.listNameExists(
      this.listData,
      this.trimmedListName,
    );
  }

  onCreateList() {
    this.listsService.createList(this.trimmedListName, this.listType);
    this.dismissModal();
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }
}
