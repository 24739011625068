import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PlatformService } from '@panamax/app-state';
import { FileFormControl } from './file-form-control/file-form-control';
import { mimeTypeValidator } from './mime-type-validator/mime-type-validator';
import { FILE_FORMAT_OPTIONS } from './file-format-options';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { OrderTransaction } from '@usf/order-types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  template: '',
})
export class ImportOrderBaseComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @Input() loading$: Observable<boolean>;
  @Input() loaded$: Observable<boolean>;
  @Input() orderTransactions$: Observable<OrderTransaction[]>;
  @Input() fileImportErrors$: Observable<string[]>;
  @Input() fileImportMessage$: Observable<string>;
  @Input() apiError$: Observable<string>;
  @Output() isOpenEmitter = new EventEmitter<boolean>();
  @Output() importOrderEmitter = new EventEmitter<{
    file: File;
    fileType: string;
  }>();
  @Output() importSuccessEmitter = new EventEmitter<any>();
  @Output() initializeImportOrderStateEmitter = new EventEmitter<void>();
  fileReading = false;
  fileFormatOptions = FILE_FORMAT_OPTIONS;
  errors$: Observable<string[]>;
  form: FormGroup = new FormGroup({
    file: new FileFormControl('', [mimeTypeValidator(), Validators.required]),
    format: new FormControl('', Validators.required),
  });
  formatControlSub: Subscription;
  fileControlSub: Subscription;
  showMultipleFileError = false;

  constructor(
    readonly platformService: PlatformService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.formatControlSub = this.form.controls['format'].valueChanges.subscribe(
      x => {
        this.showMultipleFileError = false;
        this.initializeImportOrderStateEmitter.emit();
      },
    );
    this.fileControlSub = this.form.controls['file'].valueChanges.subscribe(
      x => {
        this.initializeImportOrderStateEmitter.emit();
      },
    );

    const clientFileErrors$ = this.form.valueChanges.pipe(
      map(x => {
        const errors = [];
        if (this.form.controls['file'].hasError('mimeTypeError')) {
          errors.push(
            this.translateService?.instant(
              'i18n.importOrderModal.errorMessages.mimeType',
            ),
          );
        }
        if (this.showMultipleFileError) {
          errors.push(
            this.translateService?.instant(
              'i18n.importOrderModal.errorMessages.multipleFiles',
            ),
          );
        }
        return errors;
      }),
    );
    this.errors$ = combineLatest([
      clientFileErrors$,
      this.fileImportErrors$.pipe(filter(x => !!x)),
      this.loaded$,
      this.loading$,
      this.orderTransactions$,
      this.fileImportMessage$,
      this.apiError$,
    ]).pipe(
      debounceTime(1000),
      map(
        ([
          clientErrors,
          serverErrors,
          loaded,
          loading,
          orderTransactions,
          importMessage,
          apiError,
        ]) => {
          if (loading) {
            this.form.get('file')?.disable({ emitEvent: false });
            this.form.get('format')?.disable({ emitEvent: false });
          } else {
            this.form.get('file')?.enable({ emitEvent: false });
            this.form.get('format')?.enable({ emitEvent: false });
          }
          const errors = [...clientErrors, ...serverErrors];
          if (!!importMessage?.length) {
            errors.push(importMessage);
          }
          if (!!apiError) {
            errors.push(
              'There was an error, please try and upload your orders again.',
            );
          }
          if (!!orderTransactions?.length) {
            this.importSuccessEmitter.emit(orderTransactions);
          } else {
            return errors;
          }
        },
      ),
    );
  }

  onDrop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.loading$.pipe(take(1)).subscribe(loading => {
      if (!loading) {
        const dataTransfer = event.dataTransfer;
        const numberOfFiles = dataTransfer?.files.length;

        this.showMultipleFileError = numberOfFiles > 1;

        if (this.showMultipleFileError) {
          this.form.controls['file'].setValue(null);
          this.form.get('file')?.enable();
          return;
        }
        const file = event.dataTransfer.files[0];
        this.setFileInput(file);
        this.form.controls['file'].markAsTouched();
      }
    });
  }

  onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  setModalOpen(open: boolean) {
    this.isOpenEmitter.emit(open);
  }

  importOrder() {
    const file = this.fileInput.nativeElement.files[0];
    const fileType = this.form.controls['format'].value;
    if (!file || !fileType) {
      return;
    }
    this.importOrderEmitter.emit({ file, fileType });
  }

  browseFiles() {
    this.fileInput.nativeElement.click();
  }

  onFileChange(event) {
    this.showMultipleFileError = false;
    const fileInput = event.target as HTMLInputElement;
    this.form.controls['file'].setValue(fileInput.files);
    this.form.controls['file'].markAsTouched();
    const file = event.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024 * 1024) {
      // 2GB
      this.fileReading = true;

      const reader = new FileReader();
      reader.onload = () => {
        this.fileReading = false;
      };

      reader.onerror = error => {
        this.fileReading = false;
        console.error('Error reading file:', error);
      };

      reader.readAsArrayBuffer(file);
    } else {
      console.error('File is too large.', file);
    }
  }

  setFileInput(file: File) {
    // Get the file input element
    const inputEl = this.fileInput.nativeElement;

    // Create a DataTransfer object to hold the file
    const dataTransfer = new DataTransfer();
    if (file) {
      dataTransfer.items.add(file);
    }

    // Set the files property of the input element
    inputEl.files = dataTransfer.files;
    this.form.controls['file'].setValue(inputEl.files);
  }

  ngOnDestroy() {
    this.formatControlSub?.unsubscribe();
    this.fileControlSub?.unsubscribe();
  }
}
