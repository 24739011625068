import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-product-card-line-number',
  templateUrl: './product-card-line-number.component.html',
  styleUrls: ['./product-card-line-number.component.scss'],
})
export class ProductCardLineNumberComponent {
  @Input() sequenceNumber: number;
  @Input() isListManagement: boolean;

  @Output() resequenceProductEmitter: EventEmitter<number> = new EventEmitter();

  constructor() {}

  resequenceProduct(newValue: any) {
    this.resequenceProductEmitter.emit(Number(newValue));
  }
}
