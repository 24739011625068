import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController, IonNav, PopoverController } from '@ionic/angular';
import { PlatformEnum, PlatformService } from '@panamax/app-state';
import { DownloadRequestFileType } from '@usf/list-types';
import { DownloadListOptions, ListState } from '@usf/ngrx-list';
import { ListTypes } from '@shared/constants/lists-constants';
import { ListCopyOptions } from '@shared/constants/lists-constants';
import { ListsPageData } from '@app/lists/model/lists-page.model';
import { ListsViewModel } from '@app/lists/model/lists-view.model';
import { ListsService } from '../../../../services/lists.service';
import { CopyListModalBaseComponent } from '../../copy-list-modal-base-component';
import {
  CustomerSelection,
  DivisionSelection,
} from '@app/lists/model/copy-list-options.model';

@Component({
  selector: 'app-copy-list-input',
  templateUrl: './copy-list-input-component.html',
  styleUrls: ['./copy-list-input-component.scss'],
})
export class CopyListInputComponent extends CopyListModalBaseComponent {
  @Input() selectedCopyListOption: ListCopyOptions;
  @Input() newListName: string;
  @Input() selectedList: ListsViewModel;
  @Input() listType: ListTypes;
  @Input() divisions: DivisionSelection[];
  @Input() customers: CustomerSelection[];
  @Input() customersView: CustomerSelection[];
  @Input() filteredCustomersView: CustomerSelection[];
  @Input() callBack: Function;
  @Input() marketSelectedCount: number;
  @Input() marketSelected: boolean;

  constructor(
    nav: IonNav,
    modalController: ModalController,
    listsService: ListsService,
    popoverController: PopoverController,
  ) {
    super(nav, popoverController, modalController, listsService);
  }

  listNameChange(event) {
    const inputValue = event.detail.value;
    this.newListName = inputValue;
  }

  save() {
    this.callBack(
      this.newListName,
      this.selectedList,
      this.listType,
      {
        divisions: this.divisions,
        marketSelected: this.marketSelected,
        marketSelectedCount: this.marketSelectedCount,
      },
      {
        customers: this.customersView,
        customerSelected: this.customerSelected,
        customerSelectedCount: this.customerSelectedCount,
      },
    );
    this.nav.pop();
  }
}
