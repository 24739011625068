import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadingSpinnerService } from '@shared/services/loading-spinner/loading-spinner.service';
import { SERVICE_REQUEST_ACTIONS } from '@usf/ngrx-list';
import { Router } from '@angular/router';
import { PATHS } from '@shared/constants/paths';
import { tap } from 'rxjs/operators';
import { MessageStoreService } from '@app/ngrx-message/services/message/message-store.service';
import { TranslateService } from '@ngx-translate/core';
import { Message } from '@app/ngrx-message/models/client/message';
import { MessageTypeEnum } from '@app/ngrx-message/constants/messageTypeEnum';

@Injectable({
  providedIn: 'root',
})
export class ClientServiceRequestEffects {
  submitRecoverySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SERVICE_REQUEST_ACTIONS.submitRecoveryOrderSuccess),
        tap(action => {
          this.loadingSpinnerService.dismissSpinnerModal();
          this.displaySubmitRecoverySuccessToast();
        }),
      ),
    { dispatch: false },
  );

  submitRecoveryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SERVICE_REQUEST_ACTIONS.submitRecoveryOrderFailure),
        tap(action => {
          this.loadingSpinnerService.dismissSpinnerModal();
          this.displaySubmitRecoveryFailureToast();
        }),
      ),
    { dispatch: false },
  );

  submitSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SERVICE_REQUEST_ACTIONS.submitRequestCreditSuccess),
        tap(action => {
          this.loadingSpinnerService.dismissSpinnerModal();
          this.router.navigate([PATHS.REQUEST_CREDIT_CONFIRMATION]);
        }),
      ),
    { dispatch: false },
  );

  submitFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SERVICE_REQUEST_ACTIONS.submitRequestCreditFailure),
        tap(action => {
          this.loadingSpinnerService.dismissSpinnerModal();
          this.displaySubmitFailToast();
        }),
      ),
    { dispatch: false },
  );

  submitPickupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SERVICE_REQUEST_ACTIONS.submitPickupRequestSuccess),
        tap(action => {
          this.loadingSpinnerService.dismissSpinnerModal();
          this.router.navigate([PATHS.REQUEST_CREDIT_CONFIRMATION]);
        }),
      ),
    { dispatch: false },
  );

  submitPickupFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SERVICE_REQUEST_ACTIONS.submitPickupRequestFailure),
        tap(action => {
          this.loadingSpinnerService.dismissSpinnerModal();
          this.displaySubmitFailToast(false);
        }),
      ),
    { dispatch: false },
  );

  displaySubmitFailToast(isCredit = true): void {
    this.messageStoreService.upsertMessage({
      type: MessageTypeEnum.error,
      display: this.translateService.instant(
        isCredit
          ? 'i18n.requestCredit.failureMessage'
          : 'i18n.requestCredit.pickUp.failureMessage',
      ),
      toast: true,
      sticky: true,
    } as Message);
  }

  displaySubmitRecoverySuccessToast(): void {
    this.messageStoreService.upsertMessage({
      type: MessageTypeEnum.success,
      display: this.translateService.instant(
        'i18n.requestCredit.recoveryOrder.successMessage',
      ),
      toast: true,
      sticky: false,
    } as Message);
  }

  displaySubmitRecoveryFailureToast(): void {
    this.messageStoreService.upsertMessage({
      type: MessageTypeEnum.error,
      display: this.translateService.instant(
        'i18n.requestCredit.recoveryOrder.failureMessage',
      ),
      toast: true,
      sticky: false,
    } as Message);
  }

  constructor(
    private actions$: Actions,
    private router: Router,
    private loadingSpinnerService: LoadingSpinnerService,
    private messageStoreService: MessageStoreService,
    private translateService: TranslateService,
  ) {}
}
