<ion-item lines="none">
  <div class="wrapper" (click)="selectInventory()">
    <div
      *ngIf="inventory?.isCompleted"
      class="complete-banner"
      [attr.data-cy]="'completed-banner-' + index"
    >
      <p>{{ 'i18n.inventory.complete' | translate }}</p>
    </div>
    <div class="label-container">
      <div class="label">
        <p [attr.data-cy]="'inventory-name-' + index" class="name bold">
          {{ inventory?.inventoryName }}
        </p>
        <p
          [attr.data-cy]="'last-updated-' + index"
          class="date"
          *ngIf="!!showDate"
        >
          {{ inventory?.lastUpdated }}
        </p>
        <p
          [attr.data-cy]="'department-' + index"
          class="date"
          *ngIf="!!showDepartment"
        >
          {{ inventory?.departmentName | titlecase }}
        </p>
        <p [attr.data-cy]="'inventory-value-' + index" class="value">
          Value: {{ inventory?.value }}
        </p>
      </div>
    </div>
    <div class="check-container">
      <div class="badge">
        <ion-badge
          [attr.data-cy]="'selectable-inventory-num-products-icon' + index"
          class="bold inventory-badge"
          mode="ios"
        >
          {{ inventory?.count }}
        </ion-badge>
      </div>
      <ion-checkbox
        value="{{ inventory }}"
        [checked]="inventory?.selected"
        mode="ios"
        justify="space-between"
        labelPlacement="start"
        class="checkbox"
        [attr.data-cy]="'radio-checkbox-' + index"
      >
      </ion-checkbox>
    </div>
  </div>
</ion-item>
