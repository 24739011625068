import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  IonNav,
  ModalController,
  Platform,
  PopoverController,
  ViewWillEnter,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '@panamax/app-state';
import { WarningPopoverComponent } from '../../../../../shared/components/popovers/warning-popover/warning-popover.component';
import { ListActions } from '../../../../../shared/constants/lists-constants';
import { StateAbbreviations } from '../../../../../shared/constants/state-abbreviations';
import { ManageVendorsModel } from '../../model/manage-vendors.model';
import { ExternalVendorService } from '../../services/external-vendor.service';
import { CreateEditNonUsfVendorInputComponent } from './create-edit-non-usf-vendor-input/create-edit-non-usf-vendor-input.component';
import { ExternalVendorState } from '@usf/ngrx-product';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-edit-non-usf-vendor',
  templateUrl: './create-edit-non-usf-vendor.component.html',
  styleUrls: ['./create-edit-non-usf-vendor.component.scss'],
})
export class CreateEditNonUsfVendorComponent implements OnInit, ViewWillEnter {
  @Input() selectedState: string;
  @Input() mode: string;
  @Input() selectedVendor: ExternalVendorState;
  @Input() externalVendorData: ManageVendorsModel;

  readonly ListActions = ListActions;
  nonUsfVendorForm: UntypedFormGroup;
  isPopoverOpen = false;
  vendorIdExists = false;
  states: string[] = [];
  originalVendorDetails = {};
  formSub$: Subscription;
  initialForm: any;
  valuesDiffer: boolean;
  showPasteMsg = false;

  constructor(
    private fb: UntypedFormBuilder,
    public nav: IonNav,
    private modalController: ModalController,
    private popoverController: PopoverController,
    readonly platformService: PlatformService,
    private externalVendorService: ExternalVendorService,
    private translateService: TranslateService,
    readonly platform: Platform,
  ) {}

  ngOnInit(): void {
    this.nonUsfVendorForm = this.fb.group({
      code: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          this.getVendorIdExists.bind(this),
        ]),
      ],
      name: ['', Validators.required],
      address1: [''],
      address2: [''],
      city: ['', Validators.pattern('^([a-zA-Z0-9]+ ?)*$')],
      state: [''],
      zip: [
        '',
        Validators.compose([
          Validators.pattern('^[0-9]{5}$|[0-9]{5}-[0-9]{4}$'),
        ]),
      ],
      phone: [''],
      ext: [''],
      contact: [''],
      terms: [''],
      comment: [''],
      email: ['', Validators.compose([Validators.email])],
    });

    Object.keys(StateAbbreviations).forEach(abbreviation => {
      this.states.push(abbreviation);
    });

    if (this.mode === ListActions.edit) {
      this.setInitialFormValues();
      this.formSub$ = this.nonUsfVendorForm.valueChanges.subscribe(form => {
        this.checkSubmit();
      });
    }
  }

  ionViewWillEnter(): void {
    if (this.nav?.rootParams?.selectedStateInput) {
      this.selectedState = this.nav?.rootParams?.selectedStateInput;
      this.nonUsfVendorForm?.controls['state'].setValue(this.selectedState);
    }
  }

  setInitialFormValues() {
    this.originalVendorDetails = {
      code: this.selectedVendor?.vendorId,
      name: this.selectedVendor?.distributorName,
      address1: this.selectedVendor?.address1,
      address2: this.selectedVendor?.address2,
      city: this.selectedVendor?.city,
      state: this.selectedVendor?.state,
      zip: this.selectedVendor?.zip,
      phone: this.selectedVendor?.telephoneNumber,
      ext: this.selectedVendor?.extensionNumber,
      contact: this.selectedVendor?.contactName,
      terms: this.selectedVendor?.vendorTerms,
      comment: this.selectedVendor?.comment,
      email: this.selectedVendor?.email,
    };
    this.nonUsfVendorForm.patchValue(this.originalVendorDetails);
    this.nonUsfVendorForm.controls['code'].disable();
    this.selectedState = this.selectedVendor?.state;
    this.initialForm = this.nonUsfVendorForm.value;
  }

  async setChevronDirection() {
    this.isPopoverOpen = !!(await this.popoverController.getTop());
  }

  async openStateOptionsTouch() {
    await this.nav.push(CreateEditNonUsfVendorInputComponent, {
      selectedState: this.selectedState,
      states: this.states,
    });
  }

  async dismissModal() {
    await this.modalController.dismiss();
    if (this.formSub$) this.formSub$.unsubscribe();
  }

  async onSaveExternalVendor() {
    const formValues = this.nonUsfVendorForm.value;
    if (this.nonUsfVendorForm.invalid) {
      return;
    }

    let vendorDetails: any = {
      vendorId: formValues.code,
      distributorName: formValues.name,
      address1: formValues.address1,
      address2: formValues.address2,
      city: formValues.city,
      state: formValues.state,
      zip: formValues.zip,
      telephoneNumber: formValues?.phone?.replace(/\D/g, ''),
      extensionNumber: formValues.ext,
      contactName: formValues.contact,
      vendorTerms: formValues.terms,
      comment: formValues.comment,
      email: formValues.email,
    };

    if (!this.externalVendorData?.online) {
      const popover = await this.popoverController.create({
        component: WarningPopoverComponent,
        cssClass: 'warning-popover',
        mode: 'ios',
        translucent: true,
        componentProps: {
          title: this.translateService.instant('i18n.home.unableToConnect'),
          description: this.translateService.instant(
            'i18n.home.networkWarning',
          ),
        },
      });
      await popover.present();
    } else if (this.mode === ListActions.create) {
      this.externalVendorService.createExternalVendor(vendorDetails);
    } else {
      vendorDetails = {
        ...vendorDetails,
        vendorId: this.selectedVendor?.vendorId,
      };
      this.externalVendorService.editExternalVendor(
        this.originalVendorDetails,
        vendorDetails,
      );
      this.externalVendorService.setSelectedVendors(
        this.selectedVendor?.vendorId,
        !this.selectedVendor?.isSelected,
      );
    }
    await this.dismissModal();
  }

  getVendorIdExists(control: UntypedFormControl): { [s: string]: boolean } {
    this.vendorIdExists = this.externalVendorService.vendorIdExists(
      this.externalVendorData?.vendors,
      control.value,
    );

    if (this.vendorIdExists) {
      return { vendorIdExists: true };
    }
    return null;
  }

  checkSubmit() {
    this.valuesDiffer = Object.keys(this.initialForm).some(
      key => this.nonUsfVendorForm.value[key] !== this.initialForm[key],
    );
  }

  pasteFired(event: any, control: string) {
    // patch value forces a valueChange on the form which otherwise does not fire due to directive
    this.nonUsfVendorForm.controls[control].patchValue(event.target.value);
  }

  setShowPasteMsg(specialRemoved: boolean) {
    this.showPasteMsg = specialRemoved;
    setTimeout(() => (this.showPasteMsg = false), 5000);
  }
}
