import { ListsPageData } from '../model/lists-page.model';

export const doesListNameExist = (
  listPageData: ListsPageData,
  listName: string,
) => {
  const trimmedName = listName.toLowerCase().replace(/\s/g, '');
  if (trimmedName === 'orderguide' || trimmedName === 'recentlypurchased') {
    return true;
  }
  if (listPageData?.managedByUsfLists?.length > 0) {
    if (
      trimmedName ===
      `orderguide#${listPageData.managedByUsfLists[0].listKey.listId}`
    ) {
      return true;
    }
  }
  const listNames: string[] = [];
  listPageData?.lists?.publicLists.forEach(list => {
    listNames.push(list.listName.toLowerCase());
  });
  listPageData?.lists?.privateLists.forEach(list => {
    listNames.push(list.listName.toLowerCase());
  });
  listPageData?.lists?.internalLists.forEach(list => {
    listNames.push(list.listName.toLowerCase());
  });
  return listNames.includes(listName.toLowerCase());
};
