import { Injectable, OnDestroy } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import {
  PanAppState,
  SelectedCustomerState,
  TokenAuthContext,
  UsfTokenStorageService,
} from '@panamax/app-state';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { Customer } from '@usf/customer-types';
import { LoginService } from 'src/app/login/services';
import { filter, first, map, take, tap } from 'rxjs/operators';
import { Params } from '@angular/router';
import { CustomerStoreService } from '@app/ngrx-customer/services';
import { CustomerSwitchStatus } from '@app/ngrx-customer/constants/customer-switch-status';
import {
  selectCustomerSwitchStatus,
  selectedCustomer,
} from '@app/ngrx-customer/store';
import { Store } from '@ngrx/store';
import { PATHS } from '@shared/constants/paths';

@Injectable({
  providedIn: 'root',
})
export class CustomerSwitchGuardService implements CanActivate, OnDestroy {
  selectedCustomerState$: Subscription;
  deepLinkCustomer$: Subscription;
  selectedCustomer: SelectedCustomerState;
  currentCustomerDetails: Params;

  constructor(
    private store: Store,
    public router: Router,
    private loginService: LoginService,
    private panAppState: PanAppState,
    private customerStoreService: CustomerStoreService,
    private tokenStorageService: UsfTokenStorageService,
  ) {}

  ngOnDestroy(): void {
    if (this.selectedCustomerState$) {
      this.selectedCustomerState$.unsubscribe();
    }
    if (this.deepLinkCustomer$) {
      this.deepLinkCustomer$.unsubscribe();
    }
  }

  waitForCustomerSwitchStatus(): Observable<CustomerSwitchStatus> {
    return this.store
      .select(selectCustomerSwitchStatus)
      .pipe(
        filter(
          (custSwitchStatus: CustomerSwitchStatus) =>
            custSwitchStatus == CustomerSwitchStatus.complete,
        ),
      );
  }

  /** Attempts to request token for requested customer from query params, always returns true */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const queryParams = route.queryParamMap;
    const customerNumber = Number(queryParams.get('customerNumber'));
    const departmentNumber = Number(queryParams.get('departmentNumber')) || 0;
    const divisionNumber = Number(queryParams.get('divisionNumber'));
    if (customerNumber && divisionNumber) {
      // if the link is requesting a customer, check if it matches current customer
      return combineLatest([
        this.panAppState.customer$,
        this.store.select(selectCustomerSwitchStatus),
      ]).pipe(
        filter(
          ([selectedCustomer, customerSwitchStatus]) => !!selectedCustomer,
        ),
        map(([selectedCustomer, customerSwitchStatus]) => {
          if (
            customerNumber === selectedCustomer?.customerNumber &&
            departmentNumber === selectedCustomer?.departmentNumber &&
            divisionNumber === selectedCustomer?.divisionNumber
          ) {
            return true;
          }

          if (customerSwitchStatus === CustomerSwitchStatus.failed) {
            this.router.navigate([PATHS.HOME]);
          }

          if (
            selectedCustomer &&
            (selectedCustomer?.customerNumber !== customerNumber ||
              selectedCustomer?.departmentNumber !== departmentNumber ||
              selectedCustomer?.divisionNumber !== divisionNumber)
          ) {
            // if not matching the current customer, attempt to switch to new customer
            const newCustomer = { customerNumber, divisionNumber } as Customer;
            this.loginService.switchToCustomer(
              newCustomer.customerNumber,
              departmentNumber,
              newCustomer.divisionNumber,
            );
          }
        }),
      );
    }
    return of(true);
  }

  /** Attempts to switch to customer based on deeplink query param */
  async customerSwitchFromDeeplink(params: Params): Promise<void> {
    return new Promise(async done => {
      if (!params.customerNumber) return done();
      let customerNumberFromDeepLink = +params.customerNumber;

      let authContext = await this.getContext();
      this.currentCustomerDetails = {
        customerNumber: authContext.customerNumber,
        departmentNumber: authContext.departmentNumber,
      };

      if (
        this.currentCustomerDetails.customerNumber ===
          customerNumberFromDeepLink &&
        this.currentCustomerDetails.departmentNumber ===
          +params.departmentNumber
      )
        return done();

      this.deepLinkCustomer$ = this.customerStoreService
        .customerWithNumber$(customerNumberFromDeepLink)
        ?.pipe(
          filter(customer => !!customer),
          take(1),
        )
        .subscribe((customer: Customer) => {
          if (!customer) return done();
          let departmentNumber = params.departmentNumber ?? '0';
          if (
            departmentNumber == '0' &&
            customer?.departments &&
            customer?.departments?.length > 0
          ) {
            departmentNumber = customer?.departments[0]?.departmentNumber;
          }
          this.loginService.switchToCustomer(
            customer.customerNumber,
            parseInt(departmentNumber),
            customer.divisionNumber,
          );
          return done();
        });
    });
  }

  async getContext(): Promise<TokenAuthContext> {
    return this.tokenStorageService.getContext().toPromise();
  }
}
