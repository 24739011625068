import { Injectable } from '@angular/core';
import { Store, createSelector } from '@ngrx/store';
import { selectOnline } from '@panamax/app-state';
import { State } from '@panamax/app-state/lib/reducers/analytics-meta-reducer';
import { debounceTime, filter, merge, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnlineBufferService {
  // update to have online/offline delays and status
  public toOfflineDelay = 1000;
  public toOnlineDelay = 5000;

  // true = online
  lastOnlineStatus = true;

  constructor(private store: Store<State>) {}

  operateOnlineCallbackAfterDelay = (
    initialOnlineStatus: boolean,
    functionOnline: Function,
    functionOffline: Function,
    onlineParam = undefined,
    offlineParam = undefined,
  ) => {
    this.lastOnlineStatus = initialOnlineStatus;

    return merge(
      this.store
        .select(
          createSelector(selectOnline(), isOnline => {
            return isOnline;
          }),
        )
        .pipe(
          debounceTime(this.toOfflineDelay),
          filter(isOnline => isOnline !== this.lastOnlineStatus),
          tap(isOnline => {
            if (!isOnline) {
              this.lastOnlineStatus = isOnline;
              if (offlineParam !== undefined) {
                functionOffline(offlineParam);
              } else {
                functionOffline();
              }
            }
          }),
        ),
      this.store
        .select(
          createSelector(selectOnline(), isOnline => {
            return isOnline;
          }),
        )
        .pipe(
          debounceTime(this.toOnlineDelay),
          filter(isOnline => isOnline !== this.lastOnlineStatus),
          tap(isOnline => {
            if (isOnline) {
              this.lastOnlineStatus = isOnline;
              if (onlineParam !== undefined) {
                functionOnline(onlineParam);
              } else {
                functionOnline();
              }
            }
          }),
        ),
    );
  };
}
