import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import {
  productPricingAdapter,
  selectProductPricingState,
} from '@usf/ngrx-pricing';
import { ProductPricing } from '@usf/price-types';
import {
  Order,
  OrderHeader,
  orderHeaderSelectors,
  OrderItem,
  orderItemSelectors,
  OrderItemState,
  orderSelectors,
  OrderStatus,
} from '@usf/ngrx-order';

import { selectedCustomer } from '@app/ngrx-customer/store';
import { selectAllFeaturesEnabled } from '@panamax/app-state';
import { FEATURES } from '@shared/constants/splitio-features';
import { Customer } from '@usf/customer-types';
import { calculatePrice } from '@shared/selectors/helpers/order-total-price.selectors.helper';
import { ProductSummaryState } from '@usf/ngrx-product/lib/models/product-summary-state.model';
import {
  selectProductSummaryEntities,
  selectProductInventoryEntities,
} from '@usf/ngrx-product';
import { ProductInventoryState } from '@usf/ngrx-product/lib/models/product-inventory-state.model';
import {
  PRONTO_MINIMUM_ORDER_CASES,
  PRONTO_MINIMUM_ORDER_DOLLARS,
} from '@shared/constants/pronto-constants';
import {
  getProntoCutoffDayIndexes,
  isProntoEnabled,
} from '@shared/helpers/pronto.helpers';
export const selectOrderMinimumViewModel = (
  paramOrderHeader?: OrderHeader,
  prontoDivisionFlag?: Boolean,
) =>
  createSelector(
    orderHeaderSelectors.selectOrderHeader,
    selectedCustomer,
    orderSelectors.getAllMyOrders,
    selectProductSummaryEntities,
    selectProductInventoryEntities,
    productPricingAdapter.getSelectors(selectProductPricingState)
      .selectEntities,
    orderItemSelectors.selectOrderItemState,
    selectAllFeaturesEnabled([FEATURES.split_global_min_order_met]),
    selectAllFeaturesEnabled([FEATURES.split_division_order_pronto]),
    (
      selectedOrderHeader: OrderHeader,
      selectedCustomer: Customer,
      orders: Order[],
      productSummaryEntities: Dictionary<ProductSummaryState>,
      productInventoryEntities: Dictionary<ProductInventoryState>,
      pricingState: Dictionary<ProductPricing>,
      orderItemState: OrderItemState,
      minOrderMetFlagEnabled: Boolean,
      prontoFlagEnabled: Boolean,
    ) => {
      let orderHeader = paramOrderHeader || selectedOrderHeader;
      let dollars = 0;
      let cases = 0;
      let previousRemainingDollars = 0;
      let previousRemainingCases = 0;
      let minOrderDollars = 0;
      let minOrderCases = 0;
      let hasOnlyVSItems = false;
      const noPriceCustomer = selectedCustomer?.ogPrintPriceInd === 'N';
      const utcDay = new Date(orderHeader?.requestedDeliveryDate).getUTCDay();
      const prontoDays = getProntoDeliveryDays(selectedCustomer);
      const isProntoOrder =
        orderHeader?.orderStatus === OrderStatus.IN_PROGRESS &&
        (prontoDivisionFlag || prontoFlagEnabled) &&
        !!prontoDays
          ? prontoDays.includes(utcDay)
          : false;

      if (minOrderMetFlagEnabled && !!orderHeader?.orderStatus) {
        const isSubmitted =
          orderHeader.orderStatus === OrderStatus.SUBMITTED ||
          orderHeader.orderStatus === OrderStatus.SUBMITTED_CREDIT_HOLD ||
          orderHeader.orderStatus === OrderStatus.SUBMITTED_WITH_EXCEPTIONS;
        minOrderDollars = isProntoOrder
          ? PRONTO_MINIMUM_ORDER_DOLLARS
          : selectedCustomer?.minOrderDollars || 0;
        minOrderCases = isProntoOrder
          ? PRONTO_MINIMUM_ORDER_CASES
          : selectedCustomer?.minOrderCases || 0;
        previousRemainingDollars = minOrderDollars;
        previousRemainingCases = minOrderCases;
        let dateStr = getDateStr(orderHeader.requestedDeliveryDate);
        if (!!paramOrderHeader) {
          dateStr = getDateStr(orderHeader.confirmedDeliveryDate);
        } else if (
          orderHeader.orderStatus === OrderStatus.IN_PROGRESS ||
          isSubmitted
        ) {
          if (isSubmitted)
            dateStr = getDateStr(orderHeader.confirmedDeliveryDate);
          const orderItems = [];
          //populate orderItems
          orderItemState?.ids?.forEach(productNumber => {
            //get orderItem entity from productNumber id
            if (
              orderItemState?.entities[productNumber]?.specialVendor === false
            ) {
              orderItems.push(orderItemState.entities[productNumber]);
            }
          });
          if (orderItems.length > 0) {
            const orderTotal = calculateOrderTotalPrice(
              productSummaryEntities,
              productInventoryEntities,
              pricingState,
              orderItems,
            );
            dollars = orderTotal?.totalDollars || 0;
            cases = orderTotal?.totalCases || 0;
          } else {
            hasOnlyVSItems = true;
          }
        }
        orders
          ?.filter(
            orderItem =>
              (orderItem?.orderHeader?.orderStatus === OrderStatus.SUBMITTED ||
                orderItem?.orderHeader?.orderStatus ===
                  OrderStatus.SUBMITTED_CREDIT_HOLD ||
                orderItem?.orderHeader?.orderStatus ===
                  OrderStatus.SUBMITTED_WITH_EXCEPTIONS) &&
              orderItem?.orderHeader?.orderType === 'RT' &&
              orderItem?.orderHeader?.confirmedDeliveryDate
                ?.toString()
                .indexOf(dateStr) === 0 &&
              orderItem?.orderHeader?.customerNumber ===
                orderHeader.customerNumber &&
              orderItem?.orderHeader?.divisionNumber ===
                orderHeader.divisionNumber,
          )
          .map(orderItem => {
            const orderTotal = calculateOrderTotalPrice(
              productSummaryEntities,
              productInventoryEntities,
              pricingState,
              orderItem.orderItems,
            );
            previousRemainingDollars -= orderTotal?.totalDollars;
            previousRemainingCases -= orderTotal?.totalCases;
            if (
              !!paramOrderHeader ||
              orderItem?.orderHeader?.tandemOrderNumber !=
                orderHeader?.tandemOrderNumber
            ) {
              dollars += orderTotal?.totalDollars || 0;
              cases += orderTotal?.totalCases || 0;
            }
          });
      }

      return {
        totalDollars: dollars,
        totalCases: cases,
        customerMinOrderDollars: minOrderDollars,
        customerMinOrderCases: minOrderCases,
        remainingDollars: noPriceCustomer
          ? minOrderDollars
          : minOrderDollars - dollars,
        remainingCases: minOrderCases - cases,
        previousRemainingDollars: noPriceCustomer
          ? minOrderDollars
          : previousRemainingDollars,
        previousRemainingCases,
        noPriceCustomer,
        isMet: minOrderDollars - dollars <= 0 && minOrderCases - cases <= 0,
        hasOnlyVSItems,
      };
    },
  );

const getDateStr = (date: Date | string) => {
  try {
    if (typeof date === 'string') {
      const ymd = date.split('-');
      return ymd[0] + '-' + ymd[1] + '-' + ymd[2].substring(0, 2);
    } else {
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate())
      );
    }
  } catch (error) {
    return 'N/A';
  }
};

export const calculateOrderTotalPrice = (
  productSummaryEntities: Dictionary<ProductSummaryState>,
  productInventoryEntities: Dictionary<ProductInventoryState>,
  pricingState: Dictionary<ProductPricing>,
  orderItems: OrderItem[],
): totalOrderResult => {
  let totalDollars = 0;
  let totalCases = 0;

  orderItems
    ?.filter(item => item?.specialVendor === false)
    .forEach(orderItem => {
      const productNumber = orderItem?.productNumber;
      const productSummary = productSummaryEntities[productNumber];
      const productInventory = productInventoryEntities[productNumber];

      const unitsOrdered = orderItem?.unitsOrdered.currentValue;
      const eachesOrdered = orderItem?.eachesOrdered.currentValue;
      let unitPrice = '0';

      //remaining item in order including previously changed hand-pricing
      if (!!orderItem?.unitPrice) {
        unitPrice = orderItem.unitPrice.toString();
      } else if (!!orderItem?.unitPriceOverride) {
        unitPrice = orderItem.unitPriceOverride.toString();
      } else if (!!pricingState[productNumber]?.unitPrice) {
        unitPrice = pricingState[productNumber].unitPrice.toString();
      }

      totalCases += unitsOrdered || 0;
      totalDollars +=
        calculatePrice(
          unitsOrdered,
          unitPrice,
          true,
          productSummary?.catchWeightFlag,
          productSummary?.netWeight,
          productInventory?.eachConversionFactor,
        ) +
          calculatePrice(
            eachesOrdered,
            pricingState[productNumber]?.eachPrice?.toString(),
            false,
            productSummary?.catchWeightFlag,
            productSummary?.netWeight,
            productInventory?.eachConversionFactor,
          ) || 0;
    });

  return { totalDollars, totalCases };
};

const getProntoDeliveryDays = (customer: Customer): Number[] => {
  return isProntoEnabled(customer)
    ? getProntoCutoffDayIndexes(customer)
    : undefined;
};

interface totalOrderResult {
  totalDollars: number;
  totalCases: number;
}
