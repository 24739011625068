import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-promote-app',
  templateUrl: './promote-app.component.html',
  styleUrls: ['./promote-app.component.scss'],
})
export class PromoteAppComponent implements OnInit {
  @Input() operatingSystem: string;
  imgSrc: string;
  appLink: string;
  constructor(private modalController: ModalController) {}

  ngOnInit(): void {
    this.imgSrc =
      this.operatingSystem === 'android'
        ? 'assets/images/google-play-store-logo.png'
        : 'assets/images/app-store-logo.png';

    this.appLink =
      this.operatingSystem === 'android'
        ? 'https://play.google.com/store/apps/details?id=com.usfoods.ecomr4'
        : 'https://apps.apple.com/us/app/us-foods-moxē/id1597763452';
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  dismiss() {
    this.closeModal();
  }
}
