import { AnalyticsTransformer, TrackingData } from '@panamax/app-state';

export const submitIpOrderSuccessWithExceptionsTraceTransformer: AnalyticsTransformer = (
  trackingData: TrackingData,
) => ({
  isStartOfTrace: trackingData.data.isStartOfTrace,
  isEndOfTrace: trackingData.data.isEndOfTrace,
  event: trackingData.data.attributes?.event,
  order: {
    id: trackingData.data.attributes?.id,
    errorDetails: JSON.stringify(trackingData.data.attributes?.errorDetails),
  },
});

export const submitEditOrderSuccessWithExceptionsTraceTransformer: AnalyticsTransformer = (
  trackingData: TrackingData,
) => ({
  isStartOfTrace: trackingData.data.isStartOfTrace,
  isEndOfTrace: trackingData.data.isEndOfTrace,
  event: trackingData.data.attributes?.event,
  order: {
    id: trackingData.data.attributes?.id,
    tandemOrderId: trackingData.data.attributes?.tandemOrderId,
    errorDetails: JSON.stringify(trackingData.data.attributes?.errorDetails),
  },
});

export const addToOrderSuccessWithExceptionsTraceTransformer: AnalyticsTransformer = (
  trackingData: TrackingData,
) => ({
  isStartOfTrace: trackingData.data.isStartOfTrace,
  isEndOfTrace: trackingData.data.isEndOfTrace,
  event: trackingData.data.attributes?.event,
  products: trackingData.data?.attributes?.products,
  order: {
    id: trackingData.data?.attributes?.order.id,
    requestedDeliveryDate:
      trackingData.data?.attributes?.order.requestedDeliveryDate,
    confirmedDeliveryDate:
      trackingData.data?.attributes?.order.confirmedDeliveryDate,
    errorDetails: JSON.stringify(
      trackingData.data?.attributes?.order.errorDetails,
    ),
  },
});

export const addToOrderFailureTraceTransformer: AnalyticsTransformer = (
  trackingData: TrackingData,
) => ({
  isStartOfTrace: trackingData.data.isStartOfTrace,
  isEndOfTrace: trackingData.data.isEndOfTrace,
  event: trackingData.data.attributes?.event,
  products: trackingData.data?.attributes?.products,
  order: {
    id: trackingData.data?.attributes?.order.id,
    totalAmt: trackingData.data?.attributes?.order?.totalAmt,
    requestedDeliveryDate:
      trackingData.data?.attributes?.order.requestedDeliveryDate,
    errorDetails: trackingData.data?.attributes?.order.errorDetails,
    confirmedDeliveryDate:
      trackingData.data?.attributes?.order.confirmedDeliveryDate,
  },
});

/**
 * NEW ORDER SUBMIT: WEB SOCKET ARRIVAL TRACING
 *
 * @param tracking
 * @returns Data Layer needed for tracking the submit order WEBSOCKET tracing event
 */
export const submitOrderWebsocketTracing = (tracking: TrackingData) => {
  const result = {
    ...tracking?.data?.attributes,
  };
  return result;
};

/**
 * SUBMIT ORDER
 *
 * @param tracking
 * @returns Data Layer needed for tracking the submit order event
 */
export const submitOrder = (tracking: TrackingData) => {
  const result = {
    event: tracking.data.event,
    capability: tracking.data.capability,
    order: tracking.data.order,
    products: tracking.data.products,
  };

  return result;
};

export const submitOrderSuccessTracing = (trackingData: TrackingData) => {
  const result = {
    event: trackingData.data.attributes?.event,
    order: {
      orderId: trackingData.data.attributes?.order?.orderId,
      totalAmount: trackingData.data.attributes?.order?.totalAmount,
    },
    products: trackingData.data.attributes?.products,
  };
  return result;
};
