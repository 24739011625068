import { Injectable } from '@angular/core';
import { State } from '@app/store';
import { ModalWithNavComponent } from '@shared/components/modal-with-nav/modal-with-nav.component';
import {
  InventoryActions,
  InventoryEllipsisModalModes,
} from '@inventory/constants/inventory-constants';
import {
  ActionSheetController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  PlatformEnum,
  PlatformService,
  Tracking,
  selectOnline,
} from '@panamax/app-state';
import { UsfProductCardModeEnum } from '@shared/constants/usf-product-card-mode.enum';
import { Button } from '@shared/models/ellipsis-option.model';
import { InventoryGroup } from '@usf/inventory-types';
import {
  GroupPosition,
  InventoryGroupHeader,
  InventoryGroupItemPair,
  InventoryItemEditOptions,
  InventoryItemModalOptions,
  InventoryAnalyticsConstants,
  InventoryTracingConstants,
  INVENTORY_ACTIONS,
  INVENTORY_GROUP_ACTIONS,
  INVENTORY_ITEM_ACTIONS,
  selectInventoryItemModalOptions,
  selectInventoryOptionPrice,
  deleteGroupDeleteItems,
  deleteGroupMoveItems,
  priceOverrideTransformer,
  updateInventoryQuantity,
  selectInventoryOnline,
  ANALYTICS_ACTIONS,
  pageLoadedTransformer,
  clickButtonTransformer,
  downloadInventoryTransformer,
  downloadCostReportInventoryTransformer,
  InventoryWorksheetCountSortOptions,
  SortDirections,
  InventoryWorksheetEditSortOptions,
} from '@usf/ngrx-inventory';
import { combineLatest, Observable } from 'rxjs';
import { first, map, take, tap } from 'rxjs/operators';
import { EllipsisOptionsPopoverComponent } from '../../../../shared/components/popovers/ellipsis-options/ellipsis-options-popover.component';
import { NavigationHelperService } from '../../../../shared/helpers/navigation.helpers.service';
import { ModalService } from '../../../../shared/services/modal/modal.service';
import {
  InventoryConversionFactorUpdate,
  InventoryCustomUOMEvent,
  InventoryGroupDropdownEvent,
  InventoryGroupUpdate,
  InventoryItemSelectionUpdate,
  InventoryItemUOMEvent,
  InventoryItemUpdate,
  InventoryLineNumberEvent,
  InventoryPriceUpdate,
  InventoryUOMUpdate,
} from '../../../models/edit-inventory.model';
import { InventoryQuantityUpdate } from '../../../models/inventory-quantity.model';
import {
  InventoryProductViewModel,
  InventoryViewModel,
} from '../../../models/inventory-view.model';
import { InventoryWorksheetViewModel } from '../../../models/inventory-worksheet-view-model.model';
import {
  inventoryWorksheetView,
  selectInventoryNameDateValidator,
  selectInventoryWorksheetViewModel,
  selectMultipleInventoryWorksheetViewModels,
} from '../../../selectors/inventory-view-model.selector';
import { InventoryService } from '../../../services/inventory.service';
import { InventoryActionModalMobileComponent } from '../components/inventory-action-modal/inventory-action-modal-mobile/inventory-action-modal-mobile.component';
import { InventoryActionModalDesktopTabletComponent } from '../components/inventory-action-modal/inventory-action-modal-desktop-tablet/inventory-action-modal-desktop-tablet.component';
import { DeleteGroupModalComponent } from '../../../../shared/components/delete-group-modal/delete-group-modal.component';
import { ListConstants } from '@usf/ngrx-list';
import { InventoryDeleteModalComponent } from '../components/inventory-delete-modal/inventory-delete-modal.component';
import { MessageStoreService } from '@app/ngrx-message/services/message/message-store.service';
import { MessageTypeEnum } from '@app/ngrx-message/constants/messageTypeEnum';
import { Message } from '@app/ngrx-message/models/client/message';
import { ItemTypes } from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { DatePipe } from '@angular/common';
import { genericAnalyticsTransformer } from '@shared/tracking/analytics/generic-analytics-transformer';
import { PATHS } from '@shared/constants/paths';
import { OnlineBufferService } from '@shared/services/online/online-buffer.service';
import {
  InventorySort,
  InventoryState,
} from '@usf/ngrx-inventory/lib/models/inventory/inventory.model';
import { convertStringToDate } from '@shared/helpers/calendar.helpers';
import { CreateInventoryValidationErrors } from '@inventory/models/validation-errors-model';
import { ToggleNewSearchService } from '@shared/services/toggle-new-search/toggle-new-search.service';
import { ModalSort } from '@shared/components/usf-filtering-modal/model/modal-filter';
import { SortDirection } from '@usf/ngrx-payment';
import { SortOption, SortSelection } from '@shared/models/sort.model';

@Injectable({
  providedIn: 'root',
})
export class InventoryWorksheetService {
  disableCompleteButton = false;
  disableAddGroupButton = false;
  allCatchWeight = true;
  someCatchWeight = false;
  catchWeightCount = 0;
  nonCatchWeightCount = 0;
  activeInventoryId = '';

  constructor(
    public inventoryService: InventoryService,
    private store: Store<State>,
    readonly platformService: PlatformService,
    private popoverController: PopoverController,
    private modalService: ModalService,
    private modalController: ModalController,
    private navigationHelperService: NavigationHelperService,
    private translateService: TranslateService,
    private actionSheetController: ActionSheetController,
    private messageService: MessageStoreService,
    private onlineBufferService: OnlineBufferService,
    readonly datePipe: DatePipe,
    private readonly toggleNewSearchService: ToggleNewSearchService,
  ) {}

  // OBSERVABLE SELECTORS
  inventoryWorksheetView$ = (
    platform: PlatformEnum,
    id: string,
  ): Observable<InventoryWorksheetViewModel> => {
    this.inventoryService.exposedLastInventory = id;
    return combineLatest([
      this.store.select(inventoryWorksheetView(platform, id)),
      this.selectInventoryPrice$(),
      this.selectInventoryItemModalOptions$(),
      this.inventoryService.inventoryStateIsLoaded$(),
      this.store.select(selectInventoryOnline()),
    ]).pipe(
      map(
        ([
          inventory,
          priceToggle,
          inventoryItemModalOptions,
          isInventoryDataLoaded,
          isOnline,
        ]) => {
          return {
            inventory,
            priceToggle,
            inventoryItemModalOptions,
            isInventoryDataLoaded,
            isOnline,
          } as InventoryWorksheetViewModel;
        },
      ),
    );
  };

  public nameDateValidations$ =
    (): Observable<CreateInventoryValidationErrors> => {
      return this.store.select(selectInventoryNameDateValidator).pipe(
        map(nameDateInvalid => {
          return { nameDateInvalid };
        }),
      );
    };

  onlineWithDelay = (inventoryId: string) => {
    let onlineStatus = true;
    this.store
      .select(selectOnline())
      .pipe(
        first(),
        tap(value => (onlineStatus = value)),
        tap(value =>
          this.store.dispatch(
            INVENTORY_ACTIONS.setOnlineFlagWithoutMessage({ online: value }),
          ),
        ),
      )
      .subscribe();
    return this.onlineBufferService.operateOnlineCallbackAfterDelay(
      onlineStatus,
      this.dispatchOnline,
      this.dispatchOffline,
      inventoryId,
    );
  };

  selectInventoryPrice$ = (): Observable<string> => {
    return this.store.select(selectInventoryOptionPrice());
  };

  selectInventoryItemModalOptions$ =
    (): Observable<InventoryItemModalOptions> => {
      return this.store.select(selectInventoryItemModalOptions());
    };

  selectMultipleInventoryWorksheetViewModels$ = (
    inventories: InventoryState[],
    ignoreSearch: boolean,
  ) => {
    return this.store.select(
      selectMultipleInventoryWorksheetViewModels(
        this.platformService.platformType,
        inventories,
        ignoreSearch,
      ),
    );
  };

  selectInventoryWorksheetViewModel$ = (inventoryId: string) => {
    return this.store.select(
      selectInventoryWorksheetViewModel(
        this.platformService.platformType,
        inventoryId,
      ),
    );
  };

  // INVENTORY ACTIONS
  updateInventoryWorksheetMode(inventoryWorksheetMode: string) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventoryWorksheetMode({
        inventoryWorksheetMode,
      }),
    );
  }

  async openUnitPopoverInItemModal(event: InventoryItemUOMEvent) {
    const buttons = this.getUnitPopoverButtons(
      event.catchWeightFlag,
      event.customUOM,
    );
    if (this.platformService.isTouch.value) {
      buttons.push({
        text: this.translateService.instant('i18n.common.cancel'),
        role: 'cancel',
        cssClass: 'cancel-button',
      });
      const actionSheet = await this.actionSheetController.create({
        cssClass: 'action-sheet-touch',
        mode: 'ios',
        buttons,
      });
      await actionSheet.present();
    } else {
      const unitPopover = await this.popoverController.create({
        component: EllipsisOptionsPopoverComponent,
        event: event.event,
        componentProps: {
          buttons,
        },
        showBackdrop: false,
        cssClass:
          this.platformService?.platformType ===
          this.platformService.platformEnum.desktop
            ? 'inventory-unit-popover-desktop'
            : 'inventory-unit-popover-touch',
        mode: 'ios',
        side: 'bottom',
        alignment: 'center',
      });
      await unitPopover.present();
    }
  }

  updateInventoryWorksheetCountSort(
    inventoryId: string,
    inventorySection: string,
    selectedHeader: string,
    sortDirection: string,
  ) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventoryWorksheetCountSort({
        inventoryId,
        inventorySection,
        selectedHeader,
        sortDirection,
      }),
    );
  }

  resetInventoryWorksheetCountSort(inventoryId: string) {
    this.store.dispatch(
      INVENTORY_ACTIONS.resetInventoryWorksheetCountSort({
        inventoryId,
      }),
    );
  }

  updateInventoryWorksheetEditSort(
    inventoryId: string,
    inventorySection: string,
    selectedHeader: string,
    sortDirection: string,
  ) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventoryWorksheetEditSort({
        inventoryId,
        inventorySection,
        selectedHeader,
        sortDirection,
      }),
    );
  }

  resetInventoryWorksheetEditSort(inventoryId: string) {
    this.store.dispatch(
      INVENTORY_ACTIONS.resetInventoryWorksheetEditSort({
        inventoryId,
      }),
    );
  }

  updateSelectedPrice(priceToggleValue: string, inventoryId: string) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateSelectedPrice({
        selectedPrice: priceToggleValue,
        inventoryId,
      }),
    );
  }

  searchInInventoryWorksheet(searchKey: string) {
    this.store.dispatch(
      INVENTORY_ACTIONS.searchInInventoryWorksheet({ searchKey }),
    );
  }

  updateInEditNameMode(inEditNameMode: boolean) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInEditNameMode({ inEditNameMode }),
    );
  }

  updateInEditGroupsMode(inEditGroupsMode: boolean) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInEditGroupsMode({ inEditGroupsMode }),
    );
  }

  getInventoryNameAndDateExists = (
    newInventoryName: string,
    date: Date | string,
  ) => {
    this.store.dispatch(
      INVENTORY_ACTIONS.setNameValidator({ newInventoryName }),
    );
    if (typeof date === 'string') {
      this.store.dispatch(
        INVENTORY_ACTIONS.setDateValidator({
          newInventoryDate: convertStringToDate(date),
        }),
      );
    } else {
      this.store.dispatch(
        INVENTORY_ACTIONS.setDateValidator({ newInventoryDate: date }),
      );
    }
  };

  resetValidation = () => {
    this.store.dispatch(
      INVENTORY_ACTIONS.setNameValidator({ newInventoryName: undefined }),
    );
    this.store.dispatch(
      INVENTORY_ACTIONS.setDateValidator({ newInventoryDate: undefined }),
    );
  };

  editInventoryName = (inventoryId: string, newInventoryName: string) => {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({ inventoryId }),
    );
    this.store.dispatch(
      INVENTORY_ACTIONS.editName({ inventoryId, newInventoryName }),
    );
  };

  completeInventory(inventoryId: string) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({ inventoryId }),
    );
    const tracking: Tracking = {
      analytics: {
        data: {
          capability: InventoryAnalyticsConstants.capability,
          event: InventoryAnalyticsConstants.completeInventory,
          inventory: { inventoryId },
        },
        transformFunc: genericAnalyticsTransformer,
      },
    };
    this.store.dispatch(INVENTORY_ACTIONS.complete({ inventoryId, tracking }));
  }

  // INVENTORY GROUP ACTIONS
  updateSelectedGroup(selectedGroup: InventoryGroupHeader) {
    this.store.dispatch(
      INVENTORY_GROUP_ACTIONS.updateSelectedGroup({
        selectedGroup,
      }),
    );
  }

  // INVENTORY ITEM ACTIONS
  selectInventoryItem = (
    inventoryItemSelection: InventoryItemSelectionUpdate,
  ) => {
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.selectInventoryItem({ ...inventoryItemSelection }),
    );
    this.countCatchWeightSelection(inventoryItemSelection);
  };

  resetInventoryItemSelections = (inventoryId: string) => {
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.resetInventoryItemSelections({ inventoryId }),
    );
    this.catchWeightCount = 0;
    this.nonCatchWeightCount = 0;
    this.allCatchWeight = false;
    this.someCatchWeight = false;
  };

  resetInventoryAddProductsCount = () => {
    this.store.dispatch(INVENTORY_ACTIONS.resetProductsAddedInventoryOption());
  };

  updateInventoryItemUOM = (inventoryUOMUpdate: InventoryUOMUpdate) => {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({
        inventoryId: inventoryUOMUpdate.inventoryId,
      }),
    );
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.updateUOM({
        inventoryItemId: inventoryUOMUpdate.inventoryItemId,
        inventoryId: inventoryUOMUpdate.inventoryId,
        groupName: inventoryUOMUpdate.groupName,
        uom: inventoryUOMUpdate.unit,
        tracking: {
          analytics: {
            data: {
              capability: InventoryAnalyticsConstants.capability,
              event: InventoryAnalyticsConstants.updateUOM,
              inventory: {
                inventoryId: inventoryUOMUpdate.inventoryId,
              },
            },
            transformFunc: genericAnalyticsTransformer,
          },
        },
      }),
    );
  };

  updateInventoryItemQuantity = (
    inventoryQuantityUpdate: InventoryQuantityUpdate,
    inventory?: InventoryViewModel,
  ) => {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({
        inventoryId: inventoryQuantityUpdate.inventoryId,
      }),
    );
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.updateQuantity({
        inventoryItemId: inventoryQuantityUpdate.inventoryItemId,
        inventoryId: inventoryQuantityUpdate.inventoryId,
        groupName: inventoryQuantityUpdate.groupName,
        isCase: inventoryQuantityUpdate.isCase,
        value: inventoryQuantityUpdate.value,
        tracking: {
          tracing: {
            data: {
              traceContext: InventoryTracingConstants.inventory,
              isEndOfTrace: false,
              isStartOfTrace: true,
              attributes: {
                spanName: InventoryTracingConstants.inventoryCountsSpan,
                event: InventoryTracingConstants.inventoryCountsEvent,
                inventoryName:
                  inventory?.inventoryName +
                  ' - ' +
                  this.datePipe.transform(inventory?.inventoryDate, 'M/d/yyyy'),
                inventoryId: inventoryQuantityUpdate.inventoryId,
                product: {
                  apn: inventoryQuantityUpdate.inventoryItemId,
                  groupName: inventoryQuantityUpdate.groupName,
                },
              },
            },
            transformFunc: updateInventoryQuantity,
          },
        },
      }),
    );
  };

  updateInventoryItemPriceOverride = (
    inventoryPriceOverrideUpdate: InventoryPriceUpdate,
  ) => {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({
        inventoryId: inventoryPriceOverrideUpdate.inventoryId,
      }),
    );
    this.trackUpdatingInventoryPrice(inventoryPriceOverrideUpdate.inventoryId);
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.updatePriceOverride({
        inventoryItemId: inventoryPriceOverrideUpdate.inventoryItemId,
        inventoryId: inventoryPriceOverrideUpdate.inventoryId,
        groupName: inventoryPriceOverrideUpdate.groupName,
        overrideValue: inventoryPriceOverrideUpdate.overrideValue,
        tracking: {
          tracing: {
            data: {
              traceContext: InventoryTracingConstants.inventory,
              isEndOfTrace: false,
              isStartOfTrace: true,
              attributes: {
                spanName: InventoryTracingConstants.priceOverrideEvent,
                event: InventoryTracingConstants.priceOverrideSpan,
                inventoryName:
                  inventoryPriceOverrideUpdate.inventoryNameAndDate ??
                  'NOT FOUND',
                inventoryId: inventoryPriceOverrideUpdate.inventoryId,
                products: [
                  {
                    apn: inventoryPriceOverrideUpdate.inventoryItemId,
                    groupName: inventoryPriceOverrideUpdate.groupName,
                    value: inventoryPriceOverrideUpdate.overrideValue,
                  },
                ],
              },
            },
            transformFunc: priceOverrideTransformer,
          },
        },
      }),
    );
  };

  openOrCloseInventoryInformationModal(openOrClose: boolean) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventoryInformationModal({
        toggle: openOrClose,
      }),
    );
  }

  openOrCloseInventoryCustomUOMModal(openOrClose: boolean) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventoryCustomUOMModal({
        toggle: openOrClose,
      }),
    );
  }

  openInventoryItemModal(
    item: InventoryProductViewModel,
    inventory: InventoryViewModel,
  ): void {
    const inventoryItemModalOptions = this.createInventoryItemModalOptions(
      item,
      inventory,
    );
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.updateInventoryItemModalOptions({
        inventoryItemModalOptions,
      }),
    );
  }

  closeInventoryItemModal(): void {
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.updateInventoryItemModalOptions({
        inventoryItemModalOptions: {
          isOpen: false,
        } as InventoryItemModalOptions,
      }),
    );
  }

  changeInventoryItemModalOptions(
    inventoryItemModalOptions: InventoryItemModalOptions,
  ) {
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.updateInventoryItemModalOptions({
        inventoryItemModalOptions,
      }),
    );
  }

  handleUnitPopoverClick(selectedUnit: string, customUnit: string) {
    let unit = selectedUnit;
    let customUnitAdjusted = customUnit;
    if (!!customUnit && selectedUnit === customUnit) {
      unit = 'Custom';
      customUnitAdjusted = selectedUnit;
    }
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.updateInventoryItemModalOptions({
        inventoryItemModalOptions: {
          unit,
          customUnit: customUnitAdjusted,
        } as InventoryItemModalOptions,
      }),
    );
    if (this.platformService.isTouch.value) {
      this.actionSheetController.dismiss();
    } else {
      this.popoverController.dismiss();
    }
  }

  saveItemChangesFromInventoryItemModal(
    inventoryItemModalOptions: InventoryItemModalOptions,
  ) {
    let actionToDispatch: any = {
      inventoryId: inventoryItemModalOptions.inventoryId,
    };

    if (
      inventoryItemModalOptions.customUnit !==
        inventoryItemModalOptions.initialCustomUnit ||
      inventoryItemModalOptions.unit !== inventoryItemModalOptions.initialUnit
    ) {
      actionToDispatch = {
        ...actionToDispatch,
        tracking: {
          analytics: {
            data: {
              capability: InventoryAnalyticsConstants.capability,
              event: InventoryAnalyticsConstants.updateUOM,
              inventory: {
                inventoryId: inventoryItemModalOptions.inventoryId,
              },
            },
            transformFunc: genericAnalyticsTransformer,
          },
        },
      };
    }

    if (
      inventoryItemModalOptions.initialInvConvFactor !==
      inventoryItemModalOptions.invConvFactor
    ) {
      this.trackUpdateUnitByCase(inventoryItemModalOptions.inventoryId);
    }

    if (
      inventoryItemModalOptions.initialOverrideCasePrice !==
      inventoryItemModalOptions.overrideCasePrice
    ) {
      this.trackUpdatingInventoryPrice(inventoryItemModalOptions.inventoryId);
    }

    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.saveInventoryItemModalOptions(actionToDispatch),
    );

    if (
      inventoryItemModalOptions.selectedPosition &&
      inventoryItemModalOptions.selectedGroup.groupName !== ''
    ) {
      const groupInventoryUpdate = {
        inventoryItemIndex: inventoryItemModalOptions.itemIndex,
        originalGroupIndex:
          inventoryItemModalOptions.initialGroup.groupAbsoluteIndex,
        inventoryId: inventoryItemModalOptions.inventoryId,
        targetGroupIndex:
          inventoryItemModalOptions.selectedGroup.groupAbsoluteIndex,
        targetItemIndex:
          inventoryItemModalOptions.selectedPosition === GroupPosition.top
            ? 0
            : inventoryItemModalOptions.selectedGroup.numProducts,
      } as InventoryGroupUpdate;
      this.moveInventoryItemToNewGroup(groupInventoryUpdate);
    }
  }

  updateInventoryItemConversionFactor = (
    inventoryConversionFactorUpdate: InventoryConversionFactorUpdate,
  ) => {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({
        inventoryId: inventoryConversionFactorUpdate.inventoryId,
      }),
    );
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.updateConversionFactor({
        inventoryItemId: inventoryConversionFactorUpdate.inventoryItemId,
        inventoryId: inventoryConversionFactorUpdate.inventoryId,
        groupName: inventoryConversionFactorUpdate.groupName,
        conversionValue: inventoryConversionFactorUpdate.conversionValue,
      }),
    );
    this.trackUpdateUnitByCase(inventoryConversionFactorUpdate.inventoryId);
  };

  updateInventoryItemUOMAndConversion = (
    inventoryItemUpdate: InventoryItemUpdate[],
    uom: string,
    conversionValue: number,
    inventoryId: string,
    customUOM: string,
  ) => {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({ inventoryId }),
    );
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.updateUOMAndConversionFactor({
        itemsToUpdate: inventoryItemUpdate,
        uom: uom,
        customUOM: customUOM,
        conversionValue: conversionValue,
        inventoryId: inventoryId,
        messageForToast: InventoryActions.changeUnits,
      }),
    );
  };

  updateCustomUnit = (
    customUpdate: InventoryCustomUOMEvent,
    inventoryId: string,
  ) => {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({
        inventoryId: inventoryId,
      }),
    );
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.updateCustomConversionFactorUnit({
        inventoryId: inventoryId,
        inventoryItemId: customUpdate.inventoryItemId,
        groupName: customUpdate.groupName,
        customUnit: customUpdate.customUOM,
        messageForToast: InventoryActions.createCustomUOM,
        tracking: {
          analytics: {
            data: {
              capability: InventoryAnalyticsConstants.capability,
              event: InventoryAnalyticsConstants.updateUOM,
              inventory: { inventoryId },
            },
            transformFunc: genericAnalyticsTransformer,
          },
        },
      }),
    );
  };

  openInventoryItemGroupDropdown(
    groupDropdownEvent: InventoryGroupDropdownEvent,
  ) {
    const buttons: Button[] = groupDropdownEvent.groupHeaders.map(value => {
      return {
        text: value.groupName,
        handler: () => {
          this.moveInventoryItemToNewGroup({
            inventoryItemIndex: groupDropdownEvent.inventoryItemIndex,
            inventoryId: groupDropdownEvent.inventoryId,
            originalGroupIndex: groupDropdownEvent.groupIndex,
            targetGroupIndex: value.groupAbsoluteIndex,
            targetItemIndex: groupDropdownEvent.targetItemIndex,
          });
          this.dismissSelection();
        },
      };
    });
    this.platformService.isTouch.value
      ? this.openSelectionsTouch(buttons)
      : this.openSelectionsDesktop(groupDropdownEvent.event, buttons);
  }

  moveInventoryItemToNewGroup = (
    inventoryGroupUpdate: InventoryGroupUpdate,
  ) => {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({
        inventoryId: inventoryGroupUpdate.inventoryId,
      }),
    );
    const inventoryGroupPair = {
      inventoryItemIndex: inventoryGroupUpdate.inventoryItemIndex,
      groupIndex: inventoryGroupUpdate.originalGroupIndex,
    } as InventoryGroupItemPair;
    const targetGroupAndIndex = {
      inventoryItemIndex: inventoryGroupUpdate.targetItemIndex,
      groupIndex: inventoryGroupUpdate.targetGroupIndex,
    } as InventoryGroupItemPair;
    this.moveInventoryItemsToNewGroup(
      [inventoryGroupPair],
      inventoryGroupUpdate.inventoryId,
      targetGroupAndIndex,
      false,
    );
  };

  // MODAL/POPOVER HELPER FUNCTIONS
  getUnitPopoverButtons(isCatchWeight: boolean, customUnit: string = '') {
    let buttons = [];
    if (isCatchWeight) {
      buttons = [
        {
          text: this.translateService.instant(
            'i18n.inventory.inventoryWorksheet.uom.pound',
          ),
          handler: () => this.handleUnitPopoverClick('LB', customUnit),
        },
      ];
    } else {
      buttons = [
        {
          text: this.translateService.instant(
            'i18n.inventory.inventoryWorksheet.uom.each',
          ),
          handler: () => this.handleUnitPopoverClick('EA', customUnit),
        },
        {
          text: this.translateService.instant(
            'i18n.inventory.inventoryWorksheet.uom.pound',
          ),
          handler: () => this.handleUnitPopoverClick('LB', customUnit),
        },
        {
          text: this.translateService.instant(
            'i18n.inventory.inventoryWorksheet.uom.ounce',
          ),
          handler: () => this.handleUnitPopoverClick('OZ', customUnit),
        },
        {
          text: this.translateService.instant(
            'i18n.inventory.inventoryWorksheet.uom.grams',
          ),
          handler: () => this.handleUnitPopoverClick('G', customUnit),
        },
        {
          text: this.translateService.instant(
            'i18n.inventory.inventoryWorksheet.uom.gallon',
          ),
          handler: () => this.handleUnitPopoverClick('GA', customUnit),
        },
        {
          text: this.translateService.instant(
            'i18n.inventory.inventoryWorksheet.uom.batch',
          ),
          handler: () => this.handleUnitPopoverClick('Batch', customUnit),
        },
        {
          text: this.translateService.instant(
            'i18n.inventory.inventoryWorksheet.uom.piece',
          ),
          handler: () => this.handleUnitPopoverClick('Piece', customUnit),
        },
      ];

      if (!!customUnit) {
        buttons.push({
          text: customUnit,
          cssClass: 'bold-font-weight',
          handler: () => this.handleUnitPopoverClick(customUnit, customUnit),
        });
        buttons.push({
          text: 'Edit Custom',
          handler: () => this.handleUnitPopoverClick('Custom', customUnit),
        });
      } else {
        buttons.push({
          text: 'Add Custom',
          handler: () => this.handleUnitPopoverClick('Custom', customUnit),
        });
      }
    }
    return buttons;
  }

  private createInventoryItemModalOptions(
    item: InventoryProductViewModel,
    inventory: InventoryViewModel,
  ): InventoryItemModalOptions {
    let initialGroup: InventoryGroupHeader = undefined;
    for (const group of inventory.groupHeaders) {
      if (group.groupName === item.groupName) {
        initialGroup = group;
      }
    }
    let defaultUnits: string[];

    defaultUnits = ['CS', 'EA', 'LB', 'OZ', 'G', 'GA', 'Batch', 'Piece'];

    const unit = defaultUnits.includes(item.uom) ? item.uom : 'Custom';
    return {
      isOpen: true,
      isPDPOverlayOpen: false,
      imageThumbnail: item.productImageThumbnail,
      brand: item.productBrand,
      description: item.productName,
      productNumber: item.productNumber,
      manufacturerNumber: item.productManufacturerNumber,
      numberOfUnits: item.eachOnHand.currentValue,
      unitPrice: item.unitPrice,
      numberOfCases: item.caseOnHand.currentValue,
      casePrice: item.casePrice,
      value: item.value,
      selectedGroup: initialGroup,
      groups: inventory.groupHeaders,
      itemSequenceNumber: item.itemSequenceNumber,
      unit,
      customUnit: item.customUOM ? item.customUOM : '',
      priceOverrideInd: item.priceOverrideInd,
      overrideCasePrice: item.overrideCasePrice,
      salesPackSize: item.productPackSizeLabel,
      selectedPosition: '',
      selectedOption:
        this.platformService.platformType ===
        this.platformService.platformEnum.mobile
          ? ''
          : InventoryItemEditOptions.chooseGroup,
      invConvFactor: item.invConvFactor,
      inventoryId: inventory.inventoryId,
      inventoryItemId: item.productNumber,
      groupIndex: 0,
      itemIndex: item.itemAbsoluteIndex,
      initialGroup,
      initialUnit: item.uom,
      initialInvConvFactor: item.invConvFactor,
      initialPriceOverrideInd: item.priceOverrideInd,
      initialOverrideCasePrice: item.overrideCasePrice,
      initialCustomUnit: item.customUOM ?? '',
      saveButtonIsDisabled: false,
      catchWeightFlag: item.productCatchWeightFlag,
      marketBreakFlag: item.productMarketBreakFlag,
      customErrorFlag: false,
      nonUSFFlag: item.nonUSFFlag,
    } as InventoryItemModalOptions;
  }

  async displayCompleteSuccessModal(completedInventoryId: string) {
    this.inventoryService.displayInventoryCardEllipsisModal(
      completedInventoryId,
      InventoryEllipsisModalModes.complete,
    );
  }

  selectAllItemsInGroup(inventory: InventoryViewModel) {
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.selectAllInventoryItems({
        inventoryId: inventory.inventoryId,
        selectedGroup: inventory.inventoryOptions?.selectedGroup,
      }),
    );
    this.countAllSelectionsCatchWeight(inventory);
  }

  async openInventoryActionModal(
    inventoryAction: string,
    inventory: InventoryViewModel,
  ) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      ModalWithNavComponent,
      {
        rootPage:
          this.platformService.platformType ===
          this.platformService.platformEnum.mobile
            ? InventoryActionModalMobileComponent
            : InventoryActionModalDesktopTabletComponent,
        rootPageParams: {
          selectedAction: inventoryAction,
          inventory,
        },
      },
      'modal-desktop-size-xl',
    );

    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );
    await modal.present();
    return modal;
  }

  copyInventoryItemsIntoGroup(
    inventoryItemIdAndGroupPairs: InventoryGroupItemPair[],
    inventoryId: string,
    targetGroupAndIndex: InventoryGroupItemPair,
  ) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({ inventoryId }),
    );
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.copyInventoryItemsIntoGroup({
        inventoryItemIdAndGroupPairs,
        inventoryId,
        targetGroupAndIndex,
        messageForToast: InventoryActions.copyToGroup,
      }),
    );
  }

  deleteProduct(
    inventoryItemIdAndGroupPairs: InventoryGroupItemPair[],
    inventory: InventoryViewModel,
  ) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({
        inventoryId: inventory.inventoryId,
      }),
    );
    let products = [];
    inventory.inventoryOptions.selections.forEach(selection => {
      products.push({
        apn: selection.inventoryItemId,
        groupName: selection.groupName,
      });
    });
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.deleteInventoryItemsInGroup({
        inventoryItemIdAndGroupPairs,
        inventoryId: inventory.inventoryId,
        messageForToast: InventoryActions.delete,
        tracking: {
          tracing: {
            data: {
              traceContext: InventoryTracingConstants.inventory,
              isEndOfTrace: false,
              isStartOfTrace: true,
              attributes: {
                spanName: InventoryTracingConstants.deleteInventoryItemsEvent,
                event: InventoryTracingConstants.deleteInventoryItemsEvent,
                inventoryName:
                  inventory.inventoryName +
                  ' - ' +
                  this.datePipe.transform(inventory.inventoryDate, 'M/d/yyyy'),
                inventoryId: inventory.inventoryId,
                products,
              },
            },
            transformFunc: deleteGroupDeleteItems,
          },
        },
      }),
    );
  }

  resetAllDuplicatesFlag() {
    this.store.dispatch(INVENTORY_ACTIONS.resetAllDuplicatesFlag());
  }

  async openSelectionsDesktop(event: Event, buttons: Button[]) {
    const popover = await this.popoverController.create({
      component: EllipsisOptionsPopoverComponent,
      componentProps: {
        buttons,
      },
      showBackdrop: false,
      cssClass: 'ellipsis-options-popover-desktop',
      event,
      mode: 'ios',
      alignment: 'center',
    });
    await popover.present();
  }

  async openSelectionsTouch(buttons: Button[]) {
    buttons.push({
      text: this.translateService.instant('i18n.common.cancel'),
      role: 'cancel',
      cssClass: 'cancel-button',
    });
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'action-sheet-touch',
      mode: 'ios',
      buttons,
    });
    await actionSheet.present();
  }

  async dismissSelection() {
    // we may need to check if we have an active actionSheet or popover
    this.platformService.isTouch.value
      ? this.actionSheetController.dismiss()
      : this.popoverController.dismiss();
  }

  dismissModals() {
    this.modalController.dismiss();
  }

  async openAddProductsPopover(event: Event) {
    var buttons: Button[] = [
      {
        text: this.translateService.instant(
          'i18n.inventory.inventoryWorksheet.addUSFoodsProducts',
        ),
        handler: () => {
          this.trackAddProductPageLoaded(this.activeInventoryId, false);
          this.routeToSearch();
          this.dismissSelection();
        },
      },
      {
        text: this.translateService.instant(
          'i18n.inventory.inventoryWorksheet.addNonUSFoodsProducts',
        ),
        handler: () => {
          this.inventoryService.addNonUSFProdFromInventoryWorksheet = true;
          this.trackAddProductPageLoaded(this.activeInventoryId, true);
          this.navigateToNonUSF();
          this.dismissSelection();
        },
      },
    ];

    if (!this.platformService.isTouch.value) {
      this.openSelectionsDesktop(event, buttons);
    } else {
      this.openSelectionsTouch(buttons);
    }
  }

  // route to search page in the addToInventorySearch mode/flow:
  routeToSearch() {
    this.toggleNewSearchService
      .isNewSearchEnabled()
      .pipe(take(1))
      .subscribe(newSearchEnabled => {
        if (newSearchEnabled) {
          this.navigationHelperService.routeToNewSearch({
            isInventory: 'true',
          });
        } else {
          this.navigationHelperService.routeToSearch({
            searchFilterProperties: '0',
            mode: UsfProductCardModeEnum.addToInventorySearch,
            pageType: 'all',
          });
        }
      });
  }

  openSelectedInventoryItemModal(
    inventoryAction: string,
    inventory: InventoryViewModel,
  ) {
    if (inventoryAction !== InventoryActions.delete) {
      this.openInventoryActionModal(inventoryAction, inventory);
    } else {
      this.openSelectedInventoryItemDeleteModal(inventory);
    }
  }

  async selectedInventoryItemEllipsis(inventory: InventoryViewModel) {
    const buttons: Button[] = [];
    if (inventory.groupHeaders.length > 1) {
      buttons.push(
        {
          text: this.translateService.instant(
            'i18n.inventory.inventoryWorksheet.moveToGroup',
          ),
          handler: () =>
            this.openSelectedInventoryItemModal(
              InventoryActions.moveToGroup,
              inventory,
            ),
        },
        {
          text: this.translateService.instant(
            'i18n.inventory.inventoryWorksheet.copyToGroup',
          ),
          handler: () =>
            this.openSelectedInventoryItemModal(
              InventoryActions.copyToGroup,
              inventory,
            ),
        },
      );
    }
    if (!this.allCatchWeight) {
      buttons.push({
        text: this.translateService.instant(
          'i18n.inventory.inventoryWorksheet.changeUnits',
        ),
        handler: () =>
          this.openSelectedInventoryItemModal(
            InventoryActions.changeUnits,
            inventory,
          ),
      });
    }
    buttons.push(
      {
        text: this.translateService.instant('i18n.common.delete'),
        handler: () =>
          this.openSelectedInventoryItemModal(
            InventoryActions.delete,
            inventory,
          ),
      },
      {
        text: this.translateService.instant('i18n.common.cancel'),
        role: 'cancel',
        cssClass: 'cancel-button',
      },
    );

    const inventoryOptionsActionSheet = await this.actionSheetController.create(
      {
        cssClass: 'action-sheet-touch',
        mode: 'ios',
        buttons,
      },
    );
    await inventoryOptionsActionSheet.present();
  }

  createNewGroup(inventory: InventoryViewModel): void {
    let newName = this.createNewGroupName(inventory);

    const newInventoryGroup: InventoryGroup = {
      groupName: newName,
      groupUpdateDtm: new Date(),
      groupItems: [],
    };
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({
        inventoryId: inventory.inventoryId,
      }),
    );
    this.store.dispatch(
      INVENTORY_GROUP_ACTIONS.create({
        inventoryId: inventory.inventoryId,
        inventoryGroup: newInventoryGroup,
      }),
    );
  }

  // First priority goes to "Untitled", then "Untitled (1), Untitled (2)..."
  createNewGroupName(inventory: InventoryViewModel) {
    const untitledTranslation = this.translateService.instant(
      'i18n.inventory.inventoryWorksheet.untitled',
    );

    if (
      !inventory?.groupHeaders?.find(
        group => group.groupName === untitledTranslation,
      )
    ) {
      return untitledTranslation;
    }

    // existing inventory groups:
    let counter = 1;
    let title = untitledTranslation + ' (' + counter.toString() + ')';

    while (inventory?.groupHeaders.find(group => group.groupName === title)) {
      counter += 1;
      title = untitledTranslation + ' (' + counter.toString() + ')';
    }

    return title;
  }

  async openSelectedInventoryItemDeleteModal(inventory: InventoryViewModel) {
    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      ModalWithNavComponent,
      {
        rootPage: InventoryDeleteModalComponent,
        rootPageParams: {
          inventory,
        },
      },
      'delete-item-inventory-modal-desktop',
    );

    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );
    await modal.present();
    return modal;
  }

  updateGroupName(
    inventory: InventoryViewModel,
    group: InventoryGroup,
    newName: string,
  ) {
    if (
      inventory.inventoryOptions.selectedGroup.groupName === group.groupName
    ) {
      this.store.dispatch(
        INVENTORY_GROUP_ACTIONS.updateSelectedGroup({
          selectedGroup: {
            ...inventory.inventoryOptions.selectedGroup,
            groupName: newName,
          },
        }),
      );
    }
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({
        inventoryId: inventory.inventoryId,
      }),
    );
    this.store.dispatch(
      INVENTORY_GROUP_ACTIONS.update({
        inventoryId: inventory.inventoryId,
        inventoryGroup: group,
        newGroupName: newName,
      }),
    );
  }

  moveInventoryItemsToNewGroup(
    inventoryItemIdAndGroupPairs: InventoryGroupItemPair[],
    inventoryId: string,
    targetGroupAndIndex: InventoryGroupItemPair,
    isFromActionBar?: boolean,
  ) {
    const messageForToast = isFromActionBar
      ? InventoryActions.moveToGroup
      : undefined;
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({ inventoryId }),
    );
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.moveInventoryItemsIntoGroup({
        inventoryItemIdAndGroupPairs,
        inventoryId,
        targetGroupAndIndex,
        messageForToast,
      }),
    );
  }

  moveInventoryItemByLineNumber(
    inventoryLineNumberEvent: InventoryLineNumberEvent,
    numberOfProducts: number,
  ) {
    let newLineNumber;
    const inputValue = Number(inventoryLineNumberEvent.ionInput.value);

    if (inputValue < 1) {
      newLineNumber = 1;
    } else {
      newLineNumber = inputValue;
    }

    const lineNumberHasNotChanged =
      inventoryLineNumberEvent.inventoryItem.itemSequenceNumber ===
      newLineNumber;
    const movingLastLineItemOutOfRange =
      newLineNumber >= numberOfProducts &&
      inventoryLineNumberEvent.inventoryItem.itemSequenceNumber ===
        numberOfProducts;

    inventoryLineNumberEvent.ionInput.value =
      inventoryLineNumberEvent.inventoryItem.itemSequenceNumber;

    if (
      lineNumberHasNotChanged ||
      movingLastLineItemOutOfRange ||
      isNaN(inputValue)
    ) {
      return;
    }

    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({
        inventoryId: inventoryLineNumberEvent.inventoryId,
      }),
    );
    this.store.dispatch(
      INVENTORY_ITEM_ACTIONS.moveInventoryItemByLineNumber({
        inventoryId: inventoryLineNumberEvent.inventoryId,
        newLineNumber,
        previousLineNumber:
          inventoryLineNumberEvent.inventoryItem.itemSequenceNumber,
        numberOfProducts,
      }),
    );
  }

  async deleteGroup(
    inventory: InventoryViewModel,
    inventoryGroup: InventoryGroupHeader,
  ) {
    const groupToFocus: InventoryGroupHeader = {
      groupName: 'All',
      numProducts: inventory.numProducts,
    };
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({
        inventoryId: inventory.inventoryId,
      }),
    );
    if (inventoryGroup.numProducts === 0) {
      this.store.dispatch(
        INVENTORY_GROUP_ACTIONS.delete({
          inventoryId: inventory.inventoryId,
          groupToFocus,
          inventoryGroup,
          messageForToast: InventoryActions.deleteGroup,
          toastInputParams: { groupName: inventoryGroup.groupName },
        }),
      );
    } else {
      this.modalService.setModalOptions(
        this.platformService.isTouch.value,
        DeleteGroupModalComponent,
        {
          platformType: this.platformService.platformType,
          numberOfProducts: inventoryGroup.numProducts,
          groupName: inventoryGroup.groupName,
        },
        'modal-desktop-size-md',
      );
      const modal = await this.modalController.create(
        this.modalService.modalOptions,
      );
      await modal.present();
      modal.onDidDismiss().then(data => {
        if (data?.data?.deleteAllProducts === true) {
          let products = [];
          for (const item of inventory.items) {
            if (
              item.groupName === inventoryGroup.groupName &&
              item.itemType === ItemTypes.product
            ) {
              const inventoryItem: InventoryProductViewModel =
                item as InventoryProductViewModel;
              products.push({
                apn: inventoryItem.productNumber,
                groupName: inventoryItem.groupName,
              });
            }
          }

          this.store.dispatch(
            INVENTORY_GROUP_ACTIONS.delete({
              inventoryId: inventory.inventoryId,
              groupToFocus,
              inventoryGroup,
              messageForToast: InventoryActions.deleteGroup,
              toastInputParams: { groupName: inventoryGroup.groupName },
              tracking: {
                tracing: {
                  data: {
                    traceContext: InventoryTracingConstants.inventory,
                    isEndOfTrace: false,
                    isStartOfTrace: true,
                    attributes: {
                      spanName:
                        InventoryTracingConstants.deleteGroupDeleteProducts,
                      event:
                        InventoryTracingConstants.deleteGroupDeleteProducts,
                      inventoryName:
                        inventory.inventoryName +
                        ' - ' +
                        this.datePipe.transform(
                          inventory.inventoryDate,
                          'M/d/yyyy',
                        ),
                      inventoryId: inventory.inventoryId,
                      products,
                    },
                  },
                  transformFunc: deleteGroupDeleteItems,
                },
              },
            }),
          );
        } else if (data?.data?.deleteAllProducts === false) {
          let targetGroupIndex = 0;
          for (const group of inventory.groupHeaders) {
            if (group.groupName === ListConstants.unassignedGroup) {
              targetGroupIndex = group.groupAbsoluteIndex;
            }
          }
          const inventoryItemIdAndGroupPairs: InventoryGroupItemPair[] = [];
          for (let i = 0; i < inventoryGroup.numProducts; i++) {
            inventoryItemIdAndGroupPairs.push({
              inventoryItemIndex: i,
              groupIndex: inventoryGroup.groupAbsoluteIndex,
            });
          }
          const targetGroupAndIndex: InventoryGroupItemPair = {
            inventoryItemIndex: 0,
            groupIndex: targetGroupIndex,
          };

          this.store.dispatch(
            INVENTORY_GROUP_ACTIONS.deleteAndMoveItems({
              inventoryId: inventory.inventoryId,
              inventoryItemIdAndGroupPairs,
              groupToFocus,
              targetGroupAndIndex,
              messageForToast: InventoryActions.deleteGroupAndMove,
              toastInputParams: { groupName: inventoryGroup.groupName },
              tracking: {
                tracing: {
                  data: {
                    traceContext: InventoryTracingConstants.inventory,
                    isEndOfTrace: false,
                    isStartOfTrace: true,
                    attributes: {
                      spanName:
                        InventoryTracingConstants.deleteGroupMoveProducts,
                      event: InventoryTracingConstants.deleteGroupMoveProducts,
                      inventoryName:
                        inventory.inventoryName +
                        ' - ' +
                        this.datePipe.transform(
                          inventory.inventoryDate,
                          'M/d/yyyy',
                        ),
                      inventoryId: inventory.inventoryId,
                    },
                  },
                  transformFunc: deleteGroupMoveItems,
                },
              },
            }),
          );
        }
      });
    }
  }

  moveGroup(inventoryId: string, previousIndex: number, newIndex: number) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySnapshot({ inventoryId }),
    );
    this.store.dispatch(
      INVENTORY_GROUP_ACTIONS.move({ inventoryId, previousIndex, newIndex }),
    );
  }

  groupNameExists(inventory: InventoryViewModel, groupName: string) {
    return (
      inventory.groupHeaders.filter(
        group => group.groupName.toUpperCase() === groupName.toUpperCase(),
      ).length > 1
    );
  }

  displayRenameGroupErrorToast() {
    this.messageService.upsertMessage({
      type: MessageTypeEnum.error,
      display: this.translateService.instant(
        'i18n.inventory.inventoryWorksheet.existingGroupNameError',
      ),
      toast: true,
      sticky: false,
    } as Message);
  }

  displayEmptyGroupErrorToast() {
    this.messageService.upsertMessage({
      type: MessageTypeEnum.error,
      display: this.translateService.instant(
        'i18n.inventory.inventoryWorksheet.emptyGroupNameError',
      ),
      toast: true,
      sticky: false,
    } as Message);
  }

  countCatchWeightSelection(selection: InventoryItemSelectionUpdate) {
    if (selection.selection) {
      selection.isCatchWeight
        ? this.catchWeightCount++
        : this.nonCatchWeightCount++;
    }
    if (!selection.selection) {
      selection.isCatchWeight
        ? this.catchWeightCount--
        : this.nonCatchWeightCount--;
    }
    this.evaluateCatchWeightSelections();
  }

  countAllSelectionsCatchWeight(inventory: InventoryViewModel) {
    this.catchWeightCount, (this.nonCatchWeightCount = 0);
    const items = inventory.items;
    if (inventory.items) {
      items.forEach(item => {
        if (item.itemType === ItemTypes.product) {
          const selection = item as InventoryProductViewModel;
          selection.productCatchWeightFlag
            ? this.catchWeightCount++
            : this.nonCatchWeightCount++;
        }
      });
      this.evaluateCatchWeightSelections();
    }
  }

  evaluateCatchWeightSelections() {
    if (this.catchWeightCount > 0) {
      if (this.nonCatchWeightCount > 0) {
        this.allCatchWeight = false;
        this.someCatchWeight = true;
      } else {
        this.allCatchWeight = true;
        this.someCatchWeight = false;
      }
    } else {
      this.allCatchWeight = false;
      this.someCatchWeight = false;
    }
  }

  closeEverythingForOffline = () => {
    this.closeInventoryItemModal();
    this.openOrCloseInventoryCustomUOMModal(false);
    this.openOrCloseInventoryInformationModal(false);
    this.dismissModals();
    this.dismissSelection();
    this.resetInventoryItemSelections(this.activeInventoryId);
  };

  dispatchOnline = (inventoryId: string) => {
    this.store.dispatch(INVENTORY_ACTIONS.exitOffline({ inventoryId }));
    this.inventoryService.loadProductsForOnline(inventoryId);
  };

  dispatchOffline = () => {
    this.store.dispatch(INVENTORY_ACTIONS.enterOffline());
  };

  transformDate(date) {
    return this.datePipe.transform(date, 'M/d/yyyy');
  }

  navigateToNonUSF() {
    this.navigationHelperService.routeTo(
      PATHS.INVENTORY + '/' + PATHS.MANAGE_NON_USF_PRODUCTS,
    );
  }

  openOrCloseSortModal(openOrClose: boolean) {
    this.store.dispatch(
      INVENTORY_ACTIONS.updateInventorySortModal({
        toggle: openOrClose,
      }),
    );
  }

  createCountModeSortOptions(): SortOption[] {
    return [
      {
        sortName: InventoryWorksheetCountSortOptions.lineNum,
        sortDirection: SortDirections.asc,
        label: this.translateService.instant(
          'i18n.inventory.sortOptions.lineNum',
        ),
      },
      {
        sortName: InventoryWorksheetCountSortOptions.productName,
        sortDirection: SortDirections.asc,
        label: this.translateService.instant(
          'i18n.inventory.sortOptions.productName',
        ),
      },
      {
        sortName: InventoryWorksheetCountSortOptions.byCase,
        sortDirection: SortDirections.asc,
        label: this.translateService.instant(
          'i18n.inventory.sortOptions.byCase',
        ),
      },
      {
        sortName: InventoryWorksheetCountSortOptions.byUnit,
        sortDirection: SortDirections.asc,
        label: this.translateService.instant(
          'i18n.inventory.sortOptions.byUnit',
        ),
      },
      {
        sortName: InventoryWorksheetCountSortOptions.total,
        sortDirection: SortDirections.asc,
        label: this.translateService.instant(
          'i18n.inventory.sortOptions.total',
        ),
      },
      {
        sortName: InventoryWorksheetCountSortOptions.value,
        sortDirection: SortDirections.asc,
        label: this.translateService.instant(
          'i18n.inventory.sortOptions.value',
        ),
      },
    ];
  }

  createEditModeSortOptions(): SortOption[] {
    return [
      {
        sortName: InventoryWorksheetEditSortOptions.lineNum,
        sortDirection: SortDirections.asc,
        label: this.translateService.instant(
          'i18n.inventory.sortOptions.lineNum',
        ),
      },
      {
        sortName: InventoryWorksheetEditSortOptions.productName,
        sortDirection: SortDirections.asc,
        label: this.translateService.instant(
          'i18n.inventory.sortOptions.productName',
        ),
      },
      {
        sortName: InventoryWorksheetEditSortOptions.unitDescription,
        sortDirection: SortDirections.asc,
        label: this.translateService.instant('i18n.inventory.sortOptions.uom'),
      },
      {
        sortName: InventoryWorksheetEditSortOptions.unitByCase,
        sortDirection: SortDirections.asc,
        label: this.translateService.instant(
          'i18n.inventory.sortOptions.unitByCase',
        ),
      },
      {
        sortName: InventoryWorksheetEditSortOptions.casePrice,
        sortDirection: SortDirections.asc,
        label: this.translateService.instant(
          'i18n.inventory.sortOptions.price',
        ),
      },
      {
        sortName: InventoryWorksheetEditSortOptions.unitPrice,
        sortDirection: SortDirections.asc,
        label: this.translateService.instant(
          'i18n.inventory.sortOptions.unitPrice',
        ),
      },
    ];
  }

  getSortSelection(selectedGroup: string, currentSort: InventorySort) {
    let sortSelection: SortSelection;
    if (selectedGroup === 'All') {
      let allGroupHeaders: string[] = [];
      let allSortDirections: string[] = [];
      Object.entries(currentSort).forEach(([key, value]) => {
        allGroupHeaders.push(value.selectedHeader);
        allSortDirections.push(
          value.headers[value.selectedHeader].sortDirection,
        );
      });
      const baseHeader = allGroupHeaders[0];
      const baseDirection = allSortDirections[0];
      if (
        allGroupHeaders.every(header => header === baseHeader) &&
        allSortDirections.every(direction => direction === baseDirection)
      ) {
        sortSelection = {
          fieldName: baseHeader,
          sortValue: baseDirection,
        };
      } else {
        sortSelection = {
          fieldName: InventoryWorksheetCountSortOptions.lineNum,
          sortValue: SortDirections.asc,
        };
      }
    } else {
      const currentGroupSort = currentSort[selectedGroup];
      const selectedHeader = currentGroupSort.selectedHeader;
      const sortDirection =
        currentGroupSort.headers[selectedHeader].sortDirection;
      sortSelection = {
        fieldName: selectedHeader,
        sortValue: sortDirection,
      };
    }
    return sortSelection;
  }

  trackInventoryWorksheetLoaded(inventoryId: string) {
    this.store.dispatch(
      ANALYTICS_ACTIONS.trackInventoryWorksheetPageLoad({
        tracking: {
          analytics: {
            data: {
              capability: InventoryAnalyticsConstants.capability,
              event: InventoryAnalyticsConstants.pageLoaded,
              pageName: InventoryAnalyticsConstants.inventoryWorksheet,
              inventoryId,
            },
            transformFunc: pageLoadedTransformer,
          },
        },
      }),
    );
  }

  trackClickUpdateAllPricesButton(inventoryId: string) {
    this.store.dispatch(
      ANALYTICS_ACTIONS.trackClickUpdateAllPricesButton({
        tracking: {
          analytics: {
            data: {
              capability: InventoryAnalyticsConstants.capability,
              event: InventoryAnalyticsConstants.buttonClick,
              buttonName: InventoryAnalyticsConstants.updateAllPrices,
              inventoryId,
            },
            transformFunc: clickButtonTransformer,
          },
        },
      }),
    );
  }

  trackAddProductPageLoaded(inventoryId: string, nonUsfProduct: boolean) {
    if (nonUsfProduct) {
      this.store.dispatch(
        ANALYTICS_ACTIONS.trackAddNonUsfProductPageLoaded({
          tracking: {
            analytics: {
              data: {
                capability: InventoryAnalyticsConstants.capability,
                event: InventoryAnalyticsConstants.pageLoaded,
                pageName: InventoryAnalyticsConstants.addNonUsfProduct,
                inventoryId,
              },
              transformFunc: pageLoadedTransformer,
            },
          },
        }),
      );
    } else {
      this.store.dispatch(
        ANALYTICS_ACTIONS.trackAddProductPageLoaded({
          tracking: {
            analytics: {
              data: {
                capability: InventoryAnalyticsConstants.capability,
                event: InventoryAnalyticsConstants.pageLoaded,
                pageName: InventoryAnalyticsConstants.addUsfProduct,
                inventoryId,
              },
              transformFunc: pageLoadedTransformer,
            },
          },
        }),
      );
    }
  }

  trackUpdateUnitByCase(inventoryId: string) {
    this.store.dispatch(
      ANALYTICS_ACTIONS.trackUpdateProductUnitByCase({
        tracking: {
          analytics: {
            data: {
              capability: InventoryAnalyticsConstants.capability,
              event: InventoryAnalyticsConstants.updateUnitByCase,
              inventory: { inventoryID: inventoryId },
            },
            transformFunc: genericAnalyticsTransformer,
          },
        },
      }),
    );
  }

  trackDownloadInventoryWorksheet(inventoryId: string, format: string) {
    this.store.dispatch(
      ANALYTICS_ACTIONS.trackDownloadInventoryWorksheet({
        tracking: {
          analytics: {
            data: {
              capability: InventoryAnalyticsConstants.capability,
              event: InventoryAnalyticsConstants.downloadEvent,
              inventoryId,
              fileType:
                format === 'CSV'
                  ? InventoryAnalyticsConstants.fileTypeCsv
                  : InventoryAnalyticsConstants.fileTypePdf,
            },
            transformFunc: downloadInventoryTransformer,
          },
        },
      }),
    );
  }

  trackUpdatingInventoryPrice(inventoryId: string) {
    this.store.dispatch(
      ANALYTICS_ACTIONS.trackUpdatingInventoryPrice({
        tracking: {
          analytics: {
            data: {
              capability: InventoryAnalyticsConstants.capability,
              event: InventoryAnalyticsConstants.changeInventoryPrice,
              inventory: { inventoryID: inventoryId },
            },
            transformFunc: genericAnalyticsTransformer,
          },
        },
      }),
    );
  }
}
