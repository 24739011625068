import { NgRxPaymentLibConfig } from '@usf/ngrx-payment';
import { environment } from 'src/environments/environment';

export const NG_RX_PAYMENT_LIB_CONFIG: NgRxPaymentLibConfig = {
  paymentPayDomainApiUrl: environment.paymentPayApiUrl,
  paymentDomainApiUrl: environment.paymentApiUrl,
  paymentBankDomainApiUrl: environment.paymentBankApiUrl,
  paymentMetricsDomainApiUrl: environment.paymentMetricsApiUrl,
  webSocketUrl: environment.webSocketUrl,
};
