import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';

export const HOME_ANALYTICS_ACTIONS = {
  trackPageLoad: createAction(
    '[NgRxClient Analytics] - Track Home Page Load',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackInteractBannerClick: createAction(
    '[NgRxClient Analytics] - Track Interact Banner Click',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackInteractBannersInViewport: createAction(
    '[NgRxClient Analytics] - Track Interact Banners In Viewport',
    props<{
      tracking: Tracking;
    }>(),
  ),
};
