import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
  capitalize([first, ...rest]: string) {
    return `${first.toUpperCase()}${rest.join('')}`;
  }

  transform(value: string, caseText: string = 'FIRST'): string {
    if (!value?.trim()) {
      return value;
    }
    const rawSentence = value.trim();
    if (caseText === 'FIRST') {
      const sentences = rawSentence.toLowerCase().split('.');
      const format = sentences.reduce((sentencesFinal, sentence) => {
        if (sentence) {
          sentencesFinal = [
            ...sentencesFinal,
            this.capitalize(sentence.trim()),
          ];
        }
        return sentencesFinal;
      }, []);
      const finishAsSentence =
        rawSentence.charAt(rawSentence.length - 1) === '.';
      return `${format.join('. ')}${finishAsSentence ? '.' : ''}`;
    } else {
      const sentences = rawSentence.toLowerCase().split(' ');
      const format = sentences.reduce((sentencesFinal, sentence) => {
        if (sentence) {
          sentencesFinal = [
            ...sentencesFinal,
            this.capitalize(sentence.trim()),
          ];
        }
        return sentencesFinal;
      }, []);
      return `${format.join(' ')}`;
    }
  }
}
