import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';
import { OrderTracingService } from '@order/tracking/tracing/order-tracing.service';
import { Tracking } from '@panamax/app-state';
import { PUNCHOUT_TRACING_ACTIONS } from './punchout-tracing.actions';
import { createPunchoutTransferClickTrace } from './factories/punchout-transfer-click';

@Injectable({
  providedIn: 'root',
})
export class PunchoutTracingEffects {
  punchoutTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PUNCHOUT_TRACING_ACTIONS.transfer),
      map(() => {
        const tracking = createPunchoutTransferClickTrace();
        tracking.tracing.data.isStartOfTrace = true;
        tracking.tracing.data.isEndOfTrace = true;
        return OrderTracingService.deepRemoveUndefined(tracking);
      }),
      switchMap(tracking => [
        PUNCHOUT_TRACING_ACTIONS.transferClick({ tracking }),
      ]),
    ),
  );
  constructor(private actions$: Actions) {}
}
