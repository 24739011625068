import { Component, Input } from '@angular/core';
import { ModalController, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { ListGroupState, ListState } from '@usf/ngrx-list';
import { Subscription } from 'rxjs';
import { PlatformService } from '@panamax/app-state';
import { ListsPageData } from '../../model/lists-page.model';
import { ListsService } from '../../services/lists.service';
import { ListDetailManagementService } from '../list-detail-management/services/list-detail-management.service';
@Component({
  selector: 'app-edit-name',
  templateUrl: './edit-name.component.html',
  styleUrls: ['./edit-name.component.scss'],
})
export class EditNameComponent implements ViewWillEnter, ViewWillLeave {
  @Input() list: ListState;
  @Input() name: string;
  @Input() isGroup: boolean;
  @Input() groups: ListGroupState[];

  listPageData: ListsPageData;
  selectedGroup: ListGroupState;
  newName: string;
  nameExists = false;

  listsViewSub$ = new Subscription();

  constructor(
    private modalController: ModalController,
    readonly platformService: PlatformService,
    private listsService: ListsService,
    private listDetailManagementService: ListDetailManagementService,
  ) {}

  ionViewWillEnter() {
    this.listsViewSub$ = this.listsService.listsView$().subscribe(data => {
      this.listPageData = data;
    });
    this.newName = this.name;
    this.selectedGroup = this.listDetailManagementService.getSelectedGroup(
      this.name,
      this.groups,
    );
  }

  getListNameExists() {
    this.nameExists = this.listsService.listNameExists(
      this.listPageData,
      this.newName,
    );
  }

  getGroupNameExists() {
    this.nameExists = this.listDetailManagementService.groupNameExists(
      this.newName,
      this.groups,
    );
  }

  onEditName() {
    if (this.isGroup) {
      this.listDetailManagementService.editGroupName(
        this.newName,
        this.list,
        this.selectedGroup,
      );
    } else {
      this.listDetailManagementService.editListName(this.newName, this.list);
    }

    this.dismissModal();
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }

  ionViewWillLeave() {
    if (this.listsViewSub$) {
      this.listsViewSub$.unsubscribe();
    }
  }
}
