import { Component, Input } from '@angular/core';
import { PlatformService } from '@panamax/app-state';
import { EllipsisOption } from '../../../shared/models/ellipsis-option.model';

@Component({
  selector: 'app-inventory-landing-options-modal',
  templateUrl: './inventory-landing-options-modal.component.html',
  styleUrls: ['./inventory-landing-options-modal.component.scss'],
})
export class InventoryLandingOptionsModalComponent {
  @Input() ellipsisOptions: EllipsisOption[];

  constructor(readonly platformService: PlatformService) {}
}
