import { Injectable } from '@angular/core';
import { ListRow } from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { ModalOptions } from '@ionic/angular';
import { MobileModalHeights } from '@shared/constants/modal-heights.enum';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  modalOptions: ModalOptions;

  constructor() {}

  setModalOptions(
    isTouch: boolean,
    component: any,
    componentProps?: any,
    cssClass?: string,
    backdropDismiss?: boolean,
    listRows?: ListRow[],
    touchHeightClass?: MobileModalHeights,
  ): ModalOptions {
    this.modalOptions = {
      component: component,
      mode: 'ios',
    };

    if (componentProps) {
      this.modalOptions.componentProps = componentProps;
    }

    if (isTouch) {
      this.modalOptions.cssClass = touchHeightClass
        ? touchHeightClass
        : 'ion-modal-touch';
      this.modalOptions.initialBreakpoint = 1;
      this.modalOptions.breakpoints = [0, 1];
    } else {
      this.modalOptions.cssClass = cssClass;
    }

    return this.modalOptions;
  }
}
