import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-error-popover',
  templateUrl: './error-popover.component.html',
  styleUrls: ['./error-popover.component.scss'],
})
export class ErrorPopoverComponent {
  @Input() description = 'Unable to take you to preference center at this time. Please try again later.';

  constructor(private popover: PopoverController) {}

  dismiss() {
    this.popover.dismiss();
  }
}
