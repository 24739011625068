import { createSelector } from '@ngrx/store';
import { productDetailsViewModelSelector } from '@product-detail/selectors/product-detail.selector';
import { getFirstImageURL } from '@product-detail/utils/product-images.util';
import { ListUpdatesViewModel } from '../model/list-updates.model';
import { ImageVariantEnum } from '@product-detail/models/image-variant.enum';
import { IListState, listUpdates, selectListsState } from '@usf/ngrx-list';
import {
  OriginalProductConversionCard,
  ProductConversionContainer,
  ReplacementProductConversionCard,
} from '../../shared/models/product-conversion.model';
import { formatBenefits } from '../../better-buys/selectors/better-buys-selectors';
import {
  selectAppStateSelectedCustomer,
  selectedCustomer,
} from '../../ngrx-customer/store';
import { selectOnline } from '@panamax/app-state';
import { ForcedUpdate } from '@usf/list-types';
import { BetterBuy } from '@usf/product-types';

export const getUpdatedLists = (
  listState: IListState,
  listUpdate: ForcedUpdate,
) => {
  const listLookup = listUpdate.updatedLists ?? [];
  const listNames = [];
  listLookup.forEach(lookup => {
    lookup.lists.forEach(list => {
      const listToCheck =
        listState.entities[
          list.listKey?.listTypeId + '-' + list.listKey?.listId
        ];
      if (!!listToCheck) {
        listNames.push(listToCheck.listName);
      }
    });
  });
  return listNames;
};

export const selectListUpdatesViewModel = (productNumbers: number[]) =>
  createSelector(
    listUpdates,
    productDetailsViewModelSelector(productNumbers),
    selectAppStateSelectedCustomer,
    selectOnline(),
    selectListsState,
    (
      listUpdates,
      productDetails,
      selectedCustomer,
      isOnline,
      listState,
    ): ListUpdatesViewModel => {
      const listUpdatesViewModel: ListUpdatesViewModel = {
        productConversionContainers: [],
        pricingDisabled: selectedCustomer?.ogPrintPriceInd === 'N',
        isOnline,
      };

      listUpdates.forEach(listUpdate => {
        const listsThatAreUpdated = getUpdatedLists(listState, listUpdate);

        const originalProductDetails = productDetails.get(
          listUpdate.oldProductNumber,
        );
        const replacementProductDetails = productDetails.get(
          listUpdate.newProductNumber,
        );
        const originalProduct: OriginalProductConversionCard = {
          ...originalProductDetails,
          smallImageUrl: getFirstImageURL(
            originalProductDetails?.summary,
            ImageVariantEnum.Small,
          ),
          listsThatAreUpdated,
        };
        const replacementProduct: ReplacementProductConversionCard = {
          ...replacementProductDetails,
          smallImageUrl: getFirstImageURL(
            replacementProductDetails?.summary,
            ImageVariantEnum.Small,
          ),
          formattedBenefits: formatBenefits(
            replacementProductDetails?.detail?.benefits,
          ),
          savings: listUpdate.savings,
          savingsUom: listUpdate.savingsUom,
          annualSavings: listUpdate.annualSavings,
          annualSavingsUom: listUpdate.annualSavingsUom,
          samePackSize:
            replacementProductDetails?.summary?.salesPackSize ===
            originalProductDetails?.summary?.salesPackSize,
          reasonCode: listUpdate.reason,
          pricingAvailableForSavings:
            (replacementProductDetails?.pricing?.eachPrice > 0 ||
              replacementProductDetails?.pricing?.unitPrice > 0) &&
            (originalProductDetails?.pricing?.eachPrice > 0 ||
              originalProductDetails?.pricing?.unitPrice > 0),
          listsThatAreUpdated,
        };

        const productConversionContainer: ProductConversionContainer = {
          originalProduct,
          replacementProduct,
        };
        listUpdatesViewModel.productConversionContainers.push(
          productConversionContainer,
        );
      });
      return {
        ...listUpdatesViewModel,
        updateCount: listUpdatesViewModel.productConversionContainers.length,
      };
    },
  );
