import { Component, Input, ViewChild } from '@angular/core';
import {
  IonSearchbar,
  ModalController,
  PopoverController,
  ViewWillEnter,
} from '@ionic/angular';
import { PlatformEnum, PlatformService } from '@panamax/app-state';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerSelectionVM } from '../../../../customer/models/customer-selection-vm.model';
import { CustomerSelectionOption } from '../../../../customer/models/customer-selection-option.model';

@Component({
  template: '',
})
export class CustomerSelectionBaseComponent implements ViewWillEnter {
  @Input() searchText$: BehaviorSubject<string>;
  @Input() vm$: Observable<CustomerSelectionVM>;
  @Input() showSearchbar = true;
  @Input() selectedDepartmentNumber: string;
  @ViewChild(IonSearchbar, { static: false }) searchbar: IonSearchbar;

  searchText = '';
  PlatformEnum = PlatformEnum;

  constructor(
    private modalController: ModalController,
    private popoverController: PopoverController,
    readonly platformService: PlatformService,
  ) {}

  ionViewWillEnter() {
    if (this.showSearchbar) {
      this.searchText$.next(this.searchText);
    }
    if (
      this.platformService.platformType !==
      this.platformService.platformEnum.mobile
    ) {
      setTimeout(() => this?.searchbar?.setFocus(), 500);
    }
  }

  filterCustomers() {
    this.searchText$.next(this.searchText);
  }

  async switchTo(customerSelectionOption: CustomerSelectionOption) {
    // Customers with departments cannot have department 0 selected
    if (
      customerSelectionOption.hasDepartments &&
      customerSelectionOption.departmentNumber === '0'
    ) {
      return;
    }
    const isDepartmentSelected = !!this.selectedDepartmentNumber
      ? customerSelectionOption.departmentNumber ===
        this.selectedDepartmentNumber
      : customerSelectionOption.isSelected;
    // Same customer
    if (isDepartmentSelected) {
      if (!customerSelectionOption.hasDepartments) {
        return;
      }
      // if customer and department are already selected, no reason to change anything
      if (isDepartmentSelected) {
        return;
      }
    }

    if (this.platformService.isTouch.value) {
      await this.modalController.dismiss({
        customerNumber: customerSelectionOption.customerNumber,
        departmentNumber: customerSelectionOption.departmentNumber,
      });
    } else {
      await this.popoverController.dismiss({
        customerNumber: customerSelectionOption.customerNumber,
        departmentNumber: customerSelectionOption.departmentNumber,
      });
    }
  }

  async multipleSwitch(customerSelectionOptions: CustomerSelectionOption[]) {
    let customers = [];
    customers = customerSelectionOptions.map(c => {
      return c.customerNumber;
    });
    await this.modalController.dismiss({
      customers,
    });
  }

  async close() {
    await this.modalController.dismiss();
  }
}
