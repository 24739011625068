import { ItemTypes } from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { UsfProductCardModeEnum } from '../constants/usf-product-card-mode.enum';
import { Product } from '../models/product.model';
import { UsfProductCardViewModel } from '../models/usf-product-card.model';

export class ProductCardModelHelper {
  static create(
    product: Product,
    inProgressOrderItems,
    online: boolean,
    selectedCustomer,
    mode: UsfProductCardModeEnum = UsfProductCardModeEnum.defaultSearch,
  ): UsfProductCardViewModel {
    const orderItem =
      inProgressOrderItems?.entities[
        inProgressOrderItems?.ids.find(id => id === product?.productNumber)
      ];
    if (!!product && !!orderItem) {
      product.eachesOrdered = orderItem?.eachesOrdered;
      product.casesOrdered = orderItem?.unitsOrdered;
    }
    const alternativeOrderItem =
      inProgressOrderItems?.entities[
        inProgressOrderItems?.ids.find(
          id => id === product?.alternative?.product?.productNumber,
        )
      ];
    if (!!product && !!alternativeOrderItem) {
      product.alternative.product.eachesOrdered =
        alternativeOrderItem?.eachesOrdered;
      product.alternative.product.casesOrdered =
        alternativeOrderItem?.unitsOrdered;
    }
    const productCardVM: UsfProductCardViewModel = {
      mode: mode,
      product,
      itemType: ItemTypes.product,
      isOnline: online,
      pricingDisabled: selectedCustomer?.ogPrintPriceInd === 'N',
    };
    return productCardVM;
  }
}
