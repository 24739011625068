import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

const historyStorageKey = 'historyUrl';

@Injectable({
  providedIn: 'root',
})
export class DetailHistoryService {
  private history: string[] = [];

  constructor(private router: Router, private location: Location) {
    this.startSaveHistory();
  }

  public startSaveHistory(): void {
    this.history = this.getSavedHistory() || [];
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('home')) {
          this.clearHistory();
        } else {
          this.saveHistory(event.urlAfterRedirects);
        }
      }
    });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public goBack(): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/home');
    }
  }

  private getSavedHistory(): string[] {
    return JSON.parse(localStorage.getItem(historyStorageKey));
  }

  private saveHistory(url: string) {
    this.history.push(url);
    localStorage.setItem(historyStorageKey, JSON.stringify(this.history));
  }

  private clearHistory() {
    this.history = [];
    localStorage.removeItem(historyStorageKey);
  }

  public getPreviousUrl(): string {
    if (this.history.length > 0) {
      return this.history[this.history.length - 2];
    }
    return '';
  }
}
