import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';

export const MY_ORDERS_ANALYTICS_ACTIONS = {
  trackPageLoad: createAction(
    '[NgRxClient Analytics] - Track My Orders Page Load',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackPastOrdersTabClick: createAction(
    '[NgRxTrace - Order] - Track Past Orders Tab Click',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackCopyOrderModalOpen: createAction(
    '[NgRxOrder Analytics] Track Copy Order Modal Open',
    props<{ tracking: Tracking }>(),
  ),
};
