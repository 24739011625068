import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Injectable()
export class PopoverHelperService {
  presentPopover = async (
    popoverController: PopoverController,
    component: any,
    description: string,
    title?: string,
  ) => {
    const popover = await popoverController.create({
      component,
      componentProps: {
        description,
        title,
      },
      cssClass: 'warning-popover',
      mode: 'ios',
      translucent: true,
    });
    await popover.present();
  };
}
