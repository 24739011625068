import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  ActionSheetController,
  IonNav,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlatformEnum } from '@panamax/app-state';
import { CostReportModalOutput } from '@usf/ngrx-inventory';
import { EllipsisOptionsPopoverComponent } from '../../../shared/components/popovers/ellipsis-options/ellipsis-options-popover.component';
import { RadioBtnListComponent } from '../../../shared/components/radio-btn-list/radio-btn-list.component';
import { CostReportSortOptions } from '../../models/cost-report-model.model';
import { InventoryState } from '@usf/ngrx-inventory/lib/models/inventory/inventory.model';
import { InventoryViewModel } from '@inventory/models/inventory-view.model';

@Component({
  selector: 'app-cost-report-modal',
  templateUrl: './cost-report-modal.component.html',
  styleUrls: ['./cost-report-modal.component.scss'],
})
export class CostReportModalComponent implements OnInit {
  @Input() platformType: PlatformEnum;
  @Input() allInventories: InventoryState[];
  @Input() currentInventory?: InventoryViewModel;
  @Input() glFlag: boolean;

  platformEnum = PlatformEnum;

  selectedDate: string;
  selectedInventoryDisplay: string;
  selectedFormat: string;
  selectedSort: string;
  selectedInventories: InventoryState[] = [];
  includeGlCodes = false;

  dateButtons = [];
  dateLabels = [];
  inventoryButtons = [];
  inventoryLabels = [];
  formatButtons = [
    {
      text: 'PDF',
      handler: () => {
        this.selectedFormat = 'PDF';
        this.selectedSort = CostReportSortOptions.lineNumberAsc;
        this.dismissBottomHalfPopups();
      },
    },
    {
      text: 'CSV',
      handler: () => {
        this.selectedFormat = 'CSV';
        this.selectedSort = CostReportSortOptions.lineNumberAsc;
        this.dismissBottomHalfPopups();
      },
    },
  ];

  sortButtons = [
    {
      text: this.translateService.instant(CostReportSortOptions.lineNumberAsc),
      handler: () => {
        this.selectedSort = CostReportSortOptions.lineNumberAsc;
        this.dismissBottomHalfPopups();
      },
    },
    {
      text: this.translateService.instant(CostReportSortOptions.descriptionAsc),
      handler: () => {
        this.selectedSort = CostReportSortOptions.descriptionAsc;
        this.dismissBottomHalfPopups();
      },
    },
    {
      text: this.translateService.instant(CostReportSortOptions.extendedDesc),
      handler: () => {
        this.selectedSort = CostReportSortOptions.extendedDesc;
        this.dismissBottomHalfPopups();
      },
    },
    {
      text: this.translateService.instant(
        CostReportSortOptions.groupsWithSubTotal,
      ),
      handler: () => {
        this.selectedSort = CostReportSortOptions.groupsWithSubTotal;
        this.dismissBottomHalfPopups();
      },
    },
    {
      text: this.translateService.instant(CostReportSortOptions.productNumAsc),
      handler: () => {
        this.selectedSort = CostReportSortOptions.productNumAsc;
        this.dismissBottomHalfPopups();
      },
    },
    {
      text: this.translateService.instant(
        CostReportSortOptions.glCodesWithProductDetail,
      ),
      handler: () => {
        this.selectedSort = CostReportSortOptions.glCodesWithProductDetail;
        this.includeGlCodes = true;
        this.dismissBottomHalfPopups();
      },
    },
    {
      text: this.translateService.instant(
        CostReportSortOptions.glCodesWithoutProductDetail,
      ),
      handler: () => {
        this.selectedSort = CostReportSortOptions.glCodesWithoutProductDetail;
        this.includeGlCodes = true;
        this.dismissBottomHalfPopups();
      },
    },
  ];

  constructor(
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private popoverController: PopoverController,
    private ionNav: IonNav,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
  ) {}

  ngOnInit(): void {
    this.selectedFormat = 'PDF';
    this.selectedSort = CostReportSortOptions.lineNumberAsc;
    if (!!this.currentInventory) {
      const formattedDate = this.datePipe.transform(
        this.currentInventory.inventoryDate,
        'M/d/yyyy',
      );
      this.selectedInventoryDisplay =
        this.currentInventory.inventoryName + ' ' + formattedDate;
      this.selectedDate = formattedDate;
    } else {
      this.selectedInventoryDisplay =
        'i18n.inventory.inventoryAllCompletedTitle';
      this.selectedDate = this.datePipe.transform(
        this.allInventories[0].inventoryDate,
        'M/d/yyyy',
      );
    }
    this.createSelectedInventories();
    this.createDateButtonsAndLabels();
    this.createInventoryButtonsAndLabels();
  }

  createDateButtonsAndLabels() {
    const dateMap = new Map<string, number>();
    this.dateButtons = [];
    this.dateLabels = [];
    for (const inventory of this.allInventories) {
      const formattedDate = this.datePipe.transform(
        inventory.inventoryDate,
        'M/d/yyyy',
      );
      if (!dateMap.has(formattedDate)) {
        dateMap.set(formattedDate, 1);
        this.dateButtons.push({
          text: formattedDate,
          handler: () => {
            this.selectedDate = formattedDate;
            this.selectedInventoryDisplay =
              'i18n.inventory.inventoryAllCompletedTitle';
            this.createSelectedInventories();
            this.createInventoryButtonsAndLabels();
            this.popoverController.dismiss();
          },
        });
        this.dateLabels.push(formattedDate);
      }
    }
  }

  createInventoryButtonsAndLabels() {
    this.inventoryButtons = [];
    this.inventoryLabels = [];
    for (const inventory of this.allInventories) {
      const formattedDate = this.datePipe.transform(
        inventory.inventoryDate,
        'M/d/yyyy',
      );
      if (formattedDate === this.selectedDate) {
        const invName = inventory.inventoryName + ' ' + formattedDate;
        this.inventoryButtons.push({
          text: invName,
          handler: () => {
            this.selectedInventoryDisplay = invName;
            this.createSelectedInventories();
            this.popoverController.dismiss();
          },
        });
        this.inventoryLabels.push(invName);
      }
    }
    this.inventoryButtons.unshift({
      text: 'i18n.inventory.inventoryAllCompletedTitle',
      handler: () => {
        this.selectedInventoryDisplay =
          'i18n.inventory.inventoryAllCompletedTitle';
        this.createSelectedInventories();
        this.popoverController.dismiss();
      },
    });
    this.inventoryLabels.unshift('i18n.inventory.inventoryAllCompletedTitle');
  }

  createSelectedInventories() {
    this.selectedInventories = [];
    for (const inventory of this.allInventories) {
      const formattedDate = this.datePipe.transform(
        inventory.inventoryDate,
        'M/d/yyyy',
      );
      const invName = inventory.inventoryName + ' ' + formattedDate;
      if (
        this.selectedInventoryDisplay ===
          'i18n.inventory.inventoryAllCompletedTitle' &&
        this.selectedDate === formattedDate
      ) {
        this.selectedInventories.push(inventory);
      } else if (this.selectedInventoryDisplay === invName) {
        this.selectedInventories = [inventory];
      }
    }
  }

  openSelectDateDropdown(event: any) {
    if (this.platformType === this.platformEnum.desktop) {
      this.openPopover(event, this.dateButtons);
    } else {
      this.ionNav.push(RadioBtnListComponent, {
        label: 'i18n.inventory.selectDate',
        listOfSelectableLabels: this.dateLabels,
        selectedLabel: this.selectedDate,
        callBack: this.callBackDate.bind(this),
        platformType: this.platformType,
      });
    }
  }

  callBackDate(selectedDate: string) {
    this.selectedDate = selectedDate;
    this.selectedInventoryDisplay = 'i18n.inventory.inventoryAllCompletedTitle';
    this.createSelectedInventories();
    this.createInventoryButtonsAndLabels();
  }

  openSelectInventoryDropdown(event: any) {
    if (this.platformType === this.platformEnum.desktop) {
      this.openPopover(event, this.inventoryButtons);
    } else {
      this.ionNav.push(RadioBtnListComponent, {
        label: 'i18n.inventory.selectInventory',
        listOfSelectableLabels: this.inventoryLabels,
        selectedLabel: this.selectedInventoryDisplay,
        callBack: this.callBackInventory.bind(this),
        platformType: this.platformType,
      });
    }
  }

  callBackInventory(selectedInventoryDisplay: string) {
    this.selectedInventoryDisplay = selectedInventoryDisplay;
    this.createSelectedInventories();
  }

  async openSelectFormatDropdown(event: any) {
    if (this.platformType === this.platformEnum.desktop) {
      this.openPopover(event, this.formatButtons);
    } else {
      this.openActionSheet(this.formatButtons);
    }
  }

  dismissBottomHalfPopups() {
    if (this.platformType === this.platformEnum.desktop) {
      this.popoverController.dismiss();
    } else {
      this.actionSheetController.dismiss();
    }
  }

  async openSortByDropDown(event: any) {
    let filteredSortButtons =
      this.selectedFormat === 'PDF'
        ? this.sortButtons
        : this.sortButtons.filter(button => {
            return (
              button.text !==
              this.translateService.instant(
                'i18n.inventory.costReportModal.groupsWithSubTotal',
              )
            );
          });
    filteredSortButtons = this.glFlag
      ? filteredSortButtons
      : filteredSortButtons.filter(button => !button.text.includes('GL'));

    if (this.platformType === this.platformEnum.desktop) {
      this.openPopover(event, filteredSortButtons);
    } else {
      this.openActionSheet(filteredSortButtons);
    }
  }

  async openActionSheet(buttons: any[]) {
    buttons.push({
      text: this.translateService.instant('i18n.common.cancel'),
      role: 'cancel',
      cssClass: 'cancel-button',
    });
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'action-sheet-touch',
      mode: 'ios',
      buttons,
    });
    await actionSheet.present();
  }

  async openPopover(event: any, buttons: any[]) {
    const popover = await this.popoverController.create({
      component: EllipsisOptionsPopoverComponent,
      event,
      componentProps: {
        buttons,
      },
      cssClass: 'ellipsis-options-popover-desktop',
      mode: 'ios',
      showBackdrop: false,
    });
    await popover.present();
  }

  checkSortForGl() {
    // Checks for includeGlCodes being true because it reads the value prior to the model flipping to false on click
    if (
      this.includeGlCodes &&
      (this.selectedSort === CostReportSortOptions.glCodesWithProductDetail ||
        this.selectedSort === CostReportSortOptions.glCodesWithoutProductDetail)
    ) {
      this.selectedSort = CostReportSortOptions.lineNumberAsc;
    }
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  download(): void {
    this.modalController.dismiss({
      selectedDate: this.selectedDate,
      selectedInventories: this.selectedInventories,
      selectedFormat: this.selectedFormat,
      selectedSort: this.selectedSort,
      includeGlCodes: this.includeGlCodes,
    } as CostReportModalOutput);
  }
}
