import { Component } from '@angular/core';
import { PanAppState } from '@panamax/app-state';

@Component({
  template: '',
})
export class BaseComponent {

  public currentUrl$ = this.panAppState.currentUrl$;
  public previousUrl$ = this.panAppState.previousUrl$;

  constructor(
    protected panAppState: PanAppState,
  ) {
  }
}
