<ion-header class="ion-header-modal-touch">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        id="back-modal-btn"
        data-cy="copy-list-back-modal-button"
        text=""
      ></ion-back-button>
    </ion-buttons>
    <ion-label data-cy="copy-list-option-text"
      >{{selectedCopyListOption}}</ion-label
    >
  </ion-toolbar>
</ion-header>

<ion-content class="copy-list-input_content">
  <!-- List of Lists -->
  <div *ngIf="selectedCopyListOption === listCopyOptions.selectList">
    <ion-searchbar
      data-cy="copy-list-searchbar"
      class="search"
      placeholder="{{ 'i18n.lists.copyListModal.searchByList' | translate }}"
      [(ngModel)]="searchKey"
      (ionClear)="resetSearch()"
      (ionInput)="search()"
    >
    </ion-searchbar>
    <div class="no-results-found" *ngIf="filteredListView.length === 0">
      {{'i18n.lists.copyListModal.noResultsFound' | translate}}
    </div>

    <ion-radio-group
      class="list-selection"
      *ngIf="filteredListView.length > 0"
      name="list-selection"
      value="{{selectedList?.listName}}"
      (ionChange)="handleListSelect($event)"
    >
      <ion-item *ngFor="let list of filteredListView">
        <ion-radio
          mode="md"
          class="list-item"
          labelPlacement="start"
          value="{{list?.listName}}"
          data-cy="list-name-radio"
        >
          <div>
            <div class="list-name">{{list.listName}}</div>
            <div class="product-count">
              {{list.listItemCount}} {{ list.listItemCount === 1 ?
              ('i18n.lists.copyListModal.product' | translate) :
              ('i18n.lists.copyListModal.products' | translate) }}
            </div>
          </div>
        </ion-radio>
      </ion-item>
    </ion-radio-group>
  </div>
  <!-- newListName -->
  <div
    class="create-list-input"
    *ngIf="selectedCopyListOption === listCopyOptions.newListName"
  >
    <ion-item
      class="create-list-name"
      [class.list-name-exists]="(listNameExists)"
    >
      <ion-input
        label="{{ 'i18n.lists.newListName' | translate }}"
        label-placement="floating"
        id="usf-line-input-style"
        placeholder="{{'i18n.lists.copyListModal.enterName' | translate}}"
        data-cy="list-name-input"
        maxlength="30"
        [(ngModel)]="newListName"
        (ngModelChange)="getListNameExists()"
        value="{{newListName}}"
        (ionInput)="listNameChange($event)"
        trimSpace
      ></ion-input>
    </ion-item>
    <p
      *ngIf="(listNameExists)"
      class="list-name-exists-error-text"
      data-cy="list-name-exists-message-text"
    >
      {{ 'i18n.lists.listNameExists' | translate }}
    </p>
  </div>
  <!-- ListType -->
  <div *ngIf="selectedCopyListOption === listCopyOptions.listType">
    <ion-radio-group
      mode="md"
      *ngIf="filteredListView.length > 0"
      [(ngModel)]="listType"
      data-cy="list-type-radio-group"
    >
      <ion-item>
        <ion-radio
          slot="end"
          value="{{ ListTypes.public }}"
          data-cy="public-list-type-radio"
          class="list-type"
        >
          {{ 'i18n.lists.public' | translate }}
        </ion-radio>
      </ion-item>
      <ion-item>
        <ion-radio
          slot="end"
          value="{{ ListTypes.internal }}"
          data-cy="internal-list-type-radio"
          class="list-type"
        >
          {{ 'i18n.lists.internal' | translate }}
        </ion-radio>
      </ion-item>
      <ion-item>
        <ion-radio
          slot="end"
          value="{{ ListTypes.private }}"
          data-cy="private-list-type-radio"
          class="list-type"
        >
          {{ 'i18n.lists.private' | translate }}
        </ion-radio>
      </ion-item>
    </ion-radio-group>
  </div>
  <!-- Markets -->
  <div
    class="create-list-input"
    *ngIf="selectedCopyListOption === listCopyOptions.markets"
  >
    <ion-item class="select-all" *ngIf="filteredListView.length > 0">
      <ion-checkbox
        justify="end"
        [checked]="marketSelectedCount === divisions.length"
        (ionChange)="handleSelectAllChange($event)"
      >
        {{marketSelectedCount === divisions.length
        ?('i18n.lists.copyListModal.deselectAll' | translate) :
        ('i18n.lists.copyListModal.selectAll' | translate)}}
      </ion-checkbox>
    </ion-item>
    <div>
      <ion-item *ngFor="let division of divisions">
        <ion-checkbox
          class="list-item"
          value="{{division?.divisionNumber}}"
          (ionChange)="handleMarketSelection($event)"
          [checked]="division?.isSelected"
        >
          {{division?.divisionName}} ({{division.divisionNumber}})
        </ion-checkbox>
      </ion-item>
    </div>
  </div>
  <!-- Customers -->
  <div
    class="create-list-input"
    *ngIf="selectedCopyListOption === listCopyOptions.customers"
  >
    <ion-searchbar
      data-cy="copy-list-inventory-searchbar"
      class="search"
      placeholder="{{ 'i18n.lists.copyListModal.searchForCustomers' | translate }}"
      [(ngModel)]="searchKey"
      (ionClear)="resetSearch()"
      (ionInput)="search()"
    >
    </ion-searchbar>
    <div class="no-results-found" *ngIf="filteredCustomersView.length === 0">
      {{'i18n.lists.copyListModal.noResultsFound' | translate}}
    </div>
    <ion-item class="select-all" *ngIf="filteredCustomersView.length > 0">
      <ion-checkbox
        justify="end"
        [checked]="maximumSelectableCustomerCount === customerSelectedCount"
        (ionChange)="handleSelectAllChange($event)"
      >
        {{customerSelectedCount === maximumSelectableCustomerCount
        ?('i18n.lists.copyListModal.deselectAll' | translate) :
        ('i18n.lists.copyListModal.selectAll' | translate)}}
      </ion-checkbox>
    </ion-item>

    <div *ngFor="let customer of filteredCustomersView">
      <ion-item *ngIf="customer.departments.length === 0">
        <div class="circle-badge">
          {{findFirstLetter(customer.customerName)}}
        </div>
        <ion-checkbox
          class="list-item-customer"
          value="{{customer?.customerNumber}}"
          (ionChange)="handleCustomerSelection($event)"
          [checked]="customer?.isSelected"
        >
          {{customer.customerName.slice(0,11)}} ({{customer.customerNumber}})
        </ion-checkbox>
      </ion-item>
      <ion-item
        class="list-item-dept-customer"
        *ngIf="customer.departments.length > 0"
      >
        <div class="dept-customer-wrapper">
          <div class="circle-badge">
            {{findFirstLetter(customer.customerName)}}
          </div>
          <div class="dept-customer-info">
            {{customer.customerName.slice(0,11)}} ({{customer.customerNumber}})
          </div>
        </div>
      </ion-item>
      <ion-item *ngFor="let department of customer.departments">
        <ion-checkbox
          class="list-item-customer"
          value="{{department.customerNumber}}-{{ department?.departmentNumber}}"
          (ionChange)="handleDepartmentSelection($event)"
          [checked]="department?.isSelected"
        >
          <div class="department-info">{{department.departmentName}}</div>
        </ion-checkbox>
      </ion-item>
    </div>
  </div>
  <!-- END CUSTOMERS -->
</ion-content>
<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="platformType !== platformEnum.mobile"
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="cancel-copy-list-modal-button"
        [ngClass]="
          platformType !== platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismiss()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        data-cy="copy-list-modal-button"
        [ngClass]="
          (selectedCopyListOption === listCopyOptions.newListName && listNameExists)
          || (selectedCopyListOption === listCopyOptions.selectList && selectedList === undefined)
          ? 'usf-fill-disabled-mobile-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="save()"
      >
        {{ 'i18n.common.save' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
