<div
  class="popover-warning-offline-inner"
  [ngClass]="{ touch: (this.platformService?.isTouchExperience$() | async) }"
>
  <div class="icon-container">
    <ion-icon
      color="success"
      name="close"
      size="large"
      data-cy="message-modal-close-button"
      (click)="dismiss()"
    ></ion-icon>
  </div>

  <div class="popover-warning-offline-content">
    <img
      *ngIf="message?.type === 'warning'"
      src="../../../../../assets/images/alert-orange.svg"
      class="alert-icon-border"
      data-cy="message-warning-image"
    />

    <img
      *ngIf="message?.type === 'success'"
      src="../../../../../assets/images/alert-green.svg"
      class="alert-icon-border"
      data-cy="message-success-image"
    />

    <img
      *ngIf="message?.type === 'error'"
      src="../../../../../assets/images/alert-orange.svg"
      class="alert-icon-border"
      data-cy="message-error-image"
    />

    <div class="warning-text">
      <h3 data-cy="message-modal-title">
        {{ message?.title || message?.type | titlecase }}
      </h3>
      <p data-cy="message-modal-description-text">{{ message?.display }}</p>

      <ion-button
        [ngClass]="{
          'usf-fill-green-button': message?.type === 'success',
          'usf-fill-orange-button':
            message?.type === 'warning' || message?.type === 'error'
        }"
        (click)="dismiss()"
        data-cy="message-modal-continue-button"
      >
        {{ 'i18n.common.continue' | translate }}
      </ion-button>
    </div>
  </div>
</div>
