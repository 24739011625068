import { Component } from '@angular/core';
import { MultiSelectItems } from '@shared/constants/dual-pane.enum';
import {
  DualPaneViewModel,
  SelectableItem,
} from '@shared/models/dual-pane-model';
import { Observable, firstValueFrom, map } from 'rxjs';
import { SearchablePaneComponent } from '../searchable-pane/searchable-pane.component';
import { dualPaneMultiSelectPrefix } from '@shared/constants/dual-pane-constants';
import { PlatformEnum } from '@panamax/app-state';

@Component({
  selector: 'app-multi-select-checkbox',
  templateUrl: './multi-select-checkbox.component.html',
  styleUrls: [
    './multi-select-checkbox.component.scss',
    '../../dual-pane-shared.scss',
  ],
})
export class MultiSelectCheckboxComponent extends SearchablePaneComponent {
  MultiSelectItemsRef = MultiSelectItems;
  readonly PlatformTypeRef = PlatformEnum;

  constructor() {
    super();
  }

  handleCheckboxSelection = ($event, item: SelectableItem) => {
    const leftPaneItem = this.vm.leftView.content?.find(pane => pane.selected);
    const currentSelections = leftPaneItem.value ?? [];
    const selectedItems = new Map<string, SelectableItem>();
    currentSelections.forEach(selectedItem => {
      const keyArray = [selectedItem.label, selectedItem.value.customerNumber];
      const key = keyArray.join();
      selectedItems.set(key, selectedItem);
    });
    item.selected = $event?.detail?.checked;
    const keyArray = [item.label, item.subLabel];
    const itemKey = keyArray.join();
    if ($event?.detail?.checked) {
      selectedItems.set(itemKey, item);
    } else {
      selectedItems.delete(itemKey);
    }
    this.vm.rightView.countPane.primaryCount += $event?.detail?.checked
      ? 1
      : -1;
    this.vm = this.vm.rightView.handleItemSelection(
      this.vm,
      this.platform,
      this.vm.rightView,
      Array.from(selectedItems.values()),
      dualPaneMultiSelectPrefix + selectedItems.size,
      item,
    );
    this.checkValidity.emit();
  };

  handleSelectAllChange = ($event, searchedItems: SelectableItem[]) => {
    const selected = $event?.detail?.checked;
    const leftPaneItem = this.vm.leftView.content?.find(pane => pane.selected);
    const currentSelections = leftPaneItem.value ?? [];
    const selectedItems = new Map<string, SelectableItem>();
    currentSelections.forEach(selectedItem => {
      const keyArray = [selectedItem.label, selectedItem.value.customerNumber];
      const key = keyArray.join();
      selectedItems.set(key, selectedItem);
    });
    const filteredItems = searchedItems.filter(
      item => item.type !== MultiSelectItems.iconHeader,
    );
    filteredItems.forEach(item => {
      item.selected = selected;
      const keyArray = [item.label, item.subLabel];
      const itemKey = keyArray.join();
      if (selected) {
        selectedItems.set(itemKey, item);
      } else {
        selectedItems.delete(itemKey);
      }
    });
    this.vm.rightView.countPane.primaryCount = selected
      ? filteredItems.length
      : 0;

    this.vm = this.vm.rightView.handleItemSelection(
      this.vm,
      this.platform,
      this.vm.rightView,
      Array.from(selectedItems.values()),
      dualPaneMultiSelectPrefix + selectedItems.size,
    );
    this.checkValidity.emit();
  };

  findFirstLetter(customerName: String) {
    const regex = /([a-zA-Z]{1})/;
    return customerName[customerName.search(regex)];
  }
}
