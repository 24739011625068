import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  RatingAverageCSSEnum,
  RatingStarsCSSEnum,
} from '@shared/models/power-reviews/pwr-rating-css';
import { PowerReviewRollupModel } from '@usf/ngrx-product/lib/models/power-review-product.model';

@Component({
  selector: 'app-power-custom-review-rating-stars',
  templateUrl: './power-custom-review-rating-stars.component.html',
  styleUrls: ['./power-custom-review-rating-stars.component.scss'],
})
export class PowerCustomReviewRatingStarsComponent
  implements OnInit, OnChanges {
  @Input() powerReviewRollupModel: PowerReviewRollupModel;
  @Input() ratingStarsCSSEnum: RatingStarsCSSEnum;
  @Input() ratingAverageCSSEnum: RatingAverageCSSEnum;

  ratingAverageRounded: number;
  constructor() {}

  ngOnInit() {
    this.formatRatingAverageDecimal();
    this.roundAverageRating();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formatRatingAverageDecimal();
    this.roundAverageRating();
  }

  formatRatingAverageDecimal() {
    if (this.powerReviewRollupModel != undefined) {
      this.powerReviewRollupModel = {
        ...this.powerReviewRollupModel,
        average_rating: Number(
          this.powerReviewRollupModel?.average_rating.toFixed(1),
        ),
      };
    }
  }

  roundAverageRating() {
    let starNumber = Math.floor(this.powerReviewRollupModel?.average_rating);
    const floatValue = Number(
      ((this.powerReviewRollupModel?.average_rating % 1) * 10).toFixed(0),
    );
    if (floatValue >= 3 && floatValue < 8) {
      starNumber = starNumber + 0.5;
    }
    if (floatValue >= 8 && floatValue < 10) {
      starNumber = starNumber + 1;
    }
    this.ratingAverageRounded = starNumber;
  }
}
