import { createSelector } from '@ngrx/store';
import { timezoneByCode } from '@shared/constants/timezone-constants';
import { Customer, Department } from '@usf/customer-types';
import {
  Order,
  OrderState,
  orderSelectors,
  OrderETAMessageType,
} from '@usf/ngrx-order';
import dayjs from 'dayjs';
import utc from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import {
  selectedCustomer,
  selectedDepartment,
} from 'src/app/ngrx-customer/store';

dayjs.extend(utc);
dayjs.extend(timezone);

export const selectWMTTileData = createSelector(
  selectedCustomer,
  selectedDepartment,
  orderSelectors.selectOrderContextState,
  (
    selectedCustomer: Customer,
    selectedDepartment: Department,
    orderHeaderState: OrderState,
  ): { wmtTileOrderData: Order } => {
    let myOrders: Order[] = (orderHeaderState.ids as Array<string | number>)
      .map((id: string | number) => {
        try {
          const order = orderHeaderState?.entities[id];

          // Order is current customer's
          const orderHeaderDepartmentNumber =
            order?.orderHeader?.departmentNumber || 0;
          const selectedDepartmentNumber = selectedDepartment?.departmentNumber
            ? +selectedDepartment?.departmentNumber
            : 0;
          const orderBelongsToCustomer =
            order?.orderHeader?.customerNumber ===
              selectedCustomer?.customerNumber &&
            orderHeaderDepartmentNumber === selectedDepartmentNumber;
          const orderETAMessageType =
            order?.orderHeader?.orderDelivered ||
            order?.orderHeader?.etaMessageType === OrderETAMessageType.ETA ||
            order?.orderHeader?.etaMessageType === OrderETAMessageType.PLANNED;

          // Order is for today
          const customerTZ =
            timezoneByCode.get(selectedCustomer?.timeZone)?.location ||
            'America/Chicago';
          const todaysDate = dayjs(new Date())?.tz(
            timezoneByCode.get(customerTZ)?.location,
          );

          let orderIsForToday = false;
          if (
            order?.orderHeader?.orderDelivered === true &&
            !!order?.orderHeader?.deliveredDtm
          ) {
            orderIsForToday = dayjs(order?.orderHeader?.deliveredDtm).isSame(
              todaysDate,
              'day',
            );
          } else if (
            !!order?.orderHeader?.etaRangeMinimum &&
            !!order?.orderHeader?.etaRangeMaximum &&
            !!order?.orderHeader?.deliveryEta &&
            isValidDate(order?.orderHeader?.etaRangeMinimum) &&
            isValidDate(order?.orderHeader?.etaRangeMaximum) &&
            isValidDate(order?.orderHeader?.deliveryEta)
          ) {
            orderIsForToday = dayjs(order?.orderHeader?.etaRangeMinimum).isSame(
              todaysDate,
              'day',
            );
          }

          if (
            orderBelongsToCustomer &&
            orderETAMessageType &&
            orderIsForToday
          ) {
            return order;
          }
        } catch (error) {
          console.log('[WmtTileViewModelSelector] Exception occurred', error);
          return null;
        }
      })
      .filter(order => {
        if (order) {
          return order;
        }
      })
      .sort((a: Order, b: Order) => {
        if (a.orderHeader.orderDelivered === true) {
          return (
            new Date(b.orderHeader?.deliveredDtm).getTime() -
            new Date(a.orderHeader?.deliveredDtm).getTime()
          );
        } else {
          return (
            new Date(b.orderHeader?.etaRangeMinimum).getTime() -
            new Date(a.orderHeader?.etaRangeMinimum).getTime()
          );
        }
      });
    return {
      wmtTileOrderData: myOrders.reduce((max, current) => {
        return current?.orderHeader?.totalPrice > max?.orderHeader?.totalPrice
          ? current
          : max;
      }, myOrders[0]),
    };
  },
);

export const isValidDate = (dateToValidate: any): boolean => {
  try {
    if (new Date(dateToValidate).toString() === 'Invalid Date') return false;
  } catch (e) {
    return false;
  }
  return true;
};
