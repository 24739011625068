import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { PLATFORM_ACTIONS } from '@panamax/app-state';
import { NavigationHelperService } from '@shared/helpers/navigation.helpers.service';
import { PATHS } from '@shared/constants/paths';
import { MAX_TIME_IN_BACKGROUND } from '@shared/constants/lifecycle-constants';

@Injectable({
  providedIn: 'root',
})
export class PlatformEffects {
  appForeground$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PLATFORM_ACTIONS.appForeground),
        tap(action => {
          if (action.timeInBackground >= MAX_TIME_IN_BACKGROUND) {
            this.navigationHelperService.routeTo(PATHS.HOME);
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private navigationHelperService: NavigationHelperService,
  ) {}
}
