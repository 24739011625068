export const FILE_LIMIT = 5242880;

export const BASE64_DATATYPE = 'data:application/octet-stream;base64,';
export const BASE64_CSV_DATATYPE = 'data:text/csv;base64,';
export const BASE64_EXCEL_DATATYPE = 'data:application/vnd.ms-excel;base64,';
export const BASE64_PDF_DATATYPE = 'data:application/pdf;base64,';
export const BASE64_QUICKBOOKS_DATATYPE = 'data:text/iif;base64,';
export const BASE64_ZIP_DATATYPE = 'data:application/zip;base64,';
export const BASE64_XML_DATATYPE = 'data:text/xml;base64,';

export const CONTENT_TYPE_CSV = 'text/csv';
export const CONTENT_TYPE_CSV_ANDROID = 'text/comma-separated-values';
export const CONTENT_TYPE_EXCEL = 'application/vnd.ms-excel';
export const CONTENT_TYPE_XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const CONTENT_TYPE_PDF = 'application/pdf';
export const CONTENT_TYPE_QUICKBOOKS = 'text/iif';
export const CONTENT_TYPE_ZIP = 'application/zip';
export const CONTENT_TYPE_STD = 'text/plain';
export const CONTENT_TYPE_XML = 'text/xml';

export const NONE = 'none';
export const CSV_CONTENTTYPE = 'text/csv;charset=utf-8';
export const TSV_CONTENTTYPE = 'text/tsv;charset=utf-8';
