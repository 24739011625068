import { Component } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ListDetailManagementService } from '../../../list-detail-management/services/list-detail-management.service';
import { SelectedProductsBaseComponent } from '../../selected-products-base.component';

@Component({
  selector: 'app-selected-products-input',
  templateUrl: './selected-products-input.component.html',
  styleUrls: ['./selected-products-input.component.scss'],
})
export class SelectedProductsInputComponent extends SelectedProductsBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    listDetailManagementService: ListDetailManagementService,
  ) {
    super(nav, modalController, platformService, listDetailManagementService);
  }
}
