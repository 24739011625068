import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import { timezoneByCode } from '@shared/constants/timezone-constants';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

@Pipe({
  name: 'timezoneDate',
})
export class TimezoneDatePipe implements PipeTransform {
  transform(
    date: string | Date,
    format: string = 'h:mm a',
    timezone: string = 'CT',
    includeTimezoneAbbr: boolean = false,
  ) {
    const tz = timezoneByCode.get(timezone);
    const formattedDate = includeTimezoneAbbr
      ? dayjs(date).tz(tz?.location).format(format) + ` ${tz?.abbr}`
      : dayjs(date).tz(tz?.location).format(format);

    return formattedDate;
  }
}
