export enum OrderTracingMessages {
  capability = 'orders',
  createNewOrderEvent = 'create order',
  createNewOrderFromHomePageTileEvent = 'create order Home Page Tile',
  openMethod = 'create order button',
  submitIpOrderButtonClick = 'Submit Order Start',
  submitEditedOrderButtonClick = 'Submit Edit Order Start',
  submitIpOrder = 'Submit Order',
  submitIpWithExceptionsEvent = 'Track Submit Order API Response Failure',
  submitEditWithExceptionsEvent = 'Track Submit Edit Order API Response Failure',
  addToOrderOrderWithErrorDetailsEvent = 'Track Add to Order',
  addToOrderFailureEvent = 'add to order',
  getOrderDetailEvent = 'get order detail',
  reviewOrderPageLoad = 'review order',
  cancelIPOrder = 'cancel IP order',
  cancelSubmittedOrder = 'Cancel Submitted Order',
  cancelSubmittedOrderContext = 'cancel submitted order',
  dyfBannerClick = 'dyf click',
  inspiredPicksBannerClick = 'inspired picks click',
  inspiredPicksCallEvent = 'Inspired Picks Call',
  getDirectShippingSavingsEvent = 'Direct Shipping Savings Call',
  nextDeliveryDateCallEvent = 'Next Delivery Date Call',
  getRecentOrdersCall = 'Recent Orders Call',
  orderCardClickedOnOrderStatus = 'Order Card Clicked on Order Status',
  orderCardClickedOnMyOrders = 'Order Card Clicked on My Orders',
  editOrderButtonClickedOnMyOrders = 'Edit Order on My Orders',
  editOrderButtonClickedOnOrderStatus = 'Edit Order on Order Status',
  getCustomerOrdersCall = 'Customer Orders Call',
  addToOrder = 'add to order',
  orderCPROverrideCall = 'Order CPR Override Call',
  orderCPRManualOverrideCall = 'Order CPR Manual Override Call',
  editOrder = 'Edit Order',
  createOrder = 'Create Order',
  cancelAddToOrderModal = 'Add to Order Modal Cancel',
  resolveExceptionsFromHomepageTile = 'Resolve Exceptions from Home Page Tile',
  goToCartOnHomepageTile = 'Go to Cart on Home Page Tile',
  modifyLinkClickOnHomePageTile = 'Edit Order on Home Page Tile',
  orderCardClickedOnPastOrders = 'Order Card Clicked on Past Orders',
  cancelCopyOrderModal = 'Close Clicked on Copy Order Modal',
  copyOrderClickedOnModal = 'Copy Order Clicked On Modal',
  transmitOrderContext = 'Track Order Transmit',
  transmitOrder = 'Order Transmit',
  qeProductSearch = 'QE Product Search',
  qeCustomerProductSearch = 'QE Customer Product Search',
  confirmUQEButtonClick = 'UQE Confirmed',
  getVptOrder = 'Get VPT Order',
  vptOrder = 'VPT Order',
  submitVptOrder = 'Submit VPT Order',
  submitVptOrderApiResponse = 'Submit VPT Order API Response',
  submitShipToOrderEvent = 'Submit Ship To Order Event',
  submitShipToOrder = 'Submit Ship To Order',
  shipToContext = 'shipto',
  submitShipToOrderApiResponse = 'Submit Ship To Order API Response',
}

export enum OrderTracingOpenMethod {
  createOrderButton = 'create order button',
  listPage = 'list page',
  PDP = 'PDP',
  searchPage = 'search page',
}
