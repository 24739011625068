<ion-item lines="full" (click)="onSelectProduct()">
  <ion-thumbnail *ngIf="product?.thumbnail" slot="start">
    <img src="{{ product.thumbnail }}" />
  </ion-thumbnail>
  <ion-icon
    *ngIf="!product?.thumbnail"
    name="restaurant"
    slot="start"
  ></ion-icon>
  <div class="radio-option-text">
    <p data-cy="'product-number-' + product?.productNumber + '-text'">
      #{{ product?.productNumber }}
      &nbsp;|&nbsp;
      {{ product?.productPackSize }}
    </p>
    <p data-cy="product-description-text-link">
      {{ product?.productDescription | titlecase }}
    </p>
  </div>
  <ion-radio
    mode="md"
    slot="end"
    value="{{ product?.productNumber }}"
    [attr.data-cy]="'product-' + product?.productNumber + '-radio'"
  ></ion-radio>
</ion-item>
