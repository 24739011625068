<div
  [attr.data-cy]="'selectable-inventory-section'"
  class="inventory-selectable-item"
  [class.is-selected]="isSelected"
  (click)="onSelectInventory()"
>
  <div
    [attr.data-cy]="'selectable-inventory-checkbox-section'"
    class="inventory-checkbox-section"
  >
    <app-usf-checkbox [isSelected]="isSelected"></app-usf-checkbox>
  </div>
  <ion-item
    [attr.data-cy]="'selectable-inventory-info-section-'"
    [class.inventory-completed]="
      inventory?.status === InventoryStatus.COMPLETED
    "
    lines="none"
  >
    <div
      *ngIf="inventory?.status === InventoryStatus.COMPLETED"
      [attr.data-cy]="'selectable-inventory-completed-banner-'"
      class="inventory-completed-banner"
    >
      <p
        [attr.data-cy]="'selectable-inventory-completed-banner-text-'"
        class="bold"
      >
        {{ 'i18n.inventory.complete' | translate }}
      </p>
    </div>
    <ion-label
      id="inventory-count"
      [attr.data-cy]="'selectable-inventory-name-date-text-'"
      class="bold inventory-header-text ion-text-wrap"
    >
      {{ inventory?.inventoryName }}
      {{ inventory?.inventoryDate | date: 'M/d/yyyy' }}
      <p
        [attr.data-cy]="'selectable-inventory-last-updated-text-'"
        class="inventory-subheader-text"
      >
        {{ 'i18n.inventory.lastUpdated' | translate }}&nbsp;{{
          inventory?.ecomUpdateDtm | date: 'MM/dd/yyyy'
        }}
      </p>
    </ion-label>
    <ion-badge
      aria-labelledby="inventory-count"
      [attr.data-cy]="'selectable-inventory-num-products-icon'"
      class="bold inventory-badge"
      mode="ios"
      slot="end"
    >
      {{ inventory?.count ?? 0 }}
    </ion-badge>
  </ion-item>
</div>
