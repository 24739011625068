<div
  id="quantity-input"
  [class.product-card]="isProductCard"
  [ngClass]="[
    (platformService?.isTouchExperience$() | async) ? 'touch' : 'desktop',
    !isDisabled ? 'enabled' : 'disabled',
  ]"
>
  <ion-button
    *ngIf="platformService?.isTouchExperience$() | async"
    id="{{ isEaches ? 'each-' : 'case-' }}decrement-{{ index }}"
    (click)="decrementQuantity(productNumber, itemInput?.value)"
    data-cy="click-decrement-quantity-button"
  >
    <ion-icon name="remove-outline"></ion-icon>
  </ion-button>
  <ion-input
    id="{{ id }}"
    class="quantity-input-box"
    disabled="{{ !(productNumber > 0) || isDisabled }}"
    globalkeyhandler="quantityInput"
    inputmode="numeric"
    min="0"
    type="text"
    maxlength="3"
    placeholder="{{
      (platformService?.isTouchExperience$() | async) === false && isDisabled
        ? null
        : 0
    }}"
    [value]="quantity?.currentValue > 0 ? quantity.currentValue : null"
    (ionBlur)="changeQuantity(productNumber, $event)"
    (ionFocus)="onFocus($event)"
    (keyup.enter)="changeQuantity(productNumber, $event)"
    data-cy="product-quantity-input"
    #itemInput
    numeric
  >
  </ion-input>
  <ion-button
    *ngIf="platformService?.isTouchExperience$() | async"
    id="{{ isEaches ? 'each-' : 'case-' }}increment-{{ index }}"
    (click)="incrementQuantity(productNumber, itemInput?.value)"
    data-cy="click-increment-quantity-button"
  >
    <ion-icon name="add-outline"></ion-icon>
  </ion-button>
</div>
