/* eslint-disable no-shadow */
export enum MessageTypeEnum {
  success = 'success',
  failure = 'failure',
  informational = 'informational',
  warning = 'warning',
  error = 'error',
}

export enum UpsertTypeEnum {
  http = 'http',
  global = 'global',
  toast = 'toast',
}
/* eslint-enable no-shadow */
