import { Injectable } from '@angular/core';
import { Product } from '@shared/models/product.model';
import { ProductService } from '../product/product.service';
import { take } from 'rxjs';
import { ProductDetailViewModel } from '@product-detail/models/product-detail-view-model';
import { ModalService } from '../modal/modal.service';
import { ModalController } from '@ionic/angular';
import { WorksWellWithModalComponent } from '@shared/components/works-well-with/modal/works-well-with-modal';
import { PanAppState, PlatformService } from '@panamax/app-state';
import { ProductAnalyticsService } from '@usf/ngrx-product';
import { ProductPropertiesEnum } from '@usf/product-types/Product-Summary';
import { MobileModalHeights } from '@shared/constants/modal-heights.enum';

@Injectable({
  providedIn: 'root',
})
export class WorksWellWithService {
  constructor(
    private productService: ProductService,
    private analyticsService: ProductAnalyticsService,
    private modalService: ModalService,
    private modalCtrl: ModalController,
    private panAppState: PanAppState,
    private platformService: PlatformService,
  ) {}

  public getWorksWellWithProducts(productNumbers: number[]) {
    this.productService.loadProducts(productNumbers);
    return this.productService.getProducts(productNumbers);
  }

  // filter products that are discontinued or don't exist
  // sort the remaining products by whether or not the quantity box is enabled
  public filterAndSortProducts(productsMap: Map<number, Product>) {
    return Array.from(productsMap.values())
      .filter(
        product =>
          !!product &&
          !product?.notFound &&
          !product.summary.properties?.has(ProductPropertiesEnum.discontinued),
      )
      .sort((productA, productB) => {
        if (
          productA.summary.properties?.has(ProductPropertiesEnum.specialOrder)
        ) {
          return 1;
        }

        if (
          productB.summary.properties?.has(ProductPropertiesEnum.specialOrder)
        ) {
          return -1;
        }

        if (productA.orderable === productB.orderable) {
          return 0;
        }

        if (productA.orderable === true) {
          return -1;
        }

        if (productB.orderable === true) {
          return 1;
        }
      });
  }

  async handleViewButtonClick(product: Product, productList: Product[]) {
    let customer;
    this.panAppState.customer$.pipe(take(1)).subscribe(c => (customer = c));
    let productDetails = productList.map(product => {
      return {
        ...product,
        selectedCustomer: customer,
      } as ProductDetailViewModel;
    });

    this.modalService.setModalOptions(
      this.platformService?.isTouch.value,
      WorksWellWithModalComponent,
      {
        product: product,
        worksWellWithProducts: productDetails,
        platform: this.platformService?.platformType,
      },
      'modal-desktop-size-lg',
      null,
      null,
      this.platformService?.platformType ===
        this.platformService?.platformEnum?.tablet
        ? MobileModalHeights.auto
        : null,
    );

    const modal = await this.modalCtrl.create(this.modalService.modalOptions);

    await modal.present();
  }

  public trackProductWorksWellWithButtonEvent(products: Product[]) {
    let productData: any[] = [];
    products.forEach(data => {
      const product = {
        divisionApn: `${data.summary.divisionNumber}-${data.summary.productNumber}`,
        attributes: data.trackingAttributes,
      };
      productData.push(product);
    });

    if (productData.length > 0) {
      this.analyticsService.trackProductWorksWellWithButton(productData);
    }
  }
}
