import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '@panamax/app-state';

@Component({
  selector: 'app-warning-popover',
  templateUrl: './warning-popover.component.html',
  styleUrls: ['./warning-popover.component.scss'],
})
export class WarningPopoverComponent {
  @Input() title = this.translateService.instant('i18n.home.warning');
  @Input() description = this.translateService.instant(
    'i18n.home.networkWarning',
  );

  constructor(
    private popoverController: PopoverController,
    readonly platformService: PlatformService,
    private translateService: TranslateService,
  ) {}

  async dismiss() {
    await this.popoverController.dismiss();
  }
}
