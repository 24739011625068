import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Message } from 'src/app/ngrx-message/models/client/message';
import { MessageState } from 'src/app/ngrx-message/models/state/message-state';
import * as MessageActions from 'src/app/ngrx-message/store/actions/message/message.action';

export const adapter: EntityAdapter<Message> = createEntityAdapter<Message>();
export const initialMessageState = adapter.getInitialState();

const messageReducer = createReducer(
  initialMessageState,
  on(MessageActions.upsertMessage, (state, { message }) =>
    adapter.upsertOne(message, state),
  ),
  on(MessageActions.upsertManyMessages, (state, { messages }) =>
    adapter.upsertMany(messages, state),
  ),
);

export const reducer = (state: MessageState, action: Action) =>
  messageReducer(state, action);

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal,
} = adapter.getSelectors();
