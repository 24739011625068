<ion-header
  class="ion-no-border"
  [ngClass]="
    platformType !== platformEnum.desktop
      ? platformType === platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons *ngIf="platformType === platformEnum.desktop" slot="end">
      <ion-button
        id="close-modal-btn"
        data-cy="close-download-inventory-modal-button"
        (click)="dismiss()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label
      *ngIf="platformType !== platformEnum.desktop"
      class="bold"
      data-cy="download-inventory-label"
    >
      <span>
        {{ 'i18n.common.download' | translate }} {{ inventoryName }}
        {{ inventoryDate | date: 'M/d/yy' }}
      </span>
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="download-inventory-content"
  [ngClass]="
    platformType !== platformEnum.desktop
      ? platformType === platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <div
    class="body-content"
    [class.is-touch]="platformType !== platformEnum.desktop"
    [class.is-mobile]="platformType === platformEnum.mobile"
  >
    <div *ngIf="platformType === platformEnum.desktop" class="title">
      <ion-label class="bold" data-cy="download-inventory-label">
        {{ 'i18n.common.download' | translate }} {{ inventoryName }}
        {{ inventoryDate | date: 'M/d/yy' }}
      </ion-label>
    </div>

    <div class="instructions">
      <span>{{
        'i18n.inventory.downloadInventoryModal.chooseFilename' | translate
      }}</span>
      <span class="bold">
        {{ 'i18n.inventory.downloadInventoryModal.download' | translate }}
      </span>
      <span>{{
        'i18n.inventory.downloadInventoryModal.beginGeneration' | translate
      }}</span>
    </div>

    <div class="file-name-format-section">
      <div class="file-name-section">
        <div class="label" id="file-name">
          <span>{{ 'i18n.lists.fileName' | translate }}</span>
          <span class="required-indicator">
            {{ 'i18n.common.requiredInputIndicator' | translate }}
          </span>
        </div>
        <ion-item>
          <ion-input
            aria-labelledby="file-name"
            id="file-name-input"
            data-cy="file-name-input"
            maxlength="60"
            [(ngModel)]="fileName"
            data-cy="new-file-name-input"
          ></ion-input>
        </ion-item>
      </div>

      <div class="format-section">
        <div class="label">
          <span>Select Format</span>
          <span class="required-indicator">
            {{ 'i18n.common.requiredInputIndicator' | translate }}
          </span>
        </div>
        <ion-item
          id="click-trigger"
          class="format-button"
          data-cy="select-format-option"
          button
          detail="true"
          detail-icon="chevron-down-outline"
          (click)="openFiletypeOptions($event)"
        >
          <span data-cy="select-format-option-value">
            {{ format }}
          </span>
        </ion-item>
      </div>
    </div>

    <div class="group-selection">
      <ion-item class="generate">
        <ion-label>
          {{ 'i18n.inventory.downloadInventoryModal.generate' | translate }}
        </ion-label>
      </ion-item>
      <ion-radio-group
        value="allGroups"
        [(ngModel)]="groupRadioValue"
        (ionChange)="onGroupSelection()"
      >
        <ion-item>
          <ion-radio value="allGroups" mode="md">
            {{ 'i18n.inventory.downloadInventoryModal.allGroups' | translate }}
          </ion-radio>
        </ion-item>
        <div
          *ngIf="platformType !== platformEnum.mobile"
          [class.border]="singleGroupChosen"
        >
          <ion-item [class.borderless]="singleGroupChosen">
            <ion-radio value="singleGroup" mode="md">
              {{
                'i18n.inventory.downloadInventoryModal.singleGroup' | translate
              }}
            </ion-radio>
          </ion-item>
          <ion-item
            *ngIf="singleGroupChosen"
            id="click-trigger"
            class="group-dropdown"
            data-cy="select-format-option"
            button
            detail="true"
            detail-icon="chevron-down-outline"
            (click)="openGroupOptions($event)"
          >
            <span data-cy="select-group-value">
              {{ selectedGroup }}
            </span>
          </ion-item>
        </div>
      </ion-radio-group>
      <ion-item
        *ngIf="platformType === platformEnum.mobile"
        class="select-group-mobile"
        button="true"
        (click)="onSelectGroupMobile()"
      >
        <div class="mobile-group">
          <ion-label [class.selected-title]="selectedGroup">
            {{
              'i18n.inventory.downloadInventoryModal.singleGroup' | translate
            }}
          </ion-label>
          <ion-label
            *ngIf="selectedGroup"
            [class.selected-group]="selectedGroup"
          >
            {{ selectedGroup }}
          </ion-label>
        </div>
      </ion-item>
    </div>

    <div class="checkbox-buttons">
      <ion-item *ngIf="format === 'PDF'" class="freehand-checkbox" lines="none">
        <ion-checkbox
          [(ngModel)]="includeFreehandCount"
          mode="ios"
          justify="start"
          labelPlacement="end"
          >{{
            'i18n.inventory.downloadInventoryModal.freehandCount' | translate
          }}</ion-checkbox
        >
      </ion-item>
      <ion-item class="status-checkbox" lines="none">
        <ion-checkbox
          [(ngModel)]="includeProductStatus"
          mode="ios"
          justify="start"
          labelPlacement="end"
          >{{
            'i18n.inventory.downloadInventoryModal.includeProductStatus'
              | translate
          }}</ion-checkbox
        >
      </ion-item>
    </div>
  </div>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="platformType !== platformEnum.mobile"
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="cancel-download-inventory-modal-button"
        [ngClass]="
          platformType !== platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismiss()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        data-cy="download-inventory-modal-button"
        [disabled]="!fileName"
        [ngClass]="
          platformType !== platformEnum.mobile
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="download()"
      >
        {{ 'i18n.common.download' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
