import { SeperatedColumn } from '@shared/models/seperated-columns';
import { ListColumns } from './document-helper-models-and-enums';
import { desiredColumn } from '@shared/models/desired-column';

/**
 *
 * @param seperatedColumns Contains List Data
 * @param numberOfRows The Number of Data Rows
 * @param seperator
 * @returns
 */
export const stringifyASCII = (
  seperatedColumns: SeperatedColumn[],
  numberOfRows: number,
  seperator: any,
  includeProductPrices: boolean,
): string => {
  // Variable to hold the seperated columns as one string
  let stringified = '';
  // note: no header row needed for fixed width ascii file
  // Add every row of data to return string
  for (let i = 0; i < numberOfRows; i++) {
    const line = seperatedColumns
      .map(pm => {
        // retrieve column value to be inserted
        const value = pm.columnValues ? pm.columnValues[i] : pm.singleValue;

        // apply fixed width formatting rules as needed
        let padString = value.toString().replace(/^"|"$/g, '');
        switch (pm.columnName) {
          case 'Product Number':
            padString = padString.substring(0, 7).padStart(7, '0');
            break;
          case 'Product Description':
            padString = padString.substring(0, 30).padEnd(30);
            break;
          case 'Product Brand':
            padString = padString.substring(0, 10).padEnd(10);
            break;
          case 'Product Package Size':
            padString = padString.substring(0, 12).padEnd(12);
            break;
          case 'Product Price':
            if (
              includeProductPrices &&
              padString !== 'No Price' &&
              padString !== 'Call for price'
            ) {
              // 4 whole digit and 2 decimals
              const splitPrice = padString.replace('$', '').split('.');
              if (splitPrice.length > 1)
                padString =
                  splitPrice[0].padStart(4, '0') +
                  '.' +
                  splitPrice[1].padStart(2, '0');
              else padString = splitPrice[0].padStart(4, '0') + '.00';
            } else {
              padString = '0000.00'; // change to fixed width price format if, not including prices or value is 'No Price' or 'Call for Price'
            }
            break;
          case 'Product UOM':
            padString = padString.substring(0, 2).padEnd(2);
            break;
          case 'Net Weight':
            // 5 whole digit and 3 decimals
            const netWeightSplit = padString.split('.');
            if (netWeightSplit.length > 1)
              padString =
                netWeightSplit[0].padStart(5, '0') +
                '.' +
                netWeightSplit[1].padStart(3, '0');
            else padString = netWeightSplit[0].padStart(5, '0') + '.000';
            break;
          case 'Customer Product Number':
            padString = padString.substring(0, 10).padEnd(10);
            break;
          case 'Catch Weight Product':
            padString = padString.padEnd(1);
            break;
          case 'Breakable Product':
            padString = padString.padEnd(1);
            break;
        }

        let resultString: string;
        if (
          pm.columnName !== 'Product Price' &&
          pm.columnName !== 'Net Weight'
        ) {
          // add double quote to all columns which are not price or weight
          resultString = '"' + padString + '"';
        } else {
          // do not add double quotes to price and weight fields
          resultString = padString;
        }
        return resultString;
      })
      .join(seperator);

    // append the line of mapped fixed width data to stringified result
    stringified += line + '\r\n';
  }
  return stringified;
};

export const getASCIIColumns = (
  includePoductPrices: boolean,
  includePoductNotes: boolean,
  includeProductType: boolean,
  includeProductStatus: boolean,
): desiredColumn[] => {
  const seperatedColumns = [];
  seperatedColumns.push({
    columnName: 'Product Number',
    columnType: ListColumns.productNumber,
  });
  seperatedColumns.push({
    columnName: 'Product Description',
    columnType: ListColumns.productDescription,
  });
  seperatedColumns.push({
    columnName: 'Product Brand',
    columnType: ListColumns.productBrand,
  });
  seperatedColumns.push({
    columnName: 'Product Package Size',
    columnType: ListColumns.productPackageSize,
  });
  seperatedColumns.push({
    columnName: 'Product Price',
    columnType: ListColumns.productPrice,
  });
  if (includePoductPrices)
    seperatedColumns.push({
      columnName: 'Product UOM',
      columnType: ListColumns.productUom,
    });
  seperatedColumns.push({
    columnName: 'Net Weight',
    columnType: ListColumns.netWeight,
  });
  seperatedColumns.push({
    columnName: 'Customer Product Number',
    columnType: ListColumns.customerProductNumber,
  });
  seperatedColumns.push({
    columnName: 'Catch Weight Product',
    columnType: ListColumns.catchWeightFlag,
  });
  seperatedColumns.push({
    columnName: 'Breakable Product',
    columnType: ListColumns.breakable,
  });
  return seperatedColumns;
};
