import { FormControl } from '@angular/forms';

export class FileFormControl extends FormControl {
  setValue(fileList: FileList | null, options: any = {}) {
    if (fileList) {
      super.setValue(fileList[0], options);
    } else {
      super.setValue(null, options);
    }
  }
}
