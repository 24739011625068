import { Component } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ListAnalyticsService, ListConstants } from '@usf/ngrx-list';
import { SortFilterListBaseComponent } from '../sort-filter-list-base.component';
import { SortFilterListInputComponent } from './sort-filter-list-input/sort-filter-list-input.component';

@Component({
  selector: 'app-sort-filter-list-mobile',
  templateUrl: './sort-filter-list-mobile.component.html',
  styleUrls: ['./sort-filter-list-mobile.component.scss'],
})
export class SortFilterListMobileComponent extends SortFilterListBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    listAnalyticsService: ListAnalyticsService,
  ) {
    super(nav, modalController, platformService, listAnalyticsService);
  }

  setSort(sortOption: ListConstants) {
    if (sortOption === ListConstants.alphabetical) {
      this.alphabeticalSelected = true;
      this.dateSelected = false;
      this.selectedSort = ListConstants.alphabetical;
    } else if (sortOption === ListConstants.date) {
      this.dateSelected = true;
      this.alphabeticalSelected = false;
      this.selectedSort = ListConstants.date;
    } else {
      const selectedGroupToPassOn =
        sortOption !== this.selectedSort
          ? ListConstants.allGroups
          : this.selectedGroup;
      this.nav.push(SortFilterListInputComponent, {
        selectedSort: sortOption,
        selectedGroup: selectedGroupToPassOn,
        groupNames: this.groupNames,
        productClasses: this.productClasses,
        isRecentPurchase: this.isRecentPurchase,
        callBack: this.callBackSetSort.bind(this),
      });
    }
  }

  callBackSetSort(selectedSort: ListConstants, selectedGroup: string) {
    this.selectedSort = selectedSort;
    this.selectedGroup = selectedGroup;
    this.alphabeticalSelected = false;
    this.dateSelected = false;
  }
}
