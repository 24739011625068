import { NgRxOrderLibConfig } from '@usf/ngrx-order';
import { environment } from 'src/environments/environment';

export const NG_RX_ORDER_LIB_CONFIG: NgRxOrderLibConfig = {
  apiUrl: environment.orderApiUrl,
  submissionApiUrl: environment.orderSubmissionApiUrl,
  requestReplyApiUrl: environment.orderRequestReplyApiUrl,
  orderImportApiUrl: environment.orderImportApiUrl,
  partnerApiUrl: environment.partnerApiUrl,
  webSocketUrl: environment.webSocketUrl,
};
