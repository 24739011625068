import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APP_ERRORS_STATE_KEY } from '../../constants/constants';
import { AppErrorState } from '../../models/state/app-error-state';
import { AppError } from '@app/ngrx-message/models/client/app-error';

const selectFeature = createFeatureSelector<AppErrorState>(
  APP_ERRORS_STATE_KEY,
);

const selectAppErrorState = createSelector(
  selectFeature,
  (appErrorState: AppErrorState) => appErrorState,
);

const selectAppErrors = createSelector(
  selectAppErrorState,
  (appErrorState: AppErrorState) => appErrorState?.appErrors,
);

const selectModals = createSelector(selectAppErrors, (appErrors: AppError[]) =>
  appErrors?.filter(errors => !!errors?.modal),
);

export const appErrorSelectors = {
  selectAppErrors,
  selectModals,
};
