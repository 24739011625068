export enum ImportOrderValidationTracingConstants {
  capability = 'orders',
  cancelContext = 'Cancel Import Order',
  event = 'Cancel Import Order',
  fileFormat = 'Upload File for Import',
  submitImportOrderEvent = 'Submit Import Order',
  submitImportOrderContext = 'Submit Import Order',
  submitImportOrderOpenMethod = 'Submit Import Order',
  loadImportOrder = 'Upload File for Import',
  pageLoadContext = 'Import Order Validations Page Load',
  pageLoadEvent = 'Validations Page',
}
