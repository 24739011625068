import { Product } from '@shared/models/product.model';
import { ProductFilteringEnum } from '@shared/constants/product-filtering.enum';
import { ProductInventory, ProductPropertiesEnum } from '@usf/product-types';
import {
  customerIsMslRestricted,
  customerIsOgRestricted,
  customerIsUnRestricted,
} from './customer.helpers';
import { Customer } from '@usf/customer-types';
import { productHasStatus } from '@usf/ngrx-product';
import { mslProductHasSecondaries } from '../selectors/helpers/product-info.selectors.helper';

// Aux functions
const productPassesStatusAndInventoryRestrictions = (
  product: Product,
  selectedCustomer: Customer,
): boolean => {
  return (
    product.inventory?.productStatus === '0' ||
    productIsStat1AndDirectOrCES(product, selectedCustomer) ||
    productIsStat2348WithInventoryOnHand(product)
  );
};

const productIsStat1AndDirectOrCES = (
  product: Product,
  selectedCustomer: Customer,
): boolean => {
  return (
    productHasStatus('1', product.inventory) &&
    ((product.summary?.properties?.has(ProductPropertiesEnum.direct) &&
      selectedCustomer?.directEligible) ||
      product.summary?.properties?.has(ProductPropertiesEnum.ces))
  );
};

const productIsStat2348WithInventoryOnHand = (product: Product): boolean => {
  return (
    isProductWithInventoryOnHand(product.inventory) &&
    !isStat28Direct(product.inventory) &&
    productIsStatus2348(product?.inventory.productStatus)
  );
};

const isProductWithInventoryOnHand = (inventory: ProductInventory): boolean => {
  return inventory?.available && inventory?.isInStock;
};

const isStat28Direct = (inventory: ProductInventory): boolean => {
  return (
    inventory?.isDirect &&
    (inventory?.productStatus === '2' || inventory?.productStatus === '8')
  );
};

const productIsStatus2348 = (productStatus: string) => {
  return (
    productStatus === '2' ||
    productStatus === '3' ||
    productStatus === '4' ||
    productStatus === '8'
  );
};

const productPassesOgRestrictions = (
  product: Product,
  selectedCustomer: Customer,
): boolean => {
  return (
    customerIsUnRestricted(selectedCustomer) ||
    (customerIsMslRestricted(selectedCustomer) &&
      mslProductHasSecondaries(product?.mslProduct)) ||
    (customerIsOgRestricted(selectedCustomer) && productIsOnOrderGuide(product))
  );
};

const productIsOnOrderGuide = (product: Product): boolean => {
  return product?.summary?.properties?.has(ProductPropertiesEnum.onOrderGuide);
};

const productIsNotChefStore2or3 = (product: Product) => {
  return !(
    product?.inventory?.cashCarryIndicator === '2' ||
    product?.inventory?.cashCarryIndicator === '3'
  );
};

// filtering logic
const filterByDefault = (
  product: Product,
  selectedCustomer: Customer,
): boolean => {
  if (!!product) {
    if (
      productPassesStatusAndInventoryRestrictions(product, selectedCustomer) &&
      productPassesOgRestrictions(product, selectedCustomer) &&
      productIsNotChefStore2or3(product)
    ) {
      return true;
    }
  }
  return false;
};

const applyFilter = (
  product: Product,
  selectedCustomer: Customer,
  filterType: ProductFilteringEnum = ProductFilteringEnum.DEFAULT,
): boolean => {
  switch (filterType) {
    case ProductFilteringEnum.DYF:
    case ProductFilteringEnum.INSPIRED_PICKS:
    case ProductFilteringEnum.REVIEW_ORDER_MERCH_ZONE:
    case ProductFilteringEnum.FREQUENTLY_BOUGHT_TOGETHER:
    case ProductFilteringEnum.TM_NOTES:
    case ProductFilteringEnum.PROMOTIONS:
    case ProductFilteringEnum.YOU_MAY_ALSO_NEED:
    case ProductFilteringEnum.SUGGESTED_PRODUCT:
    case ProductFilteringEnum.CAB:
    case ProductFilteringEnum.TOP_SELLERS:
    case ProductFilteringEnum.DEFAULT:
      return filterByDefault(product, selectedCustomer);
  }
  return false;
};

const filterProductsMap = (
  products: Map<number, Product>,
  productNumbers: number[],
  selectedCustomer: Customer,
  filterType: ProductFilteringEnum = ProductFilteringEnum.DEFAULT,
): Map<number, Product> => {
  // if products map is not defined, return it as is.
  if (!products) {
    return products;
  }
  // if productNumbers are not defined, return the map as is
  if (!productNumbers || productNumbers?.length <= 0) {
    return products;
  }
  // Apply filter
  return productNumbers.reduce((filteredMap, productNumber) => {
    const product = products.get(productNumber);
    if (applyFilter(product, selectedCustomer, filterType)) {
      filteredMap.set(productNumber, product);
    }

    return filteredMap;
  }, new Map<number, Product>());
};

export const ProductFilter = {
  filterProductsMap,
  applyFilter,
};
