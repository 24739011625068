<ion-header class="ion-no-border ion-header-modal-desktop">
  <ion-toolbar>
    <ion-buttons
      *ngIf="(platformService?.isTouchExperience$() | async) === false"
      slot="end"
    >
      <ion-button
        data-cy="close-complete-inventory-modal-button"
        (click)="dismissModalAndNav(true)"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content
  class="inventory-completion-content"
  scroll-y="false"
  [ngClass]="
    (platformService?.isTouchExperience$() | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
  *ngIf="completedInventory$ | async as inventory"
>
  <img
    *ngIf="mode === InventoryEllipsisModalModes.complete"
    class="success-icon"
    src="../../../../../assets/images/alert-green.svg"
  />
  <img
    *ngIf="mode === InventoryEllipsisModalModes.delete"
    class="success-icon"
    src="../../../../../assets/icon/search-not-found.svg"
  />
  <ion-label
    data-cy="complete-inventory-success-label"
    *ngIf="
      mode === InventoryEllipsisModalModes.complete ||
      mode === InventoryEllipsisModalModes.delete
    "
  >
    {{
      mode === InventoryEllipsisModalModes.complete
        ? ('i18n.inventory.inventoryWorksheet.completeInventorySuccess'
          | translate)
        : platformService?.platformType === platformService?.platformEnum.mobile
        ? ('i18n.inventory.deleteInventoryWorksheetModal.headerMobile'
          | translate)
        : ('i18n.inventory.deleteInventoryWorksheetModal.header' | translate)
    }}
  </ion-label>

  <ion-label
    *ngIf="mode === InventoryEllipsisModalModes.delete"
    class="inventory-info-subheader"
    data-cy="ellipsis-modal-subtitle"
  >
    {{ 'i18n.inventory.deleteInventoryWorksheetModal.subheader' | translate }}
  </ion-label>

  <ion-label
    [ngClass]="
      mode === InventoryEllipsisModalModes.viewSummary
        ? 'view-summary-title'
        : 'inventory-info'
    "
    data-cy="complete-inventory-name-label"
  >
    {{ inventory?.inventoryName }} -
    {{ inventory?.inventoryDate | date: 'M/d/yyyy' }}
  </ion-label>

  <div
    *ngIf="mode !== InventoryEllipsisModalModes.delete"
    class="download-container"
  >
    <div (click)="openDownloadOptions()" id="download-btn" class="download-btn">
      <ion-icon name="download-outline" slot="icon-only"></ion-icon>
      {{ 'i18n.common.download' | translate }}
    </div>

    <!-- Download Options Popover-->
    <ion-popover
      *ngIf="
        this.platformService.platformType ===
        this.platformService.platformEnum.desktop
      "
      data-cy="download-inventory-summary-popover"
      class="download-inventory-summary-popover"
      mode="ios"
      showBackdrop="false"
      trigger="download-btn"
      dismissOnSelect="true"
    >
      <ng-template>
        <app-ellipsis-options-popover
          class="popover-viewport"
          [buttons]="downloadOptionsButtons"
        ></app-ellipsis-options-popover>
      </ng-template>
    </ion-popover>
  </div>

  <div class="gutter">
    <ion-row class="summary-header ion-justify-content-between">
      <ion-col size="3"> Group </ion-col>
      <ion-col size="2"> CS Count </ion-col>
      <ion-col size="2"> EA Count </ion-col>
      <ion-col
        [size]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? '2'
            : '3'
        "
      >
        Total Price
      </ion-col>
    </ion-row>
  </div>
  <ion-grid
    class="inventory-summary-grid gutter"
    [ngClass]="
      mode === InventoryEllipsisModalModes.delete
        ? 'delete'
        : mode === InventoryEllipsisModalModes.complete
        ? 'complete'
        : 'viewSummary'
    "
  >
    <ng-container *ngFor="let group of inventory.items">
      <ion-row
        *ngIf="group.itemType === 'group'"
        class="group-row ion-justify-content-between"
      >
        <ion-col size="3">
          {{ group.groupName }}
        </ion-col>
        <ion-col size="2">{{
          asGroup(group).totalCases | number: '1.0-2'
        }}</ion-col>
        <ion-col size="2">{{
          asGroup(group).totalEaches | number: '1.0-2'
        }}</ion-col>
        <ion-col
          [size]="
            platformService?.platformType !==
            platformService?.platformEnum?.mobile
              ? '2'
              : '3'
          "
        >
          {{
            (asGroup(group).totalPrice ? asGroup(group).totalPrice : '0.00')
              | currency
          }}
        </ion-col>
      </ion-row>
    </ng-container>
  </ion-grid>
  <div class="gutter">
    <ion-row class="total-row ion-justify-content-between">
      <ion-col size="3"> All Groups </ion-col>
      <ion-col size="2"> {{ inventory.totalCases | number: '1.0-2' }} </ion-col>
      <ion-col size="2">
        {{ inventory.totalEaches | number: '1.0-2' }}
      </ion-col>
      <ion-col
        [size]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? '2'
            : '3'
        "
      >
        {{ (inventory.totalPrice ? inventory.totalPrice : '0.00') | currency }}
      </ion-col>
    </ion-row>
  </div>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  *ngIf="completedInventory$ | async as inventory"
>
  <ion-toolbar>
    <!-- Buttons for Complete Inventory modal: -->
    <ion-buttons *ngIf="mode === InventoryEllipsisModalModes.complete">
      <ion-button
        data-cy="exit-complete-inventory-modal-button"
        [ngClass]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismissModalAndNav(false)"
      >
        {{
          'i18n.inventory.inventoryWorksheet.backToEditInventory' | translate
        }}
      </ion-button>
      <ion-button
        data-cy="cancel-complete-inventory-modal-button"
        [ngClass]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="dismissModalAndNav(true)"
      >
        {{ 'i18n.inventory.inventoryWorksheet.returnToInventory' | translate }}
      </ion-button>
    </ion-buttons>

    <!-- Buttons for Delete Inventory modal: -->
    <ion-buttons *ngIf="mode === InventoryEllipsisModalModes.delete">
      <ion-button
        data-cy="exit-delete-inventory-modal-button"
        class="usf-outline-orange-button"
        (click)="dismissModalAndNav(false)"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        data-cy="cancel-complete-inventory-modal-button"
        class="usf-fill-orange-button"
        (click)="
          deleteInventory(
            inventory.inventoryId,
            inventory.inventoryName,
            inventory.inventoryDate
          )
        "
      >
        {{ 'i18n.inventory.deleteInventoryWorksheetModal.delete' | translate }}
      </ion-button>
    </ion-buttons>

    <!-- Buttons for View Summary modal: -->
    <ion-buttons *ngIf="mode === InventoryEllipsisModalModes.viewSummary">
      <ion-button
        data-cy="exit-view-inventory-summary-modal-button"
        class="usf-outline-green-button"
        (click)="dismissModalAndNav(false)"
      >
        {{ 'i18n.common.close' | translate }}
      </ion-button>
      <ion-button
        data-cy="continue-to-inventory-modal-button"
        class="usf-fill-green-button"
        (click)="goToWorksheet(inventory.inventoryId)"
      >
        {{
          'i18n.inventory.deleteInventoryWorksheetModal.continue' | translate
        }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<!-- Download Options Action sheet-->
<ion-action-sheet
  class="action-sheet-touch"
  mode="ios"
  data-cy="download-inventory-summary-action-sheet"
  [buttons]="downloadOptionsButtons"
  [isOpen]="isTouchDownloadOpen"
  (didDismiss)="isTouchDownloadOpen = false"
></ion-action-sheet>
