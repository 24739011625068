import { Component } from '@angular/core';
import { InventoryWorksheetService } from '@inventory/pages/inventory-worksheet/services/inventory-worksheet.service';
import { IonNav, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '@panamax/app-state';
import { InventoryActions } from '@inventory/constants/inventory-constants';
import { InventoryActionModalBaseComponent } from '../inventory-action-modal/inventory-action-modal-base.component';

@Component({
  selector: 'app-inventory-delete-modal',
  templateUrl: './inventory-delete-modal.component.html',
  styleUrls: ['./inventory-delete-modal.component.scss'],
})
export class InventoryDeleteModalComponent extends InventoryActionModalBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    translateService: TranslateService,
    platformService: PlatformService,
    inventoryWorksheetService: InventoryWorksheetService,
  ) {
    super(
      nav,
      modalController,
      translateService,
      platformService,
      inventoryWorksheetService,
    );
  }

  changeSelectedItemsAction(action: InventoryActions) {
    this.selectedAction = action;
  }
}
