import { Component, Input } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';

@Component({
  selector: 'app-create-edit-non-usf-vendor-input',
  templateUrl: './create-edit-non-usf-vendor-input.component.html',
  styleUrls: ['./create-edit-non-usf-vendor-input.component.scss'],
})
export class CreateEditNonUsfVendorInputComponent {
  @Input() selectedState: string;
  @Input() states: string[];

  constructor(public nav: IonNav, readonly platformService: PlatformService) {}

  returnToCreateNonUsfVendorForm() {
    this.nav.getPrevious().then(data => {
      data.params.selectedStateInput = this.selectedState;
      this.nav.pop();
    });
  }
}
