import { FoodCostReportState } from '@usf/ngrx-inventory';

export enum CostReportSortOptions {
  alphabeticalAsc = 'i18n.inventory.costReportModal.alphabeticalAsc',
  lineNumberAsc = 'i18n.inventory.costReportModal.lineNumberAsc',
  descriptionAsc = 'i18n.inventory.costReportModal.descriptionAsc',
  extendedDesc = 'i18n.inventory.costReportModal.extendedDesc',
  groupsWithSubTotal = 'i18n.inventory.costReportModal.groupsWithSubTotal',
  productNumAsc = 'i18n.inventory.costReportModal.productNumAsc',
  percentPriceChange = 'i18n.inventory.costReportModal.percentPriceChange',
  glCodesWithProductDetail = 'i18n.inventory.costReportModal.glCodesWithProductDetail',
  glCodesWithoutProductDetail = 'i18n.inventory.costReportModal.glCodesWithoutProductDetail',
}

export enum CostReportIncludeOptions {
  allProducts = 'i18n.inventory.costReportModal.allProducts',
  productsWithPriceChanges = 'i18n.inventory.costReportModal.productsWithPriceChanges',
}

export interface CostReportCardEllipsisClick {
  event: Event;
  foodCostReport: FoodCostReportState;
}
