import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';
import { MY_ORDERS_ANALYTICS_ACTIONS } from './my-orders-analytics.actions';
import { MyOrdersAnalyticsMessages } from './my-orders-analytics.constants';
import { myOrdersPageLoad } from './my-orders-analytics.transformers';
import { genericAnalyticsTransformer } from '@shared/tracking/analytics/generic-analytics-transformer';
import { OrderHeader } from '@usf/ngrx-order';

@Injectable({
  providedIn: 'root',
})
export class MyOrdersAnalyticsService {
  constructor(private store: Store) {}

  trackPageLoad(filters: string): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          pageName: MyOrdersAnalyticsMessages.myOrdersPageName,
          event: MyOrdersAnalyticsMessages.pageLoadedEvent,
          capability: MyOrdersAnalyticsMessages.capability,
          filters: filters,
        },
        transformFunc: myOrdersPageLoad,
      },
    };
    this.store.dispatch(
      MY_ORDERS_ANALYTICS_ACTIONS.trackPageLoad({ tracking }),
    );
  }
  trackPastOrdersTabClick(): void {
    const tracking: Tracking = {
      analytics: {
        data: {
          page: { pageName: MyOrdersAnalyticsMessages.pastOrdersPageName },
          event: MyOrdersAnalyticsMessages.pageLoadedEvent,
          capability: MyOrdersAnalyticsMessages.capability,
        },
        transformFunc: genericAnalyticsTransformer,
      },
    };
    this.store.dispatch(
      MY_ORDERS_ANALYTICS_ACTIONS.trackPastOrdersTabClick({ tracking }),
    );
  }
  trackCopyOrderModalOpen(orderHeader: OrderHeader) {
    const tracking: Tracking = {
      analytics: {
        data: {
          order: {
            id: orderHeader?.orderId,
          },
          event: MyOrdersAnalyticsMessages.modalOpen,
          capability: MyOrdersAnalyticsMessages.capability,
          modal: {
            name: MyOrdersAnalyticsMessages.copyOrder,
          },
        },
        transformFunc: genericAnalyticsTransformer,
      },
    };
    this.store.dispatch(
      MY_ORDERS_ANALYTICS_ACTIONS.trackCopyOrderModalOpen({ tracking }),
    );
  }
}
