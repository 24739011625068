import { createSelector } from '@ngrx/store';
import { partnerSelectors } from '@usf/ngrx-order';
import { PartnerState } from '@usf/ngrx-order/lib/models/state/partner-state';
import { PartnerPunchoutProfile } from '@usf/partner-types';
import { PunchoutReturnLinkData } from '../models/punchout-return-link-data.model';

export const selectPunchoutProfile = createSelector(
  partnerSelectors.selectPartner,
  (partnerState: PartnerState) => {
    return partnerState?.punchoutProfile;
  },
);

export const selectPunchoutReturnLinkData = createSelector(
  selectPunchoutProfile,
  (punchoutProfile: PartnerPunchoutProfile) => {
    return {
      returnLinkFlag: punchoutProfile?.returnLinkFlag,
      returnLinkText: punchoutProfile?.returnLink,
      returnLinkUrl: punchoutProfile?.returnLinkUrl,
    } as PunchoutReturnLinkData;
  },
);
