import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-left-view-checkbox',
  templateUrl: './left-view-checkbox.component.html',
  styleUrls: ['./left-view-checkbox.component.scss'],
})
export class LeftViewCheckboxComponent {
  @Input() label: string;
  @Input() index = 1;
  @Input() isChecked: boolean;

  @Output() clickValueEmitter: EventEmitter<boolean> = new EventEmitter();
  constructor() {}

  clickValue() {
    this.clickValueEmitter.emit();
  }
}
