import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ProductDetailStatusData } from '../../../shared/components/product-detail-status-tags-info/product-detail-status-tags-info.component';
import { ChipTypeEnum } from '../../../shared/components/usf-chip/models/chip-type.enum';

@Component({
  selector: 'app-product-detail-status',
  templateUrl: './product-detail-status.component.html',
  styleUrls: ['./product-detail-status.component.scss'],
})
export class ProductDetailStatusComponent implements OnInit, OnChanges {
  readonly chipTypeEnum = ChipTypeEnum;
  @Input() productDetailStatusData: ProductDetailStatusData;
  @Input() showProductClaims = true;
  displayProductDetailSection: boolean;

  constructor(
    readonly platformService: PlatformService,
    private modalController: ModalController,
  ) {}

  ngOnInit() {
    this.checkProductSectionVisibility();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.propertiesSet?.currentValue) {
      this.checkProductSectionVisibility();
    }
    if (changes.readOut?.currentValue) {
      this.checkProductSectionVisibility();
    }
    if (changes.alertMessage?.currentValue) {
      this.checkProductSectionVisibility();
    }
  }

  getClaimDescription(claim: any) {
    let claimFullName = claim.name.toLowerCase();
    if (claimFullName === 'child nutr. cert.') {
      claimFullName = 'child nutrition certificate';
    } else if (claimFullName === 'cert. angus beef') {
      claimFullName = 'certified angus beef';
    }
    return 'This product is ' + claimFullName;
  }

  checkProductSectionVisibility(): void {
    this.displayProductDetailSection =
      this.productDetailStatusData?.summary?.properties?.size > 0 ||
      (!!this.productDetailStatusData?.jitConfig &&
        !!this.productDetailStatusData?.alertMessage);
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
