import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DIVISION_STATE_KEY } from '../../constants/constants';
import { DivisionState } from '../../models/state/division-state';
import {
  selectDivisionEntities,
  selectAllDivisions,
} from '../reducers/division.reducers';
import { APP_STATE_KEY } from '@panamax/app-state';
import { AppState } from '@panamax/app-state/lib/models/app-state.model';
import { Division } from '@usf/customer-types';

const selectDivisionState = createFeatureSelector<DivisionState>(
  DIVISION_STATE_KEY,
);

const selectAppState = createFeatureSelector<AppState>(APP_STATE_KEY);

export const selectAppStateSelectedCustomerDivision = createSelector(
  selectAppState,
  (appState: AppState) => appState?.selectedCustomer.divisionNumber,
);

export const getDivisionEntities = createSelector(
  selectDivisionState,
  selectDivisionEntities,
);

export const getDivisions = createSelector(
  selectDivisionState,
  selectAllDivisions,
);

export const selectedCustomerDivision = createSelector(
  selectAppStateSelectedCustomerDivision,
  getDivisions,
  (divisionSelectedNumber: number, list: Division[]) =>
    list.find(division => division.divisionNumber === divisionSelectedNumber),
);

export const getDivisionError = createSelector(
  selectDivisionState,
  (divisionState: DivisionState) => divisionState.error,
);

export const getDivisionLoading = createSelector(
  selectDivisionState,
  (divisionState: DivisionState) => divisionState.loading,
);
