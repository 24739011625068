<ng-container
  *ngIf="
    ((!productDetailStatusData?.isSplitCard &&
      !productDetailStatusData?.showAlternative) ||
      productDetailStatusData?.isSplitCard) &&
    (!productDetailStatusData?.isQuickViewProductStatusModal ||
      (productDetailStatusData?.isQuickViewProductStatusModal &&
        !productDetailStatusData?.hasPromotions))
  "
>
  <!-- CES / DIRECT MESSAGE -->
  <ion-row *ngIf="productDetailStatusData?.vendorMessage">
    <span>
      {{ productDetailStatusData?.vendorMessage | truncateWithEllipsis: 200 }}
    </span>
  </ion-row>
  <!-- JIT MESSAGE -->
  <ion-row *ngIf="productDetailStatusData?.jitConfig?.showMessage">
    <span>
      {{
        productDetailStatusData?.jitConfig?.message | truncateWithEllipsis: 200
      }}
    </span>
  </ion-row>
  <!-- Warning Message-->
  <ion-row
    *ngIf="productDetailStatusData?.alertMessage?.display && !isQuickView"
  >
    <ion-col size="12">
      <app-product-message-banner
        [alertMessage]="productDetailStatusData?.alertMessage"
      ></app-product-message-banner>
    </ion-col>
  </ion-row>
</ng-container>
<!-- CONTRACT PILL & INFO -->
<ion-row *ngIf="!!productDetailStatusData?.contractPill">
  <ion-col size="6" size-xl="2.5" size-lg="3" size-md="4">
    <app-usf-chip
      [type]="productDetailStatusData?.contractPill?.type"
      [iconConfig]="productDetailStatusData?.contractPill?.iconConfig"
      [iconUrl]="productDetailStatusData?.contractPill?.iconUrl"
    >
      {{ productDetailStatusData?.contract?.iconDescription }} - CONTRACT
    </app-usf-chip>
  </ion-col>
  <ion-col size="6" size-xl="9.5" size-lg="9" size-md="8">
    <span>{{ productDetailStatusData?.contract?.iconText }}</span>
  </ion-col>
</ion-row>
<!-- DIRECT PILL & INFO -->
<ion-row
  *ngIf="
    productDetailStatusData?.summary?.properties?.has(propertiesEnum.direct)
  "
>
  <ion-col size="6" size-xl="2.5" size-lg="3" size-md="4">
    <app-usf-chip
      [type]="chipTypeEnum.image"
      [iconConfig]="{ name: 'DIRECT', iconWidth: 4.5 }"
      iconUrl="assets/icon/direct.svg"
    >
      {{ 'i18n.productDetail.status.direct' | translate }}
    </app-usf-chip>
  </ion-col>
  <ion-col size="6" size-xl="9.5" size-lg="9" size-md="8">
    <ul *appShowSplit="[directAllowReturnsFlag]">
      <li>{{ 'i18n.productDetail.status.ships' | translate }}</li>
      <li>
        {{
          'i18n.productDetail.status.arrivesWithin'
            | translate
              : {
                  vendorLeadTime:
                    productDetailStatusData?.summary?.vendorLeadTime,
                }
        }}
      </li>
      <li *ngIf="(isProductShippingPriceEnabled$ | async) === false">
        {{ 'i18n.productDetail.status.shippingIncluded' | translate }}
      </li>
      <li *ngIf="productDetailStatusData?.summary?.returnable !== 'Y'">
        {{ 'i18n.productDetail.status.noReturnsOrCancellations' | translate }}
      </li>
      <li *ngIf="productDetailStatusData?.summary?.returnable === 'Y'">
        {{ 'i18n.productDetail.status.returnsPolicy.title' | translate }}
        <ul>
          <li>
            {{
              'i18n.productDetail.status.returnsPolicy.productStatus'
                | translate
            }}
          </li>
          <li>
            {{
              'i18n.productDetail.status.returnsPolicy.originalPackaging'
                | translate
            }}
          </li>
          <li>
            {{
              'i18n.productDetail.status.returnsPolicy.returnWindow' | translate
            }}
          </li>
          <li>
            {{
              'i18n.productDetail.status.returnsPolicy.restockingFee'
                | translate
            }}
          </li>
          <li>
            {{
              'i18n.productDetail.status.returnsPolicy.shippingCost' | translate
            }}
          </li>
        </ul>
      </li>
    </ul>
    <ul *appHideSplit="[directAllowReturnsFlag]">
      <li>
        {{ 'i18n.productDetail.status.shipsNoDirectAllowReturns' | translate }}
      </li>
      <li>
        {{
          'i18n.productDetail.status.arrivesWithin'
            | translate
              : {
                  vendorLeadTime:
                    productDetailStatusData?.summary?.vendorLeadTime,
                }
        }}
      </li>
      <li>
        {{
          'i18n.productDetail.status.noReturnsOrCancellationsDirectReturnsNotAllowed'
            | translate
        }}
      </li>
      <li>
        {{ 'i18n.productDetail.status.shippingIncluded' | translate }}
      </li>
    </ul>
  </ion-col>
</ion-row>
<!-- RECENTLY PURCHASED PILL & INFO -->
<ion-row
  *ngIf="
    productDetailStatusData?.summary?.properties?.has(
      propertiesEnum.recentlyPurchased
    )
  "
>
  <ion-col size="6" size-xl="2.5" size-lg="3" size-md="4">
    <app-usf-chip>
      {{ 'i18n.productDetail.status.recentPurchase' | translate }}
    </app-usf-chip>
  </ion-col>
  <ion-col size="6" size-xl="9.5" size-lg="9" size-md="8">
    <span>
      {{ 'i18n.productDetail.status.lastRecentPurchase' | translate }}
    </span>
    <div class="recent-purchase-list">
      <table>
        <tbody>
          <tr
            *ngFor="
              let record of productDetailStatusData?.recentPurchaseRecords
            "
          >
            <td>
              <ul>
                <li>{{ record?.dateOrdered }}</li>
              </ul>
            </td>
            <td *ngIf="record?.casesOrdered > 0" class="recent-purchase-cell">
              {{ record?.casesOrdered }}
            </td>
            <td *ngIf="record?.casesOrdered > 0">
              {{ productDetailStatusData?.summary?.priceUom }}
            </td>
            <td *ngIf="record?.casesOrdered > 0 && record?.eachesOrdered > 0">
              |
            </td>
            <td *ngIf="record?.eachesOrdered > 0" class="recent-purchase-cell">
              {{ record?.eachesOrdered }}
            </td>
            <td *ngIf="record?.eachesOrdered > 0">
              {{ productDetailStatusData?.summary?.eachUom }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ion-col>
</ion-row>
<!-- ON ML/ON R-ML PILL & INFO -->
<ion-row
  *ngIf="
    productDetailStatusData?.summary?.properties?.has(
      propertiesEnum.onMasterList
    )
  "
>
  <ion-col size="6" size-xl="2.5" size-lg="3" size-md="4">
    <app-usf-chip>
      {{
        (productDetailStatusData?.isMslRestricted
          ? 'i18n.productDetail.status.onMasterListRestricted'
          : 'i18n.productDetail.status.onMasterList'
        ) | translate
      }}
    </app-usf-chip>
  </ion-col>
  <ion-col size="6" size-xl="9.5" size-lg="9" size-md="8">
    <div class="list-of-lists">
      <table>
        <tbody>
          <tr *ngFor="let masterList of productDetailStatusData?.masterLists">
            <td>
              <ul>
                <li>
                  {{ masterList }}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ion-col>
</ion-row>
<!-- DWO PILL & INFO -->
<ion-row
  *ngIf="productDetailStatusData?.summary?.properties?.has(propertiesEnum.dwo)"
>
  <ion-col size="6" size-xl="2.5" size-lg="3" size-md="4">
    <app-usf-chip>
      {{ 'i18n.productDetail.status.dwo' | translate }}
    </app-usf-chip>
  </ion-col>
  <ion-col size="6" size-xl="9.5" size-lg="9" size-md="8">
    <span>{{ 'i18n.productDetail.status.dwoDescription' | translate }}</span>
  </ion-col>
</ion-row>
<!-- ON ORDER GUIDE SHOPPING LIST PILL & INFO -->
<ion-row
  *ngIf="
    productDetailStatusData?.summary?.properties?.has(
      propertiesEnum.onOrderGuideOrOnShoppingList
    )
  "
>
  <ion-col size="6" size-xl="2.5" size-lg="3" size-md="4">
    <app-usf-chip>
      {{ 'i18n.productDetail.status.onMyList' | translate }}
    </app-usf-chip>
  </ion-col>
  <ion-col size="6" size-xl="9.5" size-lg="9" size-md="8">
    <div class="list-of-lists">
      <table>
        <tbody>
          <tr *ngFor="let list of productDetailStatusData?.lists">
            <td>
              <ul>
                <li>
                  {{ list }}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ion-col>
</ion-row>
<!-- CUSTOMER PILL -->
<ion-row *ngIf="!!productDetailStatusData?.customerPill">
  <ion-col size="6" size-xl="2.5" size-lg="3" size-md="4">
    <app-usf-chip
      [type]="chipTypeEnum.customerPill"
      [textColor]="productDetailStatusData?.customerPill?.textColor"
      [backgroundColor]="productDetailStatusData?.customerPill?.backgroundColor"
    >
      {{ productDetailStatusData?.customerPill?.content }}
    </app-usf-chip>
  </ion-col>
  <ion-col size="6" size-xl="9.5" size-lg="9" size-md="8">
    <span
      [innerHTML]="productDetailStatusData?.customerPill?.description"
    ></span>
  </ion-col>
</ion-row>
<!-- SCOOP -->
<ion-row
  *ngIf="
    productDetailStatusData?.summary?.properties?.has(propertiesEnum.scoop)
  "
>
  <ion-col size="6" size-xl="2.5" size-lg="3" size-md="4">
    <app-usf-chip
      [type]="chipTypeEnum.image"
      [iconConfig]="scoopConfig"
      iconUrl="assets/icon/scoop.svg"
    >
      {{ 'i18n.productDetail.status.scoop' | translate }}
    </app-usf-chip>
  </ion-col>
  <ion-col size="6" size-xl="9.5" size-lg="9" size-md="8">
    <span>
      {{ 'i18n.productDetail.status.scoopDescription' | translate }}
    </span>
  </ion-col>
</ion-row>
<!-- LOCALLY SOURCED PILL & INFO -->
<ion-row
  *ngIf="
    productDetailStatusData?.summary?.properties?.has(
      propertiesEnum.locallySourced
    )
  "
>
  <ion-col size="6" size-xl="2.5" size-lg="3" size-md="4">
    <app-usf-chip>
      {{ 'i18n.productDetail.status.locallySource' | translate }}
    </app-usf-chip>
  </ion-col>
  <ion-col size="6" size-xl="9.5" size-lg="9" size-md="8">
    <span>
      {{ 'i18n.productDetail.status.locallySourceDescription' | translate }}
    </span>
  </ion-col>
</ion-row>
