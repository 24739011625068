<ion-header class="ion-no-border ion-header-modal-mobile">
  <ion-toolbar>
    <ion-label data-cy="selected-products-label">
      {{
        (selectedProducts?.length > 1
          ? 'i18n.lists.selectedProducts'
          : 'i18n.lists.selectedProduct'
        ) | translate
      }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-content-modal-mobile">
  <ion-item-divider></ion-item-divider>
  <ion-item
    detail
    (click)="changeSelectedProductsAction(ListActions.viewSelected)"
    data-cy="click-change-selected-products-item"
  >
    <ion-label data-cy="select-products-text">
      {{ 'i18n.lists.viewSelected' | translate }}
      {{
        (selectedProducts?.length > 1
          ? 'i18n.lists.products'
          : 'i18n.lists.product'
        ) | translate
      }}
    </ion-label>
  </ion-item>
  <ion-item
    *ngIf="!fromListDetail"
    detail
    (click)="changeSelectedProductsAction(ListActions.move)"
    data-cy="click-move-selected-products-item"
  >
    <ion-label data-cy="move-selected-products-label">{{
      'i18n.lists.move' | translate
    }}</ion-label>
  </ion-item>
  <ion-item
    *ngIf="!fromListDetail"
    detail
    (click)="changeSelectedProductsAction(ListActions.copy)"
    data-cy="click-copy-selected-products-item"
  >
    <ion-label data-cy="copy-selected-products-label">{{
      'i18n.lists.copy' | translate
    }}</ion-label>
  </ion-item>
  <ion-item
    detail
    (click)="changeSelectedProductsAction(ListActions.delete)"
    data-cy="click-delete-selected-products-item"
  >
    <ion-label data-cy="delete-selected-products-label">{{
      'i18n.common.delete' | translate
    }}</ion-label>
  </ion-item>
</ion-content>
