import { Component } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { AddToInventoryBaseComponent } from '../add-to-inventory-base.component';
import { AddToInventoryInputComponent } from './add-to-inventory-input/add-to-inventory-input.component';
import { InventoryService } from '@inventory/services/inventory.service';
import { AddToInventoryStrings } from '@inventory/constants/inventory-constants';

@Component({
  selector: 'app-add-to-inventory-mobile',
  templateUrl: './add-to-inventory-mobile.component.html',
  styleUrls: ['./add-to-inventory-mobile.component.scss'],
})
export class AddToInventoryMobileComponent extends AddToInventoryBaseComponent {
  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    inventoryService: InventoryService,
  ) {
    super(nav, modalController, platformService, inventoryService);
  }

  changeSelectedAddToinventoryOption(
    addToinventoryOption: AddToInventoryStrings,
  ) {
    super.changeSelectedAddToinventoryOption(addToinventoryOption);
    this.nav.push(AddToInventoryInputComponent, {
      selectedAddToinventoryOption: addToinventoryOption,
      inventories: this.inventories,
      groups: this.groups,
      productNumbers: this.productNumbers,
      inventory: this.inventory,
      inventoryInput: this.selectedInventory,
      groupInput: this.group,
      groupPositionInput: this.groupPosition,
    });
  }
}
