import { Component, Input } from '@angular/core';
import { IonNav, ModalController, ViewWillEnter } from '@ionic/angular';
import { ListGroup, List } from '@usf/list-types';
import { ListState, ListConstants } from '@usf/ngrx-list';
import { ListsPageData } from 'src/app/lists/model/lists-page.model';
import { ListDetailManagementService } from 'src/app/lists/shared/list-detail-management/services/list-detail-management.service';
import { PlatformService } from '@panamax/app-state';
import { AddToListStrings } from '../../constants/lists-constants';
import { take } from 'rxjs/operators';
import { Product } from '../../models/product.model';

/**
 * @deprecated
 */
@Component({
  template: '',
})
export class AddToListBaseComponent implements ViewWillEnter {
  @Input() listPageData: ListsPageData;
  @Input() selectedProducts: Product[];
  @Input() addMethod?: string = null;
  @Input() merchFeatureName?: string = null;

  @Input() selectedAddToListOption: AddToListStrings =
    AddToListStrings.chooseList;
  @Input() showAlternativeToast = false;
  @Input() addMultipleEntryPoint = false;
  lastSelectedList: ListState;
  searchKey: string;
  lists: ListState[];
  filteredLists: ListState[];
  groups: ListGroup[];
  filteredGroups: ListGroup[];
  group: ListGroup;
  groupPosition: string;

  constructor(
    public nav: IonNav,
    protected modalController: ModalController,
    readonly platformService: PlatformService,
    protected listDetailManagementService: ListDetailManagementService,
  ) {}

  ionViewWillEnter(): void {
    if (this.nav.rootParams?.groupInput) {
      this.group = this.nav.rootParams?.groupInput;
    }
    if (this.nav.rootParams?.groupPositionInput) {
      this.groupPosition = this.nav.rootParams?.groupPositionInput;
    }

    this.lastSelectedList = this.nav.rootParams?.lastSelectedListInput
      ? this.nav.rootParams?.lastSelectedListInput
      : this.listPageData?.lastSelectedList
      ? this.listPageData?.lastSelectedList
      : null;
    this.searchKey = '';

    if (!this.lists) {
      this.createLists();
    } else {
      this.filteredLists = this.lists;
    }

    this.setInitialValues();
  }

  get addToList(): typeof AddToListStrings {
    return AddToListStrings;
  }

  createLists() {
    const lists: List[] = [];
    this.listPageData?.lists?.publicLists.forEach(list => {
      lists.push(list);
    });
    this.listPageData?.lists?.privateLists.forEach(list => {
      lists.push(list);
    });
    this.listPageData?.lists?.internalLists.forEach(list => {
      lists.push(list);
    });
    this.filteredLists = lists;
    this.lists = lists;
  }

  setInitialValues() {
    this.lastSelectedList = this.lastSelectedList
      ? this.filteredLists.find(
          list => list?.listName === this.lastSelectedList?.listName,
        )
      : null;

    if (this.lastSelectedList) {
      if (!this.groups) {
        this.getGroupsByList(this.lastSelectedList);
      }
      this.filteredGroups = this.groups;

      if (!this.group) {
        this.setInitialGroupValues();
      }
    }
  }

  getGroupsByList(list: List) {
    this.listDetailManagementService
      .listGroupsByListKey$(
        list.listKey.listTypeId,
        list.listKey.listId.toString(),
      )
      .pipe(take(1))
      .subscribe(data => {
        this.groups = data;
      });
  }

  setInitialGroupValues() {
    const groupLookUpKey = this.lastSelectedList?.selectedGroup
      ? this.lastSelectedList?.selectedGroup.toUpperCase()
      : ListConstants.unassignedGroup.toUpperCase();
    this.group = this.groups.find(
      group => group?.groupName?.toUpperCase() === groupLookUpKey,
    );
    this.groupPosition = AddToListStrings.top;
  }

  changeSelectedAddToListOption(addToListOption: AddToListStrings) {
    this.selectedAddToListOption = addToListOption;
    this.searchKey = '';
    this.searchList();
    this.searchGroup();
  }

  searchList() {
    if (this.searchKey != '') {
      this.filteredLists = this.lists.filter(list =>
        list?.listName
          ?.toUpperCase()
          .includes((this.searchKey || '').toUpperCase()),
      );
    } else {
      this.filteredLists = this.lists;
    }
  }

  searchGroup() {
    if (this.searchKey != '') {
      this.filteredGroups = this.groups.filter(group =>
        group?.groupName
          ?.toUpperCase()
          .includes((this.searchKey || '').toUpperCase()),
      );
    } else {
      this.filteredGroups = this.groups;
    }
  }

  resetSearchList() {
    this.searchKey = '';
    this.filteredLists = this.lists;
  }

  resetSearchGroup() {
    this.searchKey = '';
    this.filteredGroups = this.groups;
  }

  onChooseList(event: any) {
    this.lastSelectedList = event?.target?.value;
    this.getGroupsByList(this.lastSelectedList);
    this.filteredGroups = this.groups;
    this.setInitialGroupValues();
  }

  onChooseGroup(event: any) {
    this.group = event?.target?.value;
  }

  onChoosePosition(event: any) {
    this.groupPosition = event?.target?.value;
  }

  isValid() {
    return this.lastSelectedList && this.group && this.groupPosition;
  }

  onSubmit() {
    this.listDetailManagementService.addProductsToList(
      this.lastSelectedList,
      this.group,
      this.selectedProducts,
      this.groupPosition,
      this.addMethod,
      this.merchFeatureName,
      this.showAlternativeToast,
      this.addMultipleEntryPoint,
    );
    this.dismissModal(true);
  }

  async dismissModal(didSubmit?: boolean) {
    await this.modalController.dismiss({ didSubmit });
  }
}
