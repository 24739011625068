import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ProductActions,
  TRACE_CONTEXT,
  allAvailableDealsTransformer,
} from '@usf/ngrx-product';

@Injectable({
  providedIn: 'root',
})
export class DealsTracingService {
  constructor(protected store: Store) {}

  traceAllAvailableDeals() {
    this.store.dispatch(
      ProductActions.LoadAllAvailableDeals({
        tracking: {
          tracing: {
            data: {
              traceContext: TRACE_CONTEXT.deals,
              isStartOfTrace: true,
              isEndOfTrace: false,
              attributes: {
                event: 'All Available Deals',
              },
            },
            transformFunc: allAvailableDealsTransformer,
          },
        },
      }),
    );
  }

  traceAllAvailableDealsSuccess() {
    this.store.dispatch(
      ProductActions.LoadAllAvailableDealsSuccess({
        tracking: {
          tracing: {
            data: {
              traceContext: TRACE_CONTEXT.deals,
              isStartOfTrace: false,
              isEndOfTrace: true,
              attributes: {
                event: 'All Available Deals',
              },
            },
            transformFunc: allAvailableDealsTransformer,
          },
        },
      }),
    );
  }
}
