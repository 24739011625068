import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PanAppState, PlatformService } from '@panamax/app-state';
import { UsfProductCardBaseComponent } from '../usf-product-card-base.component';

@Component({
  selector: 'app-usf-product-card-touch',
  templateUrl: './usf-product-card-touch.component.html',
  styleUrls: [
    '../usf-product-card-base.component.scss',
    './usf-product-card-touch.component.scss',
  ],
})
export class UsfProductCardTouchComponent extends UsfProductCardBaseComponent {
  constructor(
    platformService: PlatformService,
    translateService: TranslateService,
    panAppState: PanAppState,
  ) {
    super(platformService, translateService, panAppState);
  }
}
