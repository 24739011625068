export enum OrderSubmittedTracingMessages {
  capability = 'orders',
  editOrderEvent = 'edit order',
  submitOrderEvent = 'submit order',
  submitVptOrderEvent = 'submit vpt order',
  editOrderButtonClick = 'Edit Order on Order Details',
  openMethod = 'edit submitted order',
  submittedOrderDetailsPageLoad = 'Submitted Order Details Page Load',
  downloadOrder = 'Download Submitted Order Details',
}

export enum OrderTracingMessagesTest {
  capability = 'Order',
  editOrderEvent = 'edit order',
  submitOrderEvent = 'submit order',
  editOrderButtonClick = 'Edit Order on Order Details',
  openMethod = 'edit submitted order',
}
