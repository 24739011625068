export const trimSpaceForInput = (value: any) => {
  const noLeadingSpaces = new RegExp('^[\\s]+', 'g');
  const noTrailingSpaces = new RegExp('[\\s]{2,}$', 'g');
  let regexValue = value.replace(noTrailingSpaces, '');
  regexValue = regexValue.replace(noLeadingSpaces, '');
  return regexValue;
};

export const currencyCleanup = (value: any, truncateValue: number) => {
  const scrubAlphaRegex = new RegExp('[^\\d.-]', 'g');
  const scrubAlphaRegexAndNegative = new RegExp('[^\\d.]', 'g');
  let regexValue = value.replace(scrubAlphaRegex, '');
  let isNegative = Number(regexValue).valueOf() < 0;
  regexValue = value.replace(scrubAlphaRegexAndNegative, '');
  if (regexValue.includes('.')) {
    let regexArray = regexValue.split('.');
    if (regexArray.length > 1) {
      regexValue =
        regexArray[0].slice(0, truncateValue) + '.' + regexArray[1].slice(0, 2);
    } else {
      regexValue = regexArray[0].slice(0, truncateValue);
    }
  } else {
    regexValue = regexValue.slice(0, truncateValue);
  }

  if (isNegative) {
    regexValue = '-' + regexValue;
  }

  return regexValue;
};
