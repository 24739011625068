import { createAction, props } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';

export const ORDER_SUBMITTED_ANALYTICS_ACTIONS = {
  trackPageLoad: createAction(
    '[NgRxClient Analytics] - Track Order Submitted Page Load',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackEditSubmitOrderApiResponseSuccess: createAction(
    '[NgRxTrace - Order] - Track Edit Submitted Order API Response Success',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackEditOrderClick: createAction(
    '[NgRxClient Analytics] - Track Edit Order Button Click',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackDownloadOrder: createAction(
    '[NgRxTrace - Order] - Track Download Order',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackTileEditOrderButtonClick: createAction(
    '[NgRxClient Analytics] - Edit Order Button Clicked on Home Page',
    props<{
      tracking: Tracking;
    }>(),
  ),
  trackShipToSubmit: createAction(
    '[NgRxOrder Analytics] Track Submit Ship To Conversion',
    props<{ tracking: Tracking }>(),
  ),
};
