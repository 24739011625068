import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { OrderAnalyticsService } from '@order/tracking/analytics/order-analytics.service';
import { PATHS } from '@shared/constants/paths';
import { NavigationHelperService } from '@shared/helpers/navigation.helpers.service';
import {
  IMPORT_ORDERS_ACTIONS,
  ImportOrdersStoreService,
} from '@usf/ngrx-order';
import { OrderTransaction } from '@usf/order-types';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  HeaderLinkLabelEnum,
  MenuOption,
} from 'src/app/shared/constants/header';

@Component({
  selector: 'app-menu-hover',
  templateUrl: './menu-hover.component.html',
  styleUrls: ['./menu-hover.component.scss'],
})
export class MenuHoverComponent {
  @Input() menuOptions: MenuOption[];
  importOrderModalIsOpen$ = new BehaviorSubject<boolean>(false);
  importOrdersLoading$: Observable<boolean> = this.importOrdersStoreService.loading$();
  importOrdersLoaded$: Observable<boolean> = this.importOrdersStoreService.loaded$();
  importOrdersTransactions$: Observable<
    OrderTransaction[]
  > = this.importOrdersStoreService.orderTransactions$();

  fileImportErrors$: Observable<
    string[]
  > = this.importOrdersStoreService.fileImportErrors$();
  fileImportMessage$: Observable<string> = this.importOrdersStoreService.message$();
  apiError$: Observable<string> = this.importOrdersStoreService.error$();

  constructor(
    private importOrdersStoreService: ImportOrdersStoreService,
    private popoverController: PopoverController,
    private store: Store,
    private navigationHelperService: NavigationHelperService,
    private orderAnalyticsService: OrderAnalyticsService,
  ) {}

  menuOptionClick(menuOption: MenuOption) {
    if (menuOption.label === HeaderLinkLabelEnum.importOrder) {
      this.initializeImportOrderState(new Event(''));
      this.openImportOrderModal(true);
      return;
    }
    menuOption.handler();
    this.dismiss();
  }

  dismiss() {
    if (!this.importOrderModalIsOpen$.value) {
      this.popoverController.dismiss();
    }
  }

  openImportOrderModal(isOpen: boolean) {
    this.importOrderModalIsOpen$.next(isOpen);
    this.orderAnalyticsService.trackImportOrderModalOpen(isOpen);
  }

  importOrderHandler(event: { file: File; fileType: string }) {
    this.store.dispatch(
      IMPORT_ORDERS_ACTIONS.load({
        ordersFile: event.file,
        fileType: event.fileType,
      }),
    );
  }

  handleImportModalDismiss(event) {
    if (this.importOrderModalIsOpen$.value) {
      this.openImportOrderModal(false);
    }
    this.popoverController.dismiss();
  }

  handleImportSuccess(event) {
    this.handleImportModalDismiss(event);
    this.navigationHelperService.routeTo(PATHS.IMPORT_ORDER);
  }

  initializeImportOrderState(event) {
    this.store.dispatch(IMPORT_ORDERS_ACTIONS.initialize());
  }
}
