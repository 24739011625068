<div class="inner-container">
  <div class="icon-container">
    <ion-icon
      color="success"
      name="close"
      size="large"
      (click)="dismiss()"
      data-cy="promote-app-close-modal-button"
    ></ion-icon>
  </div>
  <img class="usf-logo" src="assets/images/usfoods-logo-color.svg" />
  <div class="text">
    <h1 data-cy="promote-app-title">
      {{ 'i18n.popovers.promoteTitle' | translate }}
    </h1>
    <h3 data-cy="promote-app-message-text">
      {{ 'i18n.popovers.promoteText' | translate }}
    </h3>
  </div>
  <a [href]="appLink" data-cy="promote-app-download-link">
    <img class="store-logo" [src]="imgSrc" />
  </a>
</div>
