import { WeeklyMovement } from '@usf/product-types';
import { WeeklyMovementViewModel } from '../models/weekly-movement-view-model';
import dayjs from 'dayjs';
import { weeklyMovementDateFormat } from '../../shared/constants/date-formats';

export const findMostRecentWeeklyMovement = (
  movements: WeeklyMovement[],
): WeeklyMovementViewModel => {
  const mostRecentMovement = calculateWeeklyMovements(movements).find(
    mov => !!mov,
  );
  if (!!mostRecentMovement) {
    const currentDate = dayjs();
    if (
      currentDate.isAfter(dayjs(mostRecentMovement.weekStart)) &&
      currentDate.isBefore(dayjs(mostRecentMovement.weekEnd))
    ) {
      // is current
    } else {
      // is old
      return {
        ...mostRecentMovement,
        rate: 0,
        rateLabel: '0%',
        isTrendingUp: undefined,
      };
    }
  }

  return mostRecentMovement;
};

export const calculateWeeklyMovements = (
  movements: WeeklyMovement[],
): WeeklyMovementViewModel[] => {
  const weeklyMovements = [...(movements ?? [])];

  if (weeklyMovements.length === 1) {
    const singleMovement = movements[0];
    return [
      {
        ...singleMovement,
        weekLabel: dayjs
          .utc(singleMovement.weekStart)
          .format(weeklyMovementDateFormat),
        rate: 0,
        rateLabel: '0%',
      },
    ];
  }

  return weeklyMovements
    .sort((left, right) =>
      dayjs(left.weekStart).isAfter(dayjs(right.weekStart)) ? 1 : -1,
    )
    .reduce((ratedMovements, movement) => {
      const currentMovement: WeeklyMovementViewModel = {
        ...movement,
        weekLabel: dayjs
          .utc(movement.weekStart)
          .format(weeklyMovementDateFormat),
        rate: 0,
        rateLabel: '0%',
      };

      const prevMovement = ratedMovements[ratedMovements.length - 1];
      if (!!prevMovement) {
        // special case divided by 0
        if (prevMovement.projectedSales === 0) {
          currentMovement.rate = 100;
          currentMovement.rateLabel = '+100%';
          currentMovement.isTrendingUp = true;
        } else {
          currentMovement.rate = parseFloat(
            (
              ((currentMovement.projectedSales - prevMovement.projectedSales) /
                prevMovement.projectedSales) *
              100
            ).toFixed(1),
          );
          currentMovement.isTrendingUp =
            currentMovement.rate === 0 ? undefined : currentMovement.rate > 0;
          currentMovement.rateLabel = `${
            currentMovement.isTrendingUp ? '+' : ''
          }${currentMovement.rate}%`;
        }
      }

      ratedMovements.push(currentMovement);
      return ratedMovements;
    }, [] as WeeklyMovementViewModel[])
    .sort((left, right) =>
      dayjs(left.weekStart).isBefore(dayjs(right.weekStart)) ? 1 : -1,
    );
};
