import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PlatformEnum } from '@panamax/app-state';
import { DualPaneViewModel } from '@shared/models/dual-pane-model';

@Component({
  template: '',
})
export class RightPaneComponent {
  @Input() dataCyTag = 'dual-pane';
  @Input() vm: DualPaneViewModel;
  @Input() platform: PlatformEnum;

  @Output() checkValidity = new EventEmitter<void>();
  platformRef = PlatformEnum;
  constructor() {}
}
