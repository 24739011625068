import { BetterBuyDeclineCode } from '@shared/models/better-buy-card.model';

export const BetterBuyDeclineCodes: BetterBuyDeclineCode[] = [
  {
    label: 'Cost',
    code: 0,
  },
  {
    label: 'Quality',
    code: 1,
  },
  {
    label: 'Pack Size',
    code: 2,
  },
  {
    label: 'Flavor Profile',
    code: 3,
  },
  {
    label: 'Not Nutritionally Equivalent ',
    code: 4,
  },
  {
    label: 'Conflicts with current menu or menu cycle',
    code: 5,
  },
  {
    label: 'Conflicts with brand offerings',
    code: 6,
  },
  {
    label: 'Conflicts with rebated / contracted products',
    code: 7,
  },
  {
    label: 'Other',
    code: 8,
  },
];
