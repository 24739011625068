import { Injectable, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PanAppState, PlatformService } from '@panamax/app-state';
import { RecentPurchase } from '@usf/list-types';
import {
  CustomerPill,
  ProductContract,
  ProductInventory,
  ProductSummary,
} from '@usf/product-types';
import { ProductPropertiesEnum } from '@usf/product-types/Product-Summary';
import { combineLatest, Subscription, take } from 'rxjs';
import { CustomerStoreService } from '../../ngrx-customer/services';
import { RecentPurchasePopoverComponent } from '../../shared/components/popovers/recent-purchase/recent-purchase-popover.component';
import { ChipTypeEnum } from '../../shared/components/usf-chip/models/chip-type.enum';
import { TagProduct } from '../../shared/components/usf-product-tag-list/models/tag-product.model';
import { FEATURES } from '../../shared/constants/splitio-features';
import { OGRestrictionsEnum } from '../../shared/constants/sub-restrictions-enum';
import { RecentPurchaseHelper } from '../../shared/helpers/recent-purchase.helpers';
import { ModalService } from '../../shared/services/modal/modal.service';
import { ProductDetailStatusComponent } from '../components/product-detail-status/product-detail-status.component';
import { ProductDetailViewModel } from '../models/product-detail-view-model';
import {
  ProductTagDisplay,
  ProductTagDisplayOrder,
} from '../models/product-tag.model';
import {
  getContractTag,
  getCustomerPillTextColor,
  getProductDetailStatusData,
} from '../utils/product-tags-util';
import { UsfChipPopoverOptions } from '@shared/components/usf-chip/models/usf-chip-popover-options.model';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailTagsService implements OnDestroy {
  masterListsEnabled = false;
  isMslRestricted = false;
  isOGRestricted = false;
  isDirectAllowReturnsEnabled = false;
  featureFlagSub$: Subscription = new Subscription();
  loadSelectedCustomerSub$: Subscription = new Subscription();

  constructor(
    private modalController: ModalController,
    private panAppState: PanAppState,
    private customerStoreService: CustomerStoreService,
    private modalService: ModalService,
    readonly platformService: PlatformService,
  ) {
    this.featureFlagSub$ = combineLatest([
      this.panAppState.feature$([FEATURES.split_global_master_lists]),
      this.panAppState.feature$([FEATURES.split_global_direct_allow_returns]),
    ]).subscribe(
      ([materListFeatureFlagEnabled, directAllowReturnsFeatureFlagEnabled]) => {
        this.masterListsEnabled = materListFeatureFlagEnabled;
        this.isDirectAllowReturnsEnabled = directAllowReturnsFeatureFlagEnabled;
      },
    );
    // This may be causing async problems, setting component variable with subscription then using it in other functions
    this.getIsMslIsOgRestricted();
  }

  async openProductDetailStatusModal(
    productDetail: ProductDetailViewModel,
    showProductClaims = true,
  ) {
    const productDetailStatusData = getProductDetailStatusData(
      productDetail,
      this.isMslRestricted,
      this.isOGRestricted,
      this.isDirectAllowReturnsEnabled,
    );

    this.modalService.setModalOptions(
      this.platformService.isTouch.value,
      ProductDetailStatusComponent,
      {
        productDetailStatusData,
        showProductClaims,
      },
      'modal-desktop-size-xl',
    );

    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );
    return await modal.present();
  }

  async showAllAvailableStatuses(
    product: ProductDetailViewModel,
    showProductClaims = true,
  ) {
    let data: ProductDetailViewModel = { ...product };
    if (this.masterListsEnabled) {
      data = {
        ...data,
        masterLists: product?.masterLists,
        isMslRestricted: this.isMslRestricted,
      };
    }
    await this.openProductDetailStatusModal(data, showProductClaims);
  }

  getDisplayTags(
    productSummary: ProductSummary,
    productInventory: ProductInventory,
    productContract: ProductContract,
    customerPill: CustomerPill,
    recentPurchase?: RecentPurchase,
    clickable = false,
    isGuestUser = false,
  ): TagProduct[] {
    const displayOrder = ProductTagDisplayOrder;

    return Array.from(productSummary?.properties ?? [])
      .sort((a, b) => {
        return displayOrder.indexOf(a) - displayOrder.indexOf(b);
      })
      .map(tag => {
        const guestUserTags = [
          ProductPropertiesEnum.direct,
          ProductPropertiesEnum.scoop,
          ProductPropertiesEnum.locallySourced,
        ];
        if (isGuestUser && !guestUserTags.includes(tag)) {
          return null;
        }
        switch (tag) {
          case ProductPropertiesEnum.contract:
            const tag = productContract
              ? getContractTag(productContract)
              : null;
            if (!!tag) {
              return {
                content: `${productContract.iconDescription} - Contract`,
                type: tag.type,
                clickable: clickable,
                iconUrl: tag.iconUrl,
                iconConfig: tag.iconConfig,
                clickEmitter: this.getClickEmitter(
                  clickable,
                  ProductPropertiesEnum.contract,
                  productSummary,
                  productInventory,
                  productContract,
                  customerPill,
                ),
              };
            }
            return null;
          case ProductPropertiesEnum.customerPill:
            return !!customerPill
              ? {
                  content: customerPill?.pillText,
                  type: ChipTypeEnum.customerPill,
                  textColor: getCustomerPillTextColor(
                    customerPill?.htmlColorCode,
                  ),
                  backgroundColor: customerPill?.htmlColorCode,
                  description: customerPill?.pillDescription,
                  clickable,
                  clickEmitter: this.getClickEmitter(
                    clickable,
                    ProductPropertiesEnum.customerPill,
                    productSummary,
                    productInventory,
                    productContract,
                    customerPill,
                  ),
                }
              : null;
          case ProductPropertiesEnum.direct:
            return {
              content: ProductTagDisplay.direct,
              type: ChipTypeEnum.image,
              clickable: clickable,
              iconConfig: {
                name: 'DIRECT',
                iconWidth: 4.5,
              },
              iconUrl: 'assets/icon/direct.svg',
              clickEmitter: this.getClickEmitter(
                clickable,
                ProductPropertiesEnum.direct,
                productSummary,
                productInventory,
                productContract,
                customerPill,
              ),
            };
          case ProductPropertiesEnum.locallySourced:
            return {
              content: ProductTagDisplay.locallySourced,
              type: ChipTypeEnum.default,
              clickable: clickable,
              clickEmitter: this.getClickEmitter(
                clickable,
                ProductPropertiesEnum.locallySourced,
                productSummary,
                productInventory,
                productContract,
                customerPill,
              ),
            };
          case ProductPropertiesEnum.dwo:
            return {
              content: ProductTagDisplay.dwo,
              type: ChipTypeEnum.default,
              clickable: clickable,
              clickEmitter: this.getClickEmitter(
                clickable,
                ProductPropertiesEnum.dwo,
                productSummary,
                productInventory,
                productContract,
                customerPill,
              ),
            };
          case ProductPropertiesEnum.recentlyPurchased:
            if (
              RecentPurchaseHelper.formatRecentPurchase(recentPurchase).length >
              0
            ) {
              return {
                content: ProductTagDisplay.recentPurchase,
                type: ChipTypeEnum.default,
                clickable: clickable,
                popoverOptions: !!recentPurchase
                  ? ({
                      component: RecentPurchasePopoverComponent,
                      componentProps: {
                        recentPurchase,
                      },
                      cssClass: 'recent-purchase-popover',
                      mode: 'ios',
                      showBackdrop: true,
                      backdropDismiss: true,
                      inline: false,
                    } as UsfChipPopoverOptions)
                  : undefined,
                clickEmitter: this.getClickEmitter(
                  clickable,
                  ProductPropertiesEnum.recentlyPurchased,
                  productSummary,
                  productInventory,
                  productContract,
                  customerPill,
                  recentPurchase,
                ),
              };
            }
            return null;
          case ProductPropertiesEnum.onOrderGuideOrOnShoppingList:
            return {
              content: ProductTagDisplay.onMyLists,
              type: ChipTypeEnum.default,
              clickable: clickable,
              clickEmitter: this.getClickEmitter(
                clickable,
                ProductPropertiesEnum.onOrderGuideOrOnShoppingList,
                productSummary,
                productInventory,
                productContract,
                customerPill,
                recentPurchase,
              ),
            };
          case ProductPropertiesEnum.scoop:
            return {
              content: ProductTagDisplay.scoop,
              type: ChipTypeEnum.image,
              clickable: clickable,
              iconConfig: {
                name: 'SCOOP',
                iconWidth: 4.313,
              },
              iconUrl: 'assets/icon/scoop.svg',
              clickEmitter: this.getClickEmitter(
                clickable,
                ProductPropertiesEnum.scoop,
                productSummary,
                productInventory,
                productContract,
                customerPill,
                recentPurchase,
              ),
            };
          case ProductPropertiesEnum.onMasterList:
            return {
              content: this.isMslRestricted
                ? ProductTagDisplay.onMasterListRestricted
                : ProductTagDisplay.onMasterList,
              type: ChipTypeEnum.default,
              clickable: clickable,
              clickEmitter: this.getClickEmitter(
                clickable,
                ProductPropertiesEnum.onMasterList,
                productSummary,
                productInventory,
                productContract,
                customerPill,
              ),
            };
          default:
            return null;
        }
      })
      .filter(displayTag => !!displayTag);
  }

  getIsMslIsOgRestricted() {
    this.loadSelectedCustomerSub$ = this.customerStoreService
      .loadSelectedCustomer$()
      ?.subscribe(customer => {
        this.isMslRestricted =
          customer?.restrictToOG === OGRestrictionsEnum.RESTRICT_TO_ML
            ? true
            : false;
        this.isOGRestricted =
          customer?.restrictToOG === OGRestrictionsEnum.RESTRICT_TO_OG
            ? true
            : false;
      });
  }

  getClaimTags(
    productDetail: ProductDetailViewModel,
    clickable = false,
  ): TagProduct[] {
    return productDetail?.detail?.claims?.map(claim => ({
      content: claim.name,
      type: ChipTypeEnum.claim,
      clickable: clickable,
      clickEmitter: () => this.openProductDetailStatusModal(productDetail),
    }));
  }

  private getClickEmitter(
    clickable: boolean,
    selectedProperty: ProductPropertiesEnum,
    productSummary: ProductSummary,
    productInventory: ProductInventory,
    productContract: ProductContract,
    customerPill: CustomerPill,
    recentPurchase?: RecentPurchase,
  ) {
    if (!clickable) {
      return undefined;
    }
    const propertiesSet: Set<ProductPropertiesEnum> = new Set([
      selectedProperty,
    ]);
    return () =>
      this.openProductDetailStatusModal({
        summary: { ...productSummary, properties: propertiesSet },
        inventory: productInventory,
        contract: productContract,
        customerPill,
        recentPurchase,
      } as ProductDetailViewModel);
  }

  ngOnDestroy(): void {
    if (this.featureFlagSub$) {
      this.featureFlagSub$.unsubscribe();
    }
    if (this.loadSelectedCustomerSub$) {
      this.loadSelectedCustomerSub$.unsubscribe();
    }
  }
}
