import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomerSummary } from '@usf/customer-types/customer';
import { BehaviorSubject } from 'rxjs';
import { CUSTOMER_ACTIONS } from '../../ngrx-customer/store';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  isSuperUserCustomersModalOpen$ = new BehaviorSubject<boolean>(false);
  showCustomersDataLoadingSpinner$ = new BehaviorSubject<boolean>(false);
  filteredCustomerSummaries$ = new BehaviorSubject<CustomerSummary[]>([]);

  constructor(private store: Store) {}

  searchCustomers(searchKey: string) {
    this.store.dispatch(
      CUSTOMER_ACTIONS.searchCustomers({
        searchKey,
      }),
    );
  }

  setIsSuperUserCustomersModalOpen(isOpen: boolean) {
    this.isSuperUserCustomersModalOpen$.next(isOpen);
  }

  setShowCustomersDataLoadingSpinner(isOpen: boolean) {
    this.showCustomersDataLoadingSpinner$.next(isOpen);
  }

  setFilteredCustomerSummaries(customerSummaries: CustomerSummary[]) {
    this.filteredCustomerSummaries$.next(customerSummaries);
  }
}
