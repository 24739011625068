export const FEATURES = {
  demo_user_trafic_type_feature: 'demo_user_trafic_type_feature',
  demo_user_trafic_type_feature2: 'demo_user_trafic_type_feature2',
  demo_user_trafic_type_feature3: 'demo_user_trafic_type_feature3',
  split_global_legacy_login: 'split_global_legacy_login',
  split_global_microsoft_login: 'split_global_microsoft_login',
  split_global_microsoft_login_automation:
    'split_global_microsoft_login_automation',
  split_global_service_request: 'split_global_service_request',
  split_global_dyf_products: 'split_global_dyf_products',
  split_global_inspired_pick_products: 'split_global_inspired_pick_products',
  split_global_review_order_merch_zone: 'split_global_review_order_merch_zone',
  split_global_review_order_frequently_bought_together:
    'split_global_review_order_frequently_bought_together',
  split_global_topsellernull_recentpurchase_filter:
    'split_global_topsellernull_recentpurchase_filter',
  split_global_microsoft_login_sellers_customers:
    'split_global_microsoft_login_sellers_customers',
  split_global_price_per_portion: 'split_global_price_per_portion',
  split_global_product_reviews: 'split_global_product_reviews',
  split_global_product_questions: 'split_global_product_questions',
  split_global_create_opportunity: 'split_global_create_opportunity',
  split_global_convert_to_willcall: 'split_global_convert_to_willcall',
  split_global_deals: 'split_global_deals',
  split_global_download_search: 'split_global_download_search',
  split_user_payments_link: 'split_user_payments_link',
  split_user_payments_make_a_payment: 'split_user_payments_make_a_payment',
  split_user_payments_cancel_payment: 'split_user_payments_cancel_payment',
  split_user_payments_dashboard: 'split_user_payments_dashboard',
  split_user_payments_dashboard_override:
    'split_user_payments_dashboard_override',
  split_user_payments_dashboard_aging: 'split_user_payments_dashboard_aging',
  split_user_payments_payment_activity: 'split_user_payments_payment_activity',
  split_user_payments_allInvoices_download:
    'split_user_payments_allInvoices_download',
  split_user_payments_autopay: 'split_user_payments_autopay',
  split_user_payments_payment_preferences:
    'split_user_payments_payment_preferences',
  split_division_payments_make_a_payment:
    'split_division_payments_make_a_payment',
  split_global_payments_outage: 'split_global_payments_outage',
  split_global_master_lists: 'split_global_master_lists',
  split_global_og_msl_subs: 'split_global_og_msl_subs',
  split_global_kibo_tag: 'split_global_kibo_tag',
  split_global_logout_all_sessions: 'split_global_logout_all_sessions',
  split_global_logoutrocket_sessions: 'split_global_logoutrocket_sessions',
  split_global_user_app_error_modal: 'split_global_user_app_error_modal',
  split_global_inventory: 'split_global_inventory',
  split_global_inventory_migration: 'split_global_inventory_migration',
  split_division_dfg_banner: 'split_division_dfg_banner',
  split_b2c_refreshtoken_call: 'split_b2c_refreshtoken_call',
  split_global_external_vendors: 'split_global_external_vendors',
  split_global_alerts_custom_message: 'split_global_alerts_custom_message',
  split_global_direct_shipping_savings: 'split_global_direct_shipping_savings',
  split_global_national_customers: 'split_global_national_customers',
  split_global_profile_redirect: 'split_global_profile_redirect',
  split_global_download_print: 'split_global_download_print',
  split_global_cpr_button: 'split_global_cpr_button',
  split_global_ngp_cpr_low_limit: 'split_global_ngp_cpr_low_limit',
  split_global_share_pdp: 'split_global_share_pdp',
  split_global_share_pdp_desktop: 'split_global_share_pdp_desktop',
  split_global_print_pdp: 'split_global_print_pdp',
  split_global_PFAS_restriction: 'split_global_PFAS_restriction',
  split_global_order_details_download: 'split_global_order_details_download',
  split_global_order_confirmation_download:
    'split_global_order_confirmation_download',
  split_global_interact_banners: 'split_global_interact_banners',
  split_global_min_order_met: 'split_global_min_order_met',
  split_global_delivery_toggle: 'split_global_delivery_toggle',
  split_global_product_compare: 'split_global_product_compare',
  split_global_avendra_toggle: 'split_global_avendra_toggle',
  split_global_chef_store_qr_code: 'split_division_alerts_qr_code',
  split_global_chef_store_qr_code_new: 'split_global_chef_store_qr_code_new',
  split_global_order_guide_filter: 'split_global_order_guide_filter',
  split_global_appcues: 'split_global_appcues',
  split_division_alerts_appcues: 'split_division_alerts_appcues',
  split_global_new_inventory_feature: 'split_global_new_inventory_feature',
  split_global_product_compare_split_card:
    'split_global_product_compare_split_card',
  split_global_autosub_review_order: 'split_global_autosub_review_order',
  split_global_product_compare_popover: 'split_global_product_compare_popover',
  split_global_product_compare_print: 'split_global_product_compare_print',
  split_global_product_compare_download:
    'split_global_product_compare_download',
  split_global_product_compare_share: 'split_global_product_compare_share',
  split_global_user_customizations: 'split_global_user_customizations',
  split_global_better_buys: 'split_global_better_buys',
  split_global_background_refresh: 'split_global_background_refresh',
  split_division_recently_viewed: 'split_division_recently_viewed',
  split_global_order_details_print: 'split_global_order_details_print',
  split_global_order_confirmation_print:
    'split_global_order_confirmation_print',
  split_global_review_order_print: 'split_global_review_order_print',
  split_global_list_item_note: 'split_global_list_item_note',
  split_global_quick_order_entry_page: 'split_global_quick_order_entry_page',
  split_global_past_orders_page: 'split_global_past_orders_page',
  split_global_import_order: 'split_global_import_order',
  split_global_import_list: 'split_global_import_list',
  split_global_list_download: 'split_global_list_download',
  split_global_copy_list: 'split_global_copy_list',
  split_global_mass_copy_list: 'split_global_mass_copy_list',
  split_global_mass_delete_list: 'split_global_mass_delete_list',
  split_global_pickup_request: 'split_global_pickup_request',
  split_global_inventory_cost_report: 'split_global_inventory_cost_report',
  split_global_inventory_extended_cost_report:
    'split_global_inventory_extended_cost_report',
  split_global_force_app_update: 'split_global_force_app_update',
  split_global_forced_update_notification:
    'split_global_forced_update_notification',
  split_global_inventory_worksheet_download:
    'split_global_inventory_worksheet_download',
  split_global_alerts_trendview: 'split_global_alerts_trendview',
  split_global_imported_orders: 'split_global_imported_orders',
  split_global_inventory_cost_comparison_report:
    'split_global_inventory_cost_comparison_report',
  split_global_reusable_cost_comparison_report:
    'split_global_reusable_cost_comparison_report',
  split_global_download_imported_orders:
    'split_global_download_imported_orders',
  split_global_invite_new_user: 'split_global_invite_new_user',
  split_customer_type_not_NA_appcues: 'split_customer_type_not_NA_appcues',
  split_global_quick_order_entry_widget:
    'split_global_quick_order_entry_widget',
  split_global_order_unusual_quantities:
    'split_global_order_unusual_quantities',
  split_user_toggle_new_search: 'split_user_toggle_new_search',
  split_global_review_order_download: 'split_global_review_order_download',
  split_global_order_review_share: 'split_global_order_review_share',
  split_global_chef_store_role_check: 'split_global_chef_store_role_check',
  split_division_local_only_invite_user:
    'split_division_local_only_invite_user',
  split_division_invite_new_user: 'split_division_invite_new_user',
  split_global_works_well_with_flag: 'split_global_works_well_with_flag',
  split_global_product_price_per_portion_card_flag:
    'split_global_product_price_per_portion_card_flag',
  split_customization_direct_separate_shipping:
    'split_customization_direct_separate_shipping',
  split_global_primary_language_selection:
    'split_global_primary_language_selection',
  split_global_alerts_delete_user: 'split_global_alerts_delete_user',
  split_global_alerts_super_user: 'split_global_alerts_super_user',
  split_global_inventory_gl_sorting: 'split_global_inventory_gl_sorting',
  split_global_equipment_and_supplies: 'split_global_equipment_and_supplies',
  split_global_order_insight: 'split_global_order_insight',
  split_global_alerts_phone_nbrs: 'split_global_alerts_phone_nbrs',
  split_division_alerts_phone_nbrs: 'split_division_alerts_phone_nbrs',
  split_global_modify_vs_order: 'split_global_modify_vs_order',
  split_global_add_multiple_products: 'split_global_add_multiple_products',
  split_global_my_orders_refresher: 'split_global_my_orders_refresher',
  split_global_order_status_refresher: 'split_global_order_status_refresher',
  split_global_order_detail_refresher: 'split_global_order_detail_refresher',
  split_global_assign_pim_nonUsfProduct:
    'split_global_assign_pim_nonUsfProduct',
  split_global_vs_order_status_display: 'split_global_vs_order_status_display',
  split_global_order_status_download: 'split_global_order_status_download',
  split_user_vs_order_status_display: 'split_user_vs_order_status_display',
  split_global_food_cost_report: 'split_global_food_cost_report',
  split_global_search_banner_whilesupplieslast:
    'split_global_search_banner_whilesupplieslast',
  split_global_search_banner_serveyou: 'split_global_search_banner_serveyou',
  split_global_order_delivery_instructions:
    'split_global_order_delivery_instructions',
  split_global_guest_experience: 'split_global_guest_experience',
  split_global_quick_order_entry_cust_prod:
    'split_global_quick_order_entry_cust_prod',
  split_global_search_banner_revman: 'split_global_search_banner_revman',
  split_global_alerts_user_mgmt: 'split_global_alerts_user_mgmt',
  split_global_search_within_review_order:
    'split_global_search_within_review_order',
  split_global_order_add_lost_line: 'split_global_order_add_lost_line',
  split_global_search_banner_payments: 'split_global_search_banner_payments',
  split_global_cab_v2: 'split_global_cab_v2',
  split_global_support_center: 'split_global_support_center',
  split_global_search_browse: 'split_global_search_browse',
  split_global_search_within_order_details:
    'split_global_search_within_order_details',
  split_global_seller_scoop_preview: 'split_global_seller_scoop_preview',
  split_global_search_banner_EBCOP: 'split_global_search_banner_EBCOP',
  split_global_search_banner_equip_and_supplies:
    'split_global_search_banner_equip_and_supplies',
  split_global_dyf_fbt_filter: 'split_global_dyf_fbt_filter',
  split_global_will_call_type: 'split_global_will_call_type',
  split_division_order_shipto_button: 'split_division_order_shipto_button',
  split_division_order_recovery_button: 'split_division_order_recovery_button',
  split_global_contracts_v2: 'split_global_contracts_v2',
  split_global_avendraforgood_banner: 'split_global_avendraforgood_banner',
  split_division_order_pronto: 'split_division_order_pronto',
  split_global_redirect_unauthenticated_users_to_guest:
    'split_global_redirect_unauthenticated_users_to_guest',
  split_global_aog: 'split_global_aog',
  split_global_tablet_aog: 'split_global_tablet_aog',
  split_global_msl_slim_product_load: 'split_global_msl_slim_product_load',
  split_global_direct_allow_returns: 'split_global_direct_allow_returns',
  split_global_offline_update_quantities:
    'split_global_offline_update_quantities',
  split_global_search_banner_crossvalleyfarms:
    'split_global_search_banner_crossvalleyfarms',
  split_global_alerts_user_mgmt_adv_opts:
    'split_global_alerts_user_mgmt_adv_opts',
  split_global_alerts_user_mgmt_premier:
    'split_global_alerts_user_mgmt_premier',
  split_global_msl_compare_alternates: 'split_global_msl_compare_alternates',
  split_global_order_super_user_import_order:
    'split_global_order_super_user_import_order',
  split_global_order_gen_ai_playground: 'split_global_order_gen_ai_playground',
  split_user_order_gen_ai_playground: 'split_user_order_gen_ai_playground',
  split_global_alerts_survey: 'split_global_alerts_survey',
  split_global_search_new_search_analytics:
    'split_global_search_new_search_analytics',
} as const;

export type FeatureFlag = (typeof FEATURES)[keyof typeof FEATURES];
